import { Injectable } from '@angular/core';
import { PublisherId, SortOrders, PublisherConstantId } from '../common/interfaces';
import { Editor } from './editor';

@Injectable()
export class PublishersService {
  editor?: Editor;
  linkAccount(_id: PublisherId): Promise<void> { return Promise.resolve(); }
  remixRevision(_shortId: string, _artworkName: string | undefined) { return Promise.resolve(); }
  publishRevision(_editor: Editor, _providerId: PublisherId, _data: any) { return Promise.resolve(); }
  async getMyRegisteredIps(_limit: number, _offset: number, _sortBy: 'date' | 'name', _sortOrder: SortOrders): Promise<{ revisions: any[], count: number }> { return Promise.resolve({ revisions: [], count: 0 }); }
  getEntity(_id: string): Promise<any> { return Promise.resolve(undefined); }
  updateUserPublicationStatus(_publisherId: PublisherId, _revisionId: string, _status: number): Promise<any> { return Promise.resolve(undefined); }
  cancelPublication(_publisherId: PublisherId, _revisionId: string): Promise<any> { return Promise.resolve(undefined); }
  async getConstant(_providerId: PublisherId, _constantId: PublisherConstantId): Promise<any> { return Promise.resolve(undefined); }
}
