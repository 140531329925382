import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { CreateAccountData, UpdateAccountData } from '../../../../common/interfaces';
import { USER_COLORS, USER_COLORS_NAMES, USER_JOBS, USER_NAME_LENGTH_LIMIT } from '../../../../common/constants';
import { getAvatarPath, randomAvatar } from '../../../../common/utils';
import { colorYIQ } from '../../../../common/color';
import { faTrash, faFolderOpen, faDice, faCheck } from '../../../../common/icons';
import { uploadAvatar } from '../../../../services/uploadService';
import { Model } from '../../../../services/model';
import { options, signUps } from '../../../../common/data';
import { ActivatedRoute } from '@angular/router';
import { storageGetItem, storageSetItem } from '../../../../services/storage';

@Component({
  selector: 'settings-form',
  templateUrl: 'settings-form.pug',
  styleUrls: ['settings-form.scss'],
})
export class SettingsForm implements OnInit {
  readonly removeIcon = faTrash;
  readonly openIcon = faFolderOpen;
  readonly randomIcon = faDice;
  readonly checkIcon = faCheck;
  readonly colors = USER_COLORS;
  readonly userJobs = USER_JOBS;
  readonly colorsNames = USER_COLORS_NAMES;
  readonly colorYIQ = colorYIQ;
  readonly maxNameLength = USER_NAME_LENGTH_LIMIT;
  readonly samlActive = signUps.includes('saml');
  receiveEmails = '';
  userJob?: string;
  otherJob?: string;
  @Input() data!: CreateAccountData | UpdateAccountData;
  @Input() userSettings = false;
  @Output() error = new EventEmitter<string>();
  constructor(public model: Model, private route: ActivatedRoute) {
  }
  ngOnInit() {
    const model = this.model;

    if (!IS_HOSTED && IS_PORTAL) {
      if (model.user.userJob === undefined) {
        this.userJob = '';
        this.setUserJob(this.userJob);
      } else if (!this.userJobs.includes(model.user.userJob)) {
        this.userJob = 'Other';
        this.otherJob = model.user.userJob;
        this.setOtherJob(this.otherJob);
      } else {
        this.userJob = model.user.userJob;
        this.setUserJob(this.userJob);
      }

      this.receiveEmails = model.user.receiveEmails?.toString() ?? '';
      this.setReceiveEmails(this.receiveEmails);
      this.route.queryParams.subscribe(params => {
        const referralCode = params['ref'] || storageGetItem('referral-code');

        if (referralCode) {
          this.data.referralCode = referralCode;
          if (params['ref']) {
            storageSetItem('referral-code', referralCode);
          }
        }
      });
    }
  }
  get disabled() {
    return this.samlActive || !!options.disableChangingProfile && !this.model.user.isSuperAdmin;
  }
  get avatar() {
    return getAvatarPath(this.data.avatar, 100);
  }
  get showNewsletter() {
    return !this.userSettings || (!IS_PORTAL && !this.model.user.anonymous);
  }
  get displayNameTooltip() {
    if (this.samlActive) return 'Your username is managed by administrator';
    if (this.disabled) return `You don't have permission to change display name`;
    return undefined;
  }
  async selectAvatar(file: File) {
    if (this.samlActive || this.disabled) return;
    try {
      this.error.emit(undefined);
      this.data.avatar = await uploadAvatar(file);
    } catch (e) {
      this.error.emit(e.message);
    }
  }
  randomizeAvatar() {
    this.data.avatar = randomAvatar();
  }
  removeAvatar() {
    this.data.avatar = '';
  }
  colorTaken(color: string) {
    return this.model.colorTaken(color);
  }
  setReceiveEmails(value: string) {
    this.receiveEmails = value;
    this.data.receiveEmails = value === 'true' ? true : value === 'false' ? false : undefined;
  }
  setUserJob(value: string) {
    this.userJob = value;
    this.data.userJob = value === 'Other' ? undefined : value;
  }
  setOtherJob(value: string) {
    this.otherJob = value;
    this.data.userJob = value;
  }
}
