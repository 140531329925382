import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MiniUser } from '../../../common/interfaces';
import { faTimes, faCheck } from '../../../common/icons';

@Component({
  selector: 'recently-drawn-person',
  templateUrl: 'recently-drawn-person.pug',
  styleUrls: ['recently-drawn-person.scss'],
})
export class RecentlyDrawnPerson {
  readonly faTimes = faTimes;
  readonly faCheck = faCheck;

  @Input() person?: MiniUser = undefined;
  @Input() checked = false;

  @Output() checkedChange = new EventEmitter<boolean>();
  @Output() removed = new EventEmitter<MiniUser>();

  checkedChanged(_event: Event) {
    this.checkedChange.emit(!this.checked);
  }

  removeFromList(_event: Event, person: MiniUser) {
    this.removed.emit(person);
  }
}
