import { Feature } from './interfaces';

// keep it here because we don't want to share flag descriptions to all users
// @ignore-translation
export const FEATURE_LIST: { feature: Feature, description: string; }[] = [
  { feature: Feature.Timelapse, description: 'enable saving timelapse revisions' },

  { feature: Feature.Hid, description: 'enable WebHID' },
  { feature: Feature.Presentation, description: 'allow starting presentations' },
  { feature: Feature.PresentationObligeJoining, description: 'allow the presenter to oblige users to join the presentation' },

  { feature: Feature.BrushesGrut, description: '' },
  { feature: Feature.BrushesIntq20, description: '' },
  { feature: Feature.BrushesIntq49, description: '' },
  { feature: Feature.BrushesOmri, description: '' },
  { feature: Feature.BrushesRoss, description: '' },
  { feature: Feature.BrushesFantasio, description: '' },
  { feature: Feature.BrushesBradley, description: '' },
  { feature: Feature.BrushesExperimental, description: '' },

  { feature: Feature.ShapesGrids, description: '' },

  { feature: Feature.Ai, description: 'enable AI' },
  { feature: Feature.AiNsfw, description: 'disable NSFW filter for AI' },
  { feature: Feature.AiEula, description: 'agreed to AI EULA' },
  { feature: Feature.AiBeta, description: 'enable access to beta ai options' },
  { feature: Feature.AiNoPromptFilter, description: 'disable filtering restricted keywords in prompt' },
  { feature: Feature.AiNoUsageLimits, description: 'disable limiting ai generation time' },
  { feature: Feature.AiDreambooth, description: 'enable access to dreambooth (training custom AI models)' },

  { feature: Feature.StorageNoUsageLimits, description: 'enable no storage usage limit' },
  { feature: Feature.VersionHistory, description: 'enable version history' },
  { feature: Feature.Drawing16k, description: 'enables creating 16k images' },
  { feature: Feature.ContentPages, description: 'enable content pages for artspace' },
  { feature: Feature.BillingSkipBlazeLimit, description: 'Allow buying more than MAX_USERS_IN_BLAZE_TEAM for artspace' },
  { feature: Feature.NoFrameLimit, description: 'Skip sequence frame limit' },
  { feature: Feature.PerspectiveGrid, description: 'enable perspective grid' },

  { feature: Feature.RemixingRevisions, description: 'Enable publishing revisions for remixing' },
  { feature: Feature.StoryProtocolRegistration, description: 'Enable Story Protocol registration' },

  { feature: Feature.Jams, description: 'enable art jams' },
  { feature: Feature.Multiboard, description: 'enable multiboards' },

  { feature: Feature.UniqueUsernames, description: 'enable ability to see change username sections' },
  { feature: Feature.AdjustUsername, description: 'rollout ability to change username' },

  { feature: Feature.EmbeddedCommunityGoal, description: 'have embedded community goal page available in artdesk' },

  { feature: Feature.Blend, description: 'enable blend' },
];
