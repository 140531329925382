import MessageFormat from '@messageformat/core';

export type Locale = { [key: string]: string; };

export let locale: Locale = {};
export let localeId = 'en';

let messagesToReport: {
  node: HTMLElement | Text,
  value: string,
}[] = [];

let reportTimer: ReturnType<typeof setTimeout> | undefined = undefined;
let lastMessageCount = 0;
let forceNextSync = false;

function syncMaps() {
  if (!window.i18nMessageMapping?.onMapped) return;

  reportTimer = undefined;
  let changed = false;
  messagesToReport = messagesToReport.filter(v => {
    // remove messages that are no longer in the DOM
    const visible = document.body.contains(v.node);
    if (!visible) {
      console.log('syncMaps remove since not visible anymore', v.value);
      changed = true;
    }
    return visible;
  });

  if (!forceNextSync && !changed && lastMessageCount === messagesToReport.length) {
    return;
  }

  forceNextSync = false;
  lastMessageCount = messagesToReport.length;
  const viewport = { width: window.innerWidth, height: window.innerHeight };
  window.i18nMessageMapping.onMapped(messagesToReport.map(v => {
    const rect = v.node instanceof Text ? v.node.parentElement?.getBoundingClientRect() : v.node.getBoundingClientRect();
    // we return position relative to the viewport from 0 to 1
    return {
      message: '' + v.value,
      position: rect && viewport.width && viewport.height ? {
        x: rect.left / viewport.width,
        y: rect.top / viewport.height,
      } : { x: 0, y: 0 }
    };
  }));
}

function reportMapIfAvailable(node: HTMLElement | Text, value: string) {
  if (value in locale) {
    messagesToReport.push({ node, value });
    if (!reportTimer) {
      reportTimer = setTimeout(syncMaps, 100);
    }
  }
}

const hookAvailable = typeof window !== 'undefined' && 'undefined' !== typeof window.i18nMessageMapping && 'undefined' !== typeof window.i18nMessageMapping.onMapped;

export const reportMap = hookAvailable ? reportMapIfAvailable : () => undefined;

if (typeof window !== 'undefined' && 'undefined' !== typeof window.i18nMessageMapping) {
  locale = window.i18nMessageMapping.locale;
  localeId = window.i18nMessageMapping.localeId;
}

if (hookAvailable) {
  setInterval(syncMaps, 250);

  window.addEventListener('resize', () => {
    forceNextSync = true;
  });

  window.i18nMessageMapping!.translate = (map: { [name: string]: string }) => {
    Object.assign(locale, map);
    for (const [message, translation] of Object.entries(map)) {
      if (!translation) continue;

      for (const m of messagesToReport) {
        if (m.value === message) {
          m.node.textContent = translation;
        }
      }
    }
  };
}

export function translateFromMap(value: string, map: Locale, domain?: string): string {
  if (value.startsWith('\u00AD')) {
    return value.slice(1);
  } else if (domain) {
    return (map._DOMAINS as any)?.[domain]?.[value] || map[value] || value;
  } else {
    return map[value] || value;
  }
}

export function translateString(value: string, map: Locale = locale) {
  return translateFromMap(value, map);
}

export function nonTranslatable(value: string): string;
export function nonTranslatable(value: string | undefined): string | undefined;
export function nonTranslatable(value: string | undefined): string | undefined {
  if (!value) return value;
  return '\u00AD' + value;
}

export function getDefaultLocaleCode(localeCodes: string[]): string | undefined {
  if (navigator.language !== undefined) {
    const langCode = navigator.language.split('-')[0];
    return localeCodes.find(localeCode => localeCode.startsWith(langCode));
  }
  return undefined;
}

// simple replacement with $1, $2, $3, ...
export function translateWithPlaceholders(translation: string, values: any[], domains?: (string | undefined)[], withLocale = locale) {
  return translateFromMap(translation, withLocale).replace(/\$([0-9]+)/g, (_match: string, index: string) => {
    const i = +index - 1;
    const value = values[i];
    if (value == null) return '';
    if (typeof value === 'string') return translateFromMap(value, withLocale, domains?.[i]);
    return value;
  });
}

function combineWithPlaceholders(strings: TemplateStringsArray) {
  let fullString = strings[0] || '';

  for (let i = 1; i < strings.length; i++) {
    fullString += `$${i}${strings[i]}`;
  }

  return fullString;
}

export function createTranslate(withLocale: Locale = locale) {
  return (strings: TemplateStringsArray, ...values: any[]) => {
    if (!strings.length) return '';
    if (strings.length === 1 && !values.length) return translateString(strings[0]);

    return translateWithPlaceholders(combineWithPlaceholders(strings), values, undefined, withLocale);
  };
}

export function translatable(strings: TemplateStringsArray, ...values: any[]): [string, any[]] {
  return [combineWithPlaceholders(strings), values.map(x => `${x}`)];
}

const messageFormat = new MessageFormat(localeId.split('-')[0]);
const compiledMessages = new Map<string, (args: any) => string>();

// https://www.unicode.org/cldr/charts/42/supplemental/language_plural_rules.html

export function pluralize(format: string, value: any, domain?: string) {
  const key = translateFromMap(format, locale, domain);
  let func = compiledMessages.get(key);

  if (!func) {
    compiledMessages.set(key, func = messageFormat.compile(`{value, plural, ${key}}`));
  }

  return func({ value });
}
