import { Analytics, hasAnalyticsLayerType, BrushFeature, BrushToolSettings, SubscriptionPlan, DrawingLoadFailure } from './interfaces';
import { TextareaType } from './text/textarea';
import { TextareaControlPointDirections } from './text/control-points';
import type { Editor } from '../services/editor';
import { invalidEnum, hasFlag, randomString } from './baseUtils';
import { ImageTypeNames } from './imageUtils';
import { Mutable } from './typescript-utils';
import { MINUTE } from './constants';
import { sum } from './mathUtils';
import { JamDrawing, JamVisibility } from './interfaces-jams';

export type TextToolAnalytics = Analytics.TextToolSelectedFontFamily | Analytics.TextToolSelectedFontStyle | Analytics.TextToolSelectedBoxType;
export type TextToolAnalyticsEvents = TextToolSelectedFontFamilyEvent | TextToolSelectedFontStyleEvent | TextToolChangedTextBoxTypeEvent;

type TextToolSelectionAnalyticsString = 'undefined' | 'global' | 'caret' | 'full' | 'partial';
interface HasTextToolSelectionAnalyticsString {
  currentTextSelection: TextToolSelectionAnalyticsString;
}

interface TextToolSelectedFontSomething extends HasTextToolSelectionAnalyticsString {
  fontFamily: string;
  fontStyle: string;
}

export interface TextToolSelectedFontFamilyEvent extends TextToolSelectedFontSomething {
  needsToLoadFontFirst: boolean;
  source: 'click' | 'wheel' | 'arrows' | 'esc';
  searchedFor?: string;
}

export interface TextToolSelectedFontStyleEvent extends TextToolSelectedFontSomething {
  source: 'keydown:bold' | 'keydown:italic' | 'button:bold' | 'button:italic' | 'dropdown';
}

export interface TextToolChangedTextBoxTypeEvent {
  from: TextareaType;
  to: TextareaType;
  source: 'button' | `resizing:${TextareaControlPointDirections}`;
}

export interface RasterizedTextLayerEvent {
  source: 'layer-context-menu' | 'confirmation-prompt';
}

export interface TransformedLayerEvent extends hasAnalyticsLayerType {
  currentTool: string;
  operation: 'move' | 'rotate' | 'scale' | 'resize' | 'move-origin' | 'cover' | 'fit' | 'reset';
  source: 'mouse' | 'touch' | 'pen' | 'keyboard' | 'button:rotateCW' | 'button:rotateCCW' | 'button:flipX' | 'button:flipY' | 'button:fit' | 'button:cover' | 'button:reset';
}

export interface StopScreensharingEvent {
  source: 'video-box' | 'voice-menu';
}

export interface EditorInabilityStateEvent {
  state: EditorInabilityStateId;
  reason: string;
  timeInSuspension: number;
  waitingPeriods: number;
  stateWaitingPeriods: number;
  leftPage: boolean;
}

export interface PendingEditorInabilityState {
  startedAt: number;
  reason: string;
}

export const EDITOR_INABILITY_STATE_TIMEOUT = 10 * MINUTE;

export enum EditorInabilityStateId {
  Locked = 'Locked', // lockEditor(), unlockEditor()
  Loading = 'Loading',  // model.loaded() setter
  Connecting = 'Connecting', // ClientActions: .connected(), .disconnectedInternal()
  WaitingForServer = 'Waiting for server', // model.connectionIssues
  LoadingFonts = 'Loading fonts', // fonts.ts
  Importing = 'Importing', // importUtils.ts
}

export function clearEditorInabilityStates(editor: Editor, leaving: boolean) {
  for (const state of editor.pendingInabilityStates.keys()) {
    exitEditorInabilityState(editor, state, leaving);
  }
}

export function exitEditorInabilityState(editor: Editor, id: EditorInabilityStateId, leaving: boolean) {
  const state = editor.pendingInabilityStates.get(id);
  if (state) {
    const timeout = editor.inabilityStatesTimeouts.get(id);
    editor.model.track?.event<EditorInabilityStateEvent>(Analytics.EditorLocked, {
      state: id,
      reason: state.reason,
      timeInSuspension: !timeout ? EDITOR_INABILITY_STATE_TIMEOUT : performance.now() - state.startedAt,
      waitingPeriods: sum(Array.from(editor.inabilityStatesCounts.values())),
      stateWaitingPeriods: (editor.inabilityStatesCounts.get(id) ?? 0),
      leftPage: leaving,
    });
    editor.pendingInabilityStates.delete(id);
    timeout && clearTimeout(timeout);
    editor.inabilityStatesTimeouts.delete(id);
  }
}

export function enterEditorInabilityState(editor: Editor, id: EditorInabilityStateId, reason: string) {
  if (!editor.pendingInabilityStates.has(id)) {
    editor.pendingInabilityStates.set(id, { reason, startedAt: performance.now() });
    editor.inabilityStatesCounts.set(id, (editor.inabilityStatesCounts.get(id) ?? 0) + 1);
    let timeout = setTimeout(() => {
      editor.inabilityStatesTimeouts.delete(id);
      exitEditorInabilityState(editor, id, false);
    }, EDITOR_INABILITY_STATE_TIMEOUT);
    editor.inabilityStatesTimeouts.set(id, timeout);
  }
}

export interface PerspectiveGridModeEvent {
  mode: string
}

export interface PerspectiveGridComponentsVisibilityEvent {
  vanishingPointsVisibility: boolean,
  horizonLinesVisibility: boolean,
  gridLinesVisibility: boolean
}

export interface PerspectiveGridColorEvent {
  type: string
}

export function brushFeatureToString(tab: BrushFeature) {
  switch (tab) {
    case BrushFeature.BrushTipShape: return 'Brush Tip Shape';
    case BrushFeature.ShapeDynamics: return 'Shape Dynamics';
    case BrushFeature.Scattering: return 'Scattering';
    case BrushFeature.Texture: return 'Texture';
    case BrushFeature.DualBrush: return 'Dual Brush';
    case BrushFeature.ColorDynamics: return 'Color Dynamics';
    case BrushFeature.Transfer: return 'Transfer';
    case BrushFeature.BrushPose: return 'Brush Pose';
    default: invalidEnum(tab);
  }
}

export function brushFeaturesToString(features: BrushToolSettings['hasFeatures']) {
  let str = 'Brush Tip Shape';
  if (hasFlag(features, BrushFeature.ShapeDynamics)) str += `, ${brushFeatureToString(BrushFeature.ShapeDynamics)}`;
  if (hasFlag(features, BrushFeature.Scattering)) str += `, ${brushFeatureToString(BrushFeature.Scattering)}`;
  if (hasFlag(features, BrushFeature.Texture)) str += `, ${brushFeatureToString(BrushFeature.Texture)}`;
  if (hasFlag(features, BrushFeature.DualBrush)) str += `, ${brushFeatureToString(BrushFeature.DualBrush)}`;
  if (hasFlag(features, BrushFeature.ColorDynamics)) str += `, ${brushFeatureToString(BrushFeature.ColorDynamics)}`;
  if (hasFlag(features, BrushFeature.Transfer)) str += `, ${brushFeatureToString(BrushFeature.Transfer)}`;
  if (hasFlag(features, BrushFeature.BrushPose)) str += `, ${brushFeatureToString(BrushFeature.BrushPose)}`;
  return str;
}

export function getSubscriptionPlanName(plan: SubscriptionPlan | undefined) {
  switch (plan) {
    case undefined:
    case SubscriptionPlan.Free:
      return 'Spark';
    case SubscriptionPlan.Blaze:
      return 'Blaze';
    case SubscriptionPlan.Fusion:
      return 'Fusion';
    default: invalidEnum(plan);
  }
}

export type ViewUnauthorizedPageReason = 'session-limit' | 'not-found' | 'no-access' | 'frame-limit';

export function getViewUnauthorizedPageReason(loadFailure: DrawingLoadFailure) : ViewUnauthorizedPageReason | undefined {
  switch (loadFailure) {
    case DrawingLoadFailure.SessionLimit: return 'session-limit';
    case DrawingLoadFailure.NotFound: return 'not-found';
    case DrawingLoadFailure.NoAccess: return 'no-access';
    case DrawingLoadFailure.FrameLimit: return 'frame-limit';
  }
  return undefined;
}

export interface ViewUnauthorizedPageEvent {
  reason: ViewUnauthorizedPageReason,
  isAnonymous: boolean
}

export interface CreateDrawingEvent {
  drawingId: string;
  method: 'manual:editor' | 'manual:portal' | 'import:editor' | 'import:portal';
  canvasWidth: number;
  canvasHeight: number;
}

export interface ManualCreateDrawingEvent extends CreateDrawingEvent {
  method: Extract<CreateDrawingEvent['method'], `manual:${string}`>;
  password: boolean;
  takeOverOfflineUsersLayers: boolean;
  copyPermissions: boolean;
}

export interface ImportCreateDrawingEvent extends CreateDrawingEvent {
  method: Extract<CreateDrawingEvent['method'], `import:${string}`>;
  fileSize: number;
  fileExtension: Mutable<typeof ImageTypeNames>[number];
  success?: boolean;
  isMissingFeatures?: boolean; // only for PSD and only if import was successful
}

export interface CollaborateEvent {
  drawingId: string;
  userPresent: boolean;
  numberOfCreatorsOnline: number;
  numberOfCreators: number;
  isOwnDrawing: boolean;
}

export function getTabSessionId() {
  if (SERVER) return 'serverside';
  let tabSessionId = '';
  try {
    tabSessionId = sessionStorage.getItem('tabSessionId') ?? '';
    if (!tabSessionId) {
      tabSessionId = randomString(8, true);
      sessionStorage.setItem('tabSessionId', tabSessionId);
    }
  } catch (e) {
    tabSessionId = randomString(8, true);
  }
  return tabSessionId;
}

export const TAB_SESSION_ID = getTabSessionId();

export interface AddDrawingToSequenceEvent {
  mainDrawingId: string;
  createdUrl: string;
  createdFrom: string;
  sequenceLength: number; // always greater than 1
}

interface JamAnalyticsExtraPayload {
  from?: string;
  indexOnTheList?: number;
  anonymous?: boolean;
  verified?: boolean;
}

export interface JamAnalyticsPayload {
  drawingId: string;
  tags: string;
  genre: string;
  hasRules: boolean;
  isNSFW: boolean;
  visibility?: JamVisibility;
  from?: string;
  indexOnTheList?: number;
}

export function createJamAnalyticsData(jamDrawing: JamDrawing, extra: JamAnalyticsExtraPayload = {}): JamAnalyticsPayload {
  return {
    drawingId: jamDrawing.shortId,
    tags: jamDrawing.tags.join(','),
    genre: jamDrawing.jam.genre.join(','),
    hasRules: !!jamDrawing.jam.rules,
    isNSFW: jamDrawing.jam.nsfw,
    visibility: jamDrawing.jam.visibility,
    ...extra
  };
}

