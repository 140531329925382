import { redrawLayer } from '../../services/editorUtils';
import { hasFlag, includes, keys } from '../baseUtils';
import { BLACK, BRUSH_SIZES, TEST_PRESSURE_OPACITY, WHITE } from '../constants';
import { BrushBlendMode, BrushFeature, BrushToolSettings, CompositeOp, CursorType, ITabletTool, ITool, IToolEditor, IToolModel, Layer, TabletEvent, ToolId } from '../interfaces';
import { isMaskEmpty } from '../mask';
import { PaintBrush } from '../paintBrush';
import { generateSeed } from '../random';
import { cloneRect, copyRect, createRect, haveNonEmptyIntersection, intersectRect, makeIntegerRect, rectsIntersection, safeRect, setRect } from '../rect';
import { brushShapesMap } from '../shapes';
import { createInnerStabilizer } from '../stabilizer';
import { setupSurface } from '../toolSurface';
import { finishTransform } from '../toolUtils';
import { releaseToolRenderingContext } from '../user';
import { absoluteDocumentToDocumentRect, documentToAbsoluteDocumentRect } from '../viewport';
import { BRUSH_FIELDS, compressBrushData, decompressBrushData, IBrushToolData, roundPercent, setupBrush } from './brushUtils';
import { throwIfTextLayer } from '../text/text-utils';
import { Subject } from 'rxjs';
import { createViewport } from '../create';

export abstract class BaseBrushTool implements BrushToolSettings, ITool {
  _id = '';
  id = ToolId.None;
  name = '';
  updatesCursor = true;
  altTool = true;
  lineOnShift = true;
  fields = keys<BaseBrushTool>(BRUSH_FIELDS);
  // size
  size = 20;
  sizePressure = true;
  sizeJitter = 0;
  sizes = BRUSH_SIZES;
  minSize = 0;
  sizeRatio = 1;
  // other
  flow = 1;
  flowPressure = false;
  opacity = 1;
  opacityPressure: boolean = TEST_PRESSURE_OPACITY; // TODO: add to fields, send
  spacing = 0.2;
  hardness = 1;
  stabilize = 0;
  // spread
  separateSpread = false;
  normalSpread = 0;
  tangentSpread = 0;
  // shape
  shape = '';
  angle = 0;
  angleJitter = 3.14;
  angleToDirection = false;
  flipX = false;
  flipY = false;
  roundness = 1;
  // color
  color = BLACK;
  colorHue = 0;
  background = BLACK;
  colorPressure = false;
  foregroundBackgroundJitter = 0;
  hueJitter = 0;
  saturationJitter = 0;
  brightnessJitter = 0;
  view = createViewport();
  opacityLocked = false; // indicates whether layer opacity was locked at the start of drawing
  drawingShiftLine = false;
  viewFlip = false;
  viewRotation = 0;
  blendMode = BrushBlendMode.Normal;
  hasFeatures = BrushFeature.BrushTipShape;
  lockedFeatures = 0;
  moveLayerToSurface = false;
  readonly changedProps$ = new Subject<void>();
  brush: PaintBrush;
  private stable: ITabletTool;
  private proxy: ITabletTool;
  private endX = 0;
  private endY = 0;
  private endPressure = 0;
  private prevLayers: any[] = []; // TEMP: testing code
  seed = 0;
  protected layer: Layer | undefined = undefined;
  protected compositeOperation = CompositeOp.Draw;
  drawingBounds = createRect(0, 0, 0, 0);
  private tempDirtyRect = createRect(0, 0, 0, 0);
  constructor(public editor: IToolEditor, public model: IToolModel) {
    this.brush = new PaintBrush();
    this.seed = generateSeed();

    this.brush.onDirtyRect = rect => {
      copyRect(this.tempDirtyRect, rect);
      documentToAbsoluteDocumentRect(this.tempDirtyRect, this.drawingBounds);
      redrawLayer(this.model.drawing, this.layer, this.tempDirtyRect);
    };

    this.stable = this.proxy = {
      id: this.id,
      start: (x: number, y: number, pressure: number, _: TabletEvent | undefined) => {
        if (!this.layer) throw new Error('[BaseBrushTool.proxy.start] Missing layer');
        throwIfTextLayer(this.model.user.activeLayer);

        finishTransform(this.model, 'BaseBrushTool');

        if (isMaskEmpty(this.model.user.selection)) {
          setRect(this.brush.bounds, 0, 0, this.drawingBounds.w, this.drawingBounds.h);
        } else {
          copyRect(this.brush.bounds, this.model.user.selection.bounds);
          intersectRect(this.brush.bounds, this.drawingBounds);
          absoluteDocumentToDocumentRect(this.brush.bounds, this.drawingBounds);
        }

        const hasColorDynamics = hasFlag(this.hasFeatures, BrushFeature.ColorDynamics) && (this.colorPressure || this.foregroundBackgroundJitter || this.hueJitter || this.saturationJitter || this.brightnessJitter);
        const surface = this.model.user.surface;
        setupSurface(surface, this.id, this.compositeOperation, this.layer, this.drawingBounds);
        surface.opacity = this.id === ToolId.Blend ? 1.0 : roundPercent(this.opacity);
        surface.color = hasColorDynamics || this.id === ToolId.Blend ? WHITE : this.color;

        this.brush.context = this.editor.renderer.getToolRenderingContext(this.model.user, this.drawingBounds);
        if (this.moveLayerToSurface) {
          this.editor.renderer.copyLayerToSurface(this.layer, surface, surface.rect);
        }
        this.brush.start(x, y, pressure);

        copyRect(surface.rect, this.brush.dirtyRect);
        documentToAbsoluteDocumentRect(surface.rect, this.drawingBounds);
      },
      move: (x: number, y: number, pressure: number) => {
        this.brush.move(x, y, pressure);
        copyRect(this.model.user.surface.rect, this.brush.dirtyRect);
        documentToAbsoluteDocumentRect(this.model.user.surface.rect, this.drawingBounds);
      },
      end: (x: number, y: number, pressure: number) => {
        if (!this.layer) throw new Error('[BaseBrushTool.proxy.end] Missing layer');

        const user = this.model.user;
        this.brush.end(x, y, pressure);
        copyRect(user.surface.rect, this.brush.dirtyRect);
        documentToAbsoluteDocumentRect(model.user.surface.rect, this.drawingBounds);

        // TEMP: testing code
        if (this.layer !== user.activeLayer) {
          const layerId = this.layer.id;
          const activeId = user.activeLayer?.id ?? -1;
          throw new Error(`[BaseBrushTool.proxy.end] [${this.model.type}] this.layer !== user.activeLayer (${layerId} != ${activeId}) prev: [${this.prevLayers.join(', ')}]`);
        }
        while (this.prevLayers.length > 10) this.prevLayers.shift();
        this.prevLayers.push('end');
        // END

        releaseToolRenderingContext(user);
        const remote = this.model.type === 'remote';
        const beforeRect = cloneRect(this.layer.rect);

        const dirtyRect = makeIntegerRect(rectsIntersection(this.brush.dirtyRect, this.brush.bounds));
        documentToAbsoluteDocumentRect(dirtyRect, this.drawingBounds);

        // TEMP: testing, this is happening really rarely (dirtyRect is empty when surface.rect is not)
        if (DEVELOPMENT && (dirtyRect.x !== user.surface.rect.x || dirtyRect.x !== user.surface.rect.x ||
          dirtyRect.x !== user.surface.rect.x || dirtyRect.x !== user.surface.rect.x) && !this.moveLayerToSurface) {
          throw new Error(`paintBrush.commit - different dirty rects: (${JSON.stringify(dirtyRect)}, ${JSON.stringify(user.surface.rect)})`);
        }
        // END
        const erasedNothing = this.id === ToolId.Eraser && !haveNonEmptyIntersection(beforeRect, dirtyRect);
        if (erasedNothing) {
          this.model.cancelTool('empty');
          this.editor.renderer.releaseUserCanvas(user);
          user.history.unpre();
        } else if (this.brush.commit(this.name, this.opacityLocked, user, this.editor.renderer, remote)) {
          const afterRect = cloneRect(this.layer.rect);
          this.model.endTool(this.id, this.endX, this.endY, this.endPressure, beforeRect, afterRect);
        } else {
          this.model.cancelTool('empty');
          user.history.unpre();
        }

        this.brush.context = undefined;
        this.layer = undefined;
        this.seed = generateSeed();
      }
    };
  }
  protected setupBrush(brush: PaintBrush) {
    setupBrush(brush, this, this.color, this.colorHue, this.background);
  }
  get cursor(): CursorType { return this.shape ? CursorType.Image : CursorType.Circle; }
  protected getData() {
    return compressBrushData(this);
  }
  protected setData(data?: IBrushToolData) {
    if (data) {
      if (data.p) {
        Object.assign(this, decompressBrushData(data.p));
      }
    } else {
      if (!this.layer) throw new Error(`[BaseBrushTool.setData] Missing layer`);
      this.color = this.editor.primaryColor;
      this.colorHue = this.editor.primaryColorHue;
      this.background = this.editor.secondaryColor;
      this.opacityLocked = this.layer.opacityLocked;
      this.viewFlip = this.view.flipped;
      this.viewRotation = this.view.rotation;
    }
  }
  verify() {
    if (!brushShapesMap.get(this.shape)) {
      this.editor.apply(() => this.shape = '');
    }
  }
  setup(data?: IBrushToolData) {
    this.layer = this.model.user.activeLayer;

    if (!this.layer) throw new Error(`[BaseBrushTool] Missing activeLayer`);
    if (!includes(this.model.drawing.layers, this.layer)) throw new Error(`[BaseBrushTool] ActiveLayer not in drawing`);

    // TEMP: testing code
    while (this.prevLayers.length > 10) this.prevLayers.shift();
    this.prevLayers.push(this.layer.id);
    // END

    this.setData(data);

    if (this.stabilize === 0) {
      this.stable = this.proxy;
    } else {
      this.stable = createInnerStabilizer(this.id, this.proxy, roundPercent(this.stabilize));
    }

    this.setupBrush(this.brush);

    if (data) {
      if (!data.bounds) throw new Error('Missing drawing bounds');
      copyRect(this.drawingBounds, safeRect(data.bounds));
    }
  }
  start(x: number, y: number, pressure: number) {
    if (!this.layer) throw new Error('[BaseBrushTool] Missing layer');

    this.stable.start!(x - this.drawingBounds.x, y - this.drawingBounds.y, pressure, undefined);

    const data: IBrushToolData = { id: this.id, p: this.getData(), bounds: cloneRect(this.drawingBounds) };
    if (this.drawingShiftLine) data.shiftLine = true;
    this.model.startTool<IBrushToolData>(this.layer.id, data, x, y, pressure);
  }
  move(x: number, y: number, pressure: number) {
    this.stable.move!(x - this.drawingBounds.x, y - this.drawingBounds.y, pressure);
    this.model.nextTool(x, y, pressure);
  }
  end(x: number, y: number, pressure: number) {
    this.endX = x;
    this.endY = y;
    this.endPressure = pressure;
    this.stable.end!(x - this.drawingBounds.x, y - this.drawingBounds.y, pressure);
  }
  toSettings(): BrushToolSettings {
    const settings: Partial<BrushToolSettings> = {};
    const fields = this.fields as (keyof BaseBrushTool)[];
    for (const field of fields) {
      (settings as any)[field] = this[field];
    }
    return settings as BrushToolSettings;
  }
}
