import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { sendGAEvent } from '../../../../common/utilsAnalytics';
import { logo, logo_extra } from '../../../../generated/resources';
import { Model } from '../../../../services/model';
import { storageGetBoolean } from '../../../../services/storage';
import { createTranslate, nonTranslatable,  } from '../../../../common/i18n';

const tr = createTranslate();

@Component({
  selector: 'sign-up',
  templateUrl: 'sign-up.pug',
  styleUrls: ['sign-up.scss'],
})
export class SignUp implements OnInit {
  readonly logo = logo;
  readonly logoSignup = logo_extra;
  @Input() data = { forced: false, isPublicDemoDrawing: false };
  @Output() close = new EventEmitter<void>();

  constructor(private model: Model) {
  }
  get anonName() {
    return this.model.user.name;
  }
  get forcedSignUp() {
    return storageGetBoolean('sign-up-forced');
  }
  get title() {
    return this.data.forced && !this.forcedSignUp ? tr`Welcome back, ${nonTranslatable(this.anonName)}` : 'Start drawing now';
  }
  ngOnInit() {
    sendGAEvent('Sign up', 'View', 'popup-modal');
  }
  closeModal() {
    this.close.emit();
  }
}
