import { ITool, IToolEditor, IToolModel, CompositeOp, IToolData, Layer, ToolId, Rect, DrawingDataFlags, CursorType } from '../interfaces';
import { faFillDrip } from '../icons';
import { rectContainsXY, copyRect, isRectEmpty, cloneRect, safeRect, setRect, createRect } from '../rect';
import { BLACK } from '../constants';
import { setupSurface } from '../toolSurface';
import {
  floodFillContiguous, floodFill, floodFillContiguousAlpha, floodFillAlpha, floodFillContiguousAlphaSmart, antialiasFloodFill
} from '../floodFill';
import { getAlpha, getR, getG, getB, colorFromRGBA } from '../color';
import { invalidEnum, keys } from '../baseUtils';
import { getAboveLayer, getBelowLayer } from '../drawing';
import { compressImageAlphaRLEWasm, decompressImageAlphaRLEWasm } from '../wasmUtils';
import { redrawLayer } from '../../services/editorUtils';
import { safeBoolean, safeUintAny, safeOpacity, finishTransform } from '../toolUtils';
import { throwIfTextLayer } from '../text/text-utils';
import { logAction } from '../actionLog';
import { documentToAbsoluteDocumentRect } from '../viewport';
import { cropImageData } from '../imageUtils';

function now() {
  if (DEVELOPMENT && !SERVER) {
    return performance.now();
  } else {
    return 0;
  }
}

type PaintbucketTarget = 'active' | 'all' | 'above' | 'below';
type PaintbucketMode = 'color' | 'opacity' | 'smart';
type PaintbucketFillMode = 'fill' | 'fillRest' | 'clear' | 'clearRest';

export interface IPaintbucketToolData extends IToolData {
  color: number;
  opacity: number;
  rect: Rect;
  fill: boolean;
  // only for reporting stats
  target: PaintbucketTarget;
  mode: PaintbucketMode;
  fillMode: PaintbucketFillMode;
  contiguous: boolean;
}

export class PaintbucketTool implements ITool {
  id = ToolId.Paintbucket;
  name = 'Paint Bucket';
  description = 'Fill an area with your chosen color';
  learnMore = 'https://help.magma.com/en/articles/6871494-paint-bucket';
  video = { url: '/assets/videos/paintbucket.mp4', width: 374, height: 210 };
  icon = faFillDrip;
  cursor = CursorType.Paintbucket;
  cancellableLocally = true;
  altTool = true;
  opacity = 1;
  tolerance = 0;
  antiAlias = false;
  contiguous = true;
  target: PaintbucketTarget = 'active';
  mode: PaintbucketMode = 'color';
  fillMode: PaintbucketFillMode = 'fill';
  fields = keys<PaintbucketTool>(['opacity', 'mode', 'tolerance', 'contiguous', 'target', 'fillMode', 'antiAlias']);

  drawingBounds = createRect(0, 0, 0, 0);

  private color = BLACK;
  private layer?: Layer;
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  setup(data?: IPaintbucketToolData) {
    if (!this.model.user.activeLayer) throw new Error('[PaintbucketTool] Missing activeLayer');

    this.layer = this.model.user.activeLayer;
    this.color = data ? safeUintAny(data.color) : this.editor.primaryColor;

    if (data) {
      if (!data.bounds) throw new Error('Missing drawing bounds');
      copyRect(this.drawingBounds, data.bounds);
    }
  }
  do(data: IPaintbucketToolData, buffer?: Uint8Array) {
    if (!buffer) throw new Error(`[PaintbucketTool] Missing binary data`);

    const fill = safeBoolean(data.fill);
    const rect = safeRect(data.rect);
    const color = safeUintAny(data.color);
    const opacity = safeOpacity(data.opacity);

    const start = now();
    logAction(`[remote] paintbucket decompress (${rect.w}x${rect.h}, ${buffer.byteLength})`);
    const imageData = decompressImageAlphaRLEWasm(buffer, rect.w, rect.h, color, (w, h, data) => this.editor.renderer.createImageData(w, h, data));
    !SERVER && DEVELOPMENT && console.log(`decompress: ${(now() - start).toFixed(2)} ms`);

    finishTransform(this.model, 'PaintbucketTool:do');
    this.editor.renderer.putImageData(this.model.user, imageData, rect);
    this.commit(opacity, rect, fill);
  }
  start() {
    finishTransform(this.model, 'PaintbucketTool:start');
  }
  move() {
  }
  end(x: number, y: number) {
    const { user, drawing } = this.model;
    const layer = this.layer;
    const { w: width, h: height } = drawing;
    let srcData: ImageData | undefined = undefined;

    if (!layer) throw new Error(`[PaintbucketTool] Missing layer`);
    throwIfTextLayer(layer);

    // TODO: fetch directly into WASM memory if using wasm+webgl
    switch (this.target) {
      case 'active': {
        srcData = this.editor.renderer.getLayerImageData(layer, drawing);
        break;
      }
      case 'above': {
        const above = getAboveLayer(drawing, layer);
        srcData = above && this.editor.renderer.getLayerImageData(above, drawing);
        break;
      }
      case 'below': {
        const below = getBelowLayer(drawing, layer);
        srcData = below && this.editor.renderer.getLayerImageData(below, drawing);
        break;
      }
      case 'all': {
        const flags = (this.mode === 'opacity' || this.mode === 'smart') ? DrawingDataFlags.NoBackground : 0;
        srcData = this.editor.renderer.getDrawingImageData(drawing, flags);
        break;
      }
      default:
        invalidEnum(this.target, 'Invalid target');
    }

    if (!rectContainsXY(drawing, x, y) || !srcData) {
      user.history.unpre();
      return;
    }

    // transform from abosulte document to document
    const hitX = Math.floor(x - drawing.x) | 0;
    const hitY = Math.floor(y - drawing.y) | 0;
    const o = (hitX + hitY * srcData.width) * 4;
    const hitColor = colorFromRGBA(srcData.data[o], srcData.data[o + 1], srcData.data[o + 2], srcData.data[o + 3]);
    const { color, opacity, tolerance } = this;

    // TODO: use wasm memory if using wasm+webgl
    const dstData = this.editor.renderer.createImageData(width, height, undefined);
    const floodStart = now();
    let rect: Rect;

    try {
      if (this.mode === 'color') {
        if (this.contiguous) {
          rect = floodFillContiguous(srcData.data, dstData.data, width, height, hitX, hitY, hitColor, color, tolerance);
        } else {
          rect = floodFill(srcData.data, dstData.data, width, height, hitColor, color, tolerance);
        }
      } else {
        const hitAlpha = getAlpha(hitColor);

        if (this.mode === 'smart') {
          rect = floodFillContiguousAlphaSmart(srcData.data, dstData.data, width, height, hitX, hitY, hitAlpha, color);
        } else {
          if (this.contiguous) {
            rect = floodFillContiguousAlpha(srcData.data, dstData.data, width, height, hitX, hitY, hitAlpha, color, tolerance);
          } else {
            rect = floodFillAlpha(srcData.data, dstData.data, width, height, hitAlpha, color, tolerance);
          }
        }
      }
    } catch (e) {
      // TEMP: We're getting stack buffer allocation failures here sometimes, might be algorithm error, or just low memory limits
      const info = `error: ${e.toString()}\nwidth: ${srcData.width}\nheight: ${srcData.height}\n` +
        `point: ${hitX}, ${hitY}\nmode: ${this.mode}\ncontiguous: ${this.contiguous}\ncolor: ${color}\n` +
        `tolerance: ${tolerance}`;
      const data = new Uint8Array(srcData.data.buffer, srcData.data.byteOffset, srcData.data.byteLength);
      this.model.errorWithData('Flood fill failed', info, data);
      throw e;
    }

    const floodEnd = now();

    if (isRectEmpty(rect)) { // this should never happen
      user.history.unpre();
      return;
    }

    if (this.fillMode === 'fillRest' || this.fillMode === 'clearRest') {
      const tr = getR(color);
      const tg = getG(color);
      const tb = getB(color);

      // TODO: move to wasm ?
      for (let i = 0; i < dstData.data.byteLength; i += 4) {
        dstData.data[i + 0] = tr;
        dstData.data[i + 1] = tg;
        dstData.data[i + 2] = tb;
        dstData.data[i + 3] = 255 - dstData.data[i + 3];
      }
      // use relative coords here, later rect will be transfomed to abosulte coords
      setRect(rect, 0, 0, drawing.w, drawing.h);
    }

    const antialiasStart = now();
    if (this.antiAlias) {
      // TODO: wasm
      antialiasFloodFill(dstData.data, dstData.width, dstData.height, color, rect);
    }
    const antialiasEnd = now();

    const compressStart = now();
    const data = compressImageAlphaRLEWasm(dstData, rect);
    const compressEnd = now();

    if (data.byteLength === 0) throw new Error(`[PaintbucketTool] data.byteLength === 0`);

    if (DEVELOPMENT && !TESTS) {
      console.log(`length: ${data.byteLength} b, ` +
        `flood: ${(floodEnd - floodStart).toFixed(2)} ms, ` +
        `antialias: ${(antialiasEnd - antialiasStart).toFixed(2)} ms, ` +
        `compress: ${(compressEnd - compressStart).toFixed(2)} ms`);
    }

    const beforeRect = cloneRect(layer.rect);
    // crop image data here to have only "rect" data
    // TODO move it before all other operations to optimize fill (before rle and maybe anti alias)
    const croppped = this.editor.renderer.createImageData(rect.w, rect.h, cropImageData(dstData, rect));
    documentToAbsoluteDocumentRect(rect, this.model.drawing);
    this.editor.renderer.putImageData(user, croppped, rect);

    const fill = this.fillMode === 'fill' || this.fillMode === 'fillRest';
    this.commit(opacity, rect, fill);

    const { target, mode, fillMode, contiguous } = this;
    // TODO: show progress indicator if this takes too long
    this.model.doToolWithData<IPaintbucketToolData>(layer.id, {
      id: ToolId.Paintbucket, color, opacity, rect, fill, target, mode, fillMode, contiguous,
      br: beforeRect, ar: cloneRect(layer.rect), bounds: cloneRect(this.drawingBounds)
    }, data).catch(e => DEVELOPMENT && console.error(e));
  }
  private commit(opacity: number, rect: Rect, fill: boolean) {
    if (!this.layer) throw new Error(`[PaintbucketTool] Missing layer`);

    const layer = this.layer;
    const { user, drawing } = this.model;
    const surface = user.surface;

    setupSurface(surface, this.id, fill ? CompositeOp.Draw : CompositeOp.Erase, layer, this.drawingBounds);
    copyRect(surface.rect, rect);

    surface.opacity = opacity;

    user.history.pushDirtyRect('paintbucket', layer.id, surface.rect);
    this.editor.renderer.commitTool(user, layer.opacityLocked);
    redrawLayer(drawing, layer, rect);
  }
}
