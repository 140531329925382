import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { JamVisibility, JamDrawing, LiveJam } from '../../../common/interfaces-jams';
import { copyText } from '../../../common/utils';
import { JamsService } from '../../../services/jams.service';
import { Modals } from '../../../services/modals';
import { ToastService } from '../../../services/toast.service';
import { artJamsReadOnlyIcon, faEye, faEyeSlash, faLink, faSignOutAlt, faTrash, farCommentAltExclamation, farEdit, farInfoCircle } from '../../../common/icons';
import { Analytics } from '../../../common/interfaces';
import { ManageService } from '../../../services/manageService';
import { ITrackService } from '../../../services/track.service.interface';
import { JamAnalyticsPayload, createJamAnalyticsData } from '../../../common/analytics';

@UntilDestroy()
@Component({
  selector: 'jam-context-menu',
  templateUrl: './jam-context-menu.pug',
  styleUrls: ['./jam-context-menu.scss'],
})
export class JamContextMenuComponent {
  JamVisibility = JamVisibility;
  readonly infoIcon = farInfoCircle;
  readonly editIcon = farEdit;
  readonly leaveIcon = faSignOutAlt;
  readonly reportIcon = farCommentAltExclamation;
  readonly linkIcon = faLink;
  readonly archiveIcon = artJamsReadOnlyIcon;

  readonly visibleIcon = faEye;
  readonly hiddenIcon = faEyeSlash;
  readonly deleteIcon = faTrash;

  @Input() jamDrawing!: JamDrawing;
  @Input() index!: number;
  @Input() fromEditor = false;
  @Output() onChanged = new EventEmitter();
  @Output() onRemoved = new EventEmitter<string>();

  liveJamData: LiveJam | undefined;
  connectedUsers = new Set();

  copied = false;

  constructor(
    private jamsService: JamsService,
    private modalService: Modals,
    private router: Router,
    private toastService: ToastService,
    private track: ITrackService,
    private manage: ManageService
  ) {
  }

  private getLocationForAnalytics() {
    return this.router.url;
  }

  get userId() {
    return this.manage.userId();
  }

  get isUserAnonymous() {
    return this.manage.isAnonymous();
  }

  get isUserVerified() {
    return this.manage.isUserVerified();
  }

  async joinJam() {
    this.track.event<JamAnalyticsPayload>(Analytics.ViewArtJam, createJamAnalyticsData(this.jamDrawing, {
      from: this.getLocationForAnalytics(),
      indexOnTheList: this.index,
      anonymous: this.isUserAnonymous,
      verified: this.isUserVerified,
    }));

    void this.modalService.joinJam({ jamDrawing: this.jamDrawing, indexOnTheList: this.index, fromEditor: this.fromEditor });
  }

  async editJam() {
    const updatedJam = await this.modalService.editJam({ jamDrawing: this.jamDrawing });
    if (updatedJam) {
      try {
        await this.jamsService.editJam(this.jamDrawing.shortId, updatedJam, 'modal');
      } catch (e) {
        this.toastService.error({ message: 'Failed to update Art Jam', subtitle: e.message });
      }
    }
  }

  async leaveJam() {
    try {
      await this.jamsService.leave(this.jamDrawing.shortId, this.getLocationForAnalytics());
      this.onChanged.emit();
      if (this.fromEditor) {
        void this.router.navigate(['/art-jams']);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to leave Art Jam', subtitle: e.message });
    }
  }

  hasJoined() {
    return this.userId && this.jamDrawing.jam.users.some(userId => userId === this.userId);
  }

  isOwner() {
    return this.userId && this.jamDrawing.author?._id === this.userId;
  }

  reportJam() {
    void this.modalService.createUserReport({ drawing: this.jamDrawing.shortId });
  }

  async deleteJam() {
    await this.manage.removeDrawing(this.jamDrawing._id, this.jamDrawing.name);
    this.onChanged.emit();
  }

  async updateVisibiliy(visibility: JamVisibility.HIDDEN | JamVisibility.PUBLIC) {
    try {
      await this.jamsService.editJam(this.jamDrawing.shortId, { jam: { visibility } }, 'dropdown');
    } catch (e) {
      this.toastService.error({ message: 'Failed to update Art Jam', subtitle: e.message });
    }
  }

  async copyLink(event: MouseEvent) {
    event.stopPropagation();
    await copyText(`${window.location.origin}/d/${this.jamDrawing.shortId}`);
    this.track.event<JamAnalyticsPayload>(Analytics.CopyArtJamlink, createJamAnalyticsData(this.jamDrawing, { from: this.getLocationForAnalytics() }));
    this.copied = true;
    setTimeout(() => this.copied = false, 3000);
  }

  async unarchive() {
    try {
      await this.jamsService.unarchive(this.jamDrawing.shortId);
      if (this.fromEditor) {
        location.reload();
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to restore Art Jam', subtitle: e.message });
    }
  }
}
