import { ISelectionHelperTool, IToolEditor, IToolModel, IToolData, ToolId, SelectionMode, Mask, Rect } from '../interfaces';
import { invalidEnum } from '../baseUtils';
import { deserializeMask, invertMask, addRectToMask, clearMask, addPolyToMask, moveMask, serializeMask } from '../mask';
import { logAction } from '../actionLog';
import { redraw, redrawDrawing } from '../../services/editorUtils';
import { createPolyOutlineFromBitmap } from '../polyUtils';
import { finishTransform } from '../toolUtils';
import { cloneRect } from '../rect';

export enum SelectionHelperAction {
  DeselectAll,
  SelectAll,
  InvertSelection,
  FillSelection,
  InitSelection,
  MoveSelection,
}

export interface IData extends IToolData {
  action: SelectionHelperAction;
  selectionData?: number[];
  dx?: number;
  dy?: number;
}

export class SelectionHelperTool implements ISelectionHelperTool {
  id = ToolId.SelectionHelper;
  name = '';
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  do(data: IData) {
    switch (data.action) {
      case SelectionHelperAction.DeselectAll: this.deselectAll(true); break;
      case SelectionHelperAction.SelectAll: this.selectAll(data.bounds, true); break;
      case SelectionHelperAction.InvertSelection: this.invertSelection(data.bounds, true); break;
      case SelectionHelperAction.FillSelection: this.fillSelection(true); break;
      case SelectionHelperAction.InitSelection: this.initSelection(data.selectionData, true); break;
      case SelectionHelperAction.MoveSelection: this.moveSelection(data.dx ?? 0, data.dy ?? 0, true); break;
      default: invalidEnum(data.action, `invalid selection helper action`);
    }
  }
  deselectAll(remote = false) {
    const { user } = this.model;
    logAction(`[${remote ? 'remote' : 'local'}] deselectAll (clientId: ${user.localId})`);

    finishTransform(this.model, 'deselectAll');
    user.history.pushSelection('deselectAll');
    clearMask(user.selection);
    this.model.doTool<IData>(0, { id: this.id, action: SelectionHelperAction.DeselectAll, selection: SelectionMode.Break });
    redraw(this.editor);
  }
  selectAll(bounds?: Rect, remote = false) {
    const { user, drawing } = this.model;
    logAction(`[${remote ? 'remote' : 'local'}] selectAll (clientId: ${user.localId})`);

    finishTransform(this.model, 'selectAll');
    user.history.pushSelection('selectAll');
    clearMask(user.selection);
    const selectionBounds = bounds ? bounds : drawing;
    addRectToMask(user.selection, selectionBounds);
    this.model.doTool<IData>(0, { id: this.id, action: SelectionHelperAction.SelectAll, selection: SelectionMode.Break, bounds: cloneRect(selectionBounds) });
    redraw(this.editor);
  }
  select(selection: Mask, remote = false) {
    const selectionData = serializeMask(selection);
    const { user } = this.model;
    logAction(`[${remote ? 'remote' : 'local'}] select (clientId: ${user.localId})`);

    finishTransform(this.model, 'select');
    user.history.pushSelection('select');
    this.model.doTool<IData>(0, { id: this.id, action: SelectionHelperAction.InitSelection, selection: SelectionMode.Break, selectionData });
    redraw(this.editor);
  }
  invertSelection(bounds?: Rect, remote = false) {
    const { user, drawing } = this.model;
    logAction(`[${remote ? 'remote' : 'local'}] invertSelection (clientId: ${user.localId})`);

    finishTransform(this.model, 'invertSelection');
    user.history.pushSelection('invertSelection');

    const selectionBounds = bounds ? bounds : drawing;
    invertMask(user.selection, selectionBounds);
    this.model.doTool<IData>(0, { id: this.id, action: SelectionHelperAction.InvertSelection, selection: SelectionMode.Update, bounds: cloneRect(selectionBounds) });
    redraw(this.editor);
  }
  initSelection(data: number[] | undefined, remote = false) {
    const { user } = this.model;
    logAction(`[${remote ? 'remote' : 'local'}] initSelection (clientId: ${user.localId})`);

    finishTransform(this.model, 'initSelection');
    // only for initializing user selection
    deserializeMask(user.selection, data);
    redraw(this.editor);
  }
  selectionFromLayer() {
    const { user, drawing } = this.model;
    logAction(`[local] selectionFromLayer (clientId: ${user.localId})`);

    finishTransform(this.model, 'selectionFromLayer');

    user.history.pushSelection('selectionFromLayer');

    if (!user.activeLayer) throw new Error(`[selectionFromLayer] No activeLayer`);

    // logAction(`[${remote ? 'remote' : 'local'}] invertSelection (clientId: ${this.model.user.localId})`);
    // this.model.doTool<IData>(0, { id: this.id, action: SelectionHelperAction.InvertSelection, selection: SelectionMode.Update });

    // TODO: maybe use raw data here instead, and then shift final selection by layer.rect.x/y
    const bitmap = this.editor.renderer.getLayerImageData(user.activeLayer, drawing);
    const bounds = user.activeLayer.rect;

    clearMask(user.selection);
    addPolyToMask(user.selection, createPolyOutlineFromBitmap(bitmap, bounds));
    // TODO: we need to send mask that we generated otherwise we can have discrepancies
    redraw(this.editor);
  }
  moveSelection(dx: number, dy: number, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] moveSelection (clientId: ${this.model.user.localId})`);

    finishTransform(this.model, 'moveSelection');
    this.model.user.history.pushSelection('moveSelection');
    moveMask(this.model.user.selection, dx, dy);
    this.model.doTool<IData>(0, { id: this.id, action: SelectionHelperAction.MoveSelection, selection: SelectionMode.Update, dx, dy });
    redraw(this.editor);
  }
  // helper method for testing selection
  fillSelection(remote = false) {
    const { user, drawing } = this.model;
    logAction(`[${remote ? 'remote' : 'local'}] fillSelection (clientId: ${user.localId})`);

    if (!user.activeLayer) throw new Error(`[fillSelection] No activeLayer`);

    finishTransform(this.model, 'fillSelection');
    this.editor.renderer.fillSelection(user.activeLayer, drawing);
    this.model.doTool<IData>(user.activeLayer.id, { id: this.id, action: SelectionHelperAction.FillSelection });
    redrawDrawing(drawing);
  }
}
