import { cloneDeep } from 'lodash';
import { ILayerTool, IToolEditor, IToolModel, LayerData, IToolData as ToolData, Layer, ToolId, CopyMode, Rect, LayerFlag } from '../interfaces';
import { getLayerSafe, getLayersOwnedByAccount } from '../drawing';
import { LAYER_NAME_LENGTH_LIMIT } from '../constants';
import { toLayerState, layerFromState, layerChanged, redrawLayerThumb, validateLayerData, isTextLayer, getLayerName } from '../layer';
import { cloneRect, createRect, rectsIntersection, safeRect } from '../rect';
import { logAction } from '../actionLog';
import { invalidEnum } from '../baseUtils';
import { redrawDrawing } from '../../services/editorUtils';
import { getLayerRect, isLayerEmpty } from '../layerUtils';
import { isMaskEmpty } from '../mask';
import { addLayerToDrawing, removeLayerFromDrawing } from '../layerToolHelpers';
import { finishTransform, getLayerAfterRect, safeOpacity } from '../toolUtils';
import { sendLayerOrder } from '../../services/drawingConnection';
import { createCopyName } from '../utils';

export enum Action {
  Clear = 0,
  Transfer = 1,
  Add = 2,
  Remove = 3,
  // 4
  Duplicate = 5,
  Copy = 6,
  Cut = 7,
  Merge = 8,
  Trim = 9,
}

// @ignore-translation
export const ActionNames = ['clear', 'transfer', 'add', 'remove', '', 'duplicate', 'copy', 'cut', 'merge', 'trim'];

export interface LayerToolData extends ToolData {
  action: Action;
  index?: number;
  layer?: LayerData;
  rect?: Rect;
  clip?: boolean;
  opacity?: number;
  mode?: string;
  auto?: boolean;
  analytics: {
    isTextLayer: boolean;
    layersOwned: number;
    layersTotal: number;
  }
}

export class LayerTool implements ILayerTool {
  id = ToolId.Layer;
  name = '';
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  do(data: LayerToolData, _binaryData?: Uint8Array, debugInfo?: string) {
    const action = data.action;
    const layerId = () => {
      if (!this.model.user.activeLayer) throw new Error(`[LayerTool] Missing activeLayer (action: ${Action[action]}, ${debugInfo})`);
      return this.model.user.activeLayer.id;
    };

    const otherLayerId = () => {
      if (!data.otherLayerIds?.[0]) throw new Error(`[LayerTool] Missing otherLayerId (${debugInfo})`);
      return data.otherLayerIds[0];
    };

    const bounds = () => {
      if (!data.bounds) throw new Error(`[LayerTool] Missing bounds (${debugInfo})`);
      return data.bounds;
    };

    switch (action) {
      case Action.Clear:
        return this.clear(layerId(), true);
      case Action.Transfer:
        return this.transfer(layerId(), otherLayerId(), safeOpacity(data.opacity ?? 1), !!data.clip, bounds(), true);
      case Action.Add:
        validateLayerData(data.layer!);
        return this.add(data.layer!, data.index! | 0, !!data.auto, true);
      case Action.Remove:
        return this.remove(layerId(), true);
      case Action.Duplicate:
        return this.duplicate(layerId(), otherLayerId(), bounds(), true);
      case Action.Copy:
        return this.copy(layerId(), otherLayerId(), bounds(), true);
      case Action.Cut:
        return this.cut(layerId(), otherLayerId(), bounds(), true);
      case Action.Merge:
        return this.merge(layerId(), otherLayerId(), safeOpacity(data.opacity ?? 1), !!data.clip, bounds(), true);
      case Action.Trim:
        return this.trim(layerId(), safeRect(data.rect!));
      default:
        invalidEnum(action, `[LayerTool] Invalid layer action`);
    }
  }
  clear(layerId: number, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] clear layer (${this.info()}, layerId: ${layerId})`);

    finishTransform(this.model, 'LayerTool:clear');
    const { user, drawing } = this.model;
    const layer = getLayerSafe(drawing, layerId);
    const analytics = this.getAnalyticsData(layer);
    redrawDrawing(drawing, layer.rect); // before we reset layer.rect

    user.history.pushDirtyRect('clear layer', layerId, layer.rect);
    layer.flags = LayerFlag.None;
    this.editor.renderer.releaseLayer(layer);
    redrawLayerThumb(layer, true);
    this.model.doTool<LayerToolData>(layerId, { id: this.id, action: Action.Clear, ar: cloneRect(layer.rect), analytics });
  }
  // assumes `otherLayer` is directly below `layer`
  transfer(layerId: number, otherLayerId: number, opacity: number, clip: boolean, drawingBounds: Rect, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] transfer layer (${this.info()}, layerId: ${layerId}, otherLayerId: ${otherLayerId})`);

    finishTransform(this.model, 'LayerTool:transfer');
    const { user, drawing } = this.model;
    const layer = getLayerSafe(drawing, layerId);
    const analytics = this.getAnalyticsData(layer);
    const otherLayer = getLayerSafe(drawing, otherLayerId);
    const br = cloneRect(layer.rect);
    const br2 = cloneRect(otherLayer.rect);

    user.history.execTransaction(history => {
      history.pushDirtyRect('transfer layer', layerId, br);
      history.pushDirtyRect('transfer layer', otherLayerId, br2);
      history.pushLayerState(layerId);
      history.pushLayerState(otherLayerId);
    });

    try {
      layer.opacity = opacity;
      this.editor.renderer.mergeLayers(drawingBounds, layer, otherLayer, clip);
      otherLayer.flags = otherLayer.flags | layer.flags;
      layer.flags = LayerFlag.None;
    } catch (e) {
      user.history.cancelLastUndo();
      throw e;
    }

    redrawDrawing(drawing);

    this.model.doTool<LayerToolData>(layerId, {
      id: this.id, action: Action.Transfer, otherLayerIds: [otherLayerId], opacity, clip, mode: layer.mode,
      inf: `layer: ${layerInfo(layer)}, otherLayer: ${layerInfo(otherLayer)}`,
      br, ar: getLayerAfterRect(layer), br2, ar2: cloneRect(otherLayer.rect),
      bounds: cloneRect(drawingBounds),
      analytics,
    });
  }
  // assumes `otherLayer` is directly below `layer`
  merge(layerId: number, otherLayerId: number, opacity: number, clip: boolean, drawingBounds: Rect, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] merge layer (${this.info()}, layerId: ${layerId}, otherLayerId: ${otherLayerId})`);

    finishTransform(this.model, 'LayerTool:merge');
    const { user, drawing } = this.model;
    const layer = getLayerSafe(drawing, layerId);
    const analytics = this.getAnalyticsData(layer);
    const otherLayer = getLayerSafe(drawing, otherLayerId);
    const br = cloneRect(layer.rect);
    const br2 = cloneRect(otherLayer.rect);

    if (user.surface.layer === layer) {
      if (DEVELOPMENT) throw new Error('User surface is attached to removed layer (merge)');
      logAction('warning: User surface is attached to removed layer (merge)');
    }

    user.history.execTransaction(history => {
      history.pushDirtyRect('merge layer', layerId, br);
      history.pushDirtyRect('merge layer', otherLayerId, br2);
      history.pushLayerState(otherLayerId);
      history.pushRemoveLayer(toLayerState(layer), drawing.layers.indexOf(layer));
    });

    try {
      layer.opacity = opacity;
      this.editor.renderer.mergeLayers(drawingBounds, layer, otherLayer, clip);
      otherLayer.flags = otherLayer.flags | layer.flags;
    } catch (e) {
      user.history.cancelLastUndo();
      throw e;
    }

    removeLayerFromDrawing(this.editor, user, drawing, layer.id);
    redrawDrawing(drawing);

    this.model.doTool<LayerToolData>(layerId, {
      id: this.id, action: Action.Merge, otherLayerIds: [otherLayerId], opacity, clip, mode: layer.mode,
      inf: `layer: ${layerInfo(layer)}, otherLayer: ${layerInfo(otherLayer)}`,
      br, ar: getLayerAfterRect(layer), br2, ar2: cloneRect(otherLayer.rect),
      bounds: cloneRect(drawingBounds),
      analytics
    });
  }
  remove(layerId: number, remote = false) {
    const { user, drawing } = this.model;
    logAction(`[${remote ? 'remote' : 'local'}] remove layer (${this.info()}, layerId: ${layerId}) [${drawing.layers.map(l => l.id).join(', ')}]`);

    finishTransform(this.model, 'LayerTool:remove');
    const layer = getLayerSafe(drawing, layerId);
    const analytics = this.getAnalyticsData(layer);

    if (user.surface.layer === layer) {
      if (DEVELOPMENT) throw new Error('User surface is attached to removed layer');
      logAction('warning: User surface is attached to removed layer');
    }

    user.history.execTransaction(history => {
      history.pushDirtyRect('removeLayer', layer.id, layer.rect);
      history.pushRemoveLayer(toLayerState(layer), drawing.layers.indexOf(layer));
    });

    redrawDrawing(drawing); // redraw whole drawing in case the empty layer had clipped layer on top of it
    removeLayerFromDrawing(this.editor, user, drawing, layer.id);

    const opts: LayerToolData = { id: this.id, action: Action.Remove, ar: getLayerAfterRect(layer), analytics };
    this.model.doTool<LayerToolData>(layerId, opts);
  }
  add(layerData: LayerData, index: number, auto: boolean, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] add layer (${this.info()}, layerId: ${layerData.id}, name: ${layerData.name})`);

    finishTransform(this.model, 'LayerTool:add');
    const { drawing, user } = this.model;
    user.history.pushAddLayer(layerData, index, auto);

    const layer = layerFromState(layerData, true);
    const analytics = this.getAnalyticsData(layer);
    addLayerToDrawing(this.editor, user, drawing, layer, index);
    layer.owner = user;

    redrawDrawing(drawing); // needed in case we're adding layer below clipped layer, which can result in entire drawing changing

    const tool: LayerToolData = { id: this.id, action: Action.Add, layer: layerData, index, ar: createRect(0, 0, 0, 0), analytics };
    if (auto) tool.auto = true;
    this.model.doTool(layerData.id, tool);

    if (!remote) sendLayerOrder(user, drawing);
  }
  duplicate(layerId: number, newLayerId: number, drawingBounds: Rect, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] duplicate layer (${this.info()}, layerId: ${layerId}, newId: ${newLayerId})`);

    finishTransform(this.model, 'LayerTool:duplicate');
    const { drawing, user } = this.model;
    const layer = getLayerSafe(drawing, layerId);
    const analytics = this.getAnalyticsData(layer);
    const index = drawing.layers.indexOf(layer);
    const layerName = getNewCopyName(layer, drawing.layers);
    const newLayerData = cloneLayerState(layer, newLayerId, layerName);
    const newLayer = layerFromState(newLayerData);
    let pushedHistory = false;

    addLayerToDrawing(this.editor, user, drawing, newLayer, index);

    try {
      user.history.execTransaction(history => {
        history.pushLayerId('duplicate layer', layerId);
        history.pushAddLayer(newLayerData, index);
        history.pushDirtyRect('duplicate layer', newLayerId, layer.rect);
      });
      pushedHistory = true;

      this.editor.renderer.copyLayer(layer, newLayer, undefined, CopyMode.Copy, drawingBounds);
    } catch (e) {
      // remove layer in case of failure (out of memory)
      if (pushedHistory) user.history.cancelLastUndo();
      removeLayerFromDrawing(this.editor, user, drawing, newLayerId);
      throw e;
    }

    layerChanged(newLayer);

    redrawDrawing(drawing, getLayerRect(newLayer));
    this.model.doTool<LayerToolData>(layerId, {
      id: this.id,
      action: Action.Duplicate,
      otherLayerIds: [newLayerId],
      bounds: cloneRect(drawingBounds),
      ar: getLayerAfterRect(layer), ar2: getLayerAfterRect(newLayer),
      analytics
    });
    if (!remote) sendLayerOrder(user, drawing);
  }
  copy(layerId: number, newLayerId: number, drawingBounds: Rect, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] layer:copy (${this.info()}, layerId: ${layerId}, newId: ${newLayerId})`);

    finishTransform(this.model, 'LayerTool:copy');
    const { drawing, user } = this.model;
    const layer = getLayerSafe(drawing, layerId);
    const analytics = this.getAnalyticsData(layer);
    const selection = user.selection;
    const index = drawing.layers.indexOf(layer);
    const layerName = getNewCopyName(layer, drawing.layers);
    const newLayerData = cloneLayerState(layer, newLayerId, layerName);
    const newLayer = layerFromState(newLayerData);
    let pushedHistory = false;

    if (isTextLayer(layer) && isTextLayer(newLayer)) {
      (newLayer as Layer).textData = undefined;
      newLayer.textarea = undefined;
    }

    addLayerToDrawing(this.editor, user, drawing, newLayer, index);

    try {
      user.history.execTransaction(history => {
        history.pushLayerId('copy layer', layerId);
        history.pushAddLayer(newLayerData, index);
        history.pushDirtyRect('copy layer', newLayerId, layer.rect);
      });
      pushedHistory = true;

      this.editor.renderer.copyLayer(layer, newLayer, isMaskEmpty(selection) ? undefined : selection, CopyMode.Copy, drawingBounds);
    } catch (e) {
      // remove layer in case of failure (out of memory)
      if (pushedHistory) this.model.user.history.cancelLastUndo();
      removeLayerFromDrawing(this.editor, user, drawing, newLayerId);
      throw e;
    }

    layerChanged(newLayer);

    if (isLayerEmpty(newLayer)) newLayer.flags = LayerFlag.None;

    redrawDrawing(drawing, getLayerRect(newLayer));
    this.model.doTool<LayerToolData>(layerId, {
      id: this.id,
      action: Action.Copy,
      otherLayerIds: [newLayerId],
      bounds: cloneRect(drawingBounds),
      ar: getLayerAfterRect(layer),
      ar2: getLayerAfterRect(newLayer),
      analytics,
    });
    if (!remote) sendLayerOrder(user, drawing);
  }
  cut(layerId: number, newLayerId: number, drawingBounds: Rect, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] layer:cut (${this.info()}, layerId: ${layerId}, newId: ${newLayerId})`);

    const { drawing, user } = this.model;

    finishTransform(this.model, 'LayerTool:cut');
    const layer = getLayerSafe(drawing, layerId);
    const analytics = this.getAnalyticsData(layer);
    const selection = user.selection;
    const index = drawing.layers.indexOf(layer);
    const layerName = getNewCopyName(layer, drawing.layers);
    const newLayerData = cloneLayerState(layer, newLayerId, layerName);
    const newLayer = layerFromState(newLayerData);
    let pushedHistory = false;

    addLayerToDrawing(this.editor, user, drawing, newLayer, index);

    try {
      user.history.execTransaction(history => {
        history.pushAddLayer(newLayerData, index);
        history.pushDirtyRect('cut layer', newLayerId, selection.bounds);
        history.pushDirtyRect('cut layer', layerId, layer.rect);
        history.pushLayerState(layerId); // TODO do it only when current layer will be cut to new layer
      });
      pushedHistory = true;

      this.editor.renderer.copyLayer(layer, newLayer, selection, CopyMode.Cut, drawingBounds);
    } catch (e) {
      // remove layer in case of failure (out of memory)
      if (pushedHistory) user.history.cancelLastUndo();
      removeLayerFromDrawing(this.editor, user, drawing, newLayerId);
      throw e;
    }

    redrawDrawing(drawing, getLayerRect(newLayer));

    if (isLayerEmpty(layer)) layer.flags = LayerFlag.None;
    if (isLayerEmpty(newLayer)) newLayer.flags = LayerFlag.None;

    this.model.doTool<LayerToolData>(layerId, {
      id: this.id,
      action: Action.Cut,
      otherLayerIds: [newLayerId],
      bounds: cloneRect(drawingBounds),
      ar: getLayerAfterRect(layer), ar2: getLayerAfterRect(newLayer),
      analytics
    });
    if (!remote) sendLayerOrder(user, drawing);
  }
  trim(layerId: number, rect: Rect): void {
    const { drawing, user } = this.model;
    const layer = getLayerSafe(drawing, layerId);
    const analytics = this.getAnalyticsData(layer);

    finishTransform(this.model, 'trimLayer');

    user.history.pushDirtyRect('trimLayer', layer.id, layer.rect);

    const br = cloneRect(layer.rect);
    const r = rectsIntersection(layer.rect, rect);
    this.editor.renderer.trimLayer(layer, r);

    this.model.doTool<LayerToolData>(layerId, {
      id: this.id, action: Action.Trim,
      rect,
      br, ar: cloneRect(layer.rect),
      analytics
    });
  }
  private info() {
    return `clientId: ${this.model.user.localId}`;
  }
  private getAnalyticsData(sourceLayer: Layer): LayerToolData['analytics'] {
    return {
      isTextLayer: isTextLayer(sourceLayer),
      layersOwned: getLayersOwnedByAccount(this.model.drawing, this.model.user.accountId).length,
      layersTotal: this.model.drawing.layers.length,
    };
  }
}

function cloneLayerState(layer: Layer, newId: number, newLayerName: string) {
  const state = toLayerState(layer);
  state.id = newId;
  state.name = newLayerName;
  if (layer.textData) state.textData = cloneDeep(layer.textData);
  delete state.rect;
  delete state.image;
  return state;
}

function layerInfo({ id, opacity, opacityLocked, clippingGroup, visible, locked }: Layer) {
  return JSON.stringify({ id, opacity, opacityLocked, clippingGroup, visible, locked });
}

function getNewCopyName(layer: Layer, allLayers: Layer[]) {
  return createCopyName(getLayerName(layer), allLayers.map(getLayerName), LAYER_NAME_LENGTH_LIMIT);
}
