import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { upperFirst } from 'lodash';
import { PasswordData, SharedImageResponse, ShareResponse } from 'shared/responses';
import { BaseFlowchartData, EntityData, FlowchartData, FlowchartListData, StorageUsageData, UserData, EntitiesCount } from 'shared/interfaces';
import { DEFAULT_FOLDER_NAME, DEFAULT_FLOWCHART_STYLE_OPTIONS, DEFAULT_FLOWCHART_NAME } from 'shared/constants';
import { getDefaultName } from 'shared/product-info';
import { getTypeName, handleHttpError, toPromise } from 'shared/utils';
import { EntitiesStore, SharedEntitiesStore } from './entities.store';
import { ProjectQuery } from './projects.query';
import { RecentEntitiesQuery } from './recent-entities/recent-entities.query';
import { RecentEntitiesStore } from './recent-entities/recent-entities.store';
import { TeamsQuery } from './team.query';
import { ToastService } from 'magma/services/toast.service';
import { verifyFileForImport } from 'magma/common/drawingUtils';
import { RecentParticipationService } from './recent-participation.service';
import { RouterService } from './router.service';
import { ProjectService } from './projects.service';
import { UserService } from './user.service';
import { SECOND } from 'magma/common/constants';
import { TeamsStore } from './team.store';
import { MAX_ENTITY_NAME_LENGTH } from 'magma/common/constants';
import { ImageTypeNames } from 'magma/common/imageUtils';
import { ITrackService } from 'magma/services/track.service.interface';
import { Analytics, CreateEntityData, EntityType, ImportEntityData } from 'magma/common/interfaces';
import { ImageInfo } from 'magma/common/imageUtils';
import { getAllows } from 'magma/common/userUtils';
import { FilesService } from './files.service';
import { ImportCreateDrawingEvent } from 'magma/common/analytics';
import { TeamService } from './team.service';
import { createTranslate, nonTranslatable, translateString } from 'magma/common/i18n';

const tr = createTranslate();

@Injectable({ providedIn: 'root' })
export class EntitiesService {
  constructor(
    private httpClient: HttpClient,
    private entitiesStore: EntitiesStore,
    private projectQuery: ProjectQuery,
    private sharedFlowchartStore: SharedEntitiesStore,
    private recentEntitiesQuery: RecentEntitiesQuery,
    private recentEntitiesStore: RecentEntitiesStore,
    private recentParticipationService: RecentParticipationService,
    private teamsQuery: TeamsQuery,
    private toastService: ToastService,
    private routerService: RouterService,
    private projectService: ProjectService,
    private userService: UserService,
    private teamStore: TeamsStore,
    private teamService: TeamService,
    private tracker: ITrackService,
    private files: FilesService
  ) { }

  getAll(folderId?: string, isBinFolder = false) {
    return this.httpClient.get<FlowchartListData[]>(`/api/entities?bin=${isBinFolder}${folderId ? `&folder=${folderId}&bumpOpenedAt=1` : ``}`)
      .pipe(
        tap(data => {
          if (data) { // `data` was null for some reason
            data.forEach(entity => entity.name = entity.name || getDefaultName(entity.type));
            this.entitiesStore.set(data);
          }
        }),
        handleHttpError(),
      );
  }

  getAllEntities() {
    return this.httpClient.get<FlowchartListData[]>('/api/entities?all=true')
      .pipe(handleHttpError());
  }

  sharedWithMe() {
    return this.httpClient.get<FlowchartListData[]>('/api/entities/sharedWithMe')
      .pipe(
        tap(data => this.sharedFlowchartStore.set(data)),
        handleHttpError(),
      );
  }

  get(id: string, isBinFolder = false) {
    return this.httpClient.get<EntityData>(`/api/entities/${id}?bin=${isBinFolder}`)
      .pipe(handleHttpError());
  }

  getById(id: string) {
    if (id.length > 12) { return this.get(id); }
    return this.httpClient.get<FlowchartData>(`${id}.json`);
  }

  getEntitiesCount(teamId?: string) {
    const path = !teamId ? `/api/entities/count` : `/api/teams/${teamId}/entities/count`;
    return this.httpClient.get<EntitiesCount>(path).pipe(handleHttpError());
  }

  remove(id: string, projectId?: string, permanentDelete = false) {
    return this.httpClient.delete<string[]>(`/api/entities/${id}?permanent=${permanentDelete}`)
      .pipe(tap((eds) => {
        eds.forEach(id => this.recentEntitiesStore.remove(re => re.entity._id === id));

        const team = this.projectService.getProject(projectId)?.team;
        this.refreshStorageUsage(team);

        if (projectId) {
          this.projectService.removeEntityFromProject(id, projectId);
        } else {
          this.entitiesStore.remove(id);
        }
      }));
  }

  async removeWithToast(entity: EntityData, projectId?: string) {
    const typeName = getTypeName(entity.type);

    try {
      await toPromise(this.remove(entity._id, projectId));
      this.refreshStorageUsage(entity.team);
      this.toastService.success({ message: upperFirst(tr`${translateString(typeName)} "${nonTranslatable(entity.name)}" has been deleted`) });
      return true;
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.toastService.error({ message: tr`Failed to delete ${translateString(typeName)}`, subtitle: e.message });
      return false;
    }
  }

  async moveToBinWithToast(entity: EntityData, projectId?: string) {
    const typeName = getTypeName(entity.type);

    try {
      await toPromise(this.remove(entity._id, projectId));
      this.toastService.success({ message: upperFirst(tr`${translateString(typeName)} "${nonTranslatable(entity.name)}" moved to bin`) });
      return true;
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.toastService.error({ message: tr`Failed to delete ${translateString(typeName)}`, subtitle: e.message });
      return false;
    }
  }

  async restoreEntity(entity: EntityData) {
    const typeName = getTypeName(entity.type);

    try {
      // TODO: there should be separate end point for restoring entities
      await toPromise(this.httpClient.put<FlowchartData>(`/api/entities/${entity._id}`, { isRemoved: false }));
      this.refreshStorageUsage(entity.team);
      this.toastService.success({ message: upperFirst(tr`${translateString(typeName)} "${nonTranslatable(entity.name)}" has been restored`) });
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.toastService.error({ message: tr`Failed to restore ${translateString(typeName)}`, subtitle: e.message });
    }
  }

  private async create(data: CreateEntityData, eventSource: string) {
    const entity = await toPromise(this.httpClient.post<EntityData>(`/api/entities`, data, { params: { eventSource } }));
    this.onEntityData(entity);
    this.refreshStorageUsage(data.team);
    return entity;
  }

  private async import(data: CreateEntityData & { sequence?: string; }, files: File[], eventSource: string) {
    const form = new FormData();
    form.set('type', data.type);
    form.set('name', data.name ?? '');

    // HACK: work around bug in deepkit ?
    if (files.length === 1) {
      form.set('file', files[0]);
    } else {
      for (const file of files) form.append('files', file);
    }

    if (data.project) form.set('project', data.project as string);
    if (data.folder) form.set('folder', data.folder as string);
    if (data.team) form.set('team', data.team as string);
    if (data.sequence) form.set('sequence', data.sequence);

    const entity = await toPromise(this.httpClient.post<EntityData>(`/api/entities/import`, form, { params: { eventSource } })) as EntityData;
    if (data.name != entity.name && entity.name.length === MAX_ENTITY_NAME_LENGTH && data.name.match(entity.name)) {
      this.toastService.warning({ message: 'Imported file name was trimmed to comply with our name limit.' });
    }
    this.onEntityData(entity);
    this.refreshStorageUsage(data.team);
    return entity;
  }


  async importRpc(data: ImportEntityData, files: Uint8Array[]) {
    const toast = this.toastService.loading({ message: tr`Importing file "${nonTranslatable(data.name)}"` });
    try {
      const entity = await this.files.importFiles(files, data, (current: number, total: number) => {
        this.toastService.update(toast, { subtitle: `${(current * 100 / total) | 0}%` });
      });

      if (data.name != entity.name && entity.name.length === MAX_ENTITY_NAME_LENGTH && data.name.match(entity.name)) {
        this.toastService.warning({ message: 'Imported file name was trimmed to comply with our name limit.' });
      }
      this.toastService.updateToSuccess(toast, { message: tr`Image "${nonTranslatable(data.name)}" has been imported`, subtitle: undefined });
      this.onEntityData(entity);
      this.refreshStorageUsage(data.team);
      return entity;
    } catch (e) {
      this.toastService.updateToError(toast, { message: tr`Failed to import "${nonTranslatable(data.name)}".`, subtitle: e.message });
      throw e;
    }
  }

  private onEntityData(data: EntityData) {
    if (data.project) {
      this.projectService.addEntityToProject(data);
    } else {
      this.entitiesStore.add(data);
    }
  }

  /**
   * @deprecated use \createNewEntity
   */
  async createNewFlowchart(project: string | undefined, folder: string | undefined, input: string, sourceForAnalytics: string) {
    const data: BaseFlowchartData = {
      name: DEFAULT_FLOWCHART_NAME,
      type: EntityType.Flowchart,
      input,
      diagramAppearanceOptions: DEFAULT_FLOWCHART_STYLE_OPTIONS,
      project,
      folder,
    };

    return await this.create(data, sourceForAnalytics);
  }

  async createNewEntity(type: EntityType, params: Omit<CreateEntityData, 'type'>, sourceForAnalytics: string) {
    return await this.create({ ...params, type }, sourceForAnalytics);
  }

  async createNewFolder(project: string | undefined, folder: string | undefined, folderName: string, sourceForAnalytics: string) {
    const name = folderName || DEFAULT_FOLDER_NAME;
    return await this.create({ type: EntityType.Folder, name, project, folder }, sourceForAnalytics);
  }

  update({ _id, input, diagramAppearanceOptions, name }: FlowchartData) {
    const updated: Partial<BaseFlowchartData> = { input, diagramAppearanceOptions, name };
    return this.httpClient.put<FlowchartData>(`/api/entities/${_id}`, updated);
  }

  async renameEntity(entity: EntityData, name: string) {
    await toPromise(this.httpClient.put(`/api/entities/${entity._id}`, { name }));

    if (entity.project) {
      this.projectService.renameEntityInProject(entity, name);
    } else {
      this.entitiesStore.update(entity._id, { ...entity, name });
    }
  }

  updateFlowchartState(data: FlowchartData) {
    if (data.project) {
      this.projectService.updateEntityInProject(data);
    } else {
      this.entitiesStore.update(data._id, data);
    }
  }

  saveAsShare(data: BaseFlowchartData) {
    const { input: text, diagramAppearanceOptions: options, name } = data;
    return toPromise(this.httpClient.post<ShareResponse>('/share', { type: EntityType.Flowchart, text, options, name }));
  }

  async emptyBin(team: string | undefined) {
    await toPromise(this.httpClient.delete<void>(`/api/entities/bin${team ? `?team=${team}` : ''}`));
  }

  async cloneEntity(entityId: string) {
    const clonedEntity = await toPromise(this.httpClient.post<EntityData>(`/api/entities/${entityId}/clone`, null));
    this.refreshStorageUsage(clonedEntity.team);

    if (clonedEntity.project) {
      this.projectService.addEntityToProject(clonedEntity);
    } else {
      this.entitiesStore.add(clonedEntity);
    }

    return clonedEntity;
  }

  async cloneEntityWithToast(entity: EntityData) {
    const toast = this.toastService.loading({ message: `Duplicating "${entity.name}"` });
    const typeName = getTypeName(entity.type);

    try {
      const clonedEntity = await this.cloneEntity(entity._id);
      this.toastService.updateToSuccess(toast, { message: upperFirst(tr`${translateString(typeName)} "${nonTranslatable(entity.name)}" has been duplicated`) });
      return clonedEntity;
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.toastService.updateToError(toast, { message: tr`Failed to duplicate ${translateString(typeName)}`, subtitle: e.message });
      return undefined;
    }
  }

  async importEntityWithToast(
    name: string, files: File[], folder: string | undefined, user: UserData, navigate = true,
    sequence: string | undefined = undefined,  // pass sequence='true' to create new sequence
  ): Promise<EntityData | undefined> {
    const imagesInfo = new Map<File, ImageInfo>();
    let currentFile = files[0];

    try {
      const projectData = this.projectQuery.getActive() ?? undefined;
      const project = projectData?._id;
      const team = projectData?.team;
      const teamData = team ? this.teamsQuery.getAll().find(t => t._id === team) : undefined;
      const allows = getAllows(user, teamData);

      for (const file of files) {
        currentFile = file;
        imagesInfo.set(file, (await verifyFileForImport(file, allows)).imageInfo);
      }

      const arrayBuffers = await Promise.all(files.map(file => file.arrayBuffer()));
      const filesData = arrayBuffers.map(buffer => new Uint8Array(buffer));
      const entity = await this.importRpc({ type: EntityType.Drawing, name, folder, project, team, sequence, eventSource: 'my-drawer' }, filesData);

      if (navigate) this.routerService.navigateToEntity(entity, 'import');

      return entity;
    } catch (e) {
      const imageInfo = imagesInfo.get(currentFile);
      this.tracker.event<ImportCreateDrawingEvent>(Analytics.CreateDrawing, {
        drawingId: '',
        method: 'import:portal',
        fileSize: currentFile.size,
        fileExtension: imageInfo ? ImageTypeNames[imageInfo.type] : ImageTypeNames[0],
        canvasWidth: imageInfo?.width ?? -1,
        canvasHeight: imageInfo?.height ?? -1,
        success: false,
      });

      this.toastService.error({ message: tr`Failed to import "${nonTranslatable(name)}".`, subtitle: e.message });
      return undefined;
    }
  }

  updateFlowchartStatus(id: string, payload: { status: string, notify?: string[] }) {
    return toPromise(this.httpClient.post<void>(`/api/entities/${id}/status`, payload));
  }

  getHierarchy(id: string) {
    return this.httpClient.get<EntityData>(`/api/entities/${id}/hierarchy`).pipe(handleHttpError());
  }

  // TODO: remove this, instead re-fetch user.storageUsage
  getUsageData() {
    return this.httpClient.get<StorageUsageData>(`/api/entities/usage`).pipe(
      tap(usage => this.userService.updateUser({ storageUsage: usage.used })),
      handleHttpError(),
    );
  }

  private refreshStorageTimeouts = new Set<string>();
  refreshStorageUsage(teamId?: string) {
    const id = teamId ?? '-';
    if (!this.refreshStorageTimeouts.has(id)) {
      this.refreshStorageTimeouts.add(id);
      setTimeout(() => {
        this.refreshStorageTimeouts.delete(id);
        if (teamId) {
          toPromise(this.httpClient.get<StorageUsageData>(`/api/teams/${teamId}/usage`))
            .then(usage => this.teamStore.update(this.teamService.getTeamSlug(teamId)!, team => ({ ...team, storageUsage: usage })))
            .catch(e => DEVELOPMENT && console.error(e));
        } else {
          toPromise(this.httpClient.get<StorageUsageData>(`/api/entities/usage`))
            .then(usage => this.userService.updateUser({ storageUsage: usage.used }))
            .catch(e => DEVELOPMENT && console.error(e));
        }
      }, 1 * SECOND);
    }
  }

  getRecentEntities(requestSize = 15, sortByLastUpdated = false) {
    return combineLatest([
      this.recentEntitiesQuery.selectCount(),
      this.recentEntitiesQuery.selectLast(),
    ]).pipe(
      take(1),
      switchMap(([storeCount, lastRecentEntity]) => {
        const requestCount = requestSize - storeCount;
        const afterId = lastRecentEntity?._id;

        if (requestCount < 1) { // required amount of data already in store
          return this.recentEntitiesQuery.selectAll()
            .pipe(map(recentEntities => recentEntities.splice(0, requestSize)));
        } else { // fetch additional data from server
          return this.recentParticipationService.getRecentEntities(afterId, requestCount, sortByLastUpdated, undefined, false);
        }
      })
    );
  }

  async shareLiveEntityAsImage(entityId: string, eventSource = ''): Promise<SharedImageResponse> {
    return toPromise(this.httpClient.get<SharedImageResponse>(`/api/render/share/${entityId}/link`));
  }

  async getPassword(id: string) {
    const { password } = await toPromise(this.httpClient.get<PasswordData>(`/api/entities/${id}/password`));
    this.entitiesStore.update(id, { password });
    return password;
  }

  async updatePassword(id: string, password: string) {
    await toPromise(this.httpClient.put<EntityData>(`/api/entities/${id}/password`, { password }));
    this.entitiesStore.update(id, { password, hasPassword: true });
  }

  async resetPassword(id: string) {
    await toPromise(this.httpClient.delete<EntityData>(`/api/entities/${id}/password`));
    this.entitiesStore.update(id, { password: undefined, hasPassword: false });
  }

  getRemoved(teamId: string | undefined, folderId?: string) {
    if (teamId) {
      return this.httpClient.get<EntityData[]>(`/api/teams/${teamId}/entities?bin=true`);
    } else {
      return this.httpClient.get<EntityData[]>(`/api/entities?bin=true&all=true&${folderId ? 'folder=' + folderId : ''}`);
    }
  }
}
