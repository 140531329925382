import { TeamBannedMember } from './../../../shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Subject, BehaviorSubject, of, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { TeamMember } from 'shared/interfaces';
import { toPromise } from 'shared/utils';
import { TeamMembersQuery } from './team-members.query';
import { TeamMembersStore } from './team-members.store';
import { TeamsQuery } from './team.query';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TeamMembersService {
  private reload$ = new Subject<void>();
  teamMembersReady$ = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private teamMembersStore: TeamMembersStore,
    private teamsQuery: TeamsQuery,
    private teamMemberQuery: TeamMembersQuery,
  ) {
    combineLatest([
      this.teamsQuery.selectActive().pipe(map(team => team ? team._id : null), distinctUntilChanged()),
      this.reload$,
    ]).pipe(
      switchMap(([teamId, _]) => teamId ? this.getTeamMembers(teamId) : of(null)),
      map(members => members ? members.map(member => ({ ...member, _id: member.user._id })) : null),
      untilDestroyed(this),
    ).subscribe((members) => {
      if (members !== null) {
        this.teamMembersStore.set(members);
        this.teamMembersReady$.next(true);
      }
    });
    this.reload$.next();
  }

  reset() {
    this.teamMembersReady$.next(false);
    this.reload$.next();
  }

  reloadMembers() {
    this.reload$.next();
  }

  isTeamOwner(userId: string): boolean {
    const member = this.teamMemberQuery.getEntity(userId);
    return !!member?.roles.find(r => r.type === 'owner');
  }

  countTeamMembersWithOwnerRole() {
    return this.teamMemberQuery.getAll().reduce((sum, member) => sum + (member.roles.find(r => r.type === 'owner') ? 1 : 0), 0);
  }

  getTeamMembers(teamId: string): Observable<TeamMember[]> {
    return this.httpClient.get<TeamMember[]>(`/api/teams/${teamId}/members`).pipe(catchError(() => of([])));
  }

  async getTeamMembersCount(teamId: string): Promise<number> {
    const response = await toPromise(this.httpClient.get<{ count: number }>(`/api/teams/${teamId}/members-count`));
    return response.count;
  }

  async getTeamBannedMembers(teamId: string) {
    return await toPromise(this.httpClient.get<TeamBannedMember[]>(`/api/teams/${teamId}/bans`));
  }

  async updateTeamMember(teamId: string, memberId: string, roles: string[]) {
    await toPromise(this.httpClient.put(`/api/teams/${teamId}/members/${memberId}`, { roles }));
    this.reload$.next();
  }

  async transferTeamMemberRoles(teamId: string, sourceMemberId: string, targetMemberId: string) {
    await toPromise(this.httpClient.post(`/api/teams/${teamId}/transfer-roles`, { sourceMemberId, targetMemberId }));
    this.reload$.next();
  }

  async removeMemberTeam(teamId: string, memberId: string, banMember = false) {
    await toPromise(this.httpClient.delete(`/api/teams/${teamId}/members/${memberId}`, { body: { banMember } }));
    this.teamMembersStore.remove(memberId);
    this.reload$.next();
  }

  async unbanUserTeam(teamId: string, memberId: string) {
    await toPromise(this.httpClient.delete(`/api/teams/${teamId}/bans/${memberId}`));
  }

  getTeamMembersIds(teamId?: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`/api/teams/${teamId}/members/ids`)
      .pipe(catchError(() => of([])));
    }
    
  async getTeamMembersByName(teamId?: string, name?: string): Promise<TeamMember[]> {
    return await toPromise(this.httpClient.get<TeamMember[]>(`/api/teams/${teamId}/members?name=${name}`));
  }

  async getTeamMembersByIds(teamId: string, members: string[]): Promise<TeamMember[]> {
    return await toPromise(this.httpClient.post<TeamMember[]>(`/api/teams/${teamId}/members/info`, { members }));
  }
}
