import { canDrawOnActiveLayer, canEditLayer, Editor, isFilterActive } from './editor';
import { copyViewport, refTransform } from '../common/viewport';
import { AlignmentType, ITool, Layer, Rect, ToolId, ToolSource } from '../common/interfaces';
import { toolIdToString } from '../common/toolIdUtils';
import { asOpaque, colorFromRGBA, getAlpha } from '../common/color';
import { isMaskEmpty } from '../common/mask';
import { canvasToBlob, createCanvas, getContext2d } from '../common/canvasUtils';
import { getTransformedSelection } from '../common/user';
import { scheduleSaveSettings } from './settingsService';
import { TransformTo } from '../common/tools/transformTool';
import { rotateCanvasToView } from './copyPasteActions';
import { setEditorColor } from './editorUtils';
import { isViewTool } from '../common/update';
import { logAction } from '../common/actionLog';
import { invalidEnum } from '../common/baseUtils';
import { getSurfaceBounds, getTransformedRectBounds } from '../common/toolSurface';
import { copyMat2d, createMat2d, invertMat2d, transformVec2ByMat2d } from '../common/mat2d';
import { hasPermission } from '../common/userRole';
import { cloneRect, copyRect, rectContainsXY, setRect, validateRect } from '../common/rect';
import { invalidImageSizeUserError, isImageSizeValid } from '../common/drawingUtils';
import { getAllows } from '../common/userUtils';
import { CropToolData } from '../common/tools/cropTool';
import { finishTransform } from '../common/toolUtils';
import { isWebGL } from '../common/utils';
import { isTextureInLimits } from './webgl';
import { createVec2, setVec2 } from '../common/vec2';
import type { Model } from './model';
import { sendResizeDrawing } from './drawingConnection';
import { createRect, createViewport } from '../common/create';
import { UserError } from '../common/userError';
import { getDrawingRef } from './real-model';

const vec = createVec2();
const mat = createMat2d();
let onePixelCanvas: HTMLCanvasElement | undefined = undefined;
let onePixelContext: CanvasRenderingContext2D | undefined = undefined;

function getOnePixelContext() {
  if (!onePixelCanvas) onePixelCanvas = createCanvas(1, 1);
  if (!onePixelContext) onePixelContext = getContext2d(onePixelCanvas, { willReadFrequently: true });
  return onePixelContext;
}

function pickColorFromImage(image: HTMLImageElement | ImageBitmap | ImageData, x: number, y: number) {
  if ('data' in image) {
    return undefined; // TODO: pick from image data
  } else {
    const context = getOnePixelContext();
    context.drawImage(image, -x - 0.5, -y - 0.5);
    const data = context.getImageData(0, 0, 1, 1).data;
    return data[3] === 0 ? undefined : colorFromRGBA(data[0], data[1], data[2], data[3]);
  }
}

enum ParticipationFlags {
  None,
  Creator
}

const tempRect = createRect(0, 0, 0, 0);

export function pickColorAt(editor: Editor, x: number, y: number, activeLayer: boolean, secondary: boolean, alphaTo: string) {
  let color: number | undefined = undefined;
  const board = editor.model.getBoard();

  if (rectContainsXY(editor.drawing, x, y) && board !== editor.drawing) {
    color = editor.renderer.pickColor(editor.drawing, editor.activeLayer, x, y, activeLayer);
  } else if (board) {
    // correct offset that was added to x/y
    let bx = x - editor.drawing.x;
    let by = y - editor.drawing.y;

    if (board !== editor.drawing) {
      const layer = board.layers.find(l => l.ref?.id === editor.drawing.id);

      if (layer?.ref) {
        refTransform(mat, layer.ref);
        setVec2(vec, bx, by);
        transformVec2ByMat2d(vec, vec, mat);
        bx = vec[0];
        by = vec[1];
      }
    }

    for (let i = board.layers.length - 1; i >= 0; i--) {
      const { ref } = board.layers[i];
      if (!ref || !ref.image) continue;

      // TODO MULTIBOARD: pick color from actual drawing if it's loaded

      refTransform(mat, ref);
      invertMat2d(mat, mat);
      setVec2(vec, bx, by);
      transformVec2ByMat2d(vec, vec, mat);

      setRect(tempRect, 0, 0, ref.drawingRect.w, ref.drawingRect.h); // refTransform is already accounting for drawingRect.x/y
      if (rectContainsXY(tempRect, vec[0], vec[1])) {
        const tx = vec[0] / ref.drawingRect.w;
        const ty = vec[1] / ref.drawingRect.h;
        color = pickColorFromImage(ref.image, tx * ref.image.width, ty * ref.image.height);
        break;
      }
    }
  }

  if (color) {
    editor.apply(() => {
      setEditorColor(editor, asOpaque(color!), !secondary);

      // TODO: this actually doesn't work at all, since our selected tool is eyedropper at this moment
      const tool: any = editor.selectedTool;

      if (alphaTo && tool[alphaTo] !== undefined) {
        tool[alphaTo] = getAlpha(color!) / 0xff;
      }
    });
  }
}

export function moveBy(editor: Editor, dx: number, dy: number) {
  if (canDrawOnActiveLayer(editor) && !editor.drawingInProgress) {
    logAction(`[local] moveBy (${dx}, ${dy})`);
    copyViewport(editor.moveTool.view!, createViewport());
    editor.moveTool.start!(0, 0, 0, undefined);
    editor.moveTool.end!(dx, dy, 0);
  }
}

export function selectTool(editor: Editor, tool: ITool | undefined, source: ToolSource) {
  if (editor.drawingInProgress && !(isFilterActive(editor) && tool?.id && isViewTool(tool.id))) return;
  if (editor.drawing.isRevision && tool && !tool.navigation) return;

  editor.selectedTool?.onDeselect?.();
  editor.selectedTool = tool;
  editor.selectedTool?.onSelect?.();
  editor.toolSource = source;

  if (editor.model.activeSlot && tool) {
    editor.model.activeSlot.activeTool = toolIdToString(tool.id);
  }

  scheduleSaveSettings(editor.model);
}

export async function createAvatarFromSelection(editor: Editor) {
  const selection = getTransformedSelection(editor.model.user);
  if (isMaskEmpty(selection)) return false;

  let canvas = editor.renderer.getDrawingSnapshot(editor.drawing, selection);
  if (!canvas || !canvas.width || !canvas.height) return false;

  canvas = rotateCanvasToView(canvas, editor.view);

  const size = Math.max(canvas.width, canvas.height);

  let result = createCanvas(size, size);
  const context = getContext2d(result);

  if (editor.drawing.background) {
    context.fillStyle = editor.drawing.background;
    context.fillRect(0, 0, size, size);
  }

  context.drawImage(canvas, (size - canvas.width) / 2, (size - canvas.height) / 2);

  while (result.width >= 512) {
    const halfSize = Math.floor(result.width / 2);
    const halfCanvas = createCanvas(halfSize, halfSize);
    getContext2d(halfCanvas).drawImage(result, 0, 0, result.width, result.height, 0, 0, halfSize, halfSize);
    result = halfCanvas;
  }

  const blob = await canvasToBlob(result);
  if (!blob) return false;

  await editor.model.changeAvatar(blob);
  return true;
}

export function transformRotate(editor: Editor, angle: number) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.rotate(angle);
  }
}

export function transformScale(editor: Editor, x: number, y: number) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.scale(x, y);
  }
}

export function transformFit(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Fit);
  }
}

export function transformCover(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Cover);
  }
}

export function transformFull(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Full);
  }
}

export function alignLayer(editor: Editor, align: AlignmentType) {
  const layer = editor.activeLayer;

  if (!canDrawOnActiveLayer(editor) || editor.drawingInProgress) return;
  if (!layer) return;

  const getLayerProperty = (layer: Layer) => {
    const { selection, surface } = editor.model.user;

    if (layer.textarea) {
      const { textareaFormatting, rect, transform } = layer.textarea;
      copyMat2d(transform, textareaFormatting.transform);
      return getTransformedRectBounds(rect, transform);
    } else if (surface.layer === layer) {
      return getSurfaceBounds(surface);
    } else if (!isMaskEmpty(selection)) {
      return selection.bounds;
    } else {
      return layer.rect;
    }
  };

  const { x, y, w, h } = editor.drawing;
  const layerRect = getLayerProperty(layer);

  let dx = 0;
  let dy = 0;
  let opt = '';

  switch (align) {
    case AlignmentType.Left:
      dx -= layerRect.x - x;
      opt = 'align:left';
      break;
    case AlignmentType.Right:
      dx = w - layerRect.x + x - layerRect.w;
      opt = 'align:right';
      break;
    case AlignmentType.Top:
      dy -= layerRect.y - y;
      opt = 'align:top';
      break;
    case AlignmentType.Bottom:
      dy = h - layerRect.y + y - layerRect.h;
      opt = 'align:bottom';
      break;
    case AlignmentType.CenterHorizontally:
      dx = w / 2 - layerRect.x + x - layerRect.w / 2;
      opt = 'align:center-horizontally';
      break;
    case AlignmentType.CenterVertically:
      dy = h / 2 - layerRect.y + y - layerRect.h / 2;
      opt = 'align:center-vertically';
      break;

    default:
      invalidEnum(align);
  }

  dx = Math.round(dx);
  dy = Math.round(dy);

  logAction(`[local] align (${dx}, ${dy})`);

  copyViewport(editor.moveTool.view!, createViewport());
  editor.moveTool.start!(0, 0, 0, undefined);
  editor.moveTool.end!(dx, dy, 0, undefined, opt);
}

export function cropDrawing(model: Model, rect: Rect) {
  const { editor, drawing, user } = model;

  if (!hasPermission(drawing, user, 'cropDrawing')) {
    throw new UserError(`You don't have permission to resize canvas`);
  }

  validateRect(rect);

  const teamData = drawing.team ? model.manage.team(drawing.team) : undefined;
  const allows = getAllows(user, teamData);

  if (!isImageSizeValid(rect.w, rect.h, allows)) {
    throw invalidImageSizeUserError(allows);
  }

  if (isWebGL(editor.renderer) && !isTextureInLimits(editor.renderer.params().maxTextureSize, rect, drawing.layers)) {
    model.modals.textureSizeLimitReached();
    return;
  }

  drawing.lastCropRect = cloneRect(rect);
  finishTransform(model, 'cropDrawing');

  user.history.pushResize(true);
  editor.resizeCanvas(rect, drawing);

  // update drawingRect for this drawing in board, so we don't have it jitter around before we get drawingRect from server
  const ref = getDrawingRef(model, drawing.id);
  if (ref) copyRect(ref.drawingRect, rect);

  model.doTool<CropToolData>(0, { id: ToolId.Crop, rect });
  sendResizeDrawing(user, drawing, rect.x, rect.y, rect.w, rect.h);
}

export function isDrawingParticipant(editor: Editor, isEditor = false) {
  const drawing = editor.drawing;
  const userId = drawing.user.accountId;
  const participants = drawing.participants || [];
  const participant = participants.find(p => p.id.toString() === userId);

  if (!participant) return false;
  return isEditor ? participant?.flags === ParticipationFlags.Creator : true;
}

export function canRegisterOnStory(editor: Editor) {
  return editor.model.user.role === 'owner' || isDrawingParticipant(editor, true);
}
