import { Directive, Input, TemplateRef, Component, ViewContainerRef, Injectable, HostListener, EmbeddedViewRef, ElementRef, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter } from '@angular/core';
import { findFocusableElements, focusFirstElement, isDescendantOf } from '../../../common/htmlUtils';
import { Key } from '../../../common/input';
import { clamp, distance } from '../../../common/mathUtils';
import { getX, getY, isMobile } from '../../../common/utils';
import { PositioningService } from '../../../services/positioning';
import { createPoint, setPoint } from '../../../common/point';

@Injectable({ providedIn: 'any' })
export class ContextMenuOutletService {
  viewContainer?: ViewContainerRef;
}

export const LONG_PRESS_DELAY = 1000;
export const CLICK_OR_DRAG_THRESHOLD = 5;

@Directive({
  selector: '[contextMenu]',
  exportAs: 'context-menu'
})
export class ContextMenu implements OnDestroy {
  @Input() contextMenu: TemplateRef<any> | undefined = undefined;
  @Input() skipFocusingFirstElement = false;
  @Input('contextMenuEvent') event = 'contextmenu';
  @Input('contextMenuLocation') location: string | undefined = undefined;
  @Input() allowInsideClick = false;
  dragThresholdReached = false; // this is only here because of text tool hack
  inputPosition = createPoint(0, 0);
  private ref: EmbeddedViewRef<any> | undefined = undefined;
  private positioningElement: HTMLElement | undefined = undefined;
  private longPressStart = createPoint(0, 0);
  private longPressTimeout: NodeJS.Timeout | undefined = undefined;
  get waiting() {
    return !!this.longPressTimeout;
  }
  private _disabled = false;
  constructor(
    private element: ElementRef<HTMLElement>,
    private service: ContextMenuOutletService,
    private positioning: PositioningService,
  ) {
  }
  ngOnDestroy() {
    this.close();
  }
  @Input('contextMenuDisabled') get disabled() {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    if (this.disabled && this.isOpen) this.close();
  }
  @Input() get isOpen() {
    return !!this.ref;
  }
  @Output() isOpenChange = new EventEmitter<boolean>();

  @HostListener('pointerdown', ['$event'])
  @HostListener('click', ['$event'])
  @HostListener('contextmenu', ['$event'])
  open(e: PointerEvent) {
    if (this._disabled) return;

    if (isMobile && this.event === 'contextmenu') {
      if (e.type === 'contextmenu') e.preventDefault();

      if (e.type === 'pointerdown') {
        this.clearLongPressTimeout();
        setPoint(this.longPressStart, e.clientX, e.clientY);
        this.dragThresholdReached = false;
        this.longPressTimeout = setTimeout(() => this.openInternal(e), LONG_PRESS_DELAY);
        this.element.nativeElement.addEventListener('pointermove', this.pointerMove);
      }
    } else {
      if (e.type !== this.event) return;
      this.openInternal(e);
    }
  }

  @HostListener('pointerup')
  clearLongPressTimeout() {
    if (this.longPressTimeout) {
      clearTimeout(this.longPressTimeout);
      this.longPressTimeout = undefined;
      this.element.nativeElement.removeEventListener('pointermove', this.pointerMove);
    }
  }

  private pointerMove = (e: PointerEvent) => {
    if (this.longPressTimeout && distance(this.longPressStart.x, this.longPressStart.y, e.clientX, e.clientY) > CLICK_OR_DRAG_THRESHOLD) {
      this.dragThresholdReached = true;
      this.clearLongPressTimeout();
    }
  };

  private openInternal(e: PointerEvent) {
    this.clearLongPressTimeout();
    e.preventDefault();

    setPoint(this.inputPosition, getX(e), getY(e));

    const wasOpen = this.isOpen;

    this.close();

    // toggle menu open/close instead of opening it again after each click
    if (wasOpen && this.event === 'click') return;

    if (!this.contextMenu) return;
    if (!isMobile && (e.buttons & (~2 >>> 0)) !== 0) return;

    this.ref = this.service.viewContainer!.createEmbeddedView(this.contextMenu);
    this.ref.detectChanges();

    let firstNodeIndex = 0;
    while (firstNodeIndex < this.ref.rootNodes.length && this.ref.rootNodes[firstNodeIndex].nodeType === Node.COMMENT_NODE) {
      firstNodeIndex++;
    }

    if (this.location) {
      this.positioning.addPositionElement({
        element: this.positioningElement = this.ref.rootNodes[firstNodeIndex],
        target: this.element.nativeElement,
        attachment: this.location,
        appendToBody: true,
        options: {
          flip: {
            enabled: true
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        },
      });

      if (this.positioningElement) {
        this.positioningElement.style.margin = '5px';
      }
      if (!this.allowInsideClick) {
        this.ref.rootNodes[firstNodeIndex].addEventListener('click', () => this.close());
      }
    } else {
      for (const node of this.ref.rootNodes as HTMLElement[]) {
        if (node.nodeType === Node.COMMENT_NODE) continue;

        const { width, height } = node.getBoundingClientRect();
        const x = getX(e);
        const y = getY(e);
        node.style.left = `${Math.min(x, window.innerWidth - width - 5)}px`;
        node.style.top = `${(y + height) >= window.innerHeight ? y - height : y}px`;
        node.addEventListener('contextmenu', e => e.preventDefault());

        if (!this.allowInsideClick) {
          node.addEventListener('click', () => this.close());
        }
      }
    }

    focusAndSetupUpDownArrows(this.ref.rootNodes[firstNodeIndex], this.skipFocusingFirstElement);

    document.addEventListener('pointerdown', this.close, true);
    document.addEventListener('mousedown', this.close, true);
    document.addEventListener('touchstart', this.close, true);
    window.addEventListener('keydown', this.keydown);

    this.isOpenChange.emit(true);
  }
  close = (e?: Event) => {
    if (e?.target) {
      // ignore events inside the menu
      const target = e.target as HTMLElement;
      if (this.ref && isDescendantOf(target, this.ref.rootNodes)) return;

      if (Array.from(document.querySelectorAll('.tooltip-inner')).some(t => t.contains(target))) {
        // don't close ctxMenu if clicked within tooltip, since there can be elements within ctxMenu
        // that have tooltips with buttons (and tooltips are descendants of tooltip outlet not ctxMenu)
        return;
      }

      // ignore clicking on the same button to close the menu
      if (this.event === 'click' && isDescendantOf(target, [this.element.nativeElement])) return;
    }

    this.ref?.destroy();
    this.ref = undefined;

    if (this.positioningElement) {
      this.positioning.deletePositionElement(this.positioningElement);
      this.positioningElement = undefined;
    }

    document.removeEventListener('pointerdown', this.close, true);
    document.removeEventListener('mousedown', this.close, true);
    document.removeEventListener('touchstart', this.close, true);
    window.removeEventListener('keydown', this.keydown);

    this.isOpenChange.emit(false);
  };
  private keydown = (e: KeyboardEvent) => {
    if (e.keyCode === Key.Esc) {
      this.close();
      this.element.nativeElement.focus();
    }
  };
}

const focusSetUpMap = new WeakSet<HTMLElement>();

export function focusAndSetupUpDownArrows(element: HTMLElement | undefined, skipFocusingFirstElement = false) {
  if (!element) return;

  if (!focusSetUpMap.has(element)) {
    focusSetUpMap.add(element);
    element.addEventListener('keydown', e => {
      let move = 0;

      if (e.keyCode === Key.Up) {
        e.preventDefault();
        e.stopPropagation();
        move = -1;
      } else if (e.keyCode === Key.Down) {
        e.preventDefault();
        e.stopPropagation();
        move = 1;
      }

      if (move) {
        const elements = findFocusableElements(element);
        const index = elements.indexOf(document.activeElement as any);

        if (index !== -1) {
          const newIndex = clamp(index + move, 0, elements.length - 1);
          elements[newIndex].focus();
        }
      }
    });
  }

  if (!skipFocusingFirstElement) setTimeout(() => focusFirstElement(element));
}

@Component({
  selector: 'context-menu-outlet',
  template: `<ng-template></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuOutlet {
  constructor(service: ContextMenuOutletService, viewContainer: ViewContainerRef) {
    service.viewContainer = viewContainer;
  }
}
