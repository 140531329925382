import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modals } from '../../../../services/modals';

@Component({
  selector: 'user-limit-modal',
  templateUrl: 'user-limit-modal.pug',
  styleUrls: ['user-limit-modal.scss'],
})
export class UserLimitModal {
  @Input() data = 0;
  @Output() close = new EventEmitter<void>();
  constructor(private modals: Modals) {
  }
  reload() {
    location.reload();
  }
  closeModal() {
    this.close.emit();
  }
  async createNew() {
    this.close.emit();
    await this.modals.createDrawing();
  }
}
