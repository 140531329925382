import { Injectable } from '@angular/core';
import { RpcService } from './rpc.service';
import { ClientProgress } from '@deepkit/rpc';
import { EntityData } from 'shared/interfaces';
import { ImportEntityData } from 'magma/common/interfaces';

@Injectable({ providedIn: 'root' })
export class FilesService {
  constructor(private rpc: RpcService) { }

  async importFiles(filesData: Uint8Array[], data: ImportEntityData, reporter: (current: number, total: number) => void): Promise<EntityData> {
    const progress = ClientProgress.track();
    progress.upload.subscribe(progress => {
      reporter(progress.current, progress.total);
    });
    try {
      return await this.rpc.files.importFile(filesData, data);
    } finally {
      progress.unsubscribe();
    }
  }
}
