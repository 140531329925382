import { Drawing, ITool, IToolData, IToolEditor, IToolModel, Layer, Rect, ToolId, ToolSource, User, BrushToolSettings } from './interfaces';
import { getLayer } from './drawing';
import { getTransformedSelectionBounds, setActiveLayer } from './user';
import { createDecoder, decodePressure, nextToolArrayDecoder, startDecoder } from './compressor';
import { commitedLayerRect, getSurfaceBounds, hasIdentityTransform, isSurfaceEmpty, resetSurface } from './toolSurface';
import { logAction } from './actionLog';
import { createFakeModel, createTool } from './tools';
import { History } from './history';
import { clamp } from './mathUtils';
import { Editor } from '../services/editor';
import { redrawDrawing } from '../services/editorUtils';
import { readMovesEntry } from './binary';
import { toolIdToString } from './toolIdUtils';
import type { Model } from '../services/model';
import { clipSurfaceToLimits, cloneRect, isRectEmpty, rectToString } from './rect';
import { isTextLayer } from './layer';
import { transformAndClipMask } from './mask';
import { TextToolData, TextToolMode } from './tools/textTool';
import { getMaxLayerHeight, getMaxLayerWidth } from './drawingUtils';
import { isTextureInLimits, switchToFallbackRenderer } from '../services/webgl';
import { scheduleSaveSettings } from '../services/settingsService';
import { BaseBrushTool } from './tools/baseBrushTool';

let lastToolSource = ToolSource.None;

export function getLastToolSource() {
  const result = lastToolSource;
  lastToolSource = ToolSource.None;
  return result;
}

export function setLastToolSource(source: ToolSource) {
  lastToolSource = source;
}

export function setupActiveTool(editor: IToolEditor, drawing: Drawing, user: User, tool: IToolData) {
  if (user.lastTool && user.lastTool.id === tool.id) {
    user.activeTool = user.lastTool;
  } else {
    user.activeTool = createTool(tool.id, editor, createFakeModel(editor, drawing, user));
  }

  return user.activeTool;
}

export function applyTool(editor: IToolEditor, drawing: Drawing, user: User, layerId: number, tool: IToolData, data: Uint8Array | undefined): Promise<void> | undefined {
  const layer = getLayer(drawing, layerId);

  // TEMP: error tracking
  const isTextToolInCreatingMode = (tool.id === ToolId.Text && (tool as TextToolData).mode === TextToolMode.Creating);
  if (layerId && !layer && tool.id !== ToolId.Layer && !isTextToolInCreatingMode) {
    logAction(`[remote] missing layer: ${layerId} [applyTool] (tool: ${toolIdToString(tool.id)}, t: ${tool.t})`);

    if (tool.id === ToolId.Brush || tool.id === ToolId.Pencil || tool.id === ToolId.Eraser || tool.id === ToolId.Paintbucket) {
      throw new Error(`Missing layer [applyTool] (clientId: ${user.localId}, layerId: ${layerId}, t: ${tool.t})`);
    }
  }

  if (layer) layer.owner = user;

  setActiveLayer(user, layer);

  const activeTool = setupActiveTool(editor, drawing, user, tool);
  activeTool.setup?.(tool);

  if (activeTool.doAsync) {
    return activeTool.doAsync(tool, data, `clientId: ${user.localId}, layerId: ${layerId}`)
      .then(() => {
        if (!activeTool.transient) user.lastTool = activeTool;
        user.activeTool = undefined;
        if (layer && tool.ar && editor.type === 'client') {
          verifyAfterRect((editor as Editor).model, user, layer, tool.ar, 'applyToolAsync', tool);
        }
      });
  } else if (activeTool.do && !activeTool.ignoreDo) {
    activeTool.do(tool, data, `clientId: ${user.localId}, layerId: ${layerId}`);
  } else {
    if (!data) throw new Error(`Missing data`);

    const decoder = createDecoder((x, y, p) => activeTool.move!(x, y, decodePressure(p)));
    const view = new DataView(data.buffer, data.byteOffset, data.byteLength);
    const end = data.byteLength - 10;

    {
      const { x, y, p } = readMovesEntry(view, 0);
      startDecoder(decoder, x, y, p);
      activeTool.start!(x, y, decodePressure(p), undefined);
    }

    if (end > 10) nextToolArrayDecoder(decoder, data.subarray(10, end));

    {
      const { x, y, p } = readMovesEntry(view, end);
      activeTool.end!(x, y, decodePressure(p));
    }
  }

  if (!activeTool.transient) user.lastTool = activeTool;
  user.activeTool = undefined;

  if (layer && tool.ar && editor.type === 'client') {
    verifyAfterRect((editor as Editor).model, user, layer, tool.ar, 'applyToolSync', tool);
  }

  return undefined;
}

export function theSameTool(a: ToolId, b: ToolId) {
  return a === b ||
    (a === ToolId.Move && b === ToolId.Transform) ||
    (a === ToolId.Transform && b === ToolId.Move);
}

function localOrRemote(editor: IToolEditor, user: User) {
  return editor.type === 'client' ? (user === (editor as Editor).model.user ? 'local' : 'remote') : 'server';
}

export function finishTransform(model: Pick<IToolModel, 'editor' | 'drawing' | 'user'>, origin: string) {
  const { editor, drawing, user } = model;

  if (isSurfaceEmpty(user.surface) && hasIdentityTransform(user.surface)) {
    resetSurface(user.surface);
    return;
  }

  logAction(`[${localOrRemote(editor, user)}] finishTransform (${origin}, clientId: ${user.localId}, surface.layer: ${user.surface.layer?.id})`);

  if (editor.type === 'client') {
    if (DEVELOPMENT && (user.history as History).preundos.length) throw new Error('Preundos not empty');

    const bounds = getSurfaceBounds(user.surface);

    user.history.prepushSelection('finishTransform');
    user.history.prepushTool('finishTransform');

    if (!isSurfaceEmpty(user.surface) && user.surface.layer) {
      clipSurfaceToLimits(bounds, user.surface.drawingRect, getMaxLayerWidth(user.surface.drawingRect.w), getMaxLayerHeight(user.surface.drawingRect.h));

      const afterTransformRect = commitedLayerRect(user.surface, user.surface.layer);

      if (
        (afterTransformRect && !isTextureInLimits(editor.renderer.params().maxTextureSize, afterTransformRect, drawing.layers)) ||
        !isTextureInLimits(editor.renderer.params().maxTextureSize, bounds, drawing.layers)
      ) {
        switchToFallbackRenderer(editor.renderer.params().maxTextureSize);
        throw new Error('Reached texture size limit');
      }

      user.history.prepushDirtyRect('finishTransform', user.surface.layer.id, bounds);
    }
  }

  if (user.surface.layer) {
    editor.renderer.commitToolTransform(user);
  } else {
    transformAndClipMask(user.selection, user.surface);
    editor.renderer.releaseUserCanvas(user);
  }

  redrawDrawing(drawing);
}

export function continueTransform(model: IToolModel) {
  if (model.user.activeLayer !== model.user.surface.layer) {
    finishTransform(model, 'continueTransform');
  }
}

export function prevSize(tool: ITool | undefined) {
  if (tool && tool.size && tool.sizes) {
    for (let i = 1; i < tool.sizes.length; i++) {
      if (tool.size <= tool.sizes[i]) {
        tool.size = tool.sizes[i - 1];
        return;
      }
    }

    tool.size = tool.sizes[tool.sizes.length - 1];
  }
}

export function nextSize(tool: ITool | undefined) {
  if (tool && tool.size && tool.sizes) {
    for (let i = tool.sizes.length - 2; i >= 0; i--) {
      if (tool.sizes[i] <= tool.size) {
        tool.size = tool.sizes[i + 1];
        return;
      }
    }

    tool.size = tool.sizes[0];
  }
}

export function prevOpacity(tool: ITool | undefined) {
  if (tool && tool.opacity !== undefined) {
    tool.opacity = Math.max(0, tool.opacity - 0.1);
  }
}

export function nextOpacity(tool: ITool | undefined) {
  if (tool && tool.opacity !== undefined) {
    tool.opacity = Math.min(1, tool.opacity + 0.1);
  }
}

export function setOpacity(tool: ITool | undefined, value: number) {
  if (tool && tool.opacity !== undefined) {
    tool.opacity = clamp(value, 0, 1);
  }
}

export function prevHardness(tool: ITool | undefined) {
  if (tool && tool.hardness !== undefined) {
    tool.hardness = Math.max(0, tool.hardness - 0.2);
  }
}

export function nextHardness(tool: ITool | undefined) {
  if (tool && tool.hardness !== undefined) {
    tool.hardness = Math.min(1, tool.hardness + 0.2);
  }
}

export function safeInt(value: unknown, min: number, max: number): number {
  return clamp((value as any) | 0, min, max);
}

export function safeIntAny(value: unknown): number {
  return (value as any) | 0;
}

export function safeUintAny(value: unknown): number {
  return (value as any) >>> 0;
}

export function safeFloat(value: unknown, min: number, max: number): number {
  return clamp(+(value as any), min, max);
}

export function safeFloatAny(value: unknown): number {
  const float = +(value as any);
  return (!Number.isNaN(float) && Number.isFinite(float)) ? float : 0;
}

export function safeAngle(value: unknown): number {
  return clamp(+(value as any), -Math.PI, Math.PI);
}

export function safeOpacity(value: unknown): number {
  return clamp(+(value as any), 0, 1);
}

export function safeBoolean(value: unknown): boolean {
  return !!value;
}

export function safeString(value: unknown, maxLength = 0, defaultValue = ''): string {
  let str = `${value || defaultValue}`;
  if (maxLength && str.length > maxLength) str = str.substring(0, maxLength);
  return str;
}

export function isOk(value: number) {
  return !Number.isNaN(value) && Number.isFinite(value);
}

export function safeTransform(transform: number[]) {
  const result = [0, 0, 1, 1, 0];

  if (Array.isArray(transform) && transform.length === 5) {
    for (let i = 0; i < 5; i++) {
      if (isOk(transform[i])) {
        result[i] = transform[i];
      }
    }
  }

  return result;
}

export function fixedScale(scale: number) {
  // const TRANSFORM_EPSILON = 0.00001;
  // return (Math.abs(scale) < TRANSFORM_EPSILON) ? (scale < 0 ? -TRANSFORM_EPSILON : TRANSFORM_EPSILON) : scale;
  return scale;
}

function getInfoForError(user: User, layer: Layer | undefined, tool: any) {
  const view = user.activeTool?.view;

  return {
    layer: layer ? {
      id: layer.id,
      rect: { ...layer.rect },
      textData: !!layer.textData,
    } : undefined,
    selection: { ...user.selection.bounds },
    surface: {
      translateX: user.surface.translateX,
      translateY: user.surface.translateY,
      rotate: user.surface.rotate,
      scaleX: user.surface.scaleX,
      scaleY: user.surface.scaleY,
      rect: { ...user.surface.rect },
    },
    view: view ? { ...view } : undefined,
    tool,
  };
}

export function getLayerAfterRect(layer: Layer) {
  if (isTextLayer(layer)) {
    return undefined;
  } else {
    return cloneRect(layer.rect);
  }
}

export function verifyAfterRect(model: Model, user: User, layer: Layer, afterRect: Rect, func: string, tool: any) {
  if (isTextLayer(layer)) return;

  const r = commitedLayerRect(user.surface, layer);
  const ar = afterRect;

  if (r && (r.x !== ar.x || r.y !== ar.y || r.w !== ar.w || r.h !== ar.h)) {
    model.reportError(`Invalid layer.rect after ${func} (layer: ${rectToString(r)}) != (ar: ${rectToString(ar)}), surface.drawingRect: (${rectToString(user.surface.drawingRect)})`,
      undefined, getInfoForError(user, layer, tool));
  }
}

export function verifySelection(model: Model, user: User, tool: IToolData) {
  if ('s' in tool) {
    const selectionBounds = getTransformedSelectionBounds(user); // this is expensive in some cases
    const hasSelection = !isRectEmpty(selectionBounds);

    if (!!tool.s !== hasSelection) {
      model.reportError(`Selection mismatch on ${toolIdToString(tool.id)} (local: ${hasSelection}, remote: ${!!tool.s})`,
        undefined, getInfoForError(user, undefined, tool));
    }
  }
}

export function isBaseBrushTool(tool: ITool | undefined): tool is BaseBrushTool {
  return !!(tool && (tool.id === ToolId.Eraser || tool.id === ToolId.Brush || tool.id === ToolId.Pencil));
}

export function applyBrushPreset(editor: Editor, item: BrushToolSettings) {
  const tool = editor.selectedTool;
  if (isBaseBrushTool(tool)) {
    const { name, ...rest } = item;
    Object.assign(tool, rest);
    editor.model.settings.activeBrushPreset = rest._id;
    scheduleSaveSettings(editor.model);
    tool.changedProps$.next();
  }
}
