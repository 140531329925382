import { Injectable } from '@angular/core';
import { SupportedLocales } from '../common/constants';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  getLocale(): SupportedLocales | null {
    return 'en';
  }

  setLocale(_locale: SupportedLocales) {
  }

  getLocaleName(_locale?: SupportedLocales): string {
    return '';
  }
}
