import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { removeItem } from '../../../common/baseUtils';
import { Modals } from '../../../services/modals';

@Component({
  selector: 'admin-modals-box',
  templateUrl: './admin-modals-box.component.pug',
})
export class AdminModalsBoxComponent implements AfterViewInit, OnDestroy {
  @ViewChild('prompt', { static: true }) prompt!: TemplateRef<any>;
  @ViewChild('confirm', { static: true }) confirm!: TemplateRef<any>;
  @ViewChild('alert', { static: true }) alert!: TemplateRef<any>;
  @ViewChild('featureList', { static: true }) featureList!: TemplateRef<any>;
  @ViewChild('tagList', { static: true }) tagList!: TemplateRef<any>;
  @ViewChild('optionsConfirmation', { static: true }) optionsConfirmation!: TemplateRef<any>;
  @ViewChild('input', { static: true }) input!: TemplateRef<any>;
  @ViewChild('manageRewards', { static: true }) manageRewards!: TemplateRef<any>;
  ngAfterViewInit() {
    Modals.templates.push(this);
  }
  ngOnDestroy() {
    removeItem(Modals.templates, this);
  }
}
