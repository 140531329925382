import { brewerColors } from './constants';
import { clamp } from './mathUtils';
import { sample } from './utils';

const colorNames: { [key: string]: number | undefined; } = {
  aliceblue: 0xf0f8ffff,
  antiquewhite: 0xfaebd7ff,
  aqua: 0x00ffffff,
  aquamarine: 0x7fffd4ff,
  azure: 0xf0ffffff,
  beige: 0xf5f5dcff,
  bisque: 0xffe4c4ff,
  black: 0x000000ff,
  blanchedalmond: 0xffebcdff,
  blue: 0x0000ffff,
  blueviolet: 0x8a2be2ff,
  brown: 0xa52a2aff,
  burlywood: 0xdeb887ff,
  cadetblue: 0x5f9ea0ff,
  chartreuse: 0x7fff00ff,
  chocolate: 0xd2691eff,
  coral: 0xff7f50ff,
  cornflowerblue: 0x6495edff,
  cornsilk: 0xfff8dcff,
  crimson: 0xdc143cff,
  cyan: 0x00ffffff,
  darkblue: 0x00008bff,
  darkcyan: 0x008b8bff,
  darkgoldenrod: 0xb8860bff,
  darkgray: 0xa9a9a9ff,
  darkgreen: 0x006400ff,
  darkkhaki: 0xbdb76bff,
  darkmagenta: 0x8b008bff,
  darkolivegreen: 0x556b2fff,
  darkorange: 0xff8c00ff,
  darkorchid: 0x9932ccff,
  darkred: 0x8b0000ff,
  darksalmon: 0xe9967aff,
  darkseagreen: 0x8fbc8fff,
  darkslateblue: 0x483d8bff,
  darkslategray: 0x2f4f4fff,
  darkturquoise: 0x00ced1ff,
  darkviolet: 0x9400d3ff,
  deeppink: 0xff1493ff,
  deepskyblue: 0x00bfffff,
  dimgray: 0x696969ff,
  dodgerblue: 0x1e90ffff,
  feldspar: 0xd19275ff,
  firebrick: 0xb22222ff,
  floralwhite: 0xfffaf0ff,
  forestgreen: 0x228b22ff,
  fuchsia: 0xff00ffff,
  gainsboro: 0xdcdcdcff,
  ghostwhite: 0xf8f8ffff,
  gold: 0xffd700ff,
  goldenrod: 0xdaa520ff,
  gray: 0x808080ff,
  green: 0x008000ff,
  greenyellow: 0xadff2fff,
  honeydew: 0xf0fff0ff,
  hotpink: 0xff69b4ff,
  indianred: 0xcd5c5cff,
  indigo: 0x4b0082ff,
  ivory: 0xfffff0ff,
  khaki: 0xf0e68cff,
  lavender: 0xe6e6faff,
  lavenderblush: 0xfff0f5ff,
  lawngreen: 0x7cfc00ff,
  lemonchiffon: 0xfffacdff,
  lightblue: 0xadd8e6ff,
  lightcoral: 0xf08080ff,
  lightcyan: 0xe0ffffff,
  lightgoldenrodyellow: 0xfafad2ff,
  lightgrey: 0xd3d3d3ff,
  lightgreen: 0x90ee90ff,
  lightpink: 0xffb6c1ff,
  lightsalmon: 0xffa07aff,
  lightseagreen: 0x20b2aaff,
  lightskyblue: 0x87cefaff,
  lightslateblue: 0x8470ffff,
  lightslategray: 0x778899ff,
  lightsteelblue: 0xb0c4deff,
  lightyellow: 0xffffe0ff,
  lime: 0x00ff00ff,
  limegreen: 0x32cd32ff,
  linen: 0xfaf0e6ff,
  magenta: 0xff00ffff,
  maroon: 0x800000ff,
  mediumaquamarine: 0x66cdaaff,
  mediumblue: 0x0000cdff,
  mediumorchid: 0xba55d3ff,
  mediumpurple: 0x9370d8ff,
  mediumseagreen: 0x3cb371ff,
  mediumslateblue: 0x7b68eeff,
  mediumspringgreen: 0x00fa9aff,
  mediumturquoise: 0x48d1ccff,
  mediumvioletred: 0xc71585ff,
  midnightblue: 0x191970ff,
  mintcream: 0xf5fffaff,
  mistyrose: 0xffe4e1ff,
  moccasin: 0xffe4b5ff,
  navajowhite: 0xffdeadff,
  navy: 0x000080ff,
  oldlace: 0xfdf5e6ff,
  olive: 0x808000ff,
  olivedrab: 0x6b8e23ff,
  orange: 0xffa500ff,
  orangered: 0xff4500ff,
  orchid: 0xda70d6ff,
  palegoldenrod: 0xeee8aaff,
  palegreen: 0x98fb98ff,
  paleturquoise: 0xafeeeeff,
  palevioletred: 0xd87093ff,
  papayawhip: 0xffefd5ff,
  peachpuff: 0xffdab9ff,
  peru: 0xcd853fff,
  pink: 0xffc0cbff,
  plum: 0xdda0ddff,
  powderblue: 0xb0e0e6ff,
  purple: 0x800080ff,
  red: 0xff0000ff,
  rosybrown: 0xbc8f8fff,
  royalblue: 0x4169e1ff,
  saddlebrown: 0x8b4513ff,
  salmon: 0xfa8072ff,
  sandybrown: 0xf4a460ff,
  seagreen: 0x2e8b57ff,
  seashell: 0xfff5eeff,
  sienna: 0xa0522dff,
  silver: 0xc0c0c0ff,
  skyblue: 0x87ceebff,
  slateblue: 0x6a5acdff,
  slategray: 0x708090ff,
  snow: 0xfffafaff,
  springgreen: 0x00ff7fff,
  steelblue: 0x4682b4ff,
  tan: 0xd2b48cff,
  teal: 0x008080ff,
  thistle: 0xd8bfd8ff,
  tomato: 0xff6347ff,
  turquoise: 0x40e0d0ff,
  violet: 0xee82eeff,
  violetred: 0xd02090ff,
  wheat: 0xf5deb3ff,
  white: 0xffffffff,
  whitesmoke: 0xf5f5f5ff,
  yellow: 0xffff00ff,
  yellowgreen: 0x9acd32ff,
};

export const TRANSPARENT = 0x00000000 >>> 0;
export const BLACK = 0x000000ff >>> 0;

export interface HSVA {
  h: number; // 0-360 // TODO: normalize to 0-1 ?
  s: number; // 0-1
  v: number; // 0-1
  a: number; // 0-1
}

export interface RGB {
  r: number; // 0-255
  g: number; // 0-255
  b: number; // 0-255
}

export interface RGBA extends RGB {
  a: number;
}

export function getR(color: number) {
  return (color >> 24) & 0xff;
}

export function getG(color: number) {
  return (color >> 16) & 0xff;
}

export function getB(color: number) {
  return (color >> 8) & 0xff;
}

export function getAlpha(color: number) {
  return color & 0xff;
}

export function withAlpha(color: number, alpha: number) {
  return ((color & 0xffffff00) | (alpha & 0xff)) >>> 0;
}

export function asOpaque(color: number) {
  return (color | 0xff) >>> 0;
}

export function randomColor() {
  return ((Math.floor(Math.random() * 0xffffff) << 8) | 0xff) >>> 0;
}

export function randomHueColor() {
  return colorFromHSVA(Math.random() * 360, 0.5, 0.75, 1);
}

// to

export function colorToRGBA(color: number): RGBA {
  return {
    r: getR(color),
    g: getG(color),
    b: getB(color),
    a: getAlpha(color),
  };
}

export function colorToHSVA(color: number, h?: number): HSVA {
  return rgb2hsv(getR(color), getG(color), getB(color), getAlpha(color) / 255, h);
}

export function colorToHSVAInPlace(hsva: HSVA, color: number, h?: number) {
  rgb2hsvInPlace(hsva, getR(color), getG(color), getB(color), getAlpha(color) / 255, h);
}

export function colorToCSS(color: number): string {
  const alpha = getAlpha(color);

  if (alpha === 0xff) {
    return `#${colorToHexRGB(color)}`;
  } else {
    return `rgba(${getR(color)},${getG(color)},${getB(color)},${alpha / 255})`;
  }
}

export function colorToHexRGB(color: number) {
  return (color >>> 8).toString(16).padStart(6, '0');
}

export function colorToFloatArrayInPlace(out: Float32Array, color: number): Float32Array {
  colorToFloats(out, color);
  return out;
}

export function colorToFloatArray(color: number): Float32Array {
  const result = new Float32Array(4);
  colorToFloats(result, color);
  return result;
}

export function colorToFloats(array: Float32Array, color: number, mul = 1) {
  const t = mul / 255;
  array[0] = getR(color) * t;
  array[1] = getG(color) * t;
  array[2] = getB(color) * t;
  array[3] = getAlpha(color) * t;
  return array;
}

export function RGBAToABGR(color: number): number {
  return ((getAlpha(color) << 24) | (getB(color) << 16) | (getG(color) << 8) | getR(color)) >>> 0;
}

// from

export function colorFromRGBA(r: number, g: number, b: number, a: number /* 0-255 */) {
  return ((r << 24) | (g << 16) | (b << 8) | a) >>> 0;
}

const rgb: RGB = { r: 0, g: 0, b: 0 };

export function colorFromHSVA(h: number, s: number, v: number, a: number /* 0-1 */) {
  hsv2rgbInPlace(rgb, h, s, v);
  return colorFromRGBA(rgb.r, rgb.g, rgb.b, a * 255);
}

export function colorFromHSVAObject({ h, s, v, a }: HSVA) {
  return colorFromHSVA(h, s, v, a);
}

// parse

export function parseColorFast(str: string): number {
  if (typeof str !== 'string')
    return TRANSPARENT;

  const int = parseInt(str, 16);

  if (str.length !== 6 || isNaN(int) || int < 0) {
    return parseColorWithAlpha(str, 1);
  } else {
    return ((int << 8) | 0xff) >>> 0;
  }
}

export function parseColor(str: string): number {
  if (typeof str !== 'string')
    return TRANSPARENT;

  str = str.trim().toLowerCase();

  if (str === '' || str === 'none' || str === 'transparent')
    return TRANSPARENT;

  const colorFromName = colorNames[str];

  if (colorFromName !== undefined) {
    return colorFromName;
  }

  const m = /(\d+)[ ,]+(\d+)[ ,]+(\d+)(?:[ ,]+(\d*\.?\d+))?/.exec(str);

  if (m) {
    return colorFromRGBA(
      parseInt(m[1], 10),
      parseInt(m[2], 10),
      parseInt(m[3], 10),
      m[4] ? parseFloat(m[4]) * 255 : 255);
  }

  const n = /[0-9a-f]+/i.exec(str);

  if (n) {
    const s = n[0];

    if (s.length === 3) {
      return colorFromRGBA(
        parseInt(s.charAt(0), 16) * 0x11,
        parseInt(s.charAt(1), 16) * 0x11,
        parseInt(s.charAt(2), 16) * 0x11, 255);
    } else {
      return colorFromRGBA(
        parseInt(s.substr(0, 2), 16),
        parseInt(s.substr(2, 2), 16),
        parseInt(s.substr(4, 2), 16),
        s.length >= 8 ? parseInt(s.substr(6, 2), 16) : 255);
    }
  }

  return BLACK;
}

export function parseColorWithAlpha(str: string, alpha: number /* 0-1 */): number {
  return ((parseColor(str) & 0xffffff00) | ((alpha * 255) & 0xff)) >>> 0;
}

// utils

export function rgbToGray(r: number, g: number, b: number) {
  return 0.3 * r + 0.59 * g + 0.11 * b;
}

export function makeTransparent(color: number, factor: number /* 0-1 */): number {
  return ((color & 0xffffff00) | ((getAlpha(color) * factor) & 0xff)) >>> 0;
}

export function multiplyColor(color: number, factor: number /* 0-1 */): number {
  return colorFromRGBA(
    clamp(getR(color) * factor, 0, 255),
    clamp(getG(color) * factor, 0, 255),
    clamp(getB(color) * factor, 0, 255),
    getAlpha(color)
  );
}

export function lerpColors(a: number, b: number, factor: number): number {
  const f = factor;
  const t = 1 - factor;

  return colorFromRGBA(
    getR(a) * t + getR(b) * f,
    getG(a) * t + getG(b) * f,
    getB(a) * t + getB(b) * f,
    getAlpha(a) * t + getAlpha(b) * f
  );
}

export function normalizeAngle(angle: number) {
  while (angle < 0) angle += 360;
  return angle % 360;
}

export function lerpHSVA(result: HSVA, a: HSVA, b: HSVA, factor: number) {
  const f = factor;
  const t = 1 - factor;

  let ah = a.h;
  let bh = b.h;

  if (Math.abs(bh - ah) > 180) {
    if (ah > bh) {
      ah -= 360;
    } else {
      bh -= 360;
    }
  }

  result.h = normalizeAngle(ah * t + bh * f);
  result.s = a.s * t + b.s * f;
  result.v = a.v * t + b.v * f;
  result.a = a.a * t + b.a * f;
}

// r, g, b = <0, 255>, a = <0, 1>
export function rgb2hsvInPlace(hsva: HSVA, r: number, g: number, b: number, a: number /* 0-1 */, h = 0) {
  r = r / 255;
  g = g / 255;
  b = b / 255;
  h = h / 360;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const v = max;
  const d = max - min;
  const s = max === 0 ? 0 : d / max;

  if (max !== min) {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  hsva.h = h * 360;
  hsva.s = s;
  hsva.v = v;
  hsva.a = a;
}

// r, g, b = <0, 255>, a = <0, 1>
export function rgb2hsv(r: number, g: number, b: number, a: number /* 0-1 */, h = 0): HSVA {
  const result: HSVA = { h: 0, s: 0, v: 0, a: 1 };
  rgb2hsvInPlace(result, r, g, b, a, h);
  return result;
}

// h = <0, 360>; s, v = <0, 1>
export function hsv2rgbInPlace(rgb: RGB, h: number, s: number, v: number) {
  h = Math.max(0, Math.min(360, h === 360 ? 0 : h));
  s = Math.max(0, Math.min(1, s));
  v = Math.max(0, Math.min(1, v));

  let r = v;
  let g = v;
  let b = v;

  if (s !== 0) {
    h /= 60;

    const i = Math.floor(h);
    const f = h - i;
    const p = v * (1 - s);
    const q = v * (1 - s * f);
    const t = v * (1 - s * (1 - f));

    switch (i) {
      case 0:
        r = v;
        g = t;
        b = p;
        break;
      case 1:
        r = q;
        g = v;
        b = p;
        break;
      case 2:
        r = p;
        g = v;
        b = t;
        break;
      case 3:
        r = p;
        g = q;
        b = v;
        break;
      case 4:
        r = t;
        g = p;
        b = v;
        break;
      default:
        r = v;
        g = p;
        b = q;
    }
  }

  rgb.r = Math.round(r * 255);
  rgb.g = Math.round(g * 255);
  rgb.b = Math.round(b * 255);
}

// h = <0, 360>; s, v = <0, 1>
export function hsv2rgb(h: number, s: number, v: number): RGB {
  const result: RGB = { r: 0, g: 0, b: 0 };
  hsv2rgbInPlace(result, h, s, v);
  return result;
}

export function h2rgb(h: number): RGB {
  h /= 60;
  let r = 0, g = 0, b = 0;
  const i = Math.floor(h);
  const f = h - i;
  const q = (1 - f);
  const t = (1 - (1 - f));

  switch (i) {
    case 0:
      r = 1;
      g = t;
      break;
    case 1:
      r = q;
      g = 1;
      break;
    case 2:
      g = 1;
      b = t;
      break;
    case 3:
      g = q;
      b = 1;
      break;
    case 4:
      r = t;
      b = 1;
      break;
    default:
      r = 1;
      b = q;
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
}

const yiqTextDark = '#212529';
const yiqTextLight = '#fff';
const yiqContrastedThreshold = 150;

// Port of https://github.com/twbs/bootstrap/blob/v4.5.3/scss/_functions.scss#L73
// TODO: Boostrap 5 uses WCAG contrast algo https://github.com/twbs/bootstrap/blame/f30066cb8a444a606cc6979ef45b723c94cb56b8/site/content/docs/5.0/migration.md#L44
export function colorYIQ(colorString: string, dark = yiqTextDark, light = yiqTextLight) {
  const color = parseColor(colorString);
  const yiq = (getR(color) * 299 + getG(color) * 587 + getB(color) * 114) / 1000;
  if (yiq >= yiqContrastedThreshold) {
    return dark;
  } else {
    return light;
  }
}


// https://stackoverflow.com/questions/2241447/make-foregroundcolor-black-or-white-depending-on-background/2241471#2241471
export function perceivedBrightness([r, g, b]: number[]) {
  return Math.sqrt(
    r * r * .299 +
    g * g * .587 +
    b * b * .114
  );
}

// https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex/54014428#54014428
// https://blog.logrocket.com/how-to-manipulate-css-colors-with-javascript-fb547113a1b8/
export function hsl2rgb([h, s, l]: number[]): number[] {
  let a = s * Math.min(l, 1 - l);
  let f = (n: number, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  return [f(0), f(8), f(4)];
}

export function cssHsl2hsl(c: string): number[] {
  const [h, s, l] = c.match(/\d+/g)!.map(c => parseInt(c, 10));
  return [h, s / 100, l / 100];
}

export function rgb2cssRgb([r, g, b]: number[]): number[] {
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function rgbToHex(rgb: number[]) {
  return rgb.map(c => Math.round(c * 255).toString(16).padStart(2, '0')).join('');
}

export function randomColorFromCollection() {
  return rgb2cssRgb(hsl2rgb(cssHsl2hsl(sample(brewerColors))));
}

export function randomBrewerHexColor() {
  return rgbToHex(hsl2rgb(cssHsl2hsl(sample(brewerColors))));
}

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb/5624139#5624139
export function hex2rgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (_, r, g, b) =>
    r + r + g + g + b + b
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) throw new Error('Wrong hex color');
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ];
}
