import { Component, Input, OnInit } from '@angular/core';
import { Analytics } from '../../../common/interfaces';
import { MINUTE } from '../../../common/constants';
import { Model } from '../../../services/model';
import { UTCfloor, getValueOfVariable, userHasAds } from '../../../common/utils';
import { ITrackService } from '../../../services/track.service.interface';

interface Ad {
  link: string;
  asset: string;
  position: string;
  company: string;
  expiration?: Date; // included
  allowedCoutries?: string[];
}

@Component({
  selector: 'partnership-content',
  templateUrl: 'partnership-content.pug',
  styleUrls: ['partnership-content.scss'],
})
export class PartnershipContent implements OnInit {
  readonly adReloadTime = MINUTE;

  @Input() position: 'bottom' | 'right' = 'bottom';
  @Input() page = '';

  //@ignore-translation
  ads: Ad[] = IS_PORTAL ? [
    { company: 'wacom', link: 'https://bit.ly/3xVAx4C', position: 'bottom', asset: require('assets/wacom_1456x180.jpg') },
    { company: 'schoolism', link: 'https://cf.schoolism.com/schoolism_membership_200', position: 'right', expiration: new Date('12 June 2024 UTC'), asset: require('assets/schoolism_240x1200.png') },
    { company: 'wacom', link: 'https://bit.ly/3xVAx4C', position: 'right', asset: require('assets/wacom_240x1200.jpg') },
    { company: 'schoolism', link: 'https://cf.schoolism.com/schoolism_membership_200', position: 'bottom', expiration: new Date('12 June 2024 UTC'), asset: require('assets/schoolism_1456x180.png') },
    { company: 'schoolism-new', link: 'https://schoolism.com/', position: 'right', asset: require('assets/schoolism-240x1200-b.jpg') },
    { company: 'schoolism-new', link: 'https://schoolism.com/', position: 'bottom', asset: require('assets/schoolism-1456x180-a.jpg') },
  ] : [];

  intervalId: ReturnType<typeof setTimeout> | undefined;
  displayedAd: Ad | undefined;
  index = 0;
  allowedAds: Ad[] = [];
  countryCode: string | undefined;

  constructor(private model: Model, private trackService: ITrackService) { }

  get isBottom() {
    return this.position === 'bottom';
  }

  get isAdsAllowed() {
    return userHasAds(this.model.user);
  }

  private getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnInit() {
    this.countryCode = getValueOfVariable('countryCode');
    this.allowedAds = this.adsByPosition(this.position);
    this.updateAd(this.index);
    this.intervalId = setInterval(() => {
      // TODO: randomize ads, once we have a long list getRandomNumber
      this.allowedAds = this.adsByPosition(this.position);
      const index = this.getRandomNumber(0, this.allowedAds.length - 1);
      this.updateAd(index);

    }, this.adReloadTime);
  }

  partnershipEvent(open = false) {
    this.trackService.event(open ? Analytics.OpenAd : Analytics.LoadAd, { position: this.position, company: this.displayedAd?.company, link: this.displayedAd?.link, page: this.page });
  }

  adsByPosition(position: string) {
    const startOfToday = UTCfloor(new Date());
    startOfToday.setUTCHours(0, 0, 0, 0);
    return this.ads.filter(a => a.position === position)
      .filter(a => !a?.expiration || (a?.expiration && a.expiration >= startOfToday))
      .filter(a => !a?.allowedCoutries || !a.allowedCoutries.length || (this.countryCode && a.allowedCoutries.includes(this.countryCode)));
  }

  updateAd(index: number) {
    this.index = index;
    this.displayedAd = this.allowedAds[index];
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
