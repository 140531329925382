import { Injectable, TemplateRef } from '@angular/core';
import { Drawing, SelectedFolder, PresentationModeState, TextLayer, PresentationActionData, PresentationModeStartModal, ArtworkLicensingData, RequestArtworkRegistrationResponse, CreateDrawingData } from '../common/interfaces';
import { delay } from '../common/promiseUtils';
import { ChoiceModalOption } from '../components/shared/modals/choice-modal/choice-modal';
import { DisabledOnMobileModalData } from '../components/shared/modals/disabled-on-mobile-modal/disabled-on-mobile-modal';
import { Model } from './model';
import { VcFeedback } from '../components/shared/modals/vc-feedback-modal/vc-feedback-modal';
import { PromptModalOptions } from '../components/shared/modals/prompt-modal/prompt-modal';
import { JamsJoinModalInput, JamsModalInput, UpdateJamDrawing, UserReportModalInput } from '../common/interfaces-jams';
import { StrokeTrackerData } from '../components/shared/modals/stroke-tracker-modal/stroke-tracker-modal';
import { CustomerInsightGroups } from '../common/constants';

export interface CreateDrawingProps {
  name?: string;
  addToSequence?: boolean;
  sequenceIndex?: number;
  existing?: boolean;
  openInNewWindow?: boolean;
  drawing?: Drawing;
  create?: boolean;
  team?: string;
  project?: string;
  folder?: string;
  forMultiboard?: boolean;
}

export interface OpenModalOptions {
  class?: 'modal-sm' | 'modal-lg' | 'modal-lg2' | 'modal-xl';
  overflow?: boolean;
  ignoreKeys?: boolean;
  data?: any;
}

export interface IModalsBox {
  openModal<T = void>(template: TemplateRef<any>, options?: OpenModalOptions, name?: string): Promise<T | undefined>;
  closeModal(template: TemplateRef<any>): void;
  closeModalByName(name: string): void;
  isAnyModalOpen(): boolean;
  isOpen(template: any): boolean;
  closeAll(): void;
}

export function findTemplate(name: string, templates: { [key: string]: TemplateRef<any>; }[]) {
  for (const obj of templates) {
    if (name in obj && obj[name] && 'elementRef' in obj[name]) {
      return obj[name];
    }
  }

  return undefined;
}

@Injectable({ providedIn: 'any' })
export class Modals {
  static box: IModalsBox | undefined = undefined;
  static templates: { [key: string]: any; }[] = []; // array of objects holding named modal templates
  // openByClass<T>() {

  // }

  openByTemplate<T>(template: TemplateRef<any>, options?: OpenModalOptions, name?: string) {
    return Modals.box!.openModal<T>(template, options, name);
  }
  openByName<T>(name: string, options?: OpenModalOptions) {
    const template = findTemplate(name, Modals.templates);
    if (!template) throw new Error(`Modal template not found "${name}"`);
    return this.openByTemplate<T>(template, options, name);
  }
  isOpen(name: string) {
    if (!Modals.box) return false;
    const template = findTemplate(name, Modals.templates);
    return Modals.box.isOpen(template);
  }
  closeByName(name: string) {
    Modals.box?.closeModalByName(name);
  }
  closeAll() {
    Modals.box?.closeAll();
  }
  async waitForTemplate(name: string) {
    while (!findTemplate(name, Modals.templates)) {
      await delay(100);
    }
  }
  startPresentation(data: PresentationModeStartModal) {
    return this.openByName<Pick<PresentationModeState, 'participantsUiHidden'>>('startPresentation', { data });
  }
  presentationActionModal(data: PresentationActionData) {
    return this.openByName<boolean>('presentationActionModal', { data });
  }
  managePresentationViewers(data: { model: Model }) {
    return this.openByName<boolean>('managePresentationViewersModal', { data });
  }
  invokeRasterizeFlow(layer: TextLayer) {
    return this.openByName<boolean>('rasterizeFlow', { data: layer });
  }
  disabledOnMobile(data: DisabledOnMobileModalData) {
    return this.openByName<DisabledOnMobileModalData>('disabledOnMobile', { data });
  }
  saveToVersionHistory() {
    return this.openByName('versionHistoryCreate');
  }
  createAccount() {
    void this.openByName('createAccount', { overflow: true });
  }
  createDrawing(options: CreateDrawingProps = {}) {
    const data: CreateDrawingProps = { ...options, create: true };
    return this.openByName<CreateDrawingData>('drawingSettings', { data });
  }
  drawingSettings(drawing: Drawing) {
    const data: CreateDrawingProps = { drawing, team: drawing.team, project: drawing.project, folder: drawing.folder };
    void this.openByName('drawingSettings', { data });
  }
  closeDrawingSettings() {
    this.closeByName('drawingSettings');
  }
  userSettings() {
    void this.openByName('userSettings', { overflow: true });
  }
  settings(startTab = 0) {
    void this.openByName('settings', { data: { startTab }, class: 'modal-lg', ignoreKeys: true });
  }
  signIn() {
    void this.openByName('signIn', { overflow: true });
  }
  async signUp(forced = false) {
    return this.waitForTemplate('signUp')
      .then(() => this.openByName('signUp', { class: 'modal-lg', ignoreKeys: true, data: { forced } }))
      .catch(e => DEVELOPMENT && console.error(e));
  }
  signUpMini() {
    void this.openByName('signUpMini', { class: 'modal-lg2', ignoreKeys: true, data: { } });
  }
  help() {
    void this.openByName('help', { class: 'modal-lg' });
  }
  userLimit(count: number) {
    void this.openByName('userLimit', { ignoreKeys: true, data: count });
  }
  drawingDeleted() {
    if (!this.isOpen('drawingDeleted')) {
      void this.openByName('drawingDeleted');
    }
  }
  edition() {
    void this.openByName('edition', { class: 'modal-lg' });
  }
  overloaded() {
    void this.openByName('overloaded', { class: 'modal-lg', ignoreKeys: true });
  }
  acceptAiEula() {
    return this.openByName('acceptAiEula');
  }
  aiQuotaReached() {
    return this.openByName('aiQuotaReached');
  }
  textureSizeLimitReached() {
    void this.openByName('textureSizeLimitReached');
  }
  strokeTrackerModal(data: StrokeTrackerData): Promise<StrokeTrackerData | undefined> {
    return this.openByName<StrokeTrackerData | undefined>('strokeTracker', { data });
  }
  closeOverloaded() {
    this.closeByName('overloaded');
  }
  psdImport() {
    const data: ChoiceModalOption[] = [
      { id: 'image', label: 'Import as image' },
      { id: 'layers', label: 'Import as new layers' },
      { id: 'sync', label: 'Update existing layers' },
    ];
    return this.openByName<'image' | 'layers' | 'sync'>('choice', { class: 'modal-sm', data });
  }

  multiFileImportEditor(hasPdf: boolean, importing: boolean) {
    const data: ChoiceModalOption[] = [
      ...(importing ? [{ id: 'separate', label: 'Import images separately' }] : []),
      { id: 'sequence', label: 'Add images to the sequence' },
      ...(hasPdf ? [] : [{ id: 'layers', label: 'Import as layers in a single drawing' }]),
      { id: 'single', label: importing ? 'Import first image only' : 'Paste first image only' },
    ];

    if (data.length == 1) return data[0].id as 'sequence' | 'layers' | 'single' | 'separate';
    return this.openByName<'sequence' | 'layers' | 'single' | 'separate'>('choice', { class: 'modal-sm', data });
  }

  multiFileImportManage(hasPdf: boolean) {
    const data: ChoiceModalOption[] = [
      { id: 'separate', label: 'Import images separately' },
      ...(hasPdf ? [] : [{ id: 'sequence', label: 'Import to a new sequence' }]),
      ...(hasPdf ? [] : [{ id: 'layers', label: 'Import as layers in a single drawing' }]),
    ];

    if (data.length == 1) return data[0].id as 'separate' | 'sequence' | 'layers';
    return this.openByName<'separate' | 'sequence' | 'layers'>('choice', { class: 'modal-sm', data });
  }

  question(message: string, yes?: string, no?: string) {
    return this.openByName<boolean>('question', { class: 'modal-sm', data: { message, yes, no } });
  }
  confirm(header: string, message: string, yes: string, no: string, confirmButtonStyle?: string) {
    return this.openByName<boolean>('confirm', { data: { header, message, yes, no, confirmButtonStyle } });
  }
  info(header: string, message: string, confirmButtonStyle?: string) {
    return this.openByName<boolean>('info', { data: { header, message, confirmButtonStyle } });
  }
  prompt(options: PromptModalOptions) {
    return this.openByName<string>('prompt', { data: options });
  }
  input(message: string) {
    return this.openByName<string>('input', { data: { message } });
  }
  isAnyModalOpen() {
    return !!Modals.box?.isAnyModalOpen();
  }
  async shareEntityEditor(_shortId: string, _model: Model, _openedBy: string): Promise<void> {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
  }
  async deleteEntityEditor(_shortId: string, _model: Model, _navigate: boolean, _openedBy: string): Promise<boolean | undefined> {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
    return undefined;
  }
  async duplicateEntityEditor(_shortId: string, _model: Model, _openedBy: string): Promise<string | undefined> {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
    return undefined;
  }
  async selectFolder(_data: any): Promise<SelectedFolder | undefined> {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
    return undefined;
  }
  creator(_data: any, _items?: any[], _model?: Model) {
    DEVELOPMENT && console.error('using incorrect modal service'); // overriden in modal service
  }
  closeAllMarketingModals() {
  }
  closeAllPresentationModeModals() {
  }
  stephenSilverShapesModal() {
  }
  openVoiceChatFeedbackModal(data: Partial<VcFeedback> = {}) {
    return this.openByName<VcFeedback | undefined>('vcFeedbackModal', { data });
  }
  addDrawingReferenceWindowModal() {
    return this.openByName<string | undefined>('addDrawingReferenceWindow');
  }
  registerArtwork(data: any): Promise<any | undefined> { // data: (portal/shared/interfaces.ts -> RegisterArtworkData)
    return this.openByName('registerArtwork', { data });
  }
  openArtworkLicense(data: ArtworkLicensingData) {
    return this.openByName('artworkLicensing', { data });
  }
  openArtworkPendingLicense(data: ArtworkLicensingData & { status?: number }): Promise<any | undefined> {
    return this.openByName('artworkPendingLicense', { data });
  }
  openRequestingRegistrationNotification(data: ArtworkLicensingData) : Promise<RequestArtworkRegistrationResponse | null | undefined> {
    return this.openByName('requestingRegistrationNotification', { data });
  }
  openOtherPlansModal(_data: any) {
    return Promise.resolve();
  }

  joinJam(_data: JamsJoinModalInput) { }

  setUserBirthdate(): Promise<Date | undefined> {
    return Promise.resolve(undefined);
  }

  async editJam(_data: JamsModalInput): Promise<UpdateJamDrawing | undefined> {
    return undefined;
  }

  createUserReport(_data: UserReportModalInput) {

  }

  customerInsightsInvite(group: CustomerInsightGroups) {
    this.waitForTemplate('customerInsightsInvite')
      .then(() => this.openByName('customerInsightsInvite', { data: { group } }))
      .catch(e => DEVELOPMENT && console.error(e));
  }
}
