import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EditorPopupOutletService {
  popups = new Map<string, TemplateRef<any>>(); // popupId: template
  openedPopups = new Set<string>(); // popupId
  openedPopups$ = new BehaviorSubject<Set<string>>(this.openedPopups);

  open(popupId: string) {
    this.openedPopups.add(popupId);
    this.openedPopups$.next(this.openedPopups);
  }

  close(popupId: string) {
    this.openedPopups.delete(popupId);
    this.openedPopups$.next(this.openedPopups);
  }

  toggle(popupId: string) {
    if (this.openedPopups.has(popupId)) {
      this.close(popupId);
    } else {
      this.open(popupId);
    }
  }
}
