import { UserData, Layer, User, IToolEditor, Drawing } from './interfaces';
import { createDecoder, decodePressure } from './compressor';
import { History } from './history';
import { includes } from './baseUtils';
import { createSurface, hasIdentityTransform, hasZeroTransform } from './toolSurface';
import { MockHistory } from './mockHistory';
import { createMask, clearMask, cloneMask, transformAndClipMask, isMaskEmpty } from './mask';
import { colorToFloats, parseColorFast } from './color';
import { DEFAULT_USER_COLOR, YEAR } from './constants';
import { cloneRect, createRect } from './rect';
import { fromNow } from './utils';

export function createUser(uniqId: string, localId: number, name = ''): User {
  let user: User;
  return user = {
    uniqId,
    localId,
    name,
    email: undefined,
    anonymous: true,
    anonymousNumber: 0,
    isSuperAdmin: false,
    color: DEFAULT_USER_COLOR,
    colorFloat: new Float32Array(4),
    avatar: undefined,
    avatarImage: undefined,
    talking: false,
    featureFlags: new Set(),
    role: 'all',
    activeLayer: undefined,
    activeLayerId: 0,
    ownedLayers: [],
    activeTool: undefined,
    lastTool: undefined,
    lastToolStartData: undefined,
    selection: createMask(),
    lastSelection: createMask(),
    showTransform: undefined,
    decoder: createDecoder((x, y, p) => {
      user.lastX = x;
      user.lastY = y;
      user.activeTool?.move?.(x, y, decodePressure(p));
    }),
    history: new MockHistory(),
    surface: createSurface(),
    isCreator: false,
    cursorX: 1e9,
    cursorY: 1e9,
    cursorAlpha: 1,
    cursorDelay: 0,
    cursorLastUpdate: 0,
    lastX: 1e9,
    lastY: 1e9,
    readOnly: false,
    chunkedData: new Map(),
    userJob: undefined,
    receiveEmails: undefined,
    pro: undefined,
    tags: [],
  };
}

export function createAndInitUser(uniqId: string, localId: number, state?: UserData): User {
  const user = createUser(uniqId, localId);
  if (state) setUserState(user, state);
  return user;
}

export function createAndInitUserWithHistory(uniqId: string, localId: number, state: UserData | undefined, editor: IToolEditor, drawing: Drawing): User {
  const user = createAndInitUser(uniqId, localId, state);
  user.history = new History(user, editor, drawing);
  return user;
}

// TODO: remove this so we don't have to add new fields all the time
//       this also will not unset fields
export function setUserState(user: User, state: UserData) {
  user.accountId = state.accountId;
  user.name = state.name ?? '';
  user.email = state.email;
  user.color = state.color ?? '';
  colorToFloats(user.colorFloat, parseColorFast(user.color));
  user.avatar = state.avatar;
  user.role = state.role ?? 'all';
  user.featureFlags = new Set(state.featureFlags ?? []);
  user.anonymous = state.anonymous ?? false;
  user.anonymousNumber = state.anonymousNumber ?? 0;
  user.isSuperAdmin = state.isSuperAdmin ?? false;
  user.ownedLayers = state.ownedLayers ?? [];
  user.readOnly = state.readOnly ?? false;
  user.userJob = state.userJob;
  user.workTags = state.workTags;
  user.receiveEmails = state.receiveEmails;
  user.tags = state.tags;

  // TODO: these fields are optional
  user.activeLayerId = state.activeLayerId ?? 0;
  user.isCreator = state.isCreator ?? false;
  user.subscriptionStatus = state.subscriptionStatus;
  user.pro = state.pro;
  user.proSources = state.proSources;
  user.adminUrl = state.adminUrl ?? user.adminUrl;
  user.optedIntoAds = state.optedIntoAds ?? user.optedIntoAds;

  if (state.created) user.created = Date.parse(state.created);
}

export function setActiveLayer(user: User, layer: Layer | undefined) {
  user.activeLayer = layer;
  user.activeLayerId = layer ? layer.id : 0;
}

export function userOwnsLayer(user: User, layerId: number) {
  return includes(user.ownedLayers, layerId);
}

export function userOwnsAnyLayers(user: User, drawing: Drawing) {
  return drawing.layers.some(l => l.owner === user);
}

export function releaseUser(user: User, editor: IToolEditor | undefined) {
  user.history.clear();
  editor?.renderer?.releaseUserCanvas(user);
}

export function resetUser(user: User, editor: IToolEditor | undefined) {
  user.activeTool = undefined;
  user.ownedLayers = [];
  user.role = 'all';
  user.isCreator = false;
  user.subscriptionStatus = undefined;
  setActiveLayer(user, undefined);
  clearMask(user.selection);
  releaseUser(user, editor);
}

export function clearUsers(list: User[], editor?: IToolEditor) {
  list.forEach(u => resetUser(u, editor));
  list.length = 0;
}

export function releaseToolRenderingContext(user: User) {
  if (DEVELOPMENT && !user.surface.context) throw new Error('Rendering context already released');

  if (user.surface.context) {
    try {
      user.surface.context.flush();
      user.surface.context.dispose();
    } catch (e) {
      console.error(e);
    }

    user.surface.context = undefined;
  }
}

export function getTransformedSelection(user: User) {
  const selection = cloneMask(user.selection);
  transformAndClipMask(selection, user.surface);
  return selection;
}

export function getTransformedSelectionBounds(user: User) {
  if (isMaskEmpty(user.selection) || hasZeroTransform(user.surface)) {
    return createRect(0, 0, 0, 0);
  } else if (hasIdentityTransform(user.surface)) {
    return cloneRect(user.selection.bounds);
  } else {
    return getTransformedSelection(user).bounds;
  }
}

export function isAdult(birthdate: Date | undefined) {
  return birthdate && birthdate.getTime() <= fromNow(-(18 * YEAR)).getTime();
}
