import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Feature, BannerInfo, Permission, TagInfo, Drawing, SubscriptionPlan, CreateEntityData, Rewards, UserData, InterviewInviteStatus } from '../common/interfaces';
import { Model } from './model';
import { ReferenceWindowRecentDrawing } from '../components/shared/editor/editor-reference-window/editor-reference-window';

export interface ManageTeamData {
  _id: string;
  avatar: string;
  name: string;
  slug: string;
  pro?: SubscriptionPlan;
  featureFlags?: Feature[];
  storageUsage?: { limit: number, used: number };
}

@Injectable({ providedIn: 'any' })
export class ManageService {
  constructor(public router: Router) {
  }
  team(_teamId: string): ManageTeamData | undefined {
    return undefined;
  }
  teams(_teamId: string[]): ManageTeamData[] {
    return [];
  }
  userData(): UserData | undefined {
    return undefined;
  }
  project(_projectId: string): { name: string; } | undefined {
    return undefined;
  }
  async folder(_folderId: string): Promise<{ name: string; } | undefined> {
    return undefined;
  }
  activeTeam(): { avatar: string; pro?: SubscriptionPlan, featureFlags?: Feature[] } | undefined {
    return undefined;
  }
  hasTrial() {
    return false;
  }
  error(message: string) {
    DEVELOPMENT && console.error(message);
  }
  warning(message: string, _limit = false) {
    DEVELOPMENT && console.warn(message);
  }
  upgrade(_openedBy: string, _type: 'individual' | 'team' = 'individual') {
  }
  reachedStorageLimit(_team?: ManageTeamData, _destinantion = false) {
  }
  isStorageLimitActive(_team?: ManageTeamData): boolean {
    if (IS_HOSTED) return false;
    return _team ? !_team.featureFlags?.includes(Feature.StorageNoUsageLimits) : true;
  }
  isStorageLimitExceeded(_team?: ManageTeamData): boolean {
    return false;
  }
  userStorageUsage() {
    return { used: 0, limit: 1 };
  }
  handleErrorMessages(_message?: string) {
  }
  async getStorageUsage(_teamId?: string, _fetch?: boolean) {
    return { used: 0, limit: 1 };
  }
  hasPermission(_permission: Permission[]) {
    return false;
  }
  hasNewFeature(_feature: string) {
    return false;
  }
  async addTag(_name: string): Promise<TagInfo | void> {
    return undefined;
  }
  async getFirstInSequence(_drawingId: string): Promise<string | undefined> {
    return undefined;
  }
  getBanner(): BannerInfo | undefined {
    return undefined;
  }
  toggleBannersOff() {
  }
  async importEntityWithToast(_name: string, _files: File[], _folderId: string | undefined): Promise<string | undefined> {
    return undefined;
  }
  // used in editor
  async importDrawing(_info: CreateEntityData, _files: File | Blob): Promise<string | undefined> {
    return undefined;
  }
  updateDrawing(_drawing: Drawing) {
  }
  removeDrawing(_id: string, _name: string) {
  }
  async contactUs() {
  }
  hasToolTrial(_trial: string) {
    return false;
  }
  startedToolTrial(_trial: string) {
    return false;
  }
  toolTrialDaysLeft(_trial: string) {
    return 0;
  }
  async startToolTrial(_trial: string) {
  }

  async showExportModal(_shortId: string, _model: Model) {

  }
  async openDrawingReferenceWindow(_drawingId: string): Promise<any | undefined> {
    return undefined;
  }

  async getRecentReferenceWindowDrawing(): Promise<ReferenceWindowRecentDrawing[]> {
    return [];
  }

  async getRecentEntities(_size: number): Promise<any[]> {
    return [];
  }

  async ensureArtdeskFolderExists(_folderName: string): Promise<string | undefined> {
    return undefined;
  }

  async optInToAds(_optIn = true): Promise<string | undefined> {
    return undefined;
  }

  userId(): string | undefined {
    return '';
  }

  isAnonymous(): boolean | undefined {
    return undefined;
  }

  isUserVerified(): boolean | undefined {
    return undefined;
  }

  async generateRandomName(): Promise<string | undefined> {
    return undefined;
  }

  rewards(): Rewards | undefined {
    return undefined;
  }

  participatedDrawings() {
    return 0;
  }

  async getUserToolStats(): Promise<{ [name: string]: number } | undefined> {
    return undefined;
  }

  async updateCustomerInsightsInviteStatus(_status: InterviewInviteStatus) {

  }
}
