import { userOwnsAnyLayers } from '../common/user';
import { hasFreeLayers } from '../common/drawing';
import { isLayerVisible } from '../common/layer';
import { isLayerEmpty } from '../common/layerUtils';
import { HelpSection, HelpSeverity, IHelpService, ToolError } from '../common/interfaces';
import { storageGetItem, storageSetItem } from './storage';
import { Editor } from './editor';
import { ACCESS_DENIED } from '../common/constants';
import { invalidEnum } from '../common/baseUtils';

export function helpLockedLayer(helpService: IHelpService) {
  helpService.show({ text: `Layer is locked`, section: HelpSection.Layer });
}

export function helpNotLoaded(helpService: IHelpService) {
  helpService.show({ text: `The drawing is still loading`, section: HelpSection.Layer });
}

export function helpNoActiveLayer(helpService: IHelpService, ownsLayers: boolean, hasFreeLayers: boolean) {
  let text: string;

  if (ownsLayers) {
    text = `You don't have any layers selected, click on one of your layers to select it`;
  } else {
    text = `You don't own a layer, `;
    text += hasFreeLayers ? `double click to take one or create a new one` : `create one to start drawing`;
  }

  helpService.show({ text, section: HelpSection.Layer, highlights: ['add-layer'] });
}

function helpHiddenLayer(helpService: IHelpService) {
  helpService.show({ text: `You're trying to draw on hidden layer`, section: HelpSection.Layer });
}

function helpOpacityZeroLayer(helpService: IHelpService) {
  helpService.show({ text: `You're trying to draw on layer with 0% opacity`, section: HelpSection.Layer });
}

function helpLockedOpacityLayer(helpService: IHelpService) {
  helpService.show({ text: `You're trying to draw on empty layer with locked opacity`, section: HelpSection.Layer });
}

export function helpImpossibleOnTextLayer(editor: Editor, customToDoWhat = '') {
  let text = 'Select a Drawing Layer to ';
  if (customToDoWhat !== '') {
    text += `${customToDoWhat}.`;
  } else if (editor.selectedTool) {
    text += `use ${editor.selectedTool.name}.`;
  } else {
    text += 'do that.';
  }
  editor.helpService.show({ text, section: HelpSection.Layer, severity: HelpSeverity.Warning });
}

export function showHelpAlert({ activeLayer, helpService, drawing }: Editor) {
  if (drawing.loaded !== 1 || drawing.loadingFailed) {
    helpNotLoaded(helpService);
  } else if (!activeLayer) {
    helpNoActiveLayer(helpService, userOwnsAnyLayers(drawing.user, drawing), hasFreeLayers(drawing));
  } else if (!isLayerVisible(activeLayer)) {
    helpHiddenLayer(helpService);
  } else if (activeLayer.opacity === 0) {
    helpOpacityZeroLayer(helpService);
  } else if (activeLayer.locked) {
    helpLockedLayer(helpService);
  } else if (isLayerEmpty(activeLayer) && activeLayer.opacityLocked) {
    helpLockedOpacityLayer(helpService);
  } else {
    DEVELOPMENT && console.warn('no active layer (unknown reason)');
  }
}

export function showHelpAlertForError(error: ToolError, editor: Editor) {
  switch (error) {
    case ToolError.EditorLocked:
      editor.helpService.show({ text: 'Editor is locked', section: HelpSection.Layer });
      break;
    case ToolError.DrawingNotLoaded:
      editor.helpService.show({ text: 'Drawing is not loaded', section: HelpSection.Layer });
      break;
    case ToolError.NoActiveTool:
      editor.helpService.show({ text: 'No active tool', section: HelpSection.Layer });
      break;
    case ToolError.LayerIsEmpty:
      editor.helpService.show({ text: 'Layer is empty', section: HelpSection.Layer });
      break;
    case ToolError.InvalidViewport:
      editor.helpService.show({ text: 'Invalid viewport', section: HelpSection.Layer });
      break;
    case ToolError.AccessDenied:
      editor.helpService.show({ text: ACCESS_DENIED, section: HelpSection.Layer });
      break;
    case ToolError.MissingShape:
      editor.helpService.show({ text: 'Missing shape', section: HelpSection.Layer });
      break;
    case ToolError.UnableToDrawOnActiveLayer: // TODO: split this into more detailed errors
      showHelpAlert(editor);
      break;
    case ToolError.ImpossibleOnTextLayer:
      helpImpossibleOnTextLayer(editor);
      break;
    case ToolError.WaitingForServerResponse:
      editor.helpService.show({ text: 'Waiting for server response', section: HelpSection.Layer });
      break;
    case ToolError.NoError:
    case ToolError.BlockedByPresentationMode:
    case ToolError.ImpossibleOnTextLayerInvokedRasterizing:
      break;
    case ToolError.WaitingForFontsToLoad:
      editor.helpService.show({ text: 'Waiting for fonts to load', section: HelpSection.Layer });
      break;
    case ToolError.UnableToInteractWithTextLayer:
      editor.helpService.show({ text: 'Unable to interact with text layer', section: HelpSection.Layer });
      break;
    case ToolError.ImpossibleOnPerspectiveGridLayer:
      editor.helpService.show({ text: 'Unable to do that on a perspective grid layer', section: HelpSection.Layer });
      break;
    case ToolError.NotUsableOnMultiboard:
      editor.helpService.show({ text: 'Unable to use this tool on multiboard', section: HelpSection.Layer });
      break;
    case ToolError.OnlyUsableOnMultiboard:
      editor.helpService.show({ text: 'This tool can only be used on multiboard', section: HelpSection.Layer });
      break;
    default:
      invalidEnum(error);
  }
}

let chatUsed = !!storageGetItem('chat-used');

export function showChatHelp() {
  return !chatUsed;
}

export function dismissChatHelp() {
  chatUsed = true;
  storageSetItem('chat-used', 'y');
}
