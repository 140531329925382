import { KeyValue } from '@angular/common';
import { Directive, Input, TemplateRef, Component } from '@angular/core';
import { EditorPopupOutletService } from '../../../services/popup-service';

@Directive({
  selector: '[popup]'
})
export class EditorPopupDirective {
  @Input() popupContent: TemplateRef<any> | undefined = undefined;
  @Input() popup = ''; // popup id

  @Input() set visible(val: boolean) {
    if (val) {
      this.service.openedPopups.add(this.popup);
    } else {
      this.service.openedPopups.delete(this.popup);
    }
  }

  constructor(private service: EditorPopupOutletService) { }

  ngOnInit() {
    if (this.popupContent) {
      this.service.popups.set(this.popup, this.popupContent);
    }
  }

  ngOnDestroy() {
    this.service.popups.delete(this.popup);
  }
}

@Component({
  selector: 'editor-popup-outlet',
  template: `
  <ng-container *ngFor="let popup of service.popups | keyvalue; trackBy: trackByPopup">
    <ng-container *ngIf="service.openedPopups.has(popup.key)">
      <ng-container *ngTemplateOutlet="popup.value"></ng-container>
    </ng-container>
  </ng-container>`,
})
export class EditorPopupOutlet {
  constructor(public service: EditorPopupOutletService) { }

  trackByPopup(_index: number, item: KeyValue<string, TemplateRef<any>>): string {
    return item.key;
  }
}
