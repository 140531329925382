import { ITool, IToolEditor, IToolModel, TabletEvent, IToolData, ToolId, hasShiftKey, CursorType, LayerData } from '../interfaces';
import { distance } from '../mathUtils';
import { faPlusSquare } from '../icons';
import { isRectEmpty, resetRect, setRectDynamic, createRect, cloneRect } from '../rect';
import { redraw } from '../../services/editorUtils';
import { finishTransform } from '../toolUtils';
import { Editor } from '../../services/editor';
import { addLayerToDrawing } from '../layerToolHelpers';
import { layerFromState } from '../layer';
import { addBoard } from '../../services/boardActions';

export interface AddBoardToolData extends IToolData {
  layerData: LayerData;
  layerIndex: number;
}

export class AddBoardTool implements ITool {
  id = ToolId.AddBoard;
  name = 'Add board';
  description = '';
  learnMore = '';
  // video = { url: '/assets/videos/.mp4', width: 374, height: 210 };
  icon = faPlusSquare;
  cursor = CursorType.Crosshair;
  navigation = false;
  continuousRedraw = true;
  usesModifiers = true;
  scrollView = true;
  fixedRatio = false;
  rect = createRect(0, 0, 0, 0);
  cancellableLocally = true;
  skipMoves = true;
  fields = [];
  private shift = false;
  private startX = 0;
  private startY = 0;
  private endX = 0;
  private endY = 0;
  private originalStartX = 0;
  private originalStartY = 0;
  private moved = false;
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  do(tool: AddBoardToolData) {
    const { drawing, user } = this.model;
    const layer = layerFromState(tool.layerData);
    finishTransform(this.model, `AddBoardTool:do`);
    user.history.pushAddLayer(tool.layerData, tool.layerIndex);
    addLayerToDrawing(this.editor, user, drawing, layer, tool.layerIndex);
    layer.owner = user;
    redraw(this.editor);
  }
  start(x: number, y: number, pressure: number, e?: TabletEvent) {
    const { drawing } = this.model;
    x -= drawing.x;
    y -= drawing.y;

    this.shift = !!e && hasShiftKey(e);
    this.originalStartX = x;
    this.originalStartY = y;
    this.startX = x | 0;
    this.startY = y | 0;
    this.moved = false;
    this.fixedRatio = false;
    resetRect(this.rect);

    this.doMove(x, y, pressure, e);
  }
  move(x: number, y: number, pressure: number, e?: TabletEvent) {
    const { drawing } = this.model;
    x -= drawing.x;
    y -= drawing.y;

    this.doMove(x, y, pressure, e);
  }
  end(x: number, y: number, pressure: number, e?: TabletEvent) {
    const { drawing } = this.model;
    x -= drawing.x;
    y -= drawing.y;

    this.doMove(x, y, pressure, e);

    if (!this.moved || isRectEmpty(this.rect)) return;

    const editor = this.editor as Editor;
    editor.drawingInProgress = false;
    addBoard(editor, this.model, cloneRect(this.rect));
    resetRect(this.rect);
    redraw(this.editor);
  }
  private doMove(x: number, y: number, _pressure: number, e?: TabletEvent) {
    if (!e) throw new Error('Missing event');

    this.moved = this.moved || distance(x, y, this.originalStartX, this.originalStartY) > 0;
    this.endX = x | 0;
    this.endY = y | 0;

    if (this.shift !== hasShiftKey(e)) this.fixedRatio = this.shift = hasShiftKey(e);

    if (this.moved) {
      setRectDynamic(this.rect, this.startX, this.startY, this.endX - this.startX, this.endY - this.startY, this.fixedRatio, false);
    } else {
      resetRect(this.rect);
    }

    redraw(this.editor);
  }
}
