export function getCookie(name: string, cookieHeader?: string) {
  if (SERVER && cookieHeader === undefined) return '';

  const escapedName = encodeURIComponent(name).replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');
  const regExp = new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
  const encodedURIComponent = regExp.exec(cookieHeader ?? document.cookie)?.[1] ?? '';

  try {
    return decodeURIComponent(encodedURIComponent);
  } catch {
    return encodedURIComponent; // probably it is not uri encoded. return as-is
  }
}

export function setCookie(name: string, value: string, options: {
  expires?: number | Date; path?: string; domain?: string; secure?: boolean; sameSite?: 'Lax' | 'None' | 'Strict';
} = {}) {
  if (SERVER) return;

  let cookieString = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

  if (options.expires) {
    if (typeof options.expires === 'number') {
      const dateExpires = new Date(new Date().getTime() + options.expires * 1000 * 60 * 60 * 24);
      cookieString += 'expires=' + dateExpires.toUTCString() + ';';
    } else {
      cookieString += 'expires=' + options.expires.toUTCString() + ';';
    }
  }

  if (options.path) cookieString += 'path=' + options.path + ';';
  if (!DEVELOPMENT && options.domain) cookieString += 'domain=' + options.domain + ';';

  if (options.secure === false && options.sameSite === 'None') {
    options.secure = true;
    // https://github.com/stevermeister/ngx-cookie-service/issues/86#issuecomment-597720130
    DEVELOPMENT && console.warn(`Cookie ${name} was forced with secure flag because sameSite=None`);
  }
  if (options.secure) cookieString += 'secure;';
  if (!options.sameSite) options.sameSite = 'Lax';

  cookieString += 'sameSite=' + options.sameSite + ';';
  document.cookie = cookieString;
}

export function deleteCookie(name: string, path: string, domain?: string, secure?: boolean, sameSite: 'Lax' | 'None' | 'Strict' = 'Lax') {
  setCookie(name, '', { expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, ...!!DEVELOPMENT && { domain }, secure, sameSite });
}
