import { DEFAULT_BACKGROUND_COLOR } from '../common/constants';
import { CreateDrawingData, IToolModel, LayerData, Rect, ToolId } from '../common/interfaces';
import { layerFromState } from '../common/layer';
import { addLayerToDrawing } from '../common/layerToolHelpers';
import { createRect } from '../common/rect';
import { finishTransform } from '../common/toolUtils';
import type { AddBoardToolData } from '../common/tools/addBoardTool';
import { sendLayerOrder } from './drawingConnection';
import { unlockEditor, lockEditor, Editor } from './editor';
import { redraw } from './editorUtils';
import { createNewLayers } from './layerActions';

export function addBoard(editor: Editor, model: IToolModel, rect: Rect, createData: Partial<CreateDrawingData> = {}, data?: Uint8Array) {
  const { user, drawing } = model;
  const finished = model.startTask(data ? 'Importing board' : 'Creating board');

  createNewLayers(editor, 1).then(async ([id]) => {
    if (!id) throw new Error('No layerId for creating board layer');

    let layersNumber = 0;
    for (const layer of drawing.layers) {
      if (layer.ref) layersNumber++;
    }

    const name = `Image #${layersNumber + 1}`;
    const newDrawingData: CreateDrawingData = {
      name,
      width: rect.w,
      height: rect.h,
      background: DEFAULT_BACKGROUND_COLOR,
      board: drawing._id,
      ...createData,
    };

    let newDrawingId: string;

    try {
      if (data) {
        // TODO: maybe need to use other import here
        const { id, width, height } = await editor.model.importDrawing(name, data, 'board');
        newDrawingId = id;
        newDrawingData.width = width;
        newDrawingData.height = height;
        rect.x -= width / 2;
        rect.y -= height / 2;
      } else {
        newDrawingId = await editor.model.createDrawing(newDrawingData);
      }
    } catch (e) {
      model.toasts?.error({ message: e.message });
      return;
    }

    unlockEditor(editor);
    finishTransform({ editor, drawing, user }, 'AddBoardTool:end'); // active drawing could have changed by this point, don't use model

    const activeLayer = user.activeLayer;
    const layerIndex = activeLayer === undefined ? drawing.layers.length - 1 : drawing.layers.indexOf(activeLayer);
    const layerData: LayerData = {
      id,
      name,
      ref: {
        id: newDrawingId,
        x: rect.x,
        y: rect.y,
        scale: 1,
        rotation: 0,
        drawingRect: createRect(0, 0, newDrawingData.width, newDrawingData.height),
      },
    };

    const layer = layerFromState(layerData);
    layer.owner = user;
    layer.ref!.drawingRect = createRect(0, 0, newDrawingData.width, newDrawingData.height);
    user.history.pushAddLayer(layerData, layerIndex);
    addLayerToDrawing(editor, user, drawing, layer, layerIndex);
    sendLayerOrder(user, drawing);
    redraw(editor);

    model.doTool<AddBoardToolData>(0, { id: ToolId.AddBoard, layerData, layerIndex });
  }).catch(e => {
    editor.errorReporter.reportError(e);
  }).finally(() => {
    unlockEditor(editor);
    finished();
  });

  lockEditor(editor, 'board layer creation');
}
