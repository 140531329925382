import { Injectable, OnDestroy } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service.interface';
import { Editor } from './editor';
import { Subject } from 'rxjs';
import { STATS_MILESTONES } from '../common/constants';

@Injectable()
export class ToolStatsService implements OnDestroy {
  milestones$ = new Subject<{ count: number, message: string }>();

  milestones = STATS_MILESTONES;
  globalStats: {[name: string] : number} = {};

  constructor(
    private features: FeatureFlagService,
    private editor: Editor,
  ) {
    editor.toolStats$.subscribe((stats) => {
      if (!this.enabled || !this.isVisible || (!stats.brush && !stats.pencil)) return;
      this.received(stats);
    });
    this.editor.model.userToolStats$.subscribe((stats) => {
      this.globalStats = { ...stats };
    });
  }

  testMilestone() {
    const milestone = this.milestone / 2;
    const message = this.milestoneMessage(milestone);
    if (message) this.milestones$.next({ count: milestone, message });
  }

  received(stats: {[name: string] : number}) {
    const count = (stats?.brush || 0) + (stats?.pencil || 0);
    // Check if milestone was reached
    if (count === this.milestone) {
      const message = this.milestoneMessage(count);
      if (message) this.milestones$.next({ count, message });
    }
  }

  milestoneMessage(milestone: number) {
    return this.milestones.find(m => m.count === milestone)?.message || '';
  }

  get enabled(): boolean {
    return this.features.isActivityStatsBrush;
  }

  get isVisible(): boolean {
    return !!this.editor.model.settings.showStrokesActivity;
  }

  get statsBarVisible() {
    return !!this.editor.model.settings?.showActivityBar;
  }

  get count(): number {
    let count = 0;
    const accountId = this.editor.model.user.accountId;

    if (this.editor.model.settings.showStrokesFullActivity) {
      count = this.getStatsCount(this.globalStats);
    } else {
      const stats = this.editor.drawing.toolStats;
  
      if (!accountId || !stats[accountId]) return count;
      count = this.getStatsCount(stats[accountId]);
    }
    return count;
  }

  getStatsCount(stats: { [name: string]: number }) {
    return (stats['pencil'] || 0) + (stats['brush'] || 0);
  }

  get milestone(): number {
    // Milestones are 150, and then double everytime count reached a milestone
    let milestone = 150;
    let count = this.count;
    while (count > milestone) {
      milestone *= 2;
    }
    return milestone;
  }

  ngOnDestroy() {
    this.editor.toolStats$.unsubscribe();
    this.editor.model.userToolStats$.unsubscribe();
  }
}
