import { ROTATION_SNAP, MIN_VIEW_SCALE, MAX_VIEW_SCALE } from './constants';
import { clamp } from './mathUtils';
import { setRect, createRect, moveRect, rectsEqual, copyRect, haveNonEmptyIntersection } from './rect';
import { Point, Rect, Viewport, ViewportState, Mat2d, Mat4, DrawingReference } from './interfaces';
import { createPoint, setPoint } from './point';
import { identityMat2d, translateMat2d, rotateMat2d, scaleMat2d, createMat2d, transformPointByMat2d, invertMat2d, multiplyMat2d, createDecomposedMat2d, decomposeMat2dTo } from './mat2d';
import { identityMat4, translateMat4, scaleMat4, rotateZMat4 } from './mat4';
import { roundCoord } from './compressor';
import { calculateTransformedRectBounds } from './toolSurface';

const tempPoint = createPoint(0, 0);
const tempRect = createRect(0, 0, 0, 0);
const TAU = 2 * Math.PI;

export function isViewportValid({ x, y, scale, rotation, width, height, content }: Viewport) {
  return !Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(scale) && scale > 0 && !Number.isNaN(rotation) && !Number.isNaN(width) && !Number.isNaN(height) && !Number.isNaN(content.x) && !Number.isNaN(content.y) && !Number.isNaN(content.w) && !Number.isNaN(content.h) && width > 0 && height > 0 && content.w > 0 && content.h > 0;
}

export function viewportsEqual(a: Viewport, b: Viewport, ignoreViewportSize = false) {
  return a.x === b.x && a.y === b.y && a.scale === b.scale && a.rotation === b.rotation && a.flipped === b.flipped && a.filter === b.filter && rectsEqual(a.content, b.content) && (ignoreViewportSize || (a.width === b.width && a.height === b.height));
}

export function copyViewport(dst: Viewport, src: Viewport) {
  dst.x = src.x;
  dst.y = src.y;
  dst.scale = src.scale;
  dst.rotation = src.rotation;
  dst.flipped = src.flipped;
  dst.filter = src.filter;
  dst.width = src.width;
  dst.height = src.height;
  copyRect(dst.content, src.content);
}

export function getViewportState({ x, y, scale, rotation, flipped, filter }: Viewport): ViewportState {
  return { x, y, scale, rotation, flipped, filter };
}

export function matchFromOtherViewport(view: Viewport, toMatch: Viewport) {
  let { x, y, width, height, scale, rotation, flipped, filter } = toMatch;
  const pt = createPoint(0, 0);

  screenToDocumentXY(pt, width / 2, height / 2, toMatch);

  const scaleWidth = view.width / width;
  const scaleHeight = view.height / height;
  const minScale = Math.min(scaleWidth, scaleHeight);

  view.x = x;
  view.y = y;
  view.scale = (Math.round((scale + Number.EPSILON) * 100) / 100) * minScale;
  view.rotation = rotation;
  view.flipped = flipped;
  view.filter = filter;

  documentToScreenXY(pt, pt.x, pt.y, view);

  view.x += view.width / 2 - pt.x;
  view.y += view.height / 2 - pt.y;
}

export function setViewportState(view: Viewport, state: ViewportState) {
  // safeguards against broken state
  view.x = +(state.x ?? 0);
  view.y = +(state.y ?? 0);
  view.scale = +(state.scale ?? 1);
  view.rotation = +(state.rotation ?? 0);
  view.flipped = !!state.flipped;
  view.filter = state.filter;
}

export function setViewportSize(view: Viewport, width: number, height: number) {
  view.width = width || 1;
  view.height = height || 1;
  clampViewport(view);
}

export function setViewportContentSize(view: Viewport, content: Rect) {
  view.content.x = content.x | 0;
  view.content.y = content.y | 0;
  view.content.w = Math.max(content.w | 0, 1);
  view.content.h = Math.max(content.h | 0, 1);
  clampViewport(view);
}

export function setViewportSizes(view: Viewport, width: number, height: number, content: Rect) {
  view.width = width || 1;
  view.height = height || 1;
  view.content.x = content.x | 0;
  view.content.y = content.y | 0;
  view.content.w = Math.max(content.w | 0, 1);
  view.content.h = Math.max(content.h | 0, 1);
}

export function getViewportAngle(view: Viewport, x: number, y: number) {
  const dx = x - view.width / 2;
  const dy = y - view.height / 2;
  return Math.atan2(dx, dy);
}

export function centerViewport(view: Viewport, shiftXY: boolean) {
  view.x = (view.width - view.content.w * view.scale) / 2;
  view.y = (view.height - view.content.h * view.scale) / 2;

  if (shiftXY) {
    view.x -= view.content.x * view.scale;
    view.y -= view.content.y * view.scale;
  }
}

export function moveViewport(view: Viewport, x: number, y: number) {
  moveViewportTo(view, view.x + x, view.y + y);
}

export function moveViewportTo(view: Viewport, x: number, y: number) {
  view.x = x;
  view.y = y;
  clampViewport(view);
}

export function zoomViewport(view: Viewport, delta: number) {
  zoomViewportAt(view, delta, view.width / 2, view.height / 2);
}

export function zoomViewportAt(view: Viewport, delta: number, x: number, y: number) {
  scaleViewportAt(view, view.scale * Math.pow(2, delta * 10), x, y);
}

export function scaleViewportAt(view: Viewport, scale: number, x: number, y: number) {
  const oldScale = view.scale || 1;
  view.scale = clamp(scale, MIN_VIEW_SCALE, MAX_VIEW_SCALE);
  view.x = x - ((x - view.x) * (view.scale / oldScale));
  view.y = y - ((y - view.y) * (view.scale / oldScale));
  clampViewport(view);
}

export function rotateViewport(view: Viewport, angle: number) {
  rotateViewportAt(view, angle, view.width / 2, view.height / 2);
}

export function rotateViewportBy(view: Viewport, angle: number) {
  rotateViewport(view, view.rotation + angle);
}

export function rotateViewportXY(view: Viewport, x: number, y: number, startAngle: number, snap: boolean) {
  let rotation = getViewportAngle(view, x, y) + startAngle;
  if (snap) rotation = Math.round(rotation / ROTATION_SNAP) * ROTATION_SNAP;
  rotateViewport(view, rotation);
}

export function rotateViewportAt(view: Viewport, angle: number, x: number, y: number, snap = 0.02) {
  while (angle < -Math.PI) angle += TAU;
  while (angle >= Math.PI) angle -= TAU;
  if (Math.abs(angle) < snap) angle = 0;

  setPoint(tempPoint, x, y);
  screenToDocumentPoint(tempPoint, view);
  view.rotation = angle;
  documentToScreenPoint(tempPoint, view);
  view.x += x - tempPoint.x;
  view.y += y - tempPoint.y;
}

export function fitViewport(view: Viewport, expand: boolean, shiftXY: boolean) {
  const pad = getPadding(view);
  const w = Math.max(view.width - 2 * pad, 1);
  const h = Math.max(view.height - 2 * pad, 1);

  if ((view.content.w / w) > (view.content.h / h)) {
    view.scale = w / Math.max(view.content.w, 1);
  } else {
    view.scale = h / Math.max(view.content.h, 1);
  }

  if (!expand) view.scale = Math.min(view.scale, 1);
  view.scale = clamp(view.scale, MIN_VIEW_SCALE, MAX_VIEW_SCALE);

  centerViewport(view, shiftXY);
}

export function fitViewportOnScreen(view: Viewport, shiftXY: boolean) {
  view.flipped = false;
  view.rotation = 0;
  fitViewport(view, true, shiftXY);
}

export function fitViewportToActualPixels(view: Viewport, shiftXY: boolean) {
  view.flipped = false;
  view.rotation = 0;
  view.scale = 1;
  centerViewport(view, shiftXY);
}

export function flipViewport(view: Viewport) {
  flipViewportAt(view, view.width / 2, view.height / 2);
}

export function flipViewportAt(view: Viewport, x: number, y: number) {
  setPoint(tempPoint, x, y);
  screenToDocumentPoint(tempPoint, view);
  view.flipped = !view.flipped;
  view.rotation = -view.rotation;
  documentToScreenPoint(tempPoint, view);
  view.x += x - tempPoint.x;
  view.y += y - tempPoint.y;
}

function getPadding(view: Viewport) {
  return Math.min(view.width, view.height) * 0.05;
}

export function clampViewport(view: Viewport) {
  if (DEVELOPMENT && !TESTS) return;

  const pad = getPadding(view);

  if (view.rotation === 0 && !view.flipped) {
    view.x = clamp(view.x, pad - view.content.w * view.scale, view.width - pad);
    view.y = clamp(view.y, pad - view.content.h * view.scale, view.height - pad);
  } else {
    const x = view.x;
    const y = view.y;
    view.x = 0;
    view.y = 0;
    setRect(tempRect, 0, 0, view.content.w, view.content.h);
    documentToScreenRect(tempRect, view);
    view.x = clamp(x, pad - (tempRect.x + tempRect.w), view.width - tempRect.x - pad);
    view.y = clamp(y, pad - (tempRect.y + tempRect.h), view.height - tempRect.y - pad);
  }
}

export function createViewportMatrix2d(mat: Mat2d, view: Viewport, ratio = 1) {
  identityMat2d(mat);
  scaleMat2d(mat, mat, ratio, ratio);
  translateMat2d(mat, mat, view.x, view.y);
  rotateMat2d(mat, mat, -view.rotation);
  scaleMat2d(mat, mat, view.scale * (view.flipped ? -1 : 1), view.scale);

  return mat;
}

export function createViewportMatrix4(mat: Mat4, view: Viewport, exactView = false) {
  const x = exactView ? Math.round(view.x) : view.x;
  const y = exactView ? Math.round(view.y) : view.y;

  identityMat4(mat);

  // transform to clip space
  translateMat4(mat, mat, -1, 1, 0);
  scaleMat4(mat, mat, 2 / (view.width || 1), -2 / (view.height || 1), 1);

  translateMat4(mat, mat, x, y, 0);
  rotateZMat4(mat, mat, -view.rotation);
  scaleMat4(mat, mat, view.scale * (view.flipped ? -1 : 1), view.scale, 1);

  return mat;
}

export function applyViewportTransform(context: CanvasRenderingContext2D, view: Viewport, ratio: number) {
  context.scale(ratio, ratio);
  context.translate(view.x, view.y);
  context.rotate(-view.rotation);
  context.scale(view.scale, view.scale);
  if (view.flipped) context.scale(-1, 1);
}

export function screenToDocumentXY(output: Point, x: number, y: number, view: Viewport) {
  setPoint(output, x, y);
  screenToDocumentPoint(output, view);
}

export function screenToDocumentAndRoundXY(output: Point, x: number, y: number, view: Viewport) {
  screenToDocumentXY(output, x, y, view);
  output.x = roundCoord(output.x);
  output.y = roundCoord(output.y);
}

export function documentToAbsoluteDocument(point: Point, drawing: Rect) {
  point.x += drawing.x;
  point.y += drawing.y;
}

export function documentToAbsoluteDocumentRect(rect: Rect, drawing: Rect) {
  moveRect(rect, drawing.x, drawing.y);
}

export function absoluteDocumentToDocumentRect(rect: Rect, drawing: Rect) {
  moveRect(rect, -drawing.x, -drawing.y);
}

export function absoluteDocumentToDocuemnt(point: Point, drawing: Rect) {
  point.x -= drawing.x;
  point.y -= drawing.y;
}

const tempMat2d = createMat2d();

export function screenToDocumentPoint(point: Point, view: Viewport) {
  createViewportMatrix2d(tempMat2d, view);
  invertMat2d(tempMat2d, tempMat2d);
  transformPointByMat2d(point, tempMat2d);
}

export function documentToScreenXY(point: Point, x: number, y: number, view: Viewport) {
  setPoint(point, x, y);
  documentToScreenPoint(point, view);
}

export function documentToScreenPoint(point: Point, view: Viewport) {
  createViewportMatrix2d(tempMat2d, view);
  transformPointByMat2d(point, tempMat2d);
}

export function screenToDocumentRect(rect: Rect, view: Viewport) {
  createViewportMatrix2d(tempMat2d, view);
  invertMat2d(tempMat2d, tempMat2d);
  calculateTransformedRectBounds(rect, rect, tempMat2d, false);
}

export function documentToScreenRect(rect: Rect, view: Viewport) {
  createViewportMatrix2d(tempMat2d, view);
  calculateTransformedRectBounds(rect, rect, tempMat2d, false);
}

export function documentToScreenPoints(points: Point[], view: Viewport): void {
  createViewportMatrix2d(tempMat2d, view);

  for (const point of points) {
    transformPointByMat2d(point, tempMat2d);
  }
}

export function getRotFromView(view: Viewport) {
  let rot = Math.round(view.rotation / (Math.PI / 2)) | 0;
  while (rot < 0) rot += 4;
  rot = rot % 4;
  return rot;
}

const mat1 = createMat2d();
const mat2 = createMat2d();
const decomposed = createDecomposedMat2d();

export function refTransform(mat: Mat2d, ref: Partial<DrawingReference>) {
  identityMat2d(mat);
  translateMat2d(mat, mat, ref.drawingRect?.x || 0, ref.drawingRect?.y || 0);
  translateMat2d(mat, mat, ref.x || 0, ref.y || 0);
  rotateMat2d(mat, mat, ref.rotation || 0);
  scaleMat2d(mat, mat, ref.scale || 1, ref.scale || 1);
}

export function transformViewportToRef(view: Viewport, ref: DrawingReference) {
  createViewportMatrix2d(mat1, view);

  refTransform(mat2, ref);
  multiplyMat2d(mat2, mat1, mat2);
  decomposeMat2dTo(mat2, decomposed);
  view.x = decomposed.translateX;
  view.y = decomposed.translateY;
  view.rotation = -decomposed.rotate;
  view.scale = decomposed.scaleY;
}

export function transformViewportFromRef(view: Viewport, ref: DrawingReference) {
  createViewportMatrix2d(mat1, view);

  refTransform(mat2, ref);
  invertMat2d(mat2, mat2);
  multiplyMat2d(mat2, mat1, mat2);
  decomposeMat2dTo(mat2, decomposed);
  view.x = decomposed.translateX;
  view.y = decomposed.translateY;
  view.rotation = -decomposed.rotate;
  view.scale = decomposed.scaleY;
}

const viewRect = createRect(0, 0, 0, 0);
const testRect = createRect(0, 0, 0, 0);

export function isDocumentSpaceRectInsideViewport(view: Viewport, drawing: Rect) {
  // TODO: this can be more precise for rotated rects
  setRect(viewRect, 0, 0, view.width, view.height);

  copyRect(testRect, drawing);
  absoluteDocumentToDocumentRect(testRect, drawing);
  documentToScreenRect(testRect, view);

  return haveNonEmptyIntersection(viewRect, testRect);
}

// TODO MULTIBOARD:
//       need to reworks how positioning and viewports work for board drawings
//       current way of adding rotation of viewports doesn't make sense, because they rotate around different origin
//       how do we solve this, right now this works correctly only when creating 2 matrices
//       do we want to use matrices and then infer viewport params back from the matrices ?
//       this might work

// TODO MULTIBOARD: fix drawingRect, cacheId, drawingBackground saving in layer.ref ???
