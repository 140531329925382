import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { BillingInterval, CreateRole, DrawingOwnerRole, EntityData, Invitee, ProjectData, StorageUsageData, TeamData, TeamInvite, TeamMember, TeamRole, UpdateRole, UpdateTeam } from 'shared/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { handleHttpError, hasPermissionFlag, hasPermissionFlags, routeToTeam, toPromise } from 'shared/utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvitationService } from './invitation.service';
import { Permission } from 'magma/common/interfaces';
import { Router } from '@angular/router';
import { TeamMembersService } from './team-members.service';
import { TeamMembersStore } from 'services/team-members.store';
import { TeamsQuery } from './team.query';
import { TeamsStore } from './team.store';
import { ToastService } from 'magma/services/toast.service';
import { UserService } from './user.service';
import { flatten } from 'lodash';
import { getEntityPassword } from 'magma/common/clientUtils';
import { isEntityOwner } from 'components/utils';
import { ErrorReporter } from 'magma/services/errorReporter';
import { ProjectService } from './projects.service';
import { AuthService } from './auth.service';
import { createTranslate, nonTranslatable } from 'magma/common/i18n';

const tr = createTranslate();

export interface SubscriptionPaymentPayload {
  planType: string;
  interval: BillingInterval;
  new?: boolean;
  downgrade?: boolean;
}

const ARTDESK_ROLES = [DrawingOwnerRole];

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TeamService {
  teamsReady$ = new BehaviorSubject<boolean>(false);
  correctActiveTeam$ = new Subject<string>(); // called when team slug is corrected
  showSuccessTeamUpgrade$ = new BehaviorSubject({ show: false, isIntervalUpgrade: false, isDowngrade: false });
  showCreateTeamModal$ = new BehaviorSubject<boolean>(false);

  // TODO: fix initial value
  teamSubscriptionData$ = new BehaviorSubject<SubscriptionPaymentPayload>({ new: true } as SubscriptionPaymentPayload);
  private reloadTeams$ = new BehaviorSubject(null);

  reloadTeamRoles$ = new BehaviorSubject<boolean>(false);
  teamRoles$ = new BehaviorSubject<TeamRole[]>([]); // all roles in current team

  constructor(
    private httpClient: HttpClient,
    private teamsStore: TeamsStore,
    private invitationService: InvitationService,
    private teamsQuery: TeamsQuery,
    private teamMembersStore: TeamMembersStore,
    private router: Router,
    private userService: UserService,
    private toastService: ToastService,
    private teamMemberService: TeamMembersService,
    private errorReporter: ErrorReporter,
    private projectService: ProjectService,
    private authService: AuthService,
  ) {
    combineLatest([
      userService.user$.pipe(map(user => user?._id), distinctUntilChanged()),
      this.reloadTeams$,
    ]).pipe(
      switchMap(() => this.fetchTeams()),
      untilDestroyed(this),
    ).subscribe();

    this.teamsQuery.selectActive().pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.memoizedPermissions.clear();
    });

    combineLatest([this.teamsQuery.selectActive(), this.reloadTeamRoles$]).pipe(
      filter(([t]) => !!t),
      switchMap(([team]) => this.getRoles(team!._id)),
      untilDestroyed(this),
    ).subscribe(roles => {
      this.teamRoles$.next(roles);
      this.teamMemberService.reloadMembers();
    });
  }

  private async fetchTeams() {
    let teams: TeamData[] = [];

    try {
      if (this.userService.user) {
        teams = await toPromise(this.httpClient.get<TeamData[]>('/api/teams'));
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
      return;
    }

    try {
      // TEMP: this returning empty team list or empty team.projects
      if (!teams || !Array.isArray(teams) || teams.some(t => !t.projects || !Array.isArray(t.projects))) {
        this.errorReporter.reportError(`Invalid result from /api/teams`, undefined, JSON.stringify(teams));
      }
    } catch { }

    const teamId = this.teamsQuery.getActiveId();
    this.teamsStore.set(teams);
    this.projectService.setProjects(flatten(teams.map(team => team.projects ?? [])));

    if (teamId && !this.teamsQuery.getActive()) {
      const team = this.teamsQuery.getAll().find(team => team._id === teamId); // TODO: or old slugs
      if (team) {
        this.teamsStore.setActive(team.slug);
        this.correctActiveTeam$.next(team.slug);
        DEVELOPMENT && console.warn('corrected active team');
      }
    }
    this.teamsReady$.next(true);
  }

  setActiveTeamSlug(slug: string | null | undefined) {
    if (DEVELOPMENT && slug?.length === 24) console.warn(`Passed ID instead of slug: ${slug}`);

    if (this.teamsQuery.getActiveId() !== slug) { // TODO: why is this needed ?
      if (slug) {
        const teams = this.teamsQuery.getAll();

        if (teams.length && !teams.find(team => team.slug === slug)) {
          // if we don't have team with this slug, try to match team by _id or old slugs
          const team = teams.find(team => team._id === slug); // TODO: or old slugs

          if (team) {
            slug = team.slug;
            this.correctActiveTeam$.next(slug);
            DEVELOPMENT && console.warn('corrected active team');
          }
        }
      }

      this.teamsStore.setActive(slug ?? null);
      this.teamMembersStore.reset();
      this.teamMemberService.reset();
    }
  }

  getTeam(id: string | undefined): TeamData | undefined {
    return id ? this.teamsQuery.getAll().find(t => t._id === id) : undefined;
  }

  getTeamBySlug(slug: string | undefined): TeamData | undefined {
    return slug ? this.teamsQuery.getAll().find(t => t.slug === slug) : undefined;
  }

  getTeamSlug(id: string | null | undefined) {
    return this.getTeam(id ?? undefined)?.slug;
  }

  isTeamPro(id: string | undefined) {
    const team = this.getTeam(id);
    return team?.pro;
  }

  isUserOrTeamPro(teamId: string | undefined) {
    return !!(this.userService.user?.pro || this.isTeamPro(teamId));
  }

  canUserExportEntity(entity: EntityData) {
    const { user } = this.userService;
    if (!user) return false;

    if (user.isSuperAdmin) return true;

    if (entity.team) {
      const team = this.getTeam(entity.team);
      if (!team) return false;
      return this.hasPermissionFlag(Permission.CanExportEntityAsImage);
    }

    if (isEntityOwner(entity, user)) return true;
    if (entity.userRole === 'admin') return true;
    if (!entity.team && entity.hasPassword && !getEntityPassword(entity.shortId)) return false;

    return true;
  }

  getContributors(teamId: string) {
    return this.httpClient.get<TeamRole[]>(`/api/teams/${teamId}/contributors`).pipe(handleHttpError());
  }

  getEntities(teamId: string) {
    return this.httpClient.get<EntityData[]>(`/api/teams/${teamId}/entities`).pipe(handleHttpError());
  }

  getRoles(teamId: string) {
    return this.httpClient.get<TeamRole[]>(`/api/teams/${teamId}/roles`).pipe(handleHttpError());
  }

  editRole(teamId: string, roleId: string, data: UpdateRole) {
    return this.httpClient.put<TeamRole>(`/api/teams/${teamId}/role/${roleId}`, data).pipe(handleHttpError());
  }

  deleteRole(teamId: string, roleId: string) {
    return this.httpClient.delete(`/api/teams/${teamId}/role/${roleId}`).pipe(handleHttpError());
  }

  createRole(teamId: string, data: CreateRole) {
    return this.httpClient.post<TeamRole>(`/api/teams/${teamId}/roles`, data).pipe(handleHttpError());
  }

  getTeamById(teamId: string) {
    return this.httpClient.get<TeamData>(`/api/teams/${teamId}`).pipe(handleHttpError());
  }

  async createTeam(name: string, isPublic: boolean, usage?: string) {
    const result = await toPromise(this.httpClient.post<TeamData>('/api/teams', { name, isPublic, usage }));
    await this.fetchTeams();
    return result;
  }

  async updateTeam(team: TeamData, teamUpdate: UpdateTeam) {
    await toPromise(this.httpClient.put(`/api/teams/${team._id}`, teamUpdate));

    if (teamUpdate.slug && team.slug !== teamUpdate.slug) {
      const index = this.teamsQuery.getAll().findIndex(t => t._id === team._id);
      this.teamsStore.remove(team.slug);
      this.teamsStore.add({ ...team, ...teamUpdate });
      this.teamsStore.move(this.teamsQuery.getCount() - 1, index);
      this.setActiveTeamSlug(teamUpdate.slug);
      void this.router.navigate(routeToTeam(teamUpdate.slug!, 'settings'));
    } else {
      this.teamsStore.update(team.slug, teamUpdate);
    }
  }

  async updateOwner(team: TeamData, member: TeamMember) {
    try {
      await toPromise(this.httpClient.put(`/api/teams/${team._id}/transfer-ownership/${member.user._id}`, {}));
      this.teamMemberService.reloadMembers();
      this.toastService.success({ message: tr`You're no longer owner of ${nonTranslatable(team.name)}` });
      this.reloadTeams$.next(null); // to refresh ownership state
      void this.router.navigate(routeToTeam(team.slug!));
    } catch (e) {
      this.toastService.error({ message: tr`Failed to transfer ownership over ${nonTranslatable(team.name)}`, subtitle: e.message });
    }
  }

  async leaveTeam(teamId: string) {
    try {
      await toPromise(this.httpClient.delete(`/api/teams/${teamId}/leave`));
      const slug = this.getTeamSlug(teamId);
      this.teamsStore.remove(slug);
      this.authService.refreshNow(); // in case user had pro from the team
    } catch (e) {
      if (e.status === 404) { // user might already be removed from the team, just reload the team list
        this.reloadTeams$.next(null);
      } else {
        throw e;
      }
    }
  }

  reloadTeams() {
    this.reloadTeams$.next(null);
  }

  deleteTeam(teamId: string) {
    return toPromise(this.httpClient.delete(`/api/teams/${teamId}`));
  }

  restoreTeam(teamId: string) {
    return toPromise(this.httpClient.put(`/api/teams/${teamId}/restore`, {}));
  }

  teamDeletionReason(teamId: string, reason: string) {
    return toPromise(this.httpClient.post(`/api/teams/${teamId}/deletion-reason`, { reason }));
  }

  setCreateTeamModal(show: boolean) {
    this.showCreateTeamModal$.next(show);
  }

  getCreateTeamModal() {
    return this.showCreateTeamModal$.asObservable();
  }

  setTeamSubscriptionData(data: SubscriptionPaymentPayload) {
    this.teamSubscriptionData$.next(data);
  }

  getTeamSubscriptionData() {
    return this.teamSubscriptionData$.asObservable();
  }

  setSuccessTeamUpgrade(show: boolean, isIntervalUpgrade = false, isDowngrade = false) {
    this.showSuccessTeamUpgrade$.next({ show, isIntervalUpgrade, isDowngrade });
  }

  getSuccessTeamUpgrade() {
    return this.showSuccessTeamUpgrade$;
  }

  sendInvite(teamId: string, invitation: Invitee[]) {
    return this.invitationService.inviteTeamsMembers(teamId, invitation);
  }

  // TODO: remove this, instead re-fetch team.storage
  getUsageData(teamId: string) {
    return this.httpClient.get<StorageUsageData>(`/api/teams/${teamId}/usage`).pipe(handleHttpError());
  }

  async refreshTeamUsageData(teamId: string) {
    const usage = await toPromise(this.getUsageData(teamId));
    this.teamsStore.update(teamId, team => ({ ...team, storageUsage: usage ?? { used: 0, limit: 1 } }));
    return usage;
  }

  async joinTeam(invite: TeamInvite) {
    await this.invitationService.joinTeam(invite.token);
    await this.fetchTeams();
    await this.router.navigate(routeToTeam(invite.team.slug));
  }

  private memoizedPermissions = new Map<string, boolean>();

  hasPermissionFlag(flags: Permission[] | Permission, project?: ProjectData, entity?: EntityData): boolean {
    const team = this.teamsQuery.getActive();
    const roles = team ? (team.userRoles || []) : ARTDESK_ROLES;
    const key = `${flags}-${project?._id}-${entity?._id}`;
    let permission = this.memoizedPermissions.get(key);

    if (permission === undefined) {
      if (Array.isArray(flags)) {
        permission = hasPermissionFlags(flags, roles, entity, project);
      } else {
        permission = hasPermissionFlag(flags, roles, entity, project);
      }
      this.memoizedPermissions.set(key, permission);
    }

    return permission;
  }
}
