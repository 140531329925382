import { escape } from 'lodash';
import { REMOVED_USER_NAME } from 'magma/common/constants';
import { AppNotification, AppNotificationAction } from 'magma/common/interfaces';
import { Locale, createTranslate, nonTranslatable, translateString } from 'magma/common/i18n';
import { REMOVED_ENTITY_NAME } from './utils';
import { invalidEnum } from 'magma/common/baseUtils';

export function generateNotificationContent(notification: AppNotification, forEmail: boolean, locale?: Locale): { title: string, bodyHTML: string } {
  const { user } = notification.trigger;
  const action = notification.trigger.action;
  const teamName = nonTranslatable(notification.team?.name);
  const userName = user ? nonTranslatable(user.name) : REMOVED_USER_NAME;

  const strong = (text: string | undefined) => nonTranslatable(`<strong>${escape(translateString(text || '', locale))}</strong>`);
  const tr = createTranslate(locale);

  switch (action) {
    case AppNotificationAction.CommentAdded: {
      const comment = notification.trigger.data.comment;
      const entity = notification.trigger.entity;
      const entityName = nonTranslatable(entity?.name) ?? REMOVED_ENTITY_NAME;
      return {
        title: tr`${userName} left a comment on ${entityName}`,
        bodyHTML: tr`${strong(userName)} left a comment on ${strong(entityName)}:` + (forEmail ? '<br><br>' : ' ') + escape(comment),
      };
    }
    case AppNotificationAction.CommentMentioned: {
      const comment = notification.trigger.data.comment;
      const entity = notification.trigger.entity;
      const entityName = nonTranslatable(entity?.name) ?? REMOVED_ENTITY_NAME;
      return {
        title: tr`${userName} mentioned you in a comment on ${entityName}`,
        bodyHTML: tr`${strong(userName)} mentioned you in a comment on ${escape(entityName)}:` + (forEmail ? '<br><br>' : ' ') + escape(comment),
      };
    }
    case AppNotificationAction.CanvasChanged: {
      const entity = notification.trigger.entity;
      const entityName = nonTranslatable(entity?.name) ?? REMOVED_ENTITY_NAME;
      return {
        title: tr`${userName} made changes to ${entityName}`,
        bodyHTML: tr`${strong(userName)} made changes to ${strong(entityName)}`,
      };
    }
    case AppNotificationAction.UserJoinedTeam:
      return {
        title: tr`${userName} has just joined the artspace!`,
        bodyHTML: tr`${strong(userName)} has just joined the artspace!`,
      };
    case AppNotificationAction.TeamMarkedForDeletion:
      if (forEmail) {
        return {
          title: tr`[IMPORTANT] Notice of Deletion of Your Files `,
          bodyHTML: `<div style="font-size:22px; margin-top: 15px; line-height: 36px;">` + tr`The "${escape(teamName)}" artspace has been ${strong(tr`marked for deletion`)} by the owner and will be deleted after ${strong(tr`14 days`)}` + `</div> <br><div style="font-size: 15px;">` + tr`If you want to keep your drawings, download them within 14 days. Please note that the owner may revoke this decision.` + `</div>`,
        };
      } else {
        return {
          title: tr`${userName} marked ${teamName} for deletion`,
          bodyHTML: tr`${strong(userName)} marked ${strong(teamName)} for deletion`,
        };
      }
    case AppNotificationAction.TeamUnmarkedForDeletion:
      return {
        title: tr`${userName} cancelled ${teamName} deletion`,
        bodyHTML: tr`${strong(userName)} cancelled ${strong(teamName)} deletion`,
      };
    case AppNotificationAction.CanvasInvitation:
      return {
        title: tr`${userName} has shared a drawing with you`,
        bodyHTML: tr`${strong(userName)} has shared a a drawing with you`,
      };
    default:
      invalidEnum(action);
  }
}
