import { Bin, Method } from 'ag-sockets/dist/browser';
import { getActionLog, getTimestamp, logAction } from '../common/actionLog';
import { findById, hasFlag, includes, invalidEnum, removeItem } from '../common/baseUtils';
import { BinConnId, BinCoord, BinLayerId, BinLocalId, BinPressure, BinSequenceUser } from '../common/bin';
import { readMovesEntry } from '../common/binary';
import { getContext2d, getPixelContext, replaceImageDataRect } from '../common/canvasUtils';
import { getEntityPassword, isInAngularZone, navigateToDrawing, setEntityPassword } from '../common/clientUtils';
import { clearBufferedEncoder, decodeCoord, decodePressure, nextToolArrayDecoder, startDecoder } from '../common/compressor';
import { CHAT_MESSAGE_HISTORY_LIMIT, CLIENT_LIMIT_ERROR, DRAWING_DELETED, DRAWING_NO_ACCESS, DRAWING_NOT_FOUND, DRAWING_REVISION_RESTORING, isUserLimitError, MAINTENANCE_ERROR, MINUTE, PASSWORD_ERROR, PRESENTATION_HOST_DISCONNECTED_TIME, PRESENTATION_TAKE_OVER_TIME, SEQUENCE_FRAME_LIMIT, SESSION_LIMIT_ERROR, SIGN_IN_TO_VIEW_ERROR, WEEK, SECOND, ACCESS_DENIED_ACCEPT_RULES, ACCESS_DENIED_MISSING_BIRTHDATE } from '../common/constants';
import { isFromSocial } from '../common/data';
import { createSequenceDrawing, getLayer, getMainDrawingId, setLayerOwner, setOwnedLayer, setupDrawingFromData, updateDrawingPersonalVisibility } from '../common/drawing';
import { History, redosLog, undosLog } from '../common/history';
import { ApplyFunc, ChatType, ClientAction, ClientActionRevisionCreated, ClientActionRevisionRemoved, ClientActionRevisionUpdated, ClientDrawingData, DisownFlags, DrawingDataUpdated, DrawingLoadFailure, IChatMessage, IClient, InitParams, IServerTool, IToolData, IUserSelection, JoinPresentation, LastDisconnectOnClient, Layer, LeavePresentation, LoadingResult, PresentationActions, PresentationModeInvite, PresentationModeState, PresentationViewerNotification, PromptHistoryItem, QuickAction, RecentDrawingData, SequenceAction, SocketWithDrawings, ToolId, UpdateOwnerName, User, UserData, Viewport, VoiceChatAction, QuickDrawingAction, ToolSets, Drawing, DrawingType, ClientDrawingAction, TrackedUserData, type VcSession, type VcToken, type VcActions, type VcPeerId, type VcActionList, Analytics, ArtworkLicensingData, ClientParticipant, PublicationStatus, PublisherId, RequestArtworkRegistrationResponse, ClientActionReferencedDrawingResized } from '../common/interfaces';
import { loadingEnd, loadingStart, reportLoadingStats, startLoadingStats } from '../common/loading';
import { clearMask, isMaskEmpty } from '../common/mask';
import { delay } from '../common/promiseUtils';
import { copyRect, createRect, rectsEqual, rectToString, resetRect } from '../common/rect';
import { decompressImageDataRLE } from '../common/rle';
import { addBrushShape, addShape, brushShapes, brushShapesMap, createShapePath, shapeShapes } from '../common/shapes';
import { isSelectionTool, toolIdToString } from '../common/toolIdUtils';
import { createFakeModel, createTool, getToolFullName } from '../common/tools';
import { IData, SelectionHelperAction } from '../common/tools/selectionHelperTool';
import { applyTool, finishTransform, setupActiveTool, verifyAfterRect, verifySelection } from '../common/toolUtils';
import { redrawSequenceThumbCanvas } from '../common/update';
import { clearUsers, createAndInitUserWithHistory, createUser, releaseUser, resetUser, setActiveLayer, setUserState, userOwnsLayer } from '../common/user';
import { fullName } from '../common/userUtils';
import { arraysEqual, callNowOrAfterSwitchedToTab, findByLocalId, reorder, setDocumentTitle } from '../common/utils';
import { sendGAEvent } from '../common/utilsAnalytics';
import { fitViewportOnScreen, matchFromOtherViewport } from '../common/viewport';
import { wasmFailed } from '../common/wasmUtils';
import { cancelTool, cancelToolInternal, Editor, initEditorBrushesAndShapes, ensureUserHasSelectedLayer, setupViewportForActiveDrawing, switchActiveDrawing, updateToolStats } from './editor';
import { redraw, redrawDrawing } from './editorUtils';
import { dismissChatHelp, showChatHelp } from './help';
import { Model, updateAndBroadcastDrawingLicense } from './model';
import { createClientBrowserInfo, sendLayerSnapshot } from './real-model';
import { disownAllLayers, disownLayer, disownLayerOn, selectLayer } from './layerActions';
import { loadSettings, resetRemoteSettings } from './settingsService';
import { storageGetBoolean, storageGetJson, storageGetNumber, storageRemoveItem, storageSetBoolean, storageSetItem, storageSetNumber } from './storage';
import { EditorInabilityStateId, ViewUnauthorizedPageEvent, enterEditorInabilityState, exitEditorInabilityState, getViewUnauthorizedPageReason } from '../common/analytics';
import { isTextureInLimits, switchToFallbackRenderer } from './webgl';
import { cancelLoading, clearAllUsers, clearQueuedSends, getTrackingIdsForAll, getUserByLocalId, getUserByUniqId, getUserOrTemp, setLoaded } from './drawingConnection';
import { VoiceChatService } from './voiceChatService';
import { JamsService } from './jams.service';
import { ITrackService } from './track.service.interface';
import { translateWithPlaceholders } from '../common/i18n';

// all decorator functions have to be separate and exported to work with ng-packagr

export function createApplyFunc(method: any) {
  return function (this: ClientActions, ...args: any[]) {
    return this.apply(() => method.apply(this, args));
  };
}

export function applyFunc(_target: Object, _name: string, descriptor: TypedPropertyDescriptor<any>) {
  const method = descriptor.value!;
  descriptor.value = createApplyFunc(method);
}

export function Apply() { return applyFunc; }

let shownOverloaded = false;
let shownNotSignedIn = false;
let disconnectedCodeReason: LastDisconnectOnClient | undefined = undefined;
let disconnectedState: {
  id: string;
  activeLayerId: number;
  visibleLocally: { id: number; visibleLocally: boolean | undefined; }[];
  // TODO: selection to restore
} | undefined = undefined;

const failedErrors: { [key: string]: DrawingLoadFailure; } = {
  [PASSWORD_ERROR]: DrawingLoadFailure.PasswordNeeded,
  [DRAWING_NOT_FOUND]: DrawingLoadFailure.NotFound,
  [SESSION_LIMIT_ERROR]: DrawingLoadFailure.SessionLimit,
  [DRAWING_NO_ACCESS]: DrawingLoadFailure.NoAccess,
  [SEQUENCE_FRAME_LIMIT]: DrawingLoadFailure.FrameLimit,
};

export class ClientActions implements IClient {
  lastJobId = '-1';
  ignoreMissingDrawing = false; // for tests
  private isConnected = false;
  constructor(public socket: SocketWithDrawings, private model: Model, public apply: ApplyFunc, private jamsService?: JamsService, private trackService?: ITrackService) {
  }
  private drawingFor(connId: number, func: string) {
    const drawing = this.model.getDrawing(connId);

    if (!drawing && TESTS && !this.ignoreMissingDrawing) throw new Error('Missing drawing');
    if (!drawing && !TESTS) console.warn(`Missing drawing for (connId: ${connId}, func: ${func})`);

    if (drawing?.loadingFailed) {
      DEVELOPMENT && !TESTS && console.warn(`loading failed for (connId: ${connId}, func: ${func})`);
      logAction(`loading failed for (connId: ${connId}, func: ${func})`);
    }

    return drawing;
  }
  private push(connId: number, funcName: string, wait: boolean, history: boolean, apply: boolean, action: (drawing: Drawing) => void) {
    const drawing = this.drawingFor(connId, funcName);

    if (!drawing || invalidModel(this.model)) {
      if (TESTS && !this.ignoreMissingDrawing) throw new Error('Missing drawing or invalid model');
      if (drawing && DEVELOPMENT && !TESTS) console.warn('Invalid model');
      return;
    }

    if (history && drawing.sendingHistory) {
      drawing.drawingHistory.push(() => this.exec(drawing, action, apply, funcName));
    } else if (wait && (drawing.toolPromise || drawing.waitingActions.length)) {
      if (funcName === 'disconnected') logAction('disconnected (waiting)');
      if (funcName === 'connected') logAction('connected (waiting)');
      drawing.waitingActions.push(() => this.exec(drawing, action, apply, funcName));
    } else {
      this.exec(drawing, action, apply, funcName);
    }
  }
  private exec(drawing: Drawing, action: (drawing: Drawing) => void, apply: boolean, funcName: string) {
    if (drawing.isClosed) {
      logAction(`[remote] discarding ${funcName} (drawing closed)`);
      return;
    }

    if (apply) {
      this.apply(() => action(drawing));
    } else {
      action(drawing);
    }
  }
  // TODO: remove @Wait from here, instead make sure we flush all queues and loading process
  connected() {
    logAction(`connected (socket: ${this.socket.id})`);

    if (this.isConnected) { // TODO: this should be done by ag-sockets
      logAction(`calling disconnected in connected`);
      this.disconnectedInternal(0, 'connected');
    }

    this.isConnected = true;

    const editor = this.model.editorMaybe;
    if (editor) exitEditorInabilityState(editor, EditorInabilityStateId.Connecting, false);
    if (this.model.error === MAINTENANCE_ERROR) this.model.error = undefined;
    this.model.pressureApiSent = false; // TODO: move to client actions ?
    this.model.rendererApiSent = false; // TODO: move to client actions ?

    for (const drawing of this.model.drawings) {
      if (drawing.socket !== this.socket) continue;

      setLoaded(0, drawing, editor);
      drawing.loadingFailed = DrawingLoadFailure.None;
      drawing.toolCounter = 1;
      clearQueuedSends(drawing);
    }

    if (editor && this.socket === this.model.drawing.socket && editor.selectedTool?.id === ToolId.AI) {
      editor.selectedTool.onSelect?.(); // fetch quota if needed
    }
  }
  // TODO: remove @Wait from here, instead make sure we flush all queues and loading process
  @Apply()
  disconnected(code: number, reason: string) {
    this.disconnectedInternal(code, reason);
  }
  private disconnectedInternal(code: number, reason: string) {
    logAction(`disconnected (socket: ${this.socket.id})`);

    if (!this.isConnected) { // TODO: this should be done by ag-sockets
      logAction(`skipping disconnected`);
      return;
    }

    this.isConnected = false;
    const { model } = this;

    for (const drawing of model.drawings) {
      if (drawing.socket !== this.socket) continue;

      // unexpected disconnect
      const { editor } = model;
      if (editor && drawing === model.drawing) {
        enterEditorInabilityState(editor, EditorInabilityStateId.Connecting, reason);
        disconnectedCodeReason = { code, reason };
        disconnectedState = {
          id: drawing.id,
          activeLayerId: model.user.activeLayerId,
          visibleLocally: drawing.layers
            .filter(l => l.visibleLocally !== undefined)
            .map(({ id, visibleLocally }) => ({ id, visibleLocally })),
        };

        editor.renderer?.discardThumb();
        editor.activeTool?.cancelBeganTool?.();
        cancelTool(editor, 'disconnect'); // cancelTool needs to happen before `socket.initialized` is set to false
      }

      drawing.thumbUpdate = undefined; // discard thumb, so we don't send it when we no longer have open drawing on the socket
      cancelLoading(drawing);
      clearQueuedSends(drawing);

      // remove users from sequence drawings that were tracked by this socket
      if (drawing.sequence) {
        for (const id of this.socket.drawings) {
          const sequenceDrawing = findById(drawing.sequence, id);
          if (sequenceDrawing) sequenceDrawing.users.length = 0;
        }
      }
    }

    this.socket.initialized = false;
    model.clearPendingFiles(this.socket);
  }
  connectionError(error: string) {
    if (error.startsWith('invalid version')) {
      location.reload();
    }
  }
  @Apply() @Method({ binary: [Bin.Str, Bin.Obj, Bin.Obj] })
  init(sessionId: string, user: UserData, params: InitParams) {
    logAction(`init (socket: ${this.socket.id})`);

    storageSetItem('sessionId', sessionId);

    const { model } = this;
    const connection = model.connectionService;
    for (const { socket } of connection.sockets) {
      socket.options.requestParams.sessionId = sessionId;
    }

    this.socket.initialized = true;

    const { server } = this.socket.socket;

    // re-send track drawings in case it changed during connecting
    const trackingIds = getTrackingIdsForAll(model.drawings);
    if (trackingIds.length) {
      const ids = this.socket.drawings.filter(id => includes(trackingIds, id));
      if (ids.length) server.trackDrawings(ids.map(id => [id, getEntityPassword(id)]));
    }

    // re-send open drawings in case it changed during connecting
    const drawingsOnSocket = connection.drawings.filter(d => includes(this.socket.drawings, d.id));
    server.openDrawings(drawingsOnSocket.map(({ connId, id }) => [connId, id, getEntityPassword(id)]), '');

    setUserState(model.defaultUser, user);

    for (const drawing of model.drawings) {
      if (!this.socket.drawings.includes(drawing.id)) continue;

      drawing.socket = this.socket;
      setLoaded(0, drawing, model.editor);
      drawing.loadingFailed = DrawingLoadFailure.None;
      clearBufferedEncoder(drawing.encoder);
      clearAllUsers(drawing, model.editor);
      releaseUser(drawing.user, model.editor);
      drawing.user = createAndInitUserWithHistory(user.uniqId, 0, user, model.editor, drawing);
      model.loadUserAvatar(drawing.user);

      if (drawing === model.drawing) {
        model.notifyUserChanged();
      }
    }

    // report last disconnect reason
    if (disconnectedCodeReason) {
      server.quickAction(QuickAction.DisconnectReason, disconnectedCodeReason);
      disconnectedCodeReason = undefined;
    }

    // report stats
    const wnd = typeof window !== 'undefined' ? window : undefined;
    let frame: string | undefined = undefined;
    try {
      if (wnd && parent !== wnd) frame = document.referrer;
    } catch { }
    server.quickAction(QuickAction.CheckUrl, location.href);
    if (frame) server.quickAction(QuickAction.FrameUrl, frame);

    // TODO: move this from here, this may be called before wasm fails
    if (wasmFailed) server.quickAction(QuickAction.WasmFailed, wasmFailed);

    server.quickAction(QuickAction.BrowserInfo, createClientBrowserInfo());
    server.quickAction(QuickAction.BrushShapesLoaded, brushShapes.map(s => s.id));
    server.quickAction(QuickAction.ShapeShapesLoaded, shapeShapes.map(s => s.id));

    if (!model.recentDrawings) {
      server.quickAction(QuickAction.RequestRecentDrawings);
    }

    if (!model.rootDrawingId || this.socket.drawings.includes(model.rootDrawingId)) {
      server.quickAction(QuickAction.RequestToolSets, 0);
    }

    // dismiss help messages for week old accounts
    if (user.created && (Date.now() - (new Date(user.created)).getTime()) > WEEK) {
      dismissChatHelp();
    }

    // show sign-in encouragement for old sessions
    if (!IS_HOSTED && !IS_PORTAL && user.anonymous && !/^anonymous$/i.test(user.name) && params.showNotSignedIn && !shownNotSignedIn) {
      model.onUserError.next({ message: `You're not signed in, create an account to preserve your settings and get access to admin features.` });
    }
    shownNotSignedIn = true; // ouside `if`, so we don't show not-signed-in notice when user signs out

    model.newFeature = params.newFeature ?? '';
  }
  @Apply() @Method({ binary: [Bin.Str] })
  settings(settings: string) {
    loadSettings(this.model, settings);
    this.model.settingsInitialized = true;
  }
  @Apply() @Method({ binary: [Bin.Str, Bin.Str] })
  announcement(message: string, type: string) {
    this.model.announcement = message ? { message, type } : undefined;
  }
  @Apply() @Method({ binary: [BinConnId, Bin.Str, Bin.Str, [Bin.Str], Bin.U8] })
  chat(connId: number, uniqId: string, message: string, args: string[], type: ChatType) {
    const useConnId = connId || this.model.drawing.connId; // use main drawing connId instead for sequence chat messages

    this.push(useConnId, 'chat', false, false, true, drawing => {
      if (type === ChatType.System || args.length) { // translate system messages or messages with arguments
        message = translateWithPlaceholders(message, args);
      }

      chatMessage(drawing, this.model, uniqId, message, type);
    });
  }
  @Apply() @Method({ binary: [Bin.Obj] })
  toolSets(toolSets: ToolSets) {
    this.model.toolSets = toolSets;
    const editor = this.model.editorMaybe;
    if (editor) {
      initEditorBrushesAndShapes(editor, toolSets, e => this.model.reportError('initEditorBrushesAndShapes failed', e));
    }
  }
  // Users
  @Method({ binary: [BinConnId, Bin.Obj] })
  addUser(connId: number, data: UserData) {
    this.push(connId, 'addUser', true, true, true, drawing => {
      let user = findByLocalId(drawing.tempUsers, data.localId);
      logAction(`[remote] addUser ${user ? '(promote temp) ' : ''}(clientId: ${data.localId})`);

      if (user) {
        user.uniqId = data.uniqId;
        setUserState(user, data);
        removeItem(drawing.tempUsers, user);
      } else {
        user = createAndInitUserWithHistory(data.uniqId, data.localId, data, this.model.editor, drawing);
      }

      this.model.loadUserAvatar(user);
      drawing.users.push(user);
      user.ownedLayers.forEach(layerId => setOwnedLayer(drawing, layerId, user!));
      setActiveLayer(user, getLayer(drawing, user.activeLayerId));

      if (drawing.users.length && showChatHelp()) {
        setTimeout(() => {
          if (showChatHelp()) {
            this.model.showChatPrompt = true;
          }
        }, 30 * 1000);
      }
    });
  }
  @Method({ binary: [BinConnId, Bin.Obj, Bin.Str] })
  updateUser(connId: number, data: UserData) {
    this.push(connId, 'updateUser', true, true, true, drawing => {
      logAction(`[remote] updateUser (clientId: ${data.localId})`);
      const model = this.model;
      const user = getUserByUniqId(drawing, data.uniqId, false);

      if (!user) throw new Error(`[updateUser] Missing user`);

      if (user === drawing.user) {
        // upgraded to pro, checking if email is the same in case user just signed-in to a different account
        if (!IS_HOSTED && !user.pro && data.pro && !user.anonymous && user.email === data.email) {
          // show upgrade notification
          if (data.subscriptionStatus?.status === 'trialing') {
            // TODO: maybe show different notification when not trialing ?
            callNowOrAfterSwitchedToTab(() => model.onUserError.next({ message: `UPGRADED`, type: 'success' }));
          }

          if (drawing.loadingFailed === DrawingLoadFailure.SessionLimit) {
            model.reconnect(); // reconnect because session limit changed for the user
          }
        }

        // user signed-in or signed-out
        if (user.anonymous !== data.anonymous) {
          resetRemoteSettings(model);
        }

        // in case voice chat permission changed
        model.voiceChat.applySettings();
        model.voiceChat.updateUserMutes();

        if (drawing.loadingFailed) { // TODO: why is this here?
          location.reload();
        }
      }

      // update voice chat settings
      if (user.name !== data.name) {
        model.voiceChat.updateUserName(user.name, data.name);
      }

      const oldFullName = fullName(user);
      const owned = user.ownedLayers.slice(0);

      setUserState(user, data);
      model.loadUserAvatar(user);

      // update layer ownership
      if (model.editor) {
        owned
          .filter(layerId => !userOwnsLayer(user, layerId))
          .forEach(layerId => setOwnedLayer(drawing, layerId, undefined));

        user.ownedLayers
          .forEach(layerId => setOwnedLayer(drawing, layerId, user));

        if (user !== drawing.user || !model.editor.drawingInProgress) {
          setActiveLayer(user, getLayer(drawing, user.activeLayerId));
        }

        if (user === drawing.user && !user.activeLayer) {
          // select first owned layer
          const firstOwnedLayer = drawing.layers.find(l => l.owner === drawing.user);
          if (firstOwnedLayer) selectLayer(model.editor, user, drawing, firstOwnedLayer);
        }

        // update layer owner names
        for (const layer of drawing.layers) {
          if (layer.layerOwner?.name === oldFullName) {
            layer.layerOwner.name = fullName(user);
            layer.layerOwner.color = user.color;
          }
        }
      }

      if (user === this.model.user) {
        this.model.notifyUserChanged();
      }
    });
  }
  @Method({ binary: [BinConnId, BinLocalId] })
  removeUser(connId: number, localId: number) {
    this.push(connId, 'removeUser', true, true, true, drawing => {
      const user = findByLocalId(drawing.users, localId);
      logAction(`[remote] removeUser (clientId: ${localId}${user ? '' : ', missing'})`);

      if (!user) {
        DEVELOPMENT && !TESTS && console.warn(`removeUser: user not found (clientId: ${localId})`);
        return;
      }

      const editor = this.model.editor;

      if (editor) {
        finishTransform(createFakeModel(editor, drawing, user), 'removeUserInternal');

        for (const id of user.ownedLayers) {
          const layer = getLayer(drawing, id);
          if (layer) layer.owner = undefined;
        }
      }

      removeItem(drawing.users, user);
      resetUser(user, editor);

      if (editor) redraw(editor);
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, [Bin.U8]] })
  userSelection(connId: number, localId: number, data: number[]) {
    this.push(connId, 'userSelection', true, true, false, drawing => {
      logAction(`[remote] userSelection (clientId: ${localId})`);

      void execTool(drawing, this.model, localId, 0, <IData>{
        id: ToolId.SelectionHelper,
        action: SelectionHelperAction.InitSelection,
        selectionData: data,
      }, undefined, undefined);

      drawing.toolPromise?.catch(e => {
        this.model.fatalError = 'Failed to draw tool';
        this.model.reportError('Failed on toolPromise (selection)', e);
      });
    });
  }
  @Apply() @Method({ binary: [Bin.Obj] })
  updateRecentDrawing(data: RecentDrawingData) {
    const recentDrawing = this.model.recentDrawings && findById(this.model.recentDrawings, data.id);
    if (recentDrawing) recentDrawing.name = data.name;
  }
  // Drawing
  // TODO: remove Wait
  @Apply() @Method({
    binary: [
      BinConnId, // connId
      Bin.Obj, // user
      Bin.Obj, // state
      [Bin.U8, Bin.U16, Bin.Obj, Bin.U8Array, Bin.Bool, [BinLayerId], Bin.Bool, [Bin.Obj]], // tools
      [Bin.Obj], // updates
      [Bin.U8, [Bin.U8]] // userSelections
    ]
  })
  async drawingOpen(connId: number, userData: UserData, state: ClientDrawingData, tools: IServerTool[], updates: DrawingDataUpdated[], userSelections: IUserSelection[]) {
    const drawing = this.drawingFor(connId, 'drawingOpen');
    if (!drawing) {
      if (TESTS) throw new Error('Missing drawing in drawingOpen');
      if (DEVELOPMENT) console.warn('Missing drawing in drawingOpen');
      return;
    }

    logAction(`[remote] drawingOpen (id: ${state.id}, conn: ${connId}, width: ${state.w}, height: ${state.h}, layers: [${state.layers.map(l => l.id).join(', ')}])`);

    const model = this.model;
    const { editor } = model;
    const { user } = drawing;

    let error: string | undefined = undefined;
    let cancelled = false;

    startLoadingStats();
    loadingStart('drawingOpen()');

    if (model.drawing === drawing) {
      model.modals.closeOverloaded();
    }

    if (drawing.id === model.rootDrawingId) {
      this.socket.socket.server.quickAction(QuickAction.RequestToolSets, connId);
    }

    try {
      if (!editor.renderer) throw new Error('Renderer not initialized');

      if (!isTextureInLimits(editor.renderer.params().maxTextureSize, state, state.layers)) {
        switchToFallbackRenderer(editor.renderer.params().maxTextureSize);
        return;
      }

      this.callFinishedLoadingDrawing = false;

      const mainDrawingId = getMainDrawingId(state);

      if (mainDrawingId !== this.model.voiceChat.drawingId) {
        this.model.messages = [];
        model.voiceChat.reset();
        model.voiceChat.drawingId = mainDrawingId;
      }

      cancelTool(editor, 'drawingOpen'); // cancel can leave selection non-empty, needs to be before reset
      editor.movingView = false;

      resetUser(user, editor);

      // set to empty owned layer list so later it will correctly update owned layers
      setUserState(user, { ...userData, ownedLayers: [] });

      clearUsers(drawing.users, editor);
      clearUsers(drawing.tempUsers, editor);
      clearBufferedEncoder(drawing.encoder);

      loadingStart('init renderer');
      setupDrawingFromData(drawing, state);

      if (editor.drawing === drawing) {
        // TODO: we don't want to switch it like this when we're on board
        setupViewportForActiveDrawing(editor); // setup viewport now that we know drawing size
      }

      loadingEnd();

      resetUser(user, editor); // reset again, because setDrawing can set selection back by cancelling tools

      if (!isMaskEmpty(user.selection)) logAction(`error: user selection not empty after lockEditor`); // TEMP

      setEntityPassword(state.id, state.password);

      cancelLoading(drawing);
      setLoaded(0, drawing, editor);
      drawing.loadingFailed = DrawingLoadFailure.None;
      drawing.toolCounter = 1;
      drawing.pendingLayerOwns.clear();
      drawing.sentTools = 0;
      drawing.confirmedTools = 0;
      clearQueuedSends(drawing);
      drawing.sendingHistory = true;
      drawing.drawingHistory = [];
      drawing.waitingActions = [];
      drawing.selectLayerOnOwn = 0;
      resetRect(drawing.lastCropRect);

      model.dismissError();

      user.uniqId = userData.uniqId;
      user.localId = userData.localId;
      user.history = new History(user, editor, drawing);

      // multiply by 0.9 to make sure we don't get to 100% yet
      drawing.loadingPromise = editor.renderer.loadLayerImages(drawing, model, p => setLoaded(p * 0.9, drawing, editor));

      if (model.rootDrawingId === drawing.id) {
        if (model.drawing === drawing) {
          switchActiveDrawing(editor, drawing);
        } else {
          model.drawing = drawing;
        }
      }

      model.updateConnection();

      logAction(`loading layers`);

      if (TESTS && model.testPromise) await model.testPromise; // for testing

      return drawing.loadingPromise.then(async ({ loadersUsed }) => {
        if (drawing.isClosed) throw new Error('Cancelled');

        logAction(`loaded layers (id: ${drawing.id}, layers: [${drawing.layers.map(l => l.id).join(', ')}])`);

        const { server } = this.socket.socket;
        server.quickAction(QuickAction.LoadersUsed, loadersUsed);
        model.loadedDrawing(connId, LoadingResult.LoadedLayers);
        drawing.insideCommitLoop = true;

        logAction(`loading userSelections`);
        loadingStart('userSelections');
        for (const [localId, selectionData] of userSelections) {
          if (selectionData) {
            void execTool(drawing, model, localId, 0, <IData>{ id: ToolId.SelectionHelper, action: SelectionHelperAction.InitSelection, selectionData }, undefined, undefined);
            if (drawing.toolPromise) {
              logAction('await toolPromise');
              await drawing.toolPromise;
              if (drawing.isClosed) throw new Error('Cancelled');
            }
            const user = getUserOrTemp(drawing, localId, editor);
            const r = user ? user.selection.bounds : createRect(0, 0, 0, 0);
            logAction(`  selection (clientId: ${localId}, rect: ${r.x} ${r.y} ${r.w} ${r.h})${user ? '' : ' (nouser)'}`);
          }
        }
        loadingEnd();

        logAction(`loading tools`);
        loadingStart('tools');
        for (const [localId, layerId, tool, data, finishAfter, disown, hasUpdates, updates] of tools) {
          logAction(`  tool ${toolIdToString(tool.id)} (clientId: ${localId}, layerId: ${layerId}, t: ${tool.t}, finishAfter: ${finishAfter}, disown: [${disown?.join(', ')}])`);

          if (hasUpdates) {
            drawing.toolPromise = execToolWithUpdates(drawing, editor, localId, layerId, tool, updates);
          } else {
            void execTool(drawing, model, localId, layerId, tool, data, finishAfter);
          }

          if (drawing.toolPromise) {
            logAction('await toolPromise');
            await drawing.toolPromise;
            drawing.toolPromise = undefined;
            if (drawing.isClosed) throw new Error('Cancelled');
          }

          for (const id of disown) {
            const user = getUserOrTemp(drawing, localId, editor);
            if (user) {
              if (user.surface.layer?.id === id) finishTransform(createFakeModel(editor, drawing, user), 'loading tools'); // TODO: write tests
              user.history.clearLayer(id);
            }
          }
        }
        // TODO: write tests (this might not be possible to break because it will be followed by either disown or 15+ actions)
        for (const user of drawing.users) {
          finishTransform(createFakeModel(editor, drawing, user), 'after loading tools');
          user.history.clear();
        }
        loadingEnd();

        logAction(`loading updates`);
        loadingStart('updates');
        for (const update of updates) {
          Object.assign(drawing, update);
        }
        loadingEnd();

        logAction(`loading finishTransform`);
        loadingStart('finishTransform');
        for (const user of drawing.tempUsers) {
          finishTransform(createFakeModel(editor, drawing, user), 'loading finishTransform');
        }
        loadingEnd();

        logAction('loading history');
        loadingStart('history');
        while (drawing.drawingHistory.length) {
          loadingStart('action');
          drawing.drawingHistory.shift()!();
          if (drawing.toolPromise) {
            logAction('await toolPromise');
            await drawing.toolPromise;
            if (drawing.isClosed) throw new Error('Cancelled');
          }
          loadingEnd();
        }
        loadingEnd();

        logAction('loading rest');
        loadingStart('rest');

        // setup layer owners
        for (const layer of drawing.layers) {
          layer.owner = undefined;
        }

        for (const user of drawing.users) {
          for (const id of user.ownedLayers) {
            const layer = getLayer(drawing, id);
            if (layer) setLayerOwner(layer, user);
          }

          setActiveLayer(user, getLayer(drawing, user.activeLayerId));
        }

        for (const id of user.ownedLayers) {
          const layer = getLayer(drawing, id);
          if (layer) setLayerOwner(layer, user);
        }

        if (editor.drawing === drawing) {
          editor.boundsChanged = true; // just in case
        }

        model.updateDocumentTitle();
        loadingEnd();

        logAction(`loaded (drawingId: ${drawing.id}, width: ${drawing.w}, height: ${drawing.h}, layers: [${drawing.layers.map(l => l.id).join(', ')}])`);

        logAction('commiting waiting actions');
        loadingStart('commiting waiting actions');
        await commitWaitingActions(drawing);
        drawing.insideCommitLoop = false;
        if (drawing.isClosed) throw new Error('Cancelled');
        loadingEnd();

        drawing.sendingHistory = false;
        this.updateUser(connId, userData);
        logAction(`updated user`);

        // TEMP: testing, this should never happen
        if (!isMaskEmpty(user.selection)) {
          logAction(`error: user selection not empty after load`);
          model.reportError(`User selection not empty after load`, undefined);
          clearMask(user.selection);
        }

        // TEMP: testing, this should never happen (still happening as of June 2023)
        if (user.history.canUndo() || user.history.canRedo()) {
          logAction(`error: user undos/redos not empty after load`);
          model.reportError(`User undos/redos not empty after load`, undefined, { undos: undosLog(user.history), redos: redosLog(user.history) });
          user.history.clear();
        }

        if (isFromSocial === state.id) {
          server.quickDrawingAction(connId, QuickDrawingAction.MarkDrawingAsPublic);
        }

        setLoaded(1, drawing, editor);
        model.loadedDrawing(connId, LoadingResult.Finished);
        redrawDrawing(drawing);

        if (this.callFinishedLoadingDrawing) {
          this.finishedLoadingDrawing(drawing);
        } else {
          this.callFinishedLoadingDrawing = true;
        }

        model.onLoaded(); // for testing
      }).catch((e: Error) => {
        logAction(`loading failed(1) (${e.message})`);
        DEVELOPMENT && !TESTS && console.warn(e.message, `drawingId: ${state.id}`);
        error = e.message;

        if (e.name === 'AbortError' || /^Cancelled/i.test(e.message)) {
          cancelled = true;
        } else {
          if (!/Error loading image/.test(e.message)) {
            model.reportError('clientDrawing(1)', e);
          }

          model.showError(`Failed to load drawing (${e.message})`, false, true);
        }

        model.loadedDrawing(connId, LoadingResult.Failed);
        drawing.toolStarted = false;
        drawing.loadingFailed = drawing.loadingFailed || DrawingLoadFailure.Error;
      }).finally(() => {
        logAction('loading finally');
        drawing.loadingPromise = undefined;
        loadingEnd();

        if (!cancelled) {
          try {
            reportLoadingStats(model, state, editor.renderer?.name ?? 'none', error);
          } catch (e) {
            DEVELOPMENT && console.error(e);
          }
        }
      });
    } catch (e) {
      logAction(`loading failed(2) (${e.message})`);
      model.reportError('clientDrawing(2)', e);
      model.fatalError = `Failed to load drawing (${e.message})`;
      model.loadedDrawing(connId, LoadingResult.Failed); // TODO: might be re-opening the same drawing, add opening id ?
      drawing.toolStarted = false;
      drawing.loadingFailed = drawing.loadingFailed || DrawingLoadFailure.Error;
      loadingEnd();
      return Promise.resolve();
    }
  }
  @Method({ binary: [BinConnId] })
  drawingTools(connId: number) {
    this.push(connId, 'drawingTools', true, false, false, _drawing => {
      // logAction(`[remote] drawingTools`);
      // TODO: move tools from drawing() method here
    });
  }
  @Method({ binary: [BinConnId] })
  drawingLoaded(connId: number) {
    this.push(connId, 'drawingLoaded', true, false, false, drawing => {
      if (DEVELOPMENT && !TESTS && isInAngularZone() && !drawing.insideCommitLoop) {
        console.error('Socket message inside angular zone');
      }

      logAction(`[remote] drawingLoaded (conn: ${connId})`);
      // this.model.sendingHistory = false;

      if (this.callFinishedLoadingDrawing) {
        this.apply(() => this.finishedLoadingDrawing(drawing));
      } else {
        this.callFinishedLoadingDrawing = true;
      }

      if (this.model.revisionRestoreInProgress) {
        this.model.toasts?.updateToSuccess(this.model.revisionRestoreInProgress, { message: 'Successfully restored drawing revision' });
        this.model.revisionRestoreInProgress = undefined;
      }
    });
  }
  private callFinishedLoadingDrawing = false; // TODO: move to DrawingConnection
  private isFirstDrawingLoading = true;
  private finishedLoadingDrawing(drawing: Drawing) {
    logAction(`[remote] finishedLoadingDrawing (id: ${drawing.id})`);

    const model = this.model;
    const editor = model.editor!;

    clearUsers(drawing.tempUsers, editor);

    model.finishedLoadingDrawing?.next(drawing);

    ensureUserHasSelectedLayer(editor, drawing, true);

    // restore state from before disconnect
    if (!TESTS && disconnectedState) {
      if (disconnectedState.id === drawing.id) {
        const layer = getLayer(drawing, disconnectedState.activeLayerId);
        if (layer && layer.owner === drawing.user && drawing === model.drawing) selectLayer(editor, drawing.user, drawing, layer);

        for (const { id, visibleLocally } of disconnectedState.visibleLocally) {
          const layer = getLayer(drawing, id);
          if (layer) layer.visibleLocally = visibleLocally;
        }
      }

      disconnectedState = undefined;
    }

    // hide all cursors, because they got placed by executing tools from history
    for (const user of drawing.users) {
      user.cursorX = 1e9;
      user.cursorY = 1e9;
    }

    if (!SERVER && !TESTS && (storageGetBoolean('isPresentationModeHost') || model.isPresentationOriginalHost) && document.hasFocus() && this.isFirstDrawingLoading && model.activePresentation) {
      model.continuePresentationMode();
    }

    this.isFirstDrawingLoading = false;

    if (drawing.licensing || drawing.inheritedLicense) {
      this.model.tryQuickAction(QuickAction.GetLicensingRights, drawing.connId);
    }
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.Obj] })
  updateDrawing(connId: number, localId: number, data: DrawingDataUpdated) {
    this.push(connId, 'updateDrawing', true, false, true, drawing => {
      logAction(`[remote] updateDrawing (clientId: ${localId})`);

      if ('sequence' in data) {
        const seq = drawing.sequence;
        const { _id, id, name } = drawing;
        const current = findById(seq, id) ?? { _id, id, name, users: [] };
        drawing.sequence = data.sequence?.map(data =>
          Object.assign(findById(seq, data.id) ?? createSequenceDrawing(data), data)) ?? [current];
        this.model.updateConnection();
        delete data.sequence;
      }

      if ('password' in data) {
        setEntityPassword(drawing.id, data.password);
      }

      Object.assign(drawing, data);

      if ('permissions' in data) {
        // in case voice chat permission changed
        this.model.voiceChat.applySettings();
        this.model.voiceChat.updateUserMutes();
      }

      if ('featureFlags' in data) {
        this.model.manage.updateDrawing(drawing);
      }

      // switch socket for voice chat communication if main drawing changed
      const mainDrawingId = getMainDrawingId(drawing);
      if (this.model.voiceChat.drawingId !== mainDrawingId) {
        this.model.voiceChat.drawingId = mainDrawingId;
        if (this.model.voiceChat.isConnected || this.model.voiceChat.isConnecting) {
          logAction(`reconnecting voice-chat due to main drawing change (${mainDrawingId})`);
          this.model.voiceChat.stop('updateDrawing');
          this.model.voiceChat.start('updateDrawing').catch(e => DEVELOPMENT && console.error(e));
        }
      }

      redrawDrawing(drawing);
      setDocumentTitle(drawing.name);
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.I32, Bin.I32, Bin.U32, Bin.U32] })
  resizeDrawing(connId: number, localId: number, x: number, y: number, w: number, h: number) {
    // TODO: do we need `wait` or `history` here ?
    this.push(connId, 'resizeDrawing', false, false, false, drawing => {
      const rect = createRect(x, y, w, h);
      const { editor } = this.model;

      if (localId === drawing.user.localId && rectsEqual(rect, drawing.lastCropRect)) return;

      if (!isTextureInLimits(editor.renderer.params().maxTextureSize, rect, drawing.layers)) {
        switchToFallbackRenderer(editor.renderer.params().maxTextureSize);
      } else {
        editor.resizeCanvas(rect, drawing);
      }
    });
  }
  // Layers
  @Method({ binary: [BinConnId, BinLocalId, BinLayerId] })
  selectLayer(connId: number, localId: number, layerId: number) {
    this.push(connId, 'selectLayer', true, true, true, drawing => {
      const { editor } = this.model;
      const user = getUserOrTemp(drawing, localId, editor);

      if (user && user !== drawing.user) {
        setActiveLayer(user, getLayer(drawing, layerId));
      }
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, BinLayerId] })
  ownLayer(connId: number, localId: number, layerId: number) {
    this.push(connId, 'ownLayer', true, true, true, drawing => {
      logAction(`[remote] ownLayer (clientId: ${localId}, layerId: ${layerId})`);
      drawing.pendingLayerOwns.delete(layerId);
      const { editor } = this.model;
      const user = getUserOrTemp(drawing, localId, editor);

      if (user) {
        setOwnedLayer(drawing, layerId, user);

        if (user === drawing.user && (!user.activeLayer || drawing.selectLayerOnOwn === layerId)) {
          selectLayer(editor, user, drawing, getLayer(drawing, layerId));
          drawing.selectLayerOnOwn = 0;
        }
      }
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, BinLayerId, Bin.U8] })
  disownLayer(connId: number, localId: number, layerId: number, flags: DisownFlags) {
    this.push(connId, 'disownLayer', true, true, true, drawing => {
      logAction(`[remote] disownLayer (clientId: ${localId}, layerId: ${layerId})`);
      drawing.pendingLayerOwns.delete(layerId);
      const { editor } = this.model;
      const user = getUserOrTemp(drawing, localId, editor);
      const layer = getLayer(drawing, layerId);

      if (layer) {
        if (user && user.activeLayer === layer) setActiveLayer(user, undefined); // has to be before finishTool
        if (user && user.surface.layer === layer) finishTransform(createFakeModel(editor, drawing, user), 'ClientActions:disownLayer');
        setLayerOwner(layer, undefined, true);
        if (hasFlag(flags, DisownFlags.RemoveOwner)) layer.layerOwner = undefined;
      } else {
        if (DEVELOPMENT && !TESTS) console.warn(`disowning missing layer: ${layerId}`);
        if (user) removeItem(user.ownedLayers, layerId);
      }

      logAction(`[remote] disownLayer > history.clearLayer (clientId: ${localId}, layerId: ${layerId}) ${user ? undosLog(user.history) : '-'}`);
      user?.history.clearLayer(layerId);
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, [BinLayerId]] })
  reorderLayers(connId: number, localId: number, order: number[]) {
    this.push(connId, 'reorderLayers', true, true, true, drawing => {
      if (arraysEqual(order, drawing.lastReorder)) return;

      reorder(drawing.layers, order);
      redrawDrawing(drawing);
      logAction(`[remote] reorderLayers (clientId: ${localId}) [${drawing.layers.map(l => l.id).join(', ')}]`);
    });
  }
  // Tools
  @Method({ binary: [BinConnId, Bin.U32] })
  confirm(connId: number, index: number) {
    this.push(connId, 'confirm', true, false, false, drawing => {
      drawing.confirmedTools = index;
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, BinLayerId, Bin.Obj, Bin.U8Array] })
  tool(connId: number, localId: number, layerId: number, tool: IToolData, data: Uint8Array | undefined) {
    this.push(connId, 'tool', true, true, false, drawing => {
      logAction(`[remote] tool: ${getToolFullName(tool)} (clientId: ${localId}, layerId: ${layerId}, t: ${tool.t}${tool.replace ? ', replace' : ''}${tool.preventHistory ? ', preventHistory' : ''})`);
      void execTool(drawing, this.model, localId, layerId, tool, data);
      drawing.toolPromise?.catch(e => {
        this.model.showError('Failed to draw tool', false, true);
        this.model.reportError('Failed on toolPromise (tool)', e);
      });
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, BinLayerId, Bin.Obj] })
  beginTool(connId: number, localId: number, layerId: number, data: IToolData): void {
    this.push(connId, 'beginTool', true, true, false, drawing => {
      logAction(`[remote] beginTool (clientId: ${localId}, layerId: ${layerId})`);
      const { editor } = this.model;
      const tool = beginClientTool(drawing, editor, localId, layerId, data);

      if (this.model.drawing === drawing && localId === this.model.user.localId) {
        editor.activeTool = tool;
        editor.activeTool.begin?.(data, false);
      } else {
        const user = getUserOrTemp(drawing, localId, editor);
        if (!user) throw new Error(`Missing user (updateTool, clientId: ${localId})`);
        user.activeTool = tool;
        user.activeTool.begin?.(data, true);
      }
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.Obj, Bin.Obj] })
  updateTool(connId: number, localId: number, data: IToolData, update: any) {
    this.push(connId, 'updateTool', true, true, false, drawing => {
      logAction(`[remote] updateTool (clientId: ${localId})`);
      const { editor } = this.model;
      const tool = this.getActiveTool(drawing, localId, editor, data, 'updateTool');
      drawing.toolPromise = tool?.update?.(data, update, localId !== drawing.user.localId);
      drawing.toolPromise?.then(async () => {
        drawing.toolPromise = undefined;
        if (!drawing.insideCommitLoop) await commitWaitingActions(drawing);
      }).catch(e => {
        this.model.showError('Failed to draw tool', false, true);
        this.model.reportError('Failed on toolPromise (tool)', e);
      });

      if ('jobId' in data) {
        this.lastJobId = (data as any).jobId;
      }
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.Obj] })
  finishTool(connId: number, localId: number, data: IToolData): void {
    this.push(connId, 'finishTool', true, true, false, drawing => {
      logAction(`[remote] finishTool (clientId: ${localId})`);
      const { editor } = this.model;
      const tool = this.getActiveTool(drawing, localId, editor, data, 'finishTool');

      if (localId !== drawing.user.localId) {
        const user = getUserOrTemp(drawing, localId, editor);
        if (!user) throw new Error(`Missing user (finishTool, clientId: ${localId})`);
        user.activeTool = undefined;
      }
      tool?.finish?.(true);
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, BinLayerId, Bin.Obj, BinCoord, BinCoord, BinPressure] })
  startTool(connId: number, localId: number, layerId: number, tool: IToolData, encodedX: number, encodedY: number, encodedP: number) {
    this.push(connId, 'startTool', true, true, false, drawing => {
      const x = decodeCoord(encodedX);
      const y = decodeCoord(encodedY);
      this.innerStartTool(drawing, localId, layerId, tool, x, y, encodedP, true);
    });
  }
  private innerStartTool(drawing: Drawing, localId: number, layerId: number, tool: IToolData, x: number, y: number, encodedP: number, final: boolean) {
    const { editor } = this.model;
    const user = getUserOrTemp(drawing, localId, editor);
    const layer = getLayer(drawing, layerId);

    if (!user) throw new Error(`Missing user (startTool, clientId: ${localId}, t: ${tool.t})`);

    try {
      logAction(`[remote] startTool: ${toolIdToString(tool.id)} (clientId: ${localId}, layerId: ${layerId}, t: ${tool.t}) (${rectToString(layer?.rect)}) [${drawing.layers.map(l => l.id).join(', ')}]`);

      // TEMP: we got double starts
      if (user.activeTool) {
        throw new Error(`Tool already started (startTool, clientId: ${localId}, t: ${tool.t})`);
      }

      // TEMP: error tracking
      if (!layer && (tool.id === ToolId.Brush || tool.id === ToolId.Pencil || tool.id === ToolId.Eraser || tool.id === ToolId.LassoBrush)) {
        throw new Error(`Missing layer (clientActions.innerStartTool, clientId: ${localId}, layerId: ${layerId}, t: ${tool.t})`);
      }

      if (layer) layer.owner = user;

      setActiveLayer(user, layer);
      verifySelection(this.model, user, tool);

      const activeTool = setupActiveTool(editor, drawing, user, tool);
      activeTool.setup?.(tool);

      if (!activeTool.start) throw new Error('Missing activeTool start (startTool)');

      activeTool.start(x, y, decodePressure(encodedP), undefined);

      user.lastToolStartData = tool;
      user.lastX = x;
      user.lastY = y;
      startDecoder(user.decoder, x, y, encodedP);

      if (final) {
        user.cursorX = x;
        user.cursorY = y;
      }
    } catch (e) {
      logAction(`[remote] startTool FAILED (clientId: ${localId}, t: ${tool.t}): ${e.message || e.toString()}`);
      user.activeTool = undefined; // prevent errors on nextTool and endTool
      this.model.showError(`Error during drawing: ${e.message}`, false, true);
      throw e;
    }
  }
  @Method({ binary: [BinConnId, BinLocalId, BinLayerId, Bin.Obj, Bin.Bool, Bin.U8Array, Bin.I32, Bin.I32, Bin.U32, Bin.U32] })
  startToolWithMoves(connId: number, localId: number, layerId: number, tool: IToolData, end: boolean, data: Uint8Array, ax: number, ay: number, aw: number, ah: number
  ) {
    this.push(connId, 'startToolWithMoves', true, true, false, drawing => {
      const { editor } = this.model;
      const user = getUserOrTemp(drawing, localId, editor);
      if (!user) throw new Error('Missing user');

      const view = new DataView(data.buffer, data.byteOffset, data.byteLength);
      const endOffset = end ? data.byteLength - 10 : data.byteLength;

      {
        const { x, y, p } = readMovesEntry(view, 0);
        this.innerStartTool(drawing, localId, layerId, tool, x, y, p, data.byteLength === 10);
      }

      nextToolArrayDecoder(user.decoder, data.subarray(10, endOffset));

      if (end) {
        const { x, y, p } = readMovesEntry(view, endOffset);
        this.innerEndTool(drawing, localId, x, y, p, ax, ay, aw, ah);
      } else {
        user.cursorX = user.lastX;
        user.cursorY = user.lastY;
      }
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.U8Array], ignore: true })
  nextToolArray(connId: number, localId: number, moves: Uint8Array) {
    this.push(connId, 'nextToolArray', true, true, false, drawing => {
      const { editor } = this.model;
      const user = getUserOrTemp(drawing, localId, editor);

      if (!user) return this.logAndReportError(`[remote] nextTool: missing user (clientId: ${localId})`);
      if (!user.activeTool) return this.logAndReportError(`[remote] nextTool: missing activeTool (clientId: ${localId})`);

      nextToolArrayDecoder(user.decoder, moves);

      user.cursorX = user.lastX;
      user.cursorY = user.lastY;

      if (editor.drawing === drawing) redraw(editor);
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, BinCoord, BinCoord, BinPressure, Bin.I32, Bin.I32, Bin.U32, Bin.U32] })
  endTool(connId: number, localId: number, encodedX: number, encodedY: number, encodedP: number, ax: number, ay: number, aw: number, ah: number) {
    this.push(connId, 'endTool', true, true, false, drawing => {
      this.innerEndTool(drawing, localId, decodeCoord(encodedX), decodeCoord(encodedY), encodedP, ax, ay, aw, ah);
    });
  }
  private innerEndTool(drawing: Drawing, localId: number, x: number, y: number, encodedP: number, ax: number, ay: number, aw: number, ah: number) {
    logAction(`[remote] endTool (clientId: ${localId})`);
    const { editor } = this.model;
    const user = getUserOrTemp(drawing, localId, editor);

    if (!user) return this.logAndReportError(`[remote] endTool: missing user (clientId: ${localId})`);
    if (!user.activeTool) return this.logAndReportError(`[remote] endTool: missing activeTool (clientId: ${localId})`);
    if (!user.activeTool.end) throw new Error('Missing activeTool end (endTool)');

    const selectionTool = isSelectionTool(user.activeTool.id);

    updateToolStats(this.model.editor, drawing, user.activeTool.id, user.accountId);

    user.activeTool.end(x, y, decodePressure(encodedP));
    user.lastTool = user.activeTool;
    user.activeTool = undefined;

    user.lastX = x;
    user.lastY = y;
    user.cursorX = x;
    user.cursorY = y;
    redraw(editor);

    if (user.activeLayer && !selectionTool) {
      verifyAfterRect(this.model, user, user.activeLayer, createRect(ax, ay, aw, ah), 'endTool', user.lastToolStartData);
    }
  }
  @Method({ binary: [BinConnId, BinLocalId] })
  cancelTool(connId: number, localId: number) {
    this.push(connId, 'cancelTool', true, true, false, drawing => {
      logAction(`[remote] cancelTool (clientId: ${localId})`);

      const { editor } = this.model;
      const user = getUserOrTemp(drawing, localId, editor);
      if (!user) return this.logAndReportError(`[remote] cancelTool: missing user (clientId: ${localId})`);
      if (!user.activeTool) return this.logAndReportError(`[remote] cancelTool: missing activeTool (clientId: ${localId})`);

      if (user.activeTool.cancelBeganTool) {
        user.activeTool.cancelBeganTool(true);
      } else {
        cancelToolInternal(editor, user.activeTool, user, false);
      }

      redrawDrawing(drawing);
      user.activeTool = undefined;
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.U32] })
  undo(connId: number, localId: number, t: number) {
    this.push(connId, 'undo', true, true, false, drawing => {
      const { editor } = this.model;
      const user = getUserOrTemp(drawing, localId, editor);
      if (!user) throw new Error(`Missing user (undo, clientId: ${localId})`);

      logAction(`[remote] undo (clientId: ${localId}, t: ${t}) ${undosLog(user.history)}`);

      if (!user.history.canUndo()) {
        this.model.reportError(`invalid undo (clientId: ${localId}, t: ${t})`);
      }

      user.history.undo();
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.U32] })
  redo(connId: number, localId: number, t: number) {
    this.push(connId, 'redo', true, true, false, drawing => {
      const { editor } = this.model;
      const user = getUserOrTemp(drawing, localId, editor);
      if (!user) throw new Error(`Missing user (redo, clientId: ${localId})`);

      logAction(`[remote] redo (clientId: ${localId}, t: ${t}) ${redosLog(user.history)}`);

      if (!user.history.canRedo()) {
        this.model.reportError(`invalid redo (clientId: ${localId}, t: ${t})`);
      }

      user.history.redo();
    });
  }
  @Apply() @Method({ binary: [Bin.U8, Bin.Obj] })
  clientAction(action: ClientAction, param?: any) {
    switch (action) {
      case ClientAction.Sleep: {
        logAction(`[remote] trigger sleep`);
        this.model.isSleeping = true;
        this.model.connectionService.disconnect();
        break;
      }
      case ClientAction.SwitchSocket: {
        logAction(`[remote] switch sockets [${param}]`);
        DEVELOPMENT && console.log('switch sockets', param);

        if (param) {
          for (const id of param as string[]) {
            removeItem(this.socket.drawings, id);
          }

          this.model.connectionService.update();
          return;
        }

        this.model.reconnect();
        break;
      }
      case ClientAction.AccountCreated: {
        sendGAEvent('Sign up', 'Signed up', param);
        break;
      }
      case ClientAction.AiUsageUpdated: {
        this.model.editor.aiTool.usageQuota = param as { used: number, total: number };
        break;
      }
      case ClientAction.RecentDrawings: {
        this.model.recentDrawings = param;
        break;
      }
      case ClientAction.UpdateDrawingLicensing: {
        this.model.drawing.licensing = param;
        break;
      }
      default: invalidEnum(action);
    }
  }
  @Method({ binary: [BinConnId, Bin.U8, Bin.Obj] })
  clientDrawingAction(connId: number, action: ClientDrawingAction, param?: any) {
    // TODO: should this have `history: true` ???
    this.push(connId, 'clientDrawingAction', true, false, true, drawing => {
      const { model } = this;
      const { editor } = model;

      switch (action) {
        case ClientDrawingAction.LeaveLayer: {
          if (!editor) throw new Error('Editor not set');

          const layerId = param | 0;
          const layer = getLayer(drawing, layerId);

          // TODO: refactor all this cancelling into some better place
          let filtering = !!editor.filter?.activeFilter;

          logAction(`[remote] leaveLayer (filtering: ${filtering}, layerId: ${layerId})`);

          if (model.drawing === drawing) {
            if (filtering) {
              if (editor.activeLayer?.id === layerId) {
                // cancel filter if it's on layer that we want to free
                editor.filterCancelled.next();
                filtering = false;
              } else {
                // disable `drawingInProgress` to allow for `disownLayer`
                editor.drawingInProgress = false;
              }
            }

            if (editor.hasActiveTool() && editor.activeToolLayerIds().includes(layerId)) {
              editor.activeTool?.cancelBeganTool?.();
            }

            nowOrAfterFinish(editor, editor => {
              logAction(`[remote] trigger disown (layerId: ${layerId})`);
              disownLayer(editor, layer).catch(e => DEVELOPMENT && console.error(e));
            });

            if (filtering) editor.drawingInProgress = true;
          } else {
            disownLayerOn(editor, drawing.user, drawing, layer).catch(e => DEVELOPMENT && console.error(e));
          }
          break;
        }
        case ClientDrawingAction.LeaveAllLayers: {
          if (model.drawing === drawing) {
            // cancel filter
            if (editor?.filter?.activeFilter) {
              editor.filterCancelled.next();
            }

            nowOrAfterFinish(editor, editor => {
              logAction(`[remote] trigger disown all`);
              disownAllLayers(editor, drawing.user, drawing).catch(e => DEVELOPMENT && console.error(e));
            });
          } else {
            disownAllLayers(editor, drawing.user, drawing).catch(e => DEVELOPMENT && console.error(e));
          }
          break;
        }
        case ClientDrawingAction.RevisionRestoring:
          this.showRevisionRestoreToast();
          break;
        case ClientDrawingAction.LoadErrorMessage: {
          logAction(`[remote] load error message (${param})`);
          DEVELOPMENT && console.warn('Loading error', param);
          model.error = undefined;
          const prevLoadingFailed = drawing.loadingFailed;
          drawing.loadingFailed = DrawingLoadFailure.Error;
          setLoaded(1, drawing, model.editor);

          if (IS_HOSTED && param === SIGN_IN_TO_VIEW_ERROR) {
            return; // we're showing this message in alerts
          }

          if (failedErrors[param]) {
            const failedError = failedErrors[param];
            if (prevLoadingFailed !== failedError) {
              const reason = getViewUnauthorizedPageReason(failedError);
              if (this.trackService !== undefined && reason !== undefined) {
                this.trackService.event<ViewUnauthorizedPageEvent>(Analytics.ViewUnauthorizedPage, {
                  reason, isAnonymous: this.model.user.anonymous,
                });
              }
            }
            drawing.loadingFailed = failedError;
            return;
          }

          if (param === DRAWING_DELETED) {
            model.modals.drawingDeleted();
            return;
          }

          if (IS_PORTAL && param === ACCESS_DENIED_MISSING_BIRTHDATE) {
            model.modals.setUserBirthdate().then(() => {
              location.reload();
            }).catch(e => {
              DEVELOPMENT && console.error(e);
              model.showError(param);
            });
            return;
          }

          if (IS_PORTAL && param === ACCESS_DENIED_ACCEPT_RULES) {
            if (!this.jamsService) throw new Error('No jams service');
            this.jamsService.getJam(drawing.id).then(jamDrawing => {
              if (jamDrawing) {
                if (model.modals.isOpen('jamsJoin')) return; // hack for already opened modal
                return model.modals.joinJam({ jamDrawing, fromEditor: true, indexOnTheList: -1 });
              } else {
                return model.modals.drawingDeleted();
              }
            }).catch(e => {
              // if for some reasons we were unable to get jam info show regular editor error
              DEVELOPMENT && console.error(e);
              model.showError(param);
            });
            return;
          }

          if (param === DRAWING_REVISION_RESTORING) {
            this.showRevisionRestoreToast();
            setTimeout(() => {
              model.reconnect();
            }, 1000);
            return;
          }

          if (isUserLimitError(param)) {
            model.modals.userLimit(+param.replace(/[^\d]/g, ''));
          }

          if (param === CLIENT_LIMIT_ERROR && !shownOverloaded) {
            model.modals.overloaded();
            shownOverloaded = true;
          }

          model.showError(param);

          if (DEVELOPMENT && /no active workers/.test(param)) {
            setTimeout(() => model.reconnect(), 500);
          }
          break;
        }
        case ClientDrawingAction.UpdateOwnerName: {
          const { from, to, color } = param as UpdateOwnerName;
          // update layer owner names
          for (const layer of drawing.layers) {
            if (layer.layerOwner?.name === from) {
              layer.layerOwner.name = to;
              layer.layerOwner.color = color ?? layer.layerOwner.color;
            }
          }
          break;
        }
        case ClientDrawingAction.DebugLayers: {
          const { server } = this.socket.socket;
          server.debug(drawing.connId, 'layers', `\n[${getTimestamp(Date.now())}] clientId: ${drawing.user.localId}\n` +
            `users: [${drawing.users.map(u => u.localId).join(', ')}]\n` +
            `layers: [${drawing.layers.map(l => l.id).join(', ')}]\n` +
            `actions:\n${getActionLog().map(x => `  ${x}`).join('\n')}\n\n`);
          break;
        }
        case ClientDrawingAction.DebugLayerSnapshot: {
          if (editor) {
            sendLayerSnapshot(editor, drawing, param | 0).catch(e => DEVELOPMENT && console.error(e));
          }
          break;
        }
        case ClientDrawingAction.DeletingDrawing: {
          if (drawing.id && drawing.sequence.length > 1) {
            const newDrawing = drawing.sequence.find(x => x.id !== drawing.id);
            if (newDrawing) navigateToDrawing(model.manage.router, newDrawing.id, 'deleting-drawing');
          }
          break;
        }
        case ClientDrawingAction.AiPromptAdded: {
          drawing.promptHistory?.unshift(param);
          break;
        }
        case ClientDrawingAction.AiPromptsList: {
          const aiTool = editor?.aiTool;
          const items = param as PromptHistoryItem[];
          aiTool?.appendPromptHistory(items);
          break;
        }
        case ClientDrawingAction.RevisionCreated: {
          const data = param as ClientActionRevisionCreated;
          model.revisionCreated(data.revision);
          break;
        }
        case ClientDrawingAction.RevisionUpdated: {
          const data = param as ClientActionRevisionUpdated;
          model.revisionUpdated(data.revisionId, data.update);
          break;
        }
        case ClientDrawingAction.RevisionRemoved: {
          const data = param as ClientActionRevisionRemoved;
          model.revisionRemoved(data.revisionId);
          break;
        }
        case ClientDrawingAction.VerifyHistory: {
          logAction('ClientDrawingAction.VerifyHistory');
          const { localId: remoteLocalId, undoLog: remoteUndosLog } = param as { localId: number, undoLog: string };
          const issuer = getUserByLocalId(drawing, remoteLocalId);
          if (!issuer) throw new Error('No issuer of history verification in drawing');
          const localUndosLog = undosLog(issuer.history, false);
          if (localUndosLog !== remoteUndosLog) {
            DEVELOPMENT && console.error('History desynchronised!', { local: localUndosLog, remote: remoteUndosLog });
            logAction(`  Issuer (localId: ${issuer?.localId}): "${remoteUndosLog}"`);
            logAction(`  Local (localId: ${model.user.localId}): "${localUndosLog}"`);
            model.reportError('History desynchronised!', undefined, { local: localUndosLog, remote: remoteUndosLog });
          }
          break;
        }
        case ClientDrawingAction.ChangeLayerPersonalVisibility: {
          const { layerId, visible } = param as { layerId: number, visible: boolean | undefined };
          if (drawing.personalVisibilityStates !== undefined) {
            updateDrawingPersonalVisibility(drawing, layerId, visible);
            const layer = drawing.layers.find((layer) => layer.id === layerId);
            if (layer !== undefined) {
              layer.visibleLocally = visible;
            }
            redrawDrawing(drawing);
          }
          break;
        }
        case ClientDrawingAction.DrawingLicenseUpdated: {
          const data = param as ArtworkLicensingData;
          if (this.model.editor) {
            drawing.licensing = data;
            const toNotify = data.revision?.publishedIn?.StoryProtocol?.participants.find(e => e.accountId === this.model.user.accountId)?.status === PublicationStatus.Pending;
            const isPublisher = drawing.user.accountId === data.revision?.publishedIn?.StoryProtocol?.publisher._id;
            const userIsLinked = !!this.model.manage.userData()?.publishers?.[PublisherId.StoryProtocol];

            if (toNotify && !isPublisher && userIsLinked) {
              this.model.modals.openRequestingRegistrationNotification(data).then(response => {
                if (!response) return;
                let status = undefined;
                switch (response) {
                  case RequestArtworkRegistrationResponse.Reject: status = PublicationStatus.Rejected; break;
                  case RequestArtworkRegistrationResponse.Accept: status = PublicationStatus.Accepted; break;
                  default: break;
                }
                this.model.modals.openArtworkPendingLicense({ ...data, status })
                  .then(res => res !== undefined && updateAndBroadcastDrawingLicense(this.model, res.license))
                  .catch(e => console.error(e));
                return;
              }).catch(e => DEVELOPMENT && console.error(e));
            }
          }
          break;
        }
        case ClientDrawingAction.DrawingParticipantsUpdated: {
          const participants = param as ClientParticipant[];
          drawing.participants = [...participants];
          break;
        }
        case ClientDrawingAction.ReferencedDrawingResized: {
          const arg = param as ClientActionReferencedDrawingResized;
          const layer = drawing.layers.find(l => l.ref?.id === arg.drawingId);

          if (layer) {
            copyRect(layer.ref!.drawingRect, arg);
            redrawDrawing(drawing);
          } else {
            DEVELOPMENT && console.warn('Referenced drawing not found for resize', param);
          }
          break;
        }
        default: invalidEnum(action);
      }
    });
  }
  @Apply() @Method({ binary: [Bin.U8, Bin.Obj] })
  voiceChatAction<T extends VcActions>(action: VoiceChatAction, param: T) {
    const model = this.model;
    const voiceChat = model.voiceChat;

    // @ignore-translation
    const callerLog = param.caller ? ` (caller: ${param.caller})` : '';

    switch (action) {
      case VoiceChatAction.Session:
      case VoiceChatAction.SessionInitial: {
        const { hasSession } = param as VcSession;
        voiceChat.lonelyCall = true;
        const hadSession = !!voiceChat.session;
        voiceChat.session = hasSession;
        logAction(`[voice] ${action === VoiceChatAction.SessionInitial ? 'VoiceChatAction.SessionInitial' : 'VoiceChatAction.Session'}(${hadSession} -> ${hasSession})${callerLog}`);
        if (!voiceChat.session) {
          voiceChat.setToken(undefined, 'no session');
        }

        // ignore if we're getting session while opening the drawing
        if (voiceChat.session && !hadSession && action !== VoiceChatAction.SessionInitial) {
          voiceChat.playSound('started');
        }

        // reconnect if user closed/refreshed tab with ongoing voice chat call
        const connected = storageGetJson('voice-chat-connected') as { id: string; time: number; } | undefined;
        const mainDrawingId = getMainDrawingId(model.drawing);
        storageRemoveItem('voice-chat-connected');
        if (voiceChat.session && connected && connected.id === mainDrawingId && (Date.now() - connected.time) < (5 * MINUTE)) {
          logAction('[voice] vc auto-connect');
          attemptToJoinVc(voiceChat);
        }
        break;
      }
      case VoiceChatAction.Token: {
        const { token } = param as VcToken;
        logAction(`[voice] VoiceChatAction.Token(${!!token})${callerLog}`);
        voiceChat.session = true;
        voiceChat.drawingId = getMainDrawingId(model.drawing);
        voiceChat.setToken(token, 'token action');
        if (voiceChat.lonelyCall && voiceChat.joined.size) {
          voiceChat.lonelyCall = false;
          const voiceCalls = storageGetNumber('voiceCalls');
          storageSetNumber('voiceCalls', voiceCalls + 1);
        }
        break;
      }
      case VoiceChatAction.Joined: {
        const { peerId } = param as VcPeerId;
        logAction(`[voice] "${peerId}" joined (me: ${model.user.uniqId})${callerLog}`);
        if (peerId !== model.user.uniqId) {
          voiceChat.joined.add(peerId);
          if (voiceChat.lonelyCall && voiceChat.isConnected) {
            voiceChat.lonelyCall = false;
            const voiceCalls = storageGetNumber('voiceCalls');
            storageSetNumber('voiceCalls', voiceCalls + 1);
          }
        }
        break;
      }
      case VoiceChatAction.Left: {
        const { peerId } = param as VcPeerId;
        logAction(`[voice] "${peerId}" left (me: ${model.user.uniqId})${callerLog}`);
        if (peerId === model.user.uniqId) {
          // force user to leave by themselves
          voiceChat.consumeTokens = false;
          voiceChat.stop('VoiceChatAction.Left');
        } else {
          voiceChat.joined.delete(peerId);
        }
        break;
      }
      case VoiceChatAction.Mute: {
        const { peerId } = param as VcPeerId;
        logAction(`[voice] muted "${peerId}"${callerLog}`);
        voiceChat.muted.add(peerId);
        voiceChat.updateUserMutes();
        break;
      }
      case VoiceChatAction.Unmute: {
        const { peerId } = param as VcPeerId;
        logAction(`[voice] unmuted "${peerId}"${callerLog}`);
        voiceChat.muted.delete(peerId);
        voiceChat.updateUserMutes();
        break;
      }
      case VoiceChatAction.MuteAdmin: {
        const { peerId } = param as VcPeerId;
        logAction(`[voice] muted "${peerId}" for everyone${callerLog}`);
        voiceChat.mutedByAdmin.add(peerId);
        voiceChat.updateUserMutes();
        break;
      }
      case VoiceChatAction.UnmuteAdmin: {
        const { peerId } = param as VcPeerId;
        logAction(`[voice] unmuted "${peerId}" for everyone${callerLog}`);
        voiceChat.mutedByAdmin.delete(peerId);
        voiceChat.updateUserMutes();
        break;
      }
      case VoiceChatAction.ListOfActions: {
        const { actions } = param as VcActionList;
        logAction(`[voice] start of ListOfActions (${actions.length} items)${callerLog}`);
        for (const [a, p] of actions) {
          this.voiceChatAction(a, p);
        }
        logAction(`[voice] end of ListOfActions`);
        break;
      }
      default: invalidEnum(action);
    }
  }
  @Method({ binary: [BinConnId, [BinLocalId, Bin.F32, Bin.F32]] })
  cursors(connId: number, cursors: [number, number, number][]) {
    this.push(connId, 'cursors', false, false, false, drawing => {
      for (const [localId, x, y] of cursors) {
        const user = getUserByLocalId(drawing, localId);

        if (DEVELOPMENT && !user) console.warn('Missing user for cursor', localId);

        if (user && user !== drawing.user && (user.cursorX !== x || user.cursorY !== y)) {
          user.cursorX = x;
          user.cursorY = y;
          user.cursorLastUpdate = performance.now();
        }
      }

      if (this.model.drawing === drawing) redraw(this.model.editor);
    });
  }
  private getTrackedDrawing(drawingId: string) {
    for (const drawing of this.model.drawings) {
      const sequenceDrawing = findById(drawing.sequence, drawingId);
      if (sequenceDrawing) return sequenceDrawing;

      if (drawing.type === DrawingType.Board) {
        const refDrawing = drawing.layers.find(l => l.ref?.id === drawingId);
        if (refDrawing) return refDrawing.ref!;
      }
    }

    DEVELOPMENT && console.warn(`Missing tracked drawing (${drawingId})`);
    return undefined;
  }
  @Apply() @Method({ binary: [Bin.Str, Bin.Str] })
  updateTrackedThumb(drawingId: string, cacheId: string) {
    if (invalidModel(this.model)) return;

    const tracked = this.getTrackedDrawing(drawingId);
    if (!tracked) return;

    if ('rotation' in tracked) {
      // TODO: should this somehow interact with drawing.thumbData ?
      tracked.cacheId = cacheId;
      tracked.cacheIdTimestamp = Date.now();
    } else {
      // ignore if we have recent thumb data
      // TODO: should we still update cacheId here?
      if (tracked.thumbData && tracked.thumbUpdated && tracked.thumbUpdated > (Date.now() - MINUTE)) return;

      tracked.thumbTimestamp = Date.now();
      tracked.cacheId = cacheId;
    }
  }
  @Apply() @Method({ binary: [Bin.Str, Bin.U16, Bin.U16, Bin.U8, Bin.U8, Bin.U8Array] })
  updateTrackedThumbData(drawingId: string, x: number, y: number, thumbWidth: number, thumbHeight: number, compressed: Uint8Array) {
    if (invalidModel(this.model)) return;

    const drawing = this.getTrackedDrawing(drawingId);
    if (!drawing) return;

    const { width, height, data } = decompressImageDataRLE(compressed, (width, height, data) =>
      ({ width, height, data: data ?? new Uint8ClampedArray(width * height * 4), colorSpace: 'srgb' }), true);

    if ('rotation' in drawing) {
      if (!drawing.thumbData || drawing.thumbData.width !== thumbWidth || drawing.thumbData.height !== thumbHeight) {
        drawing.thumbData = getPixelContext().createImageData(thumbWidth, thumbHeight);
      }

      replaceImageDataRect(drawing.thumbData, data, { x, y, w: width, h: height });
      drawing.thumbTimestamp = Date.now();
    } else {
      if (!drawing.thumbData || drawing.thumbData.width !== thumbWidth || drawing.thumbData.height !== thumbHeight) {
        if (drawing.thumbCanvas) getContext2d(drawing.thumbCanvas).clearRect(0, 0, drawing.thumbCanvas.width, drawing.thumbCanvas.height);

        drawing.thumbData = getPixelContext().createImageData(thumbWidth, thumbHeight);
        drawing.thumbImage = undefined;
      }

      replaceImageDataRect(drawing.thumbData, data, { x, y, w: width, h: height });
      redrawSequenceThumbCanvas(drawing); // TODO: or move this to update()
    }
  }
  @Apply() @Method({ binary: [Bin.Str, Bin.Obj] })
  updateTrackedDrawing(drawingId: string, update: { name?: string }) {
    if (invalidModel(this.model)) return;

    const drawing = this.getTrackedDrawing(drawingId);
    if (!drawing) return;

    if ('rotation' in drawing) {
      // TODO: ...
    } else {
      Object.assign(drawing, update);
    }
  }
  @Apply() @Method({ binary: [Bin.Str, BinSequenceUser] })
  updateTrackedUsers(drawingId: string, users: TrackedUserData[]) {
    if (invalidModel(this.model)) return;

    const drawing = this.getTrackedDrawing(drawingId);
    if (!drawing) return;

    for (const [accountId, uniqId, name, color, avatar, role, anonymous, anonymousNumber,] of users) {
      const state: UserData = { accountId, uniqId, name, color, avatar, role, anonymous, anonymousNumber, localId: 0, ownedLayers: [] };
      const existing = drawing.users.find(u => u.uniqId === uniqId);

      if (existing) {
        setUserState(existing, state);
      } else {
        const user = createUser(uniqId, 0);
        setUserState(user, state);
        drawing.users.push(user);
      }
    }
  }
  @Apply() @Method({ binary: [Bin.Str, [Bin.Str]] })
  removeTrackedUsers(drawingId: string, uniqIds: string[]) {
    if (invalidModel(this.model)) return;

    const drawing = this.getTrackedDrawing(drawingId);
    if (!drawing) return;

    for (let i = drawing.users.length - 1; i >= 0; i--) {
      if (uniqIds.includes(drawing.users[i].uniqId)) {
        drawing.users.splice(i, 1);
      }
    }
  }
  @Apply() @Method({ binary: [Bin.U8, Bin.Obj] })
  sequenceAction(action: SequenceAction, param: unknown) {
    if (invalidModel(this.model)) return;

    const updatePresentationViewForViewers = (view?: Viewport) => {
      if (!view) return;
      nowOrAfterFinish(this.model.editor, editor => {
        editor.lastPresentationViewToMatch = view;
        matchFromOtherViewport(editor.view, view);
      });
    };

    switch (action) {
      case SequenceAction.PresentDrawing: {
        const { id, view } = param as PresentationViewerNotification;
        navigateToDrawing(this.model.manage.router, id, 'presentation');
        if (view) {
          updatePresentationViewForViewers(view);
        }
        break;
      }
      case SequenceAction.PresentViewport: {
        if (this.model.editor && !this.model.isPresentationHost && this.model.presentationModeState.followingHostViewportEnforced) {
          const view = param as Viewport;
          updatePresentationViewForViewers(view);
        }
        break;
      }
      case SequenceAction.PresentationMode: {
        const presentationMode = param as PresentationModeState;
        const editor = this.model.editor;
        dismissChatHelp();
        this.model.setPresentationMode(presentationMode);
        if (presentationMode.host?.uniqIds.includes(this.model.user.uniqId) && !storageGetBoolean('isPresentationModeHost'))
          storageSetBoolean('isPresentationModeHost', true);
        if (this.model.isPresentationMode) {
          this.model.modals.closeAllMarketingModals();
          if (editor && !this.model.isPresentationHost) {
            if (!this.model.presentationModeState.followingHostViewportEnforced) {
              fitViewportOnScreen(editor.view, editor.drawing.type === DrawingType.Board);
            } else {
              updatePresentationViewForViewers(this.model.presentationModeState.viewport?.view);
            }
          }
        }
        if (!this.model.activePresentation) {
          this.model.modals.closeAllPresentationModeModals();
        }
        break;
      }
      case SequenceAction.PresentationModeInvite: {
        const { isForced, invitedUsers, name } = param as PresentationModeInvite;

        if (isForced) {
          this.model.joinPresentation();
        } else {
          this.model.modals.presentationActionModal({
            title: 'You are invited to join Presentation',
            user: name,
            mode: PresentationActions.InvitedToPresentation,
            state: this.model.presentationModeState,
            viewers: invitedUsers
          }).then(respone => respone && this.model.joinPresentation()).catch(e => DEVELOPMENT && console.error(e));
        }
        break;
      }
      case SequenceAction.EndedPresentationMode: {
        this.model.modals.presentationActionModal({
          title: 'Presentation is over',
          mode: PresentationActions.presentationIsOver,
          state: this.model.presentationModeState,
        }).then(() => this.model.goBackToPreviousViewPresnetationMode()).catch(e => DEVELOPMENT && console.error(e));
        break;
      }
      case SequenceAction.PresentationModeKickedOut: {
        this.model.leavePresentation();
        break;
      }
      case SequenceAction.JoinPresentation: {
        const { viewer } = param as JoinPresentation;
        if (this.model.presentationModeState.viewersUniqIds?.includes(viewer)) return;

        this.model.presentationModeState.viewersUniqIds = [...this.model.presentationModeState.viewersUniqIds || [], viewer];
        if (this.model.isPresentationHost) {
          const viewport = this.model.editor?.view ? { view: this.model.editor?.view } : undefined;
          this.model.updatePresentationMode({
            viewersUniqIds: this.model.presentationModeState.viewersUniqIds,
            viewport
          });
        }
        break;
      }
      case SequenceAction.LeavePresentation: {
        const { viewer } = param as LeavePresentation;
        const viewersUniqIds = (this.model.presentationModeState.viewersUniqIds || []).filter(u => u !== viewer);
        if (viewer) {
          this.model.presentationModeState.viewersUniqIds = viewersUniqIds;
          if (this.model.isPresentationHost) this.model.updatePresentationMode({ viewersUniqIds });
        }
        break;
      }
      case SequenceAction.ClosePresentationActionModals: {
        if (!this.model.isPresentationHost) {
          this.model.modals.closeAllPresentationModeModals();
        }
        break;
      }
      case SequenceAction.PassPresenterRole: {
        this.model.modals.presentationActionModal({
          title: 'You have been given the presentation.',
          mode: PresentationActions.givenPresenterRole
        }).then(response => response && this.model.passOverPresentation())
          .catch(e => DEVELOPMENT && console.error(e));
        break;
      }
      case SequenceAction.UserTookPresenterRole: {
        const user = param as string;
        this.model.modals.presentationActionModal({
          title: 'Handing over the Presentation',
          mode: PresentationActions.HandingControlOverPresentation,
          user
        }).catch(e => DEVELOPMENT && console.error(e));
        break;
      }
      case SequenceAction.RemovePresentationHostStatus: {
        storageRemoveItem('isPresentationModeHost');
        break;
      }
      case SequenceAction.UserDisconnectedPresentationMode: {
        const uniqId = param as string;
        const viewersClientIds = this.model.presentationModeState.viewersUniqIds || [];
        const { host } = this.model.presentationModeState;
        this.model.presentationModeState.viewersUniqIds = viewersClientIds.filter(u => u !== uniqId);

        if (host?.uniqIds?.includes(uniqId) && this.model.isPresentationViewer) {
          if (host.uniqIds.length <= 1) {
            this.model.updatePresentationMode({ host });
            if (this.model.isPresentationCoPresenter(this.model.user.uniqId)) {
              this.model.modals.presentationActionModal({
                title: 'Take over Presentation',
                mode: PresentationActions.takeOverPresentation,
                timer: PRESENTATION_TAKE_OVER_TIME
              }).then(response => {
                if (response) {
                  this.model.takeOverPresentation();
                } else if (response === false) {
                  this.model.stopPresentationMode();
                }
              }).catch(e => DEVELOPMENT && console.error(e));
            } else {
              this.model.modals.presentationActionModal({
                title: 'Host is disconnected',
                mode: PresentationActions.presentationHostDisconnected,
                user: host.name,
                timer: PRESENTATION_HOST_DISCONNECTED_TIME
              }).then(response => {
                if (response) {
                  this.model.leavePresentation();
                  if (this.model.presentationModeState.viewersUniqIds?.length === 1 || this.model.presentationModeState.host!.uniqIds.length <= 1) this.model.stopPresentationMode();
                }
              }).catch(e => DEVELOPMENT && console.error(e));
            }
          }
        } else if (this.model.isPresentationHost && host?.uniqIds.includes(uniqId)) {
          this.model.updatePresentationMode({ host: { ...host, uniqIds: host.uniqIds.filter(id => uniqId !== id) } });
        } else if (host?.uniqIds?.includes(uniqId) && this.model.presentationModeState.host!.uniqIds.length <= 1 && this.model.getPresentationViewers.length === 0) {
          this.model.stopPresentationMode();
        }
        break;
      }
      default: invalidEnum(action);
    }
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.U32, Bin.U32, Bin.U32, Bin.U8Array] })
  clientDataChunk(connId: number, localId: number, id: number, size: number, offset: number, chunk: Uint8Array) {
    // TODO: do we need to copy chunk ?

    this.push(connId, 'clientDataChunk', true, true, false, drawing => {
      // TODO: need to handle chunks when opening a drawing before using this method
      const user = getUserByLocalId(drawing, localId);

      if (!user) {
        DEVELOPMENT && console.warn(`[clientDataChunk] User does not exist (localId: ${localId})`);
        return;
      }

      const chunkedData = user.chunkedData.get(id);
      if (chunkedData) {
        chunkedData.set(chunk, offset);
      } else {
        const c = new Uint8Array(size);
        c.set(chunk, offset);
        user.chunkedData.set(id, c);
      }

      // TODO when this will be used somewhere it would be a good idea to mark chunks as complete
    });
  }
  @Method({ binary: [BinConnId, BinLocalId, Bin.U32] })
  cancelChunkedData(connId: number, localId: number, id: number) {
    this.push(connId, 'cancelChunkedData', true, true, false, drawing => {
      const user = getUserByLocalId(drawing, localId);

      if (!user) {
        DEVELOPMENT && console.warn(`[cancelChunkedData] User does not exist (localId: ${localId})`);
        return;
      }

      user.chunkedData.delete(id);
    });
  }
  @Method({ binary: [Bin.Str, Bin.Str, Bin.I32, Bin.I32, Bin.Str, Bin.I32, Bin.I32, Bin.Str] })
  shapeShape(id: string, name: string, width: number, height: number, path: string, iconWidth: number, iconHeight: number, iconPath: string | undefined) {
    addShape({
      id,
      name,
      path: createShapePath(width, height, path),
      icon: iconPath ? createShapePath(iconWidth, iconHeight, iconPath) : undefined,
    });
  }
  @Method({ binary: [Bin.Str, Bin.Str, Bin.U32, Bin.U32, Bin.U8Array] })
  brushShape(id: string, name: string, width: number, height: number, compressed: Uint8Array) {
    if (!brushShapesMap.has(id)) {
      addBrushShape({ id, name, imageData: { width, height, compressed } });
    }
  }
  @Method({ binary: [Bin.Str, Bin.Str, Bin.I32, Bin.I32, Bin.Str] })
  brushShapePath(id: string, name: string, width: number, height: number, path: string) {
    if (!brushShapesMap.has(id)) {
      addBrushShape({ id, name, path: createShapePath(width, height, path) });
    }
  }
  @Method({ binary: [Bin.Str, Bin.U8Array] })
  fileContent(name: string, content: Uint8Array | undefined) {
    this.model.fileContent(name, content);
  }
  private getActiveTool(drawing: Drawing, localId: number, editor: Editor, data: IToolData, caller: string) {
    if (editor.drawing === drawing && localId === editor.drawing.user.localId) {
      if (!editor.activeTool) throw new Error(`Missing activeTool (${caller}, clientId: ${localId})`);
      if (editor.activeTool.id !== data.id && editor.activeTool.navigation) {
        // eyedropper, move tool or other navigation tool is currently used. Find began tool and send update to that tool
        const tool = findById(editor.tools, data.id);
        if (!tool) throw new Error(`Missing tool (${caller}, clientId: ${localId}, toolId: ${data.id})`);
        if (!tool.hasBegan) throw new Error(`Selected tool was not begun (${caller}, clientId: ${localId}, toolId: ${data.id})`);
        return tool;
      } else if (editor.activeTool.id === data.id) {
        return editor.activeTool;
      } else {
        if ('jobId' in data && this.lastJobId === (data as any).jobId) {
          DEVELOPMENT && console.warn(`Received update for already finished/canceled tool,  lastJobId: ${this.lastJobId} jobId: ${(data as any).jobId}`);
          return undefined;
        }
        throw new Error(`Received updateTool for different tool (${caller}, clientId: ${localId}), activeTool: ${editor.activeTool.id}, toolId: ${data.id}`);
      }
    } else {
      const user = getUserOrTemp(drawing, localId, editor);
      if (!user) throw new Error(`Missing user (${caller}, clientId: ${localId})`);
      if (!user.activeTool) throw new Error(`Missing activeTool (${caller}, clientId: ${localId})`);
      if (user.activeTool.id !== data.id) throw new Error(`Invalid activeTool (${caller}, clientId: ${localId})`);
      return user.activeTool;
    }
  }
  private logAndReportError(message: string) {
    logAction(message);
    this.model.reportError(message);
  }
  private showRevisionRestoreToast() {
    if (!this.model.revisionRestoreInProgress) {
      this.model.revisionRestoreInProgress = this.model.toasts?.loading({ message: 'Drawing revision is being restored', timeout: 0 });
    }
  }
}

function nowOrAfterFinish(editor: Editor, func: (editor: Editor) => void) {
  if (editor.drawingInProgress) {
    logAction('delay action after finish');
    editor.afterFinish.push(() => func(editor));
  } else {
    func(editor);
  }
}

function getCallerName(steps: number) {
  const stack = (new Error()).stack?.replace(/^Error\s+/i, '') ?? '';
  return stack.split(/\n/g)[steps + 1]?.trim().replace(/^at /, '').split(/[ @]/)[0] ?? '?';
}

function invalidModel(model: Model) {
  if (!model.editorMaybe || model.fatalError) {
    // @ignore-translate
    const msg = `invalid model (editor: ${!!model.editorMaybe}, fatarError: ${model.fatalError}] (${getCallerName(1)})`;
    DEVELOPMENT && !TESTS && console.warn(msg);
    logAction(msg);
    return true;
  } else {
    return false;
  }
}

async function commitWaitingActions(drawing: Drawing) {
  drawing.insideCommitLoop = true;

  while (drawing.waitingActions.length) {
    drawing.waitingActions.shift()!();
    if (drawing.toolPromise) await drawing.toolPromise;
  }

  drawing.insideCommitLoop = false;
}

function beginClientTool(drawing: Drawing, editor: Editor, localId: number, layerId: number, tool: IToolData) {
  const user = getUserOrTemp(drawing, localId, editor);
  if (!user) throw new Error(`No user (${localId})`);

  const layer = getLayer(drawing, layerId);

  if (layer) layer.owner = user;

  setActiveLayer(user, layer);

  if (user.activeTool?.id !== tool.id) {
    user.activeTool = createTool(tool.id, editor, createFakeModel(editor, drawing, user));
  }

  return user.activeTool;
}

async function execToolWithUpdates(drawing: Drawing, editor: Editor, localId: number, layerId: number, tool: IToolData, updates: any[]) {
  const t = beginClientTool(drawing, editor, localId, layerId, tool);
  t.begin?.(tool);
  for (const update of updates) {
    await t.update?.(tool, update);
  }
  t.finish?.();
}

function checkSelectionBefore(id: ToolId) {
  // all tools with start..move..end
  return id === ToolId.Brush || id === ToolId.Pencil || id === ToolId.Eraser || id === ToolId.LassoBrush || id === ToolId.LassoSelection;
}

function execTool(drawing: Drawing, model: Model, localId: number, layerId: number, tool: IToolData, data: Uint8Array | undefined, finishAfter = false) {
  const { editor } = model;
  const user = getUserOrTemp(drawing, localId, editor);
  if (!user) throw new Error(`No user (${localId})`);

  let prevActiveLayer = user.activeLayer;

  const checkBefore = checkSelectionBefore(tool.id);
  if (checkBefore) verifySelection(model, user, tool);
  const promise = applyTool(editor, drawing, user, layerId, tool, data);

  if (promise) {
    return drawing.toolPromise = promise
      .then(async () => {
        execToolEnd(editor, drawing, user, prevActiveLayer);
        if (finishAfter) finishTransform(createFakeModel(editor, drawing, user), 'execTool-1');
        if (!checkBefore) verifySelection(model, user, tool);
        drawing.toolPromise = undefined;
        if (!drawing.insideCommitLoop) await commitWaitingActions(drawing);
      })
      .catch(e => {
        logAction(`Error in toolPromise ${e.message}`);
        throw e;
      });
  } else {
    execToolEnd(editor, drawing, user, prevActiveLayer);
    if (finishAfter) finishTransform(createFakeModel(editor, drawing, user), 'execTool-2');
    if (!checkBefore) verifySelection(model, user, tool);
    return undefined;
  }
}

function execToolEnd(editor: Editor, drawing: Drawing, user: User, prevActiveLayer: Layer | undefined) {
  if (prevActiveLayer && !includes(drawing.layers, prevActiveLayer)) {
    prevActiveLayer = undefined;
  }

  editor.apply(() => setActiveLayer(user, prevActiveLayer));
}

// chat

function createChatMessage(user: User | undefined, type: ChatType, message: string): IChatMessage {
  const msg: IChatMessage = { user, type, messages: [message] };

  if (type === ChatType.Invite) {
    msg.openId = message;
    msg.messages = [`You are invited to join new drawing`];
  }

  return msg;
}

function chatMessage(drawing: Drawing, model: Model, uniqId: string, message: string, type: ChatType) {
  model.onBeforeChatMessage.next();

  const user = getUserByUniqId(drawing, uniqId, true);
  const lastMessage = model.messages[model.messages.length - 1];

  if (lastMessage && lastMessage.user?.uniqId === user?.uniqId && lastMessage.type === type && type !== ChatType.Invite) {
    lastMessage.messages.push(message);
  } else {
    model.messages.push(createChatMessage(user, type, message));
  }

  while (model.messages.length > CHAT_MESSAGE_HISTORY_LIMIT) {
    model.messages.shift();
  }

  if (user?.uniqId !== model.uniqId && !model.chatOpened) {
    const msg = createChatMessage(user, type, message);

    if (model.message || model.messageQueue.length) {
      model.messageQueue.push(msg);
    } else {
      void showMessage(model, msg);
    }
  }
}

async function showMessage(model: Model, message: IChatMessage) {
  model.message = message;
  await delay(5000 + (message.messages[0].length * 50) * (message.type === ChatType.Invite ? 2 : 1));
  model.message = undefined;
  await delay(200);

  if (model.messageQueue.length) {
    void showMessage(model, model.messageQueue.shift()!);
  }
}

// other

function attemptToJoinVc(voiceChat: VoiceChatService) {
  if (navigator.userActivation.hasBeenActive) {
    voiceChat.start('attemptToAutoJoinVc:instant').catch(e => DEVELOPMENT && console.error(e));
  } else {
    const now = Date.now();
    window.addEventListener('click', () => {
      if (Date.now() - now < 5 * SECOND) {
        voiceChat.start('attemptToAutoJoinVc:deferred').catch(e => DEVELOPMENT && console.error(e));
      } else {
        // TODO: display some feedback?
      }
    }, { once: true });
  }
}
