import { Point, Rect } from './interfaces';
import { round5 } from './mathUtils';
import { getPixelRatio } from './utils';

export const zeroPoint = createPoint(0, 0);

export function createPoint(x: number, y: number): Point {
  return { x, y };
}

export function copyPoint(dest: Point, src: Point) {
  dest.x = src.x;
  dest.y = src.y;
}

export function movePoint(dest: Point, x: number, y: number) {
  dest.x += x;
  dest.y += y;
}

export function setPoint(dest: Point, x: number, y: number) {
  dest.x = x;
  dest.y = y;
}

export function clonePoint(point: Point) {
  return createPoint(point.x, point.y);
}

export function centerPoint(point: Point) {
  const ratio = getPixelRatio();
  point.x = round5(point.x * ratio);
  point.y = round5(point.y * ratio);
}

export function pointInHalf(x1: number, y1: number, x2: number, y2: number): Point {
  return createPoint((x1 + x2) / 2, (y1 + y2) / 2);
}
export function pointInHalfBetweenPoints(a: Point, b: Point): Point {
  return pointInHalf(a.x, a.y, b.x, b.y);
}

export function rectToPoints(points: Point[], rect: Rect) {
  points[0].x = rect.x;
  points[0].y = rect.y;
  points[1].x = rect.x + rect.w;
  points[1].y = rect.y;
  points[2].x = rect.x + rect.w;
  points[2].y = rect.y + rect.h;
  points[3].x = rect.x;
  points[3].y = rect.y + rect.h;
}
