import { AfterViewInit, Directive, ElementRef, Optional, Pipe, PipeTransform, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { escape } from 'lodash';
import { colorToCSS } from '../../common/color';
import { getUrl } from '../../common/rev';
import { formatDuration, getAvatarPath, relativeTime, replaceKeys, toDegrees, toPercentage } from '../../common/utils';
import { Model } from '../../services/model';
import { IThreadService } from '../../services/thread.service.interface';
import { convertMarkdownToComment } from './directives/mention.directive';
import { locale, nonTranslatable, pluralize, translateFromMap, translateWithPlaceholders } from '../../common/i18n';

@Pipe({ name: 'deg' })
export class Deg implements PipeTransform {
  transform(value: any, decimals = 0, suffix = '°') {
    return isNaN(+value) ? '' : toDegrees(value, decimals, suffix);
  }
}

@Pipe({ name: 'num' })
export class Num implements PipeTransform {
  transform(value: any) {
    return isNaN(+value) ? value : value.toFixed(Math.abs(+value) < 10 ? 1 : 0);
  }
}

@Pipe({ name: 'filter', pure: false })
export class Filter implements PipeTransform {
  transform(items: any, search?: (value: any, index: number, array: any[]) => boolean) {
    return (items && items.filter && search) ? items.filter(search) : items;
  }
}

@Pipe({ name: 'kbd' })
export class Kbd implements PipeTransform {
  transform(value: any) {
    return value && replaceKeys(value);
  }
}

@Pipe({ name: 'rev' })
export class Rev implements PipeTransform {
  transform(value: any) {
    return value && getUrl(value);
  }
}

@Pipe({ name: 'join' })
export class JoinPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return Array.isArray(value) ? value.join(args[0]) : value;
  }
}

const hasEmojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

async function transformChat(value: string) {
  value = escape(value);
  value = value.replace(
    // added `;` to catch `&` converted to `&amp;`
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}(\.[a-z]{2,6}|:[0-9]+)\b([-a-zA-Z0-9@:%_\+.~#?&;//=]*))/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
  if (hasEmojiRegex.test(value)) {
    const { default: emojione } = await import('emojione_minimal' /* webpackChunkName: "emoji" */);
    (emojione as any).imagePathPNG = '/assets/emoji/';
    value = emojione.toImage(value);
  }
  return value;
}

@Pipe({ name: 'chat' })
export class Chat implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  async transform(value: any) {
    value = await transformChat(value);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: 'comment' })
export class CommentPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private model: Model, private threadService: IThreadService) { }
  async transform(value: string) {
    const suggestions = await this.threadService.getMentionSuggestions();
    value = await transformChat(value);
    value = convertMarkdownToComment(value, this.model.user?.accountId, suggestions);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

const onlyEmojis = /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]| )+$/;

export function isEmojiOnly(value: string) {
  try {
    return !!value && onlyEmojis.test(value); // emojione.unifyUnicode(value));
  } catch {
    return false;
  }
}

@Pipe({ name: 'isEmojiOnly' })
export class IsEmojiOnly implements PipeTransform {
  transform(value: any) {
    return isEmojiOnly(value);
  }
}

@Pipe({ name: 'colorToCss' })
export class ColorToCss implements PipeTransform {
  transform(value: any) {
    return colorToCSS(value | 0);
  }
}

@Pipe({ name: 'percentage' })
export class Percentage implements PipeTransform {
  transform(value: any) {
    return toPercentage(value);
  }
}

@Pipe({ name: 'percentageOrOff' })
export class PercentageOrOff implements PipeTransform {
  transform(value: any) {
    return value === 0 ? 'off' : toPercentage(value);
  }
}

@Pipe({ name: 'linkEmails' })
export class LinkEmails implements PipeTransform {
  transform(value: string) {
    return escape(value).replace(/([a-z0-9._-]+@[a-z0-9._-]+)/g, '<a href="mailto:$1">$1</a>');
  }
}

@Pipe({ name: 'avatarPath' })
export class AvatarPath implements PipeTransform {
  transform(value: string | undefined, size?: string | number) {
    return getAvatarPath(value, size ? (+size | 0) : 32);
  }
}

@Pipe({ name: 'wrapUrl' })
export class WrapUrl implements PipeTransform {
  transform(value: string) {
    return `url(${value})`;
  }
}

@Pipe({ name: 'largeNumber' })
export class LargeNumber implements PipeTransform {
  transform(value: number) {
    if (value < 1000) {
      return `${value}`;
    } else if (value < 10_000) {
      return `${(value / 1000).toFixed(1)}k`;
    } else if (value < 1_000_000) {
      return `${(value / 1000).toFixed(0)}k`;
    } else if (value < 10_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value < 1_000_000_000) {
      return `${(value / 1_000_000).toFixed(0)}M`;
    } else {
      return `${(value / 1_000_000_000).toFixed(1)}G`;
    }
  }
}

@Pipe({ name: 'relativeTime' })
export class RelativeTime implements PipeTransform {
  transform(value: string | Date | undefined) {
    return relativeTime(value);
  }
}

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(value: any) {
    return formatDuration(value | 0);
  }
}

@Directive({ selector: '[translate], translate' })
export class TranslateDirective implements AfterViewInit {
  private placeholders: any[] = [];
  private placeholderDomains: (string | undefined)[] = [];
  private withTemplate = false;
  private queuedUpdate = false;
  private message: string | undefined = undefined;
  private textNodes: (Text | undefined)[] = [];

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) {
  }

  ngAfterViewInit() {
    this.message = this.elementRef.nativeElement?.innerText || '';
    if (!this.message) return;

    // check if translate was used as attribute or element
    const elementUse = this.elementRef.nativeElement.tagName.toLowerCase() === 'translate';
    this.elementRef.nativeElement.innerText = '';

    const parent = elementUse ? this.elementRef.nativeElement.parentElement : this.elementRef.nativeElement;

    // replace $1, $2, $3, ... with placeholders
    this.withTemplate = !!this.placeholders.find(v => v instanceof TemplateRef);
    if (this.withTemplate) {
      // more complex
      const translation = translateFromMap(this.message, locale);
      const parts = translation.split(/(\$\d+)/) as string[];
      for (const part of parts) {
        if (part.startsWith('$')) {
          const index = +part.substring(1) - 1;
          const value = this.placeholders[index];
          if (value instanceof TemplateRef) {
            const view = this.viewContainerRef.createEmbeddedView(value);
            this.renderer.appendChild(parent, view.rootNodes[0]);
          } else {
            this.renderer.appendChild(parent, this.textNodes[index] = this.renderer.createText(part));
          }
        } else {
          this.renderer.appendChild(parent, this.renderer.createText(part));
        }
      }
    } else {
      const translation = this.createFullText();
      this.renderer.appendChild(parent, this.textNodes[0] = this.renderer.createText(translation));
    }

    if (elementUse) {
      this.renderer.removeChild(parent, this.elementRef.nativeElement);
    }
  }

  private createFullText() {
    if (!this.message) return '';
    const placeholdersWithoutTemplates = this.placeholders.map((p, i) => p instanceof TemplateRef ? `${i + 1}` : p);
    return translateWithPlaceholders(this.message, placeholdersWithoutTemplates, this.placeholderDomains);
  }

  private update() {
    this.queuedUpdate = true;

    queueMicrotask(() => {
      if (this.withTemplate) {
        for (let i = 0; i < this.textNodes.length; i++) {
          if (this.textNodes[i]) {
            this.textNodes[i]!.textContent = this.placeholders[i];
          }
        }
      } else if (this.textNodes[0]) {
        this.textNodes[0].textContent = this.createFullText();
      }

      this.queuedUpdate = false;
    });
  }

  register(pipe: { placeholderIndex: number; }, value: any, domain: string | undefined) {
    if (pipe.placeholderIndex === -1) {
      this.placeholders.push(value);
      this.placeholderDomains.push(domain);
      pipe.placeholderIndex = this.placeholders.length - 1;
    } else {
      this.placeholders[pipe.placeholderIndex] = value;
      this.placeholderDomains[pipe.placeholderIndex] = domain;
      if (!this.queuedUpdate) this.update();
    }

    return `$${pipe.placeholderIndex + 1}`;
  }
}

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
  placeholderIndex = -1;

  constructor(/*private elementRef: ElementRef,*/ @Optional() private translate?: TranslateDirective) {
  }

  transform<T>(value: T, domain?: string): T | string | undefined {
    if (this.translate) return this.translate.register(this, value ?? '', domain);
    if (value instanceof TemplateRef) return '$error:Outside of translate directive';
    if (!value || typeof value !== 'string') return value;

    return translateFromMap(value, locale, domain);

    // const mapping = locale[value];

    // if (mapping) {
    //   reportMap(this.elementRef.nativeElement, value);
    //   return mapping;
    // }

    // return value;
  }
}

@Pipe({ name: 'untranslate' })
export class NotTranslatePipe implements PipeTransform {
  placeholderIndex = -1;

  constructor(@Optional() private translate?: TranslateDirective) {
  }

  transform<T>(value: T): T | string | undefined {
    if (this.translate) {
      return this.translate.register(this, typeof value === 'string' ? nonTranslatable(value) : (value ?? ''), undefined);
    }

    if (value instanceof TemplateRef) return '$error:Outside of translate directive';
    if (!value || typeof value !== 'string') return value;

    // This hidden character is used to prevent translation of the string
    // and handled in i18n `translateFromMap` function
    return nonTranslatable(value);
  }
}

@Pipe({ name: 'pluralize' })
export class PluralizePipe implements PipeTransform {
  transform(format: string, value: any, domain?: string): string {
    return pluralize(format, value, domain);
  }
}

export const PIPES = [Deg, Num, Filter, Kbd, Rev, Chat, IsEmojiOnly, ColorToCss, Percentage, PercentageOrOff, LinkEmails, LargeNumber, RelativeTime, AvatarPath, WrapUrl, DurationPipe, JoinPipe, TranslatePipe, NotTranslatePipe, PluralizePipe];
