import { PathCommand } from 'opentype.js';
import { isEqual } from 'lodash';
import { FontFamilies, FontStyleNames } from './font-family';
import { alignsToCenter, alignsToLeft, alignsToRight, CharacterFormatting, CharacterFormattingDescription, DEFAULT_CHARACTER_FORMATTING, DEFAULT_TEXTAREA_FORMATTING, formattingToFontStyle, getIndexesInRange, isEqualAppliedFormatting, isLastCharacterInRange, isValidCharacterFormatting, isValidParagraphFormatting, isValidTextareaFormatting, MIN_SAFE_LINEHEIGHT, ParagraphFormattingDescription, sanitizeFormatting, sortFormattingsToEnableRemovingFormattings, TextAlignment, TextareaFormatting, TextDecorationTracking, textRangesEqual, VerticalAlignments, DEFAULT_PARAGRAPH_FORMATTING } from './formatting';
import { assumeParagraphHaveMeasuredLines, assumeParagraphsHaveMeasuredLines, hasLinesMeasured, Paragraph, ParagraphWithMeasuredLines } from './paragraph';
import { calculateAdvancement, calculateKerning, GlyphScenario, hasMeasuredBbox, saveBbox, TextCharacter, TextCharacterCreationMetadata, TextCharacterWithMeasuredBbox, ZERO_WIDTH_JOINER } from './text-character';
import { Point, Rect, Vec2, Viewport } from '../interfaces';
import { FontStyle } from './font-style';
import { BreaklineStrategies, findLineDataWithCharacter, findLineIndexWithCharacter, isFirstCharacter, JustifiedSpacesTracker, justifyOneLine, Line, LineMeasuringService, LineStretchingIteration, PreviousWhitespaceTracker, shouldBreakline, shouldStretch } from './lines';
import { clampIndex, clamp, isOdd, max, min, sum } from '../mathUtils';
import { TEXTAREA_CONTROL_POINT_CORNER_OFFSET, TEXTAREA_CONTROL_POINT_HITBOX_PADDING, TEXTAREA_CONTROL_POINTS_WITH_CORNER_HITBOXES, TEXTAREA_CONTROL_POINTS_WITH_SIDE_HITBOXES, TextareaControlPoint, TextareaControlPointDirections } from './control-points';
import { cloneRect, copyRect, createRect, haveNonEmptyIntersection, integerizeRect, isRectEmpty, normalizeRect, outsetRect, setRect } from '../rect';
import { createPoint, pointInHalf, pointInHalfBetweenPoints } from '../point';
import { colorFromRGBA, colorToCSS, RGBA } from '../color';
import { applyTextAndParagraphIndexesToSelection, doubleIndexToCharacter, SelectionChangeSource, TextSelectionDetailed, TextSelectionDirection, TextSelectionSegments } from './navigation';
import { AUTO_SETTING_STRING, getPixelRatio } from '../utils';
import { getTransformedRectBounds, rectToBounds, transformBounds } from '../toolSurface';
import { applyTransform } from '../canvasUtils';
import { copyMat2d, createMat2d, scaleMat2d, transformVec2ByMat2d, translateMat2d } from '../mat2d';
import { cloneBounds, createVec2, createVec2FromValues, getParallelPoints, getPointInOppositeDirection, setVec2 } from '../vec2';
import { pointInsidePolygon } from '../polyUtils';

export const TEXTAREA_BOUNDARIES_COLOR: RGBA = { r: 46 / 255, g: 109 / 255, b: 225 / 255, a: 255 / 255 };
export const TEXTAREA_SELECTION_RECT_COLOR: RGBA = { r: 0x96 / 255, g: 0xb6 / 255, b: 0xf0 / 255, a: 1 };
export const TEXTAREA_CURSOR_WIDTH = 4;
export const TEXTAREA_HOVERED_BOUNDARIES_WIDTH = 2;
export const TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH = 1;

export const MIN_TEXTAREA_CURSOR_WIDTH = 2;
export const MAX_TEXTAREA_CURSOR_WIDTH = 10;

export const TEXTAREA_BASELINE_INDICATOR_ALIGNMENT_SQUARE_SIZE = 4;
export const TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS = 2;
export const TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS = 1;
const BASELINE_INDICATOR_ALIGNMENT_SQUARE_OFFSET = 8;
const BASELINE_INDICATOR_MIN_LENGTH = 32;
const BASE_TEXTURE_RECT_PADDING = 0;

export const TEXTAREA_OVERFLOW_INDICATOR_RED: RGBA = { r: 255, g: 0, b: 0, a: 255 };
export const TEXTAREA_OVERFLOW_INDICATOR_RED_STR = colorToCSS(colorFromRGBA(TEXTAREA_OVERFLOW_INDICATOR_RED.r, TEXTAREA_OVERFLOW_INDICATOR_RED.g, TEXTAREA_OVERFLOW_INDICATOR_RED.b, TEXTAREA_OVERFLOW_INDICATOR_RED.a));
export const TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE = 8;
export const TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS = 1 / 4;  // defined as fraction of square size
export const TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE = 3 / 4; // defined as fraction of square size

const FORCE_BATCH_STRIKE_EVERY_N_GLYPHS = 500;

export const PARAGRAPH_SPLIT_CHARACTER = '\n';
const REPLACE_EMOJIS_WITH = '?'; // this has to be char with 1 length

const tempMat2d = createMat2d();
const selectionRects: Rect[] = []; // use like temp matrixes
const caretRect = createRect(0, 0, 0, 0); // use like temp matrixes

export type TextareaOptions = {
  type: TextareaType;
  x: number;
  y: number;
  text: string;
  textareaFormatting: TextareaFormatting;
  paragraphFormattings: ParagraphFormattingDescription[];
  characterFormattings: CharacterFormattingDescription[];
  defaultFontFamily: string;
  w?: number;
  h?: number;
  dirty?: boolean;
  isFocused?: boolean;
}

export enum TextareaType {
  AutoWidth = 'auto-width',
  FixedWidth = 'fixed-width',
  FixedDimensions = 'fixed-dimensions'
}

export const TEXTAREA_TYPES = Object.values(TextareaType);

interface CursorPosition {
  caretRect: Rect | undefined;
  selectionRects: Rect[];
}

interface TextareaIteration {
  character: TextCharacter;
  paragraph: ParagraphWithMeasuredLines;
  line: Line;
  paragraphIndex: number;
  lineIndex: number;
}

type BaselineIndicator = {
  line: [Point, Point],
  alignmentSquare: Point | undefined
}[];

export abstract class Textarea {
  abstract readonly type: TextareaType;
  private viewportOperations = true;

  protected constructor(fonts: FontFamilies, options: TextareaOptions) {
    this.x = options.x;
    this.y = options.y;
    this._width = Math.round(options.w || 0);
    this._height = Math.round(options.h || 0);

    this.cursorPosX = this._x;
    this.cursorPosY = this._y;

    this._textareaFormatting = {
      ...DEFAULT_TEXTAREA_FORMATTING,
      ...options.textareaFormatting
    };

    this.fontFamilies = fonts;
    if (options.defaultFontFamily && this.fontFamilies.get(options.defaultFontFamily)) {
      this.defaultFontFamily = options.defaultFontFamily;
    } else {
      const [firstFont] = this.fontFamilies.values();
      this.defaultFontFamily = firstFont.name;
    }
    this.text = '';
    this.paragraphs = [];

    if (options.paragraphFormattings) this._paragraphFormattings = options.paragraphFormattings.filter(f => isValidParagraphFormatting(f));
    if (options.characterFormattings) this._characterFormattings = options.characterFormattings.filter(f => isValidCharacterFormatting(f));

    if (options.text) this.write(options.text);

    this.bounds = this.getBounds();
    if (this.viewportOperations) this.controlPoints = this.buildControlPoints();
    this.dirty = options.dirty || false;

    this.isFocused = options.isFocused ?? false;
  }

  text = '';
  dirty = false; // defines whether anything was changed about textarea after it's creation, text change, applied formatting, resized, moved etc.
  paragraphs: Paragraph[] = [];
  lines: Line[] = [];
  characters: TextCharacter[] = [];

  readonly fontFamilies: FontFamilies;
  defaultFontFamily: string;

  cursorPosX = 0; // x coordinate of writing font, advances while writing
  cursorPosY = 0; // y coordinate of writing font, advances while writing
  private _x = 0;// x coordinate of top left point of textarea on canvas
  get x(): number {
    return this._x;
  }
  set x(value: number) {
    if (Number.isFinite(value)) {
      this._x = Math.round(value);
    } else {
      this._x = 0;
    }
  }
  private _y = 0; // y coordinate of top left point of textarea on canvas
  get y(): number {
    return this._y;
  }
  set y(value: number) {
    if (Number.isFinite(value)) {
      this._y = Math.round(value);
    } else {
      this._y = 0;
    }
  }

  private _textareaFormatting: Required<TextareaFormatting>;
  get textareaFormatting(): Required<TextareaFormatting> {
    return this._textareaFormatting;
  }
  set textareaFormatting(value: Required<TextareaFormatting>) {
    this._textareaFormatting = value;
    this.write(this.text);
  }
  protected _paragraphFormattings: ParagraphFormattingDescription[] = [];
  get paragraphFormattings() { return this._paragraphFormattings; }
  set paragraphFormattings(formattings: ParagraphFormattingDescription[]) {
    this.clearTextareaStatus();
    for (const formatting of formattings) {
      this.formatParagraph(formatting, false);
    }
    this.write(this.text);
  }

  protected _characterFormattings: CharacterFormattingDescription[] = [];
  get characterFormattings() { return this._characterFormattings; }
  set characterFormattings(formattings: CharacterFormattingDescription[]) {
    this.clearTextareaStatus();
    for (const formatting of formattings) {
      this.formatCharacters(formatting, false);
    }
    this.write(this.text);
  }

  protected previousCharacter: TextCharacter | undefined = undefined;
  blinkOffset = performance.now();

  isResizing = false;
  activeControlPoint: TextareaControlPoint | undefined = undefined;
  resizedNegativelyX = false;
  resizedNegativelyY = false;
  isHovering = false;
  isMoving = false;
  isFocused = false;
  protected _width = 0;
  protected _height = 0;
  abstract get width(): number;
  abstract get height(): number;
  abstract set width(width: number);
  abstract set height(height: number);

  get topBorder() {
    return this._y;
  }
  get rightBorder() {
    return this.leftBorder + this.width;
  }
  get bottomBorder() {
    return this.topBorder + this.height;
  }
  get leftBorder() {
    return this._x;
  }

  get topLeftCorner(): Point {
    return {
      x: this.leftBorder,
      y: this.topBorder
    };
  }
  get topRightCorner(): Point {
    return {
      x: this.rightBorder,
      y: this.topBorder
    };
  }
  get bottomLeftCorner(): Point {
    return {
      x: this.leftBorder,
      y: this.bottomBorder
    };
  }
  get bottomRightCorner(): Point {
    return {
      x: this.rightBorder,
      y: this.bottomBorder
    };
  }

  get rect(): Rect {
    // this rect is used for interacting with tool itself - registers clicks, grabbing and dragging
    const rect = createRect(this.leftBorder, this.topBorder, this.width, this.height);
    integerizeRect(rect);
    return rect;
  }
  textureRectPadding = BASE_TEXTURE_RECT_PADDING;
  textureRect = createRect(0, 0, 0, 0);
  getUntransformedTextureRect() {
    const rect = cloneRect(this.rect);
    outsetRect(rect, this.textureRectPadding);
    integerizeRect(rect);
    return rect;
  }
  getTextureRect(): Rect {
    // this rect is later copied to layer and it should be big enough to cover all rendered glyphs even if they exceed blue frames
    return getTransformedRectBounds(this.getUntransformedTextureRect(), this.transform);
  }
  hasOverflowingCharacters = false;

  get transform() {
    copyMat2d(tempMat2d, this.textareaFormatting.transform);
    return tempMat2d;
  }

  // Returns px left to this.x which needs to be rendered too.
  // Returns negative number! (or zero)
  get negativeOffset() {
    return 0;
  }

  write(text: string) {
    this.textureRectPadding = BASE_TEXTURE_RECT_PADDING;
    this.loadParagraphs(text);
    this.measureParagraphs();
    this.resetCursor();
    this.saveBoundingBoxes();
    this.text = text;
    this.textureRect = this.getTextureRect();
    if (this.tryToFixFormattings) {
      DEVELOPMENT && console.log('Trying to fix formattings', this.characterFormattings);
      this.tryToFixFormattings = false;
      this.characterFormattings = this.characterFormattings;
      DEVELOPMENT && console.log('Fixed formattings', this.characterFormattings);
    }
    this.bounds = this.getBounds();
    this.syncControlPoints();
  }
  onInputManagedSelection(
    oldText: string, newText: string,
    oldSelection: TextSelectionDetailed, newSelection: TextSelectionDetailed,
    appliedFormatting: CharacterFormatting | undefined = undefined
  ) {
    this.dirty = true;
    this.blinkOffset = performance.now();
    this.verticallyNavigatingDoubleIndex = undefined;

    const selectionSegmentsOld = this.splitTextBySelection(oldText, oldSelection);
    const selectionSegmentsNew = this.splitTextBySelection(newText, newSelection);

    // if following are extracted to helper function it either needs to .slice()
    // segments again or receive redundant parameters allowing to compute data on it's own
    // instead I decided to wrap it into scopes not for garbage collector but for separating
    // processed selection segment - first afterSelection, then inSelection and lastly beforeSelection

    {
      // afterSelection segment processing
      // only real use case is delete/ctrl+delete with selection length 0 so
      // we can only check for if there is fewer characters than was previously
      const afterSelectionDiffCharacter = this.getSelectionDiffForCharacters(selectionSegmentsNew, selectionSegmentsOld, 'after');
      const afterSelectionDiffParagraphs = this.getSelectionDiffForParagraphs(selectionSegmentsNew, selectionSegmentsOld, 'after');

      if (afterSelectionDiffCharacter < 0) {
        const start = oldSelection.length === 0 ? oldSelection.start : oldSelection.start + oldSelection.length - 1;
        this.pullCharacterFormattings(start, afterSelectionDiffCharacter);
      }
      if (afterSelectionDiffParagraphs !== 0) {
        const entersInBefore = selectionSegmentsOld.beforeSelection.filter(c => c === PARAGRAPH_SPLIT_CHARACTER).length;
        const entersInIn = selectionSegmentsOld.inSelection.filter(c => c === PARAGRAPH_SPLIT_CHARACTER).length;
        this.pullParagraphFormattings(entersInBefore + entersInIn + 1, afterSelectionDiffParagraphs);
      }
    }

    {
      // inSelection segment processing
      // basically we handle this part on delete / backspace / replace with selection
      // if there was selection with one or more characters they are being always deleted/replaced
      // and we shift everything from selection start by x characters where x is amount of selected characters
      // if selection was of length 0, we do nothing
      const inSelectionDiffCharacters = this.getSelectionDiffForCharacters(selectionSegmentsNew, selectionSegmentsOld, 'in');
      const inSelectionDiffParagraphs = this.getSelectionDiffForParagraphs(selectionSegmentsNew, selectionSegmentsOld, 'in');
      if (inSelectionDiffCharacters < 0) {
        this.pullCharacterFormattings(oldSelection.start, oldSelection.length);
      }
      if (inSelectionDiffParagraphs < 0) {
        this.pullParagraphFormattings(
          selectionSegmentsOld.beforeSelection.filter(c => c === PARAGRAPH_SPLIT_CHARACTER).length,
          inSelectionDiffParagraphs
        );
      }
    }

    let formattingToApplyAfterwards = undefined;
    {
      // beforeSelection segment processing
      const beforeSelectionCharacterDiff = this.getSelectionDiffForCharacters(selectionSegmentsNew, selectionSegmentsOld, 'before');
      const beforeSelectionParagraphDiff = this.getSelectionDiffForParagraphs(selectionSegmentsNew, selectionSegmentsOld, 'before');

      if (beforeSelectionCharacterDiff !== 0) {
        if (beforeSelectionCharacterDiff > 0) {
          // added characters (to the end of this string) by typing or pasting multiple
          this.pushCharacterFormattings(oldSelection.start, beforeSelectionCharacterDiff);
        }
        if (beforeSelectionCharacterDiff < 0) {
          // selection was 0 and characters were deleted with either backspace or ctrl+backspace
          const firstDeletedCharIndex = Math.max(oldSelection.start + beforeSelectionCharacterDiff, 0);
          if (!selectionSegmentsNew.beforeSelection.length) {
            this.formattingToApplyOnNextInput = {
              ...this.characters[0].formatting,
              start: 0, length: 1 // length will be overwrote anyway when entered new text and its length is known
            } as CharacterFormattingDescription;
          }
          this.pullCharacterFormattings(firstDeletedCharIndex, beforeSelectionCharacterDiff);
        }
      }
      if (beforeSelectionParagraphDiff !== 0) {
        if (beforeSelectionParagraphDiff > 0) {
          // added EOL (or EOLs with paste) to the end of beforeSelection string
          this.pushParagraphFormattings(
            selectionSegmentsOld.beforeSelection.filter(c => c === PARAGRAPH_SPLIT_CHARACTER).length,
            beforeSelectionParagraphDiff
          );
        }
        if (beforeSelectionParagraphDiff < 0) {
          // selection was 0 and deleted EOL with backspace
          this.pullParagraphFormattings(
            selectionSegmentsOld.beforeSelection.filter(c => c === PARAGRAPH_SPLIT_CHARACTER).length,
            beforeSelectionParagraphDiff
          );
        }
      }

      if (this.text !== '' && newText === '') {
        this.formattingToApplyOnNextInput = { ...this.characters[0].formatting, start: 0, length: 0 } as CharacterFormattingDescription; // length will be overwrote anyway when entered new text and its length is known};
      } else if (this.text === '' && newText !== '' && this.formattingToApplyOnNextInput) {
        formattingToApplyAfterwards = {
          ...this.formattingToApplyOnNextInput,
          ...appliedFormatting,
          length: newText.length
        };
        this.formattingToApplyOnNextInput = undefined;
      } else if (appliedFormatting) {
        const formattingStart = Math.max(0, oldSelection.start);
        if (beforeSelectionCharacterDiff) {
          formattingToApplyAfterwards = { ...appliedFormatting, start: formattingStart, length: beforeSelectionCharacterDiff } as CharacterFormattingDescription;
        } else if (formattingStart === 0) {
          this.formattingToApplyOnNextInput = { ...appliedFormatting, start: formattingStart, length: 0 }; // length will be overwrote anyway and when entered new text and length is known
        }
      }
    }

    this.lastSelectionChangeSource = 'typing';
    this.write(newText);
    if (formattingToApplyAfterwards) this.formatCharacters(formattingToApplyAfterwards);
  }
  formattingToApplyOnNextInput: CharacterFormattingDescription | undefined = undefined;
  private splitTextBySelection(text: string, selection: TextSelectionDetailed) {
    const newCharacters = Array.from(text);

    const beforeSelection = newCharacters.slice(0, selection.start);
    const inSelection = newCharacters.slice(selection.start, selection.end);
    const afterSelection = newCharacters.slice(selection.end);

    return { beforeSelection, inSelection, afterSelection };
  }
  private getSelectionDiffForCharacters(newSelectionSegments: TextSelectionSegments, oldSelectionSegments: TextSelectionSegments, key: 'before' | 'in' | 'after') {
    const newCharacters = newSelectionSegments[`${key}Selection`];
    const oldCharacters = oldSelectionSegments[`${key}Selection`];
    return newCharacters.length - oldCharacters.length;
  }
  private getSelectionDiffForParagraphs(newSelectionSegments: TextSelectionSegments, oldSelectionSegments: TextSelectionSegments, key: 'before' | 'in' | 'after') {
    const newEOLs = newSelectionSegments[`${key}Selection`].filter(c => c === PARAGRAPH_SPLIT_CHARACTER);
    const oldEOLs = oldSelectionSegments[`${key}Selection`].filter(c => c === PARAGRAPH_SPLIT_CHARACTER);
    return newEOLs.length - oldEOLs.length;
  }
  private pushCharacterFormattings(start: number, length: number): void {
    let insertPositionFound = false;
    for (let existingFormatting of this._characterFormattings) {
      if (existingFormatting.start >= start) insertPositionFound = true;
      if (getIndexesInRange(existingFormatting).includes(start - 1)) {
        existingFormatting.length += length;
      } else if (insertPositionFound) {
        existingFormatting.start += length;
      } else {
      }
    }
  }
  private pullCharacterFormattings(start: number, length: number): void {
    const newFormattings: CharacterFormattingDescription[] = [];
    const pullLength = Math.abs(length);

    //  [a        ]      [b   <     ]         [c          ]         [d     >   ]  [e           ]
    // a - is #fullBefore     - do nothing
    // b - is #startsIn       - shorten length by overlapping indexes length
    // c - is #fullOverlap    - delete/remove
    // d - is #endsIn         - shorten by overlapping indexes length, pull by indexes from (d.start - removal.start)
    // e - is #fullAfter      - pull by removal.length

    const removalStart = start;
    const removalEnd = start + pullLength - 1;
    const removalIndexes = getIndexesInRange({ start, length: pullLength });

    for (let existingFormatting of this._characterFormattings) {
      const formattingStart = existingFormatting.start;
      const formattingEnd = existingFormatting.start + existingFormatting.length - 1;
      const formattingIndexes = getIndexesInRange(existingFormatting);

      const removalStartsBeforeFormatting = removalStart < formattingStart;
      const removalStartsInFormatting = formattingIndexes.includes(removalStart);
      const removalStartsAfterFormatting = removalStart > formattingEnd;
      const removalEndsInFormatting = formattingIndexes.includes(removalEnd);
      const removalEndsAfter = removalEnd > formattingEnd;

      if (removalStartsBeforeFormatting && removalEndsAfter) {
        // don't add it since we want to remove it
      } else if (removalEndsInFormatting) {
        const overlappingIndexesCount = removalIndexes.filter(rI => formattingIndexes.includes(rI)).length;
        newFormattings.push({
          ...existingFormatting,
          length: existingFormatting.length - overlappingIndexesCount,
          start: formattingStart - (pullLength - overlappingIndexesCount)
        });
      } else if (removalStartsInFormatting) {
        // shorten length by overlapping indexes length
        const overlappingIndexesCount = removalIndexes.filter(rI => formattingIndexes.includes(rI)).length;
        newFormattings.push({
          ...existingFormatting,
          length: existingFormatting.length - overlappingIndexesCount
        });
      } else if (removalStartsAfterFormatting) {
        newFormattings.push(existingFormatting);
      } else {
        newFormattings.push({
          ...existingFormatting,
          start: formattingStart - pullLength
        });
      }
    }

    this._characterFormattings = newFormattings.filter(f => isValidCharacterFormatting(f));
  }
  private pushParagraphFormattings(start: number, length: number): void {
    // start: 4 - adding paragraph in/after paragraph 4
    // length: 2 - adding 2 paragraphs by cloning previous one
    // l, l, c, c, r, r, j, j -> l, l, c, c, r, R, R, r, j, j
    const newParagraphFormattings = [];
    const pushLength = Math.abs(length);
    for (let pF of this._paragraphFormattings.sort((a, b) => a.index - b.index)) {
      if (pF.index < start) {
        newParagraphFormattings.push(pF);
      } else if (pF.index === start) {
        newParagraphFormattings.push(pF);
        for (let i = 1; i <= pushLength; i++) {
          newParagraphFormattings.push({ ...pF, index: pF.index + i, });
        }
      } else if (pF.index > start) {
        newParagraphFormattings.push({ ...pF, index: pF.index + pushLength });
      }
    }
    this._paragraphFormattings = newParagraphFormattings.filter(pF => isValidParagraphFormatting(pF));
  }
  private pullParagraphFormattings(start: number, length: number): void {
    // start: 4 - removing 4th paragraph
    // length: 2 - removing 4th and 1 next paragraphs (2 total)
    // l, l, c, c, |r, r,| j, j -> l, l, c, c,|| j, j
    const pullLength = Math.abs(length);

    const paragraphIndexesToRemove = getIndexesInRange({ start, length: pullLength });
    const biggestRemovedParagraphIndex = max(paragraphIndexesToRemove);

    const newParagraphFormattings = [];
    for (let pF of this._paragraphFormattings.sort((a, b) => a.index - b.index)) {
      if (paragraphIndexesToRemove.includes(pF.index)) {
        // don't add it since we want to remove it
      } else if (pF.index > biggestRemovedParagraphIndex) {
        newParagraphFormattings.push({ ...pF, index: pF.index - paragraphIndexesToRemove.length });
      } else {
        newParagraphFormattings.push(pF);
      }
    }

    this._paragraphFormattings = newParagraphFormattings.filter(pF => isValidParagraphFormatting(pF));
  }

  // Executes callback when iterating over characters and paragraphs in already measured positions
  // allowing to access values like cursorPosX/Y, previousCharacter on the flight
  protected iterateOverEachCharacterInPosition(andDoWhat: (iteration: TextareaIteration) => void): void {
    if (this.lines.length > 0) {
      assumeParagraphsHaveMeasuredLines(this.paragraphs);
      this.nextLine(this.lines[0]); // step into textarea from top-right corner and set correct alignment

      let lineIndex = 0;
      let line = this.lines[lineIndex];

      this.paragraphs.forEach((paragraph, paragraphIndex) => {
        paragraph.characters.forEach((character) => {
          andDoWhat({ character, paragraph, line, paragraphIndex, lineIndex });
          // const advancement = calculateAdvancement(character, this.previousCharacter);
          // this.cursorPosX += advancement;
          this.previousCharacter = character;
          if (shouldBreakline(character, line)) {
            lineIndex++;
            line = this.lines[lineIndex];
            if (line) this.nextLine(line);
          }
        });
      });
    } else {
      DEVELOPMENT && console.warn('Trying to iterate iterateOverEachCharacterInPosition but textarea has not measured lines yet!');
    }
    this.resetCursor();
  }

  resetCursor() {
    this.cursorPosX = this._x;
    this.cursorPosY = this._y;
    this.previousCharacter = undefined;
  }

  protected lineheight = 0;
  abstract saveBoundingBoxes(): void;
  protected abstract saveCharactersBboxesFlags(paragraph: ParagraphWithMeasuredLines, character: TextCharacterWithMeasuredBbox): void;
  protected extendTextureRect(bbox: Rect) {
    const { x, y, w, h } = this.rect;

    const lDiff = x - bbox.x;
    const tDiff = y - bbox.y;
    const rDiff = bbox.x + bbox.w - (x + w);
    const bDiff = bbox.y + bbox.h - (y + h);

    const mostExceedingValue = Math.max(lDiff, tDiff, rDiff, bDiff);

    if (mostExceedingValue > 0 && mostExceedingValue > this.textureRectPadding) {
      this.textureRectPadding = Math.ceil(mostExceedingValue);
    }
  }

  drawOn(ctx: CanvasRenderingContext2D) {
    ctx.save();
    applyTransform(ctx, this.transform);
    this.iterateOverEachCharacterInPosition(({ character, paragraph }) => {
      this.renderGlyph(ctx, character, paragraph);
    });
    ctx.restore();
  }

  abstract get frames(): Rect; // this rect is used to draw blue box boundaries (and indirectly consequently control points)
  bounds: Vec2[] = [createVec2FromValues(0, 0), createVec2FromValues(0, 0), createVec2FromValues(0, 0), createVec2FromValues(0, 0)];
  getBounds() {
    for (let i = 0; i < this.bounds.length; i++) {
      setVec2(this.bounds[i], 0, 0);
    }
    rectToBounds(this.bounds, this.frames);
    transformBounds(this.bounds, this.transform);
    return this.bounds;
  }
  get isUsingThickBorders(): boolean {
    const { isHovering, isResizing, isFocused } = this;
    return isHovering || isResizing || isFocused;
  }
  get boundariesStrokeWidth(): number {
    return this.isUsingThickBorders ? TEXTAREA_HOVERED_BOUNDARIES_WIDTH : TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH;
  }
  get boundariesStrokeColor(): RGBA {
    return TEXTAREA_BOUNDARIES_COLOR;
  }

  pointInFrame(point: Point) { // expects untransformed point
    const { x, y } = point;
    return pointInsidePolygon(x, y, this.bounds);
  }
  getBaselineIndicator(): BaselineIndicator {
    const result: BaselineIndicator = [];

    for (let paragraphI = 0; paragraphI < this.paragraphs.length; paragraphI++) {
      const paragraph = this.paragraphs[paragraphI];
      if (hasLinesMeasured(paragraph)) {
        for (let lineI = 0; lineI < paragraph.lines.length; lineI++) {
          const line = paragraph.lines[lineI];
          if ((paragraph.isEmpty && paragraph.insideVisibleBox) || this.characters.slice(line.startAt, line.breaklineAt).some(c => c.partiallyInsideVisibleBox)) {
            const x = line.bbox.x;
            const y = line.baseline;
            if (y >= this.topBorder && y <= this.bottomBorder) {
              const length = clamp(line.bbox.w, BASELINE_INDICATOR_MIN_LENGTH, this.width); // <-- empty lines min-capped at BASELINE_INDICATOR_MIN_LENGTH
              let wDiff = 0;

              const alignment = paragraph.formatting.alignment;

              let alignmentSquareX = x + BASELINE_INDICATOR_ALIGNMENT_SQUARE_OFFSET;
              if (alignment === TextAlignment.CenterAligned || alignment === TextAlignment.CenterJustified) {
                alignmentSquareX = x + length / 2 - TEXTAREA_BASELINE_INDICATOR_ALIGNMENT_SQUARE_SIZE / 2;
                wDiff = (length - line.bbox.w) / 2;
              }
              if (alignment === TextAlignment.RightAligned || alignment === TextAlignment.RightJustified) {
                alignmentSquareX = x + length - TEXTAREA_BASELINE_INDICATOR_ALIGNMENT_SQUARE_SIZE - BASELINE_INDICATOR_ALIGNMENT_SQUARE_OFFSET;
                wDiff = length - line.bbox.w;
              }

              const firstPointCoordinates = [x - wDiff, y];
              const secondPointCoordinates = [x - wDiff + length, y];
              transformVec2ByMat2d(firstPointCoordinates, firstPointCoordinates, this.transform);
              transformVec2ByMat2d(secondPointCoordinates, secondPointCoordinates, this.transform);
              const baselineLine: [Point, Point] = [
                createPoint(firstPointCoordinates[0], firstPointCoordinates[1]),
                createPoint(secondPointCoordinates[0], secondPointCoordinates[1])
              ];
              if (lineI === 0) {
                const alignmentSquareCoordinates = [alignmentSquareX - wDiff, y];
                transformVec2ByMat2d(alignmentSquareCoordinates, alignmentSquareCoordinates, this.transform);
                const alignmentSquare = createPoint(alignmentSquareCoordinates[0], alignmentSquareCoordinates[1]);
                result.push({ line: baselineLine, alignmentSquare });
              } else {
                result.push({ line: baselineLine, alignmentSquare: undefined });
              }
            }
          }
        }
      }
    }

    return result;
  }

  getOverflowIndicator(): Point {
    const [, a, b] = Array.from(this.bounds);
    const distance = Math.sqrt((b[0] - a[0]) ** 2 + (b[1] - a[1]) ** 2);
    const cDistance = distance / 4;
    const point = getPointInOppositeDirection(b, a, -cDistance);
    return createPoint(point[0], point[1]);
  }

  controlPoints: TextareaControlPoint[] = [];
  get renderBoundariesOnHover() { return false; }
  get renderControlPointsOnHover() { return false; }
  syncControlPoints() {
    const shadowControlPoints = this.buildControlPoints();
    for (let cp of this.controlPoints) {
      const shadowControlPoint = shadowControlPoints.find(scp => scp.direction === cp.direction);
      if (shadowControlPoint) {
        cp.x = shadowControlPoint.x;
        cp.y = shadowControlPoint.y;
      } else {
        DEVELOPMENT && console.warn(`Tried to sync "${cp.direction}" control point but it's corresponding shadow control point was not found`);
      }
    }
  }
  flipTextareaWithControlPoint(xAxis: boolean, yAxis: boolean, origin?: Point) {
    const transform = createMat2d();
    copyMat2d(transform, this.transform);

    const originX = origin?.x ?? this.leftBorder;
    const originY = origin?.y ?? this.topBorder;

    translateMat2d(transform, transform, originX, originY);

    scaleMat2d(transform, transform, xAxis ? -1 : 1, yAxis ? -1 : 1);

    translateMat2d(transform, transform, -originX, -originY);

    copyMat2d(this.textareaFormatting.transform, transform);

    if (xAxis) this.resizedNegativelyX = !this.resizedNegativelyX;
    if (yAxis) this.resizedNegativelyY = !this.resizedNegativelyY;

    this.write(this.text);
  }
  protected abstract buildControlPoints(): TextareaControlPoint[];
  pointInControlPoint(point: Point, view: Viewport): TextareaControlPoint | undefined {
    const bounds = cloneBounds(this.bounds);
    let hitbox = [createVec2(), createVec2(), createVec2(), createVec2()];

    for (let i = 0; i < 4; i++) {
      const cornerControlPoint = this.findControlPointWithCornerHitbox(bounds, hitbox, point, view, i);
      if (cornerControlPoint) return cornerControlPoint;

      const sideControlPoint = this.findControlPointWithSideHitbox(bounds, hitbox, point, view, i);
      if (sideControlPoint) return sideControlPoint;
    }

    return undefined;
  }
  private findControlPointWithCornerHitbox(corners: Vec2[], hitbox: Vec2[], { x, y }: Point, { scale }: Viewport, i: number): TextareaControlPoint | undefined {
    const previousCorner = corners[(i + 3) % 4];
    const currentCorner = corners[i];
    const furtherPoint = getPointInOppositeDirection(currentCorner, previousCorner, TEXTAREA_CONTROL_POINT_HITBOX_PADDING / scale);
    const parallelPoints = getParallelPoints(currentCorner, furtherPoint, -TEXTAREA_CONTROL_POINT_HITBOX_PADDING / scale, this.transform);
    hitbox = [currentCorner, parallelPoints[0], parallelPoints[1], furtherPoint];
    if (pointInsidePolygon(x, y, hitbox)) {
      return this.controlPoints.find(cp => cp.direction === TEXTAREA_CONTROL_POINTS_WITH_CORNER_HITBOXES[i]);
    } else {
      return undefined;
    }
  }
  private findControlPointWithSideHitbox(corners: Vec2[], hitbox: Vec2[], { x, y }: Point, { scale }: Viewport, i: number): TextareaControlPoint | undefined {
    const currentCorner = createVec2FromValues(corners[i][0], corners[i][1]);
    const nextCorner = createVec2FromValues(corners[(i + 1) % 4][0], corners[(i + 1) % 4][1]);
    switch (i) {
      case 0: currentCorner[0] += TEXTAREA_CONTROL_POINT_CORNER_OFFSET; nextCorner[0] -= TEXTAREA_CONTROL_POINT_CORNER_OFFSET; break;
      case 1: currentCorner[1] += TEXTAREA_CONTROL_POINT_CORNER_OFFSET; nextCorner[1] -= TEXTAREA_CONTROL_POINT_CORNER_OFFSET; break;
      case 2: currentCorner[0] -= TEXTAREA_CONTROL_POINT_CORNER_OFFSET; nextCorner[0] += TEXTAREA_CONTROL_POINT_CORNER_OFFSET; break;
      case 3: currentCorner[1] -= TEXTAREA_CONTROL_POINT_CORNER_OFFSET; nextCorner[1] += TEXTAREA_CONTROL_POINT_CORNER_OFFSET; break;
    }
    const parallelPoints = getParallelPoints(currentCorner, nextCorner, -TEXTAREA_CONTROL_POINT_HITBOX_PADDING / scale, this.transform);
    hitbox = [currentCorner, parallelPoints[0], parallelPoints[1], nextCorner];
    if (pointInsidePolygon(x, y, hitbox)) {
      return this.controlPoints.find(cp => cp.direction === TEXTAREA_CONTROL_POINTS_WITH_SIDE_HITBOXES[i]);
    } else {
      return undefined;
    }
  }

  previousSelection: TextSelectionDetailed | undefined;
  viableForSelectionVisually(character: TextCharacter | undefined): boolean {
    if (!character) return false;
    if (!this.lines[0]) return false;
    if (character.isZeroWidthSpace) {
      return !!(character.bbox && character.bbox.x >= this.leftBorder && character.bbox.x <= this.rightBorder);
    } else if (character.isWhitespace) {
      return !!(character.bbox && character.renderable && haveNonEmptyIntersection(this.rect, character.bbox));
    } else {
      return !!(character.bbox && (character.renderable || character.index >= 0 && character.index <= this.lines[0].breaklineAt) && character.partiallyInsideVisibleBox);
    }
  }

  characterBreaksLine(character: TextCharacter) {
    for (const line of this.lines) {
      if (shouldBreakline(character, line)) return true;
    }
    return false;
  }
  characterStartsLine(character: TextCharacter) {
    for (const line of this.lines) {
      if (isFirstCharacter(character, line)) return true;
    }
    return false;
  }

  private _caretAtEOL = false;
  set caretAtEOL(value: boolean) {
    this._caretAtEOL = value;
  }
  get caretAtEOL() { return this._caretAtEOL; }
  private _lastSelectionChangeSource: SelectionChangeSource = 'typing';
  set lastSelectionChangeSource(source: SelectionChangeSource) {
    this._lastSelectionChangeSource = source;
    if (this._lastSelectionChangeSource !== 'arrows-v') {
      this.verticallyNavigatingDoubleIndex = undefined;
    }
  }
  get lastSelectionChangeSource() { return this._lastSelectionChangeSource; }
  determineIfCaretAtEOL(selectionEndIndex: number, mouseSelectionEndDoubleIndex: number) {
    const endChar = this.characters[selectionEndIndex];
    if (!endChar) {
      this.caretAtEOL = false;
    } else if (!endChar.isWhitespace) {
      if (this.characterBreaksLine(endChar)) {
        if (mouseSelectionEndDoubleIndex < endChar.index * 2) {
          // right half of glyph previous to endChar
          this.caretAtEOL = false;
        } else if (mouseSelectionEndDoubleIndex === endChar.index * 2) {
          // left half of endChar
          this.caretAtEOL = false;
        } else if (mouseSelectionEndDoubleIndex === endChar.index * 2 + 1) {
          // right half of endChar
          this.caretAtEOL = true;
        } else if (mouseSelectionEndDoubleIndex > endChar.index * 2 + 1) {
          // first glyph of next line
          this.caretAtEOL = false;
        }
      } else if (this.characterStartsLine(endChar)) {
        this.caretAtEOL = mouseSelectionEndDoubleIndex < endChar.index * 2;
      } else {
        this.caretAtEOL = false;
      }
    } else {
      this.caretAtEOL = this.characterBreaksLine(endChar) && isOdd(mouseSelectionEndDoubleIndex);
    }
  }
  protected getNewCursorPosition(selection: TextSelectionDetailed | undefined) {
    selectionRects.length = 0;
    setRect(caretRect, 0, 0, 0, 0);

    if (!selection) return;

    if (this.text === '') {
      const [firstLine] = this.lines;
      const x = firstLine?.bbox?.x ?? this.cursorPosX;
      const y = firstLine?.bbox?.y ?? this.cursorPosY;
      const h = firstLine?.bbox?.h ?? this.defaultCharacterLineheight;
      setRect(caretRect, x, y, TEXTAREA_CURSOR_WIDTH, h);
      return;
    }

    const start = clamp(selection.start, 0, this.characters.length);
    const end = clamp(selection.end, 0, this.characters.length);
    const length = end - start;

    if (length) {
      for (let i = start; i < end; i++) {
        const char = this.characters[i];
        if (this.viableForSelectionVisually(char)) {
          selectionRects.push(char.bbox!);
        }
      }
      return;
    }

    let characterAfterCaret: TextCharacter | undefined = this.characters[start];
    let characterAfterCaretViableForSelection = this.viableForSelectionVisually(characterAfterCaret);
    let characterBeforeCaret: TextCharacter | undefined = this.characters[clampIndex(start - 1, this.characters)];
    let characterBeforeCaretViableForSelection = this.viableForSelectionVisually(characterBeforeCaret);

    if (characterAfterCaret && characterAfterCaret.bbox && characterAfterCaretViableForSelection && characterBeforeCaretViableForSelection) {
      const line = this.findLineDataFromCharacter(characterAfterCaret);
      const { x, y, w, h } = characterAfterCaret.bbox;
      setRect(caretRect, x - TEXTAREA_CURSOR_WIDTH / 2, y, TEXTAREA_CURSOR_WIDTH, h);

      if (characterAfterCaret.isWhitespace) {
        const paragraph = this.findParagraphFromCharacter(characterAfterCaret);
        if (paragraph.isEmpty && hasLinesMeasured(paragraph)) {
          let side = x;
          const { bbox } = paragraph.lines[0];
          if (alignsToLeft(paragraph.formatting.alignment)) side = bbox.x;
          if (alignsToCenter(paragraph.formatting.alignment)) side = bbox.x + bbox.w / 2;
          if (alignsToRight(paragraph.formatting.alignment)) side = bbox.x + bbox.w;
          setRect(caretRect, side, line.bbox.y, TEXTAREA_CURSOR_WIDTH, line.bbox.h);
        } else if (line.alignment === TextAlignment.FullyJustified && shouldBreakline(characterAfterCaret, line)) {
          setRect(caretRect, this.rightBorder, line.bbox.y, TEXTAREA_CURSOR_WIDTH, line.bbox.h);
        }
      } else {
        if (shouldBreakline(characterAfterCaret, line) && this.caretAtEOL) {
          caretRect.x += w;
        } else if (characterBeforeCaret !== characterAfterCaret && this.characterStartsLine(characterAfterCaret) && !characterBeforeCaret.isWhitespace && this.caretAtEOL && characterBeforeCaret.bbox) {
          if (line.alignment !== TextAlignment.FullyJustified) {
            copyRect(caretRect, characterBeforeCaret.bbox);
            caretRect.x += characterBeforeCaret.bbox.w;
          } else {
            const thisLineIndex = this.lines.indexOf(line);
            const previousLine = this.lines[clampIndex(thisLineIndex - 1, this.lines)];
            setRect(caretRect, this.rightBorder, previousLine.bbox.y, TEXTAREA_CURSOR_WIDTH, line.bbox.h);
          }
        }
      }
    } else if (characterBeforeCaret && characterBeforeCaret.bbox && characterBeforeCaretViableForSelection) {
      const { x, y, w, h } = characterBeforeCaret.bbox;
      setRect(caretRect, x + w, y, TEXTAREA_CURSOR_WIDTH, h);
    }

    if (caretRect && characterAfterCaretViableForSelection && characterBeforeCaretViableForSelection) {
      this.trimCusrorRectToBox(caretRect, characterAfterCaret);
    }

    const r = this.rect;
    if (caretRect
      && !haveNonEmptyIntersection(caretRect, r)
      && (caretRect.x > r.x + r.w || caretRect.x + caretRect.w < r.x || (characterAfterCaret && !characterAfterCaret.renderable && characterBeforeCaret && !characterBeforeCaret.renderable))
    ) {
      setRect(caretRect, 0, 0, 0, 0);
    }

    return;
  }
  private trimCusrorRectToBox(cursorRect: Rect, usedCharacter: TextCharacter | undefined) {
    if (usedCharacter && usedCharacter?.renderable && cursorRect.x > this.rightBorder) cursorRect.x -= cursorRect.x - this.rightBorder;
    if (usedCharacter && usedCharacter?.renderable && cursorRect.x + cursorRect.w < this.leftBorder) cursorRect.x += this.leftBorder - (cursorRect.x + cursorRect.w);
  }
  private updateSelection(newSelection: TextSelectionDetailed | undefined): boolean {
    let updated = false;

    const noPreviousSelection = !this.previousSelection;
    const startDiff = this.previousSelection && newSelection && newSelection.start !== this.previousSelection.start;
    const endDiff = this.previousSelection && newSelection && newSelection.end !== this.previousSelection.end;

    if (noPreviousSelection || startDiff || endDiff) {
      this.previousSelection = newSelection;
      updated = true;
    }

    return updated;
  }
  getCursorPosition(selection: TextSelectionDetailed | undefined): CursorPosition {
    if (!this.viewportOperations) return { selectionRects: [], caretRect: undefined };

    const updatedSelection = this.updateSelection(selection);
    if (updatedSelection) {
      this.getNewCursorPosition(selection);
      this.blinkOffset = performance.now();
    }

    const areSecondsEven = (((performance.now() - this.blinkOffset) / 1000) | 0) % 2;
    const cursorPosition = { caretRect, selectionRects };

    if (cursorPosition) {
      const hideCursorRect = !((updatedSelection || !areSecondsEven) && (cursorPosition.caretRect && !isRectEmpty(cursorPosition.caretRect)));
      const hideSelectionRects = this.isResizing;

      return {
        caretRect: hideCursorRect ? undefined : cursorPosition.caretRect,
        selectionRects: hideSelectionRects ? [] : cursorPosition.selectionRects,
      };
    } else {
      return { selectionRects: [], caretRect: undefined };
    }
  }

  private clampFormattingClampableProperties(formatting: CharacterFormattingDescription) {
    formatting.start = Math.max(formatting.start, 0);
    formatting.length = Math.max(formatting.length, 0);
  }
  private fontFamilyAvailableForFormatting(formatting: CharacterFormattingDescription) {
    if (formatting.fontFamily === undefined) return true; // no font family formatting applied - accept formatting
    const ff = this.fontFamilies.get(formatting.fontFamily);
    return !!ff && ff.styles.size > 0;
  }
  formatCharacters(newFormatting: CharacterFormattingDescription, runWrite = true) {
    this.clampFormattingClampableProperties(newFormatting);
    if (!isValidCharacterFormatting(newFormatting)) {
      DEVELOPMENT && !TESTS && console.warn('Invalid character formatting', newFormatting);
      return;
    }
    if (!this.fontFamilyAvailableForFormatting(newFormatting)) {
      DEVELOPMENT && !TESTS && console.warn('Invalid character formatting (font family unavailable)', newFormatting);
      return;
    }

    const formattedIndexes: { index: number; formatting: CharacterFormattingDescription }[] = [];
    let afterMerges: CharacterFormattingDescription[] = [];

    this._characterFormattings.push(newFormatting);
    const formattingRanges = this._characterFormattings.map((f) => getIndexesInRange(f)); // ex: [ [2, 3, 4], [7, 8, 9, 10, 11], [9, 10] ];
    const flatFormattingRanges = formattingRanges.flat();
    const minFormattedIndex = min(flatFormattingRanges);
    const maxFormattedIndex = max(flatFormattingRanges);
    for (let i = minFormattedIndex; i <= maxFormattedIndex; i++) {
      const matchingFormattingsToThisOneIndex: CharacterFormattingDescription[] = [];
      formattingRanges.forEach((formattingRange, formattingRangeIndex) => {
        if (formattingRange.includes(i)) {
          matchingFormattingsToThisOneIndex.push(this._characterFormattings[formattingRangeIndex]);
        }
      });
      formattedIndexes.push({
        index: i,
        formatting: Object.assign({}, ...matchingFormattingsToThisOneIndex.sort(sortFormattingsToEnableRemovingFormattings))
      });
    }

    for (let i = 0; i < formattedIndexes.length;) {
      const formattedIndex = formattedIndexes[i];
      let length = 1;
      let formattingIsTheSame = true;
      const thisFormatting = sanitizeFormatting(formattedIndex.formatting);
      while (formattingIsTheSame) {
        const nextIndex = formattedIndexes[i + length];
        if (nextIndex && isEqualAppliedFormatting(sanitizeFormatting(nextIndex.formatting), thisFormatting)) length++;
        else formattingIsTheSame = false;
      }
      afterMerges.push({ ...thisFormatting, start: formattedIndex.index, length });
      i += length;
    }

    afterMerges = afterMerges
      .filter(afterMergedFormatting => isValidCharacterFormatting(afterMergedFormatting))
      .sort((a, b) => a.start - b.start);

    let extendedSome: boolean;
    do {
      extendedSome = false;
      const joinedFormattingsToRemove = [];
      for (let i = 0; i < afterMerges.length; i++) {
        const thisOne = afterMerges[i];
        const nextOne = afterMerges[i + 1];
        if (nextOne) {
          const { start: s_1, length: l_1, ...thisOneFormattingValue } = thisOne;
          const { start: s_2, length: l_2, ...nextOneFormattingValue } = nextOne;
          const equality = isEqual(thisOneFormattingValue, nextOneFormattingValue);
          if (thisOne.start + thisOne.length === nextOne.start && equality) {
            thisOne.length += nextOne.length;
            joinedFormattingsToRemove.push(i + 1);
            extendedSome = true;
            i++;
          }
        }
      }
      for (const index of joinedFormattingsToRemove) {
        afterMerges.splice(index, 1);
      }
    } while (extendedSome);

    this._characterFormattings = afterMerges;
    this.clearTextareaStatus();
    if (runWrite) this.write(this.text);
  }

  formatParagraph(formatting: ParagraphFormattingDescription, runWrite = true) {
    if (!isValidParagraphFormatting(formatting)) return;

    const alreadyExistingFormattingIndex = this._paragraphFormattings.findIndex((pF) => pF.index === formatting.index);
    if (alreadyExistingFormattingIndex >= 0) {
      this._paragraphFormattings[alreadyExistingFormattingIndex] = formatting;
    } else {
      this._paragraphFormattings.push(formatting);
    }
    this.clearTextareaStatus();
    if (runWrite) this.write(this.text);
  }

  formatTextarea(formatting: TextareaFormatting, runWrite = true) {
    if (!isValidTextareaFormatting(formatting)) return;
    this._textareaFormatting = { ...this.textareaFormatting, ...formatting };
    if (runWrite) this.write(this.text);
  }

  abstract nextLine(nextLine: Line): void;
  protected abstract nextLineManually(alignment: TextAlignment, width: number, height: number): void;

  protected abstract renderGlyph(ctx: CanvasRenderingContext2D, character: TextCharacter, paragraph: ParagraphWithMeasuredLines): void;

  get regularFontStyleOfSelectedFontFamily() {
    const fontFamily = this.fontFamilies.get(this.defaultFontFamily);
    if (!fontFamily) throw new Error(`There is no font family with name '${this.defaultFontFamily}'`);
    const fontStyle = fontFamily.styles.get(FontStyleNames.Regular);
    if (!fontStyle) throw new Error(`There is no regular font style with in '${this.defaultFontFamily}' font family.`);
    return fontStyle;
  }

  private _previousCharEndedSentence = true;
  private get endOfParagraphCharacterCreationMetadata(): TextCharacterCreationMetadata {
    return {
      isFirstLetterOfWord: false,
      isFirstLetterOfSentence: false,
    };
  }
  protected loadParagraphs(text: string) {
    this.clearTextareaStatus();

    let absoluteCharIndex = -1;
    let previousChar: TextCharacter | undefined;
    let fontStyle: FontStyle = this.regularFontStyleOfSelectedFontFamily;
    let formatting: CharacterFormatting = (this.getFormatting(0) || {}) as CharacterFormatting;
    if (text === '' && this.formattingToApplyOnNextInput) {
      formatting = this.formattingToApplyOnNextInput as CharacterFormatting;
    }
    let creationMetadata: TextCharacterCreationMetadata = {
      isFirstLetterOfWord: !previousChar || previousChar.isWhitespace,
      isFirstLetterOfSentence: this._previousCharEndedSentence && (!previousChar || previousChar.isWhitespace),
    };

    this.paragraphs = text.split(PARAGRAPH_SPLIT_CHARACTER)
      .map((textParagraph, paragraphIndex) => {
        const glyphKeys = Array.from(textParagraph);

        const characters = glyphKeys.map((glyphKey) => {
          absoluteCharIndex++;

          formatting = (this.getFormatting(absoluteCharIndex) || {}) as CharacterFormatting;
          fontStyle = this.getFontStyle(formatting);

          creationMetadata.isFirstLetterOfWord = !previousChar || previousChar.isWhitespace;
          creationMetadata.isFirstLetterOfSentence = this._previousCharEndedSentence && (!previousChar || previousChar.isWhitespace);

          const textCharacter = new TextCharacter(glyphKey, absoluteCharIndex, fontStyle, formatting!, creationMetadata);
          if (this.textareaFormatting.displayNonPrintableCharacters) textCharacter.setGlyphScenario(GlyphScenario.DisplayingNonPrintableCharacters);
          this.characters.push(textCharacter);
          if (!textCharacter.isWhitespace) this._previousCharEndedSentence = textCharacter.endsSentence;
          previousChar = textCharacter;
          return textCharacter;
        });

        absoluteCharIndex++;
        const eop = new TextCharacter(PARAGRAPH_SPLIT_CHARACTER, absoluteCharIndex, fontStyle!, formatting!, this.endOfParagraphCharacterCreationMetadata);
        if (this.textareaFormatting.displayNonPrintableCharacters) eop.setGlyphScenario(GlyphScenario.DisplayingNonPrintableCharacters);
        characters.push(eop);
        this.characters.push(eop);

        const paragraph = new Paragraph(characters);
        const paragraphFormatting = this._paragraphFormattings.find((pF) => pF.index === paragraphIndex) ?? { ...DEFAULT_PARAGRAPH_FORMATTING, index: paragraphIndex };
        paragraph.loadFormatting(paragraphFormatting);

        return paragraph;
      });

    return this.paragraphs;
  }

  protected clearTextareaStatus() {
    this.hasOverflowingCharacters = false;
    this.lines = [];
    this.paragraphs = [];
    this.characters = [];
    this.resetCursor();
    this._previousCharEndedSentence = true;
    this.tryToFixFormattings = false;
  }

  getFontStyle(formatting: CharacterFormatting | CharacterFormattingDescription | undefined): FontStyle {
    const fontFamily = this.fontFamilies.get(formatting?.fontFamily || this.defaultFontFamily);
    if (!fontFamily) throw new Error(`Font family '${formatting?.fontFamily || this.defaultFontFamily}' not found! Can't return fontStyle!`);
    return formattingToFontStyle(formatting, fontFamily);
  }

  private tryToFixFormattings = false;
  protected getFormatting(index: number): CharacterFormattingDescription | undefined {
    const matchingFormattings = this._characterFormattings.filter((formatting) => {
      return index >= formatting.start && index < formatting.start + formatting.length;
    });

    if (DEVELOPMENT && matchingFormattings.length > 1) {
      console.warn(`Found more than one matching formatting for character with index ${index}:`, matchingFormattings);
      this.tryToFixFormattings = true;
    }
    if (DEVELOPMENT && matchingFormattings.some(f => Object.values(f).some(v => v === AUTO_SETTING_STRING))) {
      console.warn(`Found character formatting description with unsanitized "auto" as value:`, matchingFormattings.filter(f => Object.values(f).some(v => v === AUTO_SETTING_STRING)));
      this.tryToFixFormattings = true;
    }

    return matchingFormattings[0];
  }

  protected drawGlyphWithDecorations(ctx: CanvasRenderingContext2D, character: TextCharacter, paragraph: ParagraphWithMeasuredLines) {
    if (this.shouldRenderGlyph(character)) {
      this.drawGlyph(ctx, character);
      this.drawDecorations(ctx, character, paragraph);
    } else if (this.currentlyDrawnFillColor) {
      this.closePathForDrawingBatch(ctx);
    }
  }
  protected shouldRenderGlyph(character: TextCharacter) {
    const currentlyDrawnLineIsWithinVerticalRectBoundaries = this.cursorPosY <= this.bottomBorder && this.cursorPosY >= this.topBorder;
    return (character.isWhitespace || characterBboxPartiallyInsideVisibleBox(character, this)) && currentlyDrawnLineIsWithinVerticalRectBoundaries;
  }
  private currentlyDrawnFillCount = 0; // used to draw glyph paths in batches and force strike every 500 glyphs
  private currentlyDrawnFillColor: string | undefined = undefined; // used to draw glyph paths in batches per-same-fill-color
  private currentlyDrawnDecorations: TextDecorationTracking | undefined = undefined; // used to draw horizontal line strokes for underline/strikethroughs
  private drawGlyph(ctx: CanvasRenderingContext2D, character: TextCharacter) {
    if (this.currentlyDrawnFillColor && (this.currentlyDrawnFillColor !== character.formatting.color || this.currentlyDrawnFillCount === FORCE_BATCH_STRIKE_EVERY_N_GLYPHS)) {
      this.closePathForDrawingBatch(ctx);
    }

    if (!this.currentlyDrawnFillColor) {
      this.currentlyDrawnFillColor = character.formatting.color;
      ctx.beginPath();
    }

    const commands = this.getGlyphPaths(character);
    if (commands.length > 0) {
      this.currentlyDrawnFillCount++;
      this.executePathCommands(ctx, commands, character.formatting);
    }

    if (character === this.characters[this.characters.length - 1]) this.closePathForDrawingBatch(ctx);
  }
  private closePathForDrawingBatch(ctx: CanvasRenderingContext2D) {
    ctx.closePath();
    if (this.currentlyDrawnFillColor) ctx.fillStyle = this.currentlyDrawnFillColor;
    else DEVELOPMENT && console.warn('currentlyDrawnFillColor is ', this.currentlyDrawnFillColor, ' when invoked closing glyph batch to fill.');
    ctx.fill();
    this.currentlyDrawnFillColor = undefined;
    this.currentlyDrawnFillCount = 0;
  }
  private drawDecorations(ctx: CanvasRenderingContext2D, character: TextCharacter, paragraph: Paragraph) {
    const advancement = paragraph.calculateAdvancement(character, this.previousCharacter);

    if (character.hasDecorations) {
      const underline = character.underline;
      const strikethrough = character.strikethrough;

      let characterUnderlineRect: Rect | undefined = undefined;
      if (underline) {
        const { thickness, shiftFromBaseline } = underline;
        characterUnderlineRect = createRect(this.cursorPosX, this.cursorPosY + shiftFromBaseline, character.isEOParagraph ? 0 : advancement, thickness);
      }

      let characterStrikethroughRect: Rect | undefined = undefined;
      if (strikethrough) {
        const { thickness, shiftFromBaseline } = strikethrough;
        characterStrikethroughRect = createRect(this.cursorPosX, this.cursorPosY + shiftFromBaseline, character.isEOParagraph ? 0 : advancement, thickness);
      }

      const characterLineNr = this.findLineIndexFromCharacter(character);
      const fullFormatting = this.getFormatting(character.index)!; // it has to find formatting because we checked that this glyph has decorations

      const isDifferentFormatting = this.currentlyDrawnDecorations && this.currentlyDrawnDecorations.formatting && fullFormatting && !textRangesEqual(this.currentlyDrawnDecorations.formatting, fullFormatting);
      const isDifferentLine = this.currentlyDrawnDecorations && this.currentlyDrawnDecorations.lineNr !== characterLineNr;
      const startsLine = isFirstCharacter(character, this.lines[characterLineNr]);
      const shouldCreateNewTracking = !this.currentlyDrawnDecorations || isDifferentFormatting || isDifferentLine || startsLine;

      const isTheSameLine = this.currentlyDrawnDecorations && characterLineNr === this.currentlyDrawnDecorations.lineNr;
      const whitespaceBreakingLineBeyondBoundaries = character.isWhitespace && shouldBreakline(character, this.lines[characterLineNr]);
      const shouldContinueExistingTracking = !isDifferentFormatting && isTheSameLine && !whitespaceBreakingLineBeyondBoundaries;

      if (shouldCreateNewTracking) {
        this.currentlyDrawnDecorations = {
          underlineRect: characterUnderlineRect,
          strikethroughRect: characterStrikethroughRect,
          lineNr: characterLineNr,
          formatting: fullFormatting
        };
      } else if (this.currentlyDrawnDecorations && shouldContinueExistingTracking) {
        if (this.currentlyDrawnDecorations.strikethroughRect) this.currentlyDrawnDecorations.strikethroughRect.w += advancement;
        if (this.currentlyDrawnDecorations.underlineRect) this.currentlyDrawnDecorations.underlineRect.w += advancement;
      }

      if (this.currentlyDrawnDecorations) {
        const shouldStroke = shouldBreakline(character, this.lines[characterLineNr]) || isLastCharacterInRange(this.currentlyDrawnDecorations.formatting, character);
        if (shouldStroke) {
          const { strikethroughRect, underlineRect } = this.currentlyDrawnDecorations;

          if (strikethroughRect && strikethrough) {
            const { x, y, w, h } = strikethroughRect;
            ctx.fillStyle = strikethrough.color;
            ctx.fillRect(x, y, w, h);
          }

          if (underlineRect && underline) {
            const { x, y, w, h } = underlineRect;
            ctx.fillStyle = underline.color;
            ctx.fillRect(x, y, w, h);
          }
        }
      }
    } else {
      this.currentlyDrawnDecorations = undefined;
    }
  }
  private getGlyphPaths(character: TextCharacter) {
    const xPathPos = this.cursorPosX / (character.formatting?.scaleX || 1);
    const yPathPos = (this.cursorPosY - (character.formatting?.baselineShift || 0)) / (character.formatting?.scaleY || 1);
    const fontSize = character.size;
    return character.glyph.getPath(xPathPos, yPathPos, fontSize).commands;
  }
  protected executePathCommands(ctx: CanvasRenderingContext2D, commands: PathCommand[], formatting: CharacterFormatting) {
    const xScaling = formatting?.scaleX || 1;
    const yScaling = formatting?.scaleY || 1;
    commands.forEach((command) => {
      switch (command.type) {
        case 'M':
          ctx.moveTo(command.x * xScaling, command.y * yScaling);
          break;
        case 'L':
          ctx.lineTo(command.x * xScaling, command.y * yScaling);
          break;
        case 'C': {
          const { x1, y1, x2, y2, x, y } = command;
          ctx.bezierCurveTo(x1 * xScaling, y1 * yScaling, x2 * xScaling, y2 * yScaling, x * xScaling, y * yScaling);
          break;
        }
        case 'Q':
          ctx.quadraticCurveTo(command.x1 * xScaling, command.y1 * yScaling, command.x * xScaling, command.y * yScaling);
          break;
        case 'Z': break; // according to Opentype.js this would prompt for closing path, but since we fill/stroke in batches we ignore it
      }
    });
  }

  protected measureParagraphs() {
    for (let paragraph of this.paragraphs) {
      this.measureParagraph(paragraph);
      this.lines.push(...(paragraph.lines || []));
    }
    this.performPostMeasurementOperations();
    this.resetCursor();
  }
  protected abstract measureParagraph(paragraph: Paragraph): void;
  protected abstract performPostMeasurementOperations(): void;
  protected abstract setLineBboxX(line: Line): void;

  protected measureLines(paragraph: Paragraph): void {
    if (paragraph.formatting.breaklineStrategy === BreaklineStrategies.perLetter) {
      DEVELOPMENT && console.warn('Linebreak\'ing strategy \'perLetter\' is currently unsupported! We will add this possibility soon, meanwhile paragraphs lines will be broken \'perWord\'.');
    }
    paragraph.lines = this.measureLinesBreakablePerWords(paragraph);
  }
  protected abstract measureLinesBreakablePerWords(paragraph: Paragraph): Line[];

  protected getNextParagraphFromCharacter(character: TextCharacter): Paragraph | undefined {
    const thisParagraphIndex = this.paragraphs.findIndex((p) => {
      return p.characters.find((c) => c.index === character.index);
    });
    return this.paragraphs[thisParagraphIndex + 1] || undefined;
  }

  protected get verticalLength() {
    assumeParagraphsHaveMeasuredLines(this.paragraphs);
    if (this.text !== '' && !!this.lines.length) {
      return sum(this.lines.map(l => l.height)) - this.lines[this.lines.length - 1].descender;
    } else {
      return this.defaultCharacterLineheight;
    }
  }

  getCharactersFromTextSelection(selection: TextSelectionDetailed): TextCharacter[] {
    return this.characters.slice(selection.start, selection.end);
  }

  get defaultCharacterLineheight() {
    try {
      return this.regularFontStyleOfSelectedFontFamily.lineheight(DEFAULT_CHARACTER_FORMATTING.size);
    } catch (e) {
      DEVELOPMENT && console.error(e);
      return MIN_SAFE_LINEHEIGHT;
    }
  }

  verticallyNavigatingDoubleIndex: number | undefined = undefined;
  private findGlyphWithXInLine(line: Line, x: number) {
    const { breaklineAt, startAt } = line;

    const linesLastChar = this.characters[breaklineAt];
    if (this.caretAtEOL) return linesLastChar;

    if (hasMeasuredBbox(linesLastChar) && x > linesLastChar.bbox.x + linesLastChar.bbox.w) {
      return linesLastChar;
    }

    const linesFirstChar = this.characters[startAt];
    if (hasMeasuredBbox(linesFirstChar) && x < linesFirstChar.bbox.x) {
      return linesFirstChar;
    }

    for (const somePreviousCharacter of this.characters.slice(startAt, breaklineAt + 1)) {
      if (hasMeasuredBbox(somePreviousCharacter)) {
        const { x: someCharacterX, w: someCharacterW } = somePreviousCharacter.bbox;
        if (x >= someCharacterX && x <= someCharacterX + someCharacterW) {
          if (x >= someCharacterX + someCharacterW / 2 && !shouldBreakline(somePreviousCharacter, line)) {
            return this.characters[clampIndex(somePreviousCharacter.index + 1, this.characters)];
          } else {
            return somePreviousCharacter;
          }
        }
      }
    }

    return undefined;
  }
  findGlyphAboveDoubleIndex(doubleIndex: number, repositionedCharacter: TextCharacter) {
    const char = doubleIndexToCharacter(this, doubleIndex);
    let l1i = this.findLineIndexFromCharacter(repositionedCharacter);
    if (this.caretAtEOL && !this.characterBreaksLine(repositionedCharacter)) l1i -= 1;
    const line1 = this.lines[l1i];

    if (!char || !char.bbox || !line1) return undefined;
    const { x, w } = char.bbox;

    const checkedX = x + (isOdd(doubleIndex) ? w : 0);

    const previousLine = this.lines[l1i - 1];
    if (previousLine) {
      const charInPreviousLine = this.findGlyphWithXInLine(previousLine, checkedX);
      if (charInPreviousLine) return charInPreviousLine;
    }

    return this.characters[0];
  }
  findGlyphBelowDoubleIndex(doubleIndex: number, repositionedCharacter: TextCharacter) {
    const char = doubleIndexToCharacter(this, doubleIndex);
    let l1i = this.findLineIndexFromCharacter(repositionedCharacter);

    const atStart = repositionedCharacter === this.characters[0];
    const repositionedCharBreaksLine = this.characterBreaksLine(repositionedCharacter);

    if (this.caretAtEOL && !repositionedCharBreaksLine && !atStart) {
      l1i -= 1;
    }

    const line1 = this.lines[l1i];

    if (!char || !char.bbox || !line1) return undefined;
    const { x, w } = char.bbox;

    const checkedX = x + (isOdd(doubleIndex) ? w : 0);

    const nextLine = this.lines[l1i + 1];
    if (nextLine) {
      const charInNextLine = this.findGlyphWithXInLine(nextLine, checkedX);
      if (charInNextLine) return charInNextLine;
    }

    return this.characters[this.characters.length - 1];
  }

  findParagraphIndexFromCharacter(character: TextCharacter): number {
    const characterIndex = character.index;
    for (let i = 0; i < this.paragraphs.length; i++) {
      if (this.paragraphs[i].characters.find(c => c.index === characterIndex)) return i;
    }
    throw new Error(`Character ${character.indexGlyphKeyString}, does not belong to this textarea.`);
  }
  findParagraphFromCharacter(character: TextCharacter): Paragraph {
    const index = this.findParagraphIndexFromCharacter(character);
    if (index !== undefined) return this.paragraphs[index];
    else throw new Error(`Character ${character.indexGlyphKeyString}, does not belong to this textarea.`);
  }
  findLineIndexFromCharacter(character: TextCharacter): number {
    if (!this.characterBelongsToThisTextarea(character)) throw new Error(`Character ${character.indexGlyphKeyString}, does not belong to this textarea.`);
    return findLineIndexWithCharacter(this.lines, character)!;
  }
  findLineDataFromCharacter(character: TextCharacter): Line {
    if (!this.characterBelongsToThisTextarea(character)) throw new Error(`Character ${character.indexGlyphKeyString}, does not belong to this textarea.`);
    return findLineDataWithCharacter(this.lines, character)!;
  }
  characterBelongsToThisTextarea(character: TextCharacter) {
    return this.characters[character.index] === character;
  }
}

export class AutoWidthTextarea extends Textarea {
  readonly type = TextareaType.AutoWidth;

  constructor(fonts: FontFamilies, options: TextareaOptions) {
    super(fonts, options);
  }

  get leftBorder() {
    return this.x + this.negativeOffsetForWidth;
  }

  protected measureParagraph(paragraph: Paragraph) {
    this.measureLines(paragraph);
  }
  protected performPostMeasurementOperations() {
    for (let line of this.lines) {
      this.setLineBboxX(line);
      this.limitLineBboxW(line);
    }
  }
  protected setLineBboxX(line: Line) {
    if (alignsToRight(line.alignment)) {
      line.bbox.x = this.x - line.bbox.w;
    } else if (alignsToCenter(line.alignment)) {
      line.bbox.x = this.x - line.bbox.w / 2;
    } else {
      line.bbox.x = this.x;
    }
  }
  private limitLineBboxW(line: Line) {
    const positiveBoxPart = this.rightBorder - this.x;
    const negativeBoxPart = this.x - this.leftBorder;

    let positiveLinePart = 0;
    let negativeLinePart = 0;

    if (alignsToRight(line.alignment)) {
      positiveLinePart = 0;
      negativeLinePart = line.bbox.w;
    } else if (alignsToCenter(line.alignment)) {
      positiveLinePart = line.bbox.w / 2;
      negativeLinePart = line.bbox.w / 2;
    } else {
      positiveLinePart = line.bbox.w;
      negativeLinePart = 0;
    }

    positiveLinePart = Math.min(positiveLinePart, positiveBoxPart);
    negativeLinePart = Math.min(negativeLinePart, negativeBoxPart);

    line.bbox.w = positiveLinePart + negativeLinePart;
  }

  renderGlyph(ctx: CanvasRenderingContext2D, character: TextCharacter, paragraph: ParagraphWithMeasuredLines) {
    let origin = this.cursorPosX;
    let valueAdded = calculateAdvancement(character);

    this.drawGlyphWithDecorations(ctx, character, paragraph);
    this.previousCharacter = character;
    this.cursorPosX = origin + valueAdded;

    const lineIndex = paragraph.getLineIndexFromCharacter(character);
    if (paragraph.lines[lineIndex].breaklineAt === character.index) {
      const nextLine = this.lines[lineIndex + 1];
      if (nextLine) this.nextLine(nextLine);
    }
  }

  get renderBoundariesOnHover() { return true; }
  get renderControlPointsOnHover() { return true; }

  get negativeOffset(): number {
    return this.negativeOffsetForWidth;
  }
  negativeOffsetForWidth = 0;
  private negativeOffsets(): number[] {
    // TODO: consider caching these per write() call
    return this.paragraphs.map(p => {
      return (p.lines ?? []).map(l => {
        let value;
        if (p.formatting.alignment === TextAlignment.CenterAligned || p.formatting.alignment === TextAlignment.CenterJustified) {
          value = -(l.glyphsWidth / 2);
        } else if (p.formatting.alignment === TextAlignment.RightAligned || p.formatting.alignment === TextAlignment.RightJustified) {
          value = -l.glyphsWidth;
        } else {
          value = 0;
        }
        return value;
      });
    }).flat();
  }
  private linesLengthsWhenAccountingAlignment(): number[] {
    this.negativeOffsetForWidth = 0;
    return this.paragraphs.map(p => {
      return (p.lines ?? []).map(l => {
        let value;
        if ([TextAlignment.CenterAligned, TextAlignment.CenterJustified].includes(p.formatting.alignment)) {
          value = l.glyphsWidth / 2;
          this.negativeOffsetForWidth = Math.min(-value, this.negativeOffsetForWidth);
        } else if ([TextAlignment.RightAligned, TextAlignment.RightJustified].includes(p.formatting.alignment)) {
          value = -l.glyphsWidth;
          this.negativeOffsetForWidth = Math.min(value, this.negativeOffsetForWidth);
        } else {
          value = l.glyphsWidth;
        }
        return value;
      });
    }).flat();
  }
  private farthestLinesLengths(): [number, number] {
    const lines = this.linesLengthsWhenAccountingAlignment();
    return [Math.min(this.negativeOffsetForWidth, 0), Math.max(...lines, 0)];
  }
  get width() {
    let width = 0;
    if (this.isResizing) {
      width = this._width;
    } else {
      const alignedLinesLengths = this.linesLengthsWhenAccountingAlignment();
      if (alignedLinesLengths.length > 0) {
        const [left, right] = this.farthestLinesLengths();
        width = Math.abs(left) + Math.abs(right);
      } else {
        width = 0;
      }
    }
    return Math.max(0, width);
  }

  set width(width: number) {
    if (Number.isFinite(width)) {
      this._width = Math.round(Math.abs(width));
    } else {
      this._width = 0;
    }
  }

  get height() {
    assumeParagraphsHaveMeasuredLines(this.paragraphs);
    return this.verticalLength;
  }

  set height(height: number) {
    if (Number.isFinite(height)) {
      this._height = Math.round(Math.abs(height));
    } else {
      this._height = 0;
    }
  }

  protected measureLinesBreakablePerWords(paragraph: Paragraph): Line[] {
    const lineService = new LineMeasuringService(this.lines);

    let lineAdvancement = 0;
    let previousCharacter: TextCharacter | undefined = undefined;

    for (let index = 0; index < paragraph.characters.length; index++) {
      let character = paragraph.characters[index];
      const glyphAdvancement = calculateAdvancement(character, previousCharacter);
      if (!character.isEOParagraph) lineAdvancement += glyphAdvancement;
      lineService.storeCharacter(paragraph, character, this.cursorPosY);
    }

    const lastChar = paragraph.characters[paragraph.characters.length - 1];
    const lastCharIndex = lastChar ? lastChar.index : -1;
    lineService.submitLine(lastCharIndex, lineAdvancement);

    this.cursorPosY = lineService.savedLines[0].baseline;

    return lineService.savedLines;
  }

  nextLine(nextLine: Line) {
    this.lineheight = nextLine.height;
    this.cursorPosY = nextLine.baseline;
    if (alignsToCenter(nextLine.alignment)) {
      this.cursorPosX = this.x - nextLine.glyphsWidth / 2;
    } else if (alignsToRight(nextLine.alignment)) {
      this.cursorPosX = this.x - nextLine.glyphsWidth;
    } else {
      this.cursorPosX = this.x;
    }
    this.previousCharacter = undefined;
  }
  nextLineManually(alignment: TextAlignment, width: number, height: number): void {
    this.lineheight = height;
    this.cursorPosY += this.lineheight;
    switch (alignment) {
      case TextAlignment.LeftAligned:
      case TextAlignment.LeftJustified:
      case TextAlignment.FullyJustified: {
        this.cursorPosX = this.x;
        break;
      }
      case TextAlignment.CenterAligned:
      case TextAlignment.CenterJustified: {
        this.cursorPosX = this.x - width / 2;
        break;
      }
      case TextAlignment.RightAligned:
      case TextAlignment.RightJustified: {
        this.cursorPosX = this.x - width;
        break;
      }
    }
    this.previousCharacter = undefined;
  }

  saveBoundingBoxes() {
    let glyphsInLineUntilThisGlyph = 0;

    this.iterateOverEachCharacterInPosition(({ character, paragraph, line, paragraphIndex }) => {
      const negativeOffset = this.negativeOffsets()[paragraphIndex];
      paragraph.bbox = createRect(this.x + negativeOffset, this.cursorPosY, TEXTAREA_CURSOR_WIDTH, this.lineheight);
      const advancement = calculateAdvancement(character, this.previousCharacter);

      let x = this.x + glyphsInLineUntilThisGlyph + negativeOffset;
      if (paragraph.isEmpty) {
        if (alignsToLeft(paragraph.formatting.alignment)) x = this.cursorPosX;
        if (alignsToCenter(paragraph.formatting.alignment)) x = this.cursorPosX - advancement / 2;
        if (alignsToRight(paragraph.formatting.alignment)) x = this.cursorPosX - advancement;
      }
      saveBbox(character, x, line.bbox.y, advancement, line.bbox.h);

      this.saveCharactersBboxesFlags(paragraph, character);
      glyphsInLineUntilThisGlyph += advancement;

      if (shouldBreakline(character, line)) glyphsInLineUntilThisGlyph = 0;
    });
    this.cursorPosX = this.x;
    this.cursorPosY = this.y;
    this.previousCharacter = undefined;
  }
  protected saveCharactersBboxesFlags(paragraph: ParagraphWithMeasuredLines, character: TextCharacterWithMeasuredBbox) {
    paragraph;
    character.partiallyInsideVisibleBox = true;
    character.renderable = true;
    if (this.viableForSelectionVisually(character)) this.extendTextureRect(character.bbox);
  }

  get frames(): Rect {
    assumeParagraphsHaveMeasuredLines(this.paragraphs);
    const rect = createRect(this.x + this.negativeOffsetForWidth, this.y, this.width, this.verticalLength);
    normalizeRect(rect);
    return rect;
  }

  buildControlPoints(): TextareaControlPoint[] {
    assumeParagraphsHaveMeasuredLines(this.paragraphs);
    const allLines = this.paragraphs.map((p) => p.lines).flat();

    const allLinesHeights = allLines.map((l) => l.height ? l.height : this.defaultCharacterLineheight);
    const allLinesHeightsSum = sum(allLinesHeights);

    const rect = createRect(this.x + this.negativeOffsetForWidth, this.y, this.width, allLinesHeightsSum);
    normalizeRect(rect);

    const leftPoint = pointInHalfBetweenPoints(createPoint(this.bounds[0][0], this.bounds[0][1]), createPoint(this.bounds[3][0], this.bounds[3][1]));
    const left = new TextareaControlPoint({
      point: leftPoint,
      direction: TextareaControlPointDirections.West,
      onDrag: (dx, _, textarea, originalRect) => {
        if (!textarea.resizedNegativelyX) {
          textarea.x = originalRect.x - textarea.negativeOffset + dx;
          textarea.width = originalRect.w - dx;
          if (originalRect.w - dx < 0) {
            textarea.flipTextareaWithControlPoint(true, false, createPoint(this.rightBorder, this.topBorder));
            return true;
          }
        } else {
          textarea.x = (originalRect.x - textarea.negativeOffset + originalRect.w) - (dx - originalRect.w); // TODO: this sort of works but when spamming flips translates textarea, todo fix
          textarea.width = dx - originalRect.w;
          if (originalRect.w - dx >= 0) {
            textarea.flipTextareaWithControlPoint(true, false, createPoint(this.rightBorder, this.topBorder));
            return true;
          }
        }
        return false;
      }
    });

    const rightPoint = pointInHalfBetweenPoints(createPoint(this.bounds[1][0], this.bounds[1][1]), createPoint(this.bounds[2][0], this.bounds[2][1]));
    const right = new TextareaControlPoint({
      point: rightPoint,
      direction: TextareaControlPointDirections.East,
      onDrag: (dx, _, textarea, originalRect) => {
        textarea.width = originalRect.w + dx;

        if (!textarea.resizedNegativelyX) {
          if (originalRect.w + dx <= 0) {
            textarea.flipTextareaWithControlPoint(true, false);
            return true;
          }
        } else {
          if (originalRect.w + dx > 0) {
            textarea.flipTextareaWithControlPoint(true, false);
            return true;
          }
        }

        return false;
      }
    });

    return [left, right];
  }
}

export const BOX_TEXTAREA_DEFAULT_WIDTH = 264;
export const BOX_TEXTAREA_DEFAULT_HEIGHT = 64;

export abstract class BoxTextarea extends Textarea {
  abstract readonly type: TextareaType;

  constructor(fonts: FontFamilies, options: TextareaOptions) {
    super(fonts, options);
    this._width = options.w || BOX_TEXTAREA_DEFAULT_WIDTH;
    this._height = options.h || BOX_TEXTAREA_DEFAULT_HEIGHT;
  }

  get width() {
    return Math.max(this._width, 0);
  }

  set width(width: number) {
    if (Number.isFinite(width)) {
      this._width = Math.round(Math.abs(width));
    } else {
      this._width = 0;
    }
  }

  get height() {
    return Math.max(this._height, 0);
  }

  set height(height: number) {
    if (Number.isFinite(height)) {
      this._height = Math.round(Math.abs(height));
    } else {
      this._height = 0;
    }
  }

  resetCursor() {
    super.resetCursor();
    this._justifiedSpaceTracker = 0;
  }

  protected measureParagraph(paragraph: Paragraph) {
    this.measureLines(paragraph);
    this.adjustJustifiedParagraphsLines(paragraph);
  }

  protected performPostMeasurementOperations() {
    for (let line of this.lines) {
      this.setLineBboxX(line);
      this.advanceLineToRespectVerticalAlignment(line);
    }
  }

  protected setLineBboxX(line: Line) {
    switch (line.alignment) {
      case TextAlignment.LeftAligned:
      case TextAlignment.LeftJustified:
      case TextAlignment.FullyJustified: {
        line.bbox.x = this.leftBorder;
        break;
      }
      case TextAlignment.CenterAligned:
      case TextAlignment.CenterJustified: {
        line.bbox.x = this.rightBorder - (this.width / 2) - (line.bbox.w / 2);
        break;
      }
      case TextAlignment.RightAligned:
      case TextAlignment.RightJustified: {
        line.bbox.x = this.rightBorder - line.bbox.w;
        break;
      }
    }
  }

  protected advanceLineToRespectVerticalAlignment(line: Line) {
    const vAlignment = this.textareaFormatting.verticalAlignment;
    const emptySpace = this.height - this.verticalLength;
    let shift = 0;

    if (vAlignment === VerticalAlignments.Center) {
      shift = emptySpace / 2;
    } else if (vAlignment === VerticalAlignments.Bottom) {
      shift = emptySpace;
    }

    line.baseline += shift;
    line.bbox.y += shift;
  }

  measureLinesBreakablePerWords(paragraph: Paragraph): Line[] {
    if (paragraph.isJustified) {
      return this.measureLinesBreakablePerWordsInJustifiedParagraph(paragraph);
    } else {
      return this.measureLinesBreakablePerWordsInAlignedParagraph(paragraph);
    }
  }

  private measureLinesBreakablePerWordsInAlignedParagraph(paragraph: Paragraph) {
    const lineService = new LineMeasuringService(this.lines);
    const previousWhitespace = new PreviousWhitespaceTracker();

    let lineAdvancement = 0;
    let previousCharacter: TextCharacter | undefined;
    let lineWasJustBroken = false;

    for (let index = 0; index < paragraph.characters.length; index++) {
      let character = paragraph.characters[index];

      let glyphAdvancement = calculateAdvancement(character, previousCharacter) + (previousCharacter ? calculateKerning(previousCharacter, character) : 0);
      if (character.isEOParagraph) glyphAdvancement = 0;

      lineAdvancement += glyphAdvancement;

      if (lineAdvancement > this.width) {
        if (previousWhitespace.spaceExisting && !lineWasJustBroken) {
          lineService.submitLineAtPreviousWhitespace(previousWhitespace);
          this.cursorPosY = lineService.savedLines[lineService.savedLines.length - 1].baseline;
          index = previousWhitespace.relativeIndex;
          lineAdvancement = 0;
          previousCharacter = undefined;
          previousWhitespace.reset();
          lineWasJustBroken = true;
        } else {
          let isLastCharFromParagraph = character.index === paragraph.characters[paragraph.characters.length - 1].index;
          if (character.isWhitespace || isLastCharFromParagraph) {
            lineService.storeCharacter(paragraph, character, this.cursorPosY);
            const lineLength = lineAdvancement - (character.isWhitespace ? glyphAdvancement : 0);
            lineService.submitLine(character.index, lineLength);
            this.cursorPosY = lineService.savedLines[lineService.savedLines.length - 1].baseline;
            lineAdvancement = 0;
            previousCharacter = undefined;
            previousWhitespace.reset();
            lineWasJustBroken = true;
          } else {
            lineService.storeCharacter(paragraph, character, this.cursorPosY);
            previousCharacter = character;
          }
        }
      } else {
        lineService.storeCharacter(paragraph, character, this.cursorPosY);

        if (character.isWhitespace && !lineWasJustBroken) {
          previousWhitespace.set(character.index, index, lineAdvancement - glyphAdvancement);
        } else if (lineWasJustBroken) {
          previousWhitespace.spaceExisting = false;
          lineWasJustBroken = false;
        }

        if (index === paragraph.characters.length - 1) {
          lineService.submitLine(character.index, lineAdvancement);
          this.cursorPosY = lineService.savedLines[lineService.savedLines.length - 1].baseline;
        }

        previousCharacter = character;
      }
    }

    return lineService.savedLines;
  }

  private measureLinesBreakablePerWordsInJustifiedParagraph(paragraph: Paragraph) {
    const lineService = new LineMeasuringService(this.lines);
    const previousWhitespace = new PreviousWhitespaceTracker();
    const justifiedSpacesService = new JustifiedSpacesTracker();

    let lineAdvancement = 0;
    let previousCharacter: TextCharacter | undefined;

    for (let index = 0; index < paragraph.characters.length; index++) {
      let character = paragraph.characters[index];

      let glyphAdvancement = calculateAdvancement(character, previousCharacter);
      if (character.isEOParagraph) glyphAdvancement = 0;

      if (character.isWhitespace && !character.isEOParagraph) justifiedSpacesService.addNewSpace(glyphAdvancement);
      else lineAdvancement += glyphAdvancement;

      if (!previousWhitespace.spaceExisting && lineAdvancement > this.width && glyphAdvancement <= this.width) {
        if (!character.isWhitespace) {
          lineService.storeCharacter(paragraph, character, this.cursorPosY);
        }
        lineService.submitLine(character.index - 1, lineAdvancement - glyphAdvancement);
        this.cursorPosY = lineService.savedLines[lineService.savedLines.length - 1].baseline;
        justifiedSpacesService.submitLine();
        previousWhitespace.reset();
        lineAdvancement = 0;
        previousCharacter = undefined;
        index--;
      } else if (previousWhitespace.spaceExisting && justifiedSpacesService.shouldBreakline(this.width - lineAdvancement)) {
        if (character.isWhitespace) {
          // this is an edge case where exceeding word fits in line completely and
          // there is one space too much added. Workaround would be to move:
          // "justifiedSpacesService.addNewSpace(glyphAdvancement);" above into else block below
          // but this would cause a lot of extra logic and unnecessary iterations,
          // so it's easier to just remove it
          justifiedSpacesService.removeLastSpace();
        }
        lineService.submitLineAtPreviousWhitespace(previousWhitespace);
        this.cursorPosY = lineService.savedLines[lineService.savedLines.length - 1].baseline;
        justifiedSpacesService.submitLine();
        index = previousWhitespace.relativeIndex;
        previousWhitespace.reset();
        lineAdvancement = 0;
        previousCharacter = undefined;
      } else {
        lineService.storeCharacter(paragraph, character, this.cursorPosY);

        if (character.isWhitespace && !lineService.lineWasJustBroken) {
          previousWhitespace.set(character.index, index, lineAdvancement);
        } else if (lineService.lineWasJustBroken) {
          previousWhitespace.spaceExisting = false;
          lineService.lineWasJustBroken = false;
        }

        if (index === paragraph.characters.length - 1) {
          lineService.submitLine(character.index, lineAdvancement);
          this.cursorPosY = lineService.savedLines[lineService.savedLines.length - 1].baseline;
          justifiedSpacesService.submitLine();
        }

        previousCharacter = character;
      }
    }

    this.stretchLinesWithOneWord(paragraph, lineService, justifiedSpacesService);

    const justifiedSpaces = justifiedSpacesService.getJustifiedSpacesWidths(this.width, lineService, paragraph.formatting.breaklineStrategy);

    lineService.consumeJustificationData(justifiedSpaces, justifiedSpacesService.measuredLines, paragraph.formatting.alignment);

    return lineService.savedLines;
  }

  stretchLinesWithOneWord(paragraph: Paragraph, lineMeasurer: LineMeasuringService, justifiedSpacesService: JustifiedSpacesTracker) {
    this.iterateOverLinesToStretch(paragraph, lineMeasurer, ({ line, lineIndex, lineAdvancement, glyphAdvancement, artificialAdvancementsRegular, previous, character, }) => {
      if (lineAdvancement + glyphAdvancement > this.width) {
        artificialAdvancementsRegular.push(0);
      } else if (!(previous === undefined && character.isWhitespace) && !character.isEOParagraph) {
        lineAdvancement += glyphAdvancement;
        const preGlyphAdvancement = this.calculatePreGlyphAdvancementForStretchedWord(previous);
        if (preGlyphAdvancement > 0) artificialAdvancementsRegular.push(preGlyphAdvancement);
      }
      if (shouldBreakline(character, line)) justifiedSpacesService.measuredLines[lineIndex] = justifiedSpacesService.computeOneLine(artificialAdvancementsRegular, line.glyphsWidth, false);
    });

  }
  private iterateOverLinesToStretch(
    paragraph: Paragraph,
    lineMeasurer: LineMeasuringService,
    andDoWhat: (iteration: LineStretchingIteration) => void,
  ): void {
    let previousCharacter: TextCharacter | undefined;
    let startedAnalyzingLine = true;
    let artificialAdvancementsRegular: number[] = [];
    let lineAdvancement = 0;

    for (let index = 0; index < paragraph.characters.length; index++) {
      let character = paragraph.characters[index];
      const lineIndex = findLineIndexWithCharacter(lineMeasurer.savedLines, character)!;
      const line = lineMeasurer.savedLines[lineIndex] as Line & { bbox: never };

      if (shouldStretch(line)) {
        if (startedAnalyzingLine) {
          artificialAdvancementsRegular = [];
          startedAnalyzingLine = false;
          lineAdvancement = 0;
          previousCharacter = undefined;
        }
        const glyphAdvancement = calculateAdvancement(character, previousCharacter);
        andDoWhat({
          previous: previousCharacter,
          lineAdvancement,
          line: line,
          glyphAdvancement,
          artificialAdvancementsRegular,
          character,
          lineIndex
        });
        previousCharacter = character;
        if (shouldBreakline(character, line)) startedAnalyzingLine = true;
      } else {
        index = paragraph.characters.findIndex((char) => shouldBreakline(char, line));
        startedAnalyzingLine = true;
      }
    }
  }

  private calculatePreGlyphAdvancementForStretchedWord(previousCharacter: TextCharacter | undefined) {
    if (!previousCharacter) return 0;
    const fontStyle = previousCharacter.fontStyle;
    const space = fontStyle.getGlyph(' ');
    return fontStyle.toPixels(space.advanceWidth, previousCharacter.size);
  }

  protected adjustJustifiedParagraphsLines(paragraph: Paragraph) {
    const paragraphAlignment = paragraph.formatting.alignment;
    if (paragraph.isJustified) {
      assumeParagraphHaveMeasuredLines(paragraph);
      for (let line of paragraph.lines) {
        if (paragraphAlignment !== TextAlignment.FullyJustified) {
          if (line !== paragraph.lines[paragraph.lines.length - 1]) {
            line.alignment = TextAlignment.FullyJustified;
          } else {
            line.bbox.w = line.glyphsWidth;
          }
        }
      }
    }
  }

  protected renderGlyph(ctx: CanvasRenderingContext2D, character: TextCharacter, paragraph: ParagraphWithMeasuredLines) {
    if (paragraph.isJustified) {
      return this.renderGlyphInJustifiedParagraph(ctx, character, paragraph);
    } else {
      return this.renderGlyphInAlignedParagraph(ctx, character, paragraph);
    }
  }

  protected _justifiedSpaceTracker = 0;
  private renderGlyphInStretchedWord(
    ctx: CanvasRenderingContext2D,
    character: TextCharacter,
    paragraph: ParagraphWithMeasuredLines,
  ) {
    if (paragraph.lines) {
      const thisLineIndex = paragraph.getLineIndexFromCharacter(character);
      const thisLineData = paragraph.lines[thisLineIndex];
      const { justifiedSpaces, wordsCount } = thisLineData;
      const isLastLine = thisLineIndex === paragraph.lines.length - 1;
      const isFullyJustified = paragraph.formatting.alignment === TextAlignment.FullyJustified;

      const isLastLineInNotFullyJustifiedParagraphWithJustOneWord = (!isFullyJustified && isLastLine && wordsCount === 1);

      let glyphAdvancement = calculateAdvancement(character, this.previousCharacter);
      this.drawGlyphWithDecorations(ctx, character, paragraph);
      this.cursorPosX += glyphAdvancement;

      if (!shouldBreakline(character, thisLineData) && !isLastLineInNotFullyJustifiedParagraphWithJustOneWord) {
        const justifiedSpaceAdvancement = justifiedSpaces![this._justifiedSpaceTracker] ?? 0;
        this.cursorPosX += justifiedSpaceAdvancement;
        this._justifiedSpaceTracker++;
      }

      this.previousCharacter = character;
      this.nextLineInStretchedWord(paragraph, [thisLineData, thisLineIndex], character);
    }
  }

  protected renderGlyphInJustifiedParagraph(ctx: CanvasRenderingContext2D, character: TextCharacter, paragraph: ParagraphWithMeasuredLines) {
    const thisLineIndex = paragraph.getLineIndexFromCharacter(character);
    const thisLineData = paragraph.lines[thisLineIndex];
    const { wordsCount } = thisLineData;
    const isLastLine = thisLineIndex === paragraph.lines.length - 1;
    const isFullyJustified = paragraph.formatting.alignment === TextAlignment.FullyJustified;

    if (wordsCount === 1) {
      this.renderGlyphInStretchedWord(ctx, character, paragraph);
    } else {
      const displayingNonPrintableCharacter = character.currentGlyphScenario === GlyphScenario.DisplayingNonPrintableCharacters;
      const glyphAdvancement = paragraph.calculateAdvancement(character, this.previousCharacter);
      if (displayingNonPrintableCharacter) this.cursorPosX += glyphAdvancement / 2;
      this.drawGlyphWithDecorations(ctx, character, paragraph);

      if (character.isWhitespace && (!isLastLine || isFullyJustified)) {
        this._justifiedSpaceTracker++;
      } else {
        this.previousCharacter = character;
      }

      this.cursorPosX += glyphAdvancement / (displayingNonPrintableCharacter ? 2 : 1);

      this.nextLineInJustifiedParagraph(paragraph, [thisLineData, thisLineIndex], character);
    }
  }
  private nextLineInStretchedWord(paragraph: Paragraph, line: [Line, number], character: TextCharacter): boolean {
    paragraph;
    const [lineData, lineIndex] = line;
    const { breaklineAt } = lineData;
    if (breaklineAt === character.index) {
      const nextLine = this.lines[lineIndex + 1];
      if (nextLine) {
        const { glyphsWidth, regularSpaces, alignment } = nextLine;
        this.nextLineManually(alignment, glyphsWidth + sum(regularSpaces || []), nextLine.height);
        return true;
      }
    }
    return false;
  }
  private nextLineInJustifiedParagraph(paragraph: ParagraphWithMeasuredLines, line: [Line, number], character: TextCharacter): boolean {
    const [lineData, lineIndex] = line;
    const { breaklineAt } = lineData;
    if (breaklineAt === character.index) {
      const nextline = this.lines[lineIndex + 1];
      const isAlmostLastLine = lineIndex === paragraph.lines.length - 2;
      const isLastLine = lineIndex === paragraph.lines.length - 1;
      if (nextline) {
        const { glyphsWidth, regularSpaces, alignment } = nextline;
        const lineAlignment = isLastLine ? alignment : isAlmostLastLine ? paragraph.formatting.alignment : TextAlignment.FullyJustified;

        const lastLine = paragraph.lines[lineIndex + 1];
        let lw = glyphsWidth + sum(regularSpaces || []);
        if (isAlmostLastLine && lastLine.wordsCount === 1) {
          lw = glyphsWidth + sum(justifyOneLine(regularSpaces || [], this.width - glyphsWidth, true));
        }

        this.nextLineManually(lineAlignment, lw, nextline.height);
        return true;
      }
    }
    return false;
  }

  saveBoundingBoxes() {
    let glyphsInLineUntilThisGlyph = 0;
    this.iterateOverEachCharacterInPosition(({ character, paragraph, line }) => {
      const { bbox: lineBbox } = line;

      if (character.index === paragraph.characters[0].index) {
        const insideVisibleBox = this.cursorPosY > this.topBorder && this.cursorPosY < this.bottomBorder;
        paragraph.bbox = createRect(this.cursorPosX, this.cursorPosY, TEXTAREA_CURSOR_WIDTH, lineBbox.h);
        paragraph.insideVisibleBox = insideVisibleBox;
      }

      const advancement = paragraph.calculateAdvancement(character, this.previousCharacter);

      if (!paragraph.isJustified && this.previousCharacter) this.cursorPosX += calculateKerning(this.previousCharacter, character);

      let x = this.cursorPosX + glyphsInLineUntilThisGlyph;
      if (paragraph.isEmpty) {
        if (alignsToLeft(paragraph.formatting.alignment)) x = this.cursorPosX;
        if (alignsToCenter(paragraph.formatting.alignment)) x = this.cursorPosX - advancement / 2;
        if (alignsToRight(paragraph.formatting.alignment)) x = this.cursorPosX - advancement;
      }
      saveBbox(character, x, lineBbox.y, advancement, lineBbox.h);

      glyphsInLineUntilThisGlyph += advancement;
      if (shouldBreakline(character, line)) glyphsInLineUntilThisGlyph = 0;

      this.saveCharactersBboxesFlags(paragraph, character);
    });
  }

  protected saveCharactersBboxesFlags(paragraph: ParagraphWithMeasuredLines, character: TextCharacterWithMeasuredBbox) {
    paragraph;
    character.partiallyInsideVisibleBox = characterBboxPartiallyInsideVisibleBox(character, this);
    character.renderable = this.shouldRenderGlyph(character);
    if (this.viableForSelectionVisually(character)) this.extendTextureRect(character.bbox);
    if (!this.hasOverflowingCharacters && !character.renderable) this.hasOverflowingCharacters = true;
  }

  protected renderGlyphInAlignedParagraph(ctx: CanvasRenderingContext2D, character: TextCharacter, paragraph: ParagraphWithMeasuredLines) {
    const thisLineIndex = paragraph.getLineIndexFromCharacter(character);
    const thisLineData = paragraph.lines[thisLineIndex];

    let glyphAdvancement = paragraph.calculateAdvancement(character, this.previousCharacter);

    const displayingNonPrintableCharacter = character.currentGlyphScenario === GlyphScenario.DisplayingNonPrintableCharacters;
    if (displayingNonPrintableCharacter) this.cursorPosX += glyphAdvancement / 2 - character.glyphWidthNoMultiplier / 2;

    if (this.previousCharacter) this.cursorPosX += calculateKerning(this.previousCharacter, character);

    this.drawGlyphWithDecorations(ctx, character, paragraph);

    if (!shouldBreakline(character, thisLineData)) {
      if (displayingNonPrintableCharacter) {
        this.cursorPosX += glyphAdvancement / 2 + character.glyphWidthNoMultiplier / 2;
      } else {
        this.cursorPosX += glyphAdvancement;
      }
    } else if (!character.isWhitespace) {
      this.cursorPosX += glyphAdvancement < 0 ? -(Math.abs(character.regularGlyphWidth) + Math.abs(character.letterSpacingInPx)) : glyphAdvancement;
    }

    this.previousCharacter = character;

    if (shouldBreakline(character, thisLineData)) {
      const nextline = this.lines[thisLineIndex + 1];
      if (nextline) this.nextLine(nextline);
    }
  }

  nextLine(nextLine: Line) {
    this.lineheight = nextLine.height;
    this.cursorPosY = nextLine.baseline;
    if (alignsToCenter(nextLine.alignment)) {
      this.cursorPosX = this.rightBorder - (this.width / 2) - (nextLine.glyphsWidth / 2);
    } else if (alignsToRight(nextLine.alignment)) {
      this.cursorPosX = this.rightBorder - nextLine.glyphsWidth;
    } else {
      this.cursorPosX = this.x;
    }
    this.previousCharacter = undefined;
    this._justifiedSpaceTracker = 0;
  }
  nextLineManually(alignment: TextAlignment, width: number, height: number) {
    this.lineheight = height;
    this.cursorPosY += this.lineheight;
    if (alignsToCenter(alignment)) {
      this.cursorPosX = this.rightBorder - (this.width / 2) - (width / 2);
    } else if (alignsToRight(alignment)) {
      this.cursorPosX = this.rightBorder - width;
    } else {
      this.cursorPosX = this.x;
    }
    this.previousCharacter = undefined;
    this._justifiedSpaceTracker = 0;
  }

  get frames(): Rect {
    return createRect(this.leftBorder, this.topBorder, this.width, this.height);
  }

  buildControlPoints(): TextareaControlPoint[] {
    const bounds = this.bounds;

    const topLeftPoint = createPoint(bounds[0][0], bounds[0][1]);
    const topLeft = new TextareaControlPoint({
      point: topLeftPoint,
      direction: TextareaControlPointDirections.NorthWest,
      onDrag: (dx, dy, textarea, originalRect) => {
        textarea.y = originalRect.y + dy;
        textarea.height = originalRect.h - dy;

        let flippedX = false;
        let flippedY = false;

        if (!textarea.resizedNegativelyY) {
          textarea.y = originalRect.y + dy;
          textarea.height = originalRect.h - dy;
          flippedY = originalRect.h - dy < 0;
        } else {
          textarea.y = (originalRect.y + originalRect.h) - (dy - originalRect.h); // TODO: this sort of works but when spamming flips translates textarea, todo fix
          textarea.height = dy - originalRect.h;
          flippedY = originalRect.h - dy >= 0;
        }

        if (!textarea.resizedNegativelyX) {
          textarea.x = originalRect.x + dx;
          textarea.width = originalRect.w - dx;
          flippedX = originalRect.w - dx < 0;
        } else {
          textarea.x = (originalRect.x + originalRect.w) - (dx - originalRect.w); // TODO: this sort of works but when spamming flips translates textarea, todo fix
          textarea.width = dx - originalRect.w;
          flippedX = originalRect.w - dx >= 0;
        }

        if (flippedX || flippedY) {
          textarea.flipTextareaWithControlPoint(flippedX, flippedY);
          return true;
        }

        return false;
      },
    });

    const topRightPoint = createPoint(bounds[1][0], bounds[1][1]);
    const topRight = new TextareaControlPoint({
      point: topRightPoint,
      direction: TextareaControlPointDirections.NorthEast,
      onDrag: (dx, dy, textarea, originalRect) => {
        textarea.y = originalRect.y + dy;
        textarea.width = originalRect.w + dx;
        textarea.height = originalRect.h - dy;

        let flippedX = false;
        let flippedY = false;

        if (!textarea.resizedNegativelyY) {
          textarea.y = originalRect.y + dy;
          textarea.height = originalRect.h - dy;
          flippedY = originalRect.h - dy < 0;
        } else {
          textarea.y = (originalRect.y + originalRect.h) - (dy - originalRect.h); // TODO: this sort of works but when spamming flips translates textarea, todo fix
          textarea.height = dy - originalRect.h;
          flippedY = originalRect.h - dy >= 0;
        }

        if (!textarea.resizedNegativelyX) {
          flippedX = originalRect.w + dx <= 0;
        } else {
          flippedX = originalRect.w + dx > 0;
        }

        if (flippedX || flippedY) {
          textarea.flipTextareaWithControlPoint(flippedX, flippedY);
          return true;
        }

        return false;
      },
    });

    const bottomRightPoint = createPoint(bounds[2][0], bounds[2][1]);
    const bottomRight = new TextareaControlPoint({
      point: bottomRightPoint,
      direction: TextareaControlPointDirections.SouthEast,
      onDrag: (dx, dy, textarea, originalRect) => {
        textarea.width = originalRect.w + dx;
        textarea.height = originalRect.h + dy;

        let flippedX = false;
        let flippedY = false;

        if (!textarea.resizedNegativelyY) {
          flippedY = originalRect.h + dy <= 0;
        } else {
          flippedY = originalRect.h + dy > 0;
        }

        if (!textarea.resizedNegativelyX) {
          flippedX = originalRect.w + dx <= 0;
        } else {
          flippedX = originalRect.w + dx > 0;
        }

        if (flippedX || flippedY) {
          textarea.flipTextareaWithControlPoint(flippedX, flippedY);
          return true;
        }

        return false;
      }
    });

    const bottomLeftPoint = createPoint(bounds[3][0], bounds[3][1]);
    const bottomLeft = new TextareaControlPoint({
      point: bottomLeftPoint,
      direction: TextareaControlPointDirections.SouthWest,
      onDrag: (dx, dy, textarea, originalRect) => {
        textarea.height = originalRect.h + dy;

        let flippedX = false;
        let flippedY = false;

        if (!textarea.resizedNegativelyY) {
          flippedY = originalRect.h + dy <= 0;
        } else {
          flippedY = originalRect.h + dy > 0;
        }

        if (!textarea.resizedNegativelyX) {
          textarea.x = originalRect.x + dx;
          textarea.width = originalRect.w - dx;
          flippedX = originalRect.w - dx < 0;
        } else {
          textarea.x = (originalRect.x + originalRect.w) - (dx - originalRect.w); // TODO: this sort of works but when spamming flips translates textarea, todo fix
          textarea.width = dx - originalRect.w;
          flippedX = originalRect.w - dx >= 0;
        }

        if (flippedX || flippedY) {
          textarea.flipTextareaWithControlPoint(flippedX, flippedY);
          return true;
        }

        return false;
      },
    });

    const right = new TextareaControlPoint({
      point: pointInHalfBetweenPoints(topRightPoint, bottomRightPoint),
      direction: TextareaControlPointDirections.East,
      onDrag: (dx, _, textarea, originalRect) => {
        textarea.width = originalRect.w + dx;
        if (!textarea.resizedNegativelyX) {
          if (originalRect.w + dx <= 0) {
            textarea.flipTextareaWithControlPoint(true, false);
            return true;
          }
        } else {
          if (originalRect.w + dx > 0) {
            textarea.flipTextareaWithControlPoint(true, false);
            return true;
          }
        }
        return false;
      }
    });

    const left = new TextareaControlPoint({
      point: pointInHalfBetweenPoints(topLeftPoint, bottomLeftPoint),
      direction: TextareaControlPointDirections.West,
      onDrag: (dx, _, textarea, originalRect) => {
        if (!textarea.resizedNegativelyX) {
          textarea.x = originalRect.x + dx;
          textarea.width = originalRect.w - dx;
          if (originalRect.w - dx < 0) {
            textarea.flipTextareaWithControlPoint(true, false);
            return true;
          }
        } else {
          textarea.x = (originalRect.x + originalRect.w) - (dx - originalRect.w); // TODO: this sort of works but when spamming flips translates textarea, todo fix
          textarea.width = dx - originalRect.w;
          if (originalRect.w - dx >= 0) {
            textarea.flipTextareaWithControlPoint(true, false);
            return true;
          }
        }
        return false;
      },
    });

    return [topLeft, topRight, bottomRight, bottomLeft, left, right];
  }
}

export class FixedWidthTextarea extends BoxTextarea {
  readonly type = TextareaType.FixedWidth;

  static controlPointChangesType(controlPoint: TextareaControlPoint): boolean {
    return controlPoint.direction !== TextareaControlPointDirections.West &&
      controlPoint.direction !== TextareaControlPointDirections.East;
  }

  constructor(fonts: FontFamilies, options: TextareaOptions) {
    super(fonts, options);
    this._height = this.verticalLength;
  }

  get height(): number {
    if (this.isResizing) {
      if (this.activeControlPoint && !FixedWidthTextarea.controlPointChangesType(this.activeControlPoint)) {
        return this.verticalLength;
      } else {
        return Math.max(this._height, 0);
      }
    } else {
      return this.verticalLength;
    }
  }

  set height(value: number) {
    super.height = value;
  }

  protected shouldRenderGlyph(character: TextCharacter): boolean {
    const rect = this.rect;
    const { y, h } = rect;

    const eop = character.isEOParagraph;
    const partiallyInside = characterBboxPartiallyInsideVisibleBox(character, this, rect);
    const cursorInsideTextureRect = this.cursorPosY >= y && this.cursorPosY <= y + h;

    return (eop || partiallyInside) && cursorInsideTextureRect;
  }
}

export class FixedDimensionsTextarea extends BoxTextarea {
  readonly type = TextareaType.FixedDimensions;

  buildControlPoints(): TextareaControlPoint[] {
    const bounds = this.bounds;

    const top = new TextareaControlPoint({
      point: pointInHalf(bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1]),
      direction: TextareaControlPointDirections.North,
      onDrag: (_, dy, textarea, originalRect) => {
        textarea.y = originalRect.y + dy;
        textarea.height = originalRect.h - dy;

        if (!textarea.resizedNegativelyY) {
          textarea.y = originalRect.y + dy;
          textarea.height = originalRect.h - dy;
          if (originalRect.h - dy < 0) {
            textarea.flipTextareaWithControlPoint(false, true);
            return true;
          }
        } else {
          textarea.y = (originalRect.y + originalRect.h) - (dy - originalRect.h); // TODO: this sort of works but when spamming flips translates textarea, todo fix
          textarea.height = dy - originalRect.h;
          if (originalRect.h - dy >= 0) {
            textarea.flipTextareaWithControlPoint(false, true);
            return true;
          }
        }

        return false;
      }
    });

    const bottom = new TextareaControlPoint({
      point: pointInHalf(bounds[2][0], bounds[2][1], bounds[3][0], bounds[3][1]),
      direction: TextareaControlPointDirections.South,
      onDrag: (_, dy, textarea, originalRect) => {
        textarea.height = originalRect.h + dy;

        if (!textarea.resizedNegativelyY) {
          if (originalRect.h + dy <= 0) {
            textarea.flipTextareaWithControlPoint(false, true);
            return true;
          }
        } else {
          if (originalRect.h + dy > 0) {
            textarea.flipTextareaWithControlPoint(false, true);
            return true;
          }
        }

        return false;
      }
    });

    return [...super.buildControlPoints(), top, bottom];
  }
}

type TextareaTypeMap = {
  [TextareaType.AutoWidth]: AutoWidthTextarea,
  [TextareaType.FixedWidth]: FixedWidthTextarea,
  [TextareaType.FixedDimensions]: FixedDimensionsTextarea,
}

export type ConcreteTextarea<T extends TextareaType> = TextareaTypeMap[T];

export const TEXTAREA_CONSTRUCTOR_MAP = {
  [TextareaType.AutoWidth]: AutoWidthTextarea,
  [TextareaType.FixedWidth]: FixedWidthTextarea,
  [TextareaType.FixedDimensions]: FixedDimensionsTextarea,
};

export const createTextarea = <T extends TextareaType>(fonts: FontFamilies, options: TextareaOptions, skipViewportOperations = false): ConcreteTextarea<T> | undefined => {
  if (fonts.size > 0) {
    const textarea = new TEXTAREA_CONSTRUCTOR_MAP[options.type](fonts, options) as ConcreteTextarea<T>;
    textarea['viewportOperations'] = !skipViewportOperations;
    return textarea;
  } else {
    return undefined;
  }
};

export const isBoxTextarea = (textarea: Textarea): textarea is BoxTextarea => {
  return isBoxTextareaType(textarea.type);
};

export const isBoxTextareaType = (textareaType: TextareaType): boolean => {
  return textareaType === TextareaType.FixedWidth || textareaType === TextareaType.FixedDimensions;
};

const characterBboxPartiallyInsideVisibleBox = (character: TextCharacter, textarea: Textarea, forceRect?: Rect): boolean => {
  if (!character.bbox) return false;
  if (haveNonEmptyIntersection(character.bbox, forceRect ?? textarea.rect)) return true;
  if (character.isWhitespace) return true;
  return false;
};

export const getBaselineIndicatorAlignmentSquareSize = (view: Viewport) => {
  const ratio = getPixelRatio();
  return clamp(TEXTAREA_BASELINE_INDICATOR_ALIGNMENT_SQUARE_SIZE / view.scale / ratio, 2.5 * TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS, 3.5 * TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS);
};

export const insertCharactersIntoTextarea = (textarea: Textarea, selection: TextSelectionDetailed, insertedText: string, panelState?: CharacterFormatting) => {
  // this fn does not take max textarea text length into account! sanitize/validate/truncate this beforehand, it has to comply with the limits at this point!
  const { start, end } = selection;
  const newFullText = textarea.text.slice(0, start) + insertedText + textarea.text.slice(end);

  const newSelection = { ...selection };

  newSelection.start += insertedText.length;
  newSelection.end = newSelection.start;
  newSelection.direction = TextSelectionDirection.None;
  applyTextAndParagraphIndexesToSelection(textarea, newSelection, true);

  // move formattings that are later even later and paste this text without any formatting (then apply copied ones)
  textarea.onInputManagedSelection(textarea.text, newFullText, selection, newSelection, panelState);

  return newSelection;
};

export const removeCharactersFromTextarea = (textarea: Textarea, selection: TextSelectionDetailed) => {
  const newFullText = textarea.text.slice(0, selection.start) + textarea.text.slice(selection.end);
  // it's important to update input value and selection first because onInput will compare them and
  // they need to differ in a way pasted text is appended to "before selection" segment

  const newSelection = { ...selection };
  newSelection.end = newSelection.start;
  newSelection.direction = TextSelectionDirection.None;
  applyTextAndParagraphIndexesToSelection(textarea, newSelection, true);

  // move formattings that are later even later and paste this text without any formatting (then apply copied ones)
  textarea.onInputManagedSelection(textarea.text, newFullText, selection, newSelection, undefined);

  return newSelection;
};

export function assertTextarea(textarea: Textarea | undefined): asserts textarea is Textarea {
  if (!textarea) throw new Error(`Invalid value`);
}

export function sanitizeEmojis(str: string): string {
  return Array.from(str)
    .filter(c => c !== ZERO_WIDTH_JOINER)
    .map(c => c.length > 1 ? REPLACE_EMOJIS_WITH : c)
    .join('');
}

export function countCtrlExtension(textarea: Textarea, caretIndex: number, isForward: boolean, goesForwardNow: boolean, recurisiveCall = false): number {
  let ctrlExtension = 0;
  let index = caretIndex;

  let destinationFound = false;

  while (!destinationFound) {
    const thisChar = textarea.characters[index];
    const nextChar = textarea.characters[index + (goesForwardNow ? 1 : -1)];
    const outOfRange = index < 0 || !thisChar;

    ctrlExtension++;

    // This can be simplified but then it doesn't picture possible cases well so i decided to keep it this way
    if (isForward && goesForwardNow) {
      if (outOfRange || (!thisChar.isWhitespace && (!nextChar || nextChar.isWhitespace))) destinationFound = true;
    } else if (isForward && !goesForwardNow) {
      if (outOfRange || (!thisChar.isWhitespace && (!nextChar || nextChar.isWhitespace))) {
        if (!recurisiveCall) ctrlExtension--;
        destinationFound = true;
      }
    } else if (!isForward && goesForwardNow) {
      if (outOfRange || (!thisChar.isWhitespace && (!nextChar || nextChar.isWhitespace))) destinationFound = true;
    } else {
      if (outOfRange || (!thisChar.isWhitespace && (!nextChar || nextChar.isWhitespace))) {
        if (!recurisiveCall) ctrlExtension--;
        destinationFound = true;
      }
    }

    index = caretIndex + (goesForwardNow ? ctrlExtension : -ctrlExtension);
  }

  if (ctrlExtension === 0) return countCtrlExtension(textarea, caretIndex + (goesForwardNow ? 1 : -1), isForward, goesForwardNow, true);

  return ctrlExtension;
}
