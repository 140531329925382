import { Layer, Drawing, ClientDrawingData, User, SequenceDrawingData, LayerOwner, SequenceDrawing, WorkerDrawingData, Rect, TextLayer, DrawingType, DrawingLoadFailure, DrawingFlags } from './interfaces';
import { findById, includes, removeItem } from './baseUtils';
import { toLayerState, layerFromState, isTextLayer } from './layer';
import { clipRect, copyRect, createRect } from './rect';
import { SEQUENCE_THUMB_HEIGHT, SEQUENCE_THUMB_WIDTH, DEFAULT_DRAWING_DATA, DEFAULT_TEXTURE_TILE_MARING_SIZE, DEFAULT_TEXTURE_TILE_SIZE, DEFAULT_DPI } from './constants';
import { fullName } from './userUtils';
import { cloneDeep } from 'lodash';
import { createBufferedEncoder } from './compressor';
import { createUser } from './user';
import { getBoardBounds } from './board';

export function createDrawing(id: string): Drawing {
  return {
    _id: '',
    id,
    name: '',
    type: DrawingType.Drawing,
    x: 0,
    y: 0,
    w: 100,
    h: 100,
    background: '#ffffff',
    layers: [],
    permissions: {},
    dpi: DEFAULT_DPI,
    // other
    password: undefined,
    hasAdmins: false,
    justImported: false,
    pro: false,
    respectOfflineOwners: false,
    canvas: undefined,
    sequence: [],
    sequenceId: undefined,
    sequenceMainDrawingId: undefined,
    layersPerUser: 0,
    thumbUpdate: undefined,
    isRevision: false,
    team: undefined,
    project: undefined,
    folder: undefined,
    featureFlags: [],
    permissionFlags: undefined,
    shareType: undefined,
    promptHistory: undefined,
    flags: DrawingFlags.None,
    creator: { name: '', id: '' },
    tags: [],
    readOnly: false,
    // connection
    connId: 0,
    socket: undefined,
    isClosed: false,
    loadingPromise: undefined,
    sendingHistory: false,
    drawingHistory: [],
    waitingActions: [],
    toolPromise: undefined,
    insideCommitLoop: false,
    toolCounter: 0,
    toolStarted: false,
    encoder: createBufferedEncoder(),
    nextToolFlushTime: 0,
    pendingLayerOwns: new Set(),
    selectLayerOnOwn: 0,
    lastCropRect: createRect(0, 0, 0, 0),
    lastReorder: [],
    sentTools: 0,
    confirmedTools: 0,
    hasBufferedStart: false,
    startConnId: 0,
    startLayerId: 0,
    startTool: undefined,
    startX: 0,
    startY: 0,
    startP: 0,
    clearSendingChunksId: 0,
    dataId: 0,
    loaded: 0,
    loadingFailed: DrawingLoadFailure.None,
    sending: false,
    sendingQueue: [],
    user: createUser('', 0),
    users: [],
    tempUsers: [],
    toolStats: {},
    personalVisibilityStates: new Map(),
    // webgl renderer fields
    lod: 1,
    tileMarginSize: TESTS ? 4 : DEFAULT_TEXTURE_TILE_MARING_SIZE,
    tileSize: TESTS ? 120 : DEFAULT_TEXTURE_TILE_SIZE,
    tiles: { ox: 0, oy: 0, tiles: [[]] },
    visibleRect: createRect(0, 0, 0, 0),
    renderedRect: createRect(0, 0, 0, 0),
    dirtyRect: createRect(0, 0, 0, 0),
    thumbDirtyRect: createRect(0, 0, 0, 0),
  };
}

export function createDrawingFromData(data: ClientDrawingData): Drawing {
  const drawing = createDrawing(data.id);
  setupDrawingFromData(drawing, data);
  return drawing;
}

export function setupDrawingFromData(drawing: Drawing, data: ClientDrawingData) {
  const { _id, id, name, layerOwners } = data;

  drawing._id = _id;
  drawing.id = id;
  drawing.name = name;
  drawing.type = data.type;
  drawing.shareType = data.shareType;
  drawing.x = data.x;
  drawing.y = data.y;
  drawing.w = data.w;
  drawing.h = data.h;
  drawing.background = data.background;
  drawing.dpi = data.dpi;
  drawing.password = data.password;
  drawing.hasAdmins = data.hasAdmins ?? false;
  drawing.respectOfflineOwners = data.respectOfflineOwners ?? false;
  drawing.layersPerUser = data.layersPerUser ?? 0;
  drawing.pro = data.pro ?? false;
  drawing.justImported = data.justImported ?? false;
  drawing.team = data.team;
  drawing.project = data.project;
  drawing.folder = data.folder;
  drawing.featureFlags = [...data.featureFlags ?? []];
  drawing.permissions = data.permissions ? cloneDeep(data.permissions) : {};
  drawing.permissionFlags = [...data.permissionFlags ?? []];
  drawing.layers = data.layers.map(l => layerFromState(l));
  drawing.sequence = data.sequence?.map(createSequenceDrawing) ?? [{ _id, id, name, users: [] }];
  drawing.sequenceId = data.sequenceId;
  drawing.sequenceMainDrawingId = data.sequenceMainDrawingId;
  drawing.promptHistory = cloneDeep(data.promptHistory);
  drawing.toolStats = data.toolStats ? cloneDeep(data.toolStats) : {};
  drawing.flags = data.flags ?? DrawingFlags.None;
  drawing.jam = data.jam;
  drawing.tags = data.tags ?? [];
  drawing.readOnly = data.readOnly ?? false;
  drawing.isRevision = !!data.isRevision;
  drawing.licensing = cloneDeep(data.licensing);
  drawing.publishedIn = cloneDeep(data.publishedIn);
  drawing.inheritedLicense = cloneDeep(data.inheritedLicense);
  drawing.participants = cloneDeep(data.participants);

  if (data.creator) {
    drawing.creator = data.creator;
  }

  if (layerOwners) {
    const layerToOwner = new Map<number, LayerOwner>();

    for (const o of layerOwners) {
      for (const layerId of o.left) {
        layerToOwner.set(layerId, { name: o.name, color: o.color, left: true });
      }
    }

    for (const o of layerOwners) {
      for (const layerId of o.layers) {
        layerToOwner.set(layerId, { name: o.name, color: o.color, left: false });
      }
    }

    for (const layer of drawing.layers) {
      layer.layerOwner = layerToOwner.get(layer.id);
    }
  }

  if (drawing.type === DrawingType.Board) {
    copyRect(drawing, getBoardBounds(drawing));
  }

  if (data.personalVisibilityStates !== undefined) {
    for (const state of data.personalVisibilityStates) {
      drawing.personalVisibilityStates.set(state.layerId, state.visible);
      const layer = drawing.layers.find((layer) => layer.id === state.layerId);
      if (layer) {
        layer.visibleLocally = state.visible;
      }
    }
  }

  return drawing;
}

export function createSequenceDrawing({ _id, id, name, cacheId }: SequenceDrawingData): SequenceDrawing {
  return { _id, id, name, users: [], cacheId };
}

export function reassignSequenceDrawings(current: Drawing, old: Drawing) {
  if (!current.sequence || !old.sequence) return;

  for (const drawing of current.sequence) {
    const found = findById(old.sequence, drawing.id);
    if (found) {
      // do not reassign name, it can contain old value
      const { name, ...rest } = found;
      Object.assign(drawing, rest);
    }
  }

  // copy placeholders if drawing was changed in the same sequence
  if (current.sequenceId === old.sequenceId) {
    for (const drawing of old.sequence) {
      if (drawing.id.startsWith('placeholder-')) {
        const drawingId = drawing.id.split('-')[1];
        const index = old.sequence.map(d => d.id).indexOf(drawingId);
        if (index >= 0) {
          current.sequence.splice(index + 1, 0, drawing);
        }
      }
    }
  }
}

export function createTestDrawing(data: Partial<ClientDrawingData>) {
  return createDrawingFromData({ ...DEFAULT_DRAWING_DATA, ...data });
}

// used in worker
export function createWorkerDrawingState(drawing: Drawing): WorkerDrawingData {
  const { _id, id, type, name, w, h, background, layers, dpi, x, y } = drawing;
  return { _id, id, type, name, w, h, x, y, background, dpi, layers: layers.map(toLayerState) };
}

export function hasAllFontsLoaded(drawing: Drawing) {
  return !drawing.layers.some(l => isTextLayer(l) && !l.fontsLoaded);
}

export function forAllTextLayers(drawing: Drawing, fn: (layer: TextLayer) => void) {
  for (const l of drawing.layers) {
    if (isTextLayer(l)) fn(l);
  }
}

export function hasFreeLayers(drawing: Drawing) {
  return drawing.layers.some(l => !l.owner);
}

export function getLayer(drawing: { layers: Layer[] }, id: number): Layer | undefined {
  return findById(drawing.layers, id);
}

export function getAboveLayer(drawing: Drawing, layer: Layer): Layer | undefined {
  const index = drawing.layers.indexOf(layer) - 1;
  return index >= 0 ? drawing.layers[index] : undefined;
}

export function getBelowLayer(drawing: Drawing, layer: Layer): Layer | undefined {
  const index = drawing.layers.indexOf(layer) + 1;
  return index < drawing.layers.length ? drawing.layers[index] : undefined;
}

export function getLayerSafe(drawing: Drawing, id: number) {
  const layer = getLayer(drawing, id);

  if (!layer) throw new Error(`Missing layer (${id})`);

  return layer;
}

export function getNewLayerName(drawing: Drawing | ClientDrawingData) {
  let id = drawing.layers.length;
  let name: string;

  do {
    id++;
    name = `Layer #${id}`;
  } while (drawing.layers.some(l => l.name === name));

  return name;
}

export function hasOwner(layer: Layer) {
  return !!layer.owner || !!(layer.layerOwner && !layer.layerOwner.left);
}

export function setLayerOwner(layer: Layer, user: User | undefined, removeLayerOwner = false) {
  if (layer.owner && layer.owner !== user) {
    removeItem(layer.owner.ownedLayers, layer.id);
  }

  layer.owner = user;

  if (user) {
    layer.layerOwner = { name: fullName(user), color: user.color, left: false };
  } else if (removeLayerOwner && layer.layerOwner) {
    layer.layerOwner = { ...layer.layerOwner, left: true };
  }

  if (user && !includes(user.ownedLayers, layer.id)) {
    user.ownedLayers.push(layer.id);
  }
}

export function setOwnedLayer(drawing: Drawing, layerId: number, user: User | undefined) {
  const layer = getLayer(drawing, layerId);

  if (layer) {
    setLayerOwner(layer, user);
  } else if (user && !includes(user.ownedLayers, layerId)) {
    user.ownedLayers.push(layerId);
  }
}

export function getSequenceThumbSize(drawing: Drawing) {
  const scale = Math.min(SEQUENCE_THUMB_WIDTH / drawing.w, SEQUENCE_THUMB_HEIGHT / drawing.h);
  const width = Math.ceil(drawing.w * scale);
  const height = Math.ceil(drawing.h * scale);
  return { width, height };
}

export function clipToDrawingRect(rect: Rect, drawing: Rect) {
  clipRect(rect, drawing.x, drawing.y, drawing.w, drawing.h);
}

export function getMainDrawingId(drawing: { id: string; sequenceMainDrawingId?: string; } | undefined) {
  return drawing?.sequenceMainDrawingId ?? drawing?.id;
}

export function getLayersOwnedByAccount(drawing: Drawing, accountId: string | undefined) {
  if (!accountId) return [];
  return drawing.layers.filter(l => l.owner && l.owner.accountId === accountId);
}

export function updateDrawingPersonalVisibility(drawing: Drawing, layerId: number, visible: boolean | undefined) {
  if (visible === undefined) {
    drawing.personalVisibilityStates.delete(layerId);
  } else {
    drawing.personalVisibilityStates.set(layerId, visible);
  }
}
