import { ITool, IToolEditor, IToolModel, TabletEvent, ToolId, hasShiftKey, hasCtrlOrMetaKey, CursorType } from '../interfaces';
import { faEyeDropper } from '../icons';
import { keys } from '../baseUtils';

export class EyedropperTool implements ITool {
  id = ToolId.Eyedropper;
  name = 'Eyedropper';
  description = 'Pick colors from your drawing';
  learnMore = 'https://help.magma.com/en/articles/6871453-eyedropper';
  video = { url: '/assets/videos/eyedropper.mp4', width: 374, height: 210 };
  icon = faEyeDropper;
  cursor = CursorType.Eyedropper;
  navigation = true;
  usesModifiers = true;
  activeLayer = false;
  alphaTo = 'none';
  fields = keys<EyedropperTool>(['activeLayer', 'alphaTo']);
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  start(x: number, y: number, pressure: number, e: TabletEvent) {
    this.move(x, y, pressure, e);
  }
  move(x: number, y: number, _pressure: number, e: TabletEvent) {
    const pick = this.editor.pickColor;
    pick.do = true;
    pick.x = Math.floor(x);
    pick.y = Math.floor(y);
    pick.activeLayer = hasCtrlOrMetaKey(e) ? !this.activeLayer : this.activeLayer;
    pick.secondary = hasShiftKey(e);
    pick.alphaTo = this.alphaTo;
  }
  end(x: number, y: number, pressure: number, e: TabletEvent) {
    this.move(x, y, pressure, e);
  }
}
