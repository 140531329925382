import { CursorType, ITool, IToolEditor, IToolModel, Permission, ToolError, ToolId } from '../interfaces';
import { faCommentAlt } from '../icons';
import { hasFlags } from '../utils';
import { hasPermission } from '../userRole';
import { logAction } from '../actionLog';

export class CommentTool implements ITool {
  id = ToolId.Comment;
  name = 'Comment';
  description = 'Leave notes and feedback on the canvas';
  learnMore = 'https://help.magma.com/en/articles/6871513-comment';
  video = { url: '/assets/videos/comment.mp4', width: 374, height: 210 };
  icon = faCommentAlt;
  cursor = CursorType.SelectionAdd;
  nonDrawing = true;
  onlyPortal = true;
  constructor(public editor: IToolEditor, public model: IToolModel) { }
  start(_x: number, _y: number, _pressure: number) { }
  move(_x: number, _y: number, _pressure: number) { }
  end(x: number, y: number, _pressure: number) {
    logAction('[local] comment');
    this.model.threads?.newThread({ x, y });
  }
  canStart() {
    const { drawing, user } = this.model;
    if (drawing.team) {
      return !hasFlags([Permission.CanCreateThreadsAndComments], drawing.permissionFlags ?? []) ? ToolError.AccessDenied : ToolError.NoError;
    } else {
      return !hasPermission(drawing, user, 'createComments') ? ToolError.AccessDenied : ToolError.NoError;
    }
  }
}
