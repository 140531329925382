import { Component } from '@angular/core';
import { faEnvelope, socialIcons, magmaIcon } from '../../../../common/icons';

@Component({
  selector: 'sign-up-mini',
  templateUrl: 'sign-up-mini.pug',
  styleUrls: ['sign-up-mini.scss'],
})
export class SignUpMini {
  readonly magmaIcon = magmaIcon;
  readonly linkedInIcon = socialIcons.linkedin;
  readonly googleIcon = socialIcons.google;
  readonly discordIcon = socialIcons.discord;
  readonly envelopeIcon = faEnvelope;

  // TODO: not implemented, planned in close future

  auth(provider: string){
    alert(provider);
  }
}
