import { getEntityPassword } from 'magma/common/clientUtils';
import { LicenseTermId, LicenseRights } from 'magma/common/interfaces';


export function getAllParentsOfElement(element: HTMLElement): HTMLElement[] {
  const parentOfElement = element.parentElement;

  if (!parentOfElement) {
    return [];
  }

  return [parentOfElement, ...getAllParentsOfElement(parentOfElement)];
}

export function isElementInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect();

  return rect.top >= 0 && rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

export function generateEntityDownloadUrl(shortId: string, type: string, cacheId?: string) {
  const password = getEntityPassword(shortId);
  let url = `/${shortId}.${type}`;
  if (cacheId) url += `?cacheId=${cacheId}`;
  if (password) url += `${cacheId ? '&' : '?'}password=${password}`;
  return url;
}

export function generateLiveEntityDownloadUrl(shortId: string, type: 'png' | 'jpg' | 'psd' | 'zip') {
  const password = getEntityPassword(shortId);
  let url = `/api/render/${shortId}/${type}`;
  if (password) url += `&password=${password}`;
  return url;
}

export function generateSharedImageUrl(id: string) {
  return `${window.location.origin}/shared/${id}`;
}

export function generateAiThumbnailImageUrl(id: string) {
  return `${window.location.origin}/api/ai/job/thumbnail/${id}`;
}

// to trigger file download from js
export function downloadUrl(url: string) {
  const link = document.createElement('a');
  link.setAttribute('download', '');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function setOgEntityName(entityName: string) {
  document.querySelector('meta[property="magma:entityName"]')?.setAttribute('content', entityName);
}

const firstBytesOfEveryPng = [137, 80, 78, 71, 13, 10, 26, 10];
export async function isPngFile(file: File) {
  const fileBytes = new Uint8Array(await file.arrayBuffer());
  for (let i = 0; i < firstBytesOfEveryPng.length; i++) {
    if (fileBytes[i] !== firstBytesOfEveryPng[i]) return false;
  }
  return true;
}

export async function isJpgFile (file: File) {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.onload = () => resolve(true);
      image.onerror = () => resolve(false);
      image.src = (window.URL || window.webkitURL).createObjectURL(file);
    } catch (e) {
      resolve(false);
    }
  });
}

export async function isImageFile (file: File) {
  if (!(file.type?.includes('image/'))) return false;
  const isJpg = isJpgFile(file);
  const isPng = isPngFile(file);
  const results = await Promise.all([isJpg, isPng]);
  return results.some(r => r === true);
}

export function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => { resolve(reader.result); };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export let defaultLicenseRights: { [key in LicenseTermId]: LicenseRights } | undefined = undefined;
export function setDefaultStoryLicenseRights(rights: { [key in LicenseTermId]: LicenseRights }) {
  defaultLicenseRights = rights;
}
export function getDefaultStoryLicenseRights() {
  return defaultLicenseRights;
}
