import { Drawing, IToolEditor, Layer, User } from './interfaces';
import { getLayer, setLayerOwner } from './drawing';
import { clamp } from './mathUtils';
import { removeItem } from './baseUtils';
import { redrawLayerThumb } from './layer';
import { logAction } from './actionLog';
import { userOwnsLayer } from './user';
import { selectLayer, selectOtherLayer } from '../services/layerActions';
import type { Editor } from '../services/editor';

// NOTE: always use sendLayerOrder() after calling addLayerToDrawing() for [local] calls
export function addLayerToDrawing(editor: IToolEditor, user: User, drawing: Drawing, layer: Layer, index: number) {
  layer.loaded = true;
  index = clamp(index, 0, drawing.layers.length);
  drawing.layers.splice(index, 0, layer);

  if (editor.type === 'client') {
    setLayerOwner(layer, getLayerOwner(drawing, layer.id));
    redrawLayerThumb(layer, true);

    if (user === drawing.user && layer.owner === user && !user.activeLayer) {
      selectLayer(editor, user, drawing, layer);
    }

    layer.visibleLocally = drawing.personalVisibilityStates?.get(layer.id);

    if (layer.ref) (editor as Editor).model.updateConnection();
  }

  editor.apply(() => { }); // redraw layer list
}

export function removeLayerFromDrawing(editor: IToolEditor, user: User, drawing: Drawing, layerId: number) {
  const layer = getLayer(drawing, layerId);

  if (layer) {
    if (editor.type === 'client' && user === drawing.user && layer === user.activeLayer) {
      selectOtherLayer(editor, user, drawing, layerId);
    }

    editor.renderer.releaseLayer(layer);
    removeItem(drawing.layers, layer);

    if (editor.type === 'client' && layer.ref) (editor as Editor).model.updateConnection();
  } else {
    DEVELOPMENT && console.error(`missing layer for removal (layerId: ${layerId})`);
    logAction(`missing layer for removal (layerId: ${layerId})`);
  }

  editor.apply(() => { });
}

function getLayerOwner(drawing: Drawing, layerId: number): User | undefined {
  if (userOwnsLayer(drawing.user, layerId)) {
    return drawing.user;
  } else {
    return drawing.users.find(u => userOwnsLayer(u, layerId));
  }
}
