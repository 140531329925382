import { TeamBillingPendingChangesModalInput } from './../components/modals/team-billing-pending-changes-modal/team-billing-pending-changes-modal.component';
import { BillingInterval, CreateRole, EntityData, ProjectData, TeamData } from 'shared/interfaces';
import { CancelSubscriptionModalData } from 'components/modals/cancel-subscription-modal/cancel-subscription-modal.component';
import { CreatorInfoService } from './creator-info.service';
import { DeleteConfirmationModalInput } from 'components/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { DeleteTeamMemberModalInput } from '../components/modals/delete-team-member-modal/delete-team-member-modal.component';
import { EntitiesService } from './entities.service';
import { ExportEntityModalInput } from './../components/modals/export-entity-modal/export-entity-modal.component';
import { FolderNamingModalInput } from 'components/modals/folder-naming-modal/folder-naming-modal.component';
import { Injectable } from '@angular/core';
import { LeaveTeamModalInput } from '../components/modals/leave-team-member-modal/leave-team-modal.component';
import { Modals } from 'magma/services/modals';
import { Model } from 'magma/services/model';
import { PermissionFlagsModalInput } from '../components/permission-flags-modal/permission-flags-modal.component';
import { ProjectQuery } from './projects.query';
import { RouterService } from './router.service';
import { SelectFolderModalInput } from 'components/modals/select-folder-modal/select-folder-modal.component';
import { Analytics, EntityType, ProjectType, SelectedFolder, SequenceDrawing, SurveyId, ArtworkLicensingData, RequestArtworkRegistrationResponse } from 'magma/common/interfaces';
import { ShareEntityAsImageModalInput } from 'components/modals/share-entity-as-image-modal/share-entity-as-image-modal.component';
import { ShareEntityModalInput } from 'components/modals/share-entity-modal/share-entity-modal.component';
import { TeamInviteMembersInput } from 'components/modals/team-invite-members/team-invite-members.component';
import { TeamJoinModalInput } from '../components/modals/team-join-modal/team-join-modal.component';
import { TeamService } from './team.service';
import { TeamsQuery } from './team.query';
import { ToastService } from 'magma/services/toast.service';
import { TransferTeamOwnershipModalInput } from '../components/modals/transfer-team-ownership-modal/transfer-team-ownership-modal.component';
import { UserRolesModalInput } from '../components/user-roles-modal/user-roles-modal.component';
import { getEntityPassword } from 'magma/common/clientUtils';
import { CreateTeamSubscriptionModalInput } from 'components/modals/create-team-subscription-modal/create-team-subscription-modal.component';
import { TeamBillingChangeIntervalModalInput } from 'components/modals/team-billing-change-interval-modal/team-billing-change-interval-modal.component';
import { findIndexById } from 'magma/common/utils';
import { UserService } from './user.service';
import { TrackService } from './track.service';
import { DeleteTeamConfirmationModalInput } from 'components/modals/delete-team-confirmation-modal/delete-team-confirmation-modal.component';
import { isReading, PostModalData } from '../components/modals/post-modal/post-modal.component';
import { QueueAsyncJobModalInput } from 'components/modals/queue-async-job-modal/queue-async-job-modal.component';
import { AiModel } from 'magma/common/aiInterfaces';
import { PostType } from '../../../shared/posts';
import { invalidEnum } from 'magma/common/baseUtils';
import { SubscriptionPlanType } from 'shared/billing';
import { UpgradeTeamPlansComponentInput } from 'components/modals/upgrade-team-plans/upgrade-team-plans.component';
import { RegisterArtworkData, RegisterArtworkModalOutputData } from '../../../shared/interfaces';
import { ProjectShareTypes } from 'shared/constants';
import { JamsJoinModalInput, JamsModalInput, JamsModalOutput, UserReportModalInput } from 'magma/common/interfaces-jams';


@Injectable({ providedIn: 'root' })
export class ModalService extends Modals {
  constructor(
    private entitiesService: EntitiesService,
    private toastService: ToastService,
    private teamsQuery: TeamsQuery,
    private projectQuery: ProjectQuery,
    private teamService: TeamService,
    private creatorInfoService: CreatorInfoService,
    private routerService: RouterService,
    private userService: UserService,
    private trackService: TrackService,
  ) {
    super();
  }

  createProject(team: string, data: Partial<ProjectData> = {}) {
    const project: ProjectData = { _id: '', name: '', team, type: ProjectType.Project, shareType: ProjectShareTypes.VISIBLE_TO_MEMBERS, ...data };
    return this.openByName<ProjectData>('createProject', { data: { ...project } });
  }

  editProject(project: ProjectData) {
    return this.openByName<ProjectData>('createProject', { data: { ...project } });
  }

  editProjectDescription(project: ProjectData) {
    return this.openByName<ProjectData>('updateProjectDescription', { data: { ...project } });
  }

  openProjectDescription(project: ProjectData) {
    return this.openByName<ProjectData>('openProjectDescription', { data: { ...project } });
  }

  deleteProject(project: ProjectData) {
    return this.openByName<ProjectData>('deleteProject', { data: { ...project } });
  }

  cancelSubscription(data: CancelSubscriptionModalData) {
    return this.openByName<boolean>('cancelSubscription', { data });
  }

  changePlan(currentInterval: BillingInterval | undefined, isTrial: boolean) {
    return this.openByName<BillingInterval>('changePlan', { data: { currentInterval, isTrial } });
  }

  renameEntity(data: FolderNamingModalInput) {
    return this.openByName<string>('renameEntity', { data });
  }

  addDrawingReferenceWindow(data: string) {
    return this.openByName<string>('addDrawingReferenceWindow', { data });
  }

  deleteEntity(data: DeleteConfirmationModalInput) {
    return this.openByName<boolean>('deleteEntity', { data });
  }

  deleteTeam(data: DeleteTeamConfirmationModalInput) {
    return this.openByName<boolean>('deleteTeam', { data });
  }

  teamDeletionReason(data: DeleteTeamConfirmationModalInput) {
    return this.openByName<string | undefined>('teamDeletionReason', { data });
  }

  shareEntityAsImage(data: ShareEntityAsImageModalInput) {
    return this.openByName<void>('shareEntityAsImage', { data });
  }

  shareEntity(data: ShareEntityModalInput) {
    return this.openByName<void>('shareEntity', { data });
  }

  selectFolder(data: SelectFolderModalInput) {
    return this.openByName<SelectedFolder>('selectFolder', { data });
  }

  exportEntity(data: ExportEntityModalInput) {
    return this.openByName<void>('exportEntity', { data });
  }

  removeTeamMember(data: DeleteTeamMemberModalInput) {
    return this.openByName<void>('removeTeamMember', { data });
  }

  banTeamMember(data: DeleteTeamMemberModalInput) {
    return this.openByName<void>('banTeamMember', { data });
  }

  unbanUserFromTeam(data: DeleteTeamMemberModalInput) {
    return this.openByName<void>('unbanTeamMember', { data });
  }

  transferTeamOwnership(data: TransferTeamOwnershipModalInput) {
    return this.openByName<void>('transferTeamOwnership', { data });
  }

  leaveTeam(data: LeaveTeamModalInput) {
    return this.openByName<void>('leaveTeam', { data });
  }

  inviteTeamMembers(data: TeamInviteMembersInput) {
    return this.openByName<void>('inviteTeamMembers', { data });
  }

  joinTeam(data: TeamJoinModalInput) {
    return this.openByName<void>('joinTeam', { data });
  }

  editRole(data: PermissionFlagsModalInput) {
    return this.openByName<PermissionFlagsModalInput>('permissionFlags', { data });
  }

  createRole() {
    return this.openByName<CreateRole>('permissionFlags', { data: { create: true } });
  }

  setRoles(data: UserRolesModalInput) {
    return this.openByName<string[]>('userRoles', { data });
  }

  createTeamSubscriptionModal(data: CreateTeamSubscriptionModalInput) {
    return this.openByName<BillingInterval>('createTeamSubscriptionModal', { data });
  }

  teamBillingChangeInterval(data: TeamBillingChangeIntervalModalInput) {
    return this.openByName<BillingInterval>('teamBillingChangeInterval', { data });
  }

  teamBillingPendingChanges(data: TeamBillingPendingChangesModalInput) {
    return this.openByName<BillingInterval>('teamBillingPendingChangesInterval', { data });
  }

  openOtherPlansModal(data: UpgradeTeamPlansComponentInput) {
    return this.openByName<void>('upgradeTeamSubscriptionModal', { class: 'modal-xl', data });
  }

  moveToBinEntity(data: DeleteConfirmationModalInput) {
    return this.openByName<boolean>('moveToBinEntity', { data });
  }

  openDeletedFolder(data: DeleteConfirmationModalInput) {
    return this.openByName<boolean>('openDeletedFolder', { data });
  }

  storageLimitExceeded(userId: string, userPro: boolean | undefined, team: TeamData | undefined, numberOfDrawings: number) {
    const data = { userPro, team, userId, numberOfDrawings };
    return this.openByName<boolean>('storageLimitExceeded', { data });
  }

  announcement() {
    void this.openByName('announcement');
  }

  openPostModal(data: PostModalData) {
    let modalClass = undefined;
    if (isReading(data)) {
      if (data.post.type === PostType.Article) modalClass = 'modal-lg' as const;
      this.trackService.event(Analytics.OpenPostModal, data.analytics);
    }
    return this.openByName('postModal', { data, class: modalClass });
  }

  upgradeModal(openedBy: string, type: 'individual' | 'team' = 'individual', teamId?: string, plan?: SubscriptionPlanType) {
    // this modal can be opened before view is initialized, so wait until we have modal template
    this.waitForTemplate('upgrade')
      .then(() => this.openByName('upgrade', { data: { openedBy, type, forceTeamId: teamId, plan }, class: 'modal-lg' }))
      .catch(e => DEVELOPMENT && console.error(e));
  }

  stephenSilverShapesModal() {
    const feature = 'silver-academy';
    const user = this.userService.user;
    if (IS_HOSTED || !user) return false;
    if (!user.hideNewFeatureNotifications && user.newFeature === feature && user.lastNewFeature !== feature) {
      void this.openByName('stephenSilverShapes');
    }
  }

  // currently not used, keep it here to reususe in future
  newBrushes() {
    void this.openByName('newBrushes', { class: 'modal-lg' });
  }

  createTeam() {
    return this.openByName('createTeam');
  }

  closeAllMarketingModals() {
    this.closeByName('stephenSilverShapes');
    this.closeByName('newBrushes');
    this.closeByName('announcement');
    this.closeByName('newFeature');
  }

  closeAllPresentationModeModals() {
    this.closeByName('presentationActionModal');
  }
  // helpers for editor

  private entityFromModel(shortId: string, model: Model): EntityData | undefined {
    if (model.editor!.drawing.id === shortId) {
      const drawing = model.editor!.drawing;

      return {
        _id: drawing._id,
        shortId: drawing.id,
        type: EntityType.Drawing,
        name: drawing.name,
        folder: drawing.folder,
        project: drawing.project,
        team: drawing.team,
        password: getEntityPassword(drawing.id),
        hasPassword: !!getEntityPassword(drawing.id),
        jam: drawing.jam
      };
    } else {
      const drawing = model.editor!.drawing;
      const sequenceDrawing = drawing.sequence?.find(d => d.id === shortId);
      if (!sequenceDrawing) return undefined;

      return {
        _id: sequenceDrawing._id,
        shortId: sequenceDrawing.id,
        type: EntityType.Drawing,
        name: sequenceDrawing.name,
        folder: drawing.folder,
        project: drawing.project,
        team: drawing.team,
        password: getEntityPassword(sequenceDrawing.id),
        hasPassword: !!getEntityPassword(sequenceDrawing.id),
        jam: drawing.jam
      };
    }
  }

  private getNextImageInSequence(model: Model): SequenceDrawing | undefined {
    const { id, sequence } = model?.editor?.drawing || {};
    if (!id || !sequence) return;
    const currentIndex = findIndexById(sequence, id);
    const nextIndex = currentIndex === 0 ? 1 : currentIndex - 1;
    return sequence[nextIndex];
  }

  async shareEntityEditor(shortId: string, model: Model, openedBy: string) {
    const entity = this.entityFromModel(shortId, model);
    if (!entity) return;
    const team = this.teamsQuery.getActive();
    return this.shareEntity({ entity, openedBy, model, team });
  }

  async exportWithModal(shortId: string, model: Model) {
    const drawing = model.editor!.drawing;
    const entity = {
      _id: drawing._id,
      shortId,
      type: EntityType.Drawing,
      name: drawing.name,
      folder: drawing.folder,
      project: drawing.project,
      team: drawing.team,
      password: getEntityPassword(drawing.id),
    };
    const isPro = this.teamService.isUserOrTeamPro(entity.team);
    const hasAccess = this.teamService.canUserExportEntity(entity);
    const error = hasAccess ? undefined : (
      entity.password ? `This drawing is password protected` : `You don't have access to export this drawing`);

    return this.exportEntity({ isPro, entityShortId: shortId, cacheId: '', openedBy: 'version-history-popup', error });
  }

  async deleteEntityEditor(shortId: string, model: Model, navigate: boolean, openedBy: string) {
    const entity = this.entityFromModel(shortId, model);
    if (!entity) return;
    const shouldDelete = await this.moveToBinEntity({ entity, openedBy });
    if (!shouldDelete) return false;

    const deleted = await this.entitiesService.moveToBinWithToast(entity);
    if (deleted && navigate) {
      const nextImage = this.getNextImageInSequence(model);

      if (nextImage) {
        this.routerService.navigateToDrawing(nextImage.id, 'delete entity editor');
      } else {
        const teamSlug = this.teamService.getTeamSlug(entity.team);
        const projectId = typeof entity.project === 'string' ? entity.project : entity.project?._id;
        this.routerService.navigateToFolder(teamSlug, projectId, entity.folder);
      }
    }
  }

  async duplicateEntityEditor(shortId: string, model: Model, _openedBy: string) {
    const entity = this.entityFromModel(shortId, model);
    if (!entity) return;
    const clonedEntity = await this.entitiesService.cloneEntityWithToast(entity);
    return clonedEntity?.shortId;
  }

  async createNewFolder(parentFolderId: string | undefined) {
    try {
      const name = await this.renameEntity({ header: 'New folder', label: 'folder name' });

      if (name) {
        const project = this.projectQuery.getActive();
        await this.entitiesService.createNewFolder(project?._id, parentFolderId, name, 'my-drawer');
        return true;
      }
    } catch (e) {
      this.toastService.error({ message: e.message });
    }

    return false;
  }

  creator(id: string, items?: any[], model?: Model) {
    this.creatorInfoService.getCreatorInfo(id)
      .then(creator => this.openByName('creator', { data: { creator: { ...creator, items }, model }, class: 'modal-lg' }))
      .catch(e => DEVELOPMENT && console.error(e));
  }

  switchingToPublic(team: TeamData) {
    return this.openByName<boolean>('switchingToPublic', { data: { team } });
  }

  survey(surveyId: SurveyId) {
    openTypeformPopup(this.userService.user?._id ?? '', this.trackService, this.userService, surveyId);
  }

  queueAsyncJob(data: QueueAsyncJobModalInput | undefined) {
    return this.openByName<void>('queueAsyncJob', { data, class: 'modal-xl' });
  }

  editAiModel(data: AiModel) {
    return this.openByName<void>('editAiModel', { data: { data, mode: 'edit' } });
  }

  uploadAiModel(file?: File) {
    return this.openByName<void>('editAiModel', { data: { file, mode: 'upload' } });
  }

  createAiModel(data: Omit<AiModel, 'owner'>) {
    return this.openByName<void>('editAiModel', { data: { data, mode: 'create' } });
  }

  idleLogoutWarning() {
    return this.openByName<void>('idleLogoutModal');
  }

  registerArtwork(data: RegisterArtworkData): Promise<RegisterArtworkModalOutputData | undefined> {
    return this.openByName('registerArtwork', { data });
  }
  openArtworkLicense(data: ArtworkLicensingData) {
    return this.openByName('artworkLicensing', { data });
  }
  openArtworkPendingLicense(data: ArtworkLicensingData & { status?: number }) {
    return this.openByName('artworkPendingLicense', { data });
  }
  openRequestingRegistrationNotification(data: ArtworkLicensingData): Promise<RequestArtworkRegistrationResponse | null | undefined> {
    return this.openByName('requestingRegistrationNotification', { data });
  }
  async createJam() {
    const res = await this.openByName<JamsModalOutput | undefined>('jams', { overflow: true });
    return res?.create;
  }

  async editJam(data: JamsModalInput) {
    const res = await this.openByName<JamsModalOutput | undefined>('jams', { data, overflow: true });
    return res?.update;
  }

  joinJam(data: JamsJoinModalInput) {
    return this.openByName<void>('jamsJoin', { data });
  }

  createUserReport(data: UserReportModalInput) {
    return this.openByName<void>('userReport', { data });
  }

  setUserBirthdate() {
    return this.openByName<Date | undefined>('userBirthdate');
  }
}

let addedTypeform = false;

function initTypeform() {
  if (!addedTypeform) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '//embed.typeform.com/next/css/popup.css';
    document.head.appendChild(link);
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    document.head.appendChild(script);
    addedTypeform = true;
  }
}

// @ignore-translation
function openTypeformPopup(userId: string, track: TrackService, userService: UserService, surveyId: SurveyId) {
  initTypeform();

  let tries = 100;
  let interval: any = 0;

  interval = setInterval(() => {
    if (tries-- < 0) clearInterval(interval);

    let typeformId = '';
    switch (surveyId) {
      case SurveyId.Nps: {
        const npsForms = ['yePZI48I', 'pgUFrGPh', 'LJDN28dS'];
        typeformId = npsForms[Math.floor(Math.random() * npsForms.length)];
        break;
      }
      case SurveyId.Ai: {
        typeformId = 'YT6mIYwn';
        break;
      }
      default: invalidEnum(surveyId);
    }

    if ('tf' in window && typeformId) {
      const popup: any = (window as any).tf.createPopup(typeformId, {
        hidden: { id: userId },
        onReady: () => {
          track.event(Analytics.SurveyOpen);
        },
        onSubmit: () => {
          track.event(Analytics.SurveySent);
          userService.surveyDone(surveyId).catch(e => DEVELOPMENT && console.error(e));
        },
        onClose: () => {
          track.event(Analytics.SurveyClose);
        },
      });
      popup.open();
      clearInterval(interval);
    }
  }, 500);
}
