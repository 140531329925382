import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountData, Analytics, CreateAccountData, ResetPasswordData } from 'magma/common/interfaces';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { Model } from 'magma/services/model';
import { openPopup } from 'magma/services/popups';
import { ITrackService } from 'magma/services/track.service.interface';
import { AuthService } from 'services/auth.service';
import { UserService } from 'services/user.service';

@Injectable()
export class PortalLoginSignupService extends LoginSignupService {
  constructor(
    public model: Model,
    private authService: AuthService,
    private userService: UserService,
    private track: ITrackService,
    private route: ActivatedRoute,
  ) {
    super();

    if (!TESTS) {
      (window as any).resetNewFeature = () => this.dismissNewFeature('x');
    }
  }
  async createAccount(data: CreateAccountData, oauth: boolean) {
    if (oauth) {
      await this.authService.signupOauth(data);
    } else {
      const { needsEmailConfirmation } = await this.authService.signupWithEmail(data);
      if (!needsEmailConfirmation) {
        this.reloadSession();
      } else {
        return { needsEmailConfirmation: true };
      }
    }
    return { needsEmailConfirmation: false };
  }
  reloadSession() {
    this.authService.refreshToken();
    this.model.reloadSession(this.authService.token$.value);
  }
  async initAuth() {
    if (!this.authService.hasAuthToken) {
      await this.authService.initAuth();
    }
  }
  async signIn(email: string, password: string) {
    await this.authService.login({ email, password, rememberMe: true });
    this.reloadSession();
  }
  async signOut() {
    await this.authService.logout();
    this.reloadSession();
  }
  async resetPassword(email: string) {
    await this.authService.resetPassword({ email });
  }
  async changePassword(data: ResetPasswordData) {
    const resetToken = this.route.snapshot.children[0].params.token; // TODO: pass this instead
    await this.authService.changePassword(resetToken, data);
  }
  async finishInviteSignup(data: CreateAccountData) {
    await this.authService.finishInviteSignup(data);
  }
  async validateInviteToken(token: string, email: string) {
    return await this.authService.validateInviteToken(token, email);
  }
  async getPendingProfile() {
    return this.userService.pendingProfile();
  }
  async clearPendingProfile() {
    return this.userService.clearPendingProfile();
  }
  openAuthPopup(provider: string) {
    return openPopup(`/auth/${provider}`, 500, 800);
  }
  async updateAccount(data: AccountData) {
    await this.userService.save(data);
  }
  trackEvent(eventName: Analytics, props?: any) {
    this.track.event(eventName, props);
  }
  sessionIdRequired = false;
  get user() {
    return this.model.user;
  }
  getUserId() {
    return this.userService.user?._id ?? '';
  }
  endImpersonation(): void {
    void this.userService.endImpersonation();
  }
  isImpersonating(): boolean {
    return !!this.userService.superAdminToken;
  }
  getHideNewFeatureNotifications() {
    return !!this.userService.user?.hideNewFeatureNotifications;
  }
  setHideNewFeatureNotifications(value: boolean) {
    const user = this.userService.user;

    if (user && user.hideNewFeatureNotifications !== value) {
      this.userService.save({ hideNewFeatureNotifications: value })
        .catch(e => DEVELOPMENT && console.error(e));
    }
  }
  shouldShowNewFeature(feature: string) {
    const user = this.userService.user;

    if (this.model.isPresentationMode) return false;
    if (IS_HOSTED || !user) return false;

    return !user.hideNewFeatureNotifications && user.newFeature === feature && user.lastNewFeature !== feature;
  }
  async dismissNewFeature(feature: string) {
    await this.userService.save({ lastNewFeature: feature });
    this.userService.updateUser({ lastNewFeature: feature });
  }
  async getTeamIdForOrganization(name: string) {
    return this.authService.getTeamIdForOrganization(name);
  }
}
