import { EventType, Point, TabletEventFlags, TabletEvent, PressureApi, TabletEventSource, BitFlags } from '../common/interfaces';
import { getButton, getX, getY, isTouch, isPointer, AnyEvent, buttonToFlag, getPointerId } from '../common/utils';
import { clamp } from '../common/mathUtils';
import { isWindows, userAgent, isiPad, isFirefox, isiOS } from '../common/userAgentUtils';
import { createPoint } from '../common/point';
import { storageGetItem, storageSetItem } from './storage';
import { Key } from '../common/input';
import { roundCoord } from '../common/compressor';
import { logAction } from '../common/actionLog';
import { UserError } from '../common/userError';

function noop() { }

// WebHID support

// Huion and Gaomon don't work with chrome extension
// vendorId: 1386, productId: 132, productName: "Wacom Co.,Ltd. Wacom Wireless Receiver"
// vendorId: 1386, productId: 184, productName: "PTK-440" (wacom bamboo)
// vendorId: 1386, productId: 222, productName: "CTH-470" (wacom bamboo)
// vendorId: 1386, productId: 788, productName: "Intuos5 touch S"
// vendorId: 1386, productId: 789, productName: "Wacom Co.,Ltd. Intuos5 touch M"
// vendorId: 1386, productId: 849, productName: "Wacom Cintiq Pro 24"
// vendorId: 1386, productId: 934, productName: "Wacom Co.,Ltd. Wacom One Pen Display 13" (doesn't send any packets on windows)
// vendorId: 255, productId: 47820, productName: "Virtual Multitouch Device" (huion, works only with windows ink enabled)
// vendorId: 10429, productId: 2328, productName: "9 inch PenTablet" (xp-pen)
// vendorId: 10429, productId: 2347, productName: "13.3 inch PenDisplay" (xp-pen)
// vendorId: 10429, productId: 20993, productName: Xencelabs Pen Tablet Medium
// vendorId: 12267, productId: 5, productName: "VK640" (VEIKK) (it also shows `productId: 65535, "VEIKK Device"` which doesn't work)
// vendorId: 9580, productId: 109, productName: "Gaomon Tablet"

let activeHIDDevice: HIDDevice | undefined = undefined;
let onHIDPressure = (_timestamp: number, _pressure: number, _button: number) => { };
let isIntuos = false;

export function resetPressureFromHIDState() {
  isIntuos = false;
}

export function readPressureFromHID({ reportId, data }: HIDInputReportEvent, vendorId: number) {
  if (reportId === 2 && vendorId === 1386) { // wacom tablets on linux (vendorId: 1386)
    if ((data.getUint8(0) & 0xe0) === 0xe0) { // ignore touch/other packets
      if (data.getUint8(6) & 0xfc) isIntuos = true; // bamboo tablets always have 0 in this place

      // TODO: better way of detecting intuos ?
      if (isIntuos) {
        return ((data.getUint8(5) << 2) | ((data.getUint8(6) & 0xc0) >> 6)) / 1023;
      } else {
        return (data.getUint8(5) | (data.getUint8(6) & 0x03) << 8) / 1023;
      }
    }
  } else if (reportId === 2 || reportId === 5) { // xp-pen / xencelabs (vendorId: 10429), huion (vendorId: 255)
    return data.getUint16(5, true) / 8191;
  } else if (reportId === 15 && data.getUint8(0) === 2) { // wacom bamboo (vendorId: 1386)
    return data.getUint16(6, true) / 1023;
  } else if (reportId === 16 && (data.getUint8(0) & 0b1110_0000) === 0b0110_0000) { // wacom one (vendorId: 1386)
    return (data.getUint8(7) | (data.getUint8(8) << 8)) / 0xfff;
  } else if (reportId === 213) { // wacom intuos (vendorId: 1386) (windows ink on)
    return (data.getUint8(7) | (data.getUint8(8) << 8)) / 0x7ff;
  } else if (reportId === 220 && data.getUint8(0) === 2) { // wacom intuos (vendorId: 1386)
    if ((data.getUint8(1) & 0xe0) === 0xe0) { // prevent stylus in range packet from triggering pressure
      return ((data.getUint8(6) << 2) | (data.getUint8(7) >> 6)) / 1023;
    }
  } else if (reportId === 220 && data.getUint8(0) === 16) { // wacom cintiq (vendorId: 1386)
    return data.getUint16(8, true) / 0x1fff;
  } else if (reportId === 9 && data.getUint8(0) === 65) { // VEIKK (vendorId: 12267)
    return data.getUint16(8, true) / 8191;
  } else if (reportId === 8 && (data.getUint8(0) & 0xe0) === 0x80) { // Gaomon (vendorId: 9580)
    return data.getUint16(5, true) / 8191;
  }

  return -1;
}

export function readEraserFromHID({ reportId, data }: HIDInputReportEvent, vendorId: number) {
  if (vendorId === 1386) { // wacom
    if (reportId === 213) {
      return !!(data.getUint8(0) & 4);
    } else if (reportId === 220 && data.getUint8(0) === 16) { // cintiq
      return !!(data.getUint8(1) & 8);
    } else if (reportId === 210) {
      return undefined; // data.getUint8(0) === 2;
    } else {
      return undefined;
    }

    // if (reportId === 213) { // intuos (only with windows ink enabled)
    //   // !!(data.getUint8(0) & 4); // eraser hover
    //   return !!(data.getUint8(0) & 8); // eraser press
    // } else if (reportId === 220 && data.getUint8(0) === 2) { // intuos
    //   return false; // ??? doesn't seem this packet has eraser flag in it
    // } else if (reportId === 15 && data.getUint8(0) === 2) { // bamboo
    //   return false; // ???
    // } else {
    //   return false; // ???
    // }
  } else if (vendorId === 255 && reportId === 5) { // huion
    return !!(data.getUint8(0) & 8); // ???
  } else if (vendorId === 10429 && reportId === 2) { // xp-pen (the same as huion)
    return !!(data.getUint8(0) & 8);
  } else {
    return undefined;
  }
}

let firstHidPressureTimestamp = 0;

async function startWebHIDTablet(device: HIDDevice) {
  device.oninputreport = e => {
    const pressure = readPressureFromHID(e, device.vendorId);

    if (pressure >= 0) {
      if (pressure === 0) {
        firstHidPressureTimestamp = 0;
      } else if (firstHidPressureTimestamp === 0) {
        firstHidPressureTimestamp = e.timeStamp;
      }

      onHIDPressure(e.timeStamp, pressure, 0); // TODO: button from HID
    }
  };

  await device.open();
}

function isBadDevice(device: HIDDevice) {
  if (device.vendorId === 12267 && device.productId === 65535) return true; // "VEIKK Device"
  return false;
}

export async function autoConnectWebHIDTablet() {
  if (activeHIDDevice) return true;
  if (!navigator.hid) return false;

  try {
    const devices = await navigator.hid.getDevices();

    for (const device of devices) {
      try {
        if (isBadDevice(device)) continue;
        await startWebHIDTablet(activeHIDDevice = device);
        return true;
      } catch (e) {
        DEVELOPMENT && console.error(e);
      }
    }
  } catch (e) {
    DEVELOPMENT && console.error(e);
  }

  return false;
}

export async function connectWebHIDTablet() {
  if (activeHIDDevice) {
    activeHIDDevice.close().catch(e => DEVELOPMENT && console.error(e));
    activeHIDDevice = undefined;
  }

  if (!navigator.hid) return false;

  try {
    // TODO: add filters: [{ vendorId: 1386 }, ...];
    let devices = await navigator.hid.requestDevice({ filters: [] });

    for (const device of devices) {
      try {
        // TODO: better, customized error, also show the error to user, right now it shows only in debug mode
        if (isBadDevice(device)) throw new UserError('Invalid device, please select different device');

        await startWebHIDTablet(activeHIDDevice = device);
        return true;
      } catch (e) {
        DEVELOPMENT && console.error(e);
      }
    }
  } catch (e) {
    DEVELOPMENT && console.error(e);
  }

  return false;
}

// Stylus extension

interface IPen {
  name: string;
  pressure: number;
  pointerType: number;
}

interface ITabletStatus {
  code: string;
  message: string;
  installLink: string;
}

interface IExtension {
  gotPressure: (pressure: number, button?: number) => void;
  error(): ITabletStatus;
  restart?(): void;
  focus(): void;
  pen(): IPen;
}

// other

export let isFocused = true;
export let isCursorVisible = true;
export let lastTabletApi = '';
export let lastTabletName = '';
export let hasPressure = false;
let status: ITabletStatus | undefined;
let focusBlock = false;
let extensionWasRestarted = false;
let extensionCheckTries = 10;
let focusBlockTimeout: any = 0;

function onBlur() {
  isFocused = false;
  isCursorVisible = false;
}

function onMove() {
  isCursorVisible = true;
}

function onLeave() {
  isCursorVisible = false;
}

function onFocus() {
  focusBlock = true;
  isFocused = true;
  isCursorVisible = true;
  window.addEventListener('mousedown', disableFocusBlock);
  window.addEventListener('pointerdown', disableFocusBlock);
  window.addEventListener('touchstart', disableFocusBlock);
  window.addEventListener('keydown', disableFocusBlock);
  clearTimeout(focusBlockTimeout);
  focusBlockTimeout = setTimeout(disableFocusBlock, 100);
}

function disableFocusBlock() {
  focusBlock = false;
  window.removeEventListener('mousedown', disableFocusBlock);
  window.removeEventListener('pointerdown', disableFocusBlock);
  window.removeEventListener('touchstart', disableFocusBlock);
  window.removeEventListener('keydown', disableFocusBlock);
  clearTimeout(focusBlockTimeout);
}

if (typeof window !== 'undefined') {
  window.addEventListener('focus', onFocus);
  window.addEventListener('blur', onBlur);
  window.addEventListener('mousemove', onMove);
  window.addEventListener('pointermove', onMove);
  window.addEventListener('touchmove', onMove);
  document.body.addEventListener('mouseleave', onLeave);
  document.body.addEventListener('pointerleave', onLeave);
  document.body.addEventListener('touchleave', onLeave);
}

export function hasExtension() {
  return typeof window !== 'undefined' && !!(window as any).Tablet;
}

export function getTabletModel() {
  const ext = (window as any).Tablet as IExtension | undefined;
  return ext?.pen?.()?.name;
}

function hasOrHadExtension() {
  return hasExtension() || storageGetItem('had-extension') === 'yes';
}

function usePointerEvents() {
  return typeof PointerEvent !== 'undefined';
}

export interface TabletEventsControl {
  getPointer(): Point;
  readEvent(): TabletEvent | undefined;
  reuseEvent(e: TabletEvent): void;
  pushEvent(e: TabletEvent): void;
  hasPushedEvents(): boolean;
  destroy(): void;
}

export interface TabletConfig {
  api: PressureApi;
  minimumPressure(): number;
  isTouchDisabled(): boolean;
  tabletName(name: string): void;
}

export function normalizePressure(pressure: number) {
  return clamp(Math.round(pressure * 1023) / 1023, 0, 1);
}

function tryToRestartExtension() {
  if (extensionWasRestarted) return;
  if (--extensionCheckTries <= 0) return;

  let ext: IExtension | undefined = (window as any).Tablet;

  if (ext) {
    ext.restart?.();
    extensionWasRestarted = true;
  } else {
    setTimeout(tryToRestartExtension, 500);
  }
}

let baseTimestamp = 0;
let baseTime = 0;
let reportedTabletName = false;

function getTimestamp(e: Event) {
  if (e.timeStamp > performance.now()) {
    return baseTime + (e.timeStamp - baseTimestamp); // fix timestamp on iOS
  } else {
    return e.timeStamp;
  }
}

function isValidXY(x: number, y: number) {
  return Number.isFinite(x) && Number.isFinite(y);
}

function getTiltX(e: AnyEvent) {
  return ('tiltX' in e && Number.isFinite(e.tiltX)) ? e.tiltX : 0;
}

function getTiltY(e: AnyEvent) {
  return ('tiltY' in e && Number.isFinite(e.tiltY)) ? e.tiltY : 0;
}

function getSource(e: AnyEvent) {
  if (/mouse/.test(e.type)) {
    return TabletEventSource.Mouse;
  } else if (/touch/.test(e.type)) {
    if ((e as TouchEvent).touches[0] && ('force' in (e as TouchEvent).touches[0]) && (e as TouchEvent).touches[0].touchType === 'stylus') {
      return TabletEventSource.Pen;
    } else {
      return TabletEventSource.Touch;
    }
  } else if ((e as PointerEvent).pointerType === 'touch') {
    return TabletEventSource.Touch;
  } else if ((e as PointerEvent).pointerType === 'pen') {
    return TabletEventSource.Pen;
  } else {
    return TabletEventSource.Mouse;
  }
}

export function setupTabletEvents(element: HTMLElement, config: TabletConfig): TabletEventsControl {
  const isChromeOrOperaOnWindows = /chrome|opera/i.test(userAgent)
    && !/edge/i.test(userAgent)
    && /windows/i.test(userAgent);
  const isChromeOrOperaOnWindows7OrOlder = isChromeOrOperaOnWindows
    && !/windows nt (1[0-9]|6\.[23])/i.test(userAgent);

  setTimeout(tryToRestartExtension, 500);

  interface PressureEvent {
    pressure: number;
    button: number;
    timeStamp: number;
  }

  const pressureBufferLimit = 100;
  const eventBufferLimit = 200;
  const freeEvents: TabletEvent[] = [];
  const eventBuffer: TabletEvent[] = [];
  const pressureBuffer: PressureEvent[] = [];
  const pointer = createPoint(0, 0);
  let pointerEvents = usePointerEvents();
  let rect = { left: 0, top: 0 };
  let button = 0;
  let pointerId = 0;
  let dragging = false;
  let usingPressure = false;
  let pen: IPen | undefined = undefined;
  let subscriptions: (() => void)[] = [];
  let lastEvent: any = undefined;
  let asyncPressure = false;
  let lastPressure = 0;
  let lastButton = 0;
  let lastPressureTime = 0;
  let pushedEvents: TabletEvent[] = [];
  let pressure = 0;
  let tiltX = 0;
  let tiltY = 0;
  let pressureAdjustTime = 0;
  let ipadInitialPressure = 0;
  let ipadSkipping = 0;
  let started = false;

  function mapAndNormalizePressure(p: number) {
    const min = config.minimumPressure();
    const value = (p - min) / (1 - min);
    return normalizePressure(value);
  }

  function getPointer() {
    return pointer;
  }

  function popOneEvent() {
    if (asyncPressure) {
      if (eventBuffer.length) {
        const event = eventBuffer[0];

        while (pressureBuffer.length && (pressureBuffer[0].timeStamp + pressureAdjustTime) < event.timeStamp) {
          const entry = pressureBuffer.shift()!;
          lastPressure = entry.pressure;
          // TODO: tiltX / tiltY
          lastButton = entry.button;
        }

        if (pressureBuffer.length === 0 || (pressureBuffer[0].timeStamp + pressureAdjustTime) <= event.timeStamp) {
          const nowTime = performance.now();
          const threshold = nowTime - 8;

          if (eventBuffer[0].timeStamp < threshold) {
            if ((nowTime - lastPressureTime) < 500) {
              event.pressure = pressure = lastPressure;
              // TODO: tiltX / tiltY
              if (lastButton === 2) event.flags = (event.flags & ~TabletEventFlags.Button) | (5 << 8); // 5th button
            }
            return eventBuffer.shift();
          }
        } else {
          lastPressure = pressureBuffer[0].pressure;
          lastButton = pressureBuffer[0].button;
          lastPressureTime = pressureBuffer[0].timeStamp + pressureAdjustTime;
          event.pressure = pressure = lastPressure;
          // TODO: tiltX / tiltY
          if (lastButton === 2) event.flags = (event.flags & ~TabletEventFlags.Button) | (5 << 8); // 5th button
          return eventBuffer.shift();
        }
      }

      return undefined;
    } else {
      return eventBuffer.shift();
    }
  }

  function readEvent() {
    if (pushedEvents.length) return pushedEvents.shift();

    while (true) {
      const e = popOneEvent();

      if (e && (e.type === EventType.Start || e.type === EventType.Move || e.type === EventType.End)) {
        const min = config.minimumPressure();

        if (started) {
          if (e.pressure < min) {
            e.type = EventType.End;
            started = false;
          }
        } else {
          if (e.pressure >= min && e.type !== EventType.End) {
            e.type = EventType.Start;
            started = true;
          } else {
            reuseEvent(e);
            continue;
          }
        }
      }

      if (e) {
        e.pressure = mapAndNormalizePressure(e.pressure);

        if (!hasPressure && e.pressure !== 1 && e.pressure !== 0) {
          hasPressure = true;
        }
      }

      return e;
    }
  }

  function pushEvent(e: TabletEvent) {
    pushedEvents.push(e);
  }

  function reuseEvent(e: TabletEvent) {
    if (freeEvents.length < 1000) {
      freeEvents.push(e);
    }
  }

  function gotPressure(timeStamp: number, pressure: number, button: number) {
    asyncPressure = true;

    while (pressureBuffer.length >= pressureBufferLimit) {
      pressureBuffer.shift();
    }

    pressureBuffer.push({ timeStamp, pressure, button });
  }

  function gotPressureHandler(pressure: number, button?: number) {
    if (button === 8) button = 2; // eraser on cintiq returns 8
    gotPressure(performance.now(), pressure, button || 0);
  }

  function getPressure(): number {
    return (pen && pen.pointerType) ? pen.pressure : 1;
  }

  function calculatePressureAndTilt(e: AnyEvent, type: EventType) {
    let p = 1;

    if (isChromeOrOperaOnWindows7OrOlder) {
      p = getPressure(); // extension
    } else if (isPointer(e)) {
      if (e.pointerType === 'pen') {
        p = e.pressure;
      } else if (e.pointerType === 'touch' && !isiOS) {
        p = e.pressure;
      } else {
        p = 1;
      }
    } else if (isFirefox) {
      p = getPressure(); // there's no extension for firefox, always 1
    } else if (isTouch(e) && e.touches[0] && ('force' in e.touches[0]) && e.touches[0].touchType === 'stylus') {
      p = e.touches[0].force || 0; // old iPad stylus pressure
      config.api = 'force';
    } else {
      p = getPressure(); // extension
    }

    if (type === EventType.Start) {
      usingPressure = p !== 1;
    } else {
      usingPressure = usingPressure || p !== 1;
    }

    if (type === EventType.End && usingPressure) {
      p = 0;
    }

    pressure = p;
    tiltX = getTiltX(e); // TODO: get tilt from extension
    tiltY = getTiltY(e); // TODO: get tilt from extension
  }

  function getFreeEvent(): TabletEvent {
    return freeEvents.pop() || createTabletEvent();
  }

  function sendOneEvent(e: AnyEvent, type: EventType, flags: BitFlags<TabletEventFlags>, source: TabletEventSource) {
    let x = getX(e);
    let y = getY(e);

    if (!isValidXY(x, y)) { // sometimes we're getting NaN values for x & y
      if (type === EventType.End) { // make sure we always send end event event if x, y are broken
        x = y = 0;
      } else {
        return;
      }
    }

    // we sometimes get random points way out of range, ignore those events
    // TODO: handle end somehow (use last x, y ?)
    if (type !== EventType.End && (x < -10000 || x > 20000 || y < -10000 || y > 20000)) return;

    calculatePressureAndTilt(e, type);

    if (type === EventType.Start && pressure === 0 && (config.api === 'ext' || config.api === 'hid')) {
      flags |= TabletEventFlags.MissingPressure;
    }

    const event = getFreeEvent();
    event.timeStamp = getTimestamp(e);
    event.type = type;
    event.flags = flags;
    event.x = roundCoord(x - rect.left);
    event.y = roundCoord(y - rect.top);
    event.pressure = pressure;
    event.tiltX = tiltX;
    event.tiltY = tiltY;
    event.deltaX = 0;
    event.deltaY = 0;
    event.source = source;

    while (eventBuffer.length >= eventBufferLimit) {
      reuseEvent(eventBuffer.shift()!);
    }

    eventBuffer.push(event);
  }

  function send(event: AnyEvent, type: EventType, source: TabletEventSource, isTouch = false) {
    // fix issue on ipad with invalid pressure value on few initial events (1-4 events)
    if (ipadSkipping > 0) {
      if ((event as PointerEvent).pressure === ipadInitialPressure) {
        ipadSkipping--;
        return; // ignore event
      } else {
        type = type === EventType.Move ? EventType.Start : type;
        ipadSkipping = 0;
      }
    }

    let flags: TabletEventFlags = (button << 8) | getModifierFlags(event);
    if (isTouch) flags |= TabletEventFlags.Touch; // used to hide cursor after stroke end

    if (
      (event.type === 'pointermove' || event.type === 'mousemove' || event.type === 'touchmove') &&
      'getCoalescedEvents' in event
    ) {
      const events: AnyEvent[] = (event as any).getCoalescedEvents();

      if (events.length) {
        for (const e of events) {
          sendOneEvent(e, type, flags, source);
        }
        return;
      }
    }

    sendOneEvent(event, type, flags, source);
  }

  function addAllEventHandlers() {
    let ext: IExtension | undefined = undefined;

    const eventSets = pointerEvents ? [
      { down: 'pointerdown', move: 'pointermove', up: 'pointerup', cancel: 'pointercancel' },
    ] : [
      { down: 'mousedown', move: 'mousemove', up: 'mouseup' },
      { down: 'touchstart', move: 'touchmove', up: 'touchend', cancel: 'touchcancel' },
    ];

    function preventDefault(e: Event) {
      e.preventDefault();
    }

    if (pointerEvents && isiPad) { // disable loupe on iOS 15
      element.addEventListener('touchstart', preventDefault);
      subscriptions.push(() => element.removeEventListener('touchstart', preventDefault));
    }

    rect = element.getBoundingClientRect();

    subscriptions.push(...eventSets.map(events => {
      let draggingHere = false;
      let isTouchOrPen = false;
      let source = TabletEventSource.Mouse;

      function down(e: PointerEvent) {
        if (dragging || focusBlock) {
          e.preventDefault();
          return;
        }

        if (e.pointerType === 'touch' && config.isTouchDisabled()) return;

        const x = getX(e);
        const y = getY(e);
        if (!isValidXY(x, y)) return;

        baseTimestamp = e.timeStamp;
        baseTime = performance.now();

        pointer.x = x;
        pointer.y = y;

        if (document.activeElement && /input|select/i.test(document.activeElement.tagName)) {
          (document.activeElement as HTMLElement).blur();
        }

        e.preventDefault();

        dragging = true;
        draggingHere = true;
        button = getButton(e);
        pointerId = getPointerId(e);
        rect = element.getBoundingClientRect();
        lastEvent = e;
        pressureAdjustTime = 0;
        source = getSource(e);
        isTouchOrPen = source === TabletEventSource.Touch || source === TabletEventSource.Pen;
        started = false;

        let isExt = false;
        let tabletName: string | undefined = undefined;

        if (activeHIDDevice?.opened) {
          onHIDPressure = gotPressure;
          tabletName = activeHIDDevice.productName;
          config.api = 'hid';

          // add delay to account for delay between HID packets and pointer events
          //   ~24ms delay from testing on Windows
          pressureAdjustTime = clamp(e.timeStamp - firstHidPressureTimestamp, 0, 32) * 0.9;
        } else if (pointerEvents && !hasOrHadExtension()) {
          config.api = 'pen';
        } else {
          let pen: IPen | undefined = undefined;

          try {
            ext = (window as any).Tablet;

            if (ext && ext.pen) {
              isExt = true;
              pen = ext.pen();
              ext.focus();
              ext.gotPressure = gotPressureHandler;
              onHIDPressure = noop;

              if (status !== ext.error()) {
                status = ext.error();
              }
            }
          } catch { }

          if (pen) {
            config.api = 'ext';
            tabletName = pen.name;
          } else {
            // config.api = 'none';
          }
        }

        if (!isExt && ext) {
          ext.gotPressure = noop;
          ext = undefined;
        }

        if (!reportedTabletName && tabletName) {
          lastTabletName = tabletName;
          config.tabletName(tabletName);
          reportedTabletName = true;
        }

        lastTabletApi = config.api;

        if (isiPad && e.pressure !== 1 && e.pointerType === 'pen') {
          ipadSkipping = 5;
          ipadInitialPressure = e.pressure;
        }

        send(e, EventType.Start, source);
      }

      let ignoreMoveIn = 0;

      function end(e: PointerEvent) {
        if (e.type !== 'blur') {
          const x = getX(e);
          const y = getY(e);

          if (isValidXY(x, y)) {
            pointer.x = x;
            pointer.y = y;
          }
        }

        // ignore 2nd move after end, windows ink sends one move event with coordinates next to
        // start of the stroke for some reason causing cursor to jump back for single frame.
        if (e.type === 'pointerup' && e.pointerType === 'pen' && isWindows) ignoreMoveIn = 2;

        if (draggingHere) {
          send(lastEvent, EventType.End, source, isTouchOrPen);
          dragging = false;
          draggingHere = false;
        }
      }

      function move(e: PointerEvent) {
        if (e.pointerType === 'touch' && config.isTouchDisabled()) return;
        if (--ignoreMoveIn === 0) return;

        const x = getX(e);
        const y = getY(e);
        if (!isValidXY(x, y)) return;

        pointer.x = x;
        pointer.y = y;

        if (draggingHere) {
          if (pointerId !== getPointerId(e)) return;

          lastEvent = e;

          // pointerup & pointerdown events are only called on first mouse button,
          // check button mask end end it if button is not held anymore.
          // ignore touch here, some devices report always 0 in e.buttons when using touch.
          if (e.type === 'pointermove' && e.pointerType !== 'touch' && (buttonToFlag[button] & e.buttons) === 0) {
            if (isFirefox) return; // ignore on firefox, firefox randomly sends events with wrong buttons value and wrong pressure
            end(e);
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          send(e, EventType.Move, source);
        } else {
          // do this here because if we assign the handler in down event we might miss some HID events already
          if (activeHIDDevice && activeHIDDevice.opened) {
            onHIDPressure = gotPressure;
          }

          const event = getFreeEvent();
          event.type = EventType.Hover;
          event.flags = getModifierFlags(e);
          event.timeStamp = e.timeStamp;
          event.x = roundCoord(pointer.x - rect.left);
          event.y = roundCoord(pointer.y - rect.top);
          event.pressure = 0;
          event.tiltX = getTiltX(e);
          event.tiltY = getTiltY(e);
          event.deltaX = 0;
          event.deltaY = 0;
          event.source = getSource(e);
          eventBuffer.push(event);
        }
      }

      function up(e: PointerEvent) {
        if (!draggingHere) return;
        if (pointerId !== getPointerId(e)) return;

        // touch events don't have correct info in "up" event, so use last event instead
        if (!/^touch/i.test(e.type)) lastEvent = e;

        e.preventDefault();
        e.stopPropagation();

        if (getButton(e) !== button) return;

        end(e);
      }

      function cancel(e: PointerEvent) {
        if (!draggingHere) return;
        if (pointerId !== getPointerId(e)) return;

        send(lastEvent, EventType.Cancel, source, isTouchOrPen); // use last event, cancel event might not have any relevant info
        dragging = false;
        draggingHere = false;
      }

      function fakeMove(e: KeyboardEvent) {
        const event = getFreeEvent();
        event.type = draggingHere ? EventType.Move : EventType.Hover;
        event.flags = getModifierFlags(e);
        event.timeStamp = e.timeStamp;
        event.x = roundCoord(pointer.x - rect.left);
        event.y = roundCoord(pointer.y - rect.top);
        event.pressure = pressure;
        event.tiltX = tiltX;
        event.tiltY = tiltY;
        event.deltaX = 0;
        event.deltaY = 0;
        event.source = source;
        eventBuffer.push(event);
      }

      function key(e: KeyboardEvent) {
        if (e.keyCode === Key.Ctrl || e.keyCode === Key.Alt || e.keyCode === Key.Shift || e.keyCode === Key.Meta) {
          fakeMove(e);
        }
      }

      function blur(e: Event) {
        end(e as any);
        fakeMove(e as any);
      }

      element.addEventListener(events.down, down as any);
      window.addEventListener(events.move, move as any);
      window.addEventListener(events.up, up as any);
      events.cancel && window.addEventListener(events.cancel, cancel as any);
      window.addEventListener('blur', blur);
      window.addEventListener('keydown', key);
      window.addEventListener('keyup', key);

      return () => {
        element.removeEventListener(events.down, down as any);
        window.removeEventListener(events.move, move as any);
        window.removeEventListener(events.up, up as any);
        events.cancel && window.removeEventListener(events.cancel, cancel as any);
        window.removeEventListener('blur', blur);
        window.removeEventListener('keydown', key);
        window.removeEventListener('keyup', key);
      };
    }));

    element.addEventListener('wheel', wheelHandler);
    subscriptions.push(() => element.removeEventListener('wheel', wheelHandler));

    window.addEventListener('resize', resizeHandler);
    subscriptions.push(() => window.removeEventListener('resize', resizeHandler));
  }

  function resizeHandler() {
    rect = element.getBoundingClientRect();
    logAction(`tablet resize (${rect.left}, ${rect.top})`);
  }

  function wheelHandler(e: WheelEvent) {
    e.preventDefault();

    const x = getX(e);
    const y = getY(e);
    const event = getFreeEvent();
    event.timeStamp = e.timeStamp;
    event.type = EventType.Wheel;
    event.flags = getModifierFlags(e);
    event.pressure = 0;
    event.tiltX = 0;
    event.tiltY = 0;
    event.deltaX = clamp(e.deltaX, -1, 1) * 100;
    event.deltaY = clamp(e.deltaY, -1, 1) * 100;
    event.source = TabletEventSource.Mouse;

    if (isValidXY(x, y)) {
      event.x = roundCoord(x - rect.left);
      event.y = roundCoord(y - rect.top);
    } else {
      event.x = 0;
      event.y = 0;
    }

    eventBuffer.push(event);
  }

  function removeAllEventHandlers() {
    while (subscriptions.length) {
      subscriptions.pop()!();
    }
  }

  if (isChromeOrOperaOnWindows) {
    const hadExtension = storageGetItem('had-extension');

    if (hasExtension() || hadExtension === 'yes' || hadExtension === 'no') {
      addAllEventHandlers();
    } else {
      storageSetItem('had-extension', 'yes');
      pointerEvents = usePointerEvents();
      addAllEventHandlers();
    }

    setInterval(() => {
      const hasExtensionNow = hasExtension() ? 'yes' : 'no';

      if (hasExtensionNow !== storageGetItem('had-extension')) {
        storageSetItem('had-extension', hasExtensionNow);
        pointerEvents = usePointerEvents();
        removeAllEventHandlers();
        addAllEventHandlers();
      }
    }, 5000);
  } else {
    addAllEventHandlers();
  }

  function hasPushedEvents() {
    return pushedEvents.length > 0;
  }

  function destroy() {
    removeAllEventHandlers();
  }

  return { getPointer, readEvent, reuseEvent, pushEvent, hasPushedEvents, destroy };
}

export function createTabletEvent(): TabletEvent {
  return {
    timeStamp: 0,
    type: EventType.Start,
    flags: 0,
    x: 0,
    y: 0,
    pressure: 0,
    tiltX: 0,
    tiltY: 0,
    deltaX: 0,
    deltaY: 0,
    source: 0,
  };
}

export function copyModifiers(ev: TabletEvent, e: TabletEvent) {
  ev.flags = (ev.flags & ~TabletEventFlags.ModifierKeys) | (e.flags & TabletEventFlags.ModifierKeys);
}

function getModifierFlags(e: WheelEvent | KeyboardEvent | PointerEvent | MouseEvent | TouchEvent) {
  return (e.shiftKey ? TabletEventFlags.ShiftKey : 0) |
    (e.altKey ? TabletEventFlags.AltKey : 0) |
    (e.ctrlKey ? TabletEventFlags.CtrlKey : 0) |
    (e.metaKey ? TabletEventFlags.MetaKey : 0);
}

export function setModifiers(ev: TabletEvent, e: KeyboardEvent) {
  const flags = getModifierFlags(e);

  if ((ev.flags & TabletEventFlags.ModifierKeys) === flags) {
    return false;
  } else {
    ev.flags = (ev.flags & ~TabletEventFlags.ModifierKeys) | flags;
    return true;
  }
}
