import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';

let id = 1;

@Component({
  selector: 'custom-radio',
  templateUrl: 'custom-radio.pug',
  styleUrls: ['custom-radio.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomRadio {
  @Input() disabled = false;
  @Input() help = '';
  @Input() value = '';
  @Input() theValue = '';
  @Input() name = 'radio';
  @Input() titlecase = false;
  @Output() valueChange = new EventEmitter<string>();
  helpId = `custom-radio-help-${++id}`;
}
