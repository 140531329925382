import { ICanvasProvider, IErrorReporter, IRenderer } from '../common/interfaces';
import { Renderer } from './renderer';
import { WebGLRenderer } from './webglRenderer';
import { logAction } from '../common/actionLog';
import { createCanvas, createCanvasGL } from '../common/canvasUtils';
import { storageGetItem } from './storage';
import { isiOS } from '../common/userAgentUtils';

const IS_IE11 = typeof navigator !== 'undefined' && /Trident\/7/.test(navigator.userAgent);

export function isUsingWebGL() {
  // disabled for IE11 because it's still broken on webgl and I don't know why
  return !SERVER && ((storageGetItem('webgl') !== 'no' && !IS_IE11) || isiOS);
}

export let usingWebGL = isUsingWebGL();
export let failedWebGL = '';

// to be used when software renderer is used
export function getWebMaxTextureSize() {
  try {
    const canvas = createCanvasGL(1, 1);
    const gl: WebGLRenderingContext | WebGL2RenderingContext | null = canvas.getContext('webgl2') || canvas.getContext('webgl') || canvas.getContext('experimental-webgl') as any;
    if (gl) return gl.getParameter(gl.MAX_TEXTURE_SIZE);
  } catch (e) {
    DEVELOPMENT && console.error(e);
  }
  return -1;
}

export function markWebglAsFailed(message: string) {
  usingWebGL = false;
  failedWebGL = message || 'unknown error';
}

export function createRenderer(id: string, canvasProvider: ICanvasProvider, errorReporter: IErrorReporter): { renderer: IRenderer; error?: string; } {
  let error: string | undefined = undefined;

  try {
    if (usingWebGL) {
      const renderer = new WebGLRenderer(id, errorReporter);
      renderer.init(createCanvasGL(100, 100));
      return { renderer };
    }
  } catch (e) {
    logAction(`Failed to create WebGLRenderer: ${e.message}`);
    DEVELOPMENT && console.error(e);
    error = e.message;
    markWebglAsFailed(e.message);
  }

  const renderer = new Renderer(id, !!failedWebGL, canvasProvider, errorReporter);
  renderer.init(createCanvas(400, 400)); // 400x400 is used in editor tests
  return { renderer, error };
}
