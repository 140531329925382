import { Component, EventEmitter, Output, Input, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BACKGROUND_COLORS, MAX_ENTITY_NAME_LENGTH, MIN_IMAGE_WIDTH, MIN_IMAGE_HEIGHT, DEFAULT_DPI, DEFAULT_BACKGROUND_COLOR, DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT, DRAWING_MODEDATOR_TAG } from '../../../../common/constants';
import { Model } from '../../../../services/model';
import { UserAction, Drawing, User, DrawingPermissions, DRAWING_NOTIFICATION_SETTINGS, DEFAULT_NOTIFICATION_SETTING, SelectedFolder, SelectMode, Allows, SubscriptionPlan, ResizeAlign as ResizeAnchor, DrawingAction, Feature, DrawingFlags, CreateDrawingData } from '../../../../common/interfaces';
import { canHaveLargeCanvas, doesImageSizeRequirePro, getMaxImageWidth, invalidImageSizeUserError, isEmptyDrawing, isImageSizeValid } from '../../../../common/drawingUtils';
import { faFolder, faHashtag, faLock, blazeIcon, faArrowLeft, faCircle } from '../../../../common/icons';
import { hasPermission, hasPro, hasDrawingRole } from '../../../../common/userRole';
import { clamp } from '../../../../common/mathUtils';
import { CreateDrawingProps, Modals } from '../../../../services/modals';
import { inchesToLocalUnits } from '../../../../common/settingsUtils';
import { disableMyArtdesk, drawingsPath, DRAWING_PERMISSIONS } from '../../../../common/data';
import { urlToDrawingId, getThumbPath, setEntityPassword, navigateToDrawing } from '../../../../common/clientUtils';
import { IAppNotificationService } from '../../../../services/app-notification.service.interface';
import { ManageService } from '../../../../services/manageService';
import { logAction } from '../../../../common/actionLog';
import { getAllows } from '../../../../common/userUtils';
import { cropDrawing } from '../../../../services/otherActions';
import { createRect } from '../../../../common/rect';
import { Editor } from '../../../../services/editor';
import { hasFlag, invalidEnum } from '../../../../common/baseUtils';
import { FeatureFlagService } from '../../../../services/feature-flag.service.interface';
import { UserError } from '../../../../common/userError';

function canBecomeAdmin(user: User, drawing: Drawing): 'is_admin' | 'become_admin' | 'anonymous' | 'has_admins' | 'cannot_become' {
  if (hasDrawingRole(user, 'admin')) return 'is_admin';

  // ref: openedDrawing.ts:canBecomeAdmin
  if (user.anonymous && !IS_PORTAL) return 'anonymous';
  if (user.isSuperAdmin) return 'become_admin';
  if (drawing.hasAdmins) return 'has_admins';
  if (user.tags?.includes(DRAWING_MODEDATOR_TAG)) return 'become_admin';
  if (user.isCreator || user.role === 'owner') return 'become_admin';
  if (isEmptyDrawing(drawing)) return 'become_admin';
  return 'cannot_become';
}

@Component({
  selector: 'drawing-settings',
  templateUrl: 'drawing-settings.pug',
  styleUrls: ['drawing-settings.scss'],
})
export class DrawingSettings implements OnInit {
  ResizeAnchor = ResizeAnchor;
  readonly maxNameLength = MAX_ENTITY_NAME_LENGTH;
  readonly colors = BACKGROUND_COLORS;
  readonly minWidth = MIN_IMAGE_WIDTH;
  readonly minHeight = MIN_IMAGE_HEIGHT;
  readonly permissions = DRAWING_PERMISSIONS;
  readonly initIcon = faLock;
  readonly blazeIcon = blazeIcon;

  faArrowLeft = faArrowLeft;
  faCircle = faCircle;

  projectIcon = faHashtag;
  folderIcon = faFolder;
  @Input() data: CreateDrawingProps = {};
  @Output() close = new EventEmitter<CreateDrawingData | undefined>();
  background = DEFAULT_BACKGROUND_COLOR;
  dpi = DEFAULT_DPI;
  name = '';
  url = '';
  width = DEFAULT_CANVAS_WIDTH;
  height = DEFAULT_CANVAS_HEIGHT;
  invite = false;
  copyPermissions = false;
  respectOfflineOwners = false;
  error: string | undefined = undefined;
  password: string | undefined = undefined;
  multiboard = false;
  addToSequence = false;
  activeTab = 0;
  openInNewWindow = false;
  appNotificationSetting = DEFAULT_NOTIFICATION_SETTING;
  appNotificationSettings = DRAWING_NOTIFICATION_SETTINGS;
  private sequenceIndex = -1;
  selectedFolder: SelectedFolder | undefined;
  selectedFolderName: string | undefined;
  private resizeAnchor: ResizeAnchor = ResizeAnchor.Center;
  private ax = 0;
  private ay = 0;
  private allows: Allows = {
    isSuperAdmin: false,
    pro: SubscriptionPlan.Free,
    features: new Set()
  };
  constructor(
    private model: Model,
    private router: Router,
    private sanitizer: DomSanitizer,
    private manage: ManageService,
    private modals: Modals,
    private featureFlags: FeatureFlagService,
    private appNotificationService?: IAppNotificationService,
    @Optional() private editor?: Editor, // this can be undefined in portal
  ) {
  }
  async ngOnInit() {
    const drawing = this.drawing;

    if (drawing?.id) {
      this.width = drawing.w;
      this.height = drawing.h;
      this.background = (this.colors.find(c => c.value === drawing.background) || BACKGROUND_COLORS[0]).value;
      this.dpi = drawing.dpi;
      this.respectOfflineOwners = drawing.respectOfflineOwners;

      if (!this.create) {
        this.name = drawing.name;
        this.password = drawing.password;
      }
    }

    if (this.create) {
      this.name = this.data.name ?? '';
      this.addToSequence = !!this.data.addToSequence;
      this.sequenceIndex = this.data.sequenceIndex ?? -1;
      this.activeTab = this.data.existing ? 1 : 0;
      this.openInNewWindow = !!this.data.openInNewWindow;
      Object.assign(this.data, this.model.allowedFolderProjectTeam(this.data));
    }

    if (this.appNotificationService && this.drawing) {
      this.appNotificationSetting = await this.appNotificationService.getNotificationSettings(this.drawing.id);
    }
    if (this.folder) {
      const folder = await this.manage.folder(this.folder);
      this.selectedFolderName = folder?.name;
    }
    this.refreshAllows();
  }
  private get hasPro() {
    const team = this.data.team ? this.manage.team(this.data.team) : undefined;
    return hasPro(this.model.user) || !!team?.pro;
  }
  get hasTrial() {
    return this.manage.hasTrial();
  }
  get drawing() {
    return this.data.drawing;
  }
  get create() {
    return !!this.data.create;
  }
  get isDrawingOwner() {
    return hasDrawingRole(this.model.user, 'owner') || !!this.model.user.isCreator;
  }
  get isDrawingAdminOrOwner() {
    return hasDrawingRole(this.model.user, 'admin');
  }
  get canHaveLargeCanvas() {
    return canHaveLargeCanvas(this.allows);
  }
  get maxWidth() {
    return getMaxImageWidth(this.allows);
  }
  get maxHeight() {
    return getMaxImageWidth(this.allows);
  }
  get valid() {
    return !this.create || (!!this.name && !!this.width && !!this.height && (this.team ? this.project : true));
  }
  get sizeError() {
    return this.create && !isImageSizeValid(this.width, this.height, this.allows);
  }
  get sizeWarning() {
    return this.create && (this.width > 2048 || this.height > 2048);
  }
  get showSizeProPrompt() {
    return !this.canHaveLargeCanvas && doesImageSizeRequirePro(this.width, this.height);
  }
  get canInvite() {
    return this.create && !!this.drawing && !!this.model.drawing?.users.length;
  }
  get canCopyPermissions() {
    return this.create && this.canBecomeAdminOfNewDrawing && !!this.drawing?.id;
  }
  get hasAnyPermissions() {
    return this.permissions.some(p => !!this.drawing?.permissions[p.key]);
  }
  get adminAlert() {
    return this.drawing && canBecomeAdmin(this.model.user, this.drawing);
  }
  get canBecomeAdminOfNewDrawing() {
    return IS_PORTAL || !this.model.user.anonymous;
  }
  get units() {
    return this.model.settings.units;
  }
  get showPassword() {
    if (!IS_PORTAL) return false;

    if (hasFlag(this.drawing?.flags, DrawingFlags.Jams)) return false;

    // this is also showing password box for non-pro users, so they can see the upgrade prompt.
    const team = this.create ? this.data.team : this.drawing?.team;
    return !team && (this.create || this.password || this.isDrawingAdminOrOwner);
  }
  get canChangePassword() {
    return this.create || this.isDrawingOwner || this.isDrawingAdminOrOwner;
  }
  get proBlockPasswordChange() {
    return !this.hasPro && !this.model.user.isSuperAdmin;
  }
  get dontRespectOfflineOwners() {
    return !this.drawing?.respectOfflineOwners;
  }
  set dontRespectOfflineOwners(value) {
    this.respectOfflineOwners = !value;
  }
  get canChangeRespectOfflineOwners() {
    return this.create || this.isDrawingAdminOrOwner;
  }
  get forMultiboard() {
    return !!this.data.forMultiboard;
  }
  upgradeToPro() {
    this.manage.upgrade('create-drawing-bigger-canvas', this.team ? 'team' : 'individual');
  }
  toRealSize(value: number) {
    return inchesToLocalUnits(this.model.settings, value / this.dpi);
  }
  cancel() {
    this.close.emit(undefined);
  }
  async submit() {
    if (!this.valid) return;

    this.error = undefined;

    let wnd: Window | null = null;

    try {
      if (this.create) {
        let id: string | undefined = undefined;

        if (this.activeTab === 1) {
          // add existing drawing to sequence
          id = urlToDrawingId(this.url);
          if (!id) throw new UserError(`Invalid drawing ID or URL`);

          logAction('[local] Add to sequence');
          await this.model.drawingAction(DrawingAction.AddToSequence, id);

          this.close.emit(undefined);
        } else {
          const width = this.width | 0;
          const height = this.height | 0;
          const password = this.proBlockPasswordChange ? undefined : this.password;

          if (this.openInNewWindow) wnd = window.open();

          if (!isImageSizeValid(width, height, this.allows)) throw invalidImageSizeUserError(this.allows);

          const data: CreateDrawingData = {
            name: this.name,
            x: 0,
            y: 0,
            width,
            height,
            background: this.background === 'none' ? undefined : this.background,
            dpi: clamp(this.dpi || DEFAULT_DPI, 1, 10000),
            password,
            copyPermissions: this.copyPermissions,
            addToSequence: this.addToSequence,
            sequenceIndex: this.sequenceIndex,
            respectOfflineOwners: this.respectOfflineOwners,
            team: this.data.team,
            project: this.data.project,
            folder: this.data.folder,
            multiboard: this.multiboard,
          };

          if (!this.data.forMultiboard) {
            id = await this.model.createDrawing(data);

            await this.appNotificationService?.updateNotificationSettings(id, this.appNotificationSetting);

            setEntityPassword(id, password);

            if (this.invite) this.model.sendMessage(`/invite ${id}${password ? ` ${password}` : ''}`);
          }

          this.close.emit(data);
        }

        if (id) {
          if (wnd) {
            wnd.location.href = `${location.protocol}//${location.host}${drawingsPath}${id}`;
          } else {
            navigateToDrawing(this.router, id, 'create-drawing-modal');
          }
        }
      } else if (this.drawing) {
        if (!hasPermission(this.drawing, this.model.user, 'drawingSettings'))
          throw new UserError(`You don't have permission to change drawing settings`);

        if (this.editor && (this.drawing.w !== this.width || this.drawing.h !== this.height)) {
          const rect = this.getResizeRect();
          cropDrawing(this.model, rect);
        }

        await this.model.updateDrawing({
          name: this.name,
          background: this.background === 'none' ? '' : this.background,
          dpi: this.dpi,
          respectOfflineOwners: this.respectOfflineOwners,
        });

        if (this.canChangePassword && this.password !== this.drawing.password) {
          // prevent non-pro from setting password, but allow to clear
          if (!(this.proBlockPasswordChange && this.password)) {
            await this.model.server.updateDrawingPassword(this.model.connId, this.password ?? '');
            setEntityPassword(this.drawing.id, this.password);
          }
        }

        await this.appNotificationService?.updateNotificationSettings(this.drawing.id, this.appNotificationSetting);

        this.close.emit(undefined);
      }
    } catch (e) {
      this.error = e.message;
      wnd?.close();
    }
  }
  async becomeAdmin() {
    this.error = undefined;

    try {
      await this.model.tryUserAction(UserAction.BecomeAdmin, 0);
    } catch (e) {
      this.error = e.message;
    }
  }

  // importing / adding existing drawing to sequence
  get thumbSrc() {
    const id = urlToDrawingId(this.url);
    return id && `${location.protocol}//${location.host}${getThumbPath(id)}`;
  }
  fileThumbUrl: string | undefined = undefined;
  fileThumbSrc: SafeUrl | undefined = undefined;
  file: File | undefined = undefined;
  fileSelected(input: any) {
    const file = input.files[0];

    if (this.fileThumbUrl) {
      URL.revokeObjectURL(this.fileThumbUrl);
      this.fileThumbSrc = undefined;
      this.fileThumbUrl = undefined;
    }

    this.file = file;

    if (file) {
      this.fileThumbUrl = URL.createObjectURL(file);
      this.fileThumbSrc = this.sanitizer.bypassSecurityTrustUrl(this.fileThumbUrl);
    }
  }
  getFileName(input: any) {
    return input.files[0]?.name;
  }
  getPermission(key: keyof DrawingPermissions) {
    return this.drawing?.permissions[key];
  }
  get showMultiboard() {
    return this.create && !!this.featureFlags.isFeatureSupported(Feature.Multiboard);
  }
  get showNotificationSettings() {
    return !!this.appNotificationService;
  }
  get teamAvatar() {
    return this.team ? this.manage.team(this.team)?.avatar : undefined;
  }
  get projectName() {
    return this.project ? this.manage.project(this.project)?.name : undefined;
  }
  async selectTeam() {
    const selectedFolder = await this.modals.selectFolder({
      teamId: undefined,
      showArtdesk: !disableMyArtdesk,
      mode: SelectMode.Create,
      preselectedFolder: { folderId: this.folder, projectId: this.project, teamId: this.team },
    });

    if (selectedFolder) {
      if (selectedFolder.entityId) {
        const folder = await this.manage.folder(selectedFolder.entityId);
        this.selectedFolderName = folder?.name;
      }
      this.data.folder = selectedFolder.entityId;
      this.data.project = selectedFolder.projectId;
      this.data.team = selectedFolder.teamId;

      this.refreshAllows();
    }
  }
  get team() {
    return this.data.team;
  }
  get project() {
    return this.data.project;
  }
  get folder() {
    return this.data.folder;
  }
  refreshAllows() {
    const teamData = this.data.team ? this.manage.team(this.data.team) : undefined;
    this.allows = getAllows(this.model.user, teamData);
  }
  getResizeRect() {
    if (!this.drawing) throw new Error('No drawing');
    const { x, y, w, h } = this.drawing;
    const dw = this.width - w;
    const dh = this.height - h;

    let nx = 0;
    let ny = 0;

    switch (this.resizeAnchor) {
      case ResizeAnchor.Center:
      case ResizeAnchor.CenterTop:
      case ResizeAnchor.CenterBottom:
        nx = x - Math.round(dw / 2);
        break;
      case ResizeAnchor.LeftTop:
      case ResizeAnchor.LeftCenter:
      case ResizeAnchor.LeftBottom:
        nx = x;
        break;
      case ResizeAnchor.RightTop:
      case ResizeAnchor.RightCenter:
      case ResizeAnchor.RightBottom:
        nx = x - dw;
        break;
      default:
        invalidEnum(this.resizeAnchor);
    }
    switch (this.resizeAnchor) {
      case ResizeAnchor.Center:
      case ResizeAnchor.LeftCenter:
      case ResizeAnchor.RightCenter:
        ny = y - Math.round(dh / 2);
        break;
      case ResizeAnchor.LeftTop:
      case ResizeAnchor.CenterTop:
      case ResizeAnchor.RightTop:
        ny = y;
        break;
      case ResizeAnchor.LeftBottom:
      case ResizeAnchor.CenterBottom:
      case ResizeAnchor.RightBottom:
        ny = y - dh;
        break;
      default:
        invalidEnum(this.resizeAnchor);
    }
    return createRect(nx, ny, this.width, this.height);
  }

  alignResize(anchor: ResizeAnchor) {
    this.resizeAnchor = anchor;
    this.setAnchor(anchor);
  }

  setAnchor(anchor: ResizeAnchor) {
    const { ax, ay } = this.getAnchor(anchor);
    this.ax = ax;
    this.ay = ay;
  }

  getAnchor(anchor: ResizeAnchor) {
    let ax = 0;
    let ay = 0;
    switch (anchor) {
      case ResizeAnchor.LeftTop:
      case ResizeAnchor.LeftCenter:
      case ResizeAnchor.LeftBottom:
        ax = -1;
        break;
      case ResizeAnchor.RightTop:
      case ResizeAnchor.RightCenter:
      case ResizeAnchor.RightBottom:
        ax = 1;
        break;
    }
    switch (anchor) {
      case ResizeAnchor.LeftTop:
      case ResizeAnchor.CenterTop:
      case ResizeAnchor.RightTop:
        ay = -1;
        break;
      case ResizeAnchor.LeftBottom:
      case ResizeAnchor.CenterBottom:
      case ResizeAnchor.RightBottom:
        ay = 1;
        break;
    }
    return { ax, ay };
  }

  canResizeDrawing() {
    return !this.create && this.editor && this.drawing && hasPermission(this.editor.drawing, this.editor.model.user, 'cropDrawing');
  }

  getIcon(anchor: ResizeAnchor) {
    const { ax, ay } = this.getAnchor(anchor);

    if (Math.abs(ax - this.ax) > 1) return;
    if (Math.abs(ay - this.ay) > 1) return;

    if (ax === this.ax && ay === this.ay) return faCircle;

    return faArrowLeft;
  }

  getIconRotation(location: ResizeAnchor) {
    if (!this.drawing) return 0;
    const { ax, ay } = this.getAnchor(location);
    const dx = this.ax - ax;
    const dy = this.ay - ay;

    let rot = 0;
    if (dx < 0) {
      if (dy < 0) rot = 180 + 45;
      else if (dy > 0) rot = 180 - 45;
      else rot = 180;
    } else if (dx > 0) {
      if (dy < 0) rot = -45;
      else if (dy > 0) rot = 45;
      else rot = 0;
    } else {
      if (dy < 0) rot = -90;
      else if (dy > 0) rot = 90;
      else rot = 0;
    }

    if (dy === 0) {
      if (this.width < this.drawing.w) rot += 180;
    } else if (dx === 0) {
      if (this.height < this.drawing.h) rot += 180;
    } else {
      if (this.width < this.drawing.w) {
        rot += 180;
        if (this.height > this.drawing.h) rot -= 90 * dx * dy;
      } else if (this.height < this.drawing.h) {
        rot += 180;
        if (this.width > this.drawing.w) rot += 90 * dx * dy;
      }
    }

    return rot;
  }
}
