import { trigger, transition, query, animateChild } from '@angular/animations';
import { Component } from '@angular/core';
import { ToastService } from 'magma/services/toast.service';
import { map } from 'rxjs/operators';
import { IMsg, MsgPosition } from 'magma/common/interfaces';

const makeUnique = (toasts: IMsg[]) => {
  const unique = new Map<string, IMsg>();
  toasts.forEach(toast => unique.set(toast.message, toast));
  return Array.from(unique.values());
};

@Component({
  selector: 'toast-container',
  templateUrl: './toast-container.component.pug',
  styleUrls: ['./toast-container.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition(':enter, :leave', [
        query('@*', animateChild()),
      ]),
    ]),
  ],
})
export class ToastContainerComponent {
  toasts$ = this.toastService.getToasts();

  constructor(private toastService: ToastService) {
  }

  readonly bottomLeftToasts$ = this.toasts$.pipe(
    map(toasts => toasts.filter(t => t.position === MsgPosition.BottomLeft)),
    map(makeUnique),
  );
  readonly topLeftToasts$ = this.toasts$.pipe(map(toasts => toasts.filter(t => t.position === MsgPosition.TopLeft)));
  readonly topRightToasts$ = this.toasts$.pipe(map(toasts => toasts.filter(t => t.position === MsgPosition.TopRight)));
  readonly bottomRightToasts$ = this.toasts$.pipe(map(toasts => toasts.filter(t => t.position === MsgPosition.BottomRight)));
}
