import { Subject } from 'rxjs';
import { Drawing, experiments, Feature } from '../common/interfaces';

export abstract class FeatureFlagService {
  featuresChanged$ = new Subject<void>();
  abstract isExperimentEnabled<T extends string & keyof typeof experiments>(experimentName: T, group: string): boolean;
  abstract isFeatureSupported(feature: Feature): boolean;
  abstract updateDrawing(drawing: Drawing): void;
  abstract updateUserFlags(featureFlags: Feature[]): void;

  get isActivityStatsBrush() {
    return this.isExperimentEnabled('activity-stats-brush', 'a');
  }
    
  get isBrighterInviteButton() {
    return this.isExperimentEnabled('brighter-invite-button', 'a');
  }
    
  get isPermanentInviteButton() {
    return this.isExperimentEnabled('brighter-invite-button', 'b');
  }
}
