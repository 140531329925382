import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ManageService } from '../../../../services/manageService';
import { CUSTOMER_INSIGHTS_GROUP_NEW, CUSTOMER_INSIGHTS_GROUP_RECCURING, CustomerInsightGroups } from '../../../../common/constants';
import { Model } from '../../../../services/model';
import { InterviewInviteStatus } from '../../../../common/interfaces';

@Component({
  selector: 'customer-insights-invite-modal',
  templateUrl: 'customer-insights-invite-modal.pug',
  styleUrls: ['customer-insights-invite-modal.scss'],
})
export class CustomerInsightsInviteModal {
  readonly CUSTOMER_INSIGHTS_GROUP_NEW = CUSTOMER_INSIGHTS_GROUP_NEW;
  readonly CUSTOMER_INSIGHTS_GROUP_RECCURING = CUSTOMER_INSIGHTS_GROUP_RECCURING;
  @Input() data!: { group: CustomerInsightGroups };
  @Output() close = new EventEmitter<void>();
  
  email = 'insights@magma.com';

  calendarLinks = {
    [CUSTOMER_INSIGHTS_GROUP_NEW]: 'https://magm.ai/invite-new',
    [CUSTOMER_INSIGHTS_GROUP_RECCURING]: 'https://magm.ai/invite-recurring'
  };

  constructor(private manage: ManageService, private model: Model) { }

  get username() {
    return this.model.user.name;
  }

  get participated() {
    return this.manage.participatedDrawings();
  }

  async reject() {
    await this.manage.updateCustomerInsightsInviteStatus(InterviewInviteStatus.REJECTED);
    this.close.emit();
  }

  async askLater() {
    await this.manage.updateCustomerInsightsInviteStatus(InterviewInviteStatus.POSTPONE);
    this.close.emit();
  }

  async schedule() {
    await this.manage.updateCustomerInsightsInviteStatus(InterviewInviteStatus.CONFIRMED);
    window.open(this.calendarLinks[this.data.group]);
    this.close.emit();
  }
}
