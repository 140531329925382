import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faCopy } from '../../../common/icons';
import { delay } from '../../../common/promiseUtils';
import { copy, isMobile } from '../../../common/utils';

@Component({
  selector: 'copy-box',
  templateUrl: 'copy-box.pug',
  styleUrls: ['copy-box.scss'],
})
export class CopyBox {
  readonly faCopy = faCopy;
  readonly faCheck = faCheck;
  @Input() text = '';
  @Input() copyMessage = 'Click to copy the link to clipboard';
  @Output() copied = new EventEmitter<string>();
  showCopied = false;
  async copy(target: HTMLInputElement) {
    await copy(target);
    this.showCopied = true;
    await delay(1000);
    this.showCopied = false;
    this.copied.emit('button');
  }
  keydown(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) { // C
      this.copied.emit('ctrl+c');
    }
  }
  get isMobile() {
    return isMobile;
  }
}
