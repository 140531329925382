import { keys } from '../baseUtils';
import { DEFAULT_STABILIZE } from '../constants';
import { faTint } from '../icons';
import { CompositeOp, Feature, IToolEditor, IToolModel, ToolId } from '../interfaces';
import { PaintBrush, PaintBrushMode } from '../paintBrush';
import { BaseBrushTool } from './baseBrushTool';
import { BRUSH_FIELDS, decodePercent, encodePercent, IBrushToolData, roundPercent } from './brushUtils';

export class BlendTool extends BaseBrushTool {
  id = ToolId.Blend;
  name = 'Blend';
  icon = faTint;
  feature = Feature.Blend;
  stabilize = DEFAULT_STABILIZE;
  fields = keys<BlendTool>([...BRUSH_FIELDS, 'stretch']);
  hardness = 0.5;
  opacity = 1.0;
  flow = 0.8;
  spacing = 0.2;
  stretch = 0.4;
  moveLayerToSurface = true;
  protected compositeOperation = CompositeOp.Overwrite;

  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model);
  }

  protected setupBrush(brush: PaintBrush) {
    this.spacing = 0.2;
    super.setupBrush(brush);
    brush.setMode(PaintBrushMode.Blend);
    brush.blendStretch = roundPercent(this.stretch);
  }

  protected getData() {
    const data = super.getData();
    data[1] = encodePercent(this.stretch);
    return data;
  }

  protected setData(data?: IBrushToolData) {
    super.setData(data);
    this.stretch = data ? decodePercent(data.p[1]) : this.stretch;
  }
}