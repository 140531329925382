/// <reference path="../my.d.ts" />

import type { SocketService } from 'ag-sockets';
import type { Subject } from 'rxjs';
import type Stripe from 'stripe';
import { MaxLength, MinLength } from '@deepkit/type';
import type { IThreadService } from '../services/thread.service.interface';
import type { ToastService } from '../services/toast.service';
import type { Textarea, TextareaOptions } from './text/textarea';
import type { Mandatory } from './typescript-utils';
import type { ITrackService } from '../services/track.service.interface';
import type { AiCheckpointFileName, AiControlNetModelName, AiControlNetPreprocessorName, AiModel, InpaintFill } from './aiInterfaces';
import type { FeatureFlagService } from '../services/feature-flag.service.interface';
import type { TextSelectionDirection } from './text/navigation';
import type { ReadyTextLayer } from './text/text-utils';
import { UserFlowId } from './constants';
import { BufferedEncoder } from './compressor';
import type { Deferred } from './promiseUtils';
import type { CreateJamData, JamData } from './interfaces-jams';
import type { ImageInfo } from './imageUtils';

/**
 * T is usually an enum, but TypeScript doesn't support using enums as bit flags,
 * so we use this special type here. It returns `number` since bit flags are in
 * our case just numbers. This is important so serializers correctly convert the data
 * and validators correctly validate the data.
 *
 * @example
 * ```typescript
 *  enum MyEnum {
 *   A = 1,
 *   B = 2,
 *   C = 4,
 *  }
 *
 *  class MyDto {
 *    flags: BitFlags<MyEnum> = 0;
 *  }
 * ```
 */
export type BitFlags<T> = number & { __flags?: never & T };

// note: we can't move to constants.ts as this would lead to a circular import
export const COMMENT_MAX_LENGTH = 10 * 1000;

// NOTE: also add new features to FEATURE_LIST in feature-list.ts

// @ignore-translate
export const enum Feature {
  Timelapse = 'timelapse', // can be added to team or drawing

  Hid = 'hid', // enable WebHID
  Presentation = 'presentation', // allow starting presentations
  PresentationObligeJoining = 'presentation:oblige-joining', // allow the presenter to oblige users to join the presentation

  BrushesGrut = 'brushes-grut',
  BrushesIntq20 = 'brushes-intq20',
  BrushesIntq49 = 'brushes-intq49',
  BrushesOmri = 'brushes-omri',
  BrushesRoss = 'brushes-ross',
  BrushesFantasio = 'brushes-fantasio',
  BrushesBradley = 'brushes-bradley',
  BrushesExperimental = 'brushes-experimental',

  ShapesGrids = 'shapes-grids',

  Ai = 'ai', // enable AI
  AiNsfw = 'ai:nsfw', // disable NSFW filter for AI
  AiEula = 'ai:eula', // agreed to AI EULA
  AiBeta = 'ai:beta', // enable access to beta ai options
  AiNoPromptFilter = 'ai:no-prompt-filter', // disable filtering restricted keywords in prompt
  AiNoUsageLimits = 'ai:no-usage-limits', // disable ai worker quota
  AiDreambooth = 'ai:dreambooth', // enable access to dreambooth

  StorageNoUsageLimits = 'storage:no-usage-limits', // StorageNoUsageLimits

  VersionHistory = 'version-history', // enables UI for version history

  Drawing16k = '16k',
  ContentPages = 'content-pages',
  BillingSkipBlazeLimit = 'billing:skip-blaze-limit',
  NoFrameLimit = 'sequences:no-frame-limit',
  PerspectiveGrid = 'perspective-grid',

  StoryProtocolRegistration = 'story-protocol-registration',
  RemixingRevisions = 'remixing-revisions',

  Jams = 'jams',
  Multiboard = 'multiboard',

  UniqueUsernames = 'unique-usernames',
  AdjustUsername = 'adjusting-username',

  EmbeddedCommunityGoal = 'embedded:community-goal',

  Blend = 'blend',
}

// @ignore-translate
export const experiments: { [name: string]: string[] } = {
  'activity-stats-brush': ['a', 'b'], //a means enabled, b means disabled
  'brighter-invite-button': ['a', 'b'], //a means enabled, b means disabled
};

export const DEV_FEATURE_FLAGS: Feature[] = [ // flags available in DEV panels
  Feature.BrushesGrut, Feature.BrushesIntq20, Feature.BrushesIntq49, Feature.BrushesOmri, Feature.BrushesRoss,
  Feature.BrushesFantasio, Feature.BrushesBradley, Feature.BrushesExperimental,
  Feature.AiEula, Feature.AiDreambooth, Feature.Drawing16k,
];

export const ALPHA_FEATURE_FLAGS: Feature[] = [ // have to be enabled manually (marked as BETA)
  Feature.ShapesGrids,
];

export const BETA_FEATURE_FLAGS: Feature[] = [ // have to be enabled manually (marked as BETA)
  Feature.Presentation, Feature.PresentationObligeJoining,
  Feature.Ai, Feature.AiNsfw, Feature.AiBeta, Feature.AiNoPromptFilter, Feature.AiNoUsageLimits,
  Feature.Jams, Feature.Multiboard, Feature.EmbeddedCommunityGoal
];

export const OPEN_BETA_FEATURE_FLAGS: Feature[] = [ // enabled for everyone (marked as BETA)
  Feature.VersionHistory, Feature.PerspectiveGrid,
];

export const PUBLIC_FEATURE_FLAGS: Feature[] = [ // enabled for everyone
  Feature.ContentPages,
];

export const SPECIAL_FEATURE_FLAGS: Feature[] = [ // special flags that can be enabled only manually
  Feature.Hid, Feature.StorageNoUsageLimits, Feature.NoFrameLimit, Feature.UniqueUsernames, Feature.AdjustUsername, Feature.StoryProtocolRegistration, Feature.RemixingRevisions, Feature.Timelapse, Feature.EmbeddedCommunityGoal, Feature.Blend,
];

export const SPECIAL_FEATURE_FLAGS_FOR_TEAMS: Feature[] = [ // special flags that can be enabled only manually (teams)
  Feature.StorageNoUsageLimits,
];

export const SPECIAL_FEATURE_FLAGS_FOR_TEAMS_BILLING: Feature[] = [ // special billing flags that can be enabled only manually (teams)
  Feature.BillingSkipBlazeLimit,
];

const betaFeatures = new Set([...DEV_FEATURE_FLAGS, ...ALPHA_FEATURE_FLAGS, ...BETA_FEATURE_FLAGS, ...OPEN_BETA_FEATURE_FLAGS]);

export function isBetaFeature(feature: Feature) {
  return betaFeatures.has(feature);
}

// @ignore-translation
export function getDefaultFeatureFlags(level: string | undefined) {
  switch (level) {
    case 'dev':
    case 'alpha':
    case 'beta':
    case 'open-beta':
      return [...OPEN_BETA_FEATURE_FLAGS, ...PUBLIC_FEATURE_FLAGS];
    default:
      return PUBLIC_FEATURE_FLAGS;
  }
}

// @ignore-translation
export function getAvailableFeatureFlags(level: string | undefined) {
  switch (level) {
    case 'dev': return [...DEV_FEATURE_FLAGS, ...ALPHA_FEATURE_FLAGS, ...BETA_FEATURE_FLAGS, ...SPECIAL_FEATURE_FLAGS];
    case 'alpha': return [...ALPHA_FEATURE_FLAGS, ...BETA_FEATURE_FLAGS, ...SPECIAL_FEATURE_FLAGS];
    case 'beta': return [...BETA_FEATURE_FLAGS, ...SPECIAL_FEATURE_FLAGS];
    default: return [...SPECIAL_FEATURE_FLAGS];
  }
}

export function getAvailableFeatureFlagsForTeam(level: string | undefined) {
  return [
    ...getAvailableFeatureFlags(level),
    ...SPECIAL_FEATURE_FLAGS_FOR_TEAMS,
    ...(IS_HOSTED ? [] : SPECIAL_FEATURE_FLAGS_FOR_TEAMS_BILLING)
  ];
}

export enum Permission {
  isTeamOwner = 0,
  CanCreateEntities = 1,
  CanUpdateEntities = 2,
  CanDuplicateEntities = 3,
  CanDeleteEntities = 4,
  CanExportEntityAsImage = 5,
  CanUpdateEntityPassword = 6,

  CanEditEntities = 7,
  CanViewEntities = 8,

  CanReadThreadsAndComments = 9,
  CanCreateThreadsAndComments = 10,
  CanDeleteAllThreadsAndComments = 11,

  // teams
  CanManageInvites = 13,

  CanInviteOthersToEntities = 15, // add contributor
  CanInviteOthersToProjects = 16, // add contributor

  CanMoveEntitiesOutsideTeam = 17,
  CanManageTeamBilling = 18,

  CanManageTeam = 19,

  CanManageTeamMembers = 21,
  CanManageTeamRoles = 23,

  // projects
  CanSeeRestrictedProjectsAndEntities = 24,
  CanCreateProjects = 25,
  CanUpdateProjects = 26,
  CanDeleteProjects = 27,

  CanDeleteEntitiesPermanently = 28,

  CanViewTeamMembers = 29,

  CanManagePosts = 30,

  CanCreateRevision = 31,
  CanViewRevisions = 32,
  CanRestoreRevision = 33,
  CanUpdateRevision = 34,
  CanRemoveRevision = 35,

  CanViewPosts = 36,
}

export function getPermissionFlags(flagsBit: number[]) {
  const flags = [0];
  for (const bit of flagsBit) {
    const i = (bit / 32) | 0;
    while (flags.length < i) flags.push(0); // ensure we don't have any `undefined` values in the middle
    flags[i] = flags[i] | (1 << (bit % 32));
  }
  return flags;
}

export const PERMISSION_LIST = Object.values(Permission).filter(k => !isNaN(Number(k))) as number[];

export type Vec2 = Float32Array;
export type Vec3 = Float32Array;
export type Mat2d = Float32Array;
export type Mat4 = Float32Array;

export const enum BrokerKey {
  DrawingOpened = 'drawing-opened',
  DrawingClosed = 'drawing-closed',
  DrawingChanged = 'drawing-changed',
  DrawingInvitation = 'drawing-invitation',
}

export interface BrokerMessageDrawingChanged {
  id: string;
  shortId: string;
  userId: string;
  teamId: string | null;
}

export interface BrokerMessageDrawingClosed {
  shortId: string;
}

export interface BrokerMessageDrawingInvitation {
  invitingUserId: string;
  usersToInvite: string[];
  entityId: string;
  uiElement: string;
}

export interface TriangleBatch {
  gl: WebGLRenderingContext;
  capacity: number; // triangles
  count: number; // triangles
  index: number;
  verticesF32: Float32Array;
  smallBufferSize: number;
  largeBuffers: WebGLBuffer[];
  smallBuffers: WebGLBuffer[];
  activeLargeBuffer: number;
  activeSmallBuffer: number;
}

export interface Shader {
  program: WebGLProgram;
  uniforms: { [key: string]: WebGLUniformLocation; };
}

export interface BlurShader extends Shader {
  coeffs: WebGLUniformLocation[];
}

export interface BitmapData {
  width: number;
  height: number;
  data: Uint8Array;
  premultiplied?: boolean; // for webgl renderer
}

export interface CompressedImageData {
  width: number;
  height: number;
  compressed: Uint8Array;
}

export interface PendingPixels {
  id?: string;
  layer?: Layer;
  rect?: Rect;
  sync: WebGLSync;
  buffer: WebGLBuffer;
}

export const enum TextureFormat {
  RGBA = 0,
  Alpha = 1,
}

export interface Texture {
  id: string;
  width: number;
  height: number;
  format: TextureFormat;
  handle: WebGLTexture;
  webglId: number;
  hasMipmaps: boolean;
}

export interface CachedBrush {
  id: string;
  texture: Texture;
}

export interface WebGLResources {
  name: string;
  gl: WebGLRenderingContext;
  webgl2: boolean;
  batch: TriangleBatch;
  shaders: Map<string, Shader>;
  gaussianBlurShaders: Map<number, Shader>;
  emptyTexture: Texture;
  whiteTexture: Texture;
  frameBuffer: WebGLFramebuffer;
  tempCanvas: HTMLCanvasElement;
  textures: Texture[];
  allocatedTextures: Texture[];
  pendingLayerThumb?: PendingPixels;
  pendingDrawingThumb?: PendingPixels;
  thumbnailTexture: Texture;
  namePlatesTexture: Texture | undefined;
  videoPlatesTexture: Texture | undefined;
  selfVideoTexture: Texture | undefined;
  namePlatesMode: CursorsMode;
  vertexShader: WebGLShader;
  brushCache: CachedBrush[];
  markers: { x: number; y: number; color: number }[];
  maskTexture: Texture | undefined; // TODO: use mutliple with more users ?
  maskCacheId: number;
  maskRect: Rect;
  highp: boolean;
  fallbackCursorsTexture: Texture | undefined;
  spritesTexture: Texture | undefined;
  params: RendererParams;

  frameBufferWidth: number; // size of currently active frame buffer
  frameBufferHeight: number;

  cropLabelTexture: Texture | undefined;
  cropLabelPixelRatio: number;
  cropLabelTextureRect: Rect;

  // used only in development
  texturesToDebug: Texture[];
  tempDebugTexture: Texture | undefined;
  benchmarkTexture: Texture | undefined; // fps chart
}

export const enum CompositeOp {
  None,
  Draw,
  Erase,
  Move, // TODO: rename to Transform
  Overwrite,
}

export interface Point {
  x: number;
  y: number;
}

export interface Rect {
  x: number;
  y: number;
  w: number;
  h: number;
}

// Used for correctly drawing rects when viewport is rotated
export interface RectCorners {
  topLeft: Point;
  topRight: Point;
  bottomRight: Point;
  bottomLeft: Point;
}

export type ViewFilter = 'grayscale' | undefined;

export interface ViewportState {
  x: number;
  y: number;
  scale: number;
  rotation: number;
  flipped: boolean;
  filter: ViewFilter;
}

export interface Viewport {
  x: number;
  y: number;
  scale: number;
  rotation: number;
  flipped: boolean;
  filter: ViewFilter;
  width: number;
  height: number;
  content: Rect;
}

export interface PolySegment {
  items: Int32Array;
  size: number;
}

export interface Poly {
  ox: number;
  oy: number;
  segments: PolySegment[];
}

export type PolyfSegment = number[];

export interface Polyf {
  ox: number;
  oy: number;
  segments: PolyfSegment[];
}

export interface Mask {
  cacheId: number;
  bounds: Rect;
  poly: Poly | undefined;
}

export const enum CursorType {
  Default = 'cursor-default',
  None = 'cursor-none',
  Move = 'cursor-move',
  Text = 'cursor-text',
  Hand = 'cursor-hand',
  Eyedropper = 'cursor-eyedropper',
  Paintbucket = 'cursor-paintbucket',
  Pointer = 'cursor-pointer',
  Rotate = 'cursor-rotate',
  ZoomIn = 'cursor-zoom-in',
  ZoomOut = 'cursor-zoom-out',
  Crosshair = 'cursor-selection',
  SelectionAdd = 'cursor-selection-add',
  SelectionSubtract = 'cursor-selection-subtract',
  SelectionIntersect = 'cursor-selection-intersect',
  SelectionMove = 'cursor-selection-move',
  MoveControl = 'cursor-move-control',
  ResizeH = 'cursor-resize-h',
  ResizeV = 'cursor-resize-v',
  ResizeTR = 'cursor-resize-tr',
  ResizeTL = 'cursor-resize-tl',
  NotAllowed = 'cursor-not-allowed',
  Loading = 'cursor-loading',
  Remove = 'cursor-remove',
  Circle = 'cursor-circle', // rendered on canvas
  Square = 'cursor-square', // rendered on canvas
  Image = 'cursor-image', // rendered on canvas
}

export interface Cursor {
  x: number;
  y: number;
  size: number;
  type: CursorType;
  show: boolean;
  classic: boolean;
  lastX: number;
  lastY: number;
  lastSize: number;
  lastType: CursorType;
  lastVisible: boolean;
  lastShow: boolean;
  useSynthetic: boolean;
}

export type RendererApi = 'webgl' | 'webgl2' | '2d-off' | '2d-fail' | 'webgpu';

export type RendererChangeReson = 'texture-size-limit-reached' | 'error' | 'user-action';

export interface Announcement {
  message: string;
  type: string;
}

export interface Logger {
  log(message: string): void;
}

export interface IUndoFunction {
  (): IUndoFunction;

  type?: string;
  layerId?: number;
  free?: () => void;
  lastX?: number;
  lastY?: number;
  isMove?: boolean;
  isText?: boolean;
  textSelection?: TextSelection;
  textAction?: string;
  pre?: boolean;
  skipVerify?: boolean;
}

export interface IBackgroundColor {
  name: string;
  value: string;
}

export interface ICanvasProvider {
  stats: string;
  used: { id: string; canvas: HTMLCanvasElement }[];
  create(id: string, width: number, height: number): HTMLCanvasElement;
  release(canvas: HTMLCanvasElement | undefined): undefined;
  clear(): void;
}

// Tablet event

export const enum EventType {
  Start = 0,
  Move = 1,
  End = 2,
  Wheel = 3,
  Hover = 4,
  Cancel = 5,
}

export const eventTypeNames = ['start', 'move', 'end', 'wheel', 'hover'];

export const enum TabletEventButton {
  Left = 0,
  Middle = 1,
  Right = 2,
  Button4 = 3,
  Button5 = 4,
  Eraser = 5, // ???
}

/** @reflection no */
export const enum TabletEventFlags {
  None = 0,

  ShiftKey = 0b00001000,
  AltKey = 0b00010000,
  CtrlKey = 0b00100000,
  MetaKey = 0b01000000,
  ModifierKeys = ShiftKey | AltKey | CtrlKey | MetaKey,

  MissingPressure = 0b01000000_00000000,
  Touch = 0b10000000_00000000,
  Button = 0b001111111_00000000,
}

export const enum TabletEventSource {
  Mouse = 0,
  Touch = 1,
  Pen = 2,
}

export type PressureApi = 'none' | 'pen' | 'hid' | 'ext' | 'force';

export interface TabletEvent {
  timeStamp: number;
  type: EventType;
  flags: TabletEventFlags;
  x: number;
  y: number;
  pressure: number;
  tiltX: number;
  tiltY: number;
  deltaX: number;
  deltaY: number;
  source: TabletEventSource;
}

export const enum ToolError {
  NoError = 0,
  EditorLocked,
  DrawingNotLoaded,
  NoActiveTool,
  LayerIsEmpty,
  InvalidViewport,
  MissingShape,
  AccessDenied,
  UnableToDrawOnActiveLayer,
  ImpossibleOnTextLayer,
  ImpossibleOnTextLayerInvokedRasterizing,
  UnableToInteractWithTextLayer,
  BlockedByPresentationMode,
  WaitingForServerResponse,
  WaitingForFontsToLoad,
  ImpossibleOnPerspectiveGridLayer,
  NotUsableOnMultiboard,
  OnlyUsableOnMultiboard,
}

export function getTabletEventButton(event: TabletEvent) {
  return (event.flags & TabletEventFlags.Button) >> 8;
}

export function hasShiftKey(event: TabletEvent) {
  return (event.flags & TabletEventFlags.ShiftKey) !== 0;
}

export function hasShiftKeyOnly(event: TabletEvent) {
  return (event.flags & TabletEventFlags.ModifierKeys) === TabletEventFlags.ShiftKey;
}

export function hasAltKey(event: TabletEvent) {
  return (event.flags & TabletEventFlags.AltKey) !== 0;
}

export function hasCtrlKey(event: TabletEvent) {
  return (event.flags & TabletEventFlags.CtrlKey) !== 0;
}

export function hasCtrlOrMetaKey(event: TabletEvent) {
  return (event.flags & (TabletEventFlags.CtrlKey | TabletEventFlags.MetaKey)) !== 0;
}

// Data

export interface RecentDrawingData {
  id: string;
  name: string;
}

export interface SafeUserData {
  accountId: string;
  uniqId: string;
  localId: number;
  name: string;
  color: string;
  avatar?: string;
  role?: UserRole;
  anonymous: boolean;
  anonymousNumber: number;
  activeLayerId?: number;
  ownedLayers: number[];
  readOnly?: boolean;
  tags?: string[];
}

export interface ShapeSet {
  id: string;
  name: string;
  info?: boolean;
}

export interface CompressedBrush {
  name: string;
  [key: string]: any;
  // TODO: add fields ?
}

export interface BrushSet {
  id: string;
  name: string;
  info?: boolean;
  brushes: CompressedBrush[]; // this might be few KB ???
}

export type UserRole = 'all' | 'approved' | 'admin' | 'owner';
const userRoles: UserRole[] = ['all', 'approved', 'admin', 'owner'];

export function getUserRolePriority(value: UserRole) {
  return userRoles.indexOf(value);
}

export function isValidUserRole(value: string | undefined) {
  return value === 'all' || value === 'approved' || value === 'admin';
}

export interface UserData extends SafeUserData {
  isSuperAdmin?: boolean;
  featureFlags?: Feature[];
  email?: string;
  created?: string;
  lastVisit?: string;
  lastAction?: string;
  userAgent?: string;
  opened?: string;
  isCreator?: boolean;
  subscriptionStatus?: SubscriptionStatus;
  adminUrl?: string;
  userJob?: string;
  workTags?: string[];
  receiveEmails?: boolean;
  pro?: SubscriptionPlan;
  proSources?: string[];
  optedIntoAds?: boolean;
  hasOnboarding?: boolean;
  publishers?: UserPublishersSettings;
}

export interface UserDataLive extends Omit<UserData, 'isCreator' | 'role' | 'localId' | 'activeLayerId' | 'ownedLayers' | 'recentDrawings'> {
  id: number;
  socket?: string;
  drawings: string[];
  origin: string;
  pressureApi?: string;
  rendererApi?: string;
}

export enum PublisherId {
  Template = 'template',
  StoryProtocol = 'StoryProtocol',
}

export type PublisherProviderOptions = TemplateOptions | StoryProtocolOptions;

export interface TemplateOptions {
  author: string;
}

export interface StoryProtocolOptions {
  linked: true;
  walletId?: string;
}

export type UserPublishersSettings = {
  [PublisherId.StoryProtocol]?: StoryProtocolOptions,
}

export interface User {
  uniqId: string; // unique for entire lifetime of user browser tab, all connections to sockets have the same uniqId
  localId: number; // unique to single drawing opening, changes after reload, refresh, reconnect, or switching to a different drawing
  name: string;
  anonymous: boolean;
  anonymousNumber: number;
  isSuperAdmin: boolean;
  color: string;
  colorFloat: Float32Array;
  avatar: string | undefined;
  avatarImage: HTMLImageElement | undefined;
  talking: boolean;
  role: UserRole;
  activeLayer: Layer | undefined;
  activeLayerId: number;
  ownedLayers: number[];
  activeTool: ITool | undefined;
  lastToolStartData: IToolData | undefined; // used for checking for errors in production
  lastTool: ITool | undefined;
  selection: Mask;
  lastSelection: Mask;
  showTransform: boolean | undefined;
  decoder: ToolDecoder;
  history: IHistory;
  surface: ToolSurface;
  cursorX: number; // document space cursor position
  cursorY: number;
  cursorAlpha: number;
  cursorDelay: number;
  cursorLastUpdate: number;
  lastX: number;
  lastY: number;
  readOnly: boolean;

  // incoming chunked data
  chunkedData: Map<number, Uint8Array>;

  // only for self
  accountId?: string; // TODO: remove from here, use user profile instead
  email?: string; // TODO: remove from here, use user profile instead
  hash?: string; // email hash for tawk // TODO: remove from here, use user profile instead
  isCreator?: boolean; // TODO: remove, this is now replaced with 'owner' role
  pro?: SubscriptionPlan; // TODO: remove from here, use user profile instead
  proSources?: string[]; // TODO: remove from here, use user profile instead
  subscriptionStatus?: SubscriptionStatus; // TODO: remove from here, use user profile instead
  adminUrl?: string; // TODO: remove from here, use user profile instead
  userJob?: string; // TODO: remove from here, use user profile instead
  workTags?: string[]; // TODO: remove from here, use user profile instead
  receiveEmails?: boolean; // TODO: remove from here, use user profile instead
  lastNewFeature?: string; // TODO: remove from here, use user profile instead

  created?: number; // account creation date // TODO: remove from here, use user profile instead
  featureFlags: Set<Feature>; // don't check directly for feature flag existence, use FeatureFlagService (client) or isFeatureFlagSupported (server) // TODO: remove from here, use user profile instead

  tags?: string[]; // TODO: remove from here, use user profile instead
  optedIntoAds?: boolean; // TODO: remove from here, use user profile instead
}

export interface ToolSets {
  shapeSets: ShapeSet[];
  brushShapeSets: ShapeSet[]; // TODO: if we're loading by sets what if some brush wants shape from non-loaded set?
  brushSets: BrushSet[];
}

export interface Allows {
  pro?: SubscriptionPlan;
  isSuperAdmin?: boolean;
  features?: Set<Feature>;
}

export interface Social {
  provider: string;
  name: string;
  url?: string;
}

export interface SubscriptionStatus {
  status: Stripe.Subscription.Status;
  cancelAtPeriodEnd: boolean;
  currentPeriodEnd: number;
}

export type BillingInterval = 'day' | 'week' | 'month' | 'year';

export const enum AccountFlags {
  None = 0,
  Banned = 1,
}

export interface AccountData {
  name?: string;
  nameLower?: string; // TEMP
  email?: string;
  password?: string;
  color?: string;
  avatar?: string;
  userJob?: string;
  receiveEmails?: boolean;

  flags?: AccountFlags;
  isSuperAdmin?: boolean;
  featureFlags?: Feature[];
  id?: string;
  createdAt?: string;
  lastOrigin?: string;
  lastVisit?: string;
  lastActive?: string;
  lastUserAgent?: string;
  recentDrawings?: RecentDrawingData[];

  socials?: Social[];

  pro?: SubscriptionPlan;
  forcePro?: boolean;
  forceProUntil?: string;
  stripeCustomerId?: string;
  subscriptionStatus?: SubscriptionStatus;
}

export interface CreateAccountData {
  name: string;
  avatar: string;
  color: string;
  email: string;
  password: string;
  userJob: string | undefined;
  receiveEmails: boolean | undefined;
  forced?: boolean;
  token?: string;
  repeatPassword?: string;
  referralCode?: string;
}

export interface CreateOauthAccountData {
  name: string;
  avatar: string;
  color: string;
  userJob: string | undefined;
  receiveEmails: boolean | undefined;
  referralCode?: string;
}

export interface UpdateAccountData {
  name?: string;
  avatar?: string;
  color?: string;
  email?: string;
  password?: string;
  userJob?: string | undefined;
  receiveEmails?: boolean | undefined;
  referralCode?: string;
}

export interface CreatorData {
  id: string; // account id
  name: string;
  avatar?: string;
  sessionId?: string;
  flags?: number; // only portal
  createdAt?: string; // only portal
  updatedAt?: string; // only portal
  hiddenForUser?: boolean; // only portal
  featureFlags?: Feature[];  // only portal
}

export interface CreatorDataRole {
  id: string | undefined;
  sessionId: string | undefined;
  name: string;
  role: UserRole | undefined;
}

export interface LayerData {
  id: number;
  name?: string;
  mode?: string;
  image?: string;
  opacity?: number;
  opacityLocked?: boolean;
  clippingGroup?: boolean;
  visible?: boolean;
  locked?: boolean;
  rect?: Rect;
  flags?: LayerFlag;
  textData?: TextareaOptions;
  perspectiveGrid?: PerspectiveGridLayerData;
  fontsLoaded?: boolean;
  ref?: Partial<DrawingReference>;
}

export interface LayerUpdateData {
  id: number;
  visible?: boolean;
  locked?: boolean;
  clippingGroup?: boolean;
  mode?: string;
  name?: string;
  opacity?: number;
  opacityLocked?: boolean;
}

export interface WorkerInfo {
  id: string;
  live: boolean;
  usage: number;
  lastUpdate: number;
  drawings: number;
  activeDrawings: number;
  drawingsOpened: number;
  memory: number;
  memoryDetails: string;
  pendingTasks: number;
}

export interface ServerInfo {
  id: string;
  path: string;
  host: string;
  live: boolean;
  online: boolean;
  usage: number;
  users: number;
  drawings: number;
  drawingsDetails?: string;
  activeDrawings: number;
  lastDrawAction: number;
  lastUpdate: number;
  workers: WorkerInfo[];
  memory: number;
  memoryDetails: string;
  uptime: number;

  activeJams: number;
  activeJamsUsers: number;
}

export enum DrawingFlags {
  None = 0,
  Imported = (1 << 0), // created by importing image file
  Pro = (1 << 1), // created or claimed by pro user (used only to hide non-commertial notice at the bottom, we might just remove it)
  Public = (1 << 2), // we detected entry from social website (twitter)
  Cloned = (1 << 3), // cloned from another drawing
  Jams = (1 << 4), // drawing from art jams - it changes thumbnail intervals TODO list it here
}

export interface DrawingPermissions {
  chat?: UserRole;
  addRemoveLayer?: UserRole;
  ownLayer?: UserRole;
  reorderLayers?: UserRole;
  drawingSettings?: UserRole;
  paste?: UserRole;
  cursors?: UserRole;
  takeOver?: UserRole;
  voiceListen?: UserRole;
  voiceTalk?: UserRole;
  createComments?: UserRole;
  startPresentation?: UserRole;
  takeOverPresentation?: UserRole;
  useAiTool?: UserRole;
  cropDrawing?: UserRole;
}

export const defaultDrawingPermissions: Required<DrawingPermissions> = {
  chat: 'all',
  addRemoveLayer: 'all',
  ownLayer: 'all',
  reorderLayers: 'all',
  drawingSettings: 'all',
  paste: 'all',
  cursors: 'all',
  takeOver: 'admin',
  voiceListen: 'all',
  voiceTalk: 'all',
  createComments: 'all',
  startPresentation: 'admin',
  takeOverPresentation: 'admin',
  useAiTool: 'all',
  cropDrawing: 'admin'
};

export interface SequenceData {
  id: string;
  mainDrawingId: string | undefined;
  drawings: SequenceDrawingData[];
}

export interface SequenceDrawingData {
  _id: string;
  id: string;
  name: string;
  cacheId?: string;
}

// content related data
export interface CommonDrawingData extends Rect {
  _id: string;
  id: string; // shortId
  type: DrawingType;
  name: string;
  background: string | undefined;
  layers: LayerData[];
  dpi: number;
}

export type ArtworkLicensingClientRevision = Pick<DrawingRevision, 'shortId' | 'publishedIn' | 'name' | 'cacheId' | 'label' | 'inheritedLicense'>;

// THESE VALUES ARE DETERMINED BY STORY, HARDCODED HERE FOR CONVENIENCE
export enum LicenseTermId {
  NonCommercialRemix = 1,
  CommercialRemix = 2, // irrelevant for now, potentially outdated value
  CommercialUse = 3, // irrelevant for now, potentially outdated value
}

export interface LicenseRights {
  othersMay: string[];
  othersMayNot: string[];
}

export type ArtworkLicensingData = {
  providerId: PublisherId,
  drawing?: Drawing;
  rights?: LicenseRights;
  revision?: ArtworkLicensingClientRevision,
  inheritedLicense?: ClientInheritedLicense,
};

export type ArtworkLicensingRequestData = {
  providerId: PublisherId,
  entityId: string,
  revisionId: string,
};

export interface ClientParticipant {
  id: string;
  name: string;
  flags: number;
  avatar?: string;
}

// management related data
export interface SharedDrawingData extends CommonDrawingData {
  permissions?: DrawingPermissions;
  respectOfflineOwners?: boolean; // true if we DON'T allow taking over from offline owners // TODO: make non-nullable ?
  layersPerUser?: number; // TODO: make non-nullable ?
  password?: string;
  featureFlags?: Feature[];
  team?: string;
  project?: string;
  folder?: string;
  licensing?: ArtworkLicensingData;
  publishedIn?: ExternalPublications;
  inheritedLicense?: ClientInheritedLicense;
  participants?: ClientParticipant[];
}

export interface ToolStatsData {
  [userId: string]: { [toolName: string]: number };
}

export interface ClientDrawingData extends SharedDrawingData {
  hasAdmins?: boolean;
  pro?: boolean; // indicates if drawing team or owner has pro
  justImported?: boolean; // indicates that drawing was just imported by the user
  sequence?: SequenceDrawingData[];
  sequenceMainDrawingId?: string;
  sequenceId?: string;
  layerOwners?: { name: string; color: string; layers: number[]; left: number[]; }[];
  permissionFlags?: number[];
  shareType?: ShareType;
  isRevision?: boolean;
  promptHistory?: PromptHistoryItem[];
  toolStats?: ToolStatsData;
  personalVisibilityStates?: { layerId: number, visible: boolean }[];
  flags?: DrawingFlags;
  creator?: CreatorData;
  jam?: JamData;
  tags?: string[];
  readOnly?: boolean;
}

export interface ServerDrawingData extends SharedDrawingData {
  board?: string; // saving (_id of board document)
  creator?: CreatorData; // saving
  creators?: CreatorData[]; // saving (id === accountId)
  anonymousCreators?: CreatorData[]; // saving (id === sessionId)
  newCreators?: CreatorData[]; // saving (id === accountId)
  roles?: CreatorDataRole[]; // saving
  sizeOnDisk?: number; // saving
  flags?: BitFlags<DrawingFlags>; // saving,
  openedAt?: string; // loading
  createdAt?: string; // loading
  modifiedAt?: string; // loading
  updatedAt?: string; // loading
  sequenceInfo?: { id: string; mainDrawingId?: string; drawings: SequenceDrawingData[]; }; // loading
  owners?: { id: string; layers: number[]; left: number[]; }[]; // loading / saving
  blocked?: string[]; // loading / saving
  actionCount?: number; // loading / saving
  bannedOwnersToRemove?: string[]; // loading / saving
  isPro?: boolean; // loading
  tags?: string[]; // loading / admin
  shareType?: ShareType;
  defaultStorageBackendMountId?: number;
  isRevisionFor?: string;
  cacheId?: string; // saving
  toolStats?: { [tool: string]: { [user: string]: number }; };
  personalVisibilityStates?: { accountId: string, layerId: number, visible: boolean }[];

  jam?: JamData;
  analyticsSession?: AnalyticsSession;
}

export interface WorkerDrawingData extends CommonDrawingData {
  defaultStorageBackendMountId?: number,
}

export interface AdminDrawingData extends ServerDrawingData {
  referers?: string[];
  isRemoved?: boolean;
  inBin?: boolean;
  shareType?: ShareType;
}

export interface AdminRevisionData {
  _id: string;
  shortId: string;
  commitType: 'auto' | 'manual';
  createdAt: Date;
  cacheId: string;
}

export interface ActiveSessionLive {
  id: string;
  name: string;
  width: number;
  height: number;
  users: MiniUser[];
  opened: number;
  modified: number;
  socketId?: string;
  // TODO: thumb timestamp ?
}

export interface DrawingDataLiveUser {
  id: number;
  name: string;
  avatar: string | undefined;
  role: UserRole;
}

export interface DrawingDataLive {
  id: string;
  name: string;
  x: number;
  y: number;
  w: number;
  h: number;
  sizeOnDisk?: number;
  flags?: DrawingFlags;
  permissions?: DrawingPermissions;
  password?: string;

  opened: string;
  openedTime: number;
  modified: string | undefined;
  last: string | undefined;
  status: string;
  statusExtra?: string;
  workerStatus?: string;
  users: DrawingDataLiveUser[];
  socket?: string;
  sequence?: boolean;
  jam?: JamData;

  tags?: string[];
}

export enum EntityType {
  Folder = 'Folder',
  Drawing = 'Drawing',
  Flowchart = 'Flowchart',
  Board = 'Board',
}

export interface CreateEntityData {
  type: EntityType;
  name: string;
  folder?: string;
  project?: string;
  team?: string;
  board?: string;
  jam?: CreateJamData;
}

export interface ImportEntityData extends CreateEntityData {
  eventSource: string;
  sequence?: string;
}

export interface CreateDrawingData {
  name: string;
  x?: number;
  y?: number;
  width: number;
  height: number;
  background: string | undefined;
  dpi?: number;
  password?: string;
  copyPermissions?: boolean;
  addToSequence?: boolean;
  sequenceIndex?: number;
  respectOfflineOwners?: boolean;
  team?: string;
  project?: string;
  folder?: string;
  shareType?: ShareType;
  jam?: CreateJamData;
  multiboard?: boolean; // true to create multiboard
  board?: string; // _id of parent multiboard
  eventSource?: string;

  // used only on server side
  // TODO: move to separate interface ?
  tags?: string[];
  permissions?: DrawingPermissions;
  roles?: CreatorDataRole[];
  layersPerUser?: number;
  blocked?: string[];
  sequence?: string;
  sequenceOrder?: number;
}

export interface UpdateDrawingData {
  name?: string;
  background?: string;
  dpi?: number;
  respectOfflineOwners?: boolean;
  x?: number;
  y?: number;
  w?: number;
  h?: number;
}

export interface DrawingDataUpdated extends UpdateDrawingData {
  permissions?: DrawingPermissions;
  hasAdmins?: boolean;
  password?: string;
  sequence?: SequenceDrawingData[];
  sequenceMainDrawingId?: string;
  layersPerUser?: number;
  tags?: string[];
  folder?: string;
  project?: string;
  featureFlags?: Feature[];
  jam?: JamData;
}

export interface DrawingDataUpdate extends DrawingDataUpdated {
  shortId: string;
}

export interface LockedState {
  drawing: { lockedStates: LockedState[]; id: string; };
  locked: boolean;
  lockedAt: number;
  state: ServerDrawingData;
  timeout: any;
}

export interface ToolSurfaceData {
  toolId: ToolId;
  layerId: number;
  mode: CompositeOp;
  rect: Rect;
  opacity: number;
  color: number;
  transforming: boolean;
  // transform
  translateX: number;
  translateY: number;
  rotate: number;
  scaleX: number;
  scaleY: number;
  transformOrigin: number[] | undefined;
  drawingRect: Rect;
}

export interface ToolSurface {
  translateX: number;
  translateY: number;
  rotate: number;
  scaleX: number;
  scaleY: number;
  transformOrigin: Vec2 | undefined;
  toolId: ToolId;
  layer: Layer | undefined;
  canvas: HTMLCanvasElement | undefined;
  canvasMask: HTMLCanvasElement | undefined;
  textureX: number;
  textureY: number;
  texture: Texture | undefined;
  textureMask: Texture | undefined;
  textureIsLinear: boolean;
  context: IRenderingContext | undefined;
  mode: CompositeOp;
  rect: Rect;
  opacity: number;
  color: number;
  transforming: boolean; // set while transforming to indicate transform even when surface is empty
  transform: Mat2d;
  ignoreSelection: boolean;

  drawingRect: Rect;
}

export const enum SelectionMode {
  Empty, // no selection
  Update, // modify selection
  Keep, // start or modify selection
  Break, // break selection
}

export const enum ToolSource {
  None = 0,
  KeyboardShortcut = 1,
  ButtonPress = 2,
  KeyHold = 3,
  TouchGesture = 4,
  MouseGesture = 5,
  StylusEraser = 6,
  Auto = 7, // if we're changing it ourselves for some reason
  SlotSwitch = 8,
  FileDrop = 9,
  Indicators = 10,
}

// @ignore-translate
export const TOOL_SOURCES = [
  'none', 'keyboard-shortcut', 'button-press', 'key-hold', 'touch-gesture', 'mouse-gesture', 'stylus-eraser', 'auto',
  'slot-switch', 'file-drop', 'indicators',
];

export interface IToolData {
  id: ToolId;
  t?: number; // tool counter
  ts?: ToolSource; // for tool stats
  p?: (string | number)[];
  otherLayerIds?: number[];
  replace?: boolean;
  preventHistory?: boolean; // prevents adding this action to server history and clearing undos on server
  selection?: SelectionMode;
  bounds?: Rect;

  // TEMP: testing, remove later
  s?: number; // TEMP: testing, 0 - no selection, 1 - has selection
  inf?: string; // additional information
  bs?: Rect; // before selection rect
  as?: Rect; // after selection rect
  br?: Rect; // before rect
  ar?: Rect; // after rect
  br2?: Rect; // before rect (other layer)
  ar2?: Rect; // after rect (other layer)
}

export interface IFilterTool extends ITool {
  preview: boolean;
  snapshot: Surface | undefined;
  init: (drawingRect: Rect) => void;
  apply: (values: IFiltersValues, save?: boolean, recall?: boolean) => void;
  save: (values: IFiltersValues) => void;
  cancel: () => void;
}

export const enum CurveChannels {
  RGB = 0,
  RED,
  GREEN,
  BLUE
}

export interface Curve {
  points: Point[];
}

export interface IFiltersValues {
  hue?: number;
  saturation?: number;
  lightness?: number;
  radius?: number;
  distance?: number;
  angle?: number;
  brightness?: number;
  contrast?: number;
  curvePoints?: Curve[];
}

// Models

export interface IPredictor {
  a: number;
  b: number;
}

export interface ToolDecoder {
  x: IPredictor;
  y: IPredictor;
  p: IPredictor;
  handler: (x: number, y: number, p: number) => void;
}

export interface ClientCaps {
  webglFailed?: string;
  wasmFailed?: string;
  webglOff?: boolean;
}

// @ignore-translate
export enum SurveyId {
  Nps = 'nps', // general app-wide survey
  Ai = 'ai', // ai-specific survey
}

export interface ServerSettings {
  offline?: boolean;
  clientErrors?: boolean;
  serverErrors?: boolean;
  drawingLimit?: number;
  clientLimit?: number;
  allowIframe?: string[];
  logActions?: boolean;
  logLoadingStats?: boolean;
  signupFilter?: string[];
  banIPs?: string[];
  newFeature?: string;
  newFeatureDate?: string;
  autoCheckoutOnRedeem?: boolean;
  artspaceAssignment?: { [slug: string]: string[] };
  requireEmailConfirmation?: boolean;
  serverErrorFilter?: string;
  banner?: BannerInfo;
  surveysLimit?: Record<SurveyId, any>;
  surveysDone?: Record<SurveyId, any>;
  surveysDoneByDirectors?: Record<SurveyId, any>;
  surveysMonth?: string;
  ignoreCustomers?: string;
  defaultStorageBackendMountId?: number;
  authorizedSshKeys?: { user: string, publicKey: string }[];

  globalFeatureFlags?: Feature[];

  aiPromptExclusions?: { text: string, type: string }[];
  aiQuota?: number;

  jamExpireAfterHours?: number;
  startingTemplates?: string;

  emailFilter?: string[]; // this will be checked when sending verification email
  // onboarding
  serviceAccountId?: string;
  conferenceModeTeam?: string;
  onboardingFlowsSettings?: Record<UserFlowId, OnboardingFlowSettings>;
  deployedOnboardingFlows?: number;
  onboardingMonth?: string;
  maxOnboardingFlows?: number;
  // Growth hack
  activeGrowthHacks?: { [key: string]: boolean },
}

// @ignore-translate
export const serverSettingsSwitches: { key: keyof ServerSettings; label: string; }[] = [
  { key: 'clientErrors', label: 'log client errors' },
  { key: 'serverErrors', label: 'log server errors' },
  { key: 'logActions', label: 'log actions' },
  { key: 'logLoadingStats', label: 'log loading stats' },
];

export type SuccessfulImportResult = { id: string; width: number; height: number; isMissingFeatures?: boolean } & ImageInfo;
export type FailedImportResult = { error: string; };
export type ImportResult = SuccessfulImportResult | FailedImportResult;

export const enum UserAction {
  KickAndBlock,
  KickSuper,
  KickFromLayer,
  KickFromAllLayers,
  AssignLayerTo,
  RoleAll,
  RoleApproved,
  RoleAdmin,
  BecomeAdmin,
  ClearBlocked,
  CopyBlocked,
  RemoveLayerOwner,
}

export const enum DrawingAction {
  AddToSequence,
  RemoveFromSequence,
  RenameDrawingInSequence,
  ReorderSequence,
  DuplicateInSequence,
  AiEulaAccepted,
  AiRefreshPrompts,
  ChangeLayerPersonalVisibility,
  AnalyticsSessionCheck,
}

// @ignore-translate
export const DRAWING_ACTIONS = [
  'AddToSequence',
  'RemoveFromSequence',
  'RenameDrawingInSequence',
  'ReorderSequence',
  'DuplicateInSequence',
  'AiEulaAccepted',
  'AiRefreshPrompts',
  'ChangeLayerPersonalVisibility',
  'AnalyticsSessionCheck',
];

export const enum QuickDrawingAction {
  UpdateRespectOfflineOwners,
  UpdateLayersPerUser,
  MarkDrawingAsPublic,
  RequestHistoryVerification,
  PresentDrawing,
  PresentViewport,
  JoinPresentationDrawing,
  LeavePresentationDrawing,
  InvitePresentationViewers,
  KickPresentationViewers,
  TakeOverPresentation,
  ReclaimedPresentation,
  EndPresentationViewersNotification,
  PresentatioHostIsAFK,
  PassPresenterRole,
  ModifyPresentationModeState,
  TrackExport,
  TrackShare,
}

// @ignore-translate
export const QUICK_DRAWING_ACTIONS = [
  'UpdateRespectOfflineOwners',
  'UpdateLayersPerUser',
  'MarkDrawingAsPublic',
  'RequestHistoryVerification',
  'PresentDrawing',
  'PresentViewport',
  'JoinPresentationDrawing',
  'LeavePresentationDrawing',
  'InvitePresentationViewers',
  'KickPresentationViewers',
  'TakeOverPresentation',
  'ReclaimedPresentation',
  'EndPresentationViewersNotification',
  'PresentatioHostIsAFK',
  'PassPresenterRole',
  'ModifyPresentationModeState',
  'TrackExport',
  'TrackShare',
];

export const enum OtherAction {
  ReloadSession,
  JoinVoiceChat,
  LeaveVoiceChat,
  MuteVoiceChat,
  UnmuteVoiceChat,
  MuteVoiceChatAdmin,
  UnmuteVoiceChatAdmin,
  UpsertBrush,
  DeleteBrush,
}

// @ignore-translate
export const OTHER_ACTIONS = [
  'ReloadSession',
  'JoinVoiceChat',
  'LeaveVoiceChat',
  'MuteVoiceChat',
  'UnmuteVoiceChat',
  'MuteVoiceChatAdmin',
  'UnmuteVoiceChatAdmin',
  'UpsertBrush',
  'DeleteBrush',
  'InviteToCanvas',
];

export const enum QuickAction {
  CheckUrl,
  DiscardThumb,
  PressureApi,
  RendererApi,
  TabletName,
  FrameUrl,
  WebGLFailed,
  WasmFailed,
  DisconnectReason,
  RequestShape,
  RequestFile,
  WacomBannerShown,
  BrushShapesLoaded,
  ShapeShapesLoaded,
  LoadersUsed,
  BrowserInfo,
  ReportToolStat,
  RequestToolSets,
  RequestRecentDrawings,
  AiGetUsageQuota,
  KnowsAboutBrushLimits,
  GetLicensingRights,
  Log,
}

export const enum LoadingResult {
  LoadedLayers,
  Finished,
  Failed,
}

export interface PublicPromoCodeInfo {
  promoCode: string;
  name: string;
  durationInMonths: number;
  redeemBy: number;
  percentOff: number;
  partner?: string;
}

export type SettingsUpdate = [keyof Settings, any] | ['slots', number, keyof ToolSlot | number, any];

export type OauthProvider = 'google' | 'github' | 'discord' | 'twitter' | 'saml' | 'linkedin';
export type OAUTH_AUTH_ONLY = `${Uppercase<OauthProvider>}_AUTH_ONLY`;

// @ignore-translate
export const OAUTH_PROVIDERS: OauthProvider[] = ['google', 'discord', 'twitter', 'github', 'saml', 'linkedin'];

export interface OauthProfile {
  provider: OauthProvider;
  id: string;
  name: string;
  avatar?: string;
  email?: string;
  emailVerified?: boolean;
  identifier: string;
  gender?: string;
  _raw?: string;
  tags?: string[];
}

export interface OAuthData {
  github?: OauthProfile;
  google?: OauthProfile;
  discord?: OauthProfile;
  twitter?: OauthProfile;
  saml?: OauthProfile;
  azure?: OauthProfile;
  linkedin?: OauthProfile;
}

export interface IPublicServer {
  signingIn?: boolean;
  creatingAccount?: boolean;
  updatingAccount?: boolean;
  creatingDrawing?: boolean;

  // these can be sent out of order
  getProfile(): Promise<OauthProfile | undefined>;
  changeColor(color: string): Promise<void>;
  changeAvatar(avatar: string): Promise<void>;
  saveSettings(settings: string): void;
  saveSettingsPartial(updates: SettingsUpdate[]): void;
  createAccount(data: CreateAccountData, oauth?: boolean): Promise<void>;
  updateAccount(data?: AccountData): Promise<void>;
  resetPassword(email: string): Promise<void>;
  openDrawings(drawings: [number, string, string | undefined][] /* [connId, drawingId, password] */, openedFrom: string | undefined): void;
  trackDrawings(drawings: [string, string | undefined][] /* [drawingId, password] */): void;
  importDrawing(connId: number, createData: Partial<CreateDrawingData>, data: Uint8Array | undefined, dataId: number): Promise<ImportResult>;
  createDrawing(connId: number, createData?: CreateDrawingData): Promise<string>;

  updateDrawing(connId: number, updateData: UpdateDrawingData): Promise<void>;
  updateDrawingPermissions(connId: number, permissions: DrawingPermissions): Promise<void>;
  updateDrawingPassword(connId: number, newPassword: string): Promise<void>;
  resizeDrawing(connId: number, x: number, y: number, w: number, h: number): void;

  userAction<T>(connId: number, action: UserAction, localId: number, param?: T): Promise<void>;
  drawingAction<T>(connId: number, action: DrawingAction, param?: T): Promise<void>;
  quickDrawingAction<T>(connId: number, action: QuickDrawingAction, param?: T): void;
  quickAction<T>(action: QuickAction, param?: T): void; // TODO: add connId ?
  cursor(connId: number, x: number, y: number): void;
  chat(connId: number, message: string): void;
  updateThumb(connId: number, x: number, y: number, data: Uint8Array): void;

  debug(connId: number, action: string, data: string): void;
  layerSnapshot(layerId: number, dataId: number): void;

  createDrawingRevision(connId: number, label: string, isTemporary: boolean, skipPermission: boolean): Promise<DrawingRevision>;
  getDrawingRevisions(connId: number): Promise<DrawingRevision[]>;
  restoreDrawingRevision(connId: number, revisionId: string): Promise<void>;
  removeDrawingRevision(connId: number, revisionId: string): Promise<void>;
  updateDrawingRevision(connId: number, revisionId: string, value: Partial<DrawingRevision>): Promise<void>;
  cloneDrawingRevision(connId: number, revisionId: string): Promise<string>;

  licenseArtwork(connId: number, licensing: ArtworkLicensingRequestData): Promise<void>;
}

export interface IServer extends IPublicServer {
  connected(): void;
  disconnected(code: number, reason: string): Promise<void>;
  signIn(email: string, password: string): Promise<void>;
  signOut(): Promise<void>;
  trackEvent(eventName: Analytics, props?: any): void;
  trackUser(props?: any): void;

  // these packets must arrive in order after previous one was sent
  // TODO: add connId to these packets
  loadedDrawing(connId: number, result: LoadingResult): void;
  selectLayer(connId: number, layerId: number): void;
  ownLayer(connId: number, layerId: number, source: ToolSource): Promise<void>;
  disownLayer(connId: number, layerId: number, source: ToolSource): Promise<void>;
  reorderLayers(connId: number, order: number[]): void;
  // trimLayer(layerId: number, x: number, y: number, w: number, h: number): void;
  getNewLayerIds(connId: number, count: number): Promise<number[]>;
  doTool(connId: number, layerId: number, tool: IToolData, data: Uint8Array | undefined, dataId: number): void;
  startTool(connId: number, layerId: number, tool: IToolData, x: number, y: number, pressure: number): void;
  nextToolArray(connId: number, moves: Uint8Array): void;
  endTool(connId: number, toolId: ToolId, x: number, y: number, pressure: number, bx: number, by: number, bw: number, bh: number, ax: number, ay: number, aw: number, ah: number): void;
  cancelTool(connId: number, message: string): void;
  undo(connId: number, t: number, source: ToolSource): void;
  redo(connId: number, t: number, source: ToolSource): void;
  error(connId: number, name: string, message: string, stack: string, actions: string, data: any, originalMessage: string): void;
  errorWithData(connId: number, message: string, info: string, dataId: number): void;
  // `rand` parameter added to avoid re-sending identical packets over the network
  dataChunk(connId: number, id: number, size: number, offset: number, data: Uint8Array, rand: number): Promise<{ offset: number; length: number; }>;
  dataChunksDone(): Promise<boolean>;
  otherAction<T>(action: OtherAction, t: number, param?: T): Promise<void>;

  beginTool(connId: number, layerId: number, tool: IToolData): void;
  updateTool(connId: number, layerId: number, tool: IToolData, update: any): void;
  finishTool(connId: number, layerId: number, tool: IToolData): void;
}

export interface SearchResult<T> {
  items: T[];
  count: number;
}

export interface ToolStats {
  id: string;
  count: number;
}

export interface RequestStats {
  path: string;
  count: number;
  average: string;
  total: string;
  order: string;
  totalCount: number;
}

export interface SocketStats {
  id: number;
  name: string;
  type: string;
  countBin: number;
  countStr: number;
  average: string;
  total: string;
}

export interface ErrorData {
  _id: string;
  type: string; // 'client' | 'server';
  createdAt: string;
  userAgent: string;
  origin: string;
  client: string;
  account: string | undefined;
  drawingId: string | undefined;
  message: string;
  stack: string;
  data: string;
  version: string;
}

export interface AdminStatsResult {
  requestStats: RequestStats[];
  socketStats: SocketStats[];
  toolStats: ToolStats[];
}

export interface GraphInfo {
  names: string[];
  colors: string[];
  times: number[];
  values: number[][];
}

export interface LicenseInfo {
  to: string; // expiration date
  count: number; // number of seats
  expired: boolean;
}

export interface OtherStats {
  activeUsers: number;
  info?: LicenseInfo;
}

export interface AdminDrawingUpdate {
  password?: string;
  respectOfflineOwners?: boolean;
  tags?: string[];
  featureFlags?: Feature[];
  shareType?: ShareType;
  flags?: DrawingFlags;
}

export interface AdminAiModel extends Omit<AiModel, 'owner' | 'team' | 'permissions'> {
  owner: { _id: string, avatar: string, name: string };
  team: { _id: string, avatar: string, name: string };

  permissions: {
    users: { _id: string, avatar?: string, name: string }[];
    teams: { _id: string, avatar?: string, name: string }[];
  }
}

export type AdminAiModelUpdate = Pick<AiModel, 'permissions'>;

export interface LoadingStats {
  _id?: any;
  drawingId: string;
  sync: TimingEntry[];
  async: AsyncTimingEntry[][];
  error: string | undefined;
  userAgent: string;
  renderer: string;
  width: number;
  height: number;
  layers: number;
  time: number;
  hidden: number;
  fails: number;
  info: any;
}

export interface ImportAccountsOptions {
  makeAdmins: boolean;
  generatePasswords: boolean;
  sendInvite: boolean;
}

export interface ImportTeamsOptions {
  skipExsistingRows: boolean;
  generateArtspaceSlug: boolean;
  artspaceFeatureFlags: boolean;
  tagsToOwnerAccount: boolean;
  features?: Feature[];
  tagsToOwner?: string;
}

export interface ExportAccountsOptions {
  columns?: {
    [key: string]: 'include' | 'dontInclude' | 'require';
  };
  createdAtAfter?: string;
  createdAtBefore?: string;
  lastActiveAfter?: string;
  lastActiveBefore?: string;
  createdDrawingsGte?: number;
  createdDrawingsLte?: number;
  participatedInGte?: number;
  participatedInLte?: number;
  currentEmailVerified: boolean;
  receiveEmails: boolean;
  sortBy?: string;
  sortDirection?: string;
  limit?: number;
  sample?: number;
  separator?: string;
  includeHeaders: boolean;
}

export interface IAdminServer {
  requestBannedUsers(): Promise<void>;
  getSettings(): Promise<ServerSettings>;
  getOtherStats(): Promise<OtherStats>;
  getServerInfo(): Promise<ServerInfo[]>;
  getGraph(): Promise<void>;
  changeSettings(settings: Partial<ServerSettings>): Promise<ServerSettings>;
  getDrawing(id: string): Promise<AdminDrawingData | undefined>;
  removeDrawing(id: string): Promise<void>;
  closeDrawing(id: string): Promise<void>;
  duplicateDrawing(drawingId: string): Promise<string>;
  findDrawings(query: string, from: number, limit: number): Promise<SearchResult<AdminDrawingData>>;
  findAccounts(query: string, from: number, limit: number): Promise<SearchResult<AccountData>>;
  findSessions(query: string, from: number, limit: number): Promise<SearchResult<any>>;
  getAccount(id: string): Promise<any>;
  removeAccount(id: string): Promise<void>;
  mergeAccounts(targetId: string, sourceId: string): Promise<void>;
  updateAccountAdmin(id: string, update: any): Promise<AccountData>;
  toggleAccountRole(id: string, role: any, add: boolean): Promise<AccountData>;
  setAccountStripeId(id: string, stripeId: string): Promise<void>;
  resetAccountPassword(id: string, password: string): Promise<void>;
  getAccountsCSV(query: string, options: ExportAccountsOptions): Promise<string>;
  importAccounts(csv: string, options: ImportAccountsOptions): Promise<string>;
  removeSocial(id: string, provider: string): Promise<void>;
  fixNameLower(): Promise<string>;
  clearSessions(): Promise<void>;
  removeSession(sessionId: string): Promise<void>;
  updateSession(sessionId: string, update: { banned?: boolean; }): Promise<any>;
  nameFor(id: string): Promise<string>;
  addServer(): Promise<void>;
  getLog(log: string): Promise<string>;
  clearLog(log: string): Promise<void>;
  deleteLog(log: string): Promise<void>;

  // loading stats
  getLoadingStats(id: string): Promise<LoadingStats | null>;
  getLoadingStatsList(): Promise<LoadingStats[]>;
  getLoadingStatsCounts(): Promise<{ key: string; value: any; }[]>;
  clearLoadingStats(id?: string): Promise<void>;

  // errors
  findErrors(from: number, limit: number): Promise<SearchResult<ErrorData>>;
  removeError(id: string): Promise<void>;
  removeAllErrors(): Promise<void>;
}

export enum SocketAction {
  PrintStats,
}

export interface ISocketServer {
  kick(clientId: number): Promise<void>;
  sleep(clientId: number): Promise<void>;
  kickAll(): Promise<void>;
  getStats(): Promise<AdminStatsResult>;
  reloadSettings(): Promise<void>;
  closeDrawing(id: string): Promise<void>;
  connectLive(): void;
  disconnectLive(): void;
  announce(message: string, type: string): void;
  socketAction(action: SocketAction, workerId?: string): Promise<void>;
  getTimings(workerId: string): Promise<Timings>;
  setCreator(drawingId: string, userId: string): Promise<void>;
  removeCreator(drawingId: string): Promise<void>;
  removeCreators(drawingId: string, accountOrSessionId: string): Promise<void>;
  removeFromCreatorsInAllDrawings(accountOrSessionId: string): Promise<void>;
  addBlocked(drawingId: string, accountOrSessionId: string): Promise<void>;
  removeBlocked(drawingId: string, accountOrSessionId: string): Promise<void>;
  setDrawingRole(drawingId: string, accountOrSessionId: string, role: UserRole): Promise<CreatorDataRole[]>;
  updateDrawing(drawingId: string, update: AdminDrawingUpdate): Promise<void>;
  removeDrawing(drawingId: string, skipSave: boolean): Promise<boolean>;
  updatePermissions(drawingId: string, permissions: DrawingPermissions | undefined): Promise<void>;
  removeLayers(drawingId: string, layers: number[]): Promise<void>;
  addWorker(): Promise<void>;
  getGraph(): Promise<void>;
  getLog(log: string): Promise<string>;
  clearLog(log: string): Promise<void>;
}

export type IServerTool = [
  // localId, layerId, tool,
  number, number, IToolData,
  // binaryData, finishAfter, disown
  Uint8Array | undefined, boolean, number[],
  // hasUpdates, update:any
  boolean, any[]
];

// [localId, selection]
export type IUserSelection = [number, number[] | undefined];

export enum ChatType {
  Chat = 0,
  System = 1,
  Whisper = 2,
  Invite = 3,
}

export const enum ClientAction {
  Sleep,
  SwitchSocket,
  AccountCreated,
  AiUsageUpdated,
  RecentDrawings,
  UpdateDrawingLicensing,
}

export const enum ClientDrawingAction {
  LeaveLayer,
  LeaveAllLayers,
  LoadErrorMessage,
  UpdateOwnerName,
  DeletingDrawing,
  AiPromptAdded,
  AiPromptsList,
  RevisionRestoring,
  RevisionCreated,
  RevisionRemoved,
  RevisionUpdated,
  ChangeLayerPersonalVisibility,
  DrawingLicenseUpdated,
  DrawingParticipantsUpdated,
  ReferencedDrawingResized,

  // debug
  DebugLayers,
  DebugLayerSnapshot,
  VerifyHistory,
}

export const enum SequenceAction {
  PresentDrawing,
  PresentViewport,
  PresentationMode,
  JoinPresentation,
  LeavePresentation,
  PresentationModeInvite,
  PresentationModeKickedOut,
  ClosePresentationActionModals,
  RemovePresentationHostStatus,
  UserDisconnectedPresentationMode,
  EndedPresentationMode,
  PassPresenterRole,
  UserTookPresenterRole
}

export const enum VoiceChatAction {
  Session,
  SessionInitial,
  Token,
  Mute,
  Unmute,
  Joined,
  Left,
  MuteAdmin,
  UnmuteAdmin,
  ListOfActions,
}

export const enum DisownFlags {
  None = 0,
  RemoveOwner = 1,
}

// @ref bin.ts                [accountId, uniqId, name, color, avatar, role, anonymous, anonymousNumber]
export type TrackedUserData = [string, string, string, string, string | undefined, UserRole, boolean, number];

export interface InitParams {
  showNotSignedIn?: boolean;
  newFeature?: string;
}

export interface IClient {
  connected?(): void;
  init(sessionId: string, user: UserData, params: InitParams): void;
  drawingOpen(connId: number, user: UserData, state: ClientDrawingData, tools: IServerTool[], updates: DrawingDataUpdated[], userSelections: IUserSelection[]): void;
  drawingTools(connId: number): void;
  drawingLoaded(connId: number): void;
  updateDrawing(connId: number, localId: number, data: DrawingDataUpdated): void;
  addUser(connId: number, data: UserData): void;
  toolSets(toolSets: ToolSets): void;
  updateUser(connId: number, data: UserData): void;
  removeUser(connId: number, localId: number): void;
  userSelection(connId: number, localId: number, selection: number[]): void;
  ownLayer(connId: number, localId: number, layerId: number): void;
  disownLayer(connId: number, localId: number, layerId: number, flags: BitFlags<DisownFlags>): void;
  selectLayer(connId: number, localId: number, layerId: number): void;
  resizeDrawing(connId: number, localId: number, x: number, y: number, w: number, h: number): void;
  reorderLayers(connId: number, localId: number, order: number[]): void; // TODO: remove
  confirm(connId: number, index: number): void;
  tool(connId: number, localId: number, layerId: number, tool: IToolData, data: Uint8Array | undefined): void;
  cancelTool(connId: number, localId: number): void;
  undo(connId: number, localId: number, t: number): void;
  redo(connId: number, localId: number, t: number): void;
  startTool(connId: number, localId: number, layerId: number, tool: IToolData, x: number, y: number, pressure: number): void;
  startToolWithMoves(connId: number, localId: number, layerId: number, tool: IToolData, end: boolean, moves: Uint8Array, ax: number, ay: number, aw: number, ah: number): void;
  nextToolArray(connId: number, localId: number, moves: Uint8Array): void;
  endTool(connId: number, localId: number, x: number, y: number, pressure: number, ax: number, ay: number, aw: number, ah: number): void;
  chat(connId: number, uniqId: string, message: string, args: string[], type: ChatType): void;
  cursors(connId: number, cursors: [number, number, number][]): void;
  clientAction<T>(action: ClientAction, param?: T): void;
  clientDrawingAction<T, E = null>(connId: number, action: ClientDrawingAction, param?: T, extra?: E): void;
  voiceChatAction<T extends VcActions>(action: VoiceChatAction, param: T): void;
  sequenceAction<T>(action: SequenceAction, param: T): void;

  // tracked drawings
  updateTrackedThumb(drawingId: string, cacheId: string): void;
  updateTrackedThumbData(drawingId: string, x: number, y: number, thumbWidth: number, thumbHeight: number, data: Uint8Array): void;
  updateTrackedDrawing(drawingId: string, update: { name?: string }): void;
  updateTrackedUsers(drawingId: string, users: TrackedUserData[]): void;
  removeTrackedUsers(drawingId: string, uniqIds: string[]): void;

  // chunked data
  clientDataChunk(connId: number, localId: number, id: number, size: number, offset: number, chunk: Uint8Array): void;
  cancelChunkedData(connId: number, localId: number, id: number): void;

  // other
  announcement(message: string, type: string): void;
  settings(settings: string): void;
  updateRecentDrawing(recentDrawing: RecentDrawingData): void;
  shapeShape(id: string, name: string, width: number, height: number, path: string, iconWidth: number, iconHeight: number, iconPath: string | undefined): void;
  brushShape(id: string, name: string, width: number, height: number, compressed: Uint8Array): void;
  brushShapePath(id: string, name: string, width: number, height: number, path: string): void;
  fileContent(name: string, content: Uint8Array | undefined): void;

  beginTool(connId: number, localId: number, layerId: number, data: IToolData): void;
  updateTool<T>(connId: number, localId: number, data: IToolData, update: T): void;
  finishTool(connId: number, localId: number, data: IToolData): void;
}

export interface HistoryStats {
  used: number;
  total: number;
  canvases: number;
}

export interface IHistory {
  beginTransaction(): void;
  endTransaction(): void;
  revertTransaction(): void;
  execTransaction(actions: (history: IHistory) => void): void;

  pushUndo(undo: IUndoFunction): void;
  pushSelection(type: string): void;
  pushDirtyRect(type: string, layerId: number, dirtyRect: Rect, isMove?: boolean, ignoreLayerState?: boolean): void;
  pushLayerState(layerId: number, textAction?: string): void;
  pushAddLayer(layer: LayerData, index: number, auto?: boolean): void;
  pushRemoveLayer(layer: LayerData, index: number): void;
  pushTool(type: string, isMove?: boolean): void;
  pushLayerId(type: string, layerId: number): void;
  pushResize(isLocal: boolean): void;

  createSelection(type: string): IUndoFunction;
  createLayerState(layerId: number): IUndoFunction;

  // DON'T USE: this causes desync on server (only used for cancelling failed action)
  cancelLastUndo(): void;

  unpre(): void;
  prepushUndo(undo: IUndoFunction): void;
  prepushSelection(type: string): void;
  prepushDirtyRect(type: string, layerId: number, dirtyRect: Rect, isMove?: boolean): void;
  prepushTool(type: string, isMove?: boolean): void;
  prepushLayerState(layerId: number): void;

  clearLayer(layerId: number): void;
  clearRedos(): void;
  clear(): void;
  undo(): void;
  redo(): void;
  canUndo(): boolean;
  canRedo(): boolean;
  attachLastPoint(x: number, y: number): void;
  isLastEntryMove(layerId: number): boolean;

  stats(stats: HistoryStats): void;
  hadDiscardedUndos(): boolean;
  textureHandleInUse(handle: WebGLTexture): boolean;
}

export type LayerMode = 'normal' | 'darken' | 'multiply' | 'color burn' | 'lighten' | 'screen' | 'color dodge' |
  'overlay' | 'soft light' | 'hard light' | 'difference' | 'exclusion' | 'hue' | 'saturation' | 'color' |
  'luminosity';

export const LAYER_MODES: (LayerMode | null)[] = [
  'normal',
  null,
  'darken',
  'multiply',
  'color burn',
  null,
  'lighten',
  'screen',
  'color dodge',
  null,
  'overlay',
  'soft light',
  'hard light',
  null,
  'difference',
  'exclusion',
  null,
  'hue',
  'saturation',
  'color',
  'luminosity',
];

export const LAYER_MODES_ALL = LAYER_MODES.filter(x => x) as string[];

export interface LayerOwner {
  name: string;
  color: string;
  left: boolean;
}

export const enum LayerFlag {
  None = 0,
  External = (1 << 0),
  AiGenerated = (1 << 1),
  AiAssisted = (1 << 2),
}

export interface Layer {
  readonly id: number;
  name: string;
  mode: LayerMode;
  opacity: number;
  opacityLocked: boolean;
  visible: boolean;
  locked: boolean;
  clippingGroup: boolean;
  image: string | undefined;
  owner: User | undefined;
  layerOwner: LayerOwner | undefined;
  visibleLocally: boolean | undefined;
  personalVisibilityPrev?: boolean;
  changed: boolean;
  loaded: boolean;
  thumbDirty: number;
  readonly rect: Rect;
  canvas: HTMLCanvasElement | undefined;
  thumb: HTMLCanvasElement | undefined;
  textureX: number;
  textureY: number;
  texture: Texture | undefined;
  lastUsed: number | undefined;
  unloadedImage: string | undefined;
  unloadingNumber: number;
  flags: LayerFlag;
  textData?: TextareaOptions;
  textarea?: Textarea;
  fontsLoaded?: boolean;
  perspectiveGrid?: PerspectiveGridLayerData;
  invalidateCanvas?: boolean;
  ref?: DrawingReference;
}

export interface DrawingReferenceImage {
  cacheId: string;
  size: number;
  image?: HTMLImageElement;
  texture?: Texture;
}

export interface DrawingReference {
  id: string;
  x: number;
  y: number;
  scale: number;
  rotation: number;

  // dynamic fields
  cacheId?: string;
  cacheIdTimestamp?: number; // when we received this cacheId
  thumbData?: ImageData;
  thumbTimestamp?: number; // when we last updated thumbData
  drawingRect: Rect;
  users: User[];

  // rendering fields
  promiseCacheId?: string | number; // cacheId or thumbTimestamp
  promiseSize?: number;
  promise?: CancellablePromise<HTMLImageElement | ImageBitmap>;
  imageLastNeeded?: number;
  imageCacheId?: string | number; // cacheId or thumbTimestamp
  imageSize?: number;
  image?: HTMLImageElement | ImageBitmap | ImageData;
  texture?: Texture;
  textureWasDiscarded?: boolean;
}

export type TextLayer = Mandatory<Layer, 'textData'>;

export type PerspectiveGridLayer = Mandatory<Layer, 'perspectiveGrid'>;

export type PerspectiveGridStored = {
  name: string,
  vpointList: Point[]
}

export type PerspectiveGridLayerData = {
  vpointList: Point[];
  vpointScreenList: Point[];
  vpointListStored: PerspectiveGridStored[];
  vpointColor: number[];
  horizonDir: Point;
  horizonAngle: number;
  horizonColor: number;
  linesNumber: number;
  thickness: number;
  depthFade: number;
  bounds: Rect;
  transform: number[];
}

export interface SequenceDrawing {
  _id: string;
  id: string;
  name: string;
  users: User[];
  thumbTimestamp?: number;
  thumbUpdated?: number;
  thumbImage?: HTMLImageElement;
  thumbCanvas?: HTMLCanvasElement;
  thumbLoading?: boolean;
  thumbData?: ImageData;
  cacheId?: string;
}

export interface ThumbUpdate {
  width: number; // full thumbnail size (not data)
  height: number;
  data: Uint8Array; // size is in rect
  rect: Rect;
}

export const enum DrawingType {
  Drawing = 0,
  Revision = 1,
  Board = 2,
}

export interface Drawing {
  _id: string;
  id: string;
  type: DrawingType;
  name: string;
  x: number;
  y: number;
  w: number;
  h: number;
  background: string | undefined;
  layers: Layer[];
  permissions: DrawingPermissions;
  dpi: number;
  password: string | undefined;
  hasAdmins: boolean; // TODO: if we get more boolean flags here maybe just change this to some flags enum
  justImported: boolean;
  pro: boolean;
  respectOfflineOwners: boolean;
  canvas: HTMLCanvasElement | undefined;
  sequence: SequenceDrawing[];
  sequenceMainDrawingId: string | undefined;
  layersPerUser: number;
  thumbUpdate: ThumbUpdate | undefined;
  team: string | undefined;
  project: string | undefined;
  folder: string | undefined;
  featureFlags: Feature[];
  isRevision: boolean;
  licensing?: ArtworkLicensingData;
  publishedIn?: ExternalPublications;
  inheritedLicense?: ClientInheritedLicense;
  participants?: ClientParticipant[];

  permissionFlags: number[] | undefined;
  shareType: ShareType | undefined;
  promptHistory: PromptHistoryItem[] | undefined;
  sequenceId: string | undefined;
  toolStats: ToolStatsData;
  personalVisibilityStates: Map<number, boolean>;
  flags: DrawingFlags;
  creator: CreatorData;
  jam?: JamData;
  tags: string[];
  readOnly: boolean;
  // connection
  connId: number;
  socket: SocketWithDrawings | undefined;
  // state
  isClosed: boolean;
  discard?: boolean; // the drawing is to be released and discarded after new drawing loads and replaces it as active drawing
  readonly loaded: number; // can only change using `setLoaded()` 0-1, indicates loading progress of the drawing
  loadingPromise: CancellablePromise<LayerLoadingResult> | undefined;
  loadingFailed: DrawingLoadFailure;
  // users
  user: User; // TODO: this is dangerous property (can be accidentally used instead of model.user)
  users: User[];
  tempUsers: User[];
  // history
  sendingHistory: boolean;
  drawingHistory: (() => void)[];
  // action queue
  waitingActions: (() => void)[];
  toolPromise: Promise<void> | undefined;
  insideCommitLoop: boolean;
  // sending queue
  sending: boolean;
  sendingQueue: { action: (server: IServer) => any; defer: Deferred<any>; }[];
  dataId: number;
  clearSendingChunksId: number;
  // tool
  toolCounter: number;
  toolStarted: boolean;
  encoder: BufferedEncoder;
  nextToolFlushTime: number;
  pendingLayerOwns: Set<number>;
  selectLayerOnOwn: number;
  lastCropRect: Rect;
  lastReorder: number[];
  sentTools: number;
  confirmedTools: number;
  // buffered tool
  hasBufferedStart: boolean;
  startConnId: number;
  startLayerId: number;
  startTool: IToolData | undefined;
  startX: number;
  startY: number;
  startP: number;
  // webgl renderer
  lod: number;
  tileSize: number;
  tileMarginSize: number;
  tiles: Tiles;
  visibleRect: Rect;
  renderedRect: Rect;
  dirtyRect: Rect;
  thumbDirtyRect: Rect;
}

export interface CancellablePromise<T> extends Promise<T> {
  cancel(): void;
}

export interface BrushCache {
  size: number;
  hardness: number;
  roundness: number;
  color: number;
  canvas: HTMLCanvasElement | undefined;
}

export interface ParsedPath {
  commands: Float32Array;
}

export interface ShapePath {
  path: string;
  width: number;
  height: number;
  cachedPath2D: Path2D | undefined;
  cachedParsedPath: ParsedPath | undefined;
}

export interface BrushShape {
  id: string;
  name: string;
  path?: ShapePath;
  icon?: ShapePath;
  imageData?: CompressedImageData;
  averageOpacity?: number;
}

export interface IRenderingContext {
  gl: boolean;
  opacity: number;
  usingOpacity: boolean;
  globalAlpha: number;

  flush(): void;
  translate(x: number, y: number): void;
  rotate(angle: number): void;
  scale(sx: number, sy: number): void;
  setTransform(m11: number, m12: number, m21: number, m22: number, x: number, y: number): void;
  clearRect(x: number, y: number, w: number, h: number): void;
  fillRect(color: number, x: number, y: number, w: number, h: number): void;
  strokeRect(color: number, strokeWidth: number, x: number, y: number, w: number, h: number): void;
  fillCircle(color: number, x: number, y: number, radius: number, roundness: number, angle: number): void;
  fillEllipse(color: number, cx: number, cy: number, rx: number, ry: number): void;
  strokeEllipse(color: number, strokeWidth: number, cx: number, cy: number, rx: number, ry: number): void;
  fillPath(color: number, x: number, y: number, w: number, h: number, path: ShapePath): void;
  fillPolyfgon(color: number, shape: ShapePath | undefined, angle: number, patternScale: number, path: Polyf): void;
  strokePath(color: number, strokeWidth: number, x: number, y: number, w: number, h: number, path: ShapePath): void;
  drawSoftBrush(brush: BrushCache, color: number, radius: number, baseSize: number, hardness: number, x: number, y: number, roundness: number, angle: number): void;
  drawImageBrush(shape: BrushShape, color: number, x: number, y: number, size: number, roundness: number): void;
  drawBlendBrush(color: number, x: number, y: number, size: number, stretch: number, hardness: number): void;
  dispose(): void;
  marker(x: number, y: number, color: number): void;
}

export type HistoryBufferTarget = Layer | ToolSurface;

export interface HistoryBufferEntry {
  buffer: HistoryBuffer;
  sheet: HistoryBufferSheet;
  x: number;
  y: number;
  rect: Rect;
}

export interface HistoryBufferSheet {
  left: number;
  bottom: number;
  top: number;
  entries: HistoryBufferEntry[];
  surface: HTMLCanvasElement | Texture;
}

export interface HistoryBuffer {
  sheets: HistoryBufferSheet[];
}

export const enum CopyMode {
  Copy,
  Cut,
}

export type Surface = HTMLCanvasElement | Texture;

export const enum DrawingDataFlags {
  None = 0,
  NoBackground = 1,
}

export interface DrawDrawingOptions {
  selectedTool?: ITool;
}

export interface DrawOptions {
  cursor: Cursor;
  settings: RendererSettings;
  lastPoint: Point;
  showShiftLine: boolean;
  drawingInProgress?: boolean;
  readonly board?: Drawing;
  selectedTool: ITool | undefined;
  holdingTool?: ITool | undefined;
  activeTool?: ITool | undefined;
  videoForUser: (user: User) => HTMLVideoElement | undefined;
  drawingById: (id: string) => Drawing | undefined;
  redrawViewport: () => void;
}

export interface ExtraLoader {
  getLayerImage(url: string): CancellablePromise<ImageBitmap | HTMLImageElement>;
}

export interface RendererParams {
  maxTextureSize: number;
  maxTextureUnits: number;
}

export interface IRenderer {
  name: RendererApi;
  canvas: HTMLCanvasElement | undefined;

  setLevelOfDetail(drawing: Drawing, scale: number): void; // 1 is full resolution, 2 is half, 4 is quater...

  addRedrawRect(user: User, targetDirtyRect: Rect, editor: DrawOptions, users: User[]): boolean;

  // debug
  canvases(): any[];
  stats(): string;
  params(): RendererParams;

  init(canvas?: HTMLCanvasElement): void;
  release(): void;
  releaseTemp(): void;
  releaseLayer(layer: Layer | undefined): void;
  releaseDrawing(drawing: Drawing, keepDrawingCanvas: boolean): void;

  loadLayerImages(drawing: Drawing, extraLoader?: ExtraLoader, onProgress?: (progress: number) => void): CancellablePromise<LayerLoadingResult>;
  releaseUserCanvas(user: User): void;
  initLayer(layer: Layer, image: HTMLImageElement | ImageBitmap, drawingRect: Rect): void;
  initLayerFromBitmap(layer: Layer, bitmap: BitmapData, drawingRect: Rect): void;

  commitTool(user: User, lockOpacity: boolean): void;
  commitToolOnLayer(user: User, layer: Layer, lockOpacity: boolean): void;
  commitToolTransform(user: User): void;
  mergeLayers(drawingRect: Rect, srcLayer: Layer, dstLayer: Layer, clip: boolean): void;
  splitLayer(surface: ToolSurface, layer: Layer, selection: Mask): void;
  cutLayer(layer: Layer, selection: Mask): void;
  copyLayer(src: Layer, dst: Layer, selection: Mask | undefined, copyMode: CopyMode, drawingRect: Rect): void;

  draw(drawing: Drawing, user: User, view: Viewport, dirtyRect: Rect, options: DrawOptions): void;
  drawTextLayer(layer: ReadyTextLayer, drawing: Drawing): void;
  drawDrawing(drawing: Drawing, view: Viewport, dirtyRect: Rect | undefined, options: DrawDrawingOptions): void;
  drawLayerThumbs(layers: Layer[], drawingRect: Rect): void;
  drawThumb(drawing: Drawing, rect: Rect, thumb: HTMLCanvasElement | undefined): void;
  pingThumb(drawing: Drawing): void;
  discardThumb(): void;

  scaleImage(image: HTMLImageElement | ImageBitmap | ImageData, scaledWidth: number, scaledHeight: number): HTMLCanvasElement;

  // in funcitons below if selection is not passed it will return resposnse with drawing bounds
  getScaledDrawingSnapshot(drawing: Drawing, scaledWidth: number, scaledHeight: number, bounds?: Rect): HTMLCanvasElement;
  getScaledLayerSnapshot(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, bounds?: Rect): HTMLCanvasElement;
  getScaledLayerMask(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, bounds?: Rect): HTMLCanvasElement;

  // history
  createSurface(id: string, width: number, height: number): Surface; // can return larger surface than requested
  releaseSurface(surface: Surface | undefined): undefined;
  copyToSnapshot(src: Surface, dst: Surface, sx: number, sy: number, w: number, h: number, dx: number, dy: number): void;
  restoreSnapshotToLayer(snapshot: HistoryBufferEntry | undefined, layer: Layer, rect: Rect): void;
  restoreSnapshotToTool(snapshot: HistoryBufferEntry, user: User): void;

  getLayerSnapshot(layer: Layer, mask?: Mask, bounds?: Rect): HTMLCanvasElement | undefined;
  getDrawingSnapshot(drawing: Drawing, mask?: Mask): HTMLCanvasElement | undefined;
  getDrawingCanvasForImageData(drawing: Drawing): HTMLCanvasElement | undefined;
  getLayerCanvasForImageData(layer: Layer): { rect: Rect; canvas: HTMLCanvasElement | undefined; };

  // TODO: combine these two
  getDrawingImageData(drawing: Drawing, flags: BitFlags<DrawingDataFlags>): ImageData;
  getLayerImageData(layer: Layer, rect: Rect): ImageData;

  getDrawingThumbnail(drawing: Drawing, maxSize: number): HTMLCanvasElement;

  getLayerRawData(layer: Layer): BitmapData;
  createImageData(width: number, height: number, data: Uint8ClampedArray | undefined): ImageData;
  putImage(user: User, image: HTMLImageElement | HTMLCanvasElement | ImageBitmap, rect: Rect): void;
  putImageData(user: User, data: ImageData, rect: Rect): void;
  copyLayerToSurface(layer: Layer, surface: ToolSurface, rect: Rect): void;

  pickColor(drawing: Drawing, layer: Layer | undefined, x: number, y: number, activeLayer: boolean): number | undefined;
  getToolRenderingContext(user: User, rect: Rect): IRenderingContext;
  trimLayer(layer: Layer, rect: Rect, allowExpanding?: boolean): void;

  // testing
  fillSelection(layer: Layer, drawingRect: Rect): void;

  // filters
  applyHueSaturationLightnessFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues): void;
  applyBrightnessContrastFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues): void;
  applyCurvesFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues): void;
  applyGaussianBlurFilter(srcData: ImageData | undefined, srcDataRect: Rect, surface: ToolSurface, values: IFiltersValues): void;
  applyMotionBlurFilter(srcData: ImageData | undefined, srcDataRect: Rect, surface: ToolSurface, values: IFiltersValues): void;
}

export interface IRendererFactory {
  createRenderer(id: string, canvasProvider: ICanvasProvider, errorReporter: IErrorReporter): { renderer: IRenderer; error?: string; };
}

export interface PickColor {
  do: boolean;
  x: number;
  y: number;
  activeLayer: boolean;
  secondary: boolean;
  alphaTo: string;
}

export type FilterNames = '' | 'gaussianBlur' | 'motionBlur' | 'hueSaturationLightness' | 'brightnessContrast' | 'curves';

export interface IEditorFilter {
  activeFilter: FilterNames;
  settings: {
    preview?: boolean,
    radius?: number,
    distance?: number,
    angle?: number,
    hue?: number,
    saturation?: number,
    lightness?: number,
    brightness?: number,
    contrast?: number
  };
}

export const enum ModelType {
  Local = 'local',
  Remote = 'remote',
}

export interface IToolModel {
  type: ModelType;

  user: User;
  drawing: Drawing;
  editor: IToolEditor;

  track?: ITrackService;
  threads?: IThreadService;
  toasts?: ToastService,
  featureFlags?: FeatureFlagService;

  doTool<T extends IToolData>(layerId: number, tool: T, data?: Uint8Array): void;
  doToolWithData<T extends IToolData>(layerId: number, tool: T, data: Uint8Array): Promise<void>;
  startTool<T extends IToolData>(layerId: number, tool: T, x: number, y: number, pressure: number): void;
  nextTool(x: number, y: number, pressure: number): void;
  endTool(toolId: ToolId, x: number, y: number, pressure: number, beforeRect: Rect, afterRect: Rect): void;
  cancelTool(message: string): void;
  drawingAction<T>(action: DrawingAction, param?: T): Promise<void>;
  tryQuickAction<T>(action: QuickAction, param?: T): void;
  errorWithData(message: string, info: string, data: Uint8Array): void;

  beginTool<T extends IToolData>(layerId: number, tool: T): void;
  updateTool<T extends IToolData>(layerId: number, tool: T, update: any, binaryData?: Uint8Array): void;
  finishTool<T extends IToolData>(layerId: number, tool: T): void;

  setTaskName(name: string | undefined): void;
  startTask(name: string): () => void;
  canUseProBrushes?(): boolean; // based on user pro status
  getMaxImageSize(): number; // based on user pro status
}

export interface IToolEditor {
  name: string;
  type: string;
  view: Viewport;
  renderer: IRenderer;
  primaryColor: number;
  primaryColorHue: number;
  secondaryColor: number;
  secondaryColorHue: number;
  pickColor: PickColor;
  logger?: Logger;
  dirty: Rect;
  drawingSamples: number;
  apply(func: () => void): void;
  requestShapePath(name: string): void;
  resizeCanvas(rect: Rect, drawing: Drawing): void;
  isActiveDrawing(drawing: Drawing): boolean;
}

// Tool
export const enum ToolId {
  None = 0,
  Brush = 1,
  Pencil = 2,
  Eraser = 3,
  Move = 4,
  Rect = 5,
  Ellipse = 6,
  Eyedropper = 7,
  Layer = 8,
  LayerUpdate = 9,
  Selection = 10,
  SelectionHelper = 11,
  CircleSelection = 12,
  LassoSelection = 13,
  LassoBrush = 14,
  DeleteSelection = 15,
  Hand = 16,
  RotateView = 17,
  Zoom = 18,
  Ref = 19,
  Transform = 20,
  Paintbucket = 21,
  Paste = 22,
  Text = 23,
  Shape = 24,
  Comment = 25,
  AI = 26,
  GaussianBlur = 27,
  HueSaturationLightness = 28,
  BrightnessContrast = 29,
  Curves = 30,
  Crop = 31,
  MotionBlur = 32,
  PerspectiveGrid = 33,
  AddBoard = 34,
  Blend = 35,
  COUNT,
}

export interface ITabletTool {
  id: ToolId;
  stabilizer?: boolean;
  start?(x: number, y: number, pressure: number, e?: TabletEvent): void;
  move?(x: number, y: number, pressure: number, e?: TabletEvent): void;
  end?(x: number, y: number, pressure: number, e?: TabletEvent, opts?: string): void;
  cancel?(): void;
  frame?(): void;
  flush?(): void;
}

export interface ITool extends ITabletTool {
  id: ToolId;
  name: string;
  description?: string;
  learnMore?: string;
  video?: { url: string; width: number; height: number; };
  view?: Viewport; // if property is present tool will use custom viewport when processing point coordinates, otherwise it will set automatically to used one
  contextMenu?: boolean; // if property is present and set to true tool will show context menu inside editor-box
  icon?: any;
  size?: number;
  sizes?: number[];
  cursor?: CursorType; // TODO: merge with canvasCursor
  onboardingTutorial?: UserFlowId;
  updatesCursor?: boolean; // indicates that tool is sending cursor position by itself, no need to additionally send it
  navigation?: boolean; // tool only changes view
  nonDrawing?: boolean; // tool doesn't draw anything
  selection?: boolean; // tool can start even on layer that can't be drawn on (locked, invisible, etc)
  stabilize?: number;
  cancellableLocally?: boolean; // don't send model.cancelTool when cancelling the tool
  cancellingKeepsSurface?: boolean; // don't release user surface after cancelling this tool
  usesModifiers?: boolean; // uses ctrl/shift/alt keys
  skipMoves?: boolean; // skips multiple move events in the same frame (for tools that only depend on final cursor position)
  lineOnShift?: boolean;
  continuousRedraw?: boolean;  // responsible for toggling whether canvas should be redrawn 60 times per second when using this tool
  redrawAlways?: boolean;
  scrollView?: boolean;
  altTool?: boolean;
  opacity?: number;
  flow?: number;
  hardness?: number;
  fields?: string[]; // fields to save in settings
  ignoreDo?: boolean; // for cases when we have do() but want to still call start()-move()-end()
  transient?: boolean; // does not need active tool to finish before executing
  drawingBounds?: Rect;
  resetSettings?(): void;
  verify?(): void; // called before setup() and finishTool() so we can check if tool is ready to start (this checks for missing brush shapes etc)
  setup?(data?: IToolData): void; // called before start() or do()
  do?(data?: IToolData, binaryData?: Uint8Array, debugInfo?: string): void;
  doAsync?(data?: IToolData, binaryData?: Uint8Array, debugInfo?: string): Promise<void>;
  hover?(x: number, y: number, e: TabletEvent): void;
  wheel?(x: number, y: number, deltaX: number, deltaY: number, e: TabletEvent): void;
  canStart?(): ToolError;
  flush?(): void;
  onLayerChange?(layer: Layer | undefined): void;
  begin?(tool: IToolData, remote?: boolean): void;
  update?(tool: IToolData, update: any, remote?: boolean): Promise<void>;
  finish?(remote?: boolean): void; // end tool, add to history
  cancelBeganTool?(remote?: boolean): void;
  onSelect?(): void;
  onDeselect?(): void;
  hasBegan?: boolean;
  patternScale?: number;
  feature?: Feature; // feature required to see this tool
  onlyPortal?: boolean;
  drawingShiftLine?: boolean;
}

export interface ILayerTool extends ITool {
  clear(layerId: number): void;
  transfer(layerId: number, otherLayerId: number, opacity: number, clip: boolean, drawingBounds: Rect): void;
  merge(layerId: number, otherLayerId: number, opacity: number, clip: boolean, drawingBounds: Rect): void;
  add(layer: LayerData, index: number, autogenerated: boolean): void;
  remove(layerId: number): void;
  // update(layer: ILayerData): void;
  duplicate(layerId: number, newLayerId: number, drawingBounds: Rect): void;
  copy(layerId: number, newLayerId: number, drawingBounds: Rect): void;
  cut(layerId: number, newLayerId: number, drawingBounds: Rect): void;
  trim(layerId: number, rect: Rect): void;
}

export interface PasteLayerData extends LayerUpdateData {
  srcLayerIndex: number;
  imageData?: ImageData;
  rect?: Rect;
}

export type AnyImageType = ImageData | HTMLCanvasElement | HTMLImageElement | ImageBitmap;

export interface IPasteTool extends ITool {
  paste(drawingBounds: Rect, layerId: number, rect: Rect, transform: number[], binaryData: Uint8Array, image: AnyImageType, deselect: boolean): Promise<void>;
  pasteOnNewLayer(drawingBounds: Rect, layer: LayerData, index: number, rect: Rect, transform: number[], binaryData: Uint8Array, image: AnyImageType): Promise<void>;
  pasteLayers(syncExistingLayers: boolean, layers: PasteLayerData[], layersOrder: number[], binaryData: Uint8Array): Promise<void>;
}

export interface ISelectionHelperTool extends ITool {
  deselectAll(): void;
  selectAll(): void;
  invertSelection(): void;
  selectionFromLayer(): void;
  moveSelection(dx: number, dy: number): void;
  fillSelection(): void;
  select(selection: Mask): void;
}

export enum BrushBlendMode {
  Normal = 'normal',
}

export enum BrushFeature {
  BrushTipShape = 1,
  ShapeDynamics = 2,
  Scattering = 4,
  Texture = 8,
  DualBrush = 16,
  ColorDynamics = 32,
  Transfer = 64,
  BrushPose = 128,
}

export interface BrushToolSettings {
  _id: string;
  name: string;
  // size
  size?: number;
  sizePressure: boolean;
  sizeJitter: number;
  minSize: number;
  // other
  flow: number;
  flowPressure: boolean;
  opacity: number;
  opacityPressure: boolean;
  spacing: number;
  hardness: number;
  // spread
  separateSpread: boolean;
  normalSpread: number;
  tangentSpread: number;
  // shape
  shape: string;
  angle: number;
  angleJitter: number;
  angleToDirection: boolean;
  // color dynamics
  colorPressure: boolean;
  foregroundBackgroundJitter: number;
  hueJitter: number;
  saturationJitter: number;
  brightnessJitter: number;
  blendMode: BrushBlendMode;
  // cache
  cache?: HTMLCanvasElement;
  // advanced settings
  flipX: boolean;
  flipY: boolean;
  roundness: number;
  // features
  lockedFeatures: BitFlags<BrushFeature>;
  hasFeatures: BitFlags<BrushFeature>;
  // blocked by plan
  locked?: boolean;
}

export interface ToolResourceGroup<T> {
  id: string;
  name: string;
  path?: string;
  info?: boolean;
  items: T[];
}

// Services

export interface ApplyFunc {
  (f: () => void): void;
}

export interface IDrawSocketService extends SocketService<IClient, IServer> {
}

export interface IStabilizerProvider {
  create(model: IToolModel, tool: ITabletTool, finish: () => void): ITabletTool;
}

export type Units = 'cm' | 'in';

export const enum CursorsMode {
  PointerName = 0,
  Pointer = 1,
  None = 2,
  PointerAvatarName = 3,
  PointerAvatar = 4
}

export interface RendererSettings {
  pixelGrid: boolean;
  sharpZoom: boolean;
  background: string;
  cursors?: CursorsMode;
  includeVideo?: boolean;
  fadeCursors: boolean;
  showCursor: boolean;
  noSyntheticCursor: boolean;
  showPerspectiveGrid: boolean;
  showPerspectiveGridHorizonLines: boolean;
  showPerspectiveGridVanishingPoints: boolean;
  showPerspectiveGridLines: boolean;
}

export interface ToolSlotTool {
  id: string;
}

export interface ToolSlot {
  tools?: ToolSlotTool[];
  activeTool?: string;
  primaryColor?: number;
  secondaryColor?: number;
}

export type TouchTapGesture = '' | 'undo' | 'redo' | 'flip-view' | 'eyedropper' | 'toggle-ui';
export type TouchDragGesture = '' | 'normal' | 'eraser' | 'pan' | 'params';
export type TouchDrag2Gesture = TouchDragGesture | 'pan-zoom';
export type TouchDragParam = '' | 'size' | 'opacity' | 'flow' | 'hardness';
export type MouseWheelAction = '' | 'zoom' | 'pan';

export interface Settings extends RendererSettings {
  version: number;
  slots: ToolSlot[];
  activeSlot: number;
  savedView?: ViewportState;
  primaryColor: number;
  secondaryColor: number;
  chatX: number;
  chatHeight: number;
  hideChatNotifications: boolean;
  disableTouch: boolean; // TODO: this should be per browser
  disableHold: boolean;
  units: Units;
  shortcuts: { [key: string]: string[] | undefined; };
  keyboardLayout: string | undefined;
  showCursor: boolean;
  showSequence: boolean;
  muteSounds: boolean; // TODO: this should be per browser
  hideNewFeatureNotifications: boolean;
  keepActiveTool: boolean; // keep current tool when switching to different tool slot even if different tool is selected at that slot
  pasteNewLayer: boolean; // always create new layer when pasting
  flipOnCursor: boolean;
  snapOnPanZoom: boolean;
  richTooltips: boolean;
  classicCursor: boolean;
  // touch gestures
  touchTap: TouchTapGesture[];
  touchDoubleTap: TouchTapGesture[];
  touchLongPress: TouchTapGesture[];
  touchDrag: TouchDrag2Gesture[];
  touchDragX: TouchDragParam[];
  touchDragY: TouchDragParam[];
  saveColorsPerSlot: boolean; // save separate primary and secondary color per tool slot
  // mouse
  mouseRight: string;
  mouseMiddle: string;
  mouseButton4: string;
  mouseButton5: string;
  mouseWheel: MouseWheelAction;
  mouseWheelCtrl: MouseWheelAction;
  mouseWheelAlt: MouseWheelAction;
  // toolsOrder
  mainToolsOrder: ToolId[];
  hiddenToolsOrder: ToolId[];
  showStrokesActivity?: boolean;
  showStrokesFullActivity?: boolean;
  showActivityBar?: boolean;
  activeBrushPreset?: string; // for custom brushes - "e07f1d11c5e0f34e04cef85c" (mongo id used) | for preset brushes - "magma_rou_ink" (brush id used (group id + brush name lowercased and replaced spaces with underscores))
}

export interface IChatMessage {
  id?: string;
  user: User | undefined;
  type: ChatType;
  messages: string[];
  openId?: string;
  updatedAt?: Date;
}

export interface TimingEntry {
  t: number; // time
  n?: string; // name
  c?: string; // color
}

export interface AsyncTimingEntry {
  f: number; // from
  t: number; // to
  n?: string; // name
  c?: string; // color
}

export interface Timings {
  sync: TimingEntry[];
  async: AsyncTimingEntry[];
}

export interface DocumentSize {
  name: string;
  width: number;
  height: number;
  icon: any;
  size?: any;
}

export type CreateImageData = (width: number, height: number, buffer: Uint8ClampedArray | undefined) => ImageData;

export interface CommandEvent {
  shiftKey?: boolean;
  shortcut?: boolean;
  pointerType?: string;
}

export interface CommandParams {
  layer?: Layer;
  drawingId?: string;
}

export type CommandExecute = (params: CommandParams, event?: CommandEvent) => Promise<any> | any;

export interface Command {
  group: string;
  id: string;
  name: string;
  description: string;
  learnMore?: string;
  video: { url: string; width: number; height: number; } | undefined;
  icons: any[];
  dontPreventDefault: boolean;
  allowRepeatedKey: boolean;
  highlight: boolean;
  nonFatal: boolean;
  feature?: Feature;
  onboardingTutorial?: UserFlowId;
  canExecute: (params: CommandParams) => boolean;
  disabledTooltip?: (params: CommandParams) => string;
  execute: CommandExecute;
}

export interface GraphPoint {
  time: number;
  value: number;
}

export interface GraphData {
  name: string;
  color: string;
  data: GraphPoint[];
  minIndex: number;
  maxIndex: number;
}

export interface GraphMarker {
  name: string;
  color: string;
  time: number;
}

export type OpenSocketInfo = { path: string; host?: string; socketId: string; drawings: string[]; }[] | { error: string; };

export interface MonthlyStats {
  date: string;
  sessions: number;
  drawings: number;
  notRegistered: number;
  returning: number;
  collaborated: number;
  returningCollaborated: number;
  createdDrawings: number;
}

export interface DailyStats extends MonthlyStats {
  failedWebgl: number;
  webglOff: number;
  returningAfter14DaysCollaborated: number;
  returningAfter30DaysCollaborated: number;
}

export interface UpdateOwnerName {
  from: string;
  to: string;
  color?: string;
}

// active list

export type PartialWithId<T, U> = Partial<T> & { id: U };

export const enum ChangeType {
  Init = 0,
  Add = 1,
  Remove = 2,
  Update = 3,
  Partial = 4,
}

type LiveInit<T> = { type: ChangeType.Init; data: T[]; };
type LiveAdd<T> = { type: ChangeType.Add; data: T; };
type LiveRemove<U> = { type: ChangeType.Remove; data: { id: U; }; };
type LiveUpdate<T> = { type: ChangeType.Update; data: T; };
type LivePartial<T, U> = { type: ChangeType.Partial; data: PartialWithId<T, U>; };

export type LiveData<T extends { id: U }, U> =
  LiveInit<T> | LiveAdd<T> | LiveRemove<U> | LiveUpdate<T> | LivePartial<T, U>;

export interface ActiveListSubscription {
  unsubscribe(): void;
}

// @ignore-translate
export enum HelpSection {
  Layer = 'layer',
  SilverDrawingAcademy = 'silver-drawing-academy'
}

// @ignore-translate
export enum HelpSeverity {
  Help = 'help',
  Warning = 'warning',
}

export interface HelpMessage {
  section: HelpSection;
  severity?: HelpSeverity;
  text: string;
  highlights?: string[];
}

export interface IHelpService {
  show(message: HelpMessage): void;
}

export interface SocketWithDrawings {
  id: string;
  socket: SocketService<IClient, IServer>;
  drawings: string[]; // tracked drawings
  initialized: boolean;
}

export type CreateClient = (socket: SocketWithDrawings) => IClient;

export interface IConnectionService {
  uniqId: string;
  drawings: Drawing[];
  all: boolean;
  sockets: SocketWithDrawings[];
  onError(message: string): void;
  createClient: CreateClient;
  disconnect(): void;
  reconnect(): void;
  update(): void;
}

export interface BannerInfo {
  on: boolean;
  label: string;
  title: string;
  subtitle: string;
  buttonText: string;
  link: string;
  image: string;
}

export interface AppClientOptions {
  authorUrl?: string;
  contactEmail?: string;
  activeSessions?: boolean;
  voiceChat?: string;
  signInToView?: boolean;
  signUps?: string[];
  allowAnonymousUsers?: boolean;
  features?: string[];
  featureLevel?: string;
  client?: string; // license
  to?: string; // license
  fromSocial?: string;
  disableMyArtdesk?: boolean;
  disableCreatingTeams?: boolean;
  disableJoiningAndLeavingTeams?: boolean;
  disableSharingAsPng?: boolean;
  disableChangingProfile?: boolean;
  // canny boards tokens
  cannyFeedbackArtspaces?: string;
  cannyFeedbackFeature?: string;
  cannyFeedbackBug?: string;
  cannyFeedbackCommunityHub?: string;

  // the below is used to redirect new users to magma.com
  newUsersRedirectUrl?: string;
  userflowToken?: string;
}

// @ignore-translate
export enum Analytics {
  SendChatMessage = 'Send chat message',
  CreateAccount = 'Create account',
  SignIn = 'Sign in',
  SignOut = 'Sign out',
  OpenDrawing = 'Open drawing',
  CreateLayer = 'Create layer',
  TakeOverLayer = 'Take over layer',
  MakeBrushStroke = 'Make brush stroke',

  StartVoiceChat = 'Start voice chat',
  JoinVoiceChat = 'Join voice chat',
  GreetUsersOnVoiceChat = 'Greet users on voice chat',
  EnableVideoTransparency = 'Enable video transparency',
  DisableVideoTransparency = 'Disable video transparency',
  ScreenshotVideoFeed = 'Screenshot video feed',
  SwitchVideoLayout = 'Switch video layout',
  StartScreenSharing = 'Start screensharing',
  StopScreenSharing = 'Stop screensharing',
  MinimizeScreenSharing = 'Minimize screensharing',
  MaximizeScreenSharing = 'Maximize screensharing',
  ShowInactiveSpeakers = 'Show inactive speakers',
  HideInactiveSpeakers = 'Hide inactive speakers',

  EnablePenPressure = 'Enable pen pressure',
  ExportImage = 'Export image',
  ShareImage = 'Share image',
  GreetUsers = 'Greet users',
  Collaborate = 'Collaborate',
  ViewCreateAccountScreen = 'View create account screen',
  ViewSigninFormScreen = 'View signin form screen',
  ViewSignupFormScreen = 'View signup form screen',
  ViewChangePasswordFormScreen = 'View change password form screen',
  ViewTermsOfUseModal = 'View terms of use modal',
  RequestPasswordResetLink = 'Request password reset link',
  PasswordChanged = 'Password successfully changed',
  UserFinishedRegistration = 'User finished registration successfully',
  CancelSignup = 'Cancel signup',
  JoinAsAnonymous = 'Join as anonymous',
  AutoJoinAsAnonymous = 'Auto-join as anonymous with generated name',
  CreateFolder = 'Create folder',
  CreateDrawing = 'Create drawing',
  CreateFlowchart = 'Create flowchart',
  DuplicateFolder = 'Duplicate folder',
  DuplicateDrawing = 'Duplicate drawing',
  DuplicateFlowchart = 'Duplicate flowchart',
  DeleteFolder = 'Delete folder',
  DeleteDrawing = 'Delete drawing',
  DeleteFlowchart = 'Delete flowchart',
  Move = 'Move',
  OpenArtspaceModal = 'Open artspace modal',
  OpenShareModal = 'Open share modal',
  CopyDrawingLink = 'Copy drawing link',
  OpenShareAsPNGModal = 'Open share as PNG modal',
  CopyShareAsPNGLink = 'Copy share as PNG link',
  OpenExportModal = 'Open export modal',
  OpenDeleteModal = 'Open delete modal',
  OpenDeleteTeamModal = 'Open delete team modal',
  OpenTeamDeletionReasonModal = 'Open team deletion reason modal',
  OpenMoveToBinModal = 'Open move to bin modal',
  ViewPage = 'View page',
  OpenFeatureNotificationModal = 'Open feature notification modal',
  CloseFeatureNotificationModal = 'Close feature notification modal',
  DisableFeatureNotifications = 'Disable feature notifications',
  OpenHelpModel = 'Open help modal',
  OpenChangelogModal = 'Open changelog modal',
  OpenExternalLink = 'Open external link',
  OpenUpgradeModal = 'Open upgrade modal',
  OpenPostModal = 'Open post modal',
  StartCheckout = 'Start checkout',
  StartSubscription = 'Start subscription',
  CancelSubscription = 'Cancel subscription',
  RestartSubscription = 'Restart subscription',
  CheckoutSessionStarted = 'Checkout session started',
  ChangeCard = 'Change card',
  ChargingCustomer = 'Charging customer',
  ChargedCustomer = 'Charged customer',
  ViewBanner = 'View banner',
  CloseBanner = 'Close banner',
  Error = 'Error',
  UserMistake = 'Make mistake',
  EditorLocked = 'EditorLocked', // means more than just lock, can be connecting, waiting for server, importing etc.
  AvatarFromSelection = 'Avatar from selection',
  ReturnFromEditor = 'Return from editor',
  NotificationSendEmail = 'Send notification email',
  NotificationSettingsChanged = 'Change drawing notifications setting',
  PricingChangesModal = 'Open pricing changes modal',
  PricingChangesModalLinkCopied = 'Pricing changes modal link copied',
  OpenProCreatorModal = 'Open pro creator modal',
  SilverShapesModal = 'Open Stephen Silver modal',
  StartAuthFlow = 'Start auth flow',
  FinishAuthFlow = 'Finish auth flow',
  HitUndoLimit = 'Hit undo limit',
  BannerOpened = 'Banner opened',
  BannerClosed = 'Banner closed',
  BannerButtonClicked = 'Banner button clicked',
  BannerContentChanged = 'Educational content setting changes',
  SurveySent = 'Sent NPS result',
  SurveyOpen = 'Open NPS survey',
  SurveyClose = 'Close NPS survey',
  SurveyDismiss = 'Dismiss NPS widget',
  SurveyDontShowAgain = 'Don\'t show again NPS widget',
  VcFeedbackSubmitted = 'VC feedback submitted',
  AnalyticsSessionCheck = 'Analytics session check',
  ViewUnauthorizedPage = 'View unauthorized page',

  ThreadLoadComments = 'Load comments',
  ThreadsOpenThread = 'Open thread',
  ThreadsDeleteThread = 'Delete thread',
  ThreadsAddComment = 'Add comment',
  ThreadsUpdateComment = 'Update comment',
  ThreadsDeleteComment = 'Delete comment',
  ThreadsResolve = 'Resolve thread',
  ThreadsUnresolve = 'Unresolve thread',
  ThreadsMarkAllCommentsAsResolved = 'Mark all comments as resolved',
  ThreadsChangeViewFilter = 'Change comments filter',
  ThreadsChangeVisibility = 'Change comments visibility',

  GenerateAPIKey = 'Generate API key',
  DeleteAPIKey = 'Delete API key',
  RefreshAPIKey = 'Refresh API key',
  ReachedAPILimit = 'Reached API Rate Limit',

  Ai = 'Generated image using AI',
  AiPutResultOnNewLayer = 'Put generated image on new layer',
  AiRetry = 'Ai Retry',
  AiError = 'Ai Client Error',
  AiServerError = 'Ai Server Error',
  AiPromptError = 'Ai Prompt Error',

  StorageLimitExceeded = 'Open storage limit modal',

  DisconnectedDuringLoading = 'Disconnected during loading',

  TextToolSelectedFontFamily = 'Selected text font family',
  TextToolSelectedFontStyle = 'Selected text font style',
  TextToolSelectedBoxType = 'Changed text box type',
  RasterizedLayer = 'Rasterized text layer',

  TransformLayer = 'Transformed layer',

  customizeToolbar = 'Customize toolbar',

  BringLayerForward = 'Bring layer forward',
  SendLayerBackward = 'Send layer backward',
  BringLayerToFront = 'Bring layer to front',
  SendLayerToBack = 'Send layer to back',

  RendererApi = 'Renderer api',
  RendererError = 'Renderer error',
  TextureLimitReached = 'Texture limit reached',

  StartOnboardingFlow = 'Start onboarding flow',
  FinishOnboardingFlow = 'Finish onboarding flow',
  LoadUserflow = 'Load userflow',

  SetBrushProperty = 'Set brush property',

  OpenReferenceWindow = 'Open reference window',
  CloseReferenceWindow = 'Close reference window',
  RescaleReferenceWindow = 'Rescale reference window',
  ImportReferenceWindow = 'Import reference window',
  SwitchReferenceWindow = 'Switch reference window',
  PasteToReferenceWindow = 'Paste to reference window',

  ChangeLayerVisibility = 'Change layer visibility',
  ChangePersonalLayerVisibility = 'Change personal layer visibility',
  ShowLayerVisibilityTooltip = 'Show layer visibility tooltip',
  DismissLayerVisibilityTooltip = 'Dismiss layer visibility tooltip',

  PerspectiveGridLayerCreation = 'Perspective grid layer creation',
  PerspectiveGridLayerVisibility = 'Perspective grid layer visibility',
  PerspectiveGridMode = 'Perspective grid mode',
  PerspectiveGridComponentsVisibility = 'Perspective grid components visibility',
  PerspectiveGridColor = 'Perspective grid color',

  SavedBrush = 'Saved brush',
  AdvancedBrushesTabOpened = 'Advanced Brushes Tab Opened',

  NotManualVcDisconnection = 'Not Manual Vc Disconnection',

  ClassicCursorSettingChanged = 'Classic cursor setting changed',

  OpenAd = 'Open advertisement',
  OptInAds = 'Opt-in to ads',
  OptInAdsClicked = 'Opt-in to ads button clicked',
  OptInButtonDisplayed = 'Opt-in button displayed',
  OptOutAds = 'Opt-out from ads',
  LoadAd = 'Advertisement loaded',

  ReferralAlive = 'Referral Alive',
  ReferralConversion = 'Referral Conversion',
  UniqueReferralLinkGenerated = 'Unique Referral Link generated',

  CameraEnabled = 'Enable camera feed',
  CameraDisabled = 'Disable camera feed',

  SwitchLanguage = 'Switch language',

  CreateArtJam = 'Create Art Jam',
  UpdateArtJam = 'Update Art Jam',
  ViewArtJam = 'View Art Jam',
  JoinArtJam = 'Join Art Jam',
  LeaveArtJam = 'Leave Art Jam',
  RepublishArtJam = 'Republish Art Jam',
  ToggleMatureContent = 'Toggle Mature Content',
  CopyArtJamlink = 'Copy Art Jam link',
  PaginateArtJams = 'Paginate Art Jams',

  UserMarkedAsTroll = 'User Marked As Troll',
  UsernameReset = 'Username reset',

  SwitchMode = 'Switch mode',
  SuperSimpleModeCohort = 'Super Simple Mode Cohort',
  CustomerInsightsShowModal = 'Customer Insights Show Modal',

  AddDrawingToSequence = 'Add drawing to sequence',
  StartingTemplate = 'Starting Template',

  OpenStrokeTracker = 'Open stroke tracker',
  UpdateStrokeTracker = 'Update stroke tracker',

  EmailVerificationSkipped = 'Email verification skipped',
  DashboardButtonClicked = 'Dashboard button clicked',

  RemovedFromRecentlyDrawnWith = 'Removed from recently-drawn-with',
  InvitingUsersToCanvas = 'Inviting users to canvas',
}

export const ANALYTICS_EVENTS: Set<string> = new Set(Object.values(Analytics));
export interface ResetPasswordData {
  password: string;
  repeatPassword: string;
}

export interface SvgIconDefinition {
  prefix: string;
  iconName: string;
  icon: [number, number, any[], string, string | string[]]; // [width, height, ?, codepoint, path]
}

export enum ThreadAnchorType {
  ANCHOR_2D = '2d',
}

export type ThreadAnchor = { type: ThreadAnchorType.ANCHOR_2D, location: Point };

export enum ThreadStatus {
  RESOLVED = 'resolved',
  UNRESOLVED = 'unresolved'
}

export interface CommentUserData {
  _id?: string; // used only internally
  name: string;
  avatar: string;
}

export interface Comment {
  shortId: string;
  user: CommentUserData;
  createdAt: Date;
  updatedAt: Date;
  canEdit: boolean;
  content: string;
  mentions?: Suggestion[];
}

export interface NewComment {
  content: string & MinLength<1> & MaxLength<typeof COMMENT_MAX_LENGTH>;
  mentions?: string[];
}

export type UpdateComment = NewComment;

export interface Thread {
  shortId: string;
  user?: CommentUserData;
  entityShortId: string;
  anchor: ThreadAnchor;
  status: ThreadStatus;
  createdAt: Date;
  updatedAt: Date;

  wasRead: boolean;
  wasReadDate: Date | null;
}

export interface ThreadReadStatus {
  threadShortId: string;
  upToDate: boolean;
  readDate: Date;
}

export interface EntityThreadReadStatus {
  entityShortId: string;
  unread: number;
  unresolved: number;
}

export interface NewThread {
  teamId: string | null;
  entityShortId: string;
  anchor: ThreadAnchor;
  comments: UpdateComment[];
}

export interface UpdateThread {
  anchor?: ThreadAnchor;
  status?: ThreadStatus;
}

export const enum MsgType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Alert = 'accent',
  Notification = 'notification',
  LimitWarning = 'limit-warning',
}

export const enum MsgVariant {
  Toast = 'msg-toast',
  Boxed = 'msg-boxed',
  Embedded = 'msg-embedded'
}

export const enum MsgBackground {
  Light = 'light',
  Dark = 'dark'
}

export enum MsgPosition {
  BottomLeft = 'bottom-left',
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomRight = 'bottom-right',
}

export interface IMsg {
  icon: SvgIconDefinition | 'spinner';
  message: string;
  timeout: number;
  type: MsgType;
  variant: MsgVariant;
  background: MsgBackground;
  closable: boolean;
  subtitle?: string;
  title?: string;
  position?: MsgPosition;

  action?: () => void;
  actionText?: string;
  onUpdate?: Subject<void>;
}

export interface CustomShape {
  id: string;
  name: string;
  width: number;
  height: number;
  path: string;
  icon?: { width: number; height: number; path: string; };
}

export enum AppNotificationStatus {
  Unread = 'unread',
  Read = 'read',
  Viewed = 'viewed'
}

export const enum AppNotificationAction {
  CommentAdded = 'comment-added',
  CommentMentioned = 'comment-mentioned',
  CanvasChanged = 'canvas-changed',
  UserJoinedTeam = 'user-joined-team',
  TeamMarkedForDeletion = 'team-marked-for-deletion',
  TeamUnmarkedForDeletion = 'team-unmarked-for-deletion',
  CanvasInvitation = 'canvas-invitation',
}

type AppNotificationEntity = { name: string; shortId: string; cacheId: string } | undefined;
type AppNotificationUser = { name: string; avatar: string } | undefined;
export enum AppNotificationFlags {
  None = 0,
  WithToast = (1 << 0),
  NsfwThumb = (1 << 1),
}

export interface AppNotificationCommentAddedTrigger {
  user: AppNotificationUser;
  action: AppNotificationAction.CommentAdded;
  entity: AppNotificationEntity;
  data: {
    comment: string;
    threadShortId: string;
    commentShortId: string;
  };
}

export interface AppNotificationCommentMentionTrigger {
  user: AppNotificationUser;
  action: AppNotificationAction.CommentMentioned;
  entity: AppNotificationEntity;
  data: {
    comment: string;
    threadShortId: string;
    commentShortId: string;
  };
}

export interface AppNotificationCanvasChangedTrigger {
  user: AppNotificationUser;
  action: AppNotificationAction.CanvasChanged;
  entity: AppNotificationEntity;
  data: null;
}

export interface AppNotificationUserJoinedTeamTrigger {
  user: AppNotificationUser;
  action: AppNotificationAction.UserJoinedTeam;
  entity: null;
  data: null;
}

export interface AppNotificationTeamMarkedForDeletion {
  user: AppNotificationUser;
  action: AppNotificationAction.TeamMarkedForDeletion;
  entity: null;
  team: null;
  data: null;
}
export interface AppNotificationTeamUnmarkedForDeletion {
  user: AppNotificationUser;
  action: AppNotificationAction.TeamUnmarkedForDeletion;
  entity: null;
  team: null;
  data: null;
}
export interface AppNotificationCanvasInvitation {
  user: AppNotificationUser;
  action: AppNotificationAction.CanvasInvitation;
  entity: AppNotificationEntity;
  data: null;
}

export type AppNotificationTriggers =
  AppNotificationCommentAddedTrigger |
  AppNotificationCommentMentionTrigger |
  AppNotificationUserJoinedTeamTrigger |
  AppNotificationCanvasChangedTrigger |
  AppNotificationTeamMarkedForDeletion |
  AppNotificationTeamUnmarkedForDeletion |
  AppNotificationCanvasInvitation;

export interface AppNotification {
  shortId: string;
  status: AppNotificationStatus;
  createdAt: Date;
  trigger: AppNotificationTriggers;
  flags?: AppNotificationFlags;
  team?: {
    name: string;
    avatar: string;
    cacheId: string;
    slug: string;
    _id: string;
  } | null; // null for artdesk. optional since we have notifications without team
  generatedContent?: string;
}

export const enum AppNotificationSettingsOption {
  All = 'all',
  Replies = 'replies',
  Never = 'never'
}

export const DRAWING_NOTIFICATION_SETTINGS = [
  AppNotificationSettingsOption.All,
  AppNotificationSettingsOption.Replies,
  AppNotificationSettingsOption.Never,
];

export const DEFAULT_NOTIFICATION_SETTING = AppNotificationSettingsOption.Replies;
export const DEFAULT_OWNER_NOTIFICATION_SETTING = AppNotificationSettingsOption.All;

export interface AppNotificationSettings {
  entityShortId: string;
  userId: string;
  setting: AppNotificationSettingsOption;
}

export interface AppNotificationTeamCounter {
  teamId: string;
  unread: number;
}

export interface NewAppNotification {
  trigger: Omit<AppNotificationTriggers, 'entity' | 'user'> & { entity: string | null, user: string };
  team: string | null;
  flags?: AppNotificationFlags;
}

export interface LayerLoadingResult {
  loadersUsed: { [key: string]: number; };
}

export const NOTIFICATION_ARTDESK_ID = 'artdesk';
export const TREE_ARTDESK_ID = 'artdesk';

export type SelectedTypes = 'Folder' | 'Drawing' | 'Flowchart' | 'Artdesk' | 'Project'; // EntityType + 2 additional values from file selector
export interface SelectedFolder {
  teamId: string | undefined;
  projectId: string | undefined;
  entityId: string | undefined;
  type: SelectedTypes;
  name?: string;
}

export enum SelectMode {
  Create,
  Move,
  Select
}

export const enum DrawingLoadFailure {
  None = '',
  Error = 'error',
  PasswordNeeded = 'password-needed',
  NoAccess = 'no-access',
  SessionLimit = 'session-limit',
  NotFound = 'not-found',
  FrameLimit = 'frame-limit',
}

export interface ThumbOptions {
  size?: number;
  width?: number;
  height?: number;
}

export interface TagInfo {
  tag: string;
}

export interface Suggestion {
  _id: string;
  name: string;
  avatar: string | undefined;
  email?: string;
}

export interface ProCreatorInfo {
  id: string;
  name: string;
  logo: string;
  url: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  linkedin?: string;
  upperParagraphs: string[];
  lowerParagraphs: string[];
  video?: string;
  action?: { text: string; url: string; badge?: string; };
  items?: any[];
}

export interface PresentationHost {
  uniqIds: string[];
  name?: string;
}

export interface PresentationModeState {
  host: PresentationHost | undefined;
  viewport?: PresentViewport;
  followingHostLocationEnforced: boolean;
  hideSequencePanel: boolean;
  followingHostViewportEnforced: boolean;
  participantsUiHidden: boolean;
  viewersUniqIds?: string[];
  isForced?: boolean;
  originalHost?: PresentationHost;
  takingOver?: boolean;
  drawingId?: string;
  sequenceId?: string;
}

export const DEFAULT_PRESENTATION_MODE_STATE: PresentationModeState = {
  host: undefined,
  viewport: undefined,
  followingHostLocationEnforced: true,
  followingHostViewportEnforced: true,
  participantsUiHidden: true,
  hideSequencePanel: true,
  viewersUniqIds: []
};

export interface PresentationModeStartState {
  participantsUiHidden?: boolean;
  invitedUsers?: string[];
  isForced?: boolean;
  isSuperAdmin?: boolean;
  followingHostLocationEnforced?: boolean;
  followingHostViewportEnforced?: boolean;
  hideSequencePanel?: boolean;
}

export interface PresentationModeStartModal {
  currentCanvasUsers: User[];
  currentSequenceUsers: User[];
  hasObligeJoiningFeature?: boolean;
  drawing?: Drawing;
}

export interface PresentationModeEndingState {
  isHost: boolean;
  viewers: string[];
}

export enum PresentationActions {
  InvitedToPresentation = 'invited-to-presentation',
  LeavePresentation = 'leave-presentation',
  JoinPresentation = 'join-presentation',
  EndPresentation = 'end-presentation',
  inactivePresentationHost = 'inactive-presentation-host',
  presentationHostDisconnected = 'presentation-host-disconnected',
  takeOverPresentation = 'take-over-presentation',
  reclaimPresentation = 'reclaim-presentation',
  presentationIsOver = 'presentation-is-over',
  givenPresenterRole = 'given-presenter-role',
  passPresenterRole = 'pass-presenter-role',
  PresentingNow = 'presenting-now',
  TookControlOverPresentation = 'took-over-presentation',
  HandingControlOverPresentation = 'hand-over-presentation',
}

export enum PresentationModeMessage {
  ChangeActiveSlide = 'You cannot change the Active Slide you are currently on during this Presentation. Leave this Presentation or contact the Presenter.',
  DisabledSequence = 'Sequence Panel is disabled during this Presentation. To enable it contact Presenter.',
  DisabledZoomAndPan = 'Zooming and panning during this Presentation is disabled by the Presenter. Contact Presenter to enable it.',
}

export interface PresentationActionData {
  title: string,
  mode: PresentationActions,
  user?: string,
  timer?: number;
  forced?: boolean;
  viewers?: number;
  state?: PresentationModeState;
}

export enum ShareType {
  VISIBLE_TO_MEMBERS = 0,
  RESTRICTED_TO_SPECIFIC_PEOPLE = 1,
  PUBLIC = 2
}

export type AiToolPipeline = 'create' | 'enhance' | 'inpaint' | 'outpaint';
export type AiToolTab = AiToolPipeline | 'advanced';
export type AiToolSetting = AiToolPipeline | 'all';

export interface StableDiffusionInput {
  pipeline: AiToolPipeline;

  width: number;
  height: number;
  resolution: number;

  prompt: string;
  seed: number;
  prompt_strength: number;
  num_inference_steps: number;
  guidance_scale: number;
  num_outputs: number;

  disable_nsfw_checker?: boolean;

  init_image?: string;
  mask?: string;

  model: AiCheckpointFileName;
  sampler: string;
  negative_prompt: string;
  type: string;
  script_name: string | null;
  enable_hr: boolean;
  tiling: boolean;
  mask_blur: number;
  inpaint_fill: number;

  controlnet_preprocessor: AiControlNetPreprocessorName;
  controlnet_model: AiControlNetModelName;
}

export type StableDiffusionServerInput = Omit<StableDiffusionInput, 'mask' | 'init_image'> & {
  origin: string;
  mask?: Buffer;
  init_image?: Buffer;
  userId: string;
  drawingId: string;
};


export interface PromptHistoryItem {
  _id?: string;
  createdAt: string;
  user: {
    _id: string;
    name: string;
    avatar?: string;
  };
  drawing: string;
  mode: AiToolPipeline;
  prompt: string;
  negativePrompt: string;
  width: number;
  height: number;
  seed: number;
  promptStrength: number;
  steps: number;
  guidanceScale: number;
  numOutputs: number;
  model: string;
  sampler: string;
  resultError?: string;

  inpaintMaskBlur?: number;
  resolution?: number;
  hr?: boolean;
  inpaintFill?: InpaintFill;
}

export interface ClientBrowserInfo {
  os?: string;
  osVersion?: string;
  browser?: string;
  browserVersion?: string;
  device?: string;
  deviceType?: string;
  deviceModel?: string;
  screenResolution?: string;
  viewportSize?: string;
  browserLanguage?: string;
  // input
  touchEnabled?: boolean;
  keyboardAttached?: boolean;
  tabletAttached?: boolean;
  tabletModel?: string;
  chromeExtension?: boolean;
  // other
  referrer?: string;
  referringDomain?: string;
  isWebView?: boolean;
  preferredLocale?: string;
}

export interface IErrorReporter {
  reportError(message: string, error?: any | undefined, data?: any): void;
}

export interface LastDisconnectOnClient {
  code: number;
  reason: string;
}

export type SelectionToolMode = 'select' | 'add' | 'subtract' | 'intersect';
export const SelectionToolModes: SelectionToolMode[] = ['select', 'add', 'subtract', 'intersect'];

export interface FilterSettings {
  x: number;
  y: number;
  radius: number;
  distance: number;
  angle: number;
  hue: number;
  saturation: number;
  lightness: number;
  preview: boolean;
  pinned: boolean;
}

export interface EntityGridSettings {
  size: number;
  defaultColumns?: string[];
  binColumns?: string[];
  searchColumns?: string[];
}

export interface InvitePresentationViewers {
  invitedUsers: string[];
  isForced: boolean;
}

export interface KickPresentationViewers {
  kickedUsers: string[];
}

export interface PresentationModeInvite {
  name: string;
  isForced: boolean;
  invitedUsers: number;
}

export interface JoinPresentation {
  viewer: string;
}

export interface LeavePresentation {
  viewer: string;
}

export interface PresentViewport {
  view: Viewport;
}

export interface PresentationModeUpdated {
  state: PresentationModeState;
  drawingId: string;
  sequenceId: string;
  userJoiningId?: string;
  userLeavingId?: string;
}

export interface TakeOverPresentationBroadcast {
  drawingId: string;
  sequenceId: string;
  hostIds?: string[];
  name: string;
  clientId: string;
}

export interface TakeOverPresentationParams {
  isHost?: boolean;
  hostIds?: string[];
  name?: string;
}

export interface PresentationModeSequenceInviteParams {
  drawingId: string;
  sequenceId: string;
  name: string;
  isForced: boolean;
  invitedUsers: string[];
}

export interface ToolsOrder {
  mainToolsOrder?: number[];
  hiddenToolsOrder?: number[];
}

export enum SettingsModalTabs {
  General = 0,
  Shortcuts = 1,
  Mouse = 2,
  Touch = 3,
  Toolbar = 4,
  Grid = 5,
  Other = 6
}

export type TextSelection = [number, number, TextSelectionDirection];

export type LastFilter =
  { name: 'gaussianBlur', settings: { [key: string]: number | Curve[] } } |
  { name: 'motionBlur', settings: { [key: string]: number | Curve[] } } |
  { name: 'hueSaturationLightness', settings: { [key: string]: number | Curve[] } } |
  { name: 'brightnessContrast', settings: { [key: string]: number | Curve[] } } |
  { name: 'curves', settings: { [key: string]: number | Curve[] } } |
  { name: '', settings: { [key: string]: number | Curve[] } };

export enum ProjectType {
  Project = 'project',
  ContentPage = 'content-page',
}
export interface hasAnalyticsLayerType {
  layerType: 'raster' | 'text';
}

export type TransformOperations = 'move' | 'rotate' | 'scale' | 'move-origin';

export enum PublicationStatus {
  Pending,
  Accepted,
  Rejected,
}

export interface StoryProtocolPublicationPublisher {
  _id: string;
  name?: string; // not storing this directly
  avatar?: string; // not storing this directly
}

export interface StoryProtocolPublicationParticipant {
  accountId: string;
  name: string; // published/credited under this name
  percentage: number;
  avatar?: string; // not storing this directly, only populating when returning to app,
  status: PublicationStatus;
}

export interface PublicationCommon {
  publishedAt: number;
}

export interface StoryProtocolRegistrationCommon {
  ipAssetName: string;
  description: string;
  licenseTermId: LicenseTermId; // we can technically store chosen license termId but what if it changes in StoryHub
}

export interface StoryProtocolPublication extends PublicationCommon, StoryProtocolRegistrationCommon {
  ipAssetId: number | `0x${string}`; // foreign id (ipAssetId)
  url: string;
  status: PublicationStatus,
  publisher: StoryProtocolPublicationPublisher,
  participants: StoryProtocolPublicationParticipant[];
}

export interface TemplatePublication extends PublicationCommon {
  templateName: string;
  publishedBy: string;
}

export type ExternalPublication = StoryProtocolPublication | TemplatePublication;

export type ExternalPublicationMap = {
  [PublisherId.Template]: TemplatePublication,
  [PublisherId.StoryProtocol]: StoryProtocolPublication,
}

export type ExternalPublications = Partial<ExternalPublicationMap>;

export interface DrawingRevision {
  _id: string;
  author: {
    _id: string;
    avatar: string | undefined;
    name: string;
    verified: boolean;
  } | null;
  cacheId?: string;
  commitType: CommitType;
  createdAt: string;
  entity: string | undefined;
  modifiedAt: string | undefined;
  name: string
  openedAt: string | undefined;
  shortId: string;
  type: string;
  updatedAt: string;
  label?: string;
  isBookmarked?: boolean;
  publishedIn?: ExternalPublications;
  inheritedLicense?: InheritedLicense;
}

export interface ClientActionRevisionCreated {
  revision: DrawingRevision;
}

export interface ClientActionRevisionUpdated {
  revisionId: string;
  update: Partial<DrawingRevision>;
}

export interface ClientActionRevisionRemoved {
  revisionId: string;
}

export interface ClientActionReferencedDrawingResized extends Rect {
  drawingId: string;
}

export interface ClonedDrawingData {
  _id: string;
  shortId: string;
  name: string;
}

export enum CommitType {
  Auto = 'auto',
  Manual = 'manual',
  Temporary = 'temporary', // this is created only to render current drawing state, it will be ignored when listing and it will be deleted after 24h see: GarbageCollectObjectsJob
  Timelapse = 'timelapse',
  Report = 'report',
}

export enum SortOrders {
  Ascending = 'asc',
  Descending = 'desc'
}
export interface SimpleTableParams {
  sortBy: string;
  sortDirection: SortOrders;
  pageSize: number;
  currentPage: number;
  total?: number;
}

export const DEFAULT_SIMPLE_TABLE_PARAMS: SimpleTableParams = {
  currentPage: 1,
  pageSize: 10,
  sortBy: '',
  sortDirection: SortOrders.Ascending,
  total: 0
};

export interface SimpleTableColumn {
  name: string | (() => string);
  key?: string;
  sortable?: boolean;
  inMobileView?: boolean;
  isHidden?: boolean;
  ignoreAction?: boolean;
}

export enum AlignmentType {
  Left,
  Right,
  Top,
  Bottom,
  CenterHorizontally,
  CenterVertically,
}

export interface PresentationViewerNotification {
  id: string;
  view: Viewport | undefined;
}

export enum SubscriptionPlan {
  Free = 0,
  Blaze,
  Fusion,
}

export interface OnboardingFlowSettings {
  demoDrawingToJumpTo?: string; // when starting flow this drawing will be copied and added to users artworks
  redirections?: string[];
  deployment?: { // if missing flow is not deployed to anyone (yet can be configured)
    createdAt?: number; // if present deploys to users created past this timestamp (or with deployment.tag)
    tag?: string; // if present deploy to users with tag (or with deployment.date)
    requiresFeature?: Feature; // deploys to users that have certain feature flag, requires it alongside one of OR conditions above
  };
}

export enum SplashScreenId {
  ConnectToStory,
}

export interface StoryProtocolRegisterRevisionData extends StoryProtocolRegistrationCommon {
  participants: StoryProtocolPublicationParticipant[];
}

export interface TemplateRegisterRevisionData {

}

export interface ArtworkPublishingResult {
  url: string;
  id: string | number;
  status: PublicationStatus;
}

export interface InheritedLicense {
  shortId: string;
  name: string;
}

export interface ClientInheritedLicense extends InheritedLicense {
  publishedIn: ExternalPublications;
  cacheId?: string;
}

export enum RequestArtworkRegistrationResponse {
  Accept = 'accept',
  Reject = 'reject',
  View = 'view'
}

export enum ResizeAlign {
  LeftTop,
  CenterTop,
  RightTop,

  LeftCenter,
  Center,
  RightCenter,

  LeftBottom,
  CenterBottom,
  RightBottom,
}

export interface Tiles {
  ox: number;
  oy: number;
  tiles: (Tile | null)[][];
}

export interface Tile {
  rect: Rect;
  texture: Texture;
  textureRect: Rect;
}

export interface LoadOptions {
  onBlock?: (name: string) => void;
}

export type LoadImageCancellable<T> = (url: string, options?: LoadOptions) => CancellablePromise<T>;

export interface UsageQuota {
  used: number;
  total: number;
}

export interface SavedBrush {
  _id: string;
  owner: string;
  settings: BrushToolSettings;
}

export interface ImportFileInfo {
  drawingId: string | undefined;
  size: number | undefined;
  name: string | undefined;
}

export enum EditorPopupId {
  VersionHistory = 'history-popup',
  ReferenceWindow = 'reference-window-popup',
  AdvancedBrushes = 'advanced-brushes',
}

export enum ReferralStatus {
  Pending,
  Active,
  Rejected,
}

export enum FeaturePromoId {
  Brushes = 'brushes',
  Screenshare = 'screenshare',
}

export interface UserReport {
  _id: string;
  title: string;
  description: string;
  reporter: string; // user _id

  // context
  drawing?: string // shortId
  user?: string // user _id;
  revision?: string // shortId

  createdAt: Date;
  updatedAt: Date;
}

export type UserReportCreate = Pick<UserReport, 'title' | 'description' | 'drawing' | 'user'>;
export interface VcAction {
  caller?: string;
}

export interface VcSession extends VcAction {
  hasSession: boolean;
}

export interface VcPeerId extends VcAction {
  peerId: string;
}

export interface VcToken extends VcAction {
  token: string | undefined;
}

export interface VcActionList extends VcAction {
  actions: [VoiceChatAction, VcActions][];
}

export type VcActions = VcSession | VcPeerId | VcActionList | VcToken;

export const enum DirectionLock {
  None,
  Horizontal,
  Vertical,
}

export interface Rewards {
  extraCustomBrushSlots?: number;
}

export interface AnalyticsSession {
  cumulativeMinutes?: number
}

export enum InterviewInviteStatus {
  POSTPONE = 'postpone',
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected'
}

export enum PublisherConstantId {
  GetDefaultLicensingRights = 'defaultLicensingRights',
}

export enum SuperSimpleModePanel {
  COLORS = 'colors',
  SLIDERS = 'sliders',
  LAYERS = 'layers',
}

export interface MiniUser {
  _id: string,
  name: string,
  avatar: string | undefined,
}
