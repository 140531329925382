import { Rect, Poly, Mat2d, Vec2, TriangleBatch, Polyf } from '../common/interfaces';
import { pushQuad, pushLine, pushQuadTransformed } from './webglBatch';
import { isRectEmpty } from '../common/rect';
import { getPixelRatio } from '../common/utils';
import { round5 } from '../common/mathUtils';
import { isPolyfSegmentEmpty, isPolySegmentEmpty } from '../common/poly';
import { createVec2, setVec2, copyVec2 } from '../common/vec2';
import { transformVec2ByMat2d } from '../common/mat2d';

export function pushRectOutline(
  batch: TriangleBatch, x: number, y: number, w: number, h: number, strokeWidth: number,
  r: number, g: number, b: number, a: number
) {
  const half = strokeWidth / 2;

  if (w > strokeWidth && h > strokeWidth) {
    pushQuad(batch, x - half, y - half, w + strokeWidth, strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a); // top
    pushQuad(batch, x - half, y - half + h, w + strokeWidth, strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a); // bottom
    pushQuad(batch, x - half, y + half, strokeWidth, h - strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a); // left
    pushQuad(batch, x - half + w, y + half, strokeWidth, h - strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a); // right
  } else {
    pushQuad(batch, x - half, y - half, w + strokeWidth, h + strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a);
  }
}

export function pushRectOutlineTransformed(
  batch: TriangleBatch, transform: Float32Array, x: number, y: number, w: number, h: number, strokeWidth: number,
  r: number, g: number, b: number, a: number
) {
  const half = strokeWidth / 2;

  if (w > strokeWidth && h > strokeWidth) {
    pushQuadTransformed(batch, transform, x - half, y - half, w + strokeWidth, strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a); // top
    pushQuadTransformed(batch, transform, x - half, y - half + h, w + strokeWidth, strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a); // bottom
    pushQuadTransformed(batch, transform, x - half, y + half, strokeWidth, h - strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a); // left
    pushQuadTransformed(batch, transform, x - half + w, y + half, strokeWidth, h - strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a); // right
  } else {
    pushQuadTransformed(batch, transform, x - half, y - half, w + strokeWidth, h + strokeWidth, 0, 0, 0, 0, 0, 0, r, g, b, a);
  }
}

export function pushLineRect(batch: TriangleBatch, rect: Rect, mat: Mat2d, thickness: number) {
  if (isRectEmpty(rect))
    return;

  const ratio = getPixelRatio();
  let a = createVec2();
  let b = createVec2();
  let c = createVec2();
  let d = createVec2();

  setVec2(a, rect.x, rect.y);
  transformVec2ByMat2d(a, a, mat);
  round5Vec2(a, ratio);

  setVec2(b, rect.x + rect.w, rect.y);
  transformVec2ByMat2d(b, b, mat);
  round5Vec2(b, ratio);

  setVec2(c, rect.x + rect.w, rect.y + rect.h);
  transformVec2ByMat2d(c, c, mat);
  round5Vec2(c, ratio);

  setVec2(d, rect.x, rect.y + rect.h);
  transformVec2ByMat2d(d, d, mat);
  round5Vec2(d, ratio);

  let u = 0;
  u = pushABLine(batch, a, b, u, thickness);
  u = pushABLine(batch, b, c, u, thickness);
  u = pushABLine(batch, c, d, u, thickness);
  u = pushABLine(batch, d, a, u, thickness);
}

export function pushLineEllipse(batch: TriangleBatch, rect: Rect, mat: Mat2d, thickness: number) {
  if (!isRectEmpty(rect)) {
    const ratio = getPixelRatio();
    let prev = createVec2();
    let curr = createVec2();
    const rx = rect.w / 2;
    const ry = rect.h / 2;
    const cx = rect.x + rx;
    const cy = rect.y + ry;
    const steps = 100;
    const thetaStep = (Math.PI * 2) / steps;
    let u = 0;

    {
      const x = cx + rx * Math.cos(0);
      const y = cy - ry * Math.sin(0);
      setVec2(prev, x, y);
      transformVec2ByMat2d(prev, prev, mat);
      round5Vec2(prev, ratio);
    }

    for (let i = 0; i <= steps; i++) {
      const theta = i * thetaStep;
      const x = cx + rx * Math.cos(theta);
      const y = cy - ry * Math.sin(theta);
      setVec2(curr, x, y);
      transformVec2ByMat2d(curr, curr, mat);
      round5Vec2(curr, ratio);
      u = pushABLine(batch, prev, curr, u, thickness);
      [prev, curr] = [curr, prev];
    }
  }
}

function round5Vec2(vec: Vec2, ratio: number) {
  vec[0] = round5(vec[0] * ratio);
  vec[1] = round5(vec[1] * ratio);
}

function getPoint(vec: Vec2, x: number, y: number, m: Mat2d, ratio: number) {
  vec[0] = round5((m[0] * x + m[2] * y + m[4]) * ratio);
  vec[1] = round5((m[1] * x + m[3] * y + m[5]) * ratio);
}

function pushABLine(batch: TriangleBatch, a: Vec2, b: Vec2, u: number, thickness: number) {
  const ax = a[0];
  const ay = a[1];
  const bx = b[0];
  const by = b[1];
  const dx = bx - ax;
  const dy = by - ay;
  const d = Math.sqrt(dx * dx + dy * dy);
  const nextU = u + d;
  pushLine(batch, ax, ay, bx, by, thickness, u, nextU);
  return nextU;
}

export function pushPoly(batch: TriangleBatch, poly: Poly, mat: Mat2d, close: boolean, thickness: number) {
  const ratio = getPixelRatio();
  const prev = createVec2();
  const vec = createVec2();

  const { ox, oy, segments } = poly;

  for (const segment of segments) {
    if (!isPolySegmentEmpty(segment)) {
      const { items, size } = segment;
      const size2 = size << 1;
      let u = 0;

      getPoint(prev, items[0] + ox, items[1] + oy, mat, ratio);

      for (let j = 2; j < size2; j += 2) {
        getPoint(vec, items[j] + ox, items[j + 1] + oy, mat, ratio);
        u = pushABLine(batch, prev, vec, u, thickness);
        copyVec2(prev, vec);
      }

      if (close) {
        getPoint(vec, items[0] + ox, items[1] + oy, mat, ratio);
        pushABLine(batch, prev, vec, u, thickness);
      }
    }
  }
}

export function pushPolyf(batch: TriangleBatch, polyf: Polyf, mat: Mat2d, close: boolean, thickness: number) {
  const ratio = getPixelRatio();
  const prev = createVec2();
  const vec = createVec2();

  for (const items of polyf.segments) {
    if (!isPolyfSegmentEmpty(items)) {

      const size2 = items.length << 1;
      let u = 0;

      getPoint(prev, items[0] + polyf.ox, items[1] + polyf.oy, mat, ratio);

      for (let j = 2; j < size2; j += 2) {
        getPoint(vec, items[j] + polyf.ox, items[j + 1] + polyf.oy, mat, ratio);
        u = pushABLine(batch, prev, vec, u, thickness);
        copyVec2(prev, vec);
      }

      if (close) {
        getPoint(vec, items[0] + polyf.ox, items[1] + polyf.oy, mat, ratio);
        pushABLine(batch, prev, vec, u, thickness);
      }
    }
  }
}

export function pushPolygon(batch: TriangleBatch, poly: Vec2[], close: boolean, thickness: number) {
  let u = 0;
  let prev = poly[0];

  for (let j = 1; j < poly.length; j++) {
    let vec = poly[j];
    u = pushABLine(batch, prev, vec, u, thickness);
    prev = vec;
  }

  if (close) {
    pushABLine(batch, prev, poly[0], u, thickness);
  }
}
