import type { Rect } from './interfaces';

const max = Math.max;
const min = Math.min;
const abs = Math.abs;

export function createRect(x: number, y: number, w: number, h: number): Rect {
  if (w < 0 || h < 0) throw new Error(`Invalid rect (${x} ${y} ${w} ${h})`);
  return { x, y, w, h };
}

export function validateRect(rect: Rect) {
  if (!isOk(rect.x) || !isOk(rect.y) || !isOk(rect.w) || !isOk(rect.h) || rect.w < 0 || rect.h < 0) throw new Error(`Invalid rect (${rect.x} ${rect.y} ${rect.w} ${rect.h})`);
}

export function rectToString(r: Rect | undefined) {
  return r ? `${r.x}, ${r.y}, ${r.w}, ${r.h}` : '?';
}

export function isRectEmpty(rect: Rect) {
  return rect.w === 0 || rect.h === 0;
}

export function isIntegerRect(rect: Rect) {
  return (rect.x | 0) === rect.x && (rect.y | 0) === rect.y && (rect.w | 0) === rect.w && (rect.h | 0) === rect.h;
}

export function rectContainsRect(rect: Rect, r: Rect) {
  return rect.x < r.x && (rect.x + rect.w) > (r.x + r.w) && rect.y < r.y && (rect.y + rect.h) > (r.y + r.h);
}

export function rectIncludesRect(rect: Rect, r: Rect) {
  return rect.x <= r.x && (rect.x + rect.w) >= (r.x + r.w) && rect.y <= r.y && (rect.y + rect.h) >= (r.y + r.h);
}

export function rectContainsXY(rect: Rect, x: number, y: number) {
  return x > rect.x && x < (rect.x + rect.w) && y > rect.y && y < (rect.y + rect.h);
}

export function resetRect(rect: Rect) {
  rect.x = 0;
  rect.y = 0;
  rect.w = 0;
  rect.h = 0;
}

export function cloneRect(rect: Rect): Rect {
  return createRect(rect.x, rect.y, rect.w, rect.h);
}

export function copyRect(rect: Rect, source: Rect) {
  rect.x = source.x;
  rect.y = source.y;
  rect.w = source.w;
  rect.h = source.h;
  return rect;
}

function isOk(value: number) {
  return !Number.isNaN(value) && Number.isFinite(value);
}

export function safeRect(rect: Rect) {
  const result = createRect(0, 0, 0, 0);
  if (typeof rect === 'object') {
    if (isOk(rect.x)) result.x = rect.x;
    if (isOk(rect.y)) result.y = rect.y;
    if (isOk(rect.w)) result.w = rect.w; // TODO: maybe forbid negatives? some tools rely on negative values here
    if (isOk(rect.h)) result.h = rect.h; // TODO: maybe forbid negatives? some tools rely on negative values here
  }
  return result;
}

export function setRect(rect: Rect, x: number, y: number, w: number, h: number) {
  if (w < 0 || h < 0) throw new Error(`Invalid rect (${x} ${y} ${w} ${h})`);

  rect.x = x;
  rect.y = y;
  rect.w = w;
  rect.h = h;
  return rect;
}

export function normalizeRect(rect: Rect) {
  setRectNormalized(rect, rect.x, rect.y, rect.w, rect.h);
}

export function setRectNormalized(rect: Rect, x: number, y: number, w: number, h: number) {
  rect.x = min(x, x + w);
  rect.y = min(y, y + h);
  rect.w = abs(w);
  rect.h = abs(h);
  return rect;
}

export function setRectDynamic(rect: Rect, x: number, y: number, w: number, h: number, fixedRatio: boolean, fromCenter: boolean) {
  if (fixedRatio) {
    const s = min(abs(w), abs(h));
    w = w < 0 ? -s : s;
    h = h < 0 ? -s : s;
  }

  if (fromCenter) {
    x -= w;
    y -= h;
    w *= 2;
    h *= 2;
  }

  return setRect(rect, min(x, x + w), min(y, y + h), abs(w), abs(h));
}

export function rectsEqual(a: Rect, b: Rect): boolean {
  return a.x === b.x && a.y === b.y && a.w === b.w && a.h === b.h;
}

// TODO: remove this, it behaves weirdly in some cases
export function makeIntegerRect(rect: Rect) {
  if ((rect.x % 1) > 0) {
    ++rect.w;
  }
  if ((rect.y % 1) > 0) {
    ++rect.h;
  }
  rect.x = rect.x | 0;
  rect.y = rect.y | 0;
  rect.w = Math.ceil(rect.w) | 0;
  rect.h = Math.ceil(rect.h) | 0;
  return rect;
}

export function integerizeRect(rect: Rect) {
  const x1 = Math.floor(rect.x);
  const y1 = Math.floor(rect.y);
  const x2 = Math.ceil(rect.x + rect.w);
  const y2 = Math.ceil(rect.y + rect.h);
  rect.x = x1;
  rect.y = y1;
  rect.w = x2 - x1;
  rect.h = y2 - y1;
}

export function moveRect(rect: Rect, x: number, y: number) {
  rect.x += x;
  rect.y += y;
  return rect;
}

export function scaleRect(rect: Rect, sx: number, sy: number) {
  rect.x *= sx;
  rect.y *= sy;
  rect.w *= abs(sx);
  rect.h *= abs(sy);

  if (sx < 0) rect.x -= rect.w;
  if (sy < 0) rect.y -= rect.h;

  return rect;
}

export function outsetRect(rect: Rect, d: number) {
  const dd = 2 * d;

  if (-dd > rect.w) {
    rect.x = rect.x + rect.w / 2;
    rect.w = 0;
  } else {
    rect.x -= d;
    rect.w += dd;
  }

  if (-dd > rect.h) {
    rect.y = rect.y + rect.h / 2;
    rect.h = 0;
  } else {
    rect.y -= d;
    rect.h += dd;
  }

  return rect;
}

export function outsetRectForBlur(rect: Rect, bounds: Rect, d: number) {
  outsetRect(rect, d);
  clipRect(rect, bounds.x, bounds.y, bounds.w, bounds.h);
  return rect;
}

export function addRect(rect: Rect, other: Rect) {
  if (!isRectEmpty(other)) {
    if (isRectEmpty(rect)) {
      rect.x = other.x;
      rect.y = other.y;
      rect.w = other.w;
      rect.h = other.h;
    } else {
      const xx = min(rect.x, other.x);
      const yy = min(rect.y, other.y);
      rect.w = max(rect.x + rect.w, other.x + other.w) - xx;
      rect.h = max(rect.y + rect.h, other.y + other.h) - yy;
      rect.x = xx;
      rect.y = yy;
    }
  }

  return rect;
}

export function clipRect(rect: Rect, x: number, y: number, w: number, h: number) {
  if (w < 0 || h < 0) throw new Error(`Invalid clip (${x} ${y} ${w} ${h})`);

  if (isRectEmpty(rect) || w === 0 || h === 0) {
    rect.x = 0;
    rect.y = 0;
    rect.w = 0;
    rect.h = 0;
  } else {
    const l1 = rect.x;
    const l2 = x;
    const r1 = rect.x + rect.w;
    const r2 = x + w;
    const t1 = rect.y;
    const t2 = y;
    const b1 = rect.y + rect.h;
    const b2 = y + h;

    if (l1 > r2 || r1 < l2 || t1 > b2 || b1 < t2) {
      rect.x = 0;
      rect.y = 0;
      rect.w = 0;
      rect.h = 0;
    } else {
      rect.x = max(l1, l2);
      rect.y = max(t1, t2);
      rect.w = min(r1, r2) - rect.x;
      rect.h = min(b1, b2) - rect.y;
    }
  }

  return rect;
}

export function intersectRect(rect: Rect, other: Rect) {
  if (isRectEmpty(rect) || isRectEmpty(other)) {
    rect.x = 0;
    rect.y = 0;
    rect.w = 0;
    rect.h = 0;
  } else {
    const l1 = rect.x;
    const l2 = other.x;
    const r1 = rect.x + rect.w;
    const r2 = other.x + other.w;
    const t1 = rect.y;
    const t2 = other.y;
    const b1 = rect.y + rect.h;
    const b2 = other.y + other.h;

    if (l1 > r2 || r1 < l2 || t1 > b2 || b1 < t2) {
      rect.x = 0;
      rect.y = 0;
      rect.w = 0;
      rect.h = 0;
    } else {
      const x = max(l1, l2);
      const y = max(t1, t2);
      rect.w = min(r1, r2) - x;
      rect.h = min(b1, b2) - y;
      rect.x = x;
      rect.y = y;
    }
  }

  return rect;
}

export function haveNonEmptyIntersection(a: Rect, b: Rect) {
  return a.w !== 0 && a.h !== 0 && b.w !== 0 && b.h !== 0 &&
    a.x < (b.x + b.w) && (a.x + a.w) > b.x && a.y < (b.y + b.h) && (a.y + a.h) > b.y;
}

export function rectsIntersection(...rects: Rect[]): Rect {
  const result = cloneRect(rects[0]);

  for (let i = 1; i < rects.length; i++) {
    intersectRect(result, rects[i]);
  }

  return result;
}

export function pointInRect(x: number, y: number, rect: Rect) {
  return x > rect.x && x < rect.x + rect.w && y > rect.y && y < rect.y + rect.h;
}

export function roundRectToGrid(rect: Rect, step: number) {
  if (step === 1) return;
  const { x, y, w, h } = rect;
  const newX = Math.floor(x / step) * step;
  const newY = Math.floor(y / step) * step;
  const newX2 = Math.ceil((x + w) / step) * step;
  const newY2 = Math.ceil((y + h) / step) * step;
  setRect(rect, newX, newY, newX2 - newX, newY2 - newY);
}
function clipToLimit(offset: number, size: number, viewOffset: number, viewSize: number, limit: number) {
  const margin = Math.round((limit - viewSize) / 2);

  if (offset + size < viewOffset + viewSize) { // visible right corner, keep it
    offset = offset + size - limit;
  } else if (offset > viewOffset && size - offset > viewSize - viewOffset) { // visible left corner, keep it
    // keep offset
  } else if (offset <= viewOffset - margin && offset + size > viewSize + viewOffset + margin) { // overflow on both sides, just clip to centered rect
    offset = viewOffset - margin;
  } else { // overflow only on one side, move and clip
    if (offset > viewOffset - margin) {
      // keep rx
    } else {
      offset = offset + size - limit;
    }
  }

  return offset;
}

export function clipSurfaceToLimits(r: Rect, drawingRect: Rect, maxWidth: number, maxHeight: number) {
  if (r.w < maxWidth && r.h < maxHeight) return false;

  if (r.w > maxWidth) {
    r.x = clipToLimit(r.x, r.w, drawingRect.x, drawingRect.w, maxWidth);
    r.w = maxWidth;
  }

  if (r.h > maxHeight) {
    r.y = clipToLimit(r.y, r.h, drawingRect.y, drawingRect.h, maxHeight);
    r.h = maxHeight;
  }
  return true;
}