import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faCopy, faEye, faLock, faSync, faTimes, blazeIcon } from '../../../common/icons';
import { randomDigits } from '../../../common/baseUtils';
import { copyText, maskPassword } from '../../../common/utils';
import { ManageService } from '../../../services/manageService';

@Component({
  selector: 'drawing-password',
  templateUrl: 'drawing-password.pug',
  styleUrls: ['drawing-password.scss'],
})
export class DrawingPassword {
  readonly blazeIcon = blazeIcon;
  readonly hideIcon = faEye;
  readonly copyIcon = faCopy;
  readonly initIcon = faLock;
  readonly changeIcon = faSync;
  readonly removeIcon = faTimes;

  copied = false;
  showPassword = false;

  @Input() canChange = false; // this indicates if user could change password if they had pro
  @Input() proBlock = false; // true if this action requires pro and user doesn't have pro
  @Input() bigButton = false;
  @Input() password: string | undefined = undefined;
  @Output() passwordChange = new EventEmitter<string | undefined>();
  @Output() maskPassword = new EventEmitter<boolean>();

  constructor(private manage: ManageService) { }

  get maskedPassword() {
    const password = this.password;
    return this.showPassword ? password : maskPassword(password || '');
  }

  togglePasswordMask() {
    this.showPassword = !this.showPassword;
    this.maskPassword.emit(!this.showPassword);
  }

  get canCopyPassword() {
    return !!this.password;
  }

  async copyPassword() {
    if (this.password) {
      await copyText(this.password);
      this.copied = true;
      setTimeout(() => this.copied = false, 3000);
    }
  }

  changePassword() {
    if (this.proBlock) {
      this.manage.upgrade('set-password');
    } else {
      this.password = randomDigits(8);
      this.passwordChange.emit(this.password);
    }
  }

  removePassword() {
    this.password = undefined;
    this.passwordChange.emit(this.password);
  }
}
