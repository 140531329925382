import { Analytics, CompositeOp, CursorType, DirectionLock, DrawingType, hasCtrlOrMetaKey, hasShiftKey, ITool, IToolData, IToolEditor, IToolModel, Layer, Rect, SelectionMode, TabletEvent, ToolId } from '../interfaces';
import { moveIcon } from '../icons';
import { isPerspectiveGridLayer, isTextLayer, redrawLayerThumb } from '../layer';
import { cloneRect, copyRect, intersectRect } from '../rect';
import { commitedLayerRect, copySurfaceTransform, createSurface, isSurfaceEmpty, setTransform, setupSurface, updateTransform } from '../toolSurface';
import { isMaskEmpty } from '../mask';
import { continueTransform, fixedScale, safeFloatAny } from '../toolUtils';
import { logAction } from '../actionLog';
import { redraw, redrawLayer } from '../../services/editorUtils';
import { toolIdToString } from '../toolIdUtils';
import { canDrawTextLayer, setTextLayerTransform, textLayerToTextBox } from '../text/text-utils';
import { copyMat2d, createMat2d, decomposeMat2d } from '../mat2d';
import { TransformedLayerEvent } from '../analytics';
import { tabletEventSourceToString } from '../utils';
import { setPerspectiveGridLayerTransform } from './perspectiveGridTool';
import { createViewport } from '../create';
import { getBoardDrawingAt } from '../board';
import type { Editor } from '../../services/editor';
import { ownAndSelectLayer } from '../../services/layerActions';

export interface IMoveToolData extends IToolData {
  tx: number;
  ty: number;
  sx: number;
  sy: number;
  r: number;
  op?: string; // for stats
  ratio?: string; // for stats
}

export class MoveTool implements ITool {
  id = ToolId.Move;
  name = 'Move';
  description = 'Move your layer or a selected part of it';
  learnMore = 'https://help.magma.com/en/articles/6790067-move-tool';
  video = { url: '/assets/videos/move.mp4', width: 374, height: 210 };
  icon = moveIcon;
  skipMoves = true;
  cursor = CursorType.Move;
  usesModifiers = true;
  view = createViewport();
  cancellableLocally = true;
  cancellingKeepsSurface = true;
  private startX = 0;
  private startY = 0;
  private currentX = 0;
  private currentY = 0;
  layer: Layer | undefined = undefined;
  private moved = false;
  replace = false;
  private pushedMove = false;
  private locked = DirectionLock.None;
  private lastTranslateX = 0;
  private lastTranslateY = 0;
  private surfaceBefore = createSurface();
  inputMethod: TransformedLayerEvent['source'] = 'mouse';
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }

  get contextMenu() {
    return this.model.drawing.type === DrawingType.Board;
  }

  do({ tx, ty, sx, sy, r, bounds }: IMoveToolData, _data: Uint8Array, debugInfo?: string) {
    const { drawing, user } = this.model;

    const pushed = setupCanvasesAndLayerForMoveOrTransform(this, this.model, bounds ?? drawing, debugInfo);

    if (!this.layer) throw new Error('[MoveTool.do] Missing layer');

    const { surface } = user;
    surface.translateX = safeFloatAny(tx);
    surface.translateY = safeFloatAny(ty);
    surface.scaleX = fixedScale(safeFloatAny(sx));
    surface.scaleY = fixedScale(safeFloatAny(sy));
    surface.rotate = safeFloatAny(r);

    if (pushed) {
      user.history.pushLayerId('move', this.layer.id);
    } else {
      user.history.clearRedos();
    }

    updateTransform(surface);
    if (isTextLayer(this.layer)) setTextLayerTransform(this.layer, surface);
    if (isPerspectiveGridLayer(this.layer)) setPerspectiveGridLayerTransform(this.layer, surface);

    redrawLayerThumb(this.layer);
    redrawLayer(drawing, this.layer);
  }

  hover(_x: number, _y: number, e: TabletEvent) {
    this.cursor = hasCtrlOrMetaKey(e) ? CursorType.Default : CursorType.Move;
  }

  start(x: number, y: number, _pressure: number) {
    copySurfaceTransform(this.surfaceBefore, this.model.user.surface);

    this.moved = false;
    this.locked = DirectionLock.None;
    this.currentX = x | 0;
    this.currentY = y | 0;
    this.startX = this.currentX;
    this.startY = this.currentY;
  }

  move(x: number, y: number, _pressure: number, e?: TabletEvent) {
    if (e && hasShiftKey(e)) {
      if (this.locked === DirectionLock.None) {
        const dx = Math.abs(this.startX - this.currentX);
        const dy = Math.abs(this.startY - this.currentY);

        if (dx > dy) {
          this.locked = DirectionLock.Horizontal;
        } else if (dy > dx) {
          this.locked = DirectionLock.Vertical;
        }
      }
    } else {
      this.locked = DirectionLock.None;
    }

    if (this.locked === DirectionLock.Horizontal) {
      this.currentX = x | 0;
      this.currentY = this.startY;
    } else if (this.locked === DirectionLock.Vertical) {
      this.currentX = this.startX;
      this.currentY = y | 0;
    } else {
      this.currentX = x | 0;
      this.currentY = y | 0;
    }

    const { user, drawing } = this.model;
    const { surface } = user;

    if (!this.moved) {
      if (this.currentX !== this.startX || this.currentY !== this.startY) {
        if (e) this.inputMethod = tabletEventSourceToString(e);
        this.moved = true;

        if (drawing.type !== DrawingType.Board) {
          this.pushedMove = setupCanvasesAndLayerForMoveOrTransform(this, this.model, drawing);
          this.lastTranslateX = surface.translateX;
          this.lastTranslateY = surface.translateY;

          if (!this.layer) throw new Error('[MoveTool.move] Missing layer');
        }
      } else {
        return;
      }
    }

    if (drawing.type === DrawingType.Board) {
      // TODO: ...
    } else {
      const dx = Math.round(this.currentX - this.startX) | 0;
      const dy = Math.round(this.currentY - this.startY) | 0;
      surface.translateX = this.lastTranslateX + dx;
      surface.translateY = this.lastTranslateY + dy;
      // removed clamping due to issues when pasting images larger than canvas
      // const bounds = isMaskEmpty(this.model.user.selection) ? surface.rect : this.model.user.selection.bounds;
      // surface.translateX = clampXLimits(this.lastTranslateX + dx, bounds, this.editor.drawing);
      // surface.translateY = clampYLimits(this.lastTranslateY + dy, bounds, this.editor.drawing);
      updateTransform(surface);
      if (isTextLayer(this.layer)) setTextLayerTransform(this.layer, surface);
      if (isPerspectiveGridLayer(this.layer)) setPerspectiveGridLayerTransform(this.layer, surface);

      if (!isMaskEmpty(user.selection)) { // tool bounds instead ?
        redraw(this.editor); // TODO: use tool bounds ?
      }

      redraw(this.editor);
      redrawLayer(drawing, this.layer);
    }
  }

  end(x: number, y: number, pressure: number, e?: TabletEvent, opt?: string) {
    this.move(x, y, pressure, e);

    const { user, drawing } = this.model;

    if (drawing.type === DrawingType.Board) {
      const bx = x - drawing.x;
      const by = y - drawing.y;

      const layer = getBoardDrawingAt(drawing, bx, by);
      if (!layer) return;

      // in timeout to run after drawingInProgress is set to false
      this.editor.apply(() => setTimeout(() => {
        // TODO: maybe inform user why we can't select the layer if it fails
        ownAndSelectLayer(this.editor as Editor, drawing, layer, user)
          .catch(e => DEVELOPMENT && console.warn(e));
      }));
    } else if (this.moved) {
      if (!this.layer) throw new Error('[MoveTool.end] Missing layer');

      if (this.pushedMove) {
        user.history.pushLayerId('move', this.layer.id);
      } else {
        user.history.clearRedos();
      }

      if (isTextLayer(this.layer)) {
        this.layer.invalidateCanvas = true;
      }

      this.model.track?.event<TransformedLayerEvent>(Analytics.TransformLayer, {
        layerType: isTextLayer(this.layer) ? 'text' : 'raster',
        currentTool: this.name.toLowerCase(),
        operation: 'move',
        source: this.inputMethod,
      });

      const surface = user.surface;
      const data: IMoveToolData = {
        id: this.id,
        tx: surface.translateX,
        ty: surface.translateY,
        sx: surface.scaleX,
        sy: surface.scaleY,
        r: surface.rotate,
        op: opt || undefined,
        replace: this.replace,
        ar: commitedLayerRect(surface, this.layer),
        selection: isMaskEmpty(user.selection) ? SelectionMode.Empty : SelectionMode.Update,
        bounds: cloneRect(surface.drawingRect)
      };

      this.model.doTool<IMoveToolData>(this.layer.id, data);

      redrawLayerThumb(this.layer);
    } else {
      logAction('[local] move omitted');
      user.history.unpre();
    }
  }

  cancel() {
    if (this.moved) {
      this.moved = false;
      copySurfaceTransform(this.model.user.surface, this.surfaceBefore);
    }
  }
}

interface IMoveTool extends ITool {
  layer: Layer | undefined;
  replace: boolean;
}

export function setupCanvasesAndLayerForMoveOrTransform(tool: IMoveTool, model: IToolModel, drawingBounds: Rect, debugInfo?: string) {
  const toolId = toolIdToString(tool.id);
  const { editor, user } = model;
  const surface = user.surface;
  const layer = user.activeLayer;

  if (!layer) throw new Error(`[${tool.name}Tool] Missing layer (${debugInfo})`);

  continueTransform(model);

  tool.layer = layer;

  if (!isSurfaceEmpty(surface) || surface.transforming) {
    // continue existing move
    tool.replace = true;

    if (!user.history.isLastEntryMove(layer.id)) {
      if (isTextLayer(layer) || isPerspectiveGridLayer(layer)) {
        layer.invalidateCanvas = true;
        user.history.prepushLayerState(layer.id);
      }
      user.history.prepushTool(`${toolId}L`, true);
      return true;
    } else {
      return false;
    }
  } else {
    // start new move
    tool.replace = false;

    if (DEVELOPMENT && (surface.translateX || surface.translateY)) console.warn('Non 0 translation');

    // TODO: cancel move when selection or layer is empty ? (only local)
    const rect = cloneRect(layer.rect);
    if (!isMaskEmpty(user.selection)) intersectRect(rect, user.selection.bounds);

    if (isTextLayer(layer) || isPerspectiveGridLayer(layer)) {
      user.history.prepushLayerState(layer.id);
    }
    user.history.prepushDirtyRect(`${toolId}DR`, layer.id, rect, true);
    user.history.prepushTool(toolId);

    setupSurface(surface, tool.id, CompositeOp.Move, layer, drawingBounds);

    if (isTextLayer(layer)) {
      if (canDrawTextLayer(layer)) {
        const { translateX, translateY, rotate, scaleX, scaleY } = decomposeMat2d(layer.textarea.transform);
        setTransform(surface, [translateX, translateY, scaleX, scaleY, rotate]);
        copyRect(surface.rect, textLayerToTextBox(layer, 'untransformedTextureRect'));
      } else {
        const mat = createMat2d();
        if (layer.textData.textareaFormatting.transform) {
          copyMat2d(mat, layer.textData.textareaFormatting.transform);
        }
        const { translateX, translateY, rotate, scaleX, scaleY } = decomposeMat2d(mat);
        setTransform(surface, [translateX, translateY, scaleX, scaleY, rotate]);
        copyRect(surface.rect, layer.rect);
      }
    } else if (isPerspectiveGridLayer(layer)) {
      const mat = createMat2d();
      if (layer.perspectiveGrid.transform) {
        copyMat2d(mat, layer.perspectiveGrid.transform);
      }
      const { translateX, translateY, rotate, scaleX, scaleY } = decomposeMat2d(mat);
      setTransform(surface, [translateX, translateY, scaleX, scaleY, rotate]);
      copyRect(surface.rect, layer.perspectiveGrid.bounds);
    } else {
      editor.renderer.splitLayer(surface, layer, user.selection);
    }
    surface.transforming = true;
    return true;
  }
}
