import { Injectable } from '@angular/core';
import { Drawing, experiments, Feature } from 'magma/common/interfaces';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { options } from 'magma/common/data';
import { ActivationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { TeamService } from './team.service';
import { UserService } from './user.service';
import { TeamsQuery } from './team.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserData } from 'shared/interfaces';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class PortalFeatureFlagService extends FeatureFlagService {
  private userFeatureFlags: string[] = [];
  private teamFeatureFlags: string[] = [];
  private user?: UserData;
  private drawingFeatureFlags: string[] = [];
  private features = new Set<string>();

  constructor(
    router: Router,
    private userService: UserService,
    teamsQuery: TeamsQuery,
    _teamsService: TeamService, // here to ensure teams
  ) {
    super();
    this.updateFeatureSet();

    userService.user$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(user => {
      this.userFeatureFlags = user?.featureFlags ?? [];
      this.user = user;
      this.updateFeatureSet();
    });

    teamsQuery.selectActive().pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(team => {
      this.teamFeatureFlags = team?.featureFlags ?? [];
      this.updateFeatureSet();
    });

    router.events.pipe(filter((event): event is ActivationEnd => event instanceof ActivationEnd)).subscribe(event => {
      if (event.snapshot.routeConfig?.path !== PRODUCT_INFO.defaultAppRoute) {
        this.updateDrawing(null);
      }
    });
  }

  updateUserFlags(featureFlags: Feature[]): void {
    this.userService.updateUser({ featureFlags });
  }

  updateDrawing(drawing: Drawing | null): void {
    this.drawingFeatureFlags = drawing?.featureFlags ?? [];
    this.updateFeatureSet();
  }

  private updateFeatureSet() {
    this.features = new Set([
      ...(options.features ?? []),
      ...this.drawingFeatureFlags,
      ...this.userFeatureFlags,
      ...this.teamFeatureFlags,
    ]);
    this.featuresChanged$.next();
  }

  isExperimentEnabled<T extends string & keyof typeof experiments>(experimentName: T, group: string): boolean {
    const experiment = experiments[experimentName];
    if (!experiment || !this.user) return false;

    //check first if one particular group was force-enabled in tags
    if (this.user.tags?.includes(`@force-${experimentName}-${group}`)) return true;

    let groupIndex = -1;

    if (this.user._id) {
      // _id of `UserData` might be empty for whatever reason
      // only the first 4 bytes are relevant for the experiment (the timestamp of ObjectId)
      const id = parseInt(this.user._id.slice(0, 8), 16);

      // Use the experiment order number to differentiate group assignment
      const experimentOrder = Object.keys(experiments).indexOf(experimentName);
      groupIndex = (id + experimentOrder) % experiment.length;
    }

    if (groupIndex === -1) return false;
    return group === experiment[groupIndex];
  }

  isFeatureSupported(feature: Feature) {
    return this.features.has(feature);
  }
}
