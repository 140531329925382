import { Component, AfterViewInit, OnDestroy, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Key } from '../../../common/input';
import { removeItem } from '../../../common/baseUtils';
import { IModalsBox, Modals, OpenModalOptions } from '../../../services/modals';

export interface ModalInstance {
  name: string;
  template: TemplateRef<any>;
  class: string;
  ignoreKeys: boolean;
  data: any;
  onKeydown: Subject<KeyboardEvent>;
  onClose: (result: any) => void;
  close: (result?: any) => void;
}

@Component({
  selector: 'modals-box',
  templateUrl: 'modals-box.pug',
})
export class ModalsBox implements AfterViewInit, OnDestroy, IModalsBox {
  onKeydown = new Subject<KeyboardEvent>(); // hack for settings modal
  stack: ModalInstance[] = [];
  get activeModal() {
    return this.stack.length ? this.stack[this.stack.length - 1] : undefined;
  }
  ngAfterViewInit() {
    Modals.box = this;
    Modals.templates.push(this);
  }
  ngOnDestroy() {
    if (Modals.box === this) Modals.box = undefined;
    removeItem(Modals.templates, this);
  }
  openModal<T = void>(template: TemplateRef<any>, options: OpenModalOptions = {}, name = '') {
    return new Promise<T>(resolve => {
      let instance: ModalInstance;
      instance = {
        name,
        template,
        class: (options.class ?? '') + (options.overflow ? ' overflow' : ''),
        ignoreKeys: !!options.ignoreKeys,
        data: options.data,
        onKeydown: this.onKeydown,
        onClose: resolve,
        close: (result?: any) => this.closeInstance(instance, result),
      };
      this.stack.push(instance);
      this.updateScrollbar();
    });
  }
  closeAll(): void {
    for (const modal of this.stack) {
      modal.onClose(undefined);
    }
    this.stack = [];
    this.updateScrollbar();
  }
  private closeInstance(instance: ModalInstance, result: any) {
    removeItem(this.stack, instance);
    this.updateScrollbar();
    instance.onClose(result);
  }
  keydown(event: KeyboardEvent) {
    event.stopPropagation();

    if (event.keyCode === Key.Esc && !this.activeModal?.ignoreKeys) {
      this.close();
    } else {
      this.onKeydown.next(event);
    }
  }
  close(result: any = undefined) {
    if (this.activeModal) {
      this.closeInstance(this.activeModal!, result);
    }
  }
  closeModal(template: TemplateRef<any>, result: any = undefined) {
    const modal = this.stack.find(m => m.template === template);
    if (modal) this.closeInstance(modal, result);
  }
  closeModalByName(name: string, result: any = undefined) {
    const modal = this.stack.find(m => m.name === name);
    if (modal) this.closeInstance(modal, result);
  }
  isAnyModalOpen() {
    return !!this.stack.length;
  }
  isOpen(template: any) {
    return this.stack.some(m => m.template === template);
  }
  private showingScrollbar = false;
  private updateScrollbar() {
    if (!document.body) return; // body is null sometimes for some reason

    const showScrollbar = !!this.stack.length;
    if (this.showingScrollbar === showScrollbar) return;

    if (showScrollbar) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${getScrollbarWidth()}px`;
      this.showingScrollbar = true;
    } else {
      document.body.style.overflow = 'initial';
      document.body.style.paddingRight = 'initial';
      this.showingScrollbar = false;
    }
  }
}

function getScrollbarWidth(): number {
  const div = document.createElement('div');
  div.className = 'modal-scrollbar-measure';
  document.body.appendChild(div);
  const scrollbarWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return scrollbarWidth;
}
