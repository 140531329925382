import { readPsd, Psd, Layer as PsdLayer } from 'ag-psd'; // TODO: remove this dependency from here
import { invalidEnumReturn } from './baseUtils';
import { DEFAULT_DPI, PDF_MAX_SIZE } from './constants';
import { Rect } from './interfaces';
import { rectToString } from './rect';

export const enum ImageType {
  Unknown,
  PNG,
  JPG,
  PSD,
  GIF,
  PDF,
  WEBP = 6,
  AVIF = 7,
}

export const ImageTypeNames = ['unknown', 'png', 'jpg', 'psd', 'gif', 'pdf', 'webp', 'avif'] as const;

export interface ImageInfo {
  type: ImageType;
  width: number;
  height: number;
  layers: number;
  dpi: number;
}

export function getImageInfo(d: Uint8Array): ImageInfo {
  const view = new DataView(d.buffer, d.byteOffset, d.byteLength);
  let type = ImageType.Unknown;
  let width = 0;
  let height = 0;
  let layers = 1;
  let dpi = DEFAULT_DPI;

  try {
    if (view.byteLength > 24 && view.getUint32(0) === 0x89504e47 && view.getUint32(4) === 0x0d0a1a0a) {
      type = ImageType.PNG;
      width = view.getUint32(16);
      height = view.getUint32(20);

      try {
        for (let offset = 8; offset < view.byteLength;) {
          const length = view.getUint32(offset);
          const type = view.getUint32(offset + 4);

          if (type === 0x70485973 && length >= 9) { // pHYs
            if (view.getUint8(offset + 16) === 1) { // units (1 - meters)
              const ppcm = view.getUint32(offset + 8);

              if ((ppcm & 0xffff0000) === 0) { // ignore invalid values
                dpi = ppcm / 39.3701; // convert ppcm to ppi
                dpi = Math.round(dpi * 100) / 100;
              }
            }

            break;
          }

          offset += length + 8 + 4; // +4 for crc
        }
      } catch (e) {
        DEVELOPMENT && console.warn(e);
      }
    } else if (view.byteLength > 10 && view.getUint32(0) === 0x47494638) { // GIF8
      type = ImageType.GIF;
      width = view.getUint16(6, true);
      height = view.getUint16(8, true);
    } else if (view.byteLength > 30 && view.getUint32(0) === 0x38425053) { // 8BPS
      try {
        const psd = readPsd(d, { skipCompositeImageData: true, skipLayerImageData: true, skipThumbnail: true, skipLinkedFilesData: true });

        type = ImageType.PSD;
        width = psd.width;
        height = psd.height;

        const countLayers = (layer: Psd | PsdLayer) => {
          if (!layer.children) return 1;

          let count = 0;
          for (const child of layer.children ?? []) {
            count += countLayers(child);
          }
          return count;
        };

        layers = countLayers(psd);

        const info = psd.imageResources?.resolutionInfo;

        if (info) {
          dpi = info.horizontalResolution;

          if (info.horizontalResolutionUnit === 'PPCM') {
            dpi = Math.round((dpi * 2.54) * 100) / 100;
          }
        }
      } catch (e) {
        throw new Error(`Failed to parse PSD file: ${e.message}`);
      }
    } else if (d.length > 4 && d[0] === 0xff && d[1] === 0xd8 && d[2] === 0xff /*&& d[d.length - 1] === 0xd9 && d[d.length - 2] === 0xff*/) {
      // FF D9 is not quaranteed to be at the end of tile
      // TODO: handle orientation info
      let offset = 2;

      while (offset < d.byteLength) {
        let discardedBytes = 0;
        let marker = 0;

        while (d[offset++] !== 0xff)
          discardedBytes++;

        do {
          marker = d[offset++];
        } while (marker === 0xff);

        if (discardedBytes !== 0)
          break;

        if (marker === 0xc0 || marker === 0xc2) {
          type = ImageType.JPG;
          height = view.getUint16(offset + 3);
          width = view.getUint16(offset + 5);
          break;
        } else if (marker === 0xda || marker === 0xd9) { // end of image
          break;
        } else {
          const length = view.getUint16(offset);
          offset += length;

          if (length < 2)
            break;
        }
      }

      const unit = view.getUint8(13);

      if (unit === 1) { // ppi
        dpi = view.getUint16(14);
      } else if (unit === 2) { // ppcm
        dpi = view.getUint16(14) / 39.3701; // convert ppcm to ppi
        dpi = Math.round(dpi * 100) / 100;
      }
    } else if (view.byteLength > 4 && view.getUint32(0) === 0x25504446) { // %PDF
      type = ImageType.PDF;
      width = PDF_MAX_SIZE; // actual size may be less
      height = PDF_MAX_SIZE;
    } else if (view.byteLength > 12 && view.getUint32(0) === 0x52494646 /* RIFF */ && view.getUint32(8) === 0x57454250 /* WEBP */) {
      type = ImageType.WEBP;
    } else if (view.byteLength > 12 && view.getUint32(0) === 0x00000020 && view.getUint32(8) === 0x61766966 /* avif */) {
      type = ImageType.AVIF;
    }
  } catch (e) { // catch RangeError when passed files that are too small
    DEVELOPMENT && console.error(e);
    throw e;
  }

  return { type, width, height, layers, dpi };
}

export function isImageTypeSupportedForImport(type: ImageType) {
  switch (type) {
    case ImageType.PNG:
    case ImageType.JPG:
    case ImageType.GIF:
    case ImageType.PSD:
    case ImageType.PDF:
      return true;
    case ImageType.WEBP: /** missing dimensions info */
    case ImageType.AVIF: /** missing dimensions info */
    case ImageType.Unknown:
      return false;
    default:
      return invalidEnumReturn(type, false);
  }
}

export function canHaveTransparency(type: ImageType) {
  switch (type) {
    case ImageType.PNG:
    case ImageType.GIF:
    case ImageType.PSD:
    case ImageType.PDF:
    case ImageType.WEBP:
    case ImageType.AVIF:
      return true;
    case ImageType.JPG:
    case ImageType.Unknown:
      return false;
    default:
      return invalidEnumReturn(type, false);
  }
}

export function extensionFor(type: ImageType) {
  switch (type) {
    case ImageType.Unknown: return '.bin';
    case ImageType.PNG: return '.png';
    case ImageType.JPG: return '.jpg';
    case ImageType.PSD: return '.psd';
    case ImageType.GIF: return '.gif';
    case ImageType.PDF: return '.pdf';
    case ImageType.WEBP: return '.webp';
    case ImageType.AVIF: return '.avif';
    default: return invalidEnumReturn(type, '.bin');
  }
}

export function isValidImageTypeForPaste(type: ImageType) {
  return type === ImageType.PNG || type === ImageType.JPG || type === ImageType.GIF || type === ImageType.PSD ||
    type === ImageType.WEBP || type === ImageType.AVIF;
}

export function cropImageData(imageData: ImageData, rect: Rect) {
  if (imageData.width < rect.x + rect.w || imageData.height < rect.y + rect.h) throw new Error(`Invalid crop rect ${rectToString(rect)}`);
  const { x, y, w, h } = rect;
  const data = imageData.data;
  let temp: Uint8ClampedArray;

  try {
    temp = new Uint8ClampedArray(w * h * 4);
  } catch (e) {
    throw new Error(`${e.message} (${w} * ${h} * 4)`); // include size in error message
  }

  for (let iy = 0; iy < h; iy++) {
    const src = (((y + iy) * imageData.width) * 4) | 0;
    const dst = (iy * w * 4) | 0;

    for (let ix = 0; ix < w; ix++) {
      const d = dst + ix * 4;
      const s = src + (x + ix) * 4;
      temp[d] = data[s];
      temp[d + 1] = data[s + 1];
      temp[d + 2] = data[s + 2];
      temp[d + 3] = data[s + 3];
    }
  }

  return temp;
}
