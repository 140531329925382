import { blobToArrayBuffer } from '../common/canvasUtils';
import { verifyFileForImport } from '../common/drawingUtils';
import { Model } from './model';
import { ImageInfo, ImageType, ImageTypeNames } from '../common/imageUtils';
import { Analytics, DrawingAction, EntityType, ImportEntityData, ImportResult, SuccessfulImportResult } from '../common/interfaces';
import { EditorInabilityStateId, enterEditorInabilityState, exitEditorInabilityState, ImportCreateDrawingEvent } from '../common/analytics';
import { getAllows } from '../common/userUtils';
import { ManageService } from './manageService';
import { UserError } from '../common/userError';

export async function importFile(manage: ManageService, model: Model, file: File | Blob, addToSequence: boolean) {
  if (model.editor) enterEditorInabilityState(model.editor, EditorInabilityStateId.Importing, 'importFile');

  let imageInfo: ImageInfo | undefined = undefined;
  try {
    const verificationResult = await verifyFileForImport(file, getAllows(model.user));
    imageInfo = verificationResult.imageInfo;
    const name = 'name' in file ? file.name.replace(/\.\w+$/, '') : 'image';

    if (IS_PORTAL) {
      if (imageInfo.type === ImageType.PDF && addToSequence) throw new UserError(`You can't import PDF files here.`);
      const drawing = model.editor?.drawing;
      const { team, project, folder } = drawing ?? {};
      const info: ImportEntityData = { name, team, project, folder, type: EntityType.Drawing, eventSource: 'editor' };
      const id = await manage.importDrawing(info, file);

      if (id && addToSequence) await model.server.drawingAction(model.connId, DrawingAction.AddToSequence, id);

      return id;
    } else {
      model.importing = true;
      model.importingProgress = 0;
      const { id } = await model.importDrawing(name, verificationResult.buffer, addToSequence ? 'sequence' : undefined);
      return id;
    }
  } catch (e) {
    DEVELOPMENT && console.error(e);
    model.showError(e.message, false);
    model.trackEvent<ImportCreateDrawingEvent>(Analytics.CreateDrawing, {
      drawingId: '',
      method: 'import:editor',
      fileSize: file.size,
      fileExtension: imageInfo ? ImageTypeNames[imageInfo.type] : ImageTypeNames[0],
      success: false,
      canvasHeight: imageInfo?.height ?? -1,
      canvasWidth: imageInfo?.height ?? -1,
    });
    if (e.message.startsWith('Failed to parse PSD file')) {
      blobToArrayBuffer(file)
        .then((buffer) => buffer && model.errorWithData(e.message, '', new Uint8Array(buffer)))
        .catch(e => DEVELOPMENT && console.error(e));
    }
  } finally {
    model.importing = false;
    if (model.editor) exitEditorInabilityState(model.editor, EditorInabilityStateId.Importing, false);
    model.importingProgress = 0;
  }

  return undefined;
}

export function isSuccessfulImport(result: ImportResult): result is SuccessfulImportResult {
  return !('error' in result);
}
