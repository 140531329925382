import { addRect, createRect, isRectEmpty, moveRect } from './rect';
import { PolySegment, Poly, Mat2d, Rect, Polyf, PolyfSegment } from './interfaces';
import { clamp } from './mathUtils';

export function createPolySegment(initialCapacity = 0): PolySegment {
  return { items: new Int32Array(initialCapacity * 2), size: 0 };
}

export function clonePolySegment({ items, size }: PolySegment): PolySegment {
  return { items: items.slice(0), size };
}

function resizePolySegment(segment: PolySegment) {
  const newItems = new Int32Array(segment.items.length ? segment.items.length * 2 : 32);
  newItems.set(segment.items);
  segment.items = newItems;
}

export function addPolySegmentPoint(segment: PolySegment, x: number, y: number) {
  while ((segment.size << 1) >= segment.items.length) {
    resizePolySegment(segment);
  }

  segment.items[(segment.size << 1) + 0] = x;
  segment.items[(segment.size << 1) + 1] = y;
  segment.size++;
}

export function setPolySegmentPoint(segment: PolySegment, index: number, x: number, y: number) {
  segment.items[(index << 1) + 0] = x;
  segment.items[(index << 1) + 1] = y;
}

export function isPolySegmentEmpty(segment: PolySegment) {
  return segment.size < 3;
}

export function isPolyfSegmentEmpty(segment: PolyfSegment) {
  return segment.length < 3;
}

export function getPolySegmentBounds({ items, size }: PolySegment) {
  if (size === 0) return createRect(0, 0, 0, 0);

  let xmin = items[0];
  let ymin = items[1];
  let xmax = xmin;
  let ymax = ymin;
  const size2 = size << 1;

  for (let i = 2; i < size2; i += 2) {
    const x = items[i];
    const y = items[i + 1];
    if (x < xmin) xmin = x;
    if (x > xmax) xmax = x;
    if (y < ymin) ymin = y;
    if (y > ymax) ymax = y;
  }

  return createRect(xmin, ymin, xmax - xmin, ymax - ymin);
}

export function getPolyfSegmentBounds(items: PolyfSegment) {
  if (items.length === 0) return createRect(0, 0, 0, 0);

  let xmin = items[0];
  let ymin = items[1];
  let xmax = xmin;
  let ymax = ymin;
  const size2 = items.length << 1;

  for (let i = 2; i < size2; i += 2) {
    const x = items[i];
    const y = items[i + 1];
    if (x < xmin) xmin = x;
    if (x > xmax) xmax = x;
    if (y < ymin) ymin = y;
    if (y > ymax) ymax = y;
  }

  return createRect(xmin, ymin, xmax - xmin, ymax - ymin);
}

function isPointInsidePolySegment({ items, size }: PolySegment, x: number, y: number) {
  let oddNodes = false;
  let jx = items[(size - 1) << 1];
  let jy = items[((size - 1) << 1) + 1];
  const size2 = size << 1;

  for (let i = 0; i < size2; i += 2) {
    const ix = items[i];
    const iy = items[i + 1];

    if ((iy < y && jy >= y || jy < y && iy >= y) && (ix + (y - iy) / (jy - iy) * (jx - ix) < x))
      oddNodes = !oddNodes;

    jx = ix;
    jy = iy;
  }

  return oddNodes;
}

function polySegmentToArray({ items, size }: PolySegment) {
  const result: number[] = [];
  const size2 = size << 1;

  for (let i = 0; i < size2; i++) {
    result.push(items[i]);
  }

  return result;
}

function createPolySegmentFromArray(points: number[]) {
  const seg = createPolySegment(points.length / 2);

  for (let i = 0; i < points.length; i++) {
    seg.items[i] = points[i];
  }

  seg.size = points.length / 2;
  return seg;
}

export function createPoly(ox: number, oy: number, segments: PolySegment[] = []): Poly {
  return { ox, oy, segments };
}

export function clonePoly(poly: Poly): Poly {
  return {
    ox: poly.ox,
    oy: poly.oy,
    segments: poly.segments.map(clonePolySegment)
  };
}

export function isPolyEmpty(poly: Poly) {
  return poly.segments.length === 0 || poly.segments.every(isPolySegmentEmpty);
}

export function isPolyfEmpty(poly: Polyf) {
  return poly.segments.length === 0 || poly.segments.every(isPolyfSegmentEmpty);
}

export function totalPolySize(poly: Poly) {
  let size = 0;

  for (const segment of poly.segments) {
    size += segment.size;
  }

  return size;
}

export function clearPoly(poly: Poly) {
  poly.ox = 0;
  poly.oy = 0;
  poly.segments.length = 0;
}

export function addPolyPoint(poly: Poly, x: number, y: number) {
  if (poly.segments.length === 0) {
    poly.segments.push(createPolySegment());
  }

  addPolySegmentPoint(poly.segments[0], x - poly.ox, y - poly.oy);
}

export function addPolyfPoint(polyf: Polyf, x: number, y: number) {
  if (polyf.segments.length === 0) {
    polyf.segments.push([]);
  }

  polyf.segments[0].push(x - polyf.ox);
  polyf.segments[0].push(y - polyf.oy);
}

export function isPointInsidePoly(poly: Poly, x: number, y: number) {
  let intersections = 0;

  for (const s of poly.segments) {
    if (isPointInsidePolySegment(s, x - poly.ox, y - poly.oy)) {
      intersections++;
    }
  }

  return (intersections % 2) === 1;
}

export function getPolyBounds(poly: Poly): Rect {
  const bounds = createRect(0, 0, 0, 0);

  for (const s of poly.segments) {
    addRect(bounds, getPolySegmentBounds(s));
  }

  if (!isRectEmpty(bounds)) moveRect(bounds, poly.ox, poly.oy);
  return bounds;
}

export function getPolyfBounds(polyf: Polyf): Rect {
  const bounds = createRect(0, 0, 0, 0);

  for (const s of polyf.segments) {
    addRect(bounds, getPolyfSegmentBounds(s));
  }

  if (!isRectEmpty(bounds)) moveRect(bounds, polyf.ox, polyf.oy);
  return bounds;
}

// this is not moving ox and oy on purpose, it's needed in executeClip
export function movePoly(poly: Poly, dx: number, dy: number) {
  if (dx === 0 && dy === 0) return;

  for (const { items, size } of poly.segments) {
    const size2 = size << 1;

    for (let i = 0; i < size2; i += 2) {
      items[i] = clamp(items[i] + dx, -0x7fff, 0x7fff);
      items[i + 1] = clamp(items[i + 1] + dy, -0x7fff, 0x7fff);
    }
  }
}

export function transformPoly(poly: Poly, transform: Mat2d) {
  const ox = poly.ox;
  const oy = poly.oy;
  for (const { items, size } of poly.segments) {
    const size2 = size << 1;

    for (let i = 0; i < size2; i += 2) {
      const x = items[i] + poly.ox;
      const y = items[i + 1] + poly.oy;
      items[i] = clamp(Math.round(transform[0] * x + transform[2] * y + transform[4]), -0x7fff, 0x7fff);
      items[i + 1] = clamp(Math.round(transform[1] * x + transform[3] * y + transform[5]), -0x7fff, 0x7fff);
      items[i] -= ox;
      items[i + 1] -= oy;
    }
  }
  poly.ox = ox;
  poly.oy = oy;
}

// this will reset ox and oy to 0
export function normalizePoly(poly: Poly) {
  for (const { items, size } of poly.segments) {
    const size2 = size << 1;
    for (let i = 0; i < size2; i += 2) {
      items[i] += poly.ox;
      items[i + 1] += poly.oy;
    }
  }
  poly.ox = 0;
  poly.oy = 0;
}

// this will produce array without offet
export function polyToArray(poly: Poly) {
  return poly.segments.map(polySegmentToArray);
}

export function createPolyFromArray(ox: number, oy: number, segments: number[][]) {
  return createPoly(ox, oy, segments.map(createPolySegmentFromArray));
}

export function createPolyRect(x: number, y: number, w: number, h: number) {
  if (w === 0 || h === 0) throw new Error('Cannot create empty rect poly');

  return createPolyFromArray(x, y, [[
    0, 0,
    w, 0,
    w, h,
    0, h,
  ]]);
}

// other poly utils

function isInside(px: number, py: number, ax: number, ay: number, bx: number, by: number) {
  return (bx - ax) * (py - ay) > (by - ay) * (px - ax);
}

function lineIntersection(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number, list: number[]) {
  const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  if (denominator === 0) return;

  const x = (x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4);
  const y = (x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4);
  list.push(x / denominator, y / denominator);
}

// points need to be in counter-clockwise order
export function clipPolygon(subject: number[], clipper: number[]) {
  // https://en.wikipedia.org/wiki/Sutherland%E2%80%93Hodgman_algorithm
  let result = subject;

  for (let i = 0, prevI = clipper.length - 2; i < clipper.length; prevI = i, i += 2) {
    const cx0 = clipper[prevI];
    const cy0 = clipper[prevI + 1];
    const cx1 = clipper[i];
    const cy1 = clipper[i + 1];
    const points = result;
    result = []; // TODO MULTIBOARD: perf

    for (let j = 0, prevJ = points.length - 2; j < points.length; prevJ = j, j += 2) {
      const px0 = points[prevJ];
      const py0 = points[prevJ + 1];
      const px1 = points[j];
      const py1 = points[j + 1];

      if (isInside(px1, py1, cx0, cy0, cx1, cy1)) {
        if (!isInside(px0, py0, cx0, cy0, cx1, cy1)) {
          lineIntersection(px0, py0, px1, py1, cx0, cy0, cx1, cy1, result);
        }
        result.push(px1, py1);
      } else {
        if (isInside(px0, py0, cx0, cy0, cx1, cy1)) {
          lineIntersection(px0, py0, px1, py1, cx0, cy0, cx1, cy1, result);
        }
      }
    }
  }

  return result;
}

// points need to be in counter-clockwise order
export function areaOfPolygon(poly: number[]) {
  // https://www.mathwords.com/a/area_convex_polygon.htm
  // area = 0.5 * ((X1*Y2 + X2*Y3 + ... + Xn*Y1) - (Y1*X2 + Y2*X3 + ... + Yn*X1))

  let a = 0, b = 0;

  for (let i = 0; i < poly.length; i += 2) {
    a += poly[i] * poly[(i + 3) % poly.length];
    b += poly[i + 1] * poly[(i + 2) % poly.length];
  }

  return 0.5 * (a - b);
}

export function setPolygonFrom4Points(poly: number[], ax: number, ay: number, bx: number, by: number, cx: number, cy: number, dx: number, dy: number) {
  poly[0] = ax;
  poly[1] = ay;
  poly[2] = bx;
  poly[3] = by;
  poly[4] = cx;
  poly[5] = cy;
  poly[6] = dx;
  poly[7] = dy;
}

export function reversePolygon(poly: number[]) {
  for (let i = 0, j = poly.length - 2; i < j; i += 2, j -= 2) {
    const x = poly[i];
    poly[i] = poly[j];
    poly[j] = x;
    const y = poly[i + 1];
    poly[i + 1] = poly[j + 1];
    poly[j + 1] = y;
  }
}
