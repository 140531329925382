export interface UserErrorInfo {
  type?: string;
  error?: Error;
  message?: string;
  data?: any;
}

export class UserError extends Error {
  translatable?: [string, any[]];
  constructor(public messageOrTranslatable: string | [string, any[]], public info?: UserErrorInfo) {
    super(Array.isArray(messageOrTranslatable) ? combineWithPlaceholders(messageOrTranslatable) : messageOrTranslatable);

    Object.defineProperty(this, 'name', { value: 'UserError' });

    if (typeof Error.captureStackTrace === 'function') { // missing in some browsers ?
      Error.captureStackTrace(this, UserError);
    }

    if (Array.isArray(messageOrTranslatable)) {
      this.translatable = messageOrTranslatable;
    }
  }
}

export function isUserError(e: Error): e is UserError {
  return e.name === 'UserError';
}

export function getUserErrorMessage(e: Error) {
  return isUserError(e) ? e.message : ((DEVELOPMENT || TESTS) ? `Error occurred (${e.message})` : 'Error occurred');
}

function combineWithPlaceholders([template, values]: [string, any[]]) {
  return template.replace(/\$([0-9]+)/g, (_match: string, index: string) => {
    const value = values[+index - 1];
    if (value == null) return '';
    return `${value}`;
  });
}
