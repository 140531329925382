import { Directive, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { getButton, getX, getY, AnyEvent, buttonToFlag, getPointerId } from '../../../common/utils';

export type AgDragEventType = 'start' | 'drag' | 'end' | 'cancel';

export interface AgDragEvent {
  event: AnyEvent;
  type: AgDragEventType;
  x: number;
  y: number;
  dx: number;
  dy: number;
}

type DownEventName = 'pointerdown' | 'mousedown' | 'touchstart';
type MoveEventName = 'pointermove' | 'mousemove' | 'touchmove';
type UpEventName = 'pointerup' | 'mouseup' | 'touchend';
type CancelEventName = 'pointercancel' | 'touchcancel';

const emptyRect = { left: 0, top: 0 };

export function agDragGetDownEvents(): DownEventName[] {
  if (typeof PointerEvent !== 'undefined') {
    return ['pointerdown'];
  } else {
    return ['mousedown', 'touchstart'];
  }
}

@Directive({ selector: '[agDrag]' })
export class AgDrag implements OnInit {
  @Input('agDragRelative') relative: 'self' | 'parent' | undefined = undefined;
  @Input('agDragPrevent') prevent = false;
  @Input() onlyLeftButton = false;
  @Output('agDrag') drag = new EventEmitter<AgDragEvent>();
  private rect = emptyRect;
  private scrollLeft = 0;
  private scrollTop = 0;
  private startX = 0;
  private startY = 0;
  private button = 0;
  private pointerId = 0;
  private dragging = false;
  private preventMousedown = false;
  constructor(private element: ElementRef<HTMLElement>) {
  }
  // TODO: extract this into a helper function, so we can use drag without using AgDrag component
  ngOnInit() {
    // pointer events have issues with pressing/releasing buttons while other one is held down
    if (typeof PointerEvent !== 'undefined') {
      this.setupEvents(this.element.nativeElement, 'pointerdown', 'pointermove', 'pointerup', 'pointercancel');
    } else {
      this.setupEvents(this.element.nativeElement, 'mousedown', 'mousemove', 'mouseup');
      this.setupEvents(this.element.nativeElement, 'touchstart', 'touchmove', 'touchend', 'touchcancel');
    }
  }
  private setupEvents(element: HTMLElement, downEvent: DownEventName, moveEvent: MoveEventName, upEvent: UpEventName, cancelEvent?: CancelEventName) {
    element.addEventListener(downEvent, e => {
      // if touch didn't move from start to end it generates mousedown in that spot,
      // that causes cursor jump in some cases, ignore mouse event after touch
      if (e.type === 'mousedown' && this.preventMousedown) {
        this.preventMousedown = false;
        return;
      }

      if (this.dragging) return;
      if (this.onlyLeftButton && 'button' in e && e.button > 0) return;

      // TODO: fix issue with scroll
      if (this.relative === 'self') {
        this.rect = element.getBoundingClientRect();
        this.scrollLeft = element.scrollLeft;
        this.scrollTop = element.scrollTop;
      } else if (this.relative === 'parent') {
        this.rect = element.parentElement!.getBoundingClientRect();
        this.scrollLeft = element.parentElement!.scrollLeft;
        this.scrollTop = element.parentElement!.scrollTop;
      } else {
        this.rect = emptyRect;
        this.scrollLeft = 0;
        this.scrollTop = 0;
      }

      this.dragging = true;
      this.button = getButton(e);
      this.pointerId = getPointerId(e);
      this.startX = getX(e);
      this.startY = getY(e);
      this.send(e, 'start');

      let lastEvent = e;
      let preventedTouchStart = false;

      var move = (e: any) => {
        if (this.pointerId !== getPointerId(e)) return;

        // starting button was released during move
        if (e.type === 'pointermove' && (buttonToFlag[this.button] & e.buttons) === 0) {
          this.send(lastEvent, 'end');
          endDrag();
          return;
        }

        lastEvent = e;
        e.preventDefault();
        this.send(e, 'drag');
      };

      var up = (e: any) => {
        if (this.pointerId === getPointerId(e) && this.button === getButton(e)) {
          if (e.type === 'touchend' || e.type === 'touchcancel') {
            this.preventMousedown = true;
          } else {
            // touchend and touchcancel events don't have x, y coordinates, keep old lastEvent
            lastEvent = e;
          }
          this.send(lastEvent, 'end');
          endDrag();

          if (preventedTouchStart && Math.abs(getX(lastEvent) - this.startX) < 5 && Math.abs(getY(lastEvent) - this.startY) < 5) {
            DEVELOPMENT && console.log('SIMULATED CLICK');
            try {
              (e.target as HTMLElement | null)?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
            } catch { }
          }
        }
      };

      var cancel = (e: any) => {
        if (this.pointerId !== getPointerId(e)) return;

        this.send(lastEvent, 'cancel');
        endDrag();
      };

      var endDrag = () => {
        window.removeEventListener(moveEvent, move);
        window.removeEventListener(upEvent, up);
        if (cancelEvent) window.removeEventListener(cancelEvent, cancel);
        window.removeEventListener('blur', cancel);
        element.removeEventListener('touchstart', touchStart);
        this.dragging = false;
      };

      var touchStart = (e: TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        preventedTouchStart = true;
      };

      window.addEventListener(moveEvent, move);
      window.addEventListener(upEvent, up);
      if (cancelEvent) window.addEventListener(cancelEvent, cancel);
      window.addEventListener('blur', cancel);

      // prevent simulated touch added after stylus down
      if (e.type === 'pointerdown' && (e as PointerEvent).pointerType === 'pen') {
        element.addEventListener('touchstart', touchStart);
      }

      e.stopPropagation();
      if (this.prevent) e.preventDefault();
    });
  }
  private send(e: AnyEvent, type: AgDragEventType) {
    const x = getX(e);
    const y = getY(e);

    this.drag.emit({
      event: e,
      type: type,
      x: x - this.rect.left + this.scrollLeft,
      y: y - this.rect.top + this.scrollTop,
      dx: x - this.startX,
      dy: y - this.startY
    });
  }
}
