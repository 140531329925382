import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

interface FeatureBadgePreset {
  text: string;
  textColor: string;
  backgroundColor: string;
}

const FEATURE_BADGE_PRESETS = {
  beta: {
    text: 'Beta',
    textColor: '#ffffff',
    backgroundColor: '#e84244',
  },
  demo: {
    text: 'Demo',
    textColor: '#ffffff',
    backgroundColor: '#e84244',
  },
  new: {
    text: 'New',
    textColor: '#ffffff',
    backgroundColor: '#2f7cc9',
  },
  comingSoon: {
    text: 'Coming soon',
    textColor: '#ffffff',
    backgroundColor: '#2f7cc9',
  },
  exclamation: {
    text: '!',
    textColor: '#222222',
    backgroundColor: '#F98A23'
  },
  public: {
    text: 'public',
    textColor: '#ffffff',
    backgroundColor: '#e84244',
  },
};

export type FeatureBadgePresets = keyof typeof FEATURE_BADGE_PRESETS;

@Component({
  selector: 'feature-badge',
  templateUrl: 'feature-badge.pug',
  styleUrls: ['feature-badge.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FeatureBadge {
  @Input() behavior: 'inline' | 'absolute' = 'absolute';
  @Input() size = 10;
  @Input() set preset(value: string) { this._preset = (FEATURE_BADGE_PRESETS as Record<string, FeatureBadgePreset>)[value]; }
  @Input() justDot = false;

  private _preset: FeatureBadgePreset | undefined = undefined;

  private _backgroundColor = '';
  @Input() set backgroundColor(value: string) { this._backgroundColor = value; }
  get backgroundColor(): string {
    return this._preset?.backgroundColor ?? this._backgroundColor;
  }

  private _textColor = '';
  @Input() set textColor(value: string) { this._textColor = value; }
  get textColor(): string {
    return this._preset?.textColor ?? this._textColor;
  }

  private _text = '';
  @Input() set text(value: string) { this._text = value; }
  get text(): string {
    return this._preset?.text ?? this._text;
  }

  @HostBinding('class.inline') inline = false;
  @HostBinding('class.absolute') absolute = false;
  ngOnInit(){
    this.inline = this.behavior === 'inline';
    this.absolute = this.behavior === 'absolute';
  }
}
