import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface StrokeTrackerData {
  showStrokesActivity?: boolean;
  showStrokesFullActivity?: boolean;
  showActivityBar?: boolean;
}

@Component({
  selector: 'stroke-tracker-modal',
  templateUrl: 'stroke-tracker-modal.pug',
})
export class StrokeTrackerModal {
  enable = false;
  showTotalStats = false;
  showActivityBar = false;

  @Output() close = new EventEmitter<StrokeTrackerData>();
  @Input() set data(value: StrokeTrackerData) {
    this.enable = !!value?.showStrokesActivity;
    this.showTotalStats = !!value?.showStrokesFullActivity;
    this.showActivityBar = !!value?.showActivityBar;
  }

  onSubmit() {
    this.close.emit({
      showStrokesActivity: this.enable,
      showStrokesFullActivity: this.showTotalStats,
      showActivityBar: this.showActivityBar,
    });
  }

  onClose() {
    this.close.emit(undefined);
  }
}
