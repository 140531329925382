import type { DiagramOptions } from './interfaces';
import { MONTH } from 'magma/common/constants';
import { MaxLength, MinLength } from '@deepkit/type';

export const TEAM_NAME_MAX_LENGTH = 32;
export const TEAM_SLUG_MAX_LENGTH = 16;
export const TEAM_DESCRIPTION_MAX_LENGTH = 150;
export const ROLE_NAME_MAX_LENGTH = 32;
export const TOKEN_NAME = 'AUTH_TOKEN';
export const TOKEN_LIFETIME = 3 * MONTH;
export const REFRESH_TOKEN_LIFETIME = 4 * MONTH;

export const ANALYTICS_TOKEN_NAME = 'ANALYTICS_ID';

export const TRIAL_PERIOD_DAYS = 7;

export interface Plan {
  name: string;
  price: number;
  annualPrice: number;
  priceTitle: string;
  annualPriceTitle?: string;
  numberOfUsers: 'Unlimited' | number;
  numberOfFlowcharts: 'Unlimited' | number;
  numberOfMaxNodes: 'Unlimited' | number | string;
  editableFlowcharts: boolean;
  privateFlowcharts: boolean;
  selfHosted: boolean;
  userPermissions?: boolean;
  teamSharing?: boolean;
}

export enum DocumentStatuses {
  REVIEW = 'review',
  REJECT = 'reject',
  APPROVE = 'approve',
  DRAFT = 'draft',
}

export const PRO_PLAN_IMPLICIT_TEAM_NAME = 'My Projects';

export enum UserPlans {
  FREE = 'free',
  PRO = 'pro',
  TEAM = 'team',
  ENTERPRISE = 'enterprise',
}

// TODO: move to flowchart module
export const DEFAULT_FLOWCHART_STYLE_OPTIONS: DiagramOptions = {
  fontSize: 12,
  pageSize: {
    width: 8.5,
    height: 11,
  },
  trueLabel: 'True',
  falseLabel: 'False',
  selectedTheme: 'clean',
  renderShadows: false,
  nodeDistance: 0.8,
  rankDistance: 0.29,
  compact: false,
  direction: 'bottom',
  optimizeCommon: true,
  decorateEdgeLabels: true,
};

export enum QUERY_FOR_TYPE {
  DOCUMENTS = 'documents',
  PROJECTS = 'projects',
  USERS = 'users',
}

// @ignore-translate
export const APP_PARAMS = 'appParams';

// Merge this with portal/shared/constants.ts
export const BILLING_PLANS: Plan[] = [
  {
    name: 'Free',
    price: 0,
    annualPrice: 10,
    priceTitle: '$0 / month',
    numberOfUsers: 1,
    numberOfFlowcharts: 3,
    numberOfMaxNodes: 30,
    editableFlowcharts: true,
    privateFlowcharts: false,
    selfHosted: false,
  },
  {
    name: 'Pro',
    price: 11,
    annualPrice: 10,
    priceTitle: '$11 / month',
    annualPriceTitle: '/ month',
    numberOfUsers: 1,
    numberOfFlowcharts: 'Unlimited',
    numberOfMaxNodes: 200,
    editableFlowcharts: true,
    privateFlowcharts: true,
    selfHosted: false,
  },
  {
    name: 'Team',
    price: 17,
    annualPrice: 15,
    priceTitle: '$17 / month',
    annualPriceTitle: '/ month per user',
    numberOfUsers: 5,
    numberOfFlowcharts: 'Unlimited',
    numberOfMaxNodes: 1000,
    editableFlowcharts: true,
    privateFlowcharts: true,
    selfHosted: false,
    userPermissions: true,
    teamSharing: true,
  },
  {
    name: 'Enterprise',
    price: 1,
    annualPrice: 1,
    priceTitle: 'Custom pricing',
    annualPriceTitle: 'Custom pricing',
    numberOfUsers: 'Unlimited',
    numberOfFlowcharts: 'Unlimited',
    numberOfMaxNodes: 'Unlimited',
    editableFlowcharts: true,
    privateFlowcharts: true,
    selfHosted: true,
    userPermissions: true,
    teamSharing: true,
  },
];

export const OCCUPATION_LIST = [
  'Software Development',
  'Product Management',
  'Design',
  'Marketing',
  'C-Level / Director / Executive',
  'Student',
  'Teacher',
  'Other',
];

export const COUNTRIES_LIST = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  `Côte d'Ivoire`,
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  `Korea (the Democratic People's Republic of)`,
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  `Lao People's Democratic Republic (the)`,
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan (Province of China)',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

export const DEFAULT_PROJECT_NAME = 'My project';
export const DEFAULT_FOLDER_NAME = 'Untitled folder';
export const DEFAULT_FLOWCHART_NAME = 'Untitled flowchart';
export const DEFAULT_DRAWING_NAME = 'Untitled drawing';

export const FREE_USER_FLOWCHART_LIMIT = 5;
export const ANONYNYMOUS_NODE_LIMIT = 25;
export const LOGGEDIN_NODE_LIMIT = 50;
export const PRO_NODE_LIMIT = 200;
export const TEAM_NODE_LIMIT = 1000;

export const ENTITY_STATE_SETTLE_TIME = 11000;

export enum ProjectShareTypes {
  VISIBLE_TO_MEMBERS = 0,
  RESTRICTED_TO_SPECIFIC_PEOPLE = 1,
}

export const TEAM_PROJECT_PRIVILIEGES = [
  { label: 'Visible to all team members', value: ProjectShareTypes.VISIBLE_TO_MEMBERS },
  { label: 'Restricted to specific people', value: ProjectShareTypes.RESTRICTED_TO_SPECIFIC_PEOPLE },
];
export const TEAM_PROJECT_PRIVILIEGES_NUMBERS = TEAM_PROJECT_PRIVILIEGES.map(({ value }) => value);

// @ignore-translate
export enum SortBy {
  Name = 'sortedByName',
  SizeOnDisk = 'sortedBySize',
  CreatedAt = 'sortedByCreatedAt',
  ModifiedAt = 'sortedByModifiedAt',
  OpenedAt = 'sortedByOpenedAt',
  RemovedAt = 'sortedByRemovedAt',
}

export const MAX_POST_TITLE_LENGTH = 200;
export const MAX_POST_DESCRIPTION_LENGTH = 12000;

export type PostTitle = string & MinLength<1> & MaxLength<typeof MAX_POST_TITLE_LENGTH>;
export type PostDescription = string & MaxLength<typeof MAX_POST_DESCRIPTION_LENGTH>;

// @ignore-translate
export const GENERALLY_CONSIDERED_SAFE_DOMAINS = [
  'magma.com',
  'magmastudio.io',
  'codecharm.io',
  'aggie.io',
  'google.com',
  'apple.com',
  'microsoft.com',
  'amazon.com',
  'mozilla.org',
  'ibm.com',
  'bbc.co.uk',
  'nytimes.com',
  'wikipedia.org',
  'github.com',
  'adobe.com',
  'stackoverflow.com',
  'dropbox.com',
  'salesforce.com',
  'paypal.com',
  'linkedin.com',
  'twitter.com',
  'facebook.com',
  'instagram.com',
  'pinterest.com',
  'snapchat.com',
  'reddit.com',
  'twitch.tv',
  'vimeo.com',
  'youtube.com',
  'netflix.com',
  'hulu.com',
  'spotify.com',
  'soundcloud.com',
  'zoom.us',
  'slack.com',
  'trello.com',
  'basecamp.com',
  'airbnb.com',
  'booking.com',
  'tripadvisor.com',
  'expedia.com',
  'yelp.com',
  'fedex.com',
  'ups.com',
  'usps.com',
  'dhl.com',
  'coursera.org',
  'edx.org',
  'khanacademy.org',
  'udemy.com',
  'coursera.org',
  'zoominfo.com',
  'glassdoor.com',
  'indeed.com',
  'monster.com',
  'careerbuilder.com',
  'ziprecruiter.com',
  'angel.co',
  'crunchbase.com',
  'bloomberg.com',
  'wsj.com',
  'economist.com',
  'reuters.com',
  'forbes.com',
  'cnbc.com',
  'wired.com',
  'techcrunch.com',
  'mashable.com',
  'cnet.com',
  'arstechnica.com',
  'pcworld.com',
  'wired.com',
  'nasa.gov',
  'cdc.gov',
  'who.int',
  'un.org',
  'redcross.org',
  'worldbank.org',
  'wto.org',
  'wwf.org',
  'unicef.org',
  'icrc.org',
  'amnesty.org',
  'greenpeace.org',
  'oxfam.org',
  'unesco.org',
  'imf.org',
  'iata.org',
  'faa.gov',
  'cia.gov',
  'fbi.gov',
  'nih.gov',
  'epa.gov',
  'census.gov',
  'whitehouse.gov',
  'usda.gov',
  'uscourts.gov',
  'uspto.gov',
  'uscis.gov',
  'usajobs.gov',
  'sec.gov',
  'irs.gov',
  'fda.gov',
  'cdc.gov',
  'behance.net',
  'dribbble.com',
  'deviantart.com',
  'artstation.com',
  'artsy.net',
  'saatchiart.com',
  'designspiration.com',
  'adweek.com',
  'wired.com',
  'creativebloq.com',
  'smashingmagazine.com',
  'aiga.org',
  'fastcompany.com',
  'thecreatorsproject.vice.com',
  'designboom.com',
  'artnews.com',
  'artistnetwork.com',
  'artforum.com',
  'tate.org.uk',
  'moma.org',
  'guggenheim.org',
  'metmuseum.org',
  'whitney.org',
  'louvre.fr',
  'nationalgallery.org.uk',
  'arts.gov',
  'nationalgeographic.com',
  'bbc.co.uk',
  'designmuseum.org',
  'cooperhewitt.org',
  'architecturelab.net',
  'archdaily.com',
  'dezeen.com',
  'mymodernmet.com',
  'art.sy',
  'theartnewspaper.com',
  'artfixdaily.com',
  'smithsonianmag.com',
  'hyperallergic.com',
  'artnet.com',
  'digitalartsonline.co.uk',
  'designobserver.com',
  'artcyclopedia.com',
  'artprice.com',
  'fineartamerica.com',
  'saam.gov',
  '500px.com',
  'istockphoto.com',
  'shutterstock.com',
  'pixabay.com',
  'unsplash.com',
  'pexels.com',
  'nasa.gov',
  'sciencephoto.com',
  'nationalgeographic.com',
  'pdnonline.com',
  'creativelive.com',
  'adobe.com',
  'petapixel.com',
  'fstoppers.com',
  'photographylife.com',
  'dpreview.com',
  'stackexchange.com',
  'github.com',
  'npmjs.com',
  'dev.to',
  'codepen.io',
  'stackoverflow.com',
  'css-tricks.com',
  'smashingmagazine.com',
  'javascript.com',
  'mozilla.org',
  'w3schools.com',
  'developer.mozilla.org',
  'freecodecamp.org',
  'codecademy.com',
  'udemy.com',
  'coursera.org',
  'edx.org',
  'hackernoon.com',
  'sitepoint.com',
  'dev.to',
  'css-tricks.com',
  'codrops.com',
  'scotch.io',
  'tutorialspoint.com',
  'devdocs.io',
  'cssgridgarden.com',
  'cssreference.io',
  'learnpython.org',
  'ruby-doc.org',
  'learnjavaonline.org',
  'php.net',
  'learnreactjs.io',
  'angular.io',
  'vuejs.org',
  'swift.org',
  'kotlinlang.org',
  'rust-lang.org',
  'golang.org',
];

export const enum DeleteMode {
  MoveToBin,
  FlagAsRemoved,
  DeletePermanently
}
