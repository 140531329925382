import { Subject, BehaviorSubject } from 'rxjs';
import type { VoiceControls } from '@codecharm/voice-chat-client-mediasoup';
import { User, CancellablePromise } from '../common/interfaces';
import type { Model } from './model';
import { isError } from '../common/typescript-utils';
import { isFirefox } from '../common/userAgentUtils';

export interface VoiceChatSounds {
  started: HTMLAudioElement | undefined;
  joined: HTMLAudioElement | undefined;
  muted: HTMLAudioElement | undefined;
  unmuted: HTMLAudioElement | undefined;
  leftMe: HTMLAudioElement | undefined;
  leftOther: HTMLAudioElement | undefined;
}

export interface VoiceChatSettings {
  mute: boolean;
  deafen: boolean;
  videoEnabled: boolean;
  hideInactiveSpeakers: boolean;
  videoTransparent: boolean;
  videoLayout: 'column' | 'focus' | 'cursors';
  activation: 'voice' | 'push';
  inputVolume: number;
  outputVolume: number;
  voiceActivationLevel: number;
  inputDeviceId: string | undefined;
  outputDeviceId: string | undefined;
  videoDeviceId: string | undefined;
  users: [string, number, number][]; // [name, volume, muted]
  disableEchoCancellation: boolean;
  disableNoiseSupression: boolean;
  rememberCameraState: boolean;
}

export const defaultVoiceChatSettings: VoiceChatSettings = {
  mute: false,
  deafen: false,
  videoEnabled: false,
  videoLayout: 'column',
  videoTransparent: false,
  hideInactiveSpeakers: false,
  activation: 'voice',
  inputVolume: 1,
  outputVolume: 1,
  voiceActivationLevel: -50,
  inputDeviceId: undefined,
  outputDeviceId: undefined,
  videoDeviceId: undefined,
  users: [],
  disableEchoCancellation: false,
  disableNoiseSupression: false,
  rememberCameraState: false,
};

export interface VoiceChatUser {
  uniqId: string;
  name: string;
}

export interface VoiceChatStream extends MediaStream {
  destructor?: () => void;
}

export class VoiceChatService {
  session = false;
  drawingId: string | undefined = undefined;
  muted = new Set<string>();
  joined = new Set<string>();
  lonelyCall = true;
  mutedByAdmin = new Set<string>();
  onError = new Subject<string>();
  onTalking = new Subject<User | undefined>();
  onVoiceActivated = new Subject<boolean>();
  settings = defaultVoiceChatSettings;
  isConnected = false;
  isConnecting = false;
  userElement: HTMLElement | undefined = undefined;
  navigatingAway = false;
  model: Model | undefined = undefined;
  onNewPresentation = new Subject<HTMLVideoElement>();
  onNewVideo = new Subject<HTMLVideoElement>();
  canChangeAudioDevice$ = new BehaviorSubject(false);
  unsupportedMic = false;
  unsupportedWebcam = isFirefox; // this is only start value
  selfAudioStream: VoiceChatStream | undefined = undefined;
  changingVideoDevice = false;
  consumeTokens = true; // if user joins and leaves before getting token he will still join once he gets token, this can lead to random (dis)connections, when this is false we know that user does not want to be in vc and will ignore following token actions
  videoForUser(_user: VoiceChatUser): HTMLVideoElement | undefined {
    return undefined;
  }
  screenshareForUser(_user: VoiceChatUser): HTMLVideoElement | undefined {
    return undefined;
  }
  hasVideo() {
    return false;
  }
  get videoUsers(): VoiceChatUser[] {
    return [];
  }
  get screensharingUsers(): VoiceChatUser[] {
    return [];
  }
  get controls() {
    return undefined as VoiceControls | undefined;
  }
  async start(_caller: string) {
  }
  async join() {
  }
  stop(_caller: string) {
  }
  stopAsync(_caller: string): Promise<void> | undefined {
    return Promise.resolve();
  }
  reset() {
  }
  setToken(_token: string | undefined, _caller: string) {
  }
  addUserElement(_uniqId: string, _element: HTMLElement) {
  }
  removeUserElement(_uniqId: string, _element: HTMLElement) {
  }
  updateOutputDevice() {
  }
  applyMuted() {
  }
  enableVideo(_controls: VoiceControls): CancellablePromise<VoiceChatStream | undefined> {
    return Promise.resolve(undefined) as CancellablePromise<undefined>;
  }
  disableVideo(_controls?: VoiceControls) {
  }
  setVideoDevice(_deviceId: string | undefined) {
  }
  setInputDevice(_deviceId: string | undefined): Promise<boolean> {
    return Promise.resolve(false);
  }
  applySettings() {
  }
  startScreensharing() {
  }
  stopScreensharing() {
  }
  isScreensharing() {
    return false;
  }
  async createVideoStream(_destructor?: () => void): Promise<MediaStream | undefined> {
    return undefined;
  }
  async getAudioStream(_destructor?: () => void): Promise<MediaStream | undefined> {
    return undefined;
  }
  videoElementFromStream(_stream: MediaStream): HTMLVideoElement {
    return { } as HTMLVideoElement;
  }
  getUserVolume(_user: VoiceChatUser) {
    return 0;
  }
  setUserVolume(_user: VoiceChatUser, _volume: number) {
  }
  isMuted() {
    return false;
  }
  isUserMuted(_user: VoiceChatUser) {
    return false;
  }
  setUserMuted(_user: VoiceChatUser, _muted: boolean) {
  }
  updateUserMutes() {
  }
  updateUserName(_oldName: string, _newName: string) {
  }
  playSound(_sound: keyof VoiceChatSounds) {
  }
  ensureCorrectInputStream(_requestedDeviceId: string | undefined, _stream: MediaStream) {
    return false;
  }
}

export function isKnownDeviceError(e: any) {
  if (!isError(e)) {
    return 0;
  } else if (e.name.match(/NotFoundError|NotReadableError/gi)) {
    return 404;
  } else if (e.name.match(/NotAllowedError|Permission/gi)) {
    return 401;
  } else {
    return 0; // unknown error, 500 in http convention but i want this to not pass ifs, rethrow likely
  }
}

export function closeStream(stream: VoiceChatStream) {
  stream.getTracks().slice().forEach((track) => {
    track.stop();
    stream.removeTrack(track);
  });
  stream.destructor?.();
}

export function onStreamClosed(stream: VoiceChatStream, destructor: (s: VoiceChatStream) => void) {
  stream.destructor = () => {
    // important! to avoid infinite destructor calls unassign first
    stream.destructor = undefined;
    destructor(stream);
  };

  stream.addEventListener('removetrack', () => {
    if (!stream.getTracks().length) stream.destructor?.();
  });

  stream.getTracks().forEach((track) => {
    track.addEventListener('ended', () => {
      stream.removeTrack(track);
      if (!stream.getTracks().length) stream.destructor?.();
    });
  });

  return stream;
}
