import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, } from 'rxjs';
import { CreatePostData, Post, PostType, UpdatePostData } from 'shared/posts';
import { ProjectData } from 'shared/interfaces';
import { ToastService } from 'magma/services/toast.service';
import { Mandatory } from 'magma/common/typescript-utils';
import { blobToBase64, isImageFile } from 'util/util';
import { UserService } from './user.service';
import { ProjectService } from './projects.service';
import { Permission } from 'magma/common/interfaces';
import { TeamsQuery } from './team.query';
import { TeamsStore } from './team.store';
import { GENERALLY_CONSIDERED_SAFE_DOMAINS } from 'shared/constants';
import { PostModalData, PostModalMode } from '../components/modals/post-modal/post-modal.component';
import { ModalService } from './modal.service';
import { TeamService } from './team.service';
import { AuthService } from './auth.service';
import { COMMUNITY_HUB_TEAM_ID, COMMUNITY_HUB_TEAM_NAME } from '../../../shared/posts';
import { ITrackService } from 'magma/services/track.service.interface';
import { ProjectQuery } from './projects.query';
import { POST_RESERVED_TAGS } from 'magma/common/constants';

interface ScrapedMetadata {
  title?: string;
  description?: string;
  thumbnail?: string;
}


export enum ReadingPostMethod {
  ContentPage = 'content-page',
  ModalDeployment = 'modal-deployment',
  Highlights = 'highlights'
}

export interface ReadingPostAnalytics {
  postId: string;
  postName: string;
  projectId: string;
  projectName: string;
  teamId: string;
  teamName: string;
  isCommunityHub: boolean;
  method: ReadingPostMethod;
  notificationTag: string;
}


@Injectable({ providedIn: 'root' })
export class BlogService {
  communityHubProjects: ProjectData[] = [];

  private projectId: string | undefined = undefined; // Rely on project._id instead. This is used only when user loaded into community-hub project but has no list yet, we want to re-setupBlog with it once loaded
  project: ProjectData | undefined = undefined;
  posts: Post[] = [];
  insideCommunityHubProject = false;

  loadingPosts = false;
  awaitingPostUpdates = new Set<Post['_id']>([]);
  disableCreating = true;
  missingPermissions = false;

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private projectsService: ProjectService,
    private teamService: TeamService,
    private toastsService: ToastService,
    private teamsQuery: TeamsQuery,
    private teamsStore: TeamsStore,
    private modals: ModalService,
    private authService: AuthService,
    private tracker: ITrackService,
    private projectsQuery: ProjectQuery,
  ) {
    if (!IS_HOSTED && this.authService.loggedIn) {
      this.getCommunityHubProjects().catch((e) => { DEVELOPMENT && console.log(e); });
    }
  }

  setupBlog(projectId: string | null) {
    this.restoreInitialState();
    if (projectId !== null) {
      this.setupProject(projectId);
      if (this.project) {
        this.loadPosts(this.project._id);
      }
    }
  }

  private restoreInitialState() {
    this.missingPermissions = false;
    this.loadingPosts = true;
    this.disableCreating = true;
    this.project = undefined;
    this.insideCommunityHubProject = false;
    this.posts = [];
    this.awaitingPostUpdates.clear();
  }

  private setupProject(projectId: string) {
    this.projectId = projectId;
    this.insideCommunityHubProject = false;
    if (this.teamsQuery.getActive()) {
      this.project = this.projectsService.getProject(projectId);
    } else {
      this.project = this.isCommunityHubProject(projectId);
      if (this.project) {
        this.teamsStore.setActive(null);
        this.insideCommunityHubProject = true;
        this.userService.updateUser({ lastCommunityHubProject: this.project._id });
      }
    }
  }

  get canManage() {
    if (this.insideCommunityHubProject) {
      return !!this.userService.user?.isSuperAdmin;
    } else if (this.project) {
      return this.teamService.hasPermissionFlag(Permission.CanManagePosts);
    } else {
      return false;
    }
  }

  private loadPosts(projectId: string) {
    this.getPosts(projectId)
      .then((posts) => {
        this.posts = posts;
      })
      .catch((e) => {
        if (e.status === 403) {
          this.missingPermissions = true;
        } else {
          this.toastWithErrorMessage(e, 'load', 'posts');
        }
      })
      .finally(() => {
        this.loadingPosts = false;
        this.disableCreating = false;
      });
  }

  isCommunityHubProject(id: string) {
    return this.communityHubProjects.find(({ _id }) => _id === id);
  }

  getPosts(projectId: string) {
    this.loadingPosts = true;
    const url = `api/blog/${projectId}/posts`;
    return lastValueFrom(this.httpClient.get<{ data: Post[] }>(url))
      .then(({ data }) => data);
  }

  getReadingPostAnalytics(post: Post, method: ReadingPostMethod): ReadingPostAnalytics {
    let teamName: string, projectName: string;
    const communityHubProject = this.isCommunityHubProject(post.project);
    if (communityHubProject) {
      teamName = COMMUNITY_HUB_TEAM_NAME;
      projectName = communityHubProject.name;
    } else {
      projectName = this.projectsQuery.getEntity(post.project)?.name ?? '?';
      teamName = this.teamService.getTeam(post.team)?.name ?? '?';
    }

    return {
      postId: post._id,
      postName: post.title,
      projectId: post.project,
      projectName,
      teamId: post.team,
      teamName,
      isCommunityHub: post.team === COMMUNITY_HUB_TEAM_ID,
      method: method,
      notificationTag: (post.tags ?? []).filter(t => t.startsWith('notification:')).join(','),
    };
  }

  readPost(post: Post) {
    if (post.type === PostType.Link) {
      if (post.tags?.includes(POST_RESERVED_TAGS.redirection)) {
        window.location.href = post.url;
      } else {
        window.open(post.url, '_blank');
      }
    }
  }

  openPost(post: Post, method: ReadingPostMethod) {
    if (post.type === PostType.Link && this.isTrusted(post.url)) {
      this.readPost(post);
    } else {
      const data: PostModalData = {
        mode: PostModalMode.Reading,
        analytics: this.getReadingPostAnalytics(post, method),
        post,
      };
      this.modals.openPostModal(data)
        .catch((e) => { DEVELOPMENT && console.error(e); });
    }
  }

  private isTrusted(url: string) {
    const hostname = new URL(url).hostname;
    const hostnameSplit = hostname.split('.');
    const justDomainAndTld = `${hostnameSplit[hostnameSplit.length - 2]}.${hostnameSplit[hostnameSplit.length - 1]}`;
    return GENERALLY_CONSIDERED_SAFE_DOMAINS.includes(justDomainAndTld);
  }

  getHighlightedPosts() {
    const url = 'api/blog/highlighted-posts';
    return lastValueFrom(this.httpClient.get<{ data: Post[] }>(url)).then(({ data }) => data);
  }

  getCommunityHubProjects() {
    const url = `api/projects/community-hub`;
    return lastValueFrom(this.httpClient.get<{ data: ProjectData[] }>(url))
      .then(({ data }) => {
        this.communityHubProjects = [];
        for (const project of data) { this.communityHubProjects.push(project); }
        if (this.projectId) this.setupBlog(this.projectId);
        this.projectId = undefined;
        return data;
      });
  }

  async createPost(projectId: string, body: Omit<CreatePostData, 'project' | 'creator'>) {
    const url = `api/blog/${projectId}/posts`;
    this.disableCreating = true;
    try {
      const response = await lastValueFrom(this.httpClient.post<{ data: Post }>(url, { ...body }));
      this.posts.unshift(response.data);
      this.disableCreating = false;
    } catch (e) {
      this.toastWithErrorMessage(e, 'create', 'post');
    } finally {
      this.disableCreating = false;
    }
  }

  async updatePost(projectId: string, body: Mandatory<UpdatePostData, '_id'>) {
    const url = `api/blog/${projectId}/posts/${body._id}`;
    this.awaitingPostUpdates.add(body._id);
    try {
      const response = await lastValueFrom(this.httpClient.patch<{ data: Post }>(url, body));
      if (!response.data) throw new Error('Can\'t find post');
      this.awaitingPostUpdates.delete(body._id);
      const postIndex = this.posts.findIndex((p) => p._id === body._id);
      if (postIndex !== -1) this.posts.splice(postIndex, 1, response.data);
    } catch (e) {
      this.toastWithErrorMessage(e, 'edit', 'post');
    } finally {
      this.awaitingPostUpdates.delete(body._id);
    }
  }

  async removePost(projectId: string, postId: string) {
    const url = `api/blog/${projectId}/posts/${postId}`;
    this.awaitingPostUpdates.add(postId);
    try {
      await lastValueFrom(this.httpClient.delete(url, {}));
      this.awaitingPostUpdates.delete(postId);
      const postIndex = this.posts.findIndex((p) => p._id === postId);
      if (postIndex !== -1) this.posts.splice(postIndex, 1);
    } catch (e) {
      this.toastWithErrorMessage(e, 'delete', 'post');
    } finally {
      this.awaitingPostUpdates.delete(postId);
    }
  }

  moveProjectToLeft(projectId: string, post: Post) {
    const posts = this.posts;
    const index = posts.indexOf(post);
    if (index !== -1 && index !== 0) {
      this.awaitingPostUpdates.add(posts[index]._id);
      this.awaitingPostUpdates.add(posts[index - 1]._id);
      [posts[index - 1], posts[index]] = [posts[index], posts[index - 1]];
      this.reorderPosts(projectId, posts)
        .catch((e) => {
          [posts[index], posts[index - 1]] = [posts[index - 1], posts[index]];
          this.toastWithErrorMessage(e, 'reorder', 'posts');
        })
        .finally(() => {
          this.awaitingPostUpdates.delete(posts[index]._id);
          this.awaitingPostUpdates.delete(posts[index - 1]._id);
        });
    }
  }

  moveProjectToRight(projectId: string, post: Post) {
    const posts = this.posts;
    const index = posts.indexOf(post);
    if (index !== -1 && index !== posts.length - 1) {
      this.awaitingPostUpdates.add(posts[index]._id);
      this.awaitingPostUpdates.add(posts[index + 1]._id);
      [posts[index], posts[index + 1]] = [posts[index + 1], posts[index]];
      this.reorderPosts(projectId, posts)
        .catch((e) => {
          [posts[index], posts[index + 1]] = [posts[index + 1], posts[index]];
          this.toastWithErrorMessage(e, 'reorder', 'posts');
        })
        .finally(() => {
          this.awaitingPostUpdates.delete(posts[index]._id);
          this.awaitingPostUpdates.delete(posts[index + 1]._id);
        });
    }
  }

  async reorderPosts(projectId: string, posts: Post[]) {
    const url = `api/blog/${projectId}/reorder-posts`;
    const body = { posts: posts.map((p) => p._id) };
    return lastValueFrom(this.httpClient.post(url, body));
  }

  async reorderHighlightedPosts(posts: Post[]) {
    const url = `api/blog/reorder-highlighted`;
    const body = { posts: posts.map((p) => p._id) };
    return lastValueFrom(this.httpClient.post(url, body));
  }

  togglePostHighlight(projectId: string, post: Post) {
    const url = `api/blog/${projectId}/posts/${post._id}/highlight`;
    this.awaitingPostUpdates.add(post._id);
    return lastValueFrom(this.httpClient.post(url, {}))
      .then(() => {
        post.highlightOrder = post.highlightOrder === -1 ? 0 : -1;
      })
      .finally(() => {
        this.awaitingPostUpdates.delete(post._id);
      });
  }

  async validateThumbnail(file: File | undefined) {
    if (!file) return false;

    if (!(file.type?.includes('image/'))) return false;
    if (!(await isImageFile(file))) return false;

    const base64 = await blobToBase64(file);
    if (typeof base64 !== 'string') return false;

    return true;
  }

  async uploadThumbnail(projectId: string, file: File) {
    const url = `/api/blog/${projectId}/thumbnails`;
    const formData = new FormData();
    formData.set('file', file, 'uploaded-blog-thumbnail');
    return lastValueFrom(this.httpClient.post<{ thumbId: string }>(url, formData))
      .then(({ thumbId }) => {
        if (!thumbId?.length) {
          this.toastsService.warning({
            message: 'Failed to upload your thumbnail. Post was created without it.',
            subtitle: 'You can edit post and upload image with smaller size as thumbnail.'
          });
          return '';
        } else {
          return `${window.location.origin}${url}/${thumbId}`;
        }
      })
      .catch((e) => {
        this.toastWithErrorMessage(e, 'upload', 'thumbnail');
        throw e;
      });
  }

  getMetadata(projectId: string, url: string) {
    const request$ = this.httpClient.get<ScrapedMetadata>(`/api/blog/${projectId}/get-metadata?url=${url}`);
    return lastValueFrom(request$);
  }

  private toastWithErrorMessage(e: any, action: string, entity: 'post' | 'posts' | 'thumbnail') {
    let message = `Failed to ${action} ${entity}`;
    switch (e.status) {
      case 400: message += ' - invalid data.'; break;
      case 403: message += ' - insufficient permissions.'; break;
    }
    DEVELOPMENT && console.error(e);
    this.toastsService.error({ message });
  }

  handleQueuedModal(teamId: string) {
    this.httpClient.get<{ post: Post | undefined }>(`/api/blog/queued-modal/${teamId}`).toPromise()
      .then((response) => {
        const r = response as { post: Post | undefined };
        if (r.post) {
          this.openPost(r.post, ReadingPostMethod.ModalDeployment);
          this.acknowledgeQueuedModal(r.post._id);
        }
      })
      .catch((e) => {
        DEVELOPMENT && console.error(e);
      });
  }

  acknowledgeQueuedModal(postId: string) {
    this.httpClient.delete(`/api/blog/queued-modal/${postId}`).toPromise()
      .catch((e) => {
        DEVELOPMENT && console.error(e);
      });
  }
}
