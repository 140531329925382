import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppNotification, AppNotificationSettingsOption, AppNotificationStatus, AppNotificationTeamCounter, NOTIFICATION_ARTDESK_ID, AppNotificationFlags } from 'magma/common/interfaces';
import { IAppNotificationService } from 'magma/services/app-notification.service.interface';
import { BehaviorSubject, defer, NEVER } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { TeamsQuery } from './team.query';
import { RpcService } from './rpc.service';
import { UserService } from './user.service';
import { generateNotificationContent } from 'shared/notifications';
import { ToastService } from 'magma/services/toast.service';
import { navigateToDrawing } from 'magma/common/clientUtils';
import { Router } from '@angular/router';
import { createTranslate, nonTranslatable } from 'magma/common/i18n';
import { hasFlag } from 'magma/common/baseUtils';

const NOTIFICATION_PAGE_SIZE = 20;
const tr = createTranslate();

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppNotificationService implements IAppNotificationService {
  notifications$ = new BehaviorSubject<AppNotification[]>([]);
  private teamNotifications = new Map<string, BehaviorSubject<AppNotification[]>>(new Map());
  notificationCounters$ = new BehaviorSubject<Map<string, number>>(new Map());

  private newNotifications$ = this.rpc.isConnected$.pipe(switchMap(async (status) => {
    if (status) {
      return defer(async () => this.observeNotifications()).pipe(switchMap(o => o));
    } else {
      return NEVER;
    }
  }));

  constructor(
    private rpc: RpcService,
    private teamQuery: TeamsQuery,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.userService.user$.pipe(map(u => u?._id), distinctUntilChanged(), switchMap(user => {
      if (user) {
        this.updateNotificationCounters().catch(e => DEVELOPMENT && console.error(e));
        return this.newNotifications$.pipe(
          switchMap(o => o),
          catchError(e => {
            DEVELOPMENT && console.error(e);
            return NEVER;
          }));
      } else {
        this.notificationCounters$.next(new Map());
        this.notifications$.next([]);
        this.teamNotifications.clear();
        return NEVER;
      }
    }), untilDestroyed(this)).subscribe(notification => {
      this.handleNewNotification(notification);
    });

    this.teamQuery.selectAll().pipe(map(teams => teams.length), distinctUntilChanged()).subscribe(async (len) => {
      if (len > 0) {
        await this.updateNotificationCounters();
      }
    });
  }

  private handleNewNotification(notification: AppNotification) {
    const list = this.notifications$.getValue();

    const index = list.findIndex(n => n.shortId === notification.shortId);
    if (index > -1) {
      list[index] = this.mapNotification(notification);
      this.notifications$.next(list);
    } else {
      list.push(this.mapNotification(notification));
      this.notifications$.next(list);
    }

    const subject = this.teamNotifications.get(notification.team?._id ?? NOTIFICATION_ARTDESK_ID)!;
    if (subject) {
      const teamList = subject.getValue();

      const teamIndex = teamList.findIndex(n => n.shortId === notification.shortId);
      if (teamIndex > -1) {
        teamList[teamIndex] = this.mapNotification(notification);
        subject.next(teamList);
      } else {
        teamList.push(this.mapNotification(notification));
        subject.next(teamList);
      }
    }

    if (notification.status === AppNotificationStatus.Unread && hasFlag(notification.flags, AppNotificationFlags.WithToast)) {
      const message = tr`${nonTranslatable(notification.trigger.user?.name) ?? 'Other user'} has invited you to join "${nonTranslatable(notification.trigger.entity!.name)}"`;
      const action = () => navigateToDrawing(this.router, notification.trigger.entity!.shortId, 'canvas invitation');
      const actionText = 'Join drawing';
      this.toastService.notification({ message, timeout: 0, action, actionText });
    }

    this.updateNotificationCounters().catch(e => DEVELOPMENT && console.error(e));
  }

  observeTeamNotifications(teamId: string) {
    let subject = this.teamNotifications.get(teamId);
    if (!subject) {
      subject = new BehaviorSubject<AppNotification[]>([]);
      this.teamNotifications.set(teamId, subject);

      this.getNotifications(teamId, 0, NOTIFICATION_PAGE_SIZE).then(notifications => {
        subject!.next(notifications);
      }).catch(e => DEVELOPMENT && console.error(e));
    }

    return subject.asObservable();
  }

  async updateNotificationCounters() {
    const res = await this.getNotificationTeamCounters();
    this.notificationCounters$.next(new Map(res.map(counter => [counter.teamId, counter.unread])));
  }

  async loadMore(skip: number, teamId: string | null): Promise<number> {
    const notifications = await this.getNotifications(teamId, skip);

    if (teamId && this.teamNotifications.has(teamId)) {
      const list = this.teamNotifications.get(teamId)!.getValue();
      list.push(...notifications.map(n => this.mapNotification(n)));
      this.teamNotifications.get(teamId)!.next(list);
    } else {
      const list = this.notifications$.getValue();
      list.push(...notifications.map(n => this.mapNotification(n)));
      this.notifications$.next(list);
    }

    return notifications.length;
  }

  hasUnread(teamId: string | null): Promise<boolean> {
    return this.rpc.notifications.hasUnread(teamId);
  }

  private generateNotificationContent(notification: AppNotification, forEmail: boolean): { title: string, bodyHTML: string } {
    return generateNotificationContent(notification, forEmail);
  }

  private mapNotification(n: AppNotification) {
    return { ...n, generatedContent: this.generateNotificationContent(n, false).bodyHTML };
  }

  async markAllAsRead(teamId: string | null): Promise<void> {
    await this.rpc.notifications.markAllAsRead(teamId);

    const notifications = await this.getNotifications(null);
    this.notifications$.next(notifications);

    if (teamId) {
      const teamNotifications = await this.getNotifications(teamId);
      this.teamNotifications.get(teamId)?.next(teamNotifications);
    }
    this.updateNotificationCounters().catch(e => DEVELOPMENT && console.error(e));
  }

  async markUnreadAsViewed(teamId: string, notificationIds: string[]): Promise<void> {
    await this.rpc.notifications.markUnreadAsViewed(notificationIds);
    const notifications = await this.getNotifications(null);
    this.notifications$.next(notifications);

    if (teamId) {
      const teamNotifications = await this.getNotifications(teamId);
      this.teamNotifications.get(teamId)?.next(teamNotifications);
    }
    this.updateNotificationCounters().catch(e => DEVELOPMENT && console.error(e));
  }

  observeNotifications(): Promise<Observable<AppNotification>> {
    return this.rpc.notifications.observeNotifications();
  }

  async getNotifications(teamId: string | null, skip = 0, limit = NOTIFICATION_PAGE_SIZE): Promise<AppNotification[]> {
    const notifications = await this.rpc.notifications.getNotifications(teamId ?? '', skip, limit);
    return notifications.map(n => this.mapNotification(n));
  }

  updateNotificationStatus(shortId: string, status: AppNotificationStatus): Promise<void> {
    return this.rpc.notifications.updateNotificationStatus(shortId, status);
  }

  updateNotificationSettings(entityId: string, setting: AppNotificationSettingsOption): Promise<void> {
    return this.rpc.notifications.updateNotificationSettings(entityId, setting);
  }

  getNotificationSettings(entityId: string): Promise<AppNotificationSettingsOption> {
    return this.rpc.notifications.getNotificationSettings(entityId);
  }

  getNotificationTeamCounters(): Promise<AppNotificationTeamCounter[]> {
    return this.rpc.notifications.getNotificationTeamCounters();
  }

  inviteUsers(usersToInvite: string[], drawingShortId: string, uiElement: string): Promise<void> {
    return this.rpc.notifications.inviteUsers(usersToInvite, drawingShortId, uiElement);
  }
}
