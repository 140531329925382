export const REV: { [key: string]: string; } = {
  'admin.css': 'admin-fc02c6dee6.css',
  'eraser.cur': 'eraser-1d7db001ac.cur',
  'eyedropper.cur': 'eyedropper-938fe20e5b.cur',
  'eyedropper2.cur': 'eyedropper2-899214928f.cur',
  'eyedropper3.cur': 'eyedropper3-da25fb963d.cur',
  'images/app-small.png': 'images/app-small-6e20f1cbcf.png',
  'images/app.png': 'images/app-f1993747e1.png',
  'images/bg-small.jpg': 'images/bg-small-09759f5683.jpg',
  'images/bg.jpg': 'images/bg-17b3b263c3.jpg',
  'images/pressure-huion.png': 'images/pressure-huion-9b921b87a8.png',
  'images/pressure-ugee.png': 'images/pressure-ugee-ea7396f3ff.png',
  'images/pressure-veikk.png': 'images/pressure-veikk-486b2c9225.png',
  'images/pressure-wacom.png': 'images/pressure-wacom-1a324cea6c.png',
  'images/pressure-xppen.png': 'images/pressure-xppen-b5177b85d9.png',
  'images/sign-up-hosted.jpg': 'images/sign-up-hosted-1b53496325.jpg',
  'images/sign-up.jpg': 'images/sign-up-0333fe668c.jpg',
  'images/sign-up.svg': 'images/sign-up-7f0f8af74d.svg',
  'library.css': 'library-56f6c41a30.css',
  'remove.cur': 'remove-5c1c081cb1.cur',
  'shapes/samples-brad.bin': 'shapes/samples-brad-67f9e31e93.bin',
  'shapes/samples-experimental.bin': 'shapes/samples-experimental-0cd50727ce.bin',
  'shapes/samples-fant.bin': 'shapes/samples-fant-2c11bd2cfa.bin',
  'shapes/samples-grut.bin': 'shapes/samples-grut-72298cb40b.bin',
  'shapes/samples-intq20.bin': 'shapes/samples-intq20-315f7d243a.bin',
  'shapes/samples-intq49.bin': 'shapes/samples-intq49-995caa1c58.bin',
  'shapes/samples-magma-bal.bin': 'shapes/samples-magma-bal-ce94c5f0be.bin',
  'shapes/samples-magma-foliage.bin': 'shapes/samples-magma-foliage-70e482ef14.bin',
  'shapes/samples-magma-other.bin': 'shapes/samples-magma-other-eef89df613.bin',
  'shapes/samples-magma-sac.bin': 'shapes/samples-magma-sac-3c7efb9202.bin',
  'shapes/samples-magma-tex.bin': 'shapes/samples-magma-tex-c8ced78d7f.bin',
  'shapes/samples-magma_bal.bin': 'shapes/samples-magma_bal-4b877247f4.bin',
  'shapes/samples-magma_foliage.bin': 'shapes/samples-magma_foliage-25c1edafe6.bin',
  'shapes/samples-magma_other.bin': 'shapes/samples-magma_other-7fd72979f3.bin',
  'shapes/samples-magma_sac.bin': 'shapes/samples-magma_sac-395a206299.bin',
  'shapes/samples-magma_tex.bin': 'shapes/samples-magma_tex-40afaf06ca.bin',
  'shapes/samples-omri.bin': 'shapes/samples-omri-d777b2554e.bin',
  'shapes/samples-ross.bin': 'shapes/samples-ross-dd64366b60.bin',
  'shapes/samples-ryan.bin': 'shapes/samples-ryan-5b1b72fb24.bin',
  'shapes/shapes-grids.json': 'shapes/shapes-grids-c63a41c5ce.json',
  'shapes/shapes-pds.json': 'shapes/shapes-pds-6f3aa1bcd2.json',
  'shapes/shapes-pdsnoguns.json': 'shapes/shapes-pdsnoguns-f465200e15.json',
  'shapes/shapes-silvertoons.json': 'shapes/shapes-silvertoons-82ba961f9a.json',
  'sounds/call_joined.mp3': 'sounds/call_joined-6cb1074893.mp3',
  'sounds/call_left_me.mp3': 'sounds/call_left_me-5cefe9c1c7.mp3',
  'sounds/call_left_other.mp3': 'sounds/call_left_other-e4b63ccd89.mp3',
  'sounds/call_muted.mp3': 'sounds/call_muted-bd212efd2d.mp3',
  'sounds/call_started.mp3': 'sounds/call_started-ef29ba186d.mp3',
  'sounds/call_unmuted.mp3': 'sounds/call_unmuted-8bf4585c17.mp3',
  'style-inline.css': 'style-inline-d5e56b1b15.css',
  'style.css': 'style-f1a67e831f.css',
  'wasm/main.wasm': 'wasm/main-042125b25b.wasm'
};
