import { User } from './interfaces';

export const JAMS_CHANGED = 'jams-changed';
export const JAMS_LIST_CHANGED = 'jams-list-changed';
export const JAM_DRAWING_CHANGED = 'jam-drawing-changed';

export const JAM_THUMB_WIDTH = 240;
export const JAM_THUMB_HEIGTH = 164;

export const JAMS_USER_GUIDE_URL = 'https://magm.ai/art-jams-user-guide';
export const JAMS_CODE_OF_CONDUCT_URL = 'https://magm.ai/art-jams-code-of-conduct';
export const JAMS_TERMS_OF_USE_URL = 'https://magm.ai/art-jam-terms';

export const JAMS_USER_REPORT_OPTIONS = [
  {
    title: 'Mature Content',
    details: [
      'This Art Jam contains mature content but was not labeled as "mature"',
      'A user posted mature content in an Art Jam intended for all audiences'
    ]
  },
  {
    title: 'Violent Content',
    details: [
      'Encouraging activities that risk serious physical harm or death',
      'Sharing or creating content that features explicit violence or gore',
      'Praising or promoting violent extremist or criminal organisations, or individual criminal perpetrators'
    ]
  },
  {
    title: 'Bullying & Harassment',
    details: [
      'Making threats to groups or individuals',
      'Releasing personally identifiable information',
      'Treating others with disrespect or abuse',
      'Making repeated, unwanted malicious contact'
    ]
  },
  {
    title: 'Hate Speech',
    details: [
      'Sharing slurs or insult',
      'Promoting harmful stereotype',
      'Attacking people based on any protected characteristics, including race, religion, and sexual orientation'
    ]
  },
  {
    title: 'Sexual Activity',
    details: [
      'Sharing or creating sexual or nude content involving minors',
      'Sharing or threatening to share non - consensual intimate images',
      'Sharing or creating images depicting sexual violence',
      'Offering or soliciting sexual services'
    ]
  },
  {
    title: 'Suicide & Self - harm',
    details: ['Promoting, encouraging, or providing instructions for suicide or self - harm']
  },
  {
    title: 'Spam',
    details: [
      'Directing people away from Magma through the misleading use of links',
      'Encouraging people to engage with content under false pretences',
      'Buying, selling or giving away accounts, roles or permissions'
    ]
  },
  {
    title: 'Other',
    details: []
  }
];

export enum JamVisibility {
  FORCE_HIDDEN = 'force-hidden', // only magma admin can set it
  HIDDEN = 'hidden',
  PUBLIC = 'public',
}

// for later use
// type JamFilterGenre = `genre:${string}`;
// type JamFilterTag = `tag:${string}`;
export type JamFilter = {
  filter: 'live' | 'all' | 'my' | 'joined'
  group?: string;
  nsfw: boolean;
  genre?: string[];
  archived?: boolean;
}

export interface JamUserData {
  _id: string;
  name: string;
  avatar?: string;
}

export interface JamUserDataModerators {
  _id: string;
  name: string;
  avatar?: string;
  tags: string[];
}

export interface JamDrawing {
  jam: JamData;

  // from drawing
  _id: string;
  name: string;
  shortId: string;
  cacheId?: string;
  tags: string[];
  author: JamUserData | null;

  createdAt?: Date;
  updatedAt?: Date;
}

export interface LiveJamUser {
  id: string;
  // uncomment later
  // color: string;
  // x: number;
  // y: number;
}

export interface LiveJam {
  shortId: string; // drawing short it
  connectedUsers: LiveJamUser[];
  // thumb?: {
  //   data: Uint8Array;
  //   thumbWidth: number;
  //   thumbHeight: number;
  // };
}

export interface JamData {
  description: string;
  rules: string;
  nsfw: boolean; // make it enum
  users: string[]; // userIds that joined jam
  visibility: JamVisibility;
  group?: string
  genre: string[];
  archived: boolean;
  featured?: boolean;
}

export interface UpdateJamData {
  description?: string;
  rules?: string;
  nsfw?: boolean; // make it enum
  visibility?: JamVisibility.PUBLIC | JamVisibility.HIDDEN;
  genre?: string[];
  archived?: boolean;
  featured?: boolean;
}

export interface CreateJamData {
  description: string;
  rules: string;
  nsfw: boolean; // make it enum
  group?: string;
  visibility: JamVisibility.PUBLIC | JamVisibility.HIDDEN;
  genre: string[];
}

export interface UpdateJamDrawing {
  jam?: UpdateJamData;

  // from drawing
  name?: string;
  tags?: string[];
}

export interface CreateJamDrawing {
  jam: CreateJamData;

  // from drawing
  name: string;
  tags: string[];
}

export interface JamsChangedMessage {
  shortId: string;
  force?: boolean; // force fetch from db when cache is outdated
}

export interface JamDrawingChangedMessage {
  shortId: string;
  connectedUsers: LiveJamUser[];
  // thumb?: {
  //   data: Uint8Array;
  //   thumbWidth: number;
  //   thumbHeight: number;
  // };
}

export interface JamsList {
  jams: JamDrawing[];
  count: number
}


export type DiscordWebhookField = {
  name: string;
  value: string;
  inline?: boolean;
};

export type DiscordWebhookEmbed = {
  author?: {
    name?: string;
    url?: string;
    icon_url?: string;
  };
  title?: string;
  url?: string;
  thumbnail?: {
    url?: string;
  };
  image?: {
    url?: string;
  };
  timestamp?: Date;
  color?: number;
  description?: string;
  fields: DiscordWebhookField[];
  footer?: {
    text: string;
    icon_url?: string;
  };
};

export type DiscordWebhookPayload = {
  username: string;
  embeds: DiscordWebhookEmbed[];
};

export const JAMS_GENERES = [
  'Anime',
  'Cartoons',
  'Fan Art',
  'Character Design',
  'Game Art',
  'Comics',
  'Manga',
  'Still Life',
  'Figure Drawing',
  'Educational',
  'Games',
  'Fun'
];



export interface JamsJoinModalInput {
  jamDrawing: JamDrawing;
  indexOnTheList: number; // -1 if opened from editor
  fromEditor?: boolean;
}

export interface UserReportModalInput {
  user?: User;
  drawing?: string;
}

export interface JamsModalInput {
  jamDrawing?: JamDrawing;
}

export type JamsModalOutput = { update?: UpdateJamDrawing, create?: CreateJamDrawing };
