import { readPsd, Layer as PsdLayer, Psd } from 'ag-psd';
import { DEFAULT_DPI, LAYER_NAME_LENGTH_LIMIT } from '../common/constants';
import { ClientDrawingData, DrawingType, LAYER_MODES, LayerFlag, LayerMode, LayerUpdateData } from '../common/interfaces';
import { createLayer } from '../common/layer';
import { copyRect } from '../common/rect';
import { includes } from '../common/baseUtils';
import { parsePsdLayerImageData, flattenPsdLayers, getSingleColor } from '../common/psdHelpers';

export interface ImportPsdResult {
  drawing: ClientDrawingData;
  missingFeatures: string[];
}

export function importPsd(buffer: Uint8Array, saveImage: (data: ImageData) => string): ImportPsdResult {
  const psd = readPsd(buffer, { skipThumbnail: true, useImageData: true, skipLinkedFilesData: true });
  const features = new Set<string>();
  const drawing: ClientDrawingData = {
    _id: '',
    id: '',
    name: '',
    type: DrawingType.Drawing,
    x: 0,
    y: 0,
    w: psd.width,
    h: psd.height,
    layers: [],
    background: undefined,
    dpi: DEFAULT_DPI,
  };

  const res = psd.imageResources;

  if (res?.gridAndGuidesInformation?.grid || res?.gridAndGuidesInformation?.guides?.length) {
    features.add('guides');
  }

  if (res?.resolutionInfo) {
    drawing.dpi = res?.resolutionInfo.horizontalResolution;

    if (res?.resolutionInfo.horizontalResolutionUnit === 'PPCM') {
      drawing.dpi = Math.round((drawing.dpi * 2.54) * 100) / 100;
    }
  }

  const { layers, background } = flattenPsdLayers(psd);

  if (background) drawing.background = background;
  if (psd.children?.some(child => child.children?.length)) features.add('layerGroup');

  for (const psdLayer of layers) {
    let newLayerId = psdLayer.id || 1;
    while (drawing.layers.some(l => l.id === newLayerId)) newLayerId++;
    const layer = createLayerFromPsdLayer(newLayerId, psdLayer);

    const parsed = parsePsdLayerImageData(psdLayer);
    if (parsed && parsed.rect.w && parsed.rect.h) {
      copyRect(layer.rect, parsed.rect);
      layer.image = saveImage(parsed.imageData);
    }

    addMissingFeatures(features, psdLayer);
    if (psdLayer.adjustment) continue; // skip adjustment layers

    layer.flags = LayerFlag.External;
    drawing.layers.push(layer);
  }

  return { drawing, missingFeatures: Array.from(features) };
}

export function createLayerFromPsdLayer(layerId: number, psdLayer: PsdLayer) {
  const layer = createLayer(layerId);
  Object.assign(layer, psdLayerData(psdLayer));
  return layer;
}

export function psdLayerData(psdLayer: PsdLayer): Omit<LayerUpdateData, 'id'> {
  return {
    name: (psdLayer.name || '').substring(0, LAYER_NAME_LENGTH_LIMIT), // TODO: maybe also clear bad unicode characters ?
    mode: (psdLayer.blendMode && includes(LAYER_MODES, psdLayer.blendMode as any) ? psdLayer.blendMode as LayerMode : undefined) ?? 'normal',
    visible: !psdLayer.hidden,
    opacity: psdLayer.opacity ?? 1,
    opacityLocked: !!(psdLayer.transparencyProtected && psdLayer.protected?.transparency),
    clippingGroup: !!psdLayer.clipping,
    locked: !!(psdLayer.transparencyProtected && !psdLayer.protected?.transparency),
  };
}

export function readAndFlattenPsdLayers(data: Uint8Array): PsdLayer[] {
  const psd = readPsd(data, { skipCompositeImageData: true, skipLinkedFilesData: true, skipThumbnail: true, useImageData: true });
  const psdLayers: PsdLayer[] = [];

  function processLayer(psdLayer: Psd | PsdLayer) {
    if (psdLayer.children) {
      psdLayer.children.forEach(processLayer);
    } else {
      psdLayers.push(psdLayer);
    }
  }

  processLayer(psd);

  const bgLayer = psdLayers[0];
  let bgColor: number | undefined = undefined;

  if (
    bgLayer && bgLayer.imageData && bgLayer.transparencyProtected &&
    bgLayer.imageData.width === psd.width && bgLayer.imageData.height === psd.height
  ) {
    bgColor = getSingleColor(bgLayer.imageData);
    if (bgColor !== undefined) psdLayers.shift();
  }

  // if (syncExistingLayers && bgColor !== undefined) {
  // TODO: check for permission to update drawing data
  // drawing.background = `#${colorToHexRGB(bgColor)}`;
  // editor.model.updateDrawing({ background: drawing.background });
  // }

  return psdLayers;
}

export function getMissingFeatures(psdData: Uint8Array) {
  const psd = readPsd(psdData, { skipCompositeImageData: true, skipLayerImageData: true, skipThumbnail: true, skipLinkedFilesData: true });
  const { layers } = flattenPsdLayers(psd);
  const features = new Set<string>();

  if (psd.children?.some(child => child.children?.length)) features.add('layerGroup');
  for (const psdLayer of layers) {
    addMissingFeatures(features, psdLayer);
  }

  return Array.from(features.values());
}

function addMissingFeatures(featuresSet: Set<string>, psdLayer: PsdLayer) {
  if (psdLayer.adjustment) featuresSet.add(`adjustment:${psdLayer.adjustment.type}`);
  if (psdLayer.artboard) featuresSet.add('artboard');
  if (psdLayer.effects && !psdLayer.effects.disabled) {
    if (psdLayer.effects.dropShadow?.some(e => e.enabled)) featuresSet.add('effects:dropShadow');
    if (psdLayer.effects.innerShadow?.some(e => e.enabled)) featuresSet.add('effects:innerShadow');
    if (psdLayer.effects.outerGlow?.enabled) featuresSet.add('effects:outerGlow');
    if (psdLayer.effects.innerGlow?.enabled) featuresSet.add('effects:innerGlow');
    if (psdLayer.effects.bevel?.enabled) featuresSet.add('effects:bevel');
    if (psdLayer.effects.solidFill?.some(e => e.enabled)) featuresSet.add('effects:solidFill');
    if (psdLayer.effects.satin?.enabled) featuresSet.add('effects:satin');
    if (psdLayer.effects.stroke?.some(e => e.enabled)) featuresSet.add('effects:stroke');
    if (psdLayer.effects.gradientOverlay?.some(e => e.enabled)) featuresSet.add('effects:gradientOverlay');
    if (psdLayer.effects.patternOverlay?.enabled) featuresSet.add('effects:patternOverlay');
  }
  if (psdLayer.filterMask) featuresSet.add('filterMask');
  if (psdLayer.layerColor && psdLayer.layerColor !== 'none') featuresSet.add('layerColor');
  if (psdLayer.mask) featuresSet.add('mask');
  if (psdLayer.patterns) featuresSet.add('patterns');
  if (psdLayer.placedLayer) featuresSet.add('placedLayer');
  if (psdLayer.text) featuresSet.add('text');
  if (psdLayer.vectorMask) featuresSet.add('vectorMask');
  if (psdLayer.vectorStroke || psdLayer.vectorFill) featuresSet.add('vector');
  if (psdLayer.blendMode == 'pass through') featuresSet.add('blendMode:passThrough');
  if (psdLayer.blendMode == 'dissolve') featuresSet.add('blendMode:dissolve');
  if (psdLayer.blendMode == 'linear burn') featuresSet.add('blendMode:linearBurn');
  if (psdLayer.blendMode == 'darker color') featuresSet.add('blendMode:darkerColor');
  if (psdLayer.blendMode == 'linear dodge') featuresSet.add('blendMode:linearDodge');
  if (psdLayer.blendMode == 'lighter color') featuresSet.add('blendMode:lighterColor');
  if (psdLayer.blendMode == 'vivid light') featuresSet.add('blendMode:vividLight');
  if (psdLayer.blendMode == 'linear light') featuresSet.add('blendMode:linearLight');
  if (psdLayer.blendMode == 'pin light') featuresSet.add('blendMode:pinLight');
  if (psdLayer.blendMode == 'hard mix') featuresSet.add('blendMode:hardMix');
  if (psdLayer.blendMode == 'subtract') featuresSet.add('blendMode:subtract');
  if (psdLayer.blendMode == 'divide') featuresSet.add('blendMode:divide');
}
