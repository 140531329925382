import { PublisherId, Drawing, ArtworkLicensingData } from './interfaces';
import { invalidEnum } from './baseUtils';

export function getRegisteringTaskName(providerId: PublisherId): string {
  switch (providerId) {
    case PublisherId.StoryProtocol: return 'Registering on Story Protocol';
    case PublisherId.Template: return 'Registering as template';
    default: invalidEnum(providerId);
  }
}

export function getRevisionLabel(providerId: PublisherId): string {
  switch (providerId) {
    case PublisherId.StoryProtocol: return 'Registered on Story Protocol';
    case PublisherId.Template: return 'Published for remixing';
    default: invalidEnum(providerId);
  }
}

export function getRevisionTitle(drawing: Drawing, revision?: ArtworkLicensingData['revision']) {
  const { name, publishedIn } = drawing;

  const storyPublication = revision?.publishedIn?.StoryProtocol ?? drawing.licensing?.revision?.publishedIn?.StoryProtocol;
  if (storyPublication) return storyPublication?.ipAssetName ?? name;

  if (publishedIn?.template) return publishedIn?.template?.templateName ?? name;

  return name;
}
