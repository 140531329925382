import { truncate } from 'lodash';
import { BitFlags, BitmapData, CompositeOp, CopyMode, Cursor, CursorsMode, CursorType, defaultDrawingPermissions, Drawing, DrawingDataFlags, DrawOptions, ExtraLoader, HistoryBufferEntry, ICanvasProvider, IErrorReporter, IFiltersValues, IRenderer, Layer, Mask, Mat2d, PerspectiveGridLayer, PerspectiveGridLayerData, Rect, RendererApi, RendererParams, RendererSettings, ShapePath, ToolId, ToolSurface, User, Vec2, Viewport } from '../common/interfaces';
import { findByLocalId, findIndexById, getPixelRatio, removeAtFast } from '../common/utils';
import { clamp, distance, round5, deg2rad } from '../common/mathUtils';
import { invalidEnum, quadraticEasing } from '../common/baseUtils';
import { applyTransform, clearRect, createCanvas, defaultImageLoaders, drawImage, fillRect, getBlendMode, getContext2d, getFallbackCursorsImage, getPixelContext, imageDataToBitmapData, loadToolFonts, textWidth } from '../common/canvasUtils';
import { isLayerVisible, isPerspectiveGridLayer, isTextLayer, layerChanged, loadLayerImages, redrawLayerThumb, shouldRedrawLayerThumb } from '../common/layer';
import { addRect, clipRect, cloneRect, copyRect, createRect, haveNonEmptyIntersection, isIntegerRect, isRectEmpty, makeIntegerRect, outsetRect, rectContainsRect, rectContainsXY, rectsIntersection, rectToString, resetRect, scaleRect, setRect, rectIncludesRect, intersectRect, clipSurfaceToLimits } from '../common/rect';
import { absoluteDocumentToDocuemnt, absoluteDocumentToDocumentRect, applyViewportTransform, createViewportMatrix2d, documentToScreenPoint, screenToDocumentRect } from '../common/viewport';
import { getSurfaceBounds, getTransformBounds, getTransformedSurfaceBounds, getTransformOrigin, hasZeroTransform, isSurfaceEmpty, rectToBounds, resetSurface, transformBounds } from '../common/toolSurface';
import { createRenderingContext, fillRectWithPattern, fillWithCanvasPattern } from './renderingContext';
import { CURSOR_AVATAR_LARGE_HEIGHT, CURSOR_VIDEO_HEIGHT, DEFAULT_FONT, LAYER_THUMB_SIZE, SEQUENCE_THUMB_HEIGHT, SEQUENCE_THUMB_WIDTH, SHOW_CURSOR_UNMOVING_TIMEOUT, USER_CURSOR_RADIUS, USER_NAME_HEIGHT, USER_NAME_OFFSET, USER_NAME_WIDTH, WHITE, WHITE_STR, FALLBACK_CURSORS, FALLBACK_CURSOR_SISE, FALLBACK_CURSORS_OFFSETS, MAX_LAYER_SIZE } from '../common/constants';
import { pointToSurface } from '../common/selectionUtils';
import { copyPoint, createPoint, setPoint } from '../common/point';
import { isPolySegmentEmpty } from '../common/poly';
import { colorFromRGBA, colorToCSS, rgbToGray } from '../common/color';
import { clipMask, cloneMask, createMask, createRectMask, cutMaskFromRect, fillMask, isMaskEmpty, isMaskingWholeRect, rectMaskIntersectionBoundsInt, transformAndClipMask, transformMask } from '../common/mask';
import { copyMat2d, createMat2d, isMat2dIdentity, multiplyMat2d, transformVec2ByMat2d, translateAbsoluteMatToDrawingMat2d } from '../common/mat2d';
import { cloneBounds, createVec2, createVec2FromValues, outsetBounds, setVec2 } from '../common/vec2';
import { SelectionTool } from '../common/tools/selectionTool';
import { PERSPECTIVE_GRID_BB_LINE_WIDTH_DEFAULT, PERSPECTIVE_GRID_BB_LINE_WIDTH_HOVER, PERSPECTIVE_GRID_COLOR_STR_DEFAULT, PerspectiveGridBoundingBoxState, PerspectiveGridTool, drawPerspectiveGrid } from '../common/tools/perspectiveGridTool';
import { getLayerRect, isLayerEmpty, layerHasNonEmptyToolSurface } from '../common/layerUtils';
import { LassoSelectionTool } from '../common/tools/lassoSelectionTool';
import { CircleSelectionTool } from '../common/tools/circleSelectionTool';
import { hasDrawingRole } from '../common/userRole';
import { pickRegion } from '../common/tools/transformTool';
import { DRAW_TEXTURE_RECT, drawTextareaTextureRect, preprocessTextLayersForDrawing, shouldRenderTextareaBaselineIndicator, shouldRenderTextareaBoundaries, shouldRenderTextareaControlPoints, shouldRenderTextareaCursor, shouldRenderTextareaOverflowIndicator, TextTool, TextToolMode } from '../common/tools/textTool';
import { AutoWidthTextarea, getBaselineIndicatorAlignmentSquareSize, MAX_TEXTAREA_CURSOR_WIDTH, MIN_TEXTAREA_CURSOR_WIDTH, Textarea, TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS, TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS, TEXTAREA_BOUNDARIES_COLOR, TEXTAREA_HOVERED_BOUNDARIES_WIDTH, TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE, TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS, TEXTAREA_OVERFLOW_INDICATOR_RED_STR, TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE, TEXTAREA_SELECTION_RECT_COLOR, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH, TextareaType } from '../common/text/textarea';
import { applyHueSaturationLightness } from '../common/hueSaturationLightness';
import { applyBrightnessContrast } from '../common/brightnessContrast';
import { AiTool } from '../common/tools/aiTool';
import { applyCurves } from '../common/curves';
import { toolIncompatibleWithPerspectiveGridLayer, toolIncompatibleWithTextLayers } from '../common/update';
import { cacheTextareaInLayer, ReadyTextLayer, shouldCacheTextareaInLayer, shouldDrawTextarea } from '../common/text/text-utils';
import { logActionInDebug } from '../common/actionLog';
import { AI_BOUNDING_BOX_COLOR_1_ACTIVE_STR, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, AI_BOUNDING_BOX_MASK_ALPHA, AI_SELECTION_COLOR_1, getAiSelectionPatternCanvas, updateAiMaskTransform } from '../common/aiInterfaces';
import { applyGaussianBlur } from '../common/gaussianBlur';
import { applyMotionBlur } from '../common/motionBlur';
import { drawDebugAllocatedCanvases, drawDebugLayerBounds } from './debug';
import { DEBUG_DRAW_ALLOCATED_TEXTURES, DEBUG_DRAW_LAYER_RECTS } from '../common/settings';
import { clipToDrawingRect } from '../common/drawing';
import { CROP_SELECTION_COLOR_2_STR, CropTool, CROP_SELECTION_COLOR_1_STR, CropOverlay, CROP_LABEL_WIDTH, CROP_LABEL_HEIGTH, CROP_LABEL_ERROR_COLOR_STR, CROP_LABEL_BLAZE_COLOR_STR, CROP_LABEL_ICON_WIDTH, CROP_SELECTION_COLOR_ERROR_STR, CropToolState, CROP_BACKDROP_COLOR_FLOAT } from '../common/tools/cropTool';
import { getMaxLayerWidth, getMaxLayerHeight } from '../common/drawingUtils';
import { getSpritesImage, SPRITE_SCALE, SpriteIcon, spriteIcons } from './sprite';
import { fillPath } from '../common/path';
import { createViewport } from '../common/create';
import { isAndroid } from '../common/userAgentUtils';

type Canvas = HTMLCanvasElement;
type Context = CanvasRenderingContext2D;

const tempPt = createPoint(0, 0);
const tempBoundsVec: Vec2[] = [createVec2FromValues(0, 0), createVec2FromValues(0, 0), createVec2FromValues(0, 0), createVec2FromValues(0, 0)];

let noSelfVideoTime = 0;

export function smoothScaling(settings: RendererSettings, view: Viewport) {
  return !settings.sharpZoom || view.scale < (view.rotation ? 6 : 3);
}

export function smoothScalingWebgl(settings: RendererSettings, view: Viewport) {
  return !settings.sharpZoom || view.scale < 3;
}

export class Renderer implements IRenderer {
  name: RendererApi;
  canvas: HTMLCanvasElement | undefined = undefined;
  private sprite: HTMLImageElement | undefined;
  private tempCanvas: HTMLCanvasElement | undefined;
  private tempCanvasContext: CanvasRenderingContext2D | undefined;
  private tempDOMMatrix: DOMMatrix | undefined;
  private pattern: CanvasPattern | undefined = undefined;
  private _thumbContext: Context | undefined = undefined;
  private context: CanvasRenderingContext2D | undefined = undefined;
  private emptySelection = createMask();
  constructor(public id: string, afterFail: boolean, private canvasProvider: ICanvasProvider, private errorReporter: IErrorReporter) {
    this.name = afterFail ? '2d-fail' : '2d-off';
  }
  private async loadSprites() {
    this.sprite = await getSpritesImage() as HTMLImageElement;
  }
  setLevelOfDetail() { }
  addRedrawRect(user: User, targetDirtyRect: Rect, options: DrawOptions, users: User[]) {
    let status = false;
    if (options.settings.includeVideo) {
      const avatarVideo = options.videoForUser(user);
      if (avatarVideo) {
        addRect(targetDirtyRect, rectForCursorVideo({ x: user.cursorX, y: user.cursorY }, avatarVideo));
        status = true;
      }
      for (const user of users) {
        const avatarVideo = options.videoForUser(user);
        if (avatarVideo) {
          addRect(targetDirtyRect, rectForCursorVideo({ x: user.cursorX, y: user.cursorY }, avatarVideo));
          status = true;
        }
      }
    }
    return status;
  }
  canvases() {
    return this.canvasProvider.used;
  }
  stats() {
    return this.canvasProvider.stats;
  }
  params(): RendererParams {
    // return fake data here to match good webgl renderer
    return {
      maxTextureSize: 16384,
      maxTextureUnits: 32
    };
  }
  init(canvas?: HTMLCanvasElement) {
    this.releaseTemp();

    if (canvas) {
      this.canvas = canvas;
      this.context = getContext2d(canvas, {
        alpha: false,
        desynchronized: !isAndroid, // was causing flickering black rectangles on Android
        preserveDrawingBuffer: true,
      });
    }

    this.loadSprites().catch(e => DEVELOPMENT && console.error(e));
    loadToolFonts().catch(e => DEVELOPMENT && console.error(e));
  }
  release() {
    this.releaseTemp();
    this.context = undefined;
  }
  releaseTemp() {
    if (this._thumbContext) {
      this.canvasProvider.release(this._thumbContext.canvas);
      this._thumbContext = undefined;
    }
  }
  private thumbContext(width: number, height: number) {
    if (!this._thumbContext) {
      this._thumbContext = getContext2d(this.canvasProvider.create(`${this.id}.tempCanvas`, width, height));
    }

    if (this._thumbContext.canvas.width !== width || this._thumbContext.canvas.height !== height) {
      this._thumbContext.canvas.width = width;
      this._thumbContext.canvas.height = height;
    }

    return this._thumbContext;
  }
  releaseLayer(layer: Layer | undefined) {
    if (layer) {
      layer.canvas = this.canvasProvider.release(layer.canvas);
      resetRect(layer.rect);
      layerChanged(layer);

      if (layer.ref) {
        layer.ref.image = undefined;
        layer.ref.promise?.cancel();
        layer.ref.promise = undefined;
      }
    }
  }
  releaseDrawing(drawing: Drawing, keepDrawingCanvas: boolean) {
    if (!keepDrawingCanvas) {
      drawing.canvas = this.canvasProvider.release(drawing.canvas);
    }

    drawing.layers.forEach(l => this.releaseLayer(l));
  }
  // used in paintbucket tool
  getDrawingImageData(drawing: Drawing, flags: BitFlags<DrawingDataFlags>) {
    if (flags === DrawingDataFlags.NoBackground) {
      const canvas = this.createSurface('temp', drawing.w, drawing.h);
      const temp: Drawing = { ...drawing, background: '', canvas };
      this.drawDrawing(temp, createViewport());
      const data = getContext2d(canvas).getImageData(0, 0, canvas.width, canvas.height);
      this.releaseSurface(canvas);
      return data;
    } else {
      this.drawDrawing(drawing, createViewport());
      return getContext2d(drawing.canvas!).getImageData(0, 0, drawing.w, drawing.h);
    }
  }
  // used in paintbucket tool and blur filter
  // it will provide padded data if rect is bigger then layer
  getLayerImageData(layer: Layer, rect: Rect) {
    if (isRectEmpty(rect)) throw new Error('Empty rect');
    if (layer.canvas) {
      const temp = this.canvasProvider.create('temp getLayerImageData', rect.w, rect.h);
      const c = getContext2d(temp);
      c.drawImage(layer.canvas, rect.x - layer.textureX, rect.y - layer.textureY, rect.w, rect.h, 0, 0, rect.w, rect.h);
      const data = c.getImageData(0, 0, rect.w, rect.h);
      this.canvasProvider.release(temp);
      return data;
    } else {
      return this.createImageData(rect.w, rect.h, undefined);
    }
  }
  // used for exporting to PSD
  getDrawingThumbnail(drawing: Drawing, maxSize: number): HTMLCanvasElement {
    if (!drawing.canvas) throw new Error('Drawing canvas is not initialized');

    let width = 0, height = 0;

    if (drawing.w > drawing.h) {
      width = maxSize;
      height = Math.max(Math.floor(drawing.h * (width / drawing.w)), 1);
    } else {
      height = maxSize;
      width = Math.max(Math.floor(drawing.w * (height / drawing.h)), 1);
    }

    const canvas = createCanvas(width, height);
    const context = getContext2d(canvas);
    context.drawImage(drawing.canvas, 0, 0, drawing.w, drawing.h, 0, 0, width, height);
    return canvas;
  }
  // used in worker service and psd sync
  getLayerRawData(layer: Layer): BitmapData {
    if (!layer.canvas || isRectEmpty(layer.rect)) throw new Error('Layer is empty');

    const { w, h } = layer.rect;
    const imageData = getContext2d(layer.canvas).getImageData(0, 0, w, h);
    return imageDataToBitmapData(imageData);
  }
  // used in paste / paintbucket
  createImageData(width: number, height: number, data: Uint8ClampedArray | undefined) {
    if (width === 0 || height === 0) throw new Error(`Invalid size for image data (${width}x${height})`);
    const imageData = getPixelContext().createImageData(width, height);
    if (data) imageData.data.set(data);
    return imageData;
  }
  // used in paste (not used at the moment)
  putImage(user: User, image: HTMLImageElement | HTMLCanvasElement | ImageBitmap, rect: Rect) {
    if (image.width !== rect.w || image.height !== rect.h) throw new Error(`Invalid size for image data (${image.width}x${image.height} rect:${rectToString(rect)})`);
    this.ensureSurface(user.surface, rect);
    const context = getContext2d(user.surface.canvas!);
    const w = image.width;
    const h = image.height;
    drawImage(context, image, 0, 0, w, h, 0, 0, w, h);
    if (user.surface.layer) redrawLayerThumb(user.surface.layer);
  }
  // used in paste / paintbucket
  putImageData(user: User, image: ImageData, rect: Rect) {
    if (image.width !== rect.w || image.height !== rect.h) throw new Error(`Invalid size for image data (${image.width}x${image.height} rect:${rectToString(rect)})`);
    this.ensureSurface(user.surface, rect);
    const context = getContext2d(user.surface.canvas!);
    context.putImageData(image, 0, 0);
    if (user.surface.layer) redrawLayerThumb(user.surface.layer);
  }
  loadLayerImages(drawing: Drawing, extraLoader?: ExtraLoader, onProgress?: (progress: number) => void, ignoreErrors = false) {
    return loadLayerImages(
      drawing, defaultImageLoaders(extraLoader),
      (layer, img) => this.initLayer(layer, img, drawing), onProgress, ignoreErrors);
  }
  private ensureSurface(surface: ToolSurface, r: Rect) {
    const rect = cloneRect(r);

    if (rect.w === 0 || rect.h === 0) throw new Error('Invalid ensure surface');

    // this is a workaround for not working clipping if path has same size as canvas
    const width = rect.w + 2;
    const height = rect.h + 2;

    if (!surface.canvas) {
      surface.canvas = this.canvasProvider.create('toolCanvas', width, height);
    } else if (surface.canvas.width !== width || surface.canvas.height !== height) {
      // this can happen when pasting image larger than canvas
      surface.canvas.width = width;
      surface.canvas.height = height;
    } else if (SERVER) {
      // this is less expensive in node-canvas
      const context = getContext2d(surface.canvas);
      context.clearRect(0, 0, surface.canvas.width, surface.canvas.height);
    } else {
      surface.canvas.width = surface.canvas.width;
    }
    copyRect(surface.rect, rect);
    surface.textureX = rect.x;
    surface.textureY = rect.y;
  }
  releaseUserCanvas({ surface }: User) {
    if (DEVELOPMENT && surface.context)
      throw new Error('Rendering context not released');

    if (surface.layer && !isSurfaceEmpty(surface)) {
      redrawLayerThumb(surface.layer, true);
    }

    surface.canvas = this.canvasProvider.release(surface.canvas);
    resetSurface(surface);
  }
  initLayer(layer: Layer, image: HTMLImageElement | ImageBitmap, drawingRect: Rect) {
    if (!image) return;
    fixLayerRect(layer, image, this.errorReporter);

    if (layer.rect.w > MAX_LAYER_SIZE || layer.rect.h > MAX_LAYER_SIZE) {
      const before = cloneRect(layer.rect);
      clipSurfaceToLimits(layer.rect, drawingRect, MAX_LAYER_SIZE, MAX_LAYER_SIZE);
      this.ensureLayerCanvas(undefined, layer, layer.rect);
      getContext2d(layer.canvas!).drawImage(image, before.x - layer.textureX, before.y - layer.textureY);
    } else {
      this.ensureLayerCanvas(undefined, layer, layer.rect);
      getContext2d(layer.canvas!).drawImage(image, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY);
    }
  }
  // used only for tests and paste psd as layers
  initLayerFromBitmap(layer: Layer, image: BitmapData, drawingRect: Rect) {
    if (layer.rect.w > MAX_LAYER_SIZE || layer.rect.h > MAX_LAYER_SIZE) {
      const before = cloneRect(layer.rect);
      clipSurfaceToLimits(layer.rect, drawingRect, MAX_LAYER_SIZE, MAX_LAYER_SIZE);

      this.ensureLayerCanvas(undefined, layer, layer.rect);
      const context = getContext2d(layer.canvas!);
      const imageData = context.createImageData(layer.rect.w, layer.rect.h);
      context.putImageData(imageData, before.x - layer.textureX, before.y - layer.textureY);
    } else {
      this.ensureLayerCanvas(undefined, layer, layer.rect);
      const context = getContext2d(layer.canvas!);
      const imageData = context.createImageData(image.width, image.height);
      imageData.data.set(new Uint8ClampedArray(image.data.buffer, image.data.byteOffset, image.data.byteLength));
      context.putImageData(imageData, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY);
    }
  }
  // used in copy and save, returns data for full layer size or selection bounds
  getLayerSnapshot(layer: Layer, selection?: Mask, outBounds?: Rect) {
    const layerRect = getLayerRect(layer);
    const bounds = selection ? selection.bounds : layerRect;

    if (isRectEmpty(bounds)) return undefined;
    if (!layerHasNonEmptyToolSurface(layer) && !layer.canvas) return undefined;

    let canvas: HTMLCanvasElement | undefined = undefined;

    const temp = getContext2d(this.canvasProvider.create(`temp`, bounds.w + 1, bounds.h + 1)); // + 1 is a workaround for not working mask in clipTo
    if (layerHasNonEmptyToolSurface(layer)) {
      canvas = createCanvas(bounds.w, bounds.h);
      const context = getContext2d(canvas);
      this.drawLayer(context, bounds, layer, bounds, bounds, false, true, true);
      temp.drawImage(canvas, 0, 0);
    } else {
      canvas = layer.canvas!;
      temp.drawImage(canvas, -(bounds.x - layer.textureX), -(bounds.y - layer.textureY));
    }

    if (selection) {
      outBounds && copyRect(outBounds, bounds);
      clipTo(temp, selection, -bounds.x, -bounds.y);
      const final = createCanvas(bounds.w, bounds.h);
      const finalContext = getContext2d(final);
      finalContext.drawImage(temp.canvas, 0, 0);
      canvas = final;
    } else {
      outBounds && copyRect(outBounds, layerRect);
    }

    this.canvasProvider.release(temp.canvas);

    return canvas;
  }
  getDrawingSnapshot(drawing: Drawing, selection?: Mask) {
    const bounds = selection ? selection.bounds : drawing;

    clipToDrawingRect(bounds, drawing);
    if (isRectEmpty(bounds)) return undefined;

    const canvas = createCanvas(bounds.w, bounds.h);
    const context = getContext2d(canvas);

    if (selection) {
      clipMask(context, selection, -bounds.x, -bounds.y);
    }
    if (drawing.background) {
      fillRect(context, drawing.background, 0, 0, canvas.width, canvas.height);
    }

    preprocessTextLayersForDrawing(drawing, (l) => this.drawTextLayer(l, drawing), true);
    this.drawLayers(context, bounds, drawing.background, drawing.layers, bounds, this.canvasProvider, drawing);

    return canvas;
  }
  getDrawingCanvasForImageData(drawing: Drawing) {
    return drawing.canvas;
  }
  // used in save (psd)
  getLayerCanvasForImageData(layer: Layer) {
    if (isLayerEmpty(layer)) return { rect: createRect(0, 0, 0, 0), canvas: undefined };

    const rect = cloneRect(getLayerRect(layer));
    const canvas = createCanvas(rect.w, rect.h);
    const context = getContext2d(canvas);
    context.save();
    if (layer.canvas) context.drawImage(layer.canvas, 0, 0);
    if (layerHasNonEmptyToolSurface(layer)) drawToolSurface(context, layer.owner!.surface);
    context.restore();
    return { rect, canvas };
  }
  // used in history
  createSurface(id: string, width: number, height: number) {
    return this.canvasProvider.create(id, width, height);
  }
  // used in history
  releaseSurface(canvas: HTMLCanvasElement | undefined) {
    return this.canvasProvider.release(canvas);
  }
  // used in history
  copyToSnapshot(src: HTMLCanvasElement, dst: HTMLCanvasElement, sx: number, sy: number, w: number, h: number, dx: number, dy: number) {
    const context = getContext2d(dst);
    context.globalAlpha = 1;
    context.globalCompositeOperation = 'source-over';
    //
    // use direct drawImage because history will try to save current state that can be empty (non existing on src canvas)
    // it may try to draw src canvas outside of dst area
    context.clearRect(dx, dy, w, h);
    context.drawImage(src, sx, sy, w, h, dx, dy, w, h);
  }
  // used in history
  restoreSnapshotToLayer(entry: HistoryBufferEntry | undefined, layer: Layer, layerRect: Rect) {
    if (isRectEmpty(layerRect)) {
      this.releaseLayer(layer);
      return;
    }

    if (entry) {
      const { sheet, x, y, rect } = entry;
      this.ensureLayerCanvas(undefined, layer, layerRect);
      this.copyToSnapshot(sheet.surface as HTMLCanvasElement, layer.canvas!, x, y, rect.w, rect.h, rect.x - layer.textureX, rect.y - layer.textureY);
    }

    layerChanged(layer);
  }
  // used in history
  restoreSnapshotToTool({ sheet, x, y, rect }: HistoryBufferEntry, user: User) {
    this.ensureSurface(user.surface, rect);
    this.copyToSnapshot(sheet.surface as HTMLCanvasElement, user.surface.canvas!, x, y, rect.w, rect.h, rect.x - user.surface.textureX, rect.y - user.surface.textureY);
  }
  commitTool(user: User, lockOpacity: boolean) {
    const layer = user.activeLayer;
    if (!layer) throw new Error('No active layer');

    this.commitToolOnLayer(user, layer, lockOpacity);

    this.releaseUserCanvas(user);
  }
  commitToolOnLayer(user: User, layer: Layer, lockOpacity: boolean): void {
    const { surface } = user;
    const selection = surface.ignoreSelection ? this.emptySelection : user.selection;

    if (DEVELOPMENT && surface.context) throw new Error('Tool context not released');

    if (surface.mode === CompositeOp.None) throw new Error('Invalid surface operation');
    if (DEVELOPMENT && layer.owner !== user) throw new Error('Commiting tool to layer with different owner');
    if (!surface.canvas) throw new Error('Missing surface canvas');

    const bounds = getSurfaceBounds(surface);

    if (surface.mode !== CompositeOp.Move && !isMaskEmpty(selection)) {
      copyRect(bounds, rectMaskIntersectionBoundsInt(bounds, selection));
    }

    const canSkip = isRectEmpty(bounds) ||
      (surface.mode === CompositeOp.Erase && isRectEmpty(layer.rect)) ||
      (surface.mode === CompositeOp.Erase && !haveNonEmptyIntersection(bounds, layer.rect));

    const rect = cloneRect(layer.rect);

    if (surface.mode !== CompositeOp.Erase && !(surface.mode === CompositeOp.Draw && lockOpacity)) {
      addRect(rect, bounds);
    }

    if (!canSkip) {
      this.ensureLayerCanvas(surface.drawingRect, layer, rect);

      const temp = getContext2d(this.canvasProvider.create(`temp`, bounds.w, bounds.h));
      if (this.composeOp(temp, bounds, layer, bounds, lockOpacity, selection, surface, surface.drawingRect)) {
        const context = getContext2d(layer.canvas!);
        context.clearRect(
          bounds.x - layer.textureX, bounds.y - layer.textureY,
          bounds.w, bounds.h
        );

        // temp.canvas is without padding here (starting from bounds.x, bounds.y)
        drawImage(context, temp.canvas,
          0, 0,
          bounds.w, bounds.h,
          bounds.x - layer.textureX, bounds.y - layer.textureY,
          bounds.w, bounds.h
        );
      }
      this.canvasProvider.release(temp.canvas);
    }

    if (isRectEmpty(layer.rect)) this.releaseLayer(layer);
    layerChanged(layer);
  }
  commitToolTransform(user: User) {
    const surface = user.surface;

    if (!surface.layer) throw new Error('Missing surface layer');
    if (DEVELOPMENT && surface.context) throw new Error('Tool context not released');

    if (!isSurfaceEmpty(surface)) {
      if (!surface.canvas) throw new Error('Missing surface canvas');
      if (DEVELOPMENT && surface.layer.owner !== user) throw new Error('Commiting tool to layer with different owner');

      const layer = surface.layer;
      const bounds = getSurfaceBounds(surface);

      const rect = cloneRect(layer.rect);
      addRect(rect, bounds);

      const limit = cloneRect(layer.rect);
      addRect(limit, bounds);

      if (limit.w > getMaxLayerWidth(surface.drawingRect.w) || limit.h > getMaxLayerHeight(surface.drawingRect.h)) {
        clipSurfaceToLimits(limit, surface.drawingRect, getMaxLayerWidth(surface.drawingRect.w), getMaxLayerHeight(surface.drawingRect.h));
        intersectRect(bounds, limit);
      }

      const canSkip = isRectEmpty(bounds) || // bounds might be empty if selection is transformed outside of layer limits
        (surface.mode === CompositeOp.Erase && isRectEmpty(layer.rect)) ||
        (surface.mode === CompositeOp.Erase && !haveNonEmptyIntersection(bounds, layer.rect));

      if (!canSkip && !hasZeroTransform(surface)) {
        const context = this.getLayerContext(surface.drawingRect, layer, rect); // this will ensure layer size and may limit rect to max image size

        const transformedBounds = getTransformedSurfaceBounds(surface.rect, surface.transform);
        intersectRect(transformedBounds, bounds);

        const x = surface.rect.x - transformedBounds.x;
        const y = surface.rect.y - transformedBounds.y;

        const temp = createCanvas(Math.max(1, transformedBounds.w), Math.max(1, transformedBounds.h));
        const tempContext = getContext2d(temp);

        tempContext.translate(x, y);
        tempContext.save();
        tempContext.imageSmoothingQuality = 'high';
        tempContext.translate(-surface.rect.x, -surface.rect.y); // transform to make sure that origin from transform will be in proper place
        tempContext.transform(surface.transform[0], surface.transform[1], surface.transform[2], surface.transform[3], surface.transform[4], surface.transform[5]);
        tempContext.translate(surface.rect.x, surface.rect.y);
        tempContext.drawImage(surface.canvas, 0, 0);
        tempContext.restore();

        context.drawImage(temp, transformedBounds.x - layer.textureX, transformedBounds.y - layer.textureY);

        layerChanged(surface.layer);
      }
    }

    transformAndClipMask(user.selection, surface);
    this.releaseUserCanvas(user);
  }
  // Assumes no active tool surface
  mergeLayers(drawingRect: Rect, src: Layer, dst: Layer, clip: boolean) {
    if (layerHasTool(src) || layerHasTool(dst)) throw new Error('Cannot merge layers with active tool');

    if (
      src.canvas && src.opacity !== 0 && !isRectEmpty(src.rect) &&
      !(clip && isRectEmpty(rectsIntersection(dst.rect, src.rect)))
    ) {
      let oldCanvas: HTMLCanvasElement | undefined;

      if (dst.canvas && dst.opacity !== 1) {
        oldCanvas = dst.canvas;
        dst.canvas = undefined;
      }

      const dstRect = cloneRect(dst.rect);

      const rect = cloneRect(dst.rect);
      if (!clip) addRect(rect, src.rect);

      const context = this.getLayerContext(drawingRect, dst, rect);

      if (oldCanvas) {
        context.globalAlpha = dst.opacity;
        context.drawImage(oldCanvas, dst.textureX - dstRect.x, dst.textureY - dstRect.y);
        context.globalAlpha = 1;
        this.canvasProvider.release(oldCanvas);
      }

      if (clip) {
        const clippedRect = rectsIntersection(dst.rect, src.rect);

        if (!isRectEmpty(clippedRect)) {
          const clipCanvas = this.canvasProvider.create('clipCanvas', clippedRect.w, clippedRect.h); // ??
          const clipContext = getContext2d(clipCanvas);

          clipContext.globalCompositeOperation = 'source-over';
          this.drawLayer(clipContext, clippedRect, src, clippedRect, drawingRect, true);
          clipContext.globalCompositeOperation = 'destination-in';
          this.drawLayer(clipContext, clippedRect, dst, clippedRect, drawingRect, true);

          context.globalCompositeOperation = getBlendMode(src.mode);
          drawImage(context, clipCanvas, 0, 0, clippedRect.w, clippedRect.h, clippedRect.x - dst.textureX, clippedRect.y - dst.textureY, clippedRect.w, clippedRect.h);
          context.globalCompositeOperation = 'source-over';

          this.canvasProvider.release(clipCanvas);
        }
      } else {
        drawCanvas(context, dst.rect, src.canvas, src.rect, src.textureX, src.textureY, src, false, false);
      }

      dst.changed = true;
      dst.opacity = 1;
      redrawLayerThumb(dst, true);
    }

    this.releaseLayer(src);
    redrawLayerThumb(src, true);
  }
  splitLayer(surface: ToolSurface, layer: Layer, selection: Mask) {
    if (!layer.canvas || isRectEmpty(layer.rect)) return;

    const rect = cloneRect(layer.rect);
    if (!isMaskEmpty(selection)) intersectRect(rect, selection.bounds);

    if (isMaskEmpty(selection) || isMaskingWholeRect(layer.rect, selection)) {
      this.ensureSurface(surface, layer.rect);
      // TODO: just switch canvas ?
      drawLayerOnSurface(layer, surface);
      this.releaseLayer(layer);
    } else if (!isRectEmpty(rect)) {
      this.ensureSurface(surface, rect);
      drawLayerOnSurface(layer, surface, selection);
      this.ensureLayerCanvas(surface.drawingRect, layer, layer.rect);
      cutSelectionFromLayer(layer, selection);

      layerChanged(layer);
    }
  }
  // Assumes no active tool surface
  cutLayer(src: Layer, selection: Mask) {
    if (!src.canvas || isRectEmpty(src.rect)) return;
    if (isMaskEmpty(selection)) return;

    clipOut(getContext2d(src.canvas), selection, -src.rect.x, -src.rect.y);
    cutMaskFromRect(src.rect, selection);
    if (isRectEmpty(src.rect)) this.releaseLayer(src);
    layerChanged(src);
  }
  copyLayerToSurface(layer: Layer, surface: ToolSurface, rect: Rect) {
    if (!layer.canvas) throw new Error('Layer Missing Canvas');
    const tx = layer.rect.x - rect.x;
    const ty = layer.rect.y - rect.y;
    this.ensureSurface(surface, rect);
    const lw = Math.min(rect.w, layer.rect.w);
    const lh = Math.min(rect.h, layer.rect.h);
    getContext2d(surface.canvas!).drawImage(layer.canvas, -(tx - layer.textureX), -(ty - layer.textureY), lw, lh, 0, 0, lw, lh);
  }
  // Assumes no active tool surface and empty `dst` layer
  copyLayer(src: Layer, dst: Layer, selection: Mask | undefined, copyMode: CopyMode, drawingRect: Rect) {
    if (layerHasTool(src) || layerHasTool(dst)) throw new Error('Cannot copy layers with active tool');
    if (DEVELOPMENT && (dst.canvas || !isRectEmpty(dst.rect))) throw new Error('Destination layer is not empty');
    if (!src.canvas || isRectEmpty(src.rect)) return;

    if (selection) {
      const rect = rectMaskIntersectionBoundsInt(src.rect, selection);

      if (isRectEmpty(rect)) return;

      if (copyMode === CopyMode.Copy || copyMode === CopyMode.Cut) {
        this.ensureLayerCanvas(drawingRect, dst, rect);
        const context = getContext2d(dst.canvas!);

        const { x, y, w, h } = rect;

        context.save();
        clipMask(context, selection, -dst.rect.x, -dst.rect.y); // can't use clipTo here, it donesn't work see 'copy via cut circle selection outside drawing' test
        drawImage(context, src.canvas, x - src.textureX, y - src.textureY, w, h, 0, 0, w, h);
        context.restore();

        if (copyMode === CopyMode.Cut) {
          const context2 = getContext2d(src.canvas);
          clipOut(context2, selection, -src.rect.x, -src.rect.y);
          cutMaskFromRect(src.rect, selection);
          if (isRectEmpty(src.rect)) this.releaseLayer(src);
          layerChanged(src, true);
        }

        copyRect(dst.rect, rect);
      } else {
        invalidEnum(copyMode);
      }

      if (isRectEmpty(dst.rect)) this.releaseLayer(dst);
      layerChanged(dst);
    } else {
      if (copyMode === CopyMode.Copy) { // copy entire layer
        this.ensureLayerCanvas(drawingRect, dst, src.rect);
        getContext2d(dst.canvas!).drawImage(src.canvas, 0, 0);
        copyRect(dst.rect, src.rect);
        layerChanged(dst);
      } else {
        throw new Error('Invalid copyMode');
      }
    }
  }
  drawDrawing(drawing: Drawing, _view: Viewport, dirtyRect?: Rect) {
    if (!drawing.canvas || drawing.canvas.width !== drawing.w || drawing.canvas.height !== drawing.h) {
      if (drawing.canvas) this.canvasProvider.release(drawing.canvas);
      drawing.canvas = this.canvasProvider.create('drawing', drawing.w, drawing.h);
    }

    preprocessTextLayersForDrawing(drawing, (layer) => {
      this.drawTextLayer(layer, drawing);
    });

    if (dirtyRect) intersectRect(dirtyRect, drawing);

    this.drawLayers(getContext2d(drawing.canvas), drawing, drawing.background, drawing.layers, dirtyRect ?? drawing, this.canvasProvider, drawing);
  }
  private drawLayers(drawingContext: Context, renderingRect: Rect, background: string | undefined, layers: Layer[], dirtyRect: Rect, canvasProvider: ICanvasProvider, drawingRect: Rect) {
    const { width, height } = drawingContext.canvas;

    makeIntegerRect(dirtyRect);

    if (isRectEmpty(dirtyRect)) return;

    if (background) {
      fillRect(drawingContext, background, dirtyRect.x - renderingRect.x, dirtyRect.y - renderingRect.y, dirtyRect.w, dirtyRect.h);
    } else {
      clearRect(drawingContext, dirtyRect.x - renderingRect.x, dirtyRect.y - renderingRect.y, dirtyRect.w, dirtyRect.h);
    }

    for (let i = layers.length - 1; i >= 0; i--) {
      if (isClippingLayerWithVisibleClippedLayers(i, layers)) {
        const clippingLayer = layers[i];

        if (!layerVisibleWithCanvasOrTool(clippingLayer)) {
          while (i > 0 && layers[i - 1].clippingGroup) i--; // skip all clipped layers
          continue;
        }

        // TODO: just use clippingLayer.canvas if no tool
        const clippingCanvas = canvasProvider.create('clipping', width, height);
        this.drawLayer(getContext2d(clippingCanvas), renderingRect, clippingLayer, dirtyRect, drawingRect, true, false, true);

        const clippedCanvas = canvasProvider.create('clipped', width, height);
        const clippedContext = getContext2d(clippedCanvas);
        clippedContext.drawImage(clippingCanvas, 0, 0);

        for (; i > 0 && layers[i - 1].clippingGroup; i--) {
          if (layerVisibleWithCanvasOrTool(layers[i - 1])) {
            this.drawLayer(clippedContext, renderingRect, layers[i - 1], dirtyRect, drawingRect);
          }
        }

        // TODO: this is still wrong when clippingLayer alpha !== 1
        clippedContext.globalCompositeOperation = 'destination-in';
        clippedContext.drawImage(clippingCanvas, 0, 0);
        clippedContext.globalCompositeOperation = 'source-over';

        drawingContext.globalAlpha = clippingLayer.opacity;
        drawingContext.globalCompositeOperation = getBlendMode(clippingLayer.mode);
        drawingContext.drawImage(clippedCanvas, 0, 0);
        drawingContext.globalAlpha = 1;
        drawingContext.globalCompositeOperation = 'source-over';

        canvasProvider.release(clippingCanvas);
        canvasProvider.release(clippedCanvas);
      } else if (layerVisibleWithCanvasOrTool(layers[i])) {
        this.drawLayer(drawingContext, renderingRect, layers[i], dirtyRect, drawingRect, false);
      }
    }
  }
  // -----------------------------------------------------------------------------
  // TODO:
  //
  //  tool | clip     || temp |
  // ------+----------++------+-----------------------
  //  no   | none     || no   | blend on context
  //  no   | clip     || yes  | draw on temp -> clip -> blend on context (if normal layer can skip temp layer clipping)
  //  no   | clipping || no   | draw on context
  //  yes  | none     || yes  | composite on temp -> blend on context
  //  yes  | clip     || yes  | composite on temp -> clip -> blend on context
  //  yes  | clipping || no   | composite on context
  //
  // + brush composite mode
  // tool -> tool & tool.intersects(dirtyRect)
  // need special handling for normal layers to improve quality on edges
  //
  private drawLayer(renderContext: Context, renderRect: Rect, layer: Layer, dirtyRect: Rect, drawingRect: Rect, clip = false, force = false, noOpacity = false) {
    if (!force && (!isLayerVisible(layer) || isRectEmpty(dirtyRect))) return;

    const user = layer.owner;

    // TODO: check if layerRect is empty ?
    if (user && user.surface.layer === layer && !isSurfaceEmpty(user.surface) && user.surface.mode !== CompositeOp.None) {
      const surface = user.surface;

      if (!surface.canvas) throw new Error('No surface canvas');

      const selection = surface.ignoreSelection ? this.emptySelection : user.selection;
      const temp = getContext2d(this.canvasProvider.create(`temp`, renderRect.w, renderRect.h));

      if (this.composeOp(temp, renderRect, layer, dirtyRect, layer.opacityLocked, selection, surface, drawingRect)) {
        drawCanvas(renderContext, renderRect, temp.canvas, dirtyRect, renderRect.x, renderRect.y, layer, clip, noOpacity);
        this.canvasProvider.release(temp.canvas);
        return;
      }
      this.canvasProvider.release(temp.canvas);
    }
    const rect = cloneRect(dirtyRect);
    intersectRect(rect, layer.rect);
    drawCanvas(renderContext, renderRect, layer.canvas, rect, layer.textureX, layer.textureY, layer, clip, noOpacity);
  }
  drawTextLayer(layer: ReadyTextLayer, drawing: Drawing) {
    const textarea = layer.textarea;
    textarea.write(layer.textData.text);

    this.releaseLayer(layer);
    const rect = textarea.textureRect;
    clipToDrawingRect(rect, drawing);

    if (!isRectEmpty(rect)) {
      this.ensureLayerCanvas(drawing, layer, rect);
      const ctx = getContext2d(layer.canvas!);
      ctx.clearRect(rect.x, rect.y, rect.w, rect.h);

      ctx.translate(-rect.x, -rect.y); // TODO this is quick fix for now, drawOn should be changed to draw without offset
      textarea.drawOn(ctx);
      ctx.translate(rect.x, rect.y);

      copyRect(layer.rect, rect);

      layer.invalidateCanvas = false;

      if (DEVELOPMENT && DRAW_TEXTURE_RECT) drawTextareaTextureRect(ctx, textarea);
    }

    redrawLayerThumb(layer);
  }
  private drawLayerThumb(layer: Layer, drawingRect: Rect) {
    if (layer.thumb && shouldRedrawLayerThumb(layer)) {
      const size = Math.floor(LAYER_THUMB_SIZE * getPixelRatio());

      if (layer.thumb.width !== size || layer.thumb.height !== size) {
        layer.thumb.width = size;
        layer.thumb.height = size;
      }

      const layerRect = cloneRect(getLayerRect(layer));
      const rect = intersectRect(layerRect, drawingRect);
      const context = layer.thumb.getContext('2d');

      if (context) {
        if (rect.w > 0 && rect.h > 0) {
          fillRect(context, '#aaa', 0, 0, size, size);
          const aspectRatio = rect.w / rect.h;
          const dw = aspectRatio > 1 ? size : Math.round(size * aspectRatio);
          const dh = aspectRatio > 1 ? Math.round(size / aspectRatio) : size;
          const dx = Math.round((size - dw) / 2);
          const dy = Math.round((size - dh) / 2);
          clearRect(context, dx, dy, dw, dh);

          if (layer.canvas || (layer.owner && layer.owner.surface.layer === layer)) {
            const thumbContext = this.thumbContext(drawingRect.w, drawingRect.h);
            thumbContext.clearRect(0, 0, rect.w, rect.h);
            this.drawLayer(thumbContext, rect, layer, rect, drawingRect, false, true);
            context.save();
            context.imageSmoothingQuality = 'high';
            drawImage(context, thumbContext.canvas, 0, 0, rect.w, rect.h, dx, dy, dw, dh);
            context.restore();
          }
        } else {
          clearRect(context, 0, 0, size, size);
        }

        layer.thumbDirty = 0;
        return true;
      }
    }

    return false;
  }
  draw(drawing: Drawing, user: User | undefined, view: Viewport, rect: Rect, options: DrawOptions) {
    if (!this.context || !drawing) return;

    const { cursor, settings, lastPoint, showShiftLine } = options;
    const { users } = drawing;
    const context = this.context;
    const ratio = getPixelRatio();
    const bg = settings.background || '#222';
    const w = context.canvas.width;
    const h = context.canvas.height;

    rect = cloneRect(rect);
    scaleRect(rect, ratio, ratio);
    makeIntegerRect(rect);
    clipRect(rect, 0, 0, w, h);

    if (view.rotation === 0 && !view.flipped) {
      fillRect(context, bg, rect.x, rect.y, rect.w, rect.h);
    } else {
      fillRect(context, bg, 0, 0, w, h);
    }

    if (!drawing.canvas || !drawing.id || drawing.loaded !== 1 || drawing.loadingFailed) return;

    const smooth = smoothScaling(settings, view);
    context.imageSmoothingQuality = 'high';

    context.save();
    applyViewportTransform(context, view, ratio);

    // draw background
    if (!drawing.background) {
      // TODO: scale checker pattern properly
      context.save();
      context.scale(1 / view.scale, 1 / view.scale);
      context.fillStyle = this.getCheckerPattern();
      context.fillRect(0, 0, drawing.w * view.scale, drawing.h * view.scale);
      context.restore();
    }

    // draw picture
    context.imageSmoothingEnabled = smooth;
    if (view.filter === 'grayscale') context.filter = 'grayscale(100%)';
    context.drawImage(drawing.canvas, 0, 0);
    context.filter = 'none';
    context.imageSmoothingEnabled = true;
    drawActiveTool(context, drawing, user, view);

    context.restore();
    // draw grid
    if (settings.pixelGrid && view.scale > 6) {
      const minX = Math.round(Math.max(0, view.x)) | 0;
      const maxX = Math.round(Math.min(w, view.x + drawing.w * view.scale)) | 0;
      const minY = Math.round(Math.max(0, view.y)) | 0;
      const maxY = Math.round(Math.min(h, view.y + drawing.h * view.scale)) | 0;
      drawPixelGrid(context, view, minX, minY, maxX, maxY, ratio);
    }

    //draw perspective grid
    if (options.selectedTool?.id == ToolId.PerspectiveGrid && !!user) {
      if (!isPerspectiveGridLayer(user.activeLayer)) {
        const tool = options.selectedTool as PerspectiveGridTool;
        drawPerspectiveGridBoundingBox(context, view, drawing, options, tool.data);
      }
    }
    if (settings.showPerspectiveGrid) {
      for (const layer of drawing.layers) {
        if (isPerspectiveGridLayer(layer) && isLayerVisible(layer)) {
          const perspectiveGridLayer = layer as PerspectiveGridLayer;
          const tool = options.selectedTool?.id === ToolId.PerspectiveGrid ? options.selectedTool as PerspectiveGridTool : undefined;
          drawPerspectiveGrid(context, view, drawing, settings, perspectiveGridLayer, tool);
        }
      }
    }
    if (user?.selection && options.selectedTool?.id !== ToolId.AI) drawSelection(context, user.selection, view, user.surface.transform, drawing);
    if (user?.showTransform) drawTransform(context, user, drawing, view);
    if (options.selectedTool?.id === ToolId.AI && !!user) {
      const tool = options.selectedTool as AiTool;
      if (tool.showSelection && tool.results.size === 0 && !isMaskEmpty(tool.getSelection())) {
        this.fillSelectionWithPattern(context, tool.getSelection(), view, getAiSelectionPatternCanvas(), drawing);
      }
      if (tool.pipeline === 'outpaint' && user.activeLayer && tool.results.size === 0) {
        this.drawAiOutpaintingMask(context, tool, user.activeLayer, view, user, drawing);
      }
      drawAiBoundingBox(context, view, drawing, options);
    }
    if (options.selectedTool && !toolIncompatibleWithPerspectiveGridLayer(options.selectedTool.id) && user && isPerspectiveGridLayer(user.activeLayer) && isLayerVisible(user.activeLayer) && !user.activeLayer.locked) {
      drawPerspectiveGridBoundingBox(context, view, drawing, options, user.activeLayer.perspectiveGrid);
    }
    if (options.selectedTool?.id === ToolId.Crop) this.drawCropBoundingBox(context, view, drawing, options);

    if (user && shouldDrawTextarea(user.activeLayer, options)) {
      drawTextarea(context, drawing, user.activeLayer.textarea, view, options);
    }

    if (showShiftLine) {
      copyPoint(tempPt, lastPoint);
      absoluteDocumentToDocuemnt(tempPt, drawing);
      documentToScreenPoint(tempPt, view);
      context.beginPath();
      context.moveTo(tempPt.x * ratio, tempPt.y * ratio);
      context.lineTo(cursor.x * ratio, cursor.y * ratio);
      context.strokeStyle = 'rgba(255, 255, 255, 0.3)';
      context.lineWidth = 1.5;
      context.stroke();
      context.strokeStyle = 'rgba(128, 128, 128, 0.3)';
      context.lineWidth = 1;
      context.stroke();
    }

    if (users.length && settings.cursors !== CursorsMode.None) {
      drawCursors(context, view, drawing, users, settings.cursors ?? 0, options.settings.includeVideo || false, options);
    }

    if (!isTextLayer(user?.activeLayer) || (options.selectedTool && !toolIncompatibleWithTextLayers(options.selectedTool.id))) {
      drawCursor(context, cursor, cursor.x, cursor.y, view, settings);
    }

    if (options.settings.includeVideo && user && options.drawingInProgress !== true) {
      const alpha = quadraticEasing(noSelfVideoTime, performance.now(), 1000);
      drawSelfVideo(context, cursor, user, alpha, options);
    } else {
      noSelfVideoTime = performance.now();
    }

    if (DEBUG_DRAW_LAYER_RECTS) drawDebugLayerBounds(this, drawing, view, user!);
    if (DEBUG_DRAW_ALLOCATED_TEXTURES) drawDebugAllocatedCanvases(this, view, drawing, user!);
  }
  drawLayerThumbs(layers: Layer[], drawingRect: Rect) {
    for (const layer of layers) {
      if (this.drawLayerThumb(layer, drawingRect)) {
        break;
      }
    }
  }
  drawThumb(drawing: Drawing, dirtyRect: Rect, thumb: HTMLCanvasElement | undefined) {
    if (!drawing.canvas || !thumb) return;
    const { w, h } = drawing;

    const context = thumb.getContext('2d');
    const scale = Math.min(SEQUENCE_THUMB_WIDTH / w, SEQUENCE_THUMB_HEIGHT / h);
    const thumbWidth = Math.ceil(w * scale);
    const thumbHeight = Math.ceil(h * scale);
    const dx = Math.floor((thumb.width - thumbWidth) / 2);
    const dy = Math.floor((thumb.height - thumbHeight) / 2);

    if (context) {
      context.save();
      context.imageSmoothingQuality = 'high';
      if (scale > 1) context.imageSmoothingEnabled = false;
      context.drawImage(drawing.canvas, 0, 0, w, h, dx, dy, thumbWidth, thumbHeight);
      context.restore();
      const thumbRect = makeIntegerRect(createRect((dirtyRect.x - drawing.x) * scale, (dirtyRect.y - drawing.y) * scale, dirtyRect.w * scale, dirtyRect.h * scale));
      const imageData = context.getImageData(thumbRect.x + dx, thumbRect.y + dy, thumbRect.w, thumbRect.h);
      const data = new Uint8Array(imageData.data.buffer, imageData.data.byteOffset, imageData.data.byteLength);
      drawing.thumbUpdate = { data, width: thumbWidth, height: thumbHeight, rect: thumbRect };
    }

  }
  pingThumb(_drawing: Drawing) {
  }
  discardThumb() {
  }
  scaleImage(image: HTMLImageElement | ImageBitmap | ImageData, scaledWidth: number, scaledHeight: number): HTMLCanvasElement {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    if ('data' in image) {
      // this can happen only on server with software renderer ?
      const scaledCanvas = createCanvas(image.width, image.height);
      const scaledContext = getContext2d(scaledCanvas);
      const data = scaledContext.createImageData(image.width, image.height);
      data.data.set(image.data);

      context.drawImage(scaledCanvas, 0, 0, image.width, image.height, 0, 0, scaledWidth, scaledHeight);
    } else {
      context.drawImage(image, 0, 0, image.width, image.height, 0, 0, scaledWidth, scaledHeight);
    }
    return canvas;
  }
  getScaledDrawingSnapshot(drawing: Drawing, scaledWidth: number, scaledHeight: number, selection?: Rect) {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const bounds = selection ? selection : drawing;
    const snapshot = this.getDrawingSnapshot(drawing, createRectMask(bounds));
    if (snapshot) {
      context.imageSmoothingQuality = 'high';
      context.drawImage(snapshot, 0, 0, snapshot.width, snapshot.height, 0, 0, scaledWidth, scaledHeight);
    }
    return canvas;
  }
  getScaledLayerSnapshot(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, selection?: Rect) {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const bounds = selection ? selection : drawing;
    const snapshot = this.getLayerSnapshot(layer, createRectMask(bounds));
    if (snapshot) {
      context.imageSmoothingQuality = 'high';
      context.drawImage(snapshot, 0, 0, bounds.w, bounds.h, 0, 0, scaledWidth, scaledHeight);
    }
    return canvas;
  }
  getScaledLayerMask(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, selection?: Rect): HTMLCanvasElement {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const bounds = selection ?? drawing;
    const snapshot = this.getLayerSnapshot(layer, createRectMask(bounds));
    if (snapshot) {
      // make sure that mask is generated from original image (creating mask from scaled image can cause outpainting issues)
      const mask = createCanvas(snapshot.width, snapshot.height);
      const maskContext = getContext2d(mask);

      maskContext.drawImage(snapshot, 0, 0);

      maskContext.fillStyle = '#000000FF';
      maskContext.globalCompositeOperation = 'source-atop';
      maskContext.fillRect(0, 0, snapshot.width, snapshot.height);

      maskContext.fillStyle = '#FFFFFFFF';
      maskContext.globalCompositeOperation = 'destination-atop';
      maskContext.fillRect(0, 0, snapshot.width, snapshot.height);

      context.imageSmoothingQuality = 'high';
      context.drawImage(mask, 0, 0, bounds.w, bounds.h, 0, 0, scaledWidth, scaledHeight);
    }
    return canvas;
  }
  pickColor(drawing: Drawing, layer: Layer | undefined, x: number, y: number, activeLayer: boolean) {
    return pickColor(drawing, layer, x | 0, y | 0, activeLayer);
  }
  getToolRenderingContext(user: User, bounds: Rect) {
    if (DEVELOPMENT && user.surface.context) throw new Error('Rendering context not released');

    this.ensureSurface(user.surface, bounds);
    const context = getContext2d(user.surface.canvas!);
    return user.surface.context = createRenderingContext(context);
  }
  trimLayer(layer: Layer, rect: Rect, allowExpanding = false) {
    if (isRectEmpty(rect)) {
      this.releaseLayer(layer);
    } else {
      if (!rectIncludesRect(layer.rect, rect) && !allowExpanding) {
        throw new Error(`Trim layer is going to expand layer! (${rectToString(layer.rect)} -> ${rectToString(rect)})`);
      }
      this.ensureLayerCanvas(undefined, layer, rect);
    }
  }
  // for testing
  fillSelection(layer: Layer, drawingRect: Rect) {
    if (!layer.owner) throw new Error('Missing layer owner');
    const user = layer.owner;

    const rect = cloneRect(layer.rect);
    if (!isMaskEmpty(user.selection)) addRect(rect, user.selection.bounds);

    this.ensureLayerCanvas(drawingRect, layer, rect);
    const context = getContext2d(layer.canvas!);
    context.save();
    context.fillStyle = 'cornflowerblue';
    if (!hasZeroTransform(layer.owner.surface)) {
      applyTransform(context, user.surface.transform);
      fillMask(context, user.selection, -rect.x, -rect.y);
    }
    context.restore();
    copyRect(layer.rect, rect);
    layerChanged(layer);
  }
  private getCheckerPattern() {
    if (!this.pattern) {
      const size = 16;
      const canvas = createCanvas(size, size);
      const context = getContext2d(canvas);
      fillRect(context, '#fff', 0, 0, size, size);
      fillRect(context, '#cfcfcf', 0, 0, size / 2, size / 2);
      fillRect(context, '#cfcfcf', size / 2, size / 2, size / 2, size / 2);
      this.pattern = context.createPattern(canvas, 'repeat')!;
    }

    return this.pattern;
  }

  private ensureLayerCanvas(drawingRect: Rect | undefined, layer: Layer, r: Rect) {
    const rect = cloneRect(r);
    let clipped = false;
    if (drawingRect) {
      if (DEVELOPMENT && !TESTS && (rect.w > getMaxLayerWidth(drawingRect.w) || rect.h > getMaxLayerHeight(drawingRect.h))) console.warn(`Reached layer size limit ${rect.w}x${rect.h}, limit ${getMaxLayerWidth(drawingRect.w)}x${getMaxLayerHeight(drawingRect.h)}`);
      clipped = clipSurfaceToLimits(rect, drawingRect, getMaxLayerWidth(drawingRect.w), getMaxLayerHeight(drawingRect.h));
    }

    const width = rect.w;
    const height = rect.h;

    if (layer.canvas && (clipped || layer.rect.w !== width || layer.rect.h !== height)) {
      const c = this.canvasProvider.create(`${this.id}.layer_${layer.id}`, width, height);
      const context = getContext2d(c);
      drawImage(context, layer.canvas,
        layer.rect.x - layer.textureX, layer.rect.y - layer.textureY,
        layer.rect.w, layer.rect.h,
        layer.rect.x - rect.x, layer.rect.y - rect.y,
        layer.rect.w, layer.rect.h
      );

      this.canvasProvider.release(layer.canvas);
      layer.canvas = c;
    } else if (!layer.canvas) {
      layer.canvas = this.canvasProvider.create(`${this.id}.layer_${layer.id}`, width, height);
    }
    copyRect(layer.rect, rect);
    layer.textureX = rect.x;
    layer.textureY = rect.y;
  }
  private getLayerContext(drawingRect: Rect, layer: Layer, rect: Rect) {
    this.ensureLayerCanvas(drawingRect, layer, rect);
    return getContext2d(layer.canvas!);
  }
  // filters
  applyHueSaturationLightnessFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyHueSaturationLightnessFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    applyHueSaturationLightness(srcData.data, dstData.data, values);
    this.ensureSurface(surface, surface.rect);
    getContext2d(surface.canvas!).putImageData(dstData, 0, 0);
  }
  applyBrightnessContrastFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyBrightnessContrastFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    applyBrightnessContrast(srcData.data, dstData.data, values);
    this.ensureSurface(surface, surface.rect);
    getContext2d(surface.canvas!).putImageData(dstData, 0, 0);
  }
  applyCurvesFilter(srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyCurvesFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    applyCurves(srcData.data, dstData.data, values);
    this.ensureSurface(surface, surface.rect);
    getContext2d(surface.canvas!).putImageData(dstData, 0, 0);
  }

  applyGaussianBlurFilter(srcData: ImageData | undefined, srcDataRect: Rect, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyGaussianBlurFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    const radius = values.radius || 0;
    applyGaussianBlur(srcData.data, dstData.data, srcData.width, srcData.height, radius);
    getContext2d(surface.canvas!).putImageData(dstData, srcDataRect.x - surface.textureX, srcDataRect.y - surface.textureY);
  }
  applyMotionBlurFilter(srcData: ImageData | undefined, srcDataRect: Rect, surface: ToolSurface, values: IFiltersValues) {
    if (!srcData) throw new Error(`[applyMotionBlurFilter] Missing srcData`);
    const dstData = this.createImageData(srcData.width, srcData.height, undefined);
    const angle = values.angle!;
    const distance = values.distance || 0;

    if (Math.round(distance) == 0) {
      getContext2d(surface.canvas!).putImageData(srcData, srcDataRect.x - surface.textureX, srcDataRect.y - surface.textureY);
      return;
    }

    const angleInRadians = deg2rad(angle);
    let dirx = (angle == 90 || angle == 270) ? 0 : (angle == 180) ? 1 : Math.cos(angleInRadians);
    let diry = (angle == 90 || angle == 270) ? 1 : (angle == 180) ? 0 : Math.sin(angleInRadians);
    if ((dirx < 0 && diry < 0) || (dirx >= 0 && diry >= 0)) {
      dirx = Math.abs(dirx);
      diry = Math.abs(diry);
      applyMotionBlur(srcData.data, dstData.data, srcData.width, srcData.height, Math.round(distance || 0), dirx, diry, false);
    } else {
      dirx = Math.abs(dirx);
      diry = Math.abs(diry);
      applyMotionBlur(srcData.data, dstData.data, srcData.width, srcData.height, Math.round(distance || 0), dirx, diry, true);
    }
    getContext2d(surface.canvas!).putImageData(dstData, srcDataRect.x - surface.textureX, srcDataRect.y - surface.textureY);
  }
  private drawAiOutpaintingMask(context: Context, tool: AiTool, layer: Layer, view: Viewport, user: User, drawingRect: Rect) {
    if (!this.canvas) return;
    const mat = createViewportMatrix2d(tempMatrix2d, view);
    translateAbsoluteMatToDrawingMat2d(mat, drawingRect);
    const pixelRatio = getPixelRatio();
    const snapshot = layer.canvas;
    if (snapshot) {
      if (!this.tempCanvas || !this.tempCanvasContext || this.tempCanvas.width !== this.canvas.width || this.tempCanvas.height !== this.canvas.height) {
        this.tempCanvas = createCanvas(Math.ceil(this.canvas.width / pixelRatio), Math.ceil(this.canvas.height / pixelRatio));
        this.tempCanvasContext = getContext2d(this.tempCanvas!);
      }

      this.tempCanvasContext.clearRect(0, 0, this.tempCanvas.width, this.tempCanvas.height);
      this.tempCanvasContext.save();
      this.tempCanvasContext.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);
      this.tempCanvasContext.fillStyle = AI_SELECTION_COLOR_1;
      this.tempCanvasContext.fillRect(tool.bounds.x, tool.bounds.y, tool.bounds.w, tool.bounds.h);

      if (!TESTS) {
        if (!this.tempDOMMatrix) this.tempDOMMatrix = new DOMMatrix([1, 0, 0, 1, 0, 0]);
        updateAiMaskTransform(this.tempDOMMatrix);
      }
      fillRectWithPattern(this.tempCanvasContext, tool.bounds, getAiSelectionPatternCanvas(), this.tempDOMMatrix);

      this.tempCanvasContext.globalCompositeOperation = 'destination-out';
      this.tempCanvasContext.drawImage(snapshot, layer.rect.x, layer.rect.y, layer.rect.w, layer.rect.h);

      if (user.surface.canvas) {
        const m = user.surface.transform;
        this.tempCanvasContext.transform(m[0], m[1], m[2], m[3], m[4], m[5]);
        this.tempCanvasContext.drawImage(user.surface.canvas, 0, 0);
      }
      this.tempCanvasContext.restore();

      context.save();
      context.scale(pixelRatio, pixelRatio);
      context.drawImage(this.tempCanvasContext.canvas, 0, 0);
      context.restore();
    }
  }
  private fillSelectionWithPattern(context: CanvasRenderingContext2D, selection: Mask, view: Viewport, patternCanvas: HTMLCanvasElement, drawingRect: Rect) {
    const mat = createViewportMatrix2d(tempMatrix2d, view);
    translateAbsoluteMatToDrawingMat2d(mat, drawingRect);
    const pixelRatio = getPixelRatio();
    const mask = cloneMask(selection);
    transformMask(mask, mat);

    context.save();
    context.scale(pixelRatio, pixelRatio);
    context.fillStyle = AI_SELECTION_COLOR_1;
    fillMask(context, mask);

    if (!TESTS) {
      if (!this.tempDOMMatrix) this.tempDOMMatrix = new DOMMatrix([1, 0, 0, 1, 0, 0]);
      updateAiMaskTransform(this.tempDOMMatrix);
    }
    fillWithCanvasPattern(context, patternCanvas, this.tempDOMMatrix);
    context.restore();
  }
  private compose(context: Context, renderingRect: Rect, layer: Layer, dirtyRect: Rect, surface: ToolSurface, selection: Mask, compositeOperation: GlobalCompositeOperation) {
    if (!surface.canvas) throw new Error('Missing surface.canvas');
    const globalAlpha = context.globalAlpha;
    const globalCompositeOperation = context.globalCompositeOperation;

    const rect = cloneRect(dirtyRect);
    intersectRect(rect, layer.rect);
    if (layer.canvas && !isRectEmpty(rect)) {
      drawImage(context, layer.canvas,
        rect.x - layer.textureX, rect.y - layer.textureY,
        rect.w, rect.h,
        rect.x - renderingRect.x, rect.y - renderingRect.y,
        rect.w, rect.h
      );
    }
    context.globalAlpha = surface.opacity;
    context.globalCompositeOperation = compositeOperation;

    if (!isRectEmpty(surface.rect) && surface.color !== WHITE) {
      const toolContext = getContext2d(surface.canvas);
      toolContext.globalCompositeOperation = 'source-atop';
      toolContext.fillStyle = colorToCSS(surface.color);
      // TODO: this can be optimized to only apply to dirty region, or can be done in RenderingContext (on flush)
      toolContext.fillRect(surface.rect.x - surface.textureX, surface.rect.y - surface.textureY, surface.rect.w, surface.rect.h);
      toolContext.globalCompositeOperation = 'source-over';
    }

    if (!isMaskEmpty(selection)) {
      context.save();
      const { x, y, w, h } = selection.bounds;
      clipMask(context, selection, -renderingRect.x, -renderingRect.y);
      context.drawImage(surface.canvas,
        x - surface.textureX, y - surface.textureY,
        w, h,
        x - renderingRect.x, y - renderingRect.y,
        w, h
      );
      context.restore();
    } else {
      drawImage(context, surface.canvas,
        surface.rect.x - surface.textureX, surface.rect.y - surface.textureY,
        surface.rect.w, surface.rect.h,
        surface.rect.x - renderingRect.x, surface.rect.y - renderingRect.y,
        surface.rect.w, surface.rect.h,
      );
    }

    context.globalAlpha = globalAlpha;
    context.globalCompositeOperation = globalCompositeOperation;
  }
  private composeOp(context: Context, renderingRect: Rect, layer: Layer, dirtyRect: Rect, lockOpacity: boolean, selection: Mask, surface: ToolSurface, drawingRect: Rect) {
    if (!surface.canvas) throw new Error('Missing surface.canvas');

    switch (surface.mode) {
      case CompositeOp.None:
        throw new Error('Cannot compose None');
      case CompositeOp.Draw: {
        if (DEVELOPMENT && !isMat2dIdentity(surface.transform)) throw new Error('Not supported');
        this.compose(context, renderingRect, layer, dirtyRect, surface, selection, lockOpacity ? 'source-atop' : 'source-over');
        return true;
      }
      case CompositeOp.Erase: {
        if (DEVELOPMENT && !isMat2dIdentity(surface.transform)) throw new Error('Not supported');
        this.compose(context, renderingRect, layer, dirtyRect, surface, selection, 'destination-out');
        return true;
      }
      case CompositeOp.Move: {
        if (layer.canvas) {
          const rect = cloneRect(dirtyRect);
          intersectRect(rect, layer.rect);
          if (!isRectEmpty(rect)) {
            const { x, y, w, h } = rect;
            drawImage(context, layer.canvas,
              x - layer.textureX, y - layer.textureY,
              w, h,
              x - drawingRect.x, y - drawingRect.y,
              w, h
            );
          }
        }

        if (!hasZeroTransform(surface)) {
          const transformedBounds = getTransformedSurfaceBounds(surface.rect, surface.transform);

          // TODO: this is not very accurate

          const x = surface.rect.x - transformedBounds.x;
          const y = surface.rect.y - transformedBounds.y;
          const w = transformedBounds.w | 0;
          const h = transformedBounds.h | 0;

          if (w && h) {
            const temp = createCanvas(w, h);
            const tempContext = getContext2d(temp);

            tempContext.translate(x, y);
            tempContext.save();
            tempContext.imageSmoothingQuality = 'high';
            tempContext.translate(-surface.rect.x, -surface.rect.y); // transform to make sure that origin from transform will be in proper place
            tempContext.transform(surface.transform[0], surface.transform[1], surface.transform[2], surface.transform[3], surface.transform[4], surface.transform[5]);
            tempContext.translate(surface.rect.x, surface.rect.y);
            tempContext.drawImage(surface.canvas, 0, 0);
            tempContext.restore();

            context.drawImage(temp, transformedBounds.x - renderingRect.x, transformedBounds.y - renderingRect.y);// transformedBounds.w, transformedBounds.h);
          }
        }

        return true;
      }
      case CompositeOp.Overwrite: {
        //TODO
        return true;
      }
      default: invalidEnum(surface.mode);
    }
  }
  drawCropBoundingBox(context: Context, view: Viewport, drawing: Drawing, options: DrawOptions) {
    const tool = options.selectedTool as CropTool;
    if (tool.skipDrawingTool) return;
    const mat = createViewportMatrix2d(tempMatrix2d, view);
    const pixelRatio = getPixelRatio();
    const bounds = cloneRect(tool.bounds);
    absoluteDocumentToDocumentRect(bounds, drawing);

    context.save();
    context.scale(pixelRatio, pixelRatio);
    context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

    if (tool.maskOpacity > 0) {
      const r = cloneRect(bounds);
      clipRect(r, 0, 0, drawing.w, drawing.h);

      const opacity = tool.maskOpacity / 100;

      context.fillStyle = `rgba(${255 * CROP_BACKDROP_COLOR_FLOAT[0] * opacity | 0},${255 * CROP_BACKDROP_COLOR_FLOAT[1] * opacity | 0},${255 * CROP_BACKDROP_COLOR_FLOAT[2] * opacity | 0},${opacity})`;
      context.fillRect(0, 0, r.x, view.content.h);
      context.fillRect(r.x + r.w, 0, view.content.w - r.w - r.x, view.content.h);
      context.fillRect(r.x, 0, r.w, r.y);
      context.fillRect(r.x, r.y + r.h, r.w, view.content.h - (r.y + r.h));
    }

    if (tool.overlay !== CropOverlay.Disabled) {
      drawCropOverlay(context, view, bounds, tool.overlay, tool.state() === CropToolState.Error ? CROP_SELECTION_COLOR_ERROR_STR : CROP_SELECTION_COLOR_2_STR);
    }

    drawSolidFrame(context, view, bounds, CROP_SELECTION_COLOR_1_STR, tool.state() === CropToolState.Error ? CROP_SELECTION_COLOR_ERROR_STR : CROP_SELECTION_COLOR_2_STR, 0.5, 1);
    context.restore();

    drawLayerBounds(context, drawing, view);
    if (this.sprite) {
      const { x, y, w, h } = bounds;

      drawIcon(context, this.sprite, mat, view, x, y, SpriteIcon.CropLeftTop, 3, 3);
      drawIcon(context, this.sprite, mat, view, x, y + h, SpriteIcon.CropLeftBottom, 3, -3);
      drawIcon(context, this.sprite, mat, view, x + w, y, SpriteIcon.CropRightTop, -3, 3);
      drawIcon(context, this.sprite, mat, view, x + w, y + h, SpriteIcon.CropRightBottom, -3, -3);

      drawIcon(context, this.sprite, mat, view, x, y + h / 2, SpriteIcon.CropVertical, 0, 0);
      drawIcon(context, this.sprite, mat, view, x + w, y + h / 2, SpriteIcon.CropVertical, 0, 0);

      drawIcon(context, this.sprite, mat, view, x + w / 2, y, SpriteIcon.CropHorizontal, 0, 0);
      drawIcon(context, this.sprite, mat, view, x + w / 2, y + h, SpriteIcon.CropHorizontal, 0, 0);
    }

    tempVec[0] = bounds.x + bounds.w / 2;
    tempVec[1] = bounds.y + bounds.h;
    transformVec2ByMat2d(tempVec, tempVec, mat);

    context.save();
    const width = tool.state() !== CropToolState.Default ? CROP_LABEL_WIDTH + CROP_LABEL_ICON_WIDTH : CROP_LABEL_WIDTH;
    drawCropLabel(context, bounds, tool, pixelRatio, tempVec[0] - width / 2, tempVec[1] + 8);
    context.restore();
  }
}

function drawPixelGrid(context: Context, view: Viewport, minX: number, minY: number, maxX: number, maxY: number, ratio: number) {
  const alpha = Math.min(0.1 + 0.01 * (view.scale - 6), 0.2);

  if (view.rotation) {
    context.save();
    context.beginPath();

    applyViewportTransform(context, view, ratio);

    const rect = createRect(0, 0, view.width, view.height);
    screenToDocumentRect(rect, view);
    clipRect(rect, 0, 0, view.content.w, view.content.h);
    minX = rect.x | 0;
    minY = rect.y | 0;
    maxX = Math.ceil(rect.x + rect.w);
    maxY = Math.ceil(rect.y + rect.h);

    for (let x = minX; x < maxX; x++) {
      context.moveTo(x, minY);
      context.lineTo(x, maxY);
    }

    for (let y = minY; y < maxY; y++) {
      context.moveTo(minX, y);
      context.lineTo(maxX, y);
    }

    context.restore();
  } else {
    const s = view.scale * ratio;
    let x = view.x * ratio + s;
    let y = view.y * ratio + s;

    while (x < minX)
      x += s;

    while (y < minY)
      y += s;

    context.beginPath();

    for (; x < maxX; x += s) {
      context.moveTo((x | 0) + 0.5, minY);
      context.lineTo((x | 0) + 0.5, maxY);
    }

    for (; y < maxY; y += s) {
      context.moveTo(minX, (y | 0) + 0.5);
      context.lineTo(maxX, (y | 0) + 0.5);
    }
  }

  context.strokeStyle = `rgba(255, 255, 255, ${alpha})`;
  context.lineWidth = 1;
  context.lineCap = 'butt';
  context.stroke();
}

function pickColor(drawing: Drawing, layer: Layer | undefined, x: number, y: number, activeLayer: boolean): number | undefined {
  if (!rectContainsXY(drawing, x, y)) return undefined;

  const context = getPixelContext();

  if (!activeLayer && drawing.background) {
    fillRect(context, drawing.background, 0, 0, 1, 1);
  } else {
    clearRect(context, 0, 0, 1, 1);
  }

  if (!activeLayer) {
    if (!drawing.canvas) {
      return undefined;
    } else {
      drawImage(context, drawing.canvas, x - drawing.x, y - drawing.y, 1, 1, 0, 0, 1, 1);
    }
  } else if (layer) {
    if (rectContainsXY(layer.rect, x, y) && layer.canvas) {
      drawImage(context, layer.canvas, x - layer.textureX, y - layer.textureY, 1, 1, 0, 0, 1, 1);
    }

    const surface = layer.owner?.surface;
    if (surface && surface.layer === layer && !isSurfaceEmpty(surface) && !hasZeroTransform(surface)) {
      const vec = pointToSurface(surface, x, y);
      const sx = Math.floor(vec[0]);
      const sy = Math.floor(vec[1]);

      if (surface.canvas && rectContainsRect(surface.rect, createRect(sx, sy, 1, 1))) {
        drawImage(context, surface.canvas, sx - surface.textureX, sy - surface.textureY, 1, 1, 0, 0, 1, 1);
      }
    }
  }

  const data = context.getImageData(0, 0, 1, 1).data;
  return data[3] === 0 ? undefined : colorFromRGBA(data[0], data[1], data[2], data[3]);
}

function drawSelection(context: CanvasRenderingContext2D, mask: Mask, view: Viewport, transform: Mat2d, drawing: Drawing) {
  if (!mask.poly) return;

  const ratio = getPixelRatio();

  context.save();
  context.beginPath();
  const { ox, oy } = mask.poly;

  const vec = createVec2();
  const mat = createMat2d();
  createViewportMatrix2d(mat, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  multiplyMat2d(mat, mat, transform);

  for (const segment of mask.poly.segments) {
    if (!isPolySegmentEmpty(segment)) {
      const { items, size } = segment;
      const size2 = size << 1;

      const x = items[0] + ox;
      const y = items[1] + oy;
      transformVec2ByMat2d(vec, setVec2(vec, x, y), mat);
      context.moveTo(round5(vec[0] * ratio), round5(vec[1] * ratio));

      for (let j = 2; j < size2; j += 2) {
        const x = items[j] + ox;
        const y = items[j + 1] + oy;
        transformVec2ByMat2d(vec, setVec2(vec, x, y), mat);
        context.lineTo(round5(vec[0] * ratio), round5(vec[1] * ratio));
      }

      transformVec2ByMat2d(vec, setVec2(vec, x, y), mat);
      context.lineTo(round5(vec[0] * ratio), round5(vec[1] * ratio));
    }
  }

  context.strokeStyle = '#fff';
  context.stroke();

  context.setLineDash([4 * ratio, 4 * ratio]);
  context.lineWidth = 1 * ratio;
  context.lineDashOffset = (Math.round(Date.now() / 250) % (8 * ratio)) + 0.5;
  context.strokeStyle = '#000';
  context.stroke();

  context.restore();
}

function clipTo(context: CanvasRenderingContext2D, mask: Mask, tx = 0, ty = 0) {
  const globalCompositeOperation = context.globalCompositeOperation;
  context.globalCompositeOperation = 'destination-in';
  fillMask(context, mask, tx, ty);
  context.globalCompositeOperation = globalCompositeOperation;
}

function clipOut(context: CanvasRenderingContext2D, mask: Mask, tx = 0, ty = 0) {
  const globalCompositeOperation = context.globalCompositeOperation;
  context.globalCompositeOperation = 'destination-out';
  fillMask(context, mask, tx, ty);
  context.globalCompositeOperation = globalCompositeOperation;
}

function drawToolSurface(context: CanvasRenderingContext2D, surface: ToolSurface) {
  if (surface.canvas && !hasZeroTransform(surface)) {
    // TODO: use bounds & mode
    context.save();
    context.globalAlpha = surface.opacity;
    applyTransform(context, surface.transform);
    context.drawImage(surface.canvas, 0, 0);
    context.restore();
  }
}

interface NamePlate {
  id: number;
  name: string;
  color: string;
  canvas: HTMLCanvasElement;
  avatarImage: HTMLImageElement | undefined;
  avatarVideoDimensions?: {
    width: number;
    height: number;
  }
}

const namePlates: NamePlate[] = [];
let namePlatesMode = CursorsMode.None;
let namePlatesRatio = 1;

export function truncateName(context: CanvasRenderingContext2D, name: string, maxLength: number) {
  if (textWidth(context, name) > maxLength) {
    let length = name.length;
    let truncated: string;

    do {
      length--;
      truncated = truncate(name, { length, omission: '…' });
    } while (length && textWidth(context, truncated) > maxLength);

    name = truncated;
  }

  return name;
}

export function createNamePlate(user: User, ratio: number, mode: CursorsMode, avatarVideo: HTMLVideoElement | undefined): NamePlate {
  const { localId, name, color, colorFloat, avatarImage } = user;
  let width = Math.ceil(USER_NAME_WIDTH * ratio);
  let drawAvatar = mode === CursorsMode.PointerAvatarName || mode === CursorsMode.PointerAvatar;
  const drawName = mode === CursorsMode.PointerAvatarName || mode === CursorsMode.PointerName;
  let drawNameplate = drawName;

  if (avatarVideo) {
    drawAvatar = false;
    drawNameplate = false;
  }

  const height = Math.round((drawAvatar && !drawName ? CURSOR_AVATAR_LARGE_HEIGHT : USER_NAME_HEIGHT) * ratio);
  const canvas = createCanvas(width, height);
  const context = getContext2d(canvas);
  let w = 0;
  let truncated = '';
  const padX = 9 * ratio;
  const font = context.font = `bold ${14 * ratio}px ${DEFAULT_FONT}`;

  if (drawAvatar) {
    w += height;
    width = height;
  }

  if (drawName) {
    const maxLength = avatarVideo ? Math.floor(CURSOR_VIDEO_HEIGHT * ratio * avatarVideo.videoWidth / avatarVideo.videoHeight) - 10 * ratio : 100 * ratio + w;
    context.font = font;
    truncated = truncateName(context, name, maxLength);
    width = Math.ceil(textWidth(context, truncated) + padX * 2 + w);
  }

  canvas.width = width;

  const brightness = drawNameplate ? rgbToGray(colorFloat[0], colorFloat[1], colorFloat[2]) : 0;

  if (drawNameplate) {
    context.fillStyle = color;
    context.fillRect(w, 0, canvas.width - w, canvas.height);
  }
  if (drawName) {
    context.fillStyle = brightness > 0.71 ? '#222' : 'white';
    context.font = font;
    context.fillText(truncated, padX + w, 19 * ratio);
  }

  if (drawAvatar && avatarImage) {
    context.drawImage(avatarImage, 0, 0, avatarImage.width, avatarImage.width, 0, 0, height, height);
  }

  const avatarVideoDimensions = avatarVideo ? { width: avatarVideo.videoWidth, height: avatarVideo.videoHeight } : undefined;

  return { id: localId, name, color, canvas, avatarImage, avatarVideoDimensions };
}

function getNamePlate(user: User, users: User[], ratio: number, mode: CursorsMode, avatarVideo: HTMLVideoElement | undefined): HTMLCanvasElement {
  if (namePlatesRatio !== ratio || namePlatesMode !== mode) {
    namePlates.length = 0;
  }

  let index = findIndexById(namePlates, user.localId);

  if (index === -1) {
    if (namePlates.length >= 64) {
      for (let i = namePlates.length - 1; i >= 0; i--) {
        if (!findByLocalId(users, namePlates[i].id)) {
          removeAtFast(namePlates, i);
        }
      }
    }

    namePlates.push(createNamePlate(user, ratio, mode, avatarVideo));
    index = namePlates.length - 1;
  } else if (
    namePlates[index].name !== user.name ||
    namePlates[index].color !== user.color ||
    namePlates[index].avatarImage !== user.avatarImage ||
    namePlates[index].avatarVideoDimensions?.width !== (avatarVideo ? avatarVideo.videoWidth : undefined) ||
    namePlates[index].avatarVideoDimensions?.height !== (avatarVideo ? avatarVideo.videoHeight : undefined)
  ) {
    namePlates[index] = createNamePlate(user, ratio, mode, avatarVideo);
  }

  return namePlates[index].canvas;
}

export function drawPointer(context: CanvasRenderingContext2D, x: number, y: number, color: string, cursorAlpha: number) {
  const ratio = getPixelRatio();
  context.globalAlpha = Math.max(0.5, cursorAlpha);
  context.beginPath();
  context.strokeStyle = color;
  context.arc(x, y, USER_CURSOR_RADIUS * ratio, 0, Math.PI * 2);
  context.stroke();
}

function rectForCursorVideo(cursor: { x: number, y: number }, avatarVideo: HTMLVideoElement) {
  const h = CURSOR_VIDEO_HEIGHT;
  const srcWidth = avatarVideo.videoWidth;
  const srcHeight = avatarVideo.videoHeight;
  let w = Math.floor(h * (srcWidth / srcHeight));

  const x = cursor.x + USER_NAME_OFFSET;
  const y = cursor.y + USER_NAME_OFFSET;
  return { x, y, w, h };
}

function drawSelfVideo(context: CanvasRenderingContext2D, cursor: Cursor, user: User, opacity: number, options: DrawOptions) {
  const avatarVideo = options.videoForUser(user);

  if (avatarVideo) {
    context.save();
    context.globalAlpha = opacity;
    const { x, y, w, h } = rectForCursorVideo(cursor, avatarVideo);
    context.translate(x + w / 2, y + w / 2);
    context.scale(-1, 1);
    context.translate(-(x + w / 2), -(y + w / 2));
    context.drawImage(avatarVideo, x, y, w, h);
    context.restore();

    return { x, y, w, h };
  }
  return undefined;
}

function drawCursors(context: CanvasRenderingContext2D, view: Viewport, drawing: Drawing, users: User[], cursors: CursorsMode, includeVideo: boolean, options: DrawOptions) {
  const role = drawing.permissions.cursors ?? defaultDrawingPermissions.cursors;
  const ratio = getPixelRatio();
  const lastUpdateThreshold = performance.now() - SHOW_CURSOR_UNMOVING_TIMEOUT;

  // circles
  context.lineWidth = 1.5 * ratio;

  for (const u of users) {
    if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) {
      u.cursorX = 1e9;
      u.cursorY = 1e9;
      continue;
    }

    setPoint(tempPt, u.cursorX, u.cursorY);
    absoluteDocumentToDocuemnt(tempPt, drawing);
    documentToScreenPoint(tempPt, view);
    const cx = tempPt.x * ratio;
    const cy = tempPt.y * ratio;
    drawPointer(context, cx, cy, u.color, u.cursorAlpha);
  }

  context.lineWidth = 1;

  if (cursors === CursorsMode.PointerName || cursors === CursorsMode.PointerAvatarName || cursors === CursorsMode.PointerAvatar) { // name plates
    for (const u of users) {
      if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) continue;

      const avatarVideo = includeVideo ? options.videoForUser(u) : undefined;
      const canvas = getNamePlate(u, users, ratio, cursors, avatarVideo);
      setPoint(tempPt, u.cursorX, u.cursorY);
      absoluteDocumentToDocuemnt(tempPt, drawing);
      documentToScreenPoint(tempPt, view);
      const x = Math.round((tempPt.x + USER_NAME_OFFSET) * ratio);
      const y = Math.round((tempPt.y + USER_NAME_OFFSET) * ratio);
      context.globalAlpha = u.cursorAlpha;
      if (avatarVideo) {
        const cv = rectForCursorVideo({ x, y }, avatarVideo);
        context.drawImage(avatarVideo, cv.x, cv.y, cv.w, cv.h);
        context.drawImage(canvas, cv.x, cv.y + cv.h - USER_NAME_HEIGHT * ratio);
      } else {
        context.drawImage(canvas, x, y);
      }
    }
  }

  for (const u of users) {
    const color = u.color;
    if (isTextLayer(u.activeLayer) && u.activeLayer.textarea?.isFocused) {
      if (shouldCacheTextareaInLayer(u.activeLayer)) cacheTextareaInLayer(u.activeLayer);
      drawTextareaBoundaries(context, drawing, view, u.activeLayer.textarea, 2, color);
    }
  }

  context.globalAlpha = 1;
}

function drawCrosshair(context: CanvasRenderingContext2D, x: number, y: number, pixelRatio: number) {
  const GAP = 5 * pixelRatio;
  const SIZE = 3 * pixelRatio;
  context.lineWidth = 1 * pixelRatio;
  context.beginPath();
  context.moveTo(x, y - GAP - SIZE); context.lineTo(x, y - GAP); // top
  context.moveTo(x, y + GAP); context.lineTo(x, y + GAP + SIZE); // bottom
  context.moveTo(x - GAP - SIZE, y); context.lineTo(x - GAP, y); // left
  context.moveTo(x + GAP, y); context.lineTo(x + GAP + SIZE, y); // right
  context.stroke();
  context.lineWidth = 1;
}

function drawFilledCrosshair(context: CanvasRenderingContext2D, x: number, y: number, pixelRatio: number) {
  const LENGTH = 11 * pixelRatio; // length of arms
  const WIDTH = 1 * pixelRatio; // half width of each arm
  const t0 = y - WIDTH, t1 = y - WIDTH - LENGTH;
  const b0 = y + WIDTH, b1 = y + WIDTH + LENGTH;
  const l0 = x - WIDTH, l1 = x - WIDTH - LENGTH;
  const r0 = x + WIDTH, r1 = x + WIDTH + LENGTH;
  context.fillStyle = 'white';
  context.globalCompositeOperation = 'difference';
  context.beginPath();
  context.moveTo(l0, t0);
  context.lineTo(l0, t1); context.lineTo(r0, t1); context.lineTo(r0, t0); // top
  context.lineTo(r1, t0); context.lineTo(r1, b0); context.lineTo(r0, b0); // right
  context.lineTo(r0, b1); context.lineTo(l0, b1); context.lineTo(l0, b0); // bottom
  context.lineTo(l1, b0); context.lineTo(l1, t0); context.lineTo(l0, t0); // left
  context.closePath();
  context.fill();
  context.globalCompositeOperation = 'source-over';
}

function drawSyntheticCursor(context: CanvasRenderingContext2D, cx: number, cy: number, type: CursorType) {
  const index = FALLBACK_CURSORS.indexOf(type);
  if (index === -1) return;

  const image = getFallbackCursorsImage();
  if (!image) return;

  const pixelRatio = getPixelRatio();
  const size = FALLBACK_CURSOR_SISE;
  const offset = FALLBACK_CURSORS_OFFSETS[index];
  context.drawImage(image, index * 2 * size, 0, size, size, cx - offset.x * pixelRatio, cy - offset.y * pixelRatio, size * pixelRatio, size * pixelRatio);
}

function drawCursor(context: CanvasRenderingContext2D, cursor: Cursor, x: number, y: number, view: Viewport, settings: RendererSettings) {
  const color = '#808080';
  const glow = 'rgba(255, 255, 255, 0.5)';
  const MIN_NORMAL_SIZE = 5.0;
  const MIN_CIRCLE_SIZE = 2.5;

  if (!cursor.show) return;

  const skipCrosshair = settings.showCursor;
  const pixelRatio = getPixelRatio();
  x *= pixelRatio;
  y *= pixelRatio;

  switch (cursor.type) {
    case CursorType.Image:
    case CursorType.Circle: {
      const radius = Math.max(1, Math.round(cursor.size / 2)) * pixelRatio;
      context.save();

      context.strokeStyle = glow;
      context.fillStyle = glow;
      context.beginPath();
      context.arc(x, y, radius + 0.5, 0, Math.PI * 2, true);

      if (cursor.size >= MIN_CIRCLE_SIZE) {
        context.stroke();
      } else {
        context.fill();
      }

      context.strokeStyle = color;
      context.fillStyle = color;
      context.beginPath();
      context.arc(x, y, radius, 0, Math.PI * 2, true);

      if (cursor.size >= MIN_CIRCLE_SIZE) {
        context.stroke();
      } else {
        context.fill();
      }

      if (cursor.size < MIN_NORMAL_SIZE && !skipCrosshair) {
        drawCrosshair(context, x, y, pixelRatio);
      }

      context.restore();

      if (settings.showCursor && cursor.useSynthetic) {
        drawSyntheticCursor(context, x, y, CursorType.Default);
      }

      break;
    }
    case CursorType.Square: {
      const size = Math.max(1, Math.round(cursor.size)) * pixelRatio;
      const half = size / 2;

      if (view.rotation) {
        context.save();
        context.translate(x, y);
        context.rotate(-view.rotation);
        context.strokeStyle = 'rgba(255, 255, 255, 0.25)';
        context.strokeRect(-half - 1, -half - 1, size + 2, size + 2);
        context.strokeStyle = color;
        context.strokeRect(-half, -half, size, size);
        context.restore();
      } else {
        context.strokeStyle = 'rgba(255, 255, 255, 0.25)';
        context.strokeRect(round5(x - half) - 1, round5(y - half) - 1, size + 2, size + 2);
        context.strokeStyle = color;
        context.strokeRect(round5(x - half), round5(y - half), size, size);
      }

      if (cursor.size < MIN_NORMAL_SIZE && !skipCrosshair) {
        drawCrosshair(context, x, y, pixelRatio);
      }

      if (settings.showCursor && cursor.useSynthetic) {
        drawSyntheticCursor(context, x, y, CursorType.Default);
      }

      break;
    }
    case CursorType.Crosshair:
      drawFilledCrosshair(context, x, y, pixelRatio);
      break;
    default:
      drawSyntheticCursor(context, x, y, cursor.type);
      break;
  }
}

function drawActiveTool(context: Context, drawing: Drawing, user: User | undefined, view: Viewport) {
  const tool = user?.activeTool;

  if (!tool) return;

  switch (tool.id) {
    case ToolId.AI: {
      const t = tool as AiTool;
      drawAiSelectionPoly(context, drawing, t, AI_BOUNDING_BOX_COLOR_1_ACTIVE_STR, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, 1);
      break;
    }
    case ToolId.Selection:
    case ToolId.CircleSelection:
    case ToolId.LassoSelection: {
      const selectionTool = tool as (SelectionTool | CircleSelectionTool | LassoSelectionTool);

      if (!selectionTool.isPathEmpty()) {
        const ratio = getPixelRatio();

        context.save();
        context.setTransform(1, 0, 0, 1, 0, 0);

        selectionTool.drawPath(context, -drawing.x, -drawing.y);

        context.strokeStyle = '#fff';
        context.stroke();

        context.setLineDash([6 * ratio, 6 * ratio]);
        context.lineWidth = ratio;
        context.lineDashOffset = (Math.round(Date.now() / 100) % (12 * ratio)) + 0.5;
        context.strokeStyle = '#000';
        context.stroke();

        context.restore();
      }
      break;
    }
    case ToolId.RotateView: {
      const ratio = getPixelRatio();
      const size = 6 * ratio;
      const crosshair = size + 3;
      const x = round5(view.width * ratio / 2);
      const y = round5(view.height * ratio / 2);

      context.save();
      context.setTransform(1, 0, 0, 1, 0, 0);
      context.lineWidth = 1 * ratio;
      context.strokeStyle = '#808080';
      context.beginPath();
      context.arc(x, y, size, 0, Math.PI * 2);
      context.moveTo(x, y - crosshair);
      context.lineTo(x, y + crosshair);
      context.moveTo(x - crosshair, y);
      context.lineTo(x + crosshair, y);
      context.stroke();
      context.restore();
      break;
    }
    case ToolId.Text: {
      const textTool = tool as TextTool;
      if (textTool.mode === TextToolMode.Creating) {
        context.save();

        const ratio = getPixelRatio();
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.scale(ratio, ratio);

        const { r, g, b, a } = TEXTAREA_BOUNDARIES_COLOR;
        context.strokeStyle = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a * 255})`;
        context.lineWidth = TEXTAREA_HOVERED_BOUNDARIES_WIDTH;

        const toolView = textTool.editor.view;
        const mat = createViewportMatrix2d(tempMatrix2d, toolView);
        translateAbsoluteMatToDrawingMat2d(mat, drawing);
        context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

        let tmpRect = cloneRect(textTool.rect);
        if (textTool.textarea?.type === TextareaType.AutoWidth) {
          tmpRect.x += (textTool.textarea as AutoWidthTextarea).negativeOffsetForWidth;
        }

        context.strokeRect(tmpRect.x, tmpRect.y, tmpRect.w, tmpRect.h);

        context.restore();
      }
      break;
    }
    // case ToolId.Palette: {
    //   const { x, y, w, h } = (tool as PaletteTool).rect;

    //   context.save();
    //   context.setTransform(1, 0, 0, 1, 0, 0);
    //   context.strokeStyle = '#808080';
    //   context.beginPath();
    //   context.moveTo(x, y);
    //   context.lineTo(x + w, y);
    //   context.moveTo(x + w, y + h);
    //   context.lineTo(x, y + h);
    //   context.closePath();
    //   context.stroke();
    //   context.restore();
    //   break;
    // }
  }
}

function drawAiControl(context: Context, cx: number, cy: number, color: string, view: Viewport) {
  const size = 8 / view.scale;
  const x = Math.round(cx) - size / 2;
  const y = Math.round(cy) - size / 2;

  context.fillStyle = color;
  context.fillRect(x, y, size, size);

  context.fillStyle = `rgba(255, 255, 255, 1)`;
  context.fillRect(x + 1 / view.scale, y + 1 / view.scale, size - 2 / view.scale, size - 2 / view.scale);
}

function drawTransformControl(context: Context, x: number, y: number, offset: number) {
  const size = 6;
  context.rect(round5(x - size / 2) - offset, round5(y - size / 2) - offset, size + offset * 2, size + offset * 2);
}

function drawTransformAnchor(context: Context, x: number, y: number, offset: number) {
  const inner = 3 + offset;
  const outer = 6 + offset;
  context.moveTo(round5(x + inner), round5(y));
  context.arc(round5(x), round5(y), inner, 0, Math.PI * 2);
  context.moveTo(round5(x - inner), round5(y));
  context.lineTo(round5(x - outer), round5(y));
  context.moveTo(round5(x + inner), round5(y));
  context.lineTo(round5(x + outer), round5(y));
  context.moveTo(round5(x), round5(y - inner));
  context.lineTo(round5(x), round5(y - outer));
  context.moveTo(round5(x), round5(y + inner));
  context.lineTo(round5(x), round5(y + outer));
}

const strokes = [
  { offset: 1, color: '#fff' },
  { offset: 0, color: '#000' },
];

const tempMatrix2d = createMat2d();
const tempVec = createVec2();

const DRAW_TRANSFORM_REGIONS = false;

function drawTransform(context: Context, user: User, drawing: Drawing, view: Viewport) {
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);

  const bounds = getTransformBounds(user, drawing, mat);
  const pixelRatio = getPixelRatio();

  context.save();
  context.scale(pixelRatio, pixelRatio);

  for (const { color } of strokes) {
    context.beginPath();
    context.moveTo(round5(bounds[0][0]), round5(bounds[0][1]));
    context.lineTo(round5(bounds[1][0]), round5(bounds[1][1]));
    context.lineTo(round5(bounds[2][0]), round5(bounds[2][1]));
    context.lineTo(round5(bounds[3][0]), round5(bounds[3][1]));
    context.lineTo(round5(bounds[0][0]), round5(bounds[0][1]));
    context.closePath();
    context.strokeStyle = color;
    context.stroke();
    context.setLineDash([3, 3]);
  }

  context.setLineDash([]);

  getTransformOrigin(tempVec, bounds, user, mat);

  for (const { offset, color } of strokes) {
    context.beginPath();

    for (let i = 0; i < 4; i++) {
      const pt = bounds[i];
      drawTransformControl(context, pt[0], pt[1], offset);
      const pt2 = bounds[(i + 1) % 4];
      drawTransformControl(context, (pt[0] + pt2[0]) / 2, (pt[1] + pt2[1]) / 2, offset);
    }

    drawTransformAnchor(context, Math.round(tempVec[0]), Math.round(tempVec[1]), offset);
    context.strokeStyle = color;
    context.stroke();
  }

  if (DEVELOPMENT && DRAW_TRANSFORM_REGIONS) {
    context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);
    pickRegion(user, drawing, view, 0, 0, context);
  }

  context.restore();
}

function drawLine(context: Context, x1: number, y1: number, x2: number, y2: number) {
  context.beginPath();
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);
  context.stroke();
}

function drawCropOverlay(context: Context, view: Viewport, bounds: Rect, mode: CropOverlay, color: string) {
  const { x, y, w, h } = bounds;

  context.save();
  context.lineWidth = 1 / view.scale;
  context.strokeStyle = color;

  switch (mode) {
    case CropOverlay.Diagonal:
      drawLine(context, x, y, x + w, y + h);
      drawLine(context, x, y + h, x + w, y);
      break;
    case CropOverlay.RuleOfThirds:
      drawLine(context, x + w / 3, y, x + w / 3, y + h);
      drawLine(context, x + 2 * w / 3, y, x + 2 * w / 3, y + h);
      drawLine(context, x, y + h / 3, x + w, y + h / 3);
      drawLine(context, x, y + 2 * h / 3, x + w, y + 2 * h / 3);
      break;
    case CropOverlay.Disabled:
      break;
    default: invalidEnum(mode);
  }
  context.restore();
}

function drawIcon(context: Context, sprite: HTMLImageElement, mat: Mat2d, view: Viewport, cx: number, cy: number, icon: SpriteIcon, ox = 0, oy = 0) {
  const pixelRatio = getPixelRatio();
  tempVec[0] = cx;
  tempVec[1] = cy;
  transformVec2ByMat2d(tempVec, tempVec, mat);

  const { x, y, w, h } = spriteIcons[icon];
  const dx = (tempVec[0]) * pixelRatio;
  const dy = (tempVec[1]) * pixelRatio;
  context.save();
  context.translate(dx, dy);
  if (view.flipped) {
    context.scale(-1, 1);
    context.rotate(view.rotation);
  } else {
    context.rotate(-view.rotation);
  }
  context.translate(-dx, -dy);
  context.drawImage(sprite,
    x, y, w, h,
    dx + (ox * pixelRatio) - (w) * pixelRatio / (2 * SPRITE_SCALE), dy + (oy * pixelRatio) - (h) * pixelRatio / (2 * SPRITE_SCALE),
    w * pixelRatio / SPRITE_SCALE, h * pixelRatio / SPRITE_SCALE,
  );
  context.restore();
}


function drawAiBoundingBox(context: Context, view: Viewport, drawing: Drawing, options: DrawOptions) {
  const tool = options.selectedTool as AiTool;
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  const pixelRatio = getPixelRatio();
  const bounds = cloneRect(tool.bounds);
  absoluteDocumentToDocumentRect(bounds, drawing);
  const { w, h, x, y } = bounds;

  context.save();
  context.scale(pixelRatio, pixelRatio);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

  if (tool.showMask) {
    context.fillStyle = `rgba(0, 0, 0, ${AI_BOUNDING_BOX_MASK_ALPHA})`;
    context.fillRect(0, 0, x, drawing.h);
    context.fillRect(x + w, 0, drawing.w - w - x, drawing.h);
    context.fillRect(x, 0, w, y);
    context.fillRect(x, y + h, w, drawing.h - (y + h));
  }

  if (tool.isActive) {
    drawAnimatedFrame(context, view, bounds, AI_BOUNDING_BOX_COLOR_1_ACTIVE_STR, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, 2);
  } else {
    drawSolidFrame(context, view, bounds, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, WHITE_STR, 1);
  }

  if (!tool.isActive) {
    drawAiControl(context, x, y, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, view);
    drawAiControl(context, x, y + h, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, view);
    drawAiControl(context, x + w, y, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, view);
    drawAiControl(context, x + w, y + h, AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR, view);
  }

  context.restore();
}

function drawPerspectiveGridBoundingBox(context: Context, view: Viewport, drawing: Drawing, options: DrawOptions, data: PerspectiveGridLayerData) {
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  const pixelRatio = getPixelRatio();
  const tool = options.selectedTool?.id == ToolId.PerspectiveGrid ? (options.selectedTool as PerspectiveGridTool) : undefined;
  const boundingBoxState = tool?.boundingBoxState ?? PerspectiveGridBoundingBoxState.Default;

  context.save();
  context.scale(pixelRatio, pixelRatio);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);
  if (!isRectEmpty(data.bounds)) {
    const transform = tempMatrix2d;
    copyMat2d(tempMatrix2d, data.transform);
    if (boundingBoxState === PerspectiveGridBoundingBoxState.Default) {
      context.lineWidth = PERSPECTIVE_GRID_BB_LINE_WIDTH_DEFAULT / view.scale / pixelRatio;
    } else if (boundingBoxState === PerspectiveGridBoundingBoxState.Hover) {
      context.lineWidth = PERSPECTIVE_GRID_BB_LINE_WIDTH_HOVER / view.scale / pixelRatio;
    }
    let bounds = tempBoundsVec;
    rectToBounds(bounds, data.bounds);
    transformBounds(bounds, transform);
    drawTextareaBoundry(context, bounds, WHITE_STR);
    const pixelSize = 1 / view.scale / getPixelRatio();
    rectToBounds(bounds, data.bounds);
    transformBounds(bounds, transform);
    outsetBounds(bounds, pixelSize);
    drawTextareaBoundry(context, bounds, PERSPECTIVE_GRID_COLOR_STR_DEFAULT);
    if (tool !== undefined) {
      let bounds = tempBoundsVec;
      rectToBounds(bounds, data.bounds);
      transformBounds(bounds, transform);
      drawAiControl(context, bounds[0][0], bounds[0][1], PERSPECTIVE_GRID_COLOR_STR_DEFAULT, view);
      drawAiControl(context, bounds[1][0], bounds[1][1], PERSPECTIVE_GRID_COLOR_STR_DEFAULT, view);
      drawAiControl(context, bounds[2][0], bounds[2][1], PERSPECTIVE_GRID_COLOR_STR_DEFAULT, view);
      drawAiControl(context, bounds[3][0], bounds[3][1], PERSPECTIVE_GRID_COLOR_STR_DEFAULT, view);
    }
  }
  context.restore();
}

const TEXTAREA_SELECTION_RECT_COLOR_STR = `rgba(${TEXTAREA_SELECTION_RECT_COLOR.r * 255}, ${TEXTAREA_SELECTION_RECT_COLOR.g * 255}, ${TEXTAREA_SELECTION_RECT_COLOR.b * 255}, ${TEXTAREA_SELECTION_RECT_COLOR.a})`;

function drawTextarea(context: Context, drawing: Drawing, textarea: Textarea, view: Viewport, options: DrawOptions) {
  const pixelRatio = getPixelRatio();
  context.save();
  context.scale(pixelRatio, pixelRatio);

  if (options.selectedTool?.id === ToolId.Text) {
    const textTool = (options.selectedTool as TextTool);
    if (shouldRenderTextareaBoundaries(textarea, textTool, options)) drawTextareaBoundaries(context, drawing, view, textarea);
    if (shouldRenderTextareaCursor(textarea, textTool, options)) {
      const selection = textTool.textSelection;
      if (selection) {
        const { caretRect, selectionRects } = textarea.getCursorPosition(selection);
        if (caretRect) drawTextareaCaret(context, drawing, view, caretRect, textarea.transform);
        if (selectionRects.length > 0) drawTextareaSelectionRects(context, drawing, view, selectionRects, textarea.transform);
      }
    }
    if (shouldRenderTextareaBaselineIndicator(textarea, textTool, options)) drawTextareaBaselineIndicator(context, drawing, view, textarea);
    if (shouldRenderTextareaControlPoints(textarea, textTool, options)) drawTextareaControlPoints(context, drawing, view, textarea);
    if (shouldRenderTextareaOverflowIndicator(textarea, textTool, options)) drawTextareaOverflowIndicator(context, drawing, view, textarea);
  } else {
    if (options.selectedTool?.id !== ToolId.Transform) drawTextareaBoundaries(context, drawing, view, textarea, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH);
  }

  context.restore();
}
const TEXTAREA_BLUE_COLOR_STR = `rgba(${TEXTAREA_BOUNDARIES_COLOR.r * 255}, ${TEXTAREA_BOUNDARIES_COLOR.g * 255}, ${TEXTAREA_BOUNDARIES_COLOR.b * 255}, ${TEXTAREA_BOUNDARIES_COLOR.a * 255})`;
function drawTextareaBoundaries(context: Context, drawing: Drawing, view: Viewport, textarea: Textarea, forceBoundariesThickness?: number, color?: string) {
  const ratio = getPixelRatio();
  const pixelSize = 1 / view.scale / getPixelRatio();

  context.save();

  const mat = createViewportMatrix2d(tempMatrix2d, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  if (color !== undefined) {
    // if provided, we're drawing collaboration indicators, in order to avoid unnecesary save/restore calls, we'll apply it conditionally here
    context.scale(ratio, ratio);
  }
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

  const blueBounds = cloneBounds(textarea.bounds);
  const whiteBounds = cloneBounds(textarea.bounds);
  outsetBounds(whiteBounds, pixelSize);

  context.lineWidth = (forceBoundariesThickness ?? textarea.boundariesStrokeWidth) / view.scale / ratio;

  drawTextareaBoundry(context, whiteBounds, 'white');
  drawTextareaBoundry(context, blueBounds, color ?? TEXTAREA_BLUE_COLOR_STR);

  context.restore();
}
function drawTextareaBoundry(context: CanvasRenderingContext2D, bounds: Vec2[], color: string) {
  context.beginPath();
  context.moveTo(bounds[0][0], bounds[0][1]);
  context.lineTo(bounds[1][0], bounds[1][1]);
  context.lineTo(bounds[2][0], bounds[2][1]);
  context.lineTo(bounds[3][0], bounds[3][1]);
  context.lineTo(bounds[0][0], bounds[0][1]);
  context.closePath();
  context.strokeStyle = color;
  context.stroke();
}

function drawSolidFrame(context: Context, view: Viewport, r1: Rect, color1: string, color2: string, thickness: number, thickness2?: number) {
  const r2 = outsetRect(cloneRect(r1), (((thickness2 ?? thickness) + thickness) / 2) / view.scale);

  context.lineWidth = thickness / view.scale;

  context.strokeStyle = color1;
  context.strokeRect(r1.x, r1.y, r1.w, r1.h);

  context.lineWidth = (thickness2 ?? thickness) / view.scale;

  context.strokeStyle = color2;
  context.strokeRect(r2.x, r2.y, r2.w, r2.h);
}

function drawAnimatedFrame(context: Context, view: Viewport, rect: Rect, color1: string, color2: string, thickness: number) {
  context.lineWidth = thickness / view.scale;

  context.setLineDash([4 / view.scale, 4 / view.scale]);
  context.lineDashOffset = performance.now() / 20 / view.scale + 4 / view.scale;
  context.strokeStyle = color1;
  context.strokeRect(rect.x, rect.y, rect.w, rect.h);

  context.setLineDash([4 / view.scale, 4 / view.scale]);
  context.lineDashOffset = performance.now() / 20 / view.scale;
  context.strokeStyle = color2;
  context.strokeRect(rect.x, rect.y, rect.w, rect.h);
}

function drawAiSelectionPoly(context: Context, drawing: Drawing, t: AiTool, color1: string, color2: string, thickness: number) {
  if (!t.isPathEmpty()) {
    const pixelRatio = getPixelRatio();

    context.save();
    context.setTransform(1, 0, 0, 1, 0, 0);

    t.drawPath(context, -drawing.x, -drawing.y, true);

    context.lineWidth = thickness * pixelRatio;

    context.setLineDash([4 * pixelRatio, 4 * pixelRatio]);
    context.lineDashOffset = Math.floor(performance.now() / 20) + 4 * pixelRatio;
    context.strokeStyle = color1;
    context.stroke();

    context.setLineDash([4 * pixelRatio, 4 * pixelRatio]);
    context.lineDashOffset = Math.floor(performance.now() / 20);
    context.strokeStyle = color2;
    context.stroke();

    context.restore();
  }
}

function drawTextareaCaret(context: Context, drawing: Drawing, view: Viewport, caret: Rect, transform: Mat2d) {
  context.save();
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);
  const p1 = createVec2FromValues(caret.x + caret.w / 2, caret.y);
  const p2 = createVec2FromValues(caret.x + caret.w / 2, caret.y + caret.h);
  transformVec2ByMat2d(p1, p1, transform);
  transformVec2ByMat2d(p2, p2, transform);
  const thickness = clamp(distance(p1[0], p1[1], p2[0], p2[1]) * 0.05, MIN_TEXTAREA_CURSOR_WIDTH, MAX_TEXTAREA_CURSOR_WIDTH);
  context.strokeStyle = 'black';
  context.lineWidth = thickness;
  context.beginPath();
  context.moveTo(p1[0], p1[1]);
  context.lineTo(p2[0], p2[1]);
  context.closePath();
  context.stroke();
  context.restore();
}

function drawTextareaSelectionRects(context: Context, drawing: Drawing, view: Viewport, selectionRects: Rect[], transform: Mat2d) {
  context.save();
  const mat = createViewportMatrix2d(tempMatrix2d, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

  let rectBounds: Vec2[] = [];

  context.fillStyle = TEXTAREA_SELECTION_RECT_COLOR_STR;
  context.globalCompositeOperation = 'multiply';
  context.beginPath();
  for (const rect of selectionRects) {
    rectBounds = [createVec2(), createVec2(), createVec2(), createVec2()];
    rectToBounds(rectBounds, rect);
    transformBounds(rectBounds, transform);
    const [topLeft, topRight, bottomRight, bottomLeft] = rectBounds;
    context.moveTo(topLeft[0], topLeft[1]);
    context.lineTo(topRight[0], topRight[1]);
    context.lineTo(bottomRight[0], bottomRight[1]);
    context.lineTo(bottomLeft[0], bottomLeft[1]);
    context.lineTo(topLeft[0], topLeft[1]);
  }
  context.closePath();
  context.fill();
  context.restore();
}

function drawTextareaControlPoints(context: Context, drawing: Drawing, view: Viewport, textarea: Textarea) {
  for (const controlPoint of textarea.controlPoints) {
    const { blueRect, whiteRect, thickness } = controlPoint.getDrawingInstructions(view, -drawing.x, -drawing.y);

    context.fillStyle = 'white';
    context.fillRect(whiteRect.x, whiteRect.y, whiteRect.w, whiteRect.h);

    context.fillStyle = TEXTAREA_BLUE_COLOR_STR;
    context.fillRect(blueRect.x, blueRect.y, blueRect.w, blueRect.h);

    if (!controlPoint.active) {
      context.fillStyle = 'white';
      context.fillRect(blueRect.x + thickness, blueRect.y + thickness, blueRect.w - 2 * thickness, blueRect.h - 2 * thickness);
    }
  }
}

function drawTextareaBaselineIndicator(context: Context, drawing: Drawing, view: Viewport, textarea: Textarea) {
  const ratio = getPixelRatio();
  context.save();

  context.fillStyle = TEXTAREA_BLUE_COLOR_STR;
  context.strokeStyle = TEXTAREA_BLUE_COLOR_STR;
  context.lineWidth = round5((textarea.isHovering ? TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS : TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS) / view.scale / ratio);
  const baselineIndicator = textarea.getBaselineIndicator();

  const mat = createViewportMatrix2d(tempMatrix2d, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  context.transform(mat[0], mat[1], mat[2], mat[3], mat[4], mat[5]);

  for (const entry of baselineIndicator) {
    const { line, alignmentSquare } = entry;
    const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = line;
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.closePath();
    context.fill();
    context.stroke();
    if (alignmentSquare) {
      const squareSize = getBaselineIndicatorAlignmentSquareSize(view);
      context.fillRect(alignmentSquare.x - squareSize / 2, alignmentSquare.y - squareSize / 2, squareSize, squareSize);
    }
  }

  context.restore();
}

function drawTextareaOverflowIndicator(context: Context, drawing: Drawing, view: Viewport, textarea: Textarea) {
  const point = textarea.getOverflowIndicator();
  point.x -= drawing.x;
  point.y -= drawing.y;
  documentToScreenPoint(point, view);
  const { x, y } = point;

  const ratio = getPixelRatio();
  const size = TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE / ratio;

  context.fillStyle = 'white';
  context.fillRect(x - size / 2 - 2, y - size / 2 - 2, size + 4, size + 4);

  context.fillStyle = TEXTAREA_OVERFLOW_INDICATOR_RED_STR;
  context.fillRect(x - size / 2, y - size / 2, size, size);

  context.fillStyle = 'white';
  const plusThickness = size * TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS;
  const plusSize = size * TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE;
  context.fillRect(x - plusThickness / 2, y - plusSize / 2, plusThickness, plusSize);
  context.fillRect(x - plusSize / 2, y - plusThickness / 2, plusSize, plusThickness);
}

function drawCanvas(drawingContext: Context, renderingRect: Rect, canvas: Canvas | undefined, dirtyRect: Rect, textureX: number, textureY: number, layer: Layer, clip: boolean, noOpacity = false) {
  if (canvas && !isRectEmpty(dirtyRect)) {
    const globalCompositeOperation = drawingContext.globalCompositeOperation;
    const globalAlpha = drawingContext.globalAlpha;

    if (!clip && layer.mode !== 'normal') {
      drawingContext.globalCompositeOperation = getBlendMode(layer.mode);
    }
    drawingContext.globalAlpha = noOpacity ? 1 : layer.opacity;
    drawImage(drawingContext, canvas,
      dirtyRect.x - textureX, dirtyRect.y - textureY,
      dirtyRect.w, dirtyRect.h,
      dirtyRect.x - renderingRect.x, dirtyRect.y - renderingRect.y,
      dirtyRect.w, dirtyRect.h
    );
    drawingContext.globalAlpha = globalAlpha;
    drawingContext.globalCompositeOperation = globalCompositeOperation;
  }
}

function layerHasTool(layer: Layer) {
  const owner = layer.owner;

  return owner !== undefined &&
    owner.surface.layer === layer &&
    !isSurfaceEmpty(owner.surface) &&
    !!owner.surface.canvas;
}

function layerVisibleWithCanvasOrTool(layer: Layer) {
  return isLayerVisible(layer) && (!!layer.canvas || layerHasTool(layer) || (!!layer.textData && layer.textData.text !== ''));
}

function isClippingLayerWithVisibleClippedLayers(index: number, layers: Layer[]) {
  if (layers[index].clippingGroup) return false; // this is not clipping base

  // check if there is at least one visible clipped layer
  while (index > 0 && layers[index - 1].clippingGroup) {
    if (layerVisibleWithCanvasOrTool(layers[index - 1])) return true;
    index--;
  }

  return false;
}

export function fixLayerRect(layer: Layer, image: HTMLImageElement | ImageBitmap | BitmapData, errorReporter: IErrorReporter) {
  if (isRectEmpty(layer.rect)) {
    logActionInDebug(`Fixing layer rect (empty) (image: ${layer.image}, size: ${image.width}x${image.height})`);
    setRect(layer.rect, layer.rect.x, layer.rect.y, image.width, image.height);
  }

  if (!isIntegerRect(layer.rect)) {
    logActionInDebug(`Fixing layer rect (non-integer) (image: ${layer.image}, rect: ${rectToString(layer.rect)}, size: ${image.width}x${image.height})`);
    setRect(layer.rect, Math.round(layer.rect.x), Math.round(layer.rect.y), image.width, image.height);
  }

  if (!isTextLayer(layer) && (image.width !== layer.rect.w || image.height !== layer.rect.h)) {
    logActionInDebug(`Fixing layer rect (wrong size) (image: ${layer.image}, rect: ${rectToString(layer.rect)}, size: ${image.width}x${image.height})`);
    errorReporter.reportError(`Fixing layer rect (wrong size)`, undefined, {
      layerImage: layer.image,
      layerRect: { ...layer.rect },
      image: { type: image.constructor.name, width: image.width, height: image.height },
    });
    setRect(layer.rect, layer.rect.x, layer.rect.y, image.width, image.height);
  }
}

function drawLayerOnSurface(layer: Layer, surface: ToolSurface, selection?: Mask) {
  if (!layer.canvas) return;

  const context = getContext2d(surface.canvas!);
  const sx = surface.rect.x - layer.rect.x;
  const sy = surface.rect.y - layer.rect.y;
  drawImage(context, layer.canvas, 0, 0, layer.rect.w, layer.rect.h, -sx, -sy, layer.rect.w, layer.rect.h);

  if (selection) {
    context.globalCompositeOperation = 'destination-in';
    fillMask(context, selection, -surface.rect.x, -surface.rect.y);
    context.globalCompositeOperation = 'source-over';
  }
}

function cutSelectionFromLayer(layer: Layer, selection: Mask) {
  if (!layer.canvas) return;
  const tx = layer.rect.x;
  const ty = layer.rect.y;
  const context2 = getContext2d(layer.canvas);
  context2.globalCompositeOperation = 'destination-out';
  fillMask(context2, selection, -tx, -ty);
  context2.globalCompositeOperation = 'source-over';
  cutMaskFromRect(layer.rect, selection);
}

function drawShapeIcon(context: CanvasRenderingContext2D, icon: ShapePath, x: number, y: number, width: number, height: number, color: string) {
  context.save();
  context.beginPath();
  context.translate(x, y);
  context.scale(width / icon.width, height / icon.height);
  context.fillStyle = color;
  fillPath(context, icon);
  context.restore();
}

function polyfillRoundRect(context: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, radius = 2) {
  context.beginPath();
  context.moveTo(x + radius, y);
  context.lineTo(x + w - radius, y);
  context.quadraticCurveTo(x + w, y, x + w, y + radius);
  context.lineTo(x + w, y + h - radius);
  context.quadraticCurveTo(x + w, y + h, x + w - radius, y + h);
  context.lineTo(x + radius, y + h);
  context.quadraticCurveTo(x, y + h, x, y + h - radius);
  context.lineTo(x, y + radius);
  context.quadraticCurveTo(x, y, x + radius, y);
  context.closePath();
}

export function drawCropLabel(context: CanvasRenderingContext2D, bounds: Rect, tool: CropTool, pixelRatio: number, x = 0, y = 0) {
  context.scale(pixelRatio, pixelRatio);

  context.save();
  context.translate(x, y);

  const state = tool.state();
  const textMargin = state !== CropToolState.Default ? CROP_LABEL_ICON_WIDTH : 0;
  const labelWidth = CROP_LABEL_WIDTH + textMargin;

  context.beginPath();
  context.fillStyle = state === CropToolState.Error ? CROP_LABEL_ERROR_COLOR_STR : 'black';
  if (typeof context.roundRect === 'function') {
    context.roundRect(0, 0, labelWidth, CROP_LABEL_HEIGTH, 2 * pixelRatio);
  } else {
    polyfillRoundRect(context, 0, 0, labelWidth, CROP_LABEL_HEIGTH, 2 * pixelRatio);
  }
  context.fill();

  if (state === CropToolState.Upsell) {
    drawShapeIcon(context, tool.toolBlazeIconPaths, 10, CROP_LABEL_HEIGTH / 2 - 9, 11, 16, CROP_LABEL_BLAZE_COLOR_STR);
  } else if (state === CropToolState.Error) {
    drawShapeIcon(context, tool.toolWarningIconPaths, 8, CROP_LABEL_HEIGTH / 2 - 7, 16, 14, 'white');
  }

  if (!TESTS) {
    context.fillStyle = 'white';
    context.font = `12px Montserrat`;
    context.textAlign = 'center';
    context.fillText(`W: ${bounds.w}px`, textMargin + CROP_LABEL_WIDTH / 2, 16);
    context.fillText(`H: ${bounds.h}px`, textMargin + CROP_LABEL_WIDTH / 2, 32);
  }
  context.restore();
}

export function drawLayerBounds(context: Context, drawing: Drawing, view: Viewport) {
  const ratio = getPixelRatio();

  context.save();
  applyViewportTransform(context, view, ratio);

  context.beginPath();
  context.lineWidth = 1 / view.scale;
  context.strokeStyle = 'black';

  for (const layer of drawing.layers) {
    if (!isLayerVisible(layer)) continue;

    if (layer.owner?.surface && layer.owner?.surface.layer?.id === layer.id && !isSurfaceEmpty(layer.owner?.surface)) {
      const bounds = getSurfaceBounds(layer.owner.surface);
      addRect(bounds, layer.rect);
      if (!isRectEmpty(bounds) && !haveNonEmptyIntersection(drawing, bounds)) {
        context.rect(bounds.x - drawing.x, bounds.y - drawing.y, bounds.w, bounds.h);
      }
    } else {
      if (!isRectEmpty(layer.rect) && !haveNonEmptyIntersection(drawing, layer.rect)) {
        context.rect(layer.rect.x - drawing.x, layer.rect.y - drawing.y, layer.rect.w, layer.rect.h);
      }
    }
  }

  context.stroke();
  context.restore();
}
