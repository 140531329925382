import { logAction } from '../common/actionLog';
import { EditorInabilityStateId, enterEditorInabilityState, exitEditorInabilityState } from '../common/analytics';
import { readBufferedEncoderBuffer } from '../common/compressor';
import { DATA_SEND_LIMIT } from '../common/constants';
import { Drawing, DrawingType, IServer, IToolEditor, User } from '../common/interfaces';
import { deferred, parallel } from '../common/promiseUtils';
import { toolIdToString } from '../common/toolIdUtils';
import { getLastToolSource } from '../common/toolUtils';
import { createAndInitUserWithHistory, releaseToolRenderingContext, releaseUser } from '../common/user';
import { cloneBuffer, findByLocalId } from '../common/utils';
import { Editor } from './editor';
import { Mutable } from '../common/typescript-utils';

export async function waitForToolPromise(drawing: Drawing) {
  while (drawing.toolPromise && isConnected(drawing)) {
    await drawing.toolPromise;
  }
}

export function isConnected(drawing: Drawing) {
  return !!drawing.socket?.initialized;
}

export function cancelLoading(drawing: Drawing) {
  drawing.loadingPromise?.cancel();
  drawing.loadingPromise = undefined;
}

export function setLoaded(value: number, drawing: Drawing, editor: Editor | undefined) {
  if (editor && editor.drawing === drawing && value !== drawing.loaded) {
    if (value !== 1) {
      const connLoadedValue = value === 0 || value === 1 ? value : '0.5';
      enterEditorInabilityState(editor, EditorInabilityStateId.Loading, `conn.loaded=${connLoadedValue}`);
    } else {
      exitEditorInabilityState(editor, EditorInabilityStateId.Loading, false);
    }
  }

  (drawing as Mutable<Drawing>).loaded = value;
}

// Queued packet sending

export async function sendTo(drawing: Drawing, action: (server: IServer) => void, name: string, t?: number): Promise<void> {
  if (drawing.sending) {
    logAction(`[local] sendTo (queue action, ${name}, t: ${t || '-'})`);
    const defer = deferred();
    drawing.sendingQueue.push({ action, defer });
    await defer.promise;
  } else {
    action(drawing.socket!.socket.server);
  }
}

export async function sendToPromise<T>(drawing: Drawing, action: (server: IServer) => Promise<T>): Promise<T> {
  if (drawing.sending) {
    logAction(`[local] sendToPromise (queue action)`);
    const defer = deferred<T>();
    drawing.sendingQueue.push({ action, defer });
    return defer.promise;
  } else {
    return await action(drawing.socket!.socket.server);
  }
}

export async function sendToAsync<T>(drawing: Drawing, action: (server: IServer) => Promise<T>): Promise<T> {
  if (drawing.sending) {
    logAction(`[local] sendToAsync (queue action)`);
    const defer = deferred<T>();
    drawing.sendingQueue.push({ action, defer });
    return defer.promise;
  } else {
    drawing.sending = true;

    try {
      return await action(drawing.socket!.socket.server);
    } finally {
      setTimeout(() => commitQueuedSends(drawing), 0);
    }
  }
}

async function commitQueuedSends(drawing: Drawing) {
  if (drawing.sendingQueue.length) {
    logAction(`[local] commitQueuedSends (${drawing.sendingQueue.length})`);
  }

  while (drawing.sendingQueue.length) {
    const { action, defer } = drawing.sendingQueue.shift()!;

    try {
      defer.resolve(await action(drawing.socket!.socket.server));
    } catch (e) {
      defer.reject(e);
    }
  }

  drawing.sending = false;
}

export function clearQueuedSends(drawing: Drawing) {
  drawing.sendingQueue.length = 0;
  drawing.sending = false;
  drawing.clearSendingChunksId++;
}

export async function sendDataInChunks(drawing: Drawing, data: Uint8Array, onProgress: (progress: number) => void = () => { }) {
  const id = ++drawing.dataId;
  const chunkSize = DATA_SEND_LIMIT;
  const chunks: { offset: number; chunk: Uint8Array; }[] = [];
  const size = data.byteLength;
  const clearSendingChunksId = drawing.clearSendingChunksId;
  const connId = drawing.connId;

  for (let offset = 0; offset < size; offset += chunkSize) {
    const end = Math.min(size, offset + chunkSize);
    const chunk = data.subarray(offset, end);
    chunks.push({ offset, chunk });
  }

  let first = true;

  do {
    let progress = 0;
    if (!first) logAction(`[local] chunk restart`);
    first = false;

    try {
      await parallel(chunks, async ({ offset, chunk }) => {
        let resend = false;

        while (true) {
          if (!isConnected(drawing) || clearSendingChunksId !== drawing.clearSendingChunksId) throw new Error('Cancelled');

          const rand = (Math.random() * 0xffffffff) >>> 0;
          logAction(`[local] chunk ${offset}-${offset + chunk.byteLength} of ${size}${resend ? ' (re-send)' : ''}`);
          const result = await drawing.socket!.socket.server.dataChunk(connId, id, size, offset, chunk, rand);
          if (result.offset === offset && result.length === chunk.byteLength) break;
          resend = true;
        }

        progress++;
        onProgress(progress / chunks.length);
      }, 2);
    } catch (e) {
      // catch Disconnected error from dataChunk
      if (clearSendingChunksId !== drawing.clearSendingChunksId) throw new Error('Cancelled');
      throw e;
    }

    // TODO: remove throw if we remove dataChunksDone
    if (!isConnected(drawing) || clearSendingChunksId !== drawing.clearSendingChunksId) throw new Error('Cancelled');
    // TODO: do we need this anymore
    // re-try if failed to send data
  } while (!await drawing.socket!.socket.server.dataChunksDone());

  if (clearSendingChunksId !== drawing.clearSendingChunksId) throw new Error('Cancelled');

  return id;
}

export function getUserByUniqId(drawing: Drawing, uniqId: string, inSequence: boolean): User | undefined {
  if (drawing.user.uniqId === uniqId) {
    return drawing.user;
  }

  for (const user of drawing.users) {
    if (user.uniqId == uniqId) {
      return user;
    }
  }

  if (inSequence && drawing.sequence) {
    for (const sequenceDrawing of drawing.sequence) {
      for (const user of sequenceDrawing.users) {
        if (user.uniqId == uniqId) {
          return user;
        }
      }
    }
  }

  return undefined;
}

export function getUserIdsByUniqId(drawing: Drawing, user: User, inSequence: boolean): string[] {
  const userIds: string[] = [user.uniqId];

  for (const u of drawing.users) {
    if (u.name == user.name) {
      userIds.push(u.uniqId);
    }
  }

  if (inSequence && drawing.sequence) {
    for (const sequenceDrawing of drawing.sequence) {
      for (const inDrawingUser of sequenceDrawing.users) {
        if (inDrawingUser.name == user.name) {
          userIds.push(inDrawingUser.uniqId);
        }
      }
    }
  }

  return userIds;
}

export function getUserByLocalId(drawing: Drawing, localId: number): User | undefined {
  if (drawing.user.localId === localId) {
    return drawing.user;
  } else {
    return findByLocalId(drawing.users, localId);
  }
}

export function getUserOrTemp(drawing: Drawing, localId: number, editor: IToolEditor): User | undefined {
  if (localId === 0) return undefined;

  let user = getUserByLocalId(drawing, localId) || findByLocalId(drawing.tempUsers, localId);

  if (!user) {
    user = createAndInitUserWithHistory('', localId, undefined, editor, drawing);
    drawing.tempUsers.push(user);
  }

  return user;
}

function releaseUsers(users: User[], editor: Editor | undefined) {
  for (const user of users) {
    if (user.surface.context) releaseToolRenderingContext(user);
    releaseUser(user, editor);
  }
}

export function clearAllUsers(drawing: Drawing, editor: Editor | undefined) {
  releaseUsers(drawing.users, editor);
  releaseUsers(drawing.tempUsers, editor);
  drawing.users = [];
  drawing.tempUsers = [];
}

export function getTrackingIdsForAll(drawings: Drawing[]) {
  return drawings.map(getTrackingIds).flat().sort();
}

export function getTrackingIds(drawing: Drawing): string[] {
  if (drawing.type === DrawingType.Board) {
    return drawing.layers.filter(l => l.ref?.id).map(l => l.ref!.id!);
  } else if (drawing.sequence) {
    return drawing.sequence.map(d => d.id);
  } else {
    return [];
  }
}

export function sendLayerOrder(user: User, drawing: Drawing) {
  sendReorderLayers(user, drawing, drawing.layers.map(l => l.id));
}

export function sendReorderLayers(user: User, drawing: Drawing, order: number[]) {
  if (user !== drawing.user) return;

  drawing.lastReorder = [...order];

  void sendTo(drawing, s => {
    logAction(`[local] > reorderLayers`);
    s.reorderLayers(drawing.connId, order);
  }, 'reorderLayers');
}

export function sendResizeDrawing(user: User, drawing: Drawing, x: number, y: number, w: number, h: number) {
  if (user !== drawing.user) return;

  void sendTo(drawing, s => {
    logAction(`[local] > resizeDrawing from: x:${drawing.x} y:${drawing.y} ${drawing.w}x${drawing.h} to x:${x} y:${y} ${w}x${h}`);
    s.resizeDrawing(drawing.connId, x, y, w, h);
  }, 'resizeDrawing');
}

export function sendSelectLayer(user: User, drawing: Drawing, layerId: number) {
  if (user !== drawing.user) return;

  void sendTo(drawing, s => {
    logAction(`[local] > selectLayer`);
    s.selectLayer(drawing.connId, layerId);
  }, 'selectLayer');
}

export async function sendOwnLayer(user: User, drawing: Drawing, layerId: number) {
  if (user !== drawing.user) return;

  const source = getLastToolSource();
  return sendToPromise(drawing, s => s.ownLayer(drawing.connId, layerId, source));
}

export async function sendDisownLayer(user: User, drawing: Drawing, layerId: number) {
  if (user !== drawing.user) return;

  const source = getLastToolSource();
  return sendToPromise(drawing, s => s.disownLayer(drawing.connId, layerId, source));
}

export function flushStartTool(drawing: Drawing) {
  drawing.hasBufferedStart = false;
  const tool = drawing.startTool!;
  const layerId = drawing.startLayerId;
  const connId = drawing.startConnId;
  const x = drawing.startX;
  const y = drawing.startY;
  const p = drawing.startP;
  void sendTo(drawing, s => {
    logAction(`[local] > startTool: ${toolIdToString(tool.id)} (layerId: ${layerId}, t: ${tool.t}|${tool.s})`);
    s.startTool(connId, layerId, tool, x, y, p);
  }, 'startTool', tool.t);
}

export function flushTool(drawing: Drawing) {
  if (isConnected(drawing)) {
    if (drawing.hasBufferedStart) {
      flushStartTool(drawing);
    }

    if (drawing.encoder.offset) {
      // TODO: wait here, clone buffer only if sending asynchronously
      const data = cloneBuffer(readBufferedEncoderBuffer(drawing.encoder));
      void sendTo(drawing, s => s.nextToolArray(drawing.connId, data), 'nextToolArray');
      drawing.nextToolFlushTime = 0;
    }
  }
}
