import type Stripe from 'stripe';
import { firstValueFrom, OperatorFunction } from 'rxjs';
import { BillingData, BillingInterval, Participant, PERMISSION_DATA_MAP, RoleType, ScheduledTeamBillingUpdate, TeamMemberUserData, TeamRole, UpdateBillingItem, UserData } from 'shared/interfaces';
import { ID } from 'server/dao/types';
import { UserPresence } from 'shared/rpc-interface';
import { CC_PRODUCT_EDITOR, CC_PRODUCT_REVIEWER, CC_PRODUCT_VIEWER, getSubscriptionPlanByEnum, TEAM_PRODUCTS } from './billing';
import { storageGetItem, storageRemoveItem, storageSetItem } from 'magma/services/storage';
import { Observable } from 'rxjs/internal/Observable';
import { EntityType, Permission, PERMISSION_LIST, ShareType, SubscriptionPlan, Feature } from 'magma/common/interfaces';
import { catchError } from 'rxjs/operators';
import { getThumbPath } from 'magma/common/clientUtils';
import { hasFlag, hasFlags } from 'magma/common/utils';
import { invalidEnumReturn, includes, contains } from 'magma/common/baseUtils';
import { ProjectShareTypes } from './constants';
import { ObjectId } from 'mongodb';
import type { Nullable } from 'magma/common/typescript-utils';
import { EXTRA_STORAGE_ADS_REWARD, RESERVED_TAGS, USER_NAME_LENGTH_LIMIT } from 'magma/common/constants';

export interface DocumentForProStatus {
  forcePro?: boolean;
  forceProUntil?: Date;
  billing?: BillingData;
}

export const SLUG_REGEX = /^[a-z0-9_-]+$/;
export const REMOVED_ENTITY_NAME = 'Drawing removed';

export function createThumbPath(id: string, cacheId?: string, password?: string) {
  return getThumbPath(id, cacheId, password);
}

export function updateParticipantsFromPresence(participants: Participant[], usersPresence: UserPresence[] | undefined) {
  const presence = new Map(usersPresence?.map(userPresence => [userPresence.user._id, userPresence]));

  for (const participant of participants) {
    participant.isOnline = presence.has(participant._id);
    presence.delete(participant._id);
  }

  for (const [_, userPresence] of presence) {
    const { _id, name, userType, avatar } = userPresence.user;
    participants.push({ _id, name, userType, avatar, isOnline: true, role: undefined });
  }
}

export function isValidSlug(value: string) {
  return SLUG_REGEX.test(value);
}

export function asBool(value: string | undefined) {
  return !!(value && value !== '0' && value.toLowerCase() !== 'false');
}

// use last 3 hex digits from _id
export function getAnonymousNumber(_id: string | ObjectId) {
  return parseInt(_id.toString().substring(21), 16);
}

// @ignore-translation
export function getSubscriptionStatus(billing: BillingData | undefined, forcePro: SubscriptionPlan | undefined, forceProUntil: Date | undefined) {
  const isSubscriptionExpired = billing?.expiry?.isExpired;
  const hasSubscription = !!billing?.stripe?.subscriptionId;
  const hasTimedPro = (!!forceProUntil && forceProUntil.getTime() > Date.now());

  let cancelAtPeriodEnd = !!billing?.expiry?.cancelAtPeriodEnd;
  let currentPeriodEnd = billing?.validUntil?.getTime() || 0;
  let status: Stripe.Subscription.Status = 'incomplete';

  if (forcePro) {
    status = 'active';
  } else if (hasTimedPro) {
    status = 'trialing';
    currentPeriodEnd = forceProUntil!.getTime();
    cancelAtPeriodEnd = cancelAtPeriodEnd || !hasSubscription;
  } else if (isSubscriptionExpired) {
    status = 'incomplete_expired';
  } else if (billing?.payment?.lastPaymentStatus === 'failed') {
    status = 'unpaid';
  } else if (billing?.trialing) {
    status = 'trialing';
  } else if (hasSubscription && !isSubscriptionExpired && cancelAtPeriodEnd) {
    status = 'canceled';
  } else if (hasSubscription) {
    status = 'active';
  }

  const hasPro = status === 'active' || status === 'trialing' || status === 'canceled';

  let proState = '';

  if (hasSubscription && status === 'active') {
    proState = 'active';
  } else if (hasSubscription && status === 'trialing') {
    proState = 'trialing';
  } else if (hasSubscription && status === 'incomplete_expired') {
    proState = 'expired';
  } else if (hasSubscription && status === 'unpaid') {
    proState = 'unpaid';
  } else if (forcePro || hasTimedPro) {
    proState = 'forced';
  }

  return { status, cancelAtPeriodEnd, currentPeriodEnd, hasPro, proState };
}

export function getTypeName(type: EntityType) {
  switch (type) {
    case EntityType.Folder: return 'folder';
    case EntityType.Drawing: return 'artwork';
    case EntityType.Flowchart: return 'flowchart';
    case EntityType.Board: return 'board';
    default: return invalidEnumReturn(type, 'Unknown type');
  }
}

export const handleHttpError = <T>(): OperatorFunction<T, T> => catchError(e => {
  if (e.error) {
    const error = new Error(e.error?.message || e?.message || 'Error occured');
    (error as any).userError = true;
    (error as any).status = e.status;
    throw error;
  } else {
    throw e;
  }
});

export function toPromise<T>(response: Observable<T>): Promise<T> {
  return firstValueFrom(response.pipe(handleHttpError()));
}

export function saveLastProject(teamSlug: string, projectId: string) {
  storageSetItem(`last-project:${teamSlug}`, projectId);
}

export function getLastProject(teamSlug: string) {
  return storageGetItem(`last-project:${teamSlug}`);
}

export function removeLastProject(teamSlug: string) {
  return storageRemoveItem(`last-project:${teamSlug}`);
}

// route helpers

export function routeToTeam(teamSlug: string, subPage?: 'billing' | 'recent' | 'settings' | 'no-access' | 'reauthenticate' | ['settings', 'members'] | ['settings', 'billing']) {
  return subPage ? ['/s', teamSlug, subPage].flat() : ['/s', teamSlug];
}

export function pathToTeam(teamSlug: string, subPage?: 'billing') {
  return routeToTeam(teamSlug, subPage).join('/');
}

export function generateCacheId(entityCacheId: string | undefined, teamCacheId: string | undefined) {
  return `${entityCacheId || ''}${teamCacheId ? `-${teamCacheId}` : ''}`;
}

export function routeToArtJams(subPage?: 'live' | 'browse' | 'joined' | 'my' | 'archived') {
  return subPage ? ['/art-jams', subPage] : ['/art-jams'];
}

export const enumFromKey = <T>(enumType: T) => (key: string) =>
  enumType[key as keyof typeof enumType];

export function mergePermissionFlags(flags: number[], toBeMerged: number[]) {
  if (flags.length > toBeMerged.length) {
    for (let i = 0; i < flags.length; i++) {
      flags[i] = flags[i] | toBeMerged[i];
    }
  } else {
    for (let i = 0; i < toBeMerged.length; i++) {
      flags[i] = toBeMerged[i] | flags[i];
    }
  }
}

export function hasPermissionFlag(
  permissionFlag: Permission,
  roles: TeamRole[],
  entity: { _id?: ID, shareType?: ShareType } | undefined | null, // _id? to match EntityDocument type
  project: { _id?: ID, shareType?: ProjectShareTypes } | undefined | null, // _id? to match ProjectDocument type
) {
  if (roles.length === 0) return false;
  const blockedRole = roles.find(r => r.type === RoleType.Blocked);
  if (blockedRole) {
    roles = [blockedRole];
  }

  let rolesFlags: number[] = [];
  const entityId = entity?._id?.toString();
  const projectId = project?._id?.toString();
  for (const r of roles) {
    if (r.entities?.length || r.projects?.length) {
      if (r.entities && entityId && r.entities.includes(entityId) && hasFlag(permissionFlag, r.flags)) {
        return true;
      }
      if (r.projects && projectId && r.projects.includes(projectId) && hasFlag(permissionFlag, r.flags)) {
        return true;
      }
    } else {
      mergePermissionFlags(rolesFlags, r.flags);
    }
  }

  if (project?.shareType === ProjectShareTypes.RESTRICTED_TO_SPECIFIC_PEOPLE
    || entity?.shareType === ShareType.RESTRICTED_TO_SPECIFIC_PEOPLE) {
    return hasFlags([permissionFlag, Permission.CanSeeRestrictedProjectsAndEntities], rolesFlags);
  } else {
    return hasFlag(permissionFlag, rolesFlags);
  }
}

export function hasPermissionFlags(
  permissionFlags: Permission[],
  roles: TeamRole[],
  entity: { _id?: ID, shareType?: ShareType } | undefined | null, // _id? to match EntityDocument type
  project: { _id?: ID, shareType?: ProjectShareTypes } | undefined | null, // _id? to match ProjectDocument type
) {
  const flags = [...permissionFlags];

  if (roles.length === 0) return false;

  const blockedRole = roles.find(r => r.type === RoleType.Blocked);
  if (blockedRole) {
    roles = [blockedRole];
  }

  let rolesFlags: number[] = [];
  const entityId = entity?._id?.toString();
  const projectId = project?._id?.toString();

  for (const r of roles) {
    if (r.entities?.length || r.projects?.length) {
      if (r.entities && entityId && r.entities.includes(entityId) && hasFlags(flags, r.flags)) {
        return true;
      }
      if (r.projects && projectId && r.projects.includes(projectId) && hasFlags(flags, r.flags)) {
        return true;
      }
    } else {
      mergePermissionFlags(rolesFlags, r.flags);
    }
  }

  if (project?.shareType === ProjectShareTypes.RESTRICTED_TO_SPECIFIC_PEOPLE
    || entity?.shareType === ShareType.RESTRICTED_TO_SPECIFIC_PEOPLE) {
    flags.push(Permission.CanSeeRestrictedProjectsAndEntities);
  }

  return hasFlags(flags, rolesFlags);
}

export function getDaysDifference(from: number, to: number): number {
  if (from > to) return 0;
  return Math.round((to - from) / (1000 * 60 * 60 * 24));
}

export function getLowestPossibleProduct(requiredProduct: Set<string>) {
  if (requiredProduct.has(CC_PRODUCT_EDITOR)) {
    return CC_PRODUCT_EDITOR;
  } else if (requiredProduct.has(CC_PRODUCT_REVIEWER)) {
    return CC_PRODUCT_REVIEWER;
  } else if (requiredProduct.has(CC_PRODUCT_VIEWER)) {
    return CC_PRODUCT_VIEWER;
  }
  return null;
}

export function getMinimumProductRequired(role: { flags: Permission[] }) {
  const requiredProduct = new Set<string>();

  for (const permission of PERMISSION_LIST) {
    if (hasFlag(permission, role.flags)) {
      const product = PERMISSION_DATA_MAP.get(permission);
      if (product) {
        product.forEach(p => requiredProduct.add(p));
      }
    }
  }
  return getLowestPossibleProduct(requiredProduct);
}

export function getPlansRequiredForMembers(members: { roles: TeamRole[] }[]) {
  const plans = members.map(m => getPlanForMember(m));
  const productsNeeded = new Map<string, number>(); // stripeProductId: quantity

  for (const productId of TEAM_PRODUCTS.keys()) {
    const count = plans.reduce((count, plan) => count + (plan === productId ? 1 : 0), 0);
    if (count > 0) productsNeeded.set(productId, count);
  }
  return productsNeeded;
}

export function getPlansRequiredForMembersWithUser(members: { roles: TeamRole[], user: TeamMemberUserData }[]):
  Map<string, { count: number; users: TeamMemberUserData[]; }> {
  const plans = members.map(m => ({ plan: getSubscriptionPlanByEnum(SubscriptionPlan.Free).code, user: m.user }));
  const productsNeeded = new Map<string, { count: number, users: TeamMemberUserData[] }>(); // stripeProductId: user

  for (const productId of TEAM_PRODUCTS.keys()) {
    const count = plans.reduce((count, plan) => count + (plan.plan === productId ? 1 : 0), 0);
    if (count > 0) {
      productsNeeded.set(productId, { count, users: plans.filter(p => p.plan === productId).map(p => p.user) });
    }
  }
  return productsNeeded;
}

export function getPlanForMember(member: { roles: { flags: Permission[] }[] }) {
  const requiredProduct = new Set<string>();
  for (const role of member.roles) {
    const product = getMinimumProductRequired(role);
    if (product) {
      requiredProduct.add(product);
    }
  }
  return getLowestPossibleProduct(requiredProduct);
}

export function getBillingItems(billingData: BillingData, productsQuantity: Map<string, number>, billingInterval: BillingInterval) {
  const products = new Map(billingData.stripe?.items?.map(i => [i.product, i]));

  const items: UpdateBillingItem[] = [];
  for (const [productId, productData] of TEAM_PRODUCTS) {
    const item = products.get(productId);
    if (item) {
      items.push({
        id: item.id,
        product: productId,
        price: productData.stripePriceId[billingInterval],
        quantity: productsQuantity.get(productId) ?? 0
      });
    } else {
      const quantity = productsQuantity.get(productId) ?? 0;
      if (quantity) {
        items.push({ price: productData.stripePriceId[billingInterval], quantity, product: productId });
      }
    }
  }
  return items;
}

export function isSubscriptionActive(billingData: BillingData | undefined) {
  return !!billingData?.stripe?.subscriptionId && !isSubscriptionExpired(billingData);
}

export function isSubscriptionExpired(billingData: BillingData | undefined) {
  const isExpired = billingData?.expiry?.isExpired;
  return isExpired === undefined || isExpired === true;
}

export function isSubscriptionCancelled(billingData: BillingData | undefined) {
  return !!billingData?.expiry?.cancelAtPeriodEnd;
}

export function isSubscriptionPaymentFailed(billingData: BillingData | undefined) {
  return billingData?.payment?.lastPaymentStatus === 'failed';
}

export function userStorageLimit(user: { pro?: SubscriptionPlan; tags?: string[], optedIntoAds?: boolean }) {
  const extraStorage = (!user?.pro && user.tags?.includes(RESERVED_TAGS.adTest_BrushesAndStorage_ad) && !!user.optedIntoAds) ? EXTRA_STORAGE_ADS_REWARD : 0;
  return getSubscriptionPlanByEnum(user?.pro ?? SubscriptionPlan.Free).storageLimit + extraStorage;
}

export function getProductQuantity(items: UpdateBillingItem[], product: string): number {
  return items.reduce((sum, i) => i.product === product ? sum + i.quantity : sum, 0) ?? 0;
}

export function getNumberOfPurchasedSub(scheduledBillingUpdate?: ScheduledTeamBillingUpdate, billing?: BillingData, plan?: SubscriptionPlan): number {
  if (billing?.expiry?.isExpired) return 0;
  const planCode = getSubscriptionPlanByEnum(plan ?? SubscriptionPlan.Free).code;

  if (scheduledBillingUpdate) {
    return getProductQuantity(scheduledBillingUpdate.items ?? [], planCode);
  } else {
    return getProductQuantity(billing?.stripe?.items ?? [], planCode);
  }
}

export function hasInProSources(user: { proSources?: string[]; }, teamId: string) {
  return user.proSources?.some(s => s.startsWith(teamId));
}

export function slugifyUsername(name: string) {
  return name.trim().toLowerCase()
    .replace(/\s+/g, '-') // replace (possibly multiple consequent) whitespace characters with (single) dash
    .replace(/[^0-9a-z-_]/gi, '') // clear non-alphanumeric lowercase characters (dashes and underscores allowed)
    .replace(/^[0-9_-]+/, '') // clear illegal starting characters
    .replace(/^(-)*/, '')
    .replace(/(-)*$/, '')
    .substring(0, USER_NAME_LENGTH_LIMIT); // limit length
}

export function canChangeUsername(user: Nullable<Pick<UserData, 'pro' | 'isSuperAdmin' | 'featureFlags' | 'tags'>>) {
  if (!user) return false;
  if (user.isSuperAdmin) return true;
  if (!contains(user.featureFlags, Feature.AdjustUsername)) return false;
  if (user.pro) return true;
  return !contains(user.tags, RESERVED_TAGS.updatedUsername);
}
