import { AiToolPipeline, AiToolSetting, AiToolTab, Analytics, AnyImageType, CompositeOp, CursorType, Feature, hasAltKey, hasShiftKey, IHistory, ITool, IToolEditor, IToolModel, Layer, LayerFlag, Mask, PromptHistoryItem, QuickAction, Rect, StableDiffusionInput, TabletEvent, ToolId, UsageQuota, User, Viewport } from '../interfaces';
import { aiTool } from '../icons';
import { clearMask, cloneMask, createMask, fillMask, isMaskEmpty, transformMask } from '../mask';
import { createCanvas, getContext2d, loadImageOrImageDataFromData } from '../canvasUtils';
import { cloneDeep, random } from 'lodash';
import { addRect, cloneRect, copyRect, createRect, rectIncludesRect, setRect } from '../rect';
import { invalidEnumReturn, keys, randomString } from '../baseUtils';
import { redraw, redrawDrawing } from '../../services/editorUtils';
import { getLayerSafe, getNewLayerName } from '../drawing';
import { createTransform, setupSurface } from '../toolSurface';
import { layerFromState } from '../layer';
import { addLayerToDrawing, removeLayerFromDrawing } from '../layerToolHelpers';
import { Editor } from '../../services/editor';
import { createNewLayersInsecure } from '../../services/layerActions';
import { logAction } from '../actionLog';
import { pointInsideRegion } from './transformTool';
import { clamp } from '../mathUtils';
import { TASK_CANCELED_ERROR, UserFlowId } from '../constants';
import { storageGetBoolean, storageSetBoolean } from '../../services/storage';
import { isWebpSupported } from '../userAgentUtils';
import {
  AiUpdateValidationError, AiPasteToolData, AiCheckpointFileName, AiUpdateData, AiUpdateRequest,
  AiStatusReport, AiUpdateSaveResultToLayer, AiUpdateRemoveResult, AiModelName, AI_NUMBER_OF_RETRIES_AFTER_NSFW,
  AI_DEFAULT_MODEL, AI_OPTIMUM_SIZE_STEP, AI_DEFAULT_SAMPLER, AI_MODEL_DATA, AI_DEFAULT_SETTINGS, AiToolSettings,
  AI_MODELS_AVAILABLE_FOR_ALL_USERS, AiJob, AI_BOUNDING_BOX_WIDTH_MIN, AI_BOUNDING_BOX_HEIGHT_MIN, estimateGenerationTime,
  InpaintFill, AI_CONTROLNET_MODELS, AiControlNetModelName, AiControlNetModel, AiCheckpoint, AI_RENDER_TYPES, RenderType, AI_DEFAULT_RENDER_TYPE, RenderTypeId
} from '../aiInterfaces';
import { createMat2d } from '../mat2d';
import { createPolyRect, isPolyEmpty } from '../poly';
import { LassoSelectionBaseTool } from './lassoSelectionBaseTool';
import { intersection } from '../polyUtils';
import { finishTransform } from '../toolUtils';
import { throwIfTextLayer } from '../text/text-utils';
import { sendLayerOrder } from '../../services/drawingConnection';
import { createViewport } from '../create';
import { UserError } from '../userError';

const enum BoxControl {
  Other,
  TopLeft,
  TopRight,
  BottomRight,
  BottomLeft,
  Center
}

const cursors = new Map([
  [BoxControl.Other, CursorType.Move],
  [BoxControl.TopLeft, CursorType.ResizeTL],
  [BoxControl.TopRight, CursorType.ResizeTR],
  [BoxControl.BottomRight, CursorType.ResizeTL],
  [BoxControl.BottomLeft, CursorType.ResizeTR],
  [BoxControl.Center, CursorType.Crosshair],
]);

function roundToOptimalStep(val: number) {
  return Math.ceil(val / AI_OPTIMUM_SIZE_STEP) * AI_OPTIMUM_SIZE_STEP;
}

export class AiTool extends LassoSelectionBaseTool implements ITool {
  AI_RENDER_TYPES = AI_RENDER_TYPES;
  feature = Feature.Ai;
  id = ToolId.AI;
  name = 'AI';
  description = 'Render drafts and refine your ideas faster using AI Assistant';
  learnMore = 'https://help.magma.com/en/articles/6711598-beta-magma-ai-tutorial';
  video = { url: '/assets/videos/ai-inpaint.mp4', width: 764, height: 478 };
  icon = aiTool;
  cursor = CursorType.Move;
  nonDrawing = true;
  continuousRedraw = true;
  onlyPortal = true;
  extended = false;
  promptHeightStart = -1;
  hasBegan = false;
  onboardingTutorial = UserFlowId.AiChecklist;

  usageQuota: UsageQuota | undefined;

  get quota() {
    if (this.usageQuota?.total === 0) return 0;
    if (this.usageQuota) {
      const q = this.usageQuota.used * 100 / this.usageQuota.total;
      return q > 100 ? 100 : q;
    }
    return 0;
  }
  errorPrompt: AiUpdateValidationError | undefined;

  showAiAdvancedOptions = false;
  showAiPromptTooltip = !storageGetBoolean('tooltip-ai-firstTimePrompt');
  showAiResultTooltip = !storageGetBoolean('tooltip-ai-firstResultPrompt');

  bounds = createRect(100, 100, 512, 512);
  view = createViewport();
  startBounds = createRect(0, 0, 0, 0);
  startX = 0;
  startY = 0;
  boxMode = BoxControl.Other;
  showMask = true;
  usePixelPerfectMode = false;
  selectedRenderType = AI_DEFAULT_RENDER_TYPE;

  readonly preprocessorResultId = 0x7fffffff;

  results: Map<number, HTMLImageElement | ImageBitmap | ImageData> = new Map();
  nsfwResultIds: Set<number> = new Set();
  lastNsfwResultIds: Set<number> = new Set();
  thumbnails: Map<number, string> = new Map();
  resultRect: Rect = createRect(0, 0, 0, 0);
  resultTool: AiPasteToolData | undefined; // TODO use if for resultRect, resultLayerId
  resultLayerId = -1;
  request: StableDiffusionInput | undefined;
  totalResultCount = 0;
  selectedResultId = -1;
  retryCount = 0;

  addedLayerIds: number[] = [];

  sampler = AI_DEFAULT_SAMPLER;
  tiling = false;
  selectedModel: AiModelName = AI_DEFAULT_MODEL;

  forcedModel: AiModelName = 'Stable Diffusion 2.1';
  forcedUpscale = false;
  forcedCheckpoint: AiCheckpointFileName = AI_MODEL_DATA['Stable Diffusion 2.1'][0].file;
  forceModel = false;
  get checkpoints() {
    return AI_MODEL_DATA[this.forcedModel];
  }

  _tab: AiToolTab = 'create';
  pipeline: AiToolPipeline = 'create';

  set tab(tab: AiToolTab) {
    if (tab !== 'advanced') {
      this.pipeline = tab;
    }
    this._tab = tab;
  }

  get tab() {
    return this._tab;
  }

  get showSelection() {
    return this.pipeline === 'inpaint';
  }

  get isGenerating() {
    return !!this.currentTaskId;
  }

  get isActive() {
    return this.isGenerating || !!this.request || this.results.size > 0;
  }

  get hasPreprocessorResult() {
    return this.results.has(this.preprocessorResultId);
  }

  get selectedModelBase() {
    const selectedModel = this.forceModel ? this.forcedModel : this.selectedModel;
    const model = AI_MODEL_DATA[selectedModel];
    if (!model) throw new Error('Selected model is invalid');
    return model[0].base;
  }

  selectControlnetModel(controlnetModel: AiControlNetModel) {
    this.settings.controlnet_model = controlnetModel.modelName;
    this.settings.controlnet_preprocessor = controlnetModel.preprocessors[0];
  }

  controlnetModels() {
    const baseModel = this.selectedModelBase;
    return AI_CONTROLNET_MODELS.filter(m => m.baseModel === baseModel) ?? [];
  }

  controlnetPreprocessors(model: AiControlNetModelName | null) {
    return AI_CONTROLNET_MODELS.find(m => m.modelName === model)?.preprocessors ?? [];
  }

  get placeholders() {
    if (this.totalResultCount - this.resultIds.length > 0) {
      return Array(this.totalResultCount - this.resultIds.length);
    } else {
      return [];
    }
  }

  get resultIds() {
    return Array.from(this.results.keys()).filter(id => id !== this.preprocessorResultId);
  }

  isNegativePromptVisible = false;

  // settings
  useSeparateSettingsForEachMode = false;
  switchModeWhenApplyingSettings = true;
  toolSettings = cloneDeep(AI_DEFAULT_SETTINGS); // do not use it directly

  get settings() {
    if (!this.useSeparateSettingsForEachMode) return this.toolSettings['all'];
    return this.toolSettings[this.pipeline];
  }

  set settings(s: AiToolSettings) {
    if (!this.useSeparateSettingsForEachMode) {
      this.toolSettings['all'] = s;
    } else {
      this.toolSettings[this.pipeline] = s;
    }
  }

  resetSettingsToDefaults() {
    // @ignore-translate
    const modes: AiToolSetting[] = ['all', 'create', 'enhance', 'inpaint', 'outpaint'];
    modes.forEach((p: AiToolSetting) => {
      this.toolSettings[p] = {
        ...cloneDeep(AI_DEFAULT_SETTINGS[p]),
        // settings to keep when restoring default settings
        prompt: this.toolSettings[p].prompt,
        seed: this.toolSettings[p].seed
      };
    });
  }

  fields = keys<AiTool>(['tab', 'pipeline', 'toolSettings', 'useSeparateSettingsForEachMode', 'sampler', 'promptHeightStart', 'extended', 'bounds', 'showMask',
    'selectedModel', 'forceModel', 'forcedModel', 'forcedCheckpoint', 'forcedUpscale', 'showAiAdvancedOptions', 'showPromptHistory', 'switchModeWhenApplyingSettings', 'selectedRenderType']);

  currentTaskId: string | undefined;
  initialQueuePosition: number | undefined;
  progress: number | undefined;

  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model);
  }

  showPromptHistory = false;
  promptHistoryFullyFetched = false;
  promptHistoryHeaders: string[] = [];
  promptHistory: Map<string, PromptHistoryItem[]> = new Map(); // key is formatted date string
  promptHistoryLoading = false;

  inpaintMask = createMask();

  getSelection(): Mask {
    return this.inpaintMask;
  }

  appendPromptHistory(items: PromptHistoryItem[]) {
    this.promptHistoryLoading = false;
    if (items.length === 0) {
      this.promptHistoryFullyFetched = true;
    } else {
      for (const item of items) {
        const groupKey = new Date(item.createdAt).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
        if (!this.promptHistoryHeaders.includes(groupKey)) this.promptHistoryHeaders.push(groupKey);
        const list = this.promptHistory.get(groupKey) || [];
        list.push(item);
        this.promptHistory.set(groupKey, list);
      }
    }
  }

  get imageFormat() {
    return isWebpSupported ? 'image/webp' : 'image/png';
  }

  get enableSelectionTool() {
    return this.pipeline === 'inpaint';
  }

  get inpaintSettings() {
    if (!this.useSeparateSettingsForEachMode) {
      return this.toolSettings['all'];
    } else {
      return this.toolSettings['inpaint'];
    }
  }

  imageQuality = 95;

  private pickRegion(user: User, view: Viewport, x: number, y: number): BoxControl {
    const { surface } = user;
    const rect = this.bounds;

    const boxSize = 15 / view.scale;
    const boxOffset = 6 / view.scale;
    const boxSizeX = boxSize / Math.abs(surface.scaleX);
    const boxSizeY = boxSize / Math.abs(surface.scaleY);
    const boxOffsetX = boxOffset / Math.abs(surface.scaleX);
    const boxOffsetY = boxOffset / Math.abs(surface.scaleY);

    const l = rect.x;
    const r = rect.x + rect.w;
    const t = rect.y;
    const b = rect.y + rect.h;

    const l2 = l - boxSizeX;
    const r2 = r + boxSizeX;
    const t2 = t - boxSizeY;
    const b2 = b + boxSizeY;

    const l1 = l + boxOffsetX;
    const r1 = r - boxOffsetX;
    const t1 = t + boxOffsetY;
    const b1 = b - boxOffsetY;

    if (pointInsideRegion(x, y, l2, t2, l1, t2, l1, t1, l2, t1, undefined)) return BoxControl.TopLeft;
    if (pointInsideRegion(x, y, r1, t2, r2, t2, r2, t1, r1, t1, undefined)) return BoxControl.TopRight;
    if (pointInsideRegion(x, y, l2, b1, l1, b1, l1, b2, l2, b2, undefined)) return BoxControl.BottomLeft;
    if (pointInsideRegion(x, y, r1, b1, r2, b1, r2, b2, r1, b2, undefined)) return BoxControl.BottomRight;

    if (this.enableSelectionTool && pointInsideRegion(x, y, l, t, r, t, r, b, l, b, undefined)) return BoxControl.Center;

    return BoxControl.Other;
  }

  onSelect() {
    if (!this.usageQuota) {
      this.model.tryQuickAction(QuickAction.AiGetUsageQuota);
    }
  }

  start(x: number, y: number, pressure: number, e?: TabletEvent) {
    this.boxMode = this.pickRegion(this.model.user, this.editor.view, x, y);
    this.startX = Math.round(x);
    this.startY = Math.round(y);
    copyRect(this.startBounds, this.bounds);

    if (this.boxMode === BoxControl.Center) {
      this.startSelection(x, y, pressure, false, e);
    }
  }

  move(x: number, y: number, _pressure: number, e?: TabletEvent) {
    // block changing bounding box when generation is in progress
    if (this.isActive) return;

    if (this.boxMode === BoxControl.Center) {
      this.doMove(x, y);
      return;
    }

    let dx = Math.round(x - this.startX);
    let dy = Math.round(y - this.startY);

    const shiftKey = !!e && hasShiftKey(e);
    if (shiftKey && this.boxMode !== BoxControl.Other) {
      dx = dy = (dx + dy) / 2;
    }

    if (this.boxMode !== BoxControl.Other) {
      dx = roundToOptimalStep(dx);
      dy = roundToOptimalStep(dy);
    }

    const { drawing } = this.model;

    const minBoxWidth = Math.min(AI_BOUNDING_BOX_WIDTH_MIN, drawing.w);
    const minBoxHeight = Math.min(AI_BOUNDING_BOX_HEIGHT_MIN, drawing.h);

    // clamp dx
    if (this.boxMode === BoxControl.TopLeft || this.boxMode === BoxControl.BottomLeft) {
      const maxDx = this.startBounds.w - minBoxWidth;
      const minDx = drawing.x - this.startBounds.x;
      dx = clamp(dx, minDx, maxDx) | 0;
    } else if (this.boxMode === BoxControl.TopRight || this.boxMode === BoxControl.BottomRight) {
      const minDx = -(this.startBounds.w - minBoxWidth);
      const maxDx = drawing.x + drawing.w - (this.startBounds.w + this.startBounds.x);
      dx = clamp(dx, minDx, maxDx) | 0;
    }

    // clamp dy
    if (this.boxMode === BoxControl.TopLeft || this.boxMode === BoxControl.TopRight) {
      const maxDy = this.startBounds.h - minBoxHeight;
      const minDy = drawing.y - this.startBounds.y;
      dy = clamp(dy, minDy, maxDy) | 0;
    } else if (this.boxMode === BoxControl.BottomLeft || this.boxMode === BoxControl.BottomRight) {
      const minDy = -(this.startBounds.h - minBoxHeight);
      const maxDy = drawing.y + drawing.h - (this.startBounds.h + this.startBounds.y);
      dy = clamp(dy, minDy, maxDy) | 0;
    }

    if (this.boxMode === BoxControl.Other) {
      this.bounds.x = this.startBounds.x + dx;
      this.bounds.y = this.startBounds.y + dy;
      this.bounds.x = clamp(this.bounds.x, drawing.x, drawing.x + drawing.w - this.bounds.w);
      this.bounds.y = clamp(this.bounds.y, drawing.y, drawing.y + drawing.h - this.bounds.h);
    } else if (this.boxMode === BoxControl.TopLeft) {
      this.bounds.x = this.startBounds.x + dx;
      this.bounds.w = this.startBounds.w - dx;

      this.bounds.y = this.startBounds.y + dy;
      this.bounds.h = this.startBounds.h - dy;
    } else if (this.boxMode === BoxControl.TopRight) {
      this.bounds.w = this.startBounds.w + dx;

      this.bounds.y = this.startBounds.y + dy;
      this.bounds.h = this.startBounds.h - dy;
    } else if (this.boxMode === BoxControl.BottomLeft) {
      this.bounds.x = this.startBounds.x + dx;
      this.bounds.w = this.startBounds.w - dx;

      this.bounds.h = this.startBounds.h + dy;
    } else if (this.boxMode === BoxControl.BottomRight) {
      this.bounds.w = this.startBounds.w + dx;
      this.bounds.h = this.startBounds.h + dy;
    }
  }

  end(x: number, y: number, pressure: number) {
    if (this.boxMode === BoxControl.Center) {
      this.endSelection(x, y, pressure, false);
    } else {
      this.move(x, y, pressure);
    }
  }

  hover(x: number, y: number, e: TabletEvent) {
    const { user, drawing } = this.model;
    const region = this.pickRegion(user, this.editor.view, x + drawing.x, y + drawing.y);
    if (this.isActive) {
      this.cursor = CursorType.Default;
    } else if (region === BoxControl.Center && !isMaskEmpty(this.inpaintMask) && (hasAltKey(e) || hasShiftKey(e))) {
      this.cursor = hasAltKey(e) ? CursorType.SelectionSubtract : CursorType.SelectionAdd;
    } else {
      this.cursor = cursors.get(region) ?? CursorType.Move;
    }
  }

  begin(data: AiPasteToolData, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] ai begin (layerId: ${data.layer.id}, rect: ${data.rect.x}, ${data.rect.y}, ${data.rect.w}, ${data.rect.h})`);
    if (!data.bounds) throw new Error('Missing drawing bounds');
    this.resultLayerId = data.layer.id;
    this.resultRect = data.rect;
    this.selectedResultId = -1;
    this.resultTool = data;

    finishTransform(this.model, 'AiTool:begin');

    copyRect(this.drawingBounds, data.bounds);
  }

  async update(tool: AiPasteToolData, update: AiUpdateData, remote = false) {
    if (!this.resultTool) return;
    const debug = `layerId: ${this.resultTool?.layer.id}, rect: ${this.resultRect.x}, ${this.resultRect.y}, ${this.resultRect.w}, ${this.resultRect.h})`;

    switch (update.type) {
      case 'validationError': {
        if (update.field === 'prompt' || update.field === 'negative prompt' || update.field === 'embeddings') {
          this.errorPrompt = update;
        }

        this.handleError();
        break;
      }
      case 'finished': {
        const estimatedTime = this.estimatedTime(); // this will be valid only if parameters were not changed after staring generation
        logAction(`[${remote ? 'remote' : 'local'}] ai finished (duration:${update.duration} workerDuration:${update.workerDuration} estimatedTime:${estimatedTime} ${debug})`);
        break;
      }
      case 'result': {
        logAction(`[${remote ? 'remote' : 'local'}] ai update ${update.type} (isActive=${update.isActive}, resultId=${update.resultId}, results.size=${this.resultIds.length}, ${debug})`);
        const img = await loadImageOrImageDataFromData(update.data);

        this.results.set(update.resultId, img);
        if (update.nsfw) {
          this.lastNsfwResultIds.add(update.resultId);
          this.nsfwResultIds.add(update.resultId);
        } else {
          this.createThumbnail(img, update.resultId,);
          if ((this.selectedResultId === -1 || this.selectedResultId === this.preprocessorResultId) && update.data) {
            this.selectedResultId = update.resultId;
            await this.drawResult(update.resultId, this.resultLayerId);
          }
        }
        break;
      }
      case 'activeChange':
        if (update.resultId === -1 && tool.br) {
          // user discarded results, this will be used when at least one result is saved on different layer
          tool.ar = cloneRect(tool.br);
        }
        this.selectedResultId = update.resultId;
        await this.drawResult(update.resultId, this.resultLayerId);
        logAction(`[${remote ? 'remote' : 'local'}] ai update ${update.type} (resultId=${update.resultId}, ${debug})`);
        break;
      case 'progress':
        if (update.status.status === 'finished') {
          if (this.lastNsfwResultIds.size === this.request?.num_outputs) {
            if (this.retryCount < AI_NUMBER_OF_RETRIES_AFTER_NSFW && this.settings.generateSeedOnRun) {
              this.retryAfterNsfwError();
              this.model.toasts?.info({ message: `Retrying`, subtitle: 'Potential NSFW content was detected in all images.' });
              return;
            } else {
              this.model.toasts?.error({ message: 'Failed to generate drawing', subtitle: 'Potential NSFW content was detected in all images. Try again with a different prompt and/or seed.' });
            }
          }
          this.model.setTaskName(undefined);
          this.currentTaskId = undefined;
          if (this.results.size === 1 && this.selectedResultId !== -1) {
            await this.acceptResult(this.selectedResultId);
          }
          this.editor.apply(() => {
            this.initialQueuePosition = undefined;
            this.progress = undefined;
          });
        } else {
          this.model.setTaskName(this.getProgressMessage(this.getTaskName(), update.status!));
        }
        break;
      case 'error':
        logAction(`[${remote ? 'remote' : 'local'}] ai error ${update.message}`);
        if (!remote && update.message !== TASK_CANCELED_ERROR) this.model.toasts?.error({ message: 'Failed to generate drawing', subtitle: update.message });
        this.handleError();
        break;
      case 'removeResult': {
        logAction(`[${remote ? 'remote' : 'local'}] ai update ${update.type} (resultId=${update.resultId}, ${debug})`);
        const i = this.results.get(update.resultId);
        if (i && 'close' in i) i.close();
        this.results.delete(update.resultId);
        this.thumbnails.delete(update.resultId);
        break;
      }
      case 'saveResultToLayer': {
        await this.saveResultOnNewLayer(update.resultId, update.layerId, update.layerIndex);
        this.editor.apply(() => { });
        break;
      }
    }
  }

  private retryAfterNsfwError() {
    if (this.resultTool && this.request) {
      this.retryCount++;

      for (const id of this.lastNsfwResultIds) {
        this.results.delete(id);
        this.nsfwResultIds.delete(id);
      }
      this.lastNsfwResultIds.clear();

      this.model.setTaskName(this.getTaskName());
      const retryJobId = this.currentTaskId;
      this.currentTaskId = randomString(20);
      this.request.seed = random(0x7fffffff);

      const updateData: AiUpdateRequest = { type: 'request', jobId: this.currentTaskId, request: this.request };
      this.model.updateTool(this.resultLayerId, this.resultTool, updateData);

      this.model.track?.event(Analytics.AiRetry, { retryCount: this.retryCount, retryJobId });
    }
  }

  private createThumbnail(img: HTMLImageElement | ImageBitmap | ImageData, resultId: number) {
    const ar = img.width / img.height;
    let width = 64;
    let height = 64;
    if (ar > 1) {
      height = width / ar | 0;
    } else {
      width = height * ar | 0;
    }
    const c = this.editor.renderer.scaleImage(img, width, height);
    this.thumbnails.set(resultId, c.toDataURL(this.imageFormat, this.imageQuality));
  }

  private pushResultsOnNewLayersToHistory(history: IHistory) {
    for (const layerId of this.addedLayerIds) {
      const layer = getLayerSafe(this.model.drawing, layerId);
      const index = this.model.drawing.layers.indexOf(layer);
      history.pushAddLayer(layer, index);
      history.pushDirtyRect('ai', layer.id, this.resultRect);
    }
  }

  finish(remote = false) {
    if (this.isActive) {
      const { user, drawing } = this.model;
      this.model.setTaskName(undefined);

      logAction(`[${remote ? 'remote' : 'local'}] ai finish (layerId: ${this.resultTool?.layer.id}, addedLayerIds: ${this.addedLayerIds.length}, selectedResultId=${this.selectedResultId}, rect: ${this.resultRect.x}, ${this.resultRect.y}, ${this.resultRect.w}, ${this.resultRect.h})`);
      if (this.selectedResultId !== -1) {
        const layer = getLayerSafe(drawing, this.resultLayerId);
        user.history.execTransaction(history => {
          this.pushResultsOnNewLayersToHistory(history);
          history.pushSelection('ai');
          history.pushLayerState(this.resultLayerId);
          history.pushDirtyRect('ai', this.resultLayerId, this.resultRect);
        });

        this.editor.apply(() => {
          layer.flags = layer.flags | (this.resultTool?.created ? LayerFlag.AiGenerated : LayerFlag.AiAssisted);
        });

        this.editor.renderer.commitTool(user, layer.opacityLocked);
      } else {
        if (this.addedLayerIds.length > 0) {
          user.history.execTransaction(history => {
            this.pushResultsOnNewLayersToHistory(history);
          });
          this.editor.renderer?.releaseUserCanvas(user);
        } else {
          this.editor.renderer?.releaseUserCanvas(user);
          user.history.unpre();
        }
      }
      redrawDrawing(drawing);
      redraw(this.editor);
      this.cleanCachedResults();

    }
  }

  cancelBeganTool(remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] ai cancel begun tool ${this.isActive} addedLayerIds.length=${this.addedLayerIds.length}`);
    if (this.isActive) {
      const { user, drawing } = this.model;

      if (!remote && this.isGenerating && this.resultTool) {
        this.model.updateTool(this.resultLayerId, this.resultTool, { type: 'stop', jobId: this.currentTaskId });
      }

      if (!remote) {
        this.model.cancelTool('cancel-began');
      }

      for (const layerId of this.addedLayerIds) {
        removeLayerFromDrawing(this.editor, user, drawing, layerId);
      }

      this.editor.renderer.releaseUserCanvas(user);
      user.history.unpre();
      this.cleanCachedResults();
    }
  }

  async acceptResult(resultId: number) {
    if (this.nsfwResultIds.has(resultId)) return;
    logAction(`[local] ai acceptResult (selectedResultId=${this.selectedResultId}, isGenerating=${this.isGenerating} resultId=${resultId})`);

    if (this.isGenerating) {
      this.cancelAsyncRequest();
    }

    if (this.showAiResultTooltip && this.resultIds.length > 1) {
      this.showAiResultTooltip = false;
      storageSetBoolean('tooltip-ai-firstResultPrompt', true);
    }

    if (resultId !== this.selectedResultId) {
      await this.selectResult(resultId);
    }

    this.model.finishTool(this.resultLayerId, { id: this.id });
    this.finish();
  }

  async cancelResult() {
    logAction(`[local] ai cancelResult`);
    await this.selectResult(-1);
    if (this.addedLayerIds.length > 0) {
      this.model.finishTool(this.resultLayerId, { id: this.id });
    } else {
      this.model.cancelTool('cancel-result');
    }
    this.finish();
  }

  private getCheckpoint(m: AiModelName, mode: AiToolPipeline) {
    const model = AI_MODEL_DATA[m];
    if (!model) throw new Error('Selected model is invalid');

    if (mode === 'outpaint' || mode === 'inpaint') {
      // so far assume that there is only one inpaint model
      const inpaintCheckpoint = model.find(m => m.inpaint);
      if (inpaintCheckpoint) return inpaintCheckpoint;
    }

    const checkpointForResolution = model.find(m => m.resolutions?.includes(this.settings.resolution));
    if (checkpointForResolution) return checkpointForResolution;

    // assume that there is at lease one default checkpoint
    return model.find(m => m.default);
  }

  private getJobType(): AiJob {
    const model = this.forceModel && this.isModelSupported(this.forcedModel) ? this.forcedModel : this.selectedModel;
    if (model === 'Stable Diffusion 2.1') return AiJob.AI_A1111_SD21;
    if (model === 'Stable Diffusion 1.5') return AiJob.AI_A1111_SD21;
    else return AiJob.AI_A1111;
  }

  private async getLayerData(editor: Editor, layer: Layer | undefined) {
    const index = layer ? editor.drawing.layers.indexOf(layer) : 0;

    if (!layer) {
      const [id] = await createNewLayersInsecure(editor, 1, false);
      const name = getNewLayerName(editor.drawing);
      return { id, index, name };
    } else {
      return { id: layer.id, index, name: layer.name };
    }
  }

  cleanCachedResults() {
    for (const i of this.results.values()) {
      if (i && 'close' in i) i.close();
    }
    this.selectedResultId = -1;
    this.results.clear();
    this.thumbnails.clear();
    this.totalResultCount = 0;
    this.request = undefined;
    this.resultTool = undefined;
    this.currentTaskId = undefined;
    this.model.setTaskName(undefined);
    this.addedLayerIds = [];
    this.nsfwResultIds.clear();
    this.lastNsfwResultIds.clear();
    this.retryCount = 0;
  }

  handleError() {
    this.model.setTaskName(undefined);
    this.currentTaskId = undefined;
    this.initialQueuePosition = undefined;
    this.progress = undefined;
    // checking here this.results because we want to keep preprocessor result
    if (this.results.size === 0) {
      this.cleanCachedResults();
      this.model.cancelTool('handle-error');
      this.model.user.history.unpre();
    } else {
      // current number of results is final results
      this.totalResultCount = this.resultIds.length;
    }
    this.editor.apply(() => { });
  }

  cancelAsyncRequest() {
    logAction(`[local] ai cancelAsyncRequest (taskId=${this.currentTaskId}, results.size=${this.resultIds.length})`);
    if (this.currentTaskId && this.resultTool) {
      this.model.updateTool(this.resultLayerId, this.resultTool, { type: 'stop', jobId: this.currentTaskId });
    }
  }

  // it't not super fast but still faster than sending it to worker and getting invalid response
  private isCanvasEmpty(canvas: HTMLCanvasElement) {
    const context = getContext2d(canvas);
    const buffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
    return !buffer.some(color => color !== 0);
  }

  private getTaskName() {
    switch (this.pipeline) {
      case 'create':
        if (this.selectedRenderType.id !== RenderTypeId.EmptyLayer && this.selectedResultId === -1) return 'Analysing image';
        return `Rendering image`;
      case 'enhance':
        return `Creating variants`;
      case 'inpaint':
        return `Inpainting image`;
      case 'outpaint':
        return `Outpainting image`;
      default:
        return invalidEnumReturn(this.pipeline, '');
    }
  }

  private getProgressMessage(taskName: string, report: AiStatusReport) {
    switch (report.status) {
      case 'running': {
        if (this.initialQueuePosition === undefined) {
          this.initialQueuePosition = 100;
        }
        // in running progress will increase from 0 to num_inference_steps
        this.progress = 100 - ((100 - report.progress) / this.initialQueuePosition * 100 | 0);
        return `${taskName} (${report.status} ${this.progress}%)`;
      }
      case 'queued': {
        if (this.initialQueuePosition === undefined) {
          this.initialQueuePosition = report.progress + 100;
        }
        // in queued progress will decrease from sum of steps of all queued jobs to 0
        this.progress = 100 - ((report.progress + 100) / this.initialQueuePosition * 100 | 0);
        return `${taskName} (${report.status} ${this.progress}%)`;
      }
      default:
        return `${taskName} (${report.status})`;
    }
  }

  async doAsyncRequest(editor: Editor, request: StableDiffusionInput): Promise<void> {
    if (!request.prompt.trim()) {
      this.errorPrompt = { field: 'empty-prompt', items: [], type: 'validationError' };
      return;
    }

    this.retryCount = 0;
    this.lastNsfwResultIds.clear();

    this.request = request;
    this.currentTaskId = randomString(20);

    const rect = cloneRect(this.bounds);

    DEVELOPMENT && !TESTS && console.log(`[ai] start step=${request.num_inference_steps} width=${request.width} height=${request.height} prompt=${request.prompt}`);
    logAction(`[local] ai doAsyncRequest (taskId=${this.currentTaskId}, request=${JSON.stringify({ ...request, init_image: !!request.init_image, mask: !!request.mask })})`);
    const taskName = this.getTaskName();
    this.model.setTaskName(taskName);

    const layer = editor.activeLayer;
    if (!layer) throw new Error('Missing active layer');

    const afterRect = layer.opacityLocked ? cloneRect(layer.rect) : addRect(cloneRect(rect), layer.rect);

    const beforeRect = cloneRect(layer.rect);

    const { id, name, index } = await this.getLayerData(editor, layer);
    const created = request.pipeline === 'create';

    this.results.clear();

    this.resultRect = rect;
    this.resultLayerId = id;

    this.totalResultCount += this.request.num_outputs;

    copyRect(this.drawingBounds, this.model.drawing);
    this.resultTool = {
      jobId: this.currentTaskId,
      id: this.id, rect,
      index, layer: { id, name },
      ar: afterRect, br: beforeRect, created,
      pipeline: request.pipeline,
      bounds: cloneRect(this.drawingBounds)
    };
    this.model.beginTool<AiPasteToolData>(id, this.resultTool);
    this.hasBegan = true;

    DEVELOPMENT && !TESTS && console.log(`[ai] request = ${JSON.stringify({ ...request, init_image: !!request.init_image, mask: !!request.mask }, null, 2)}`);

    const updateData: AiUpdateRequest = { type: 'request', jobId: this.currentTaskId, request };
    this.model.updateTool(this.resultLayerId, this.resultTool, updateData);
  }

  async loadMore() {
    if (!this.settings.prompt.trim()) {
      this.errorPrompt = { field: 'empty-prompt', items: [], type: 'validationError' };
      return;
    }
    this.retryCount = 0;
    this.lastNsfwResultIds.clear();

    this.currentTaskId = randomString(20);
    logAction(`[local] ai loadMore (taskId=${this.currentTaskId})`);
    const taskName = this.getTaskName();
    this.model.setTaskName(taskName);

    if (!this.request || !this.resultTool) throw new Error('You can\'t retry generation. No active tool');

    this.request = this.createRequest(this.request.pipeline, this.request.init_image, this.request.mask);
    this.totalResultCount += this.request.num_outputs;
    this.request.seed = this.settings.seed + this.totalResultCount;

    const updateData: AiUpdateRequest = { type: 'request', jobId: this.currentTaskId, request: this.request };
    this.model.updateTool<AiPasteToolData>(this.resultLayerId, { ...this.resultTool, jobId: this.currentTaskId }, updateData);
  }

  randomizeSeed() {
    this.settings.seed = random(0x7fffffff);
  }

  private getBounds() {
    if (this.bounds.w === 0 || this.bounds.h === 0) {
      this.resetBounds();
    }

    return this.bounds;
  }

  // this will be more complex in the future, TODO create common util (same check is done on server side)
  // TODO: move this check to higher level, when setting model on the tool, so the tool doesn't have to check `model.featureFlags`
  private isModelSupported(model: AiModelName) {
    if (AI_MODELS_AVAILABLE_FOR_ALL_USERS.includes(model)) return true;

    return this.model.featureFlags?.isFeatureSupported(Feature.AiBeta);
  }

  isUpscaleActive() {
    if (this.pipeline !== 'create') return false;
    const checkpoint = this.getCheckpoint(this.forceModel ? this.forcedModel : this.selectedModel, this.pipeline);
    const enable_hr = !checkpoint?.resolutions?.includes(this.settings.resolution) && this.settings.resolution > 512;
    return this.forcedUpscale || enable_hr;
  }

  // this contains settings that can't be changed, this settings are needed to make some of the pipelines work properly (for now only outpaint)
  private getRequestOverride(pipeline: AiToolPipeline): Partial<StableDiffusionInput> {
    return pipeline === 'outpaint' ? {
      prompt_strength: 1.0,
      script_name: 'magma-outpainting2',
      inpaint_fill: InpaintFill.Fill
    } : {};
  }

  get forceCheckpoint() {
    return this.forceModel && this.isModelSupported(this.forcedModel);
  }

  private enableHighResFix(checkpoint: AiCheckpoint) {
    if (this.settings.controlnet_model) {
      // TODO retest it after updating control net plugin or adding new hr fix
      DEVELOPMENT && !TESTS && console.warn(`HR fix was disabled, it can't be used with control nets`);
      return false;
    }
    return this.forceCheckpoint ? this.forcedUpscale : !checkpoint?.resolutions?.includes(this.settings.resolution) && this.settings.resolution > 512;
  }

  createRequest(pipeline: AiToolPipeline, init_image?: string, mask?: string): StableDiffusionInput {
    const size = this.getOutputSize();

    if (!this.isModelSupported(this.selectedModel)) {
      this.selectedModel = AI_DEFAULT_MODEL;
    }

    const checkpoint = this.getCheckpoint(this.selectedModel, pipeline);
    if (!checkpoint) throw new Error('Failed to find checkpoint');

    const model = this.forceCheckpoint ? this.forcedCheckpoint : checkpoint.file;

    if (this.selectedModel === 'Stable Diffusion 2.1' || this.forcedModel === 'Stable Diffusion 2.1') {
      this.settings.controlnet_model = this.selectedRenderType.model;
      this.settings.controlnet_preprocessor = this.selectedRenderType.preprocesor;
    } else {
      this.settings.controlnet_model = null;
      this.settings.controlnet_preprocessor = null;
    }

    return {
      pipeline,
      init_image,
      mask,
      ...size,
      prompt: this.settings.prompt,
      seed: this.settings.seed,
      prompt_strength: this.settings.promptStrength,
      num_inference_steps: this.settings.numInferenceSteps,
      guidance_scale: this.settings.guidanceScale,
      num_outputs: this.settings.numberResults,
      model,
      sampler: this.sampler,
      negative_prompt: this.settings.negativePrompt,
      type: this.getJobType(),
      script_name: this.settings.scriptName,
      enable_hr: this.enableHighResFix(checkpoint),
      tiling: this.tiling,
      mask_blur: this.settings.inpaintMaskBlur | 0,
      inpaint_fill: this.settings.inpaintFill,
      resolution: this.settings.resolution,
      controlnet_model: this.settings.controlnet_model ?? 'Disable',
      controlnet_preprocessor: this.settings.controlnet_preprocessor ?? 'None',
      ...this.getRequestOverride(pipeline)
    };
  }

  getOutputSize() {
    const bounds = this.getBounds();
    const inputWidth = bounds.w;
    const inputHeight = bounds.h;

    let scale = 1;
    if (inputHeight > this.settings.resolution || inputWidth > this.settings.resolution) {
      if (inputWidth > inputHeight) {
        scale = this.settings.resolution / inputWidth;
      } else {
        scale = this.settings.resolution / inputHeight;
      }
    } else if (
      (!this.usePixelPerfectMode && inputHeight < this.settings.resolution && inputWidth < this.settings.resolution) ||
      (this.usePixelPerfectMode && inputHeight < 256 && inputWidth < 256) // in pixel perfect mode upscale only if selection is very small
    ) {
      if (inputWidth > inputHeight) {
        scale = this.settings.resolution / inputWidth;
      } else {
        scale = this.settings.resolution / inputHeight;
      }
    }

    if (this.usePixelPerfectMode) {
      const width = Math.ceil(inputWidth * scale);
      const height = Math.ceil(inputHeight * scale);

      return { width, height };
    } else {
      const width = Math.ceil(inputWidth * scale / AI_OPTIMUM_SIZE_STEP) * AI_OPTIMUM_SIZE_STEP;
      const height = Math.ceil(inputHeight * scale / AI_OPTIMUM_SIZE_STEP) * AI_OPTIMUM_SIZE_STEP;
      return { width, height };
    }
  }

  getInputSize() {
    const bounds = this.getBounds();
    const inputWidth = bounds.w;
    const inputHeight = bounds.h;

    let scale = 1;
    if (inputHeight > this.settings.resolution || inputWidth > this.settings.resolution) {
      if (inputWidth > inputHeight) {
        scale = this.settings.resolution / inputWidth;
      } else {
        scale = this.settings.resolution / inputHeight;
      }
    }

    if (this.usePixelPerfectMode) {
      const width = Math.ceil(inputWidth * scale);
      const height = Math.ceil(inputHeight * scale);

      logAction(`[local] ai getInputSize pixel perfect (width: ${width}, height: ${height}, resolution: ${this.settings.resolution})`);
      return { width, height };
    } else {
      const width = Math.ceil(inputWidth * scale / AI_OPTIMUM_SIZE_STEP) * AI_OPTIMUM_SIZE_STEP;
      const height = Math.ceil(inputHeight * scale / AI_OPTIMUM_SIZE_STEP) * AI_OPTIMUM_SIZE_STEP;
      logAction(`[local] ai getInputSize (width: ${width}, height: ${height}, resolution: ${this.settings.resolution})`);
      return { width, height };
    }
  }

  getMaskFromLayerImage(layer: Layer) {
    const { width, height } = this.getInputSize();
    const bounds = this.getBounds();

    const maskCanvas = createCanvas(width, height);
    const maskContext = getContext2d(maskCanvas);
    maskContext.imageSmoothingEnabled = false;

    maskContext.beginPath();
    maskContext.rect(0, 0, width, height);
    maskContext.fillStyle = '#fff';
    maskContext.fill();

    const canvas = this.editor.renderer.getScaledLayerSnapshot(this.model.drawing, layer, width, height, bounds);

    if (this.isCanvasEmpty(canvas)) throw new UserError(`Active layer or your selection is empty`);

    maskContext.drawImage(canvas, 0, 0);
    return maskCanvas.toDataURL(this.imageFormat, this.imageQuality);
  }

  createInpaintMask() {
    const { width, height } = this.getInputSize();
    const selectionMask = this.getSelection();

    if (isMaskEmpty(selectionMask)) throw new UserError('Selection is empty, select area that should be inpainted');

    if (selectionMask.poly) {
      const intersect = intersection(selectionMask.poly, createPolyRect(this.bounds.x, this.bounds.y, this.bounds.w, this.bounds.h), false);
      if (isPolyEmpty(intersect)) throw new UserError('Inpaint mask is outside of bounding box. Move bounding box or inapaint mask');
    }

    const maskCanvas = createCanvas(width, height);
    const maskContext = getContext2d(maskCanvas);
    maskContext.imageSmoothingEnabled = false;

    maskContext.beginPath();
    maskContext.rect(0, 0, width, height);
    maskContext.fillStyle = '#fff';
    maskContext.fill();

    maskContext.beginPath();
    maskContext.fillStyle = '#000';

    const mask = cloneMask(this.inpaintMask);

    const t = createMat2d();
    createTransform(t, -this.bounds.x * width / this.bounds.w, -this.bounds.y * height / this.bounds.h, 0, width / this.bounds.w, height / this.bounds.h);
    transformMask(mask, t);
    fillMask(maskContext, mask);

    if (this.isCanvasEmpty(maskCanvas)) throw new UserError(`Active layer or your selection is empty`);

    return maskCanvas.toDataURL(this.imageFormat, this.imageQuality);
  }

  getImage(layer?: Layer) {
    const { width, height } = this.getInputSize();
    const bounds = this.getBounds();

    const canvas = layer ?
      this.editor.renderer.getScaledLayerSnapshot(this.model.drawing, layer, width, height, bounds) :
      this.editor.renderer.getScaledDrawingSnapshot(this.model.drawing, width, height, bounds);

    if (this.isCanvasEmpty(canvas)) throw new UserError(`Active layer or your selection is empty`);

    return canvas.toDataURL(this.imageFormat, this.imageQuality);
  }

  getMask(layer: Layer) {
    const { width, height } = this.getInputSize();
    const bounds = this.getBounds();
    const canvas = this.editor.renderer.getScaledLayerMask(this.model.drawing, layer, width, height, bounds);
    if (this.isCanvasEmpty(canvas)) throw new UserError(`Active layer or your selection is empty`);
    return canvas.toDataURL(this.imageFormat, this.imageQuality);
  }

  private drawScaledImage(rect: Rect, image: AnyImageType, user: User) {
    const r = cloneRect(rect);
    const canvas = createCanvas(rect.w, rect.h);
    const context = getContext2d(canvas);
    context.imageSmoothingEnabled = false;

    if ('data' in image) {
      const scaledCanvas = createCanvas(image.width, image.height);
      const scaledContext = getContext2d(scaledCanvas);
      scaledContext.imageSmoothingEnabled = false;
      const data = scaledContext.createImageData(image.width, image.height);
      data.data.set(image.data);
      scaledContext.putImageData(data, 0, 0);
      context.drawImage(scaledCanvas, 0, 0, image.width, image.height, 0, 0, rect.w, rect.h);
      this.editor.renderer.putImageData(user, context.getImageData(0, 0, rect.w, rect.h), r);

    } else {
      context.drawImage(image, 0, 0, image.width, image.height, 0, 0, rect.w, rect.h);
      this.editor.renderer.putImage(user, canvas, r);
    }
  }

  private async saveResultOnNewLayer(resultId: number, layerId: number, layerIndex: number) {
    const { drawing, user } = this.model;
    const name = getNewLayerName(drawing);
    const layer = layerFromState({ id: layerId, name });
    addLayerToDrawing(this.editor, user, drawing, layer, layerIndex);
    layer.owner = user;
    layer.flags = layer.flags | (this.resultTool?.created ? LayerFlag.AiGenerated : LayerFlag.AiAssisted);

    // clear user.surface so it can be used to create data on new layer
    this.editor.renderer.releaseUserCanvas(user);

    await this.drawResult(resultId, layerId);
    this.editor.renderer.commitToolOnLayer(user, layer, layer.opacityLocked);
    this.addedLayerIds.push(layerId);

    // restore previous
    await this.drawResult(this.selectedResultId, this.resultLayerId);

    this.model.track?.event(Analytics.AiPutResultOnNewLayer, { jobId: this.currentTaskId });
  }

  async putResultToNewLayer(resultId: number, above: boolean) {
    logAction(`[local] ai put result on new layer (resultId=${resultId}, above=${above})`);
    if (this.nsfwResultIds.has(resultId)) return;

    const { user, drawing } = this.model;
    const editor = this.editor as Editor;
    const [layerId] = await createNewLayersInsecure(editor, 1, false);
    const layerIndex = above ?
      drawing.layers.indexOf(editor.activeLayer!) :
      drawing.layers.indexOf(editor.activeLayer!) + 1;

    await this.saveResultOnNewLayer(resultId, layerId, layerIndex);
    sendLayerOrder(user, drawing);

    const updateData: AiUpdateSaveResultToLayer = { type: 'saveResultToLayer', resultId, layerId, layerIndex };
    this.model.updateTool(this.resultLayerId, { id: this.id, otherLayerIds: this.addedLayerIds }, updateData);
  }

  async selectResult(resultId: number) {
    logAction(`[local] ai selectResult (resultId=${resultId} selectedResultId=${this.selectedResultId}) nsfwResultIds=[${[...this.nsfwResultIds.values()]}]`);
    if (this.nsfwResultIds.has(resultId)) return;
    if (!(this.results.has(resultId) || resultId === -1)) {
      DEVELOPMENT && console.warn('Trying to select invalid result', resultId);
      return;
    }
    this.selectedResultId = resultId;
    await this.drawResult(resultId, this.resultLayerId);

    const updateData: AiUpdateData = { type: 'activeChange', replace: true, resultId };
    this.model.updateTool(this.resultLayerId, { id: this.id }, updateData);
  }

  async removeResult(resultId: number) {
    logAction(`[local] ai remove result (resultId=${resultId} totalResultCount=${this.totalResultCount})`);
    const resultIds = Array.from(this.results.keys());
    let index = resultIds.indexOf(resultId);
    this.results.delete(resultId);
    resultIds.splice(index, 1);

    this.totalResultCount--;
    if (this.totalResultCount === 0) {
      await this.cancelResult();
    } else {
      if (resultId === this.selectedResultId) {
        index = clamp(index, 0, resultIds.length - 1);
        if (this.nsfwResultIds.has(resultIds[index])) {
          this.selectedResultId = -1;
        } else {
          await this.selectResult(resultIds[index]);
        }
      }
      const updateData: AiUpdateRemoveResult = { type: 'removeResult', resultId };
      this.model.updateTool(this.resultLayerId, { id: this.id }, updateData);
    }
  }

  private async drawResult(resultId: number, layerId: number) {
    const img = this.results.get(resultId);
    if (img) {
      const { user, drawing } = this.model;
      const layer = getLayerSafe(drawing, layerId);
      throwIfTextLayer(layer);
      setupSurface(user.surface, ToolId.AI, CompositeOp.Draw, layer, this.drawingBounds);
      user.surface.ignoreSelection = true;

      this.drawScaledImage(this.resultRect, img, user);

      redrawDrawing(drawing);
      redraw(this.editor);
    }
  }

  boundsMaximize() {
    copyRect(this.bounds, this.model.drawing);
  }

  resultImage(id: number) {
    return this.thumbnails.get(id);
  }

  resetBounds() {
    const { drawing } = this.model;
    if (!rectIncludesRect(drawing, this.bounds)) {
      setRect(this.bounds, drawing.x, drawing.y, Math.min(512, drawing.w), Math.min(512, drawing.h));
    }
  }

  resetSettings() {
    this.resetBounds();
    clearMask(this.inpaintMask);

    this.promptHistory.clear();
    this.promptHistoryHeaders = [];
    this.promptHistoryFullyFetched = false;
  }

  estimatedTime() {
    const o = this.getOutputSize();
    const s = o.width > o.height ? o.width / 512 : o.height / 512;
    const steps = this.pipeline === 'create' ? this.settings.numInferenceSteps : Math.ceil(this.settings.numInferenceSteps * this.settings.promptStrength);
    return Math.ceil(estimateGenerationTime(o.width * o.height, (o.width / s) * (o.height / s), this.isUpscaleActive(), steps, this.settings.numberResults));
  }

  selectRenderType(type: RenderType) {
    this.selectedRenderType = type;
  }
}
