export function keys<T>(keysArray: (keyof T)[]): string[] {
  return keysArray as string[];
}

export function hasFlag(value: number | undefined, flag: number): boolean {
  return (value! & flag) === flag;
}

export function setFlag(value: number | undefined, flag: number, on: boolean): number {
  return (value! & ~flag) | (on ? flag : 0);
}

export function findById<U, T extends { id: U; }>(items: T[], id: U): T | undefined {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) return items[i];
  }
  return undefined;
}

export function findByName<U, T extends { name: U; }>(items: T[], name: U): T | undefined {
  for (let i = 0; i < items.length; i++) {
    if (items[i].name === name) return items[i];
  }
  return undefined;
}

export function removeById<U, T extends { id: U; }>(items: T[], id: U): T | undefined {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return items.splice(i, 1)[0];
    }
  }

  return undefined;
}

// Preserves order of items
export function removeItem<T>(items: T[] | undefined, item: T) {
  if (items) {
    const index = items.indexOf(item);

    if (index !== -1) {
      items.splice(index, 1);
      return true;
    }
  }

  return false;
}

// Does not preserve order of items
export function removeItemFast<T>(items: T[], item: T) {
  const index = items.indexOf(item);

  if (index !== -1) {
    items[index] = items[items.length - 1];
    items.length--;
  }
}

export function removeOldestItems<T>(items: T[], limit: number, getTimestamp: (item: T) => any) {
  while (items.length > limit) {
    let oldestIndex = 0;
    for (let i = 1; i < items.length; i++) {
      if (getTimestamp(items[oldestIndex]) < getTimestamp(items[i])) {
        oldestIndex = i;
      }
    }

    items.splice(oldestIndex, 1);
  }
}

export function includes<T>(array: T[] | undefined, item: T): boolean {
  return array !== undefined && array.indexOf(item) !== -1;
}

export function contains<T>(iterable: Iterable<T> | undefined, item: T): boolean {
  if (iterable && Array.isArray(iterable)) {
    return includes(iterable, item);
  } else {
    for (const i of (iterable ?? [])) {
      if (i === item) return true;
    }
    return false;
  }
}

export function invalidEnumReturn<T>(value: never, result: T): T {
  if (DEVELOPMENT) throw new Error(`Invalid enum value: ${value}`);
  return result;
}

export function invalidEnum(value: never, message = 'Invalid enum value'): never {
  throw new Error(`${message}: ${value}`);
}

const digits = '0123456789';
const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lowercaseLettersAndDigits = lowercaseLetters + digits;
const uppercaseLettersAndDigits = lowercaseLetters + digits + uppercaseLetters;
const lowercaseCharacters = lowercaseLettersAndDigits + '_';
const uppercaseCharacters = lowercaseCharacters + uppercaseLetters;

export function isLowercase(str: string): boolean {
  return str.toLowerCase() === str;
}

function randomStringFrom(length: number, characters: string) {
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters[(Math.random() * characters.length) | 0];
  }

  return result;
}

export function randomString(length: number, useUpperCase = false): string {
  return randomStringFrom(length, useUpperCase ? uppercaseCharacters : lowercaseCharacters);
}

export function randomLettersAndDigits(length: number, useUpperCase = false): string {
  return randomStringFrom(length, useUpperCase ? uppercaseLettersAndDigits : lowercaseLettersAndDigits);
}

export function randomDigits(length: number): string {
  return randomStringFrom(length, digits);
}

export function removeFileExtension(value: string) {
  return value.replace(/\.[a-z0-9]+$/i, '');
}

export function quadraticEasing(start: number, current: number, length: number) {
  return Math.min(1.0, Math.sqrt((Math.max(0, current - length - start)) / length));
}

export function copyPropertyWithGetter(source: object, target: object, key: string) {
  const descriptor = Object.getOwnPropertyDescriptor(source, key);
  if (descriptor && 'get' in descriptor) {
    Object.defineProperty(target, key, descriptor);
  } else {
    (target as any)[key] = (source as any)[key];
  }
}
