import { Injectable, Injector } from '@angular/core';
import { SplashScreenId } from '../common/interfaces';
import { Constructor } from '../common/typescript-utils';

export interface ParticularSplashScreen {
  canOpen(injector: Injector): boolean;
}

@Injectable()
export class SplashScreenService {
  splashScreenOutlet: any; // component declared in portal, can't declare type here
  openSplashScreen<T = any>(_id: SplashScreenId, _data: any): Promise<T> { return Promise.resolve(undefined) as Promise<T>; }
  closeSplashScreen(_data: any) { }
  get currentComponent(): Constructor<ParticularSplashScreen> | undefined {
    return undefined;
  }
  get inputData() {
    return { };
  }
}
