import { ITool, IToolEditor, IToolModel, CompositeOp, IToolData, Layer, ToolId, TabletEvent } from '../interfaces';
import { faWrench } from '../icons';
import { addRect, intersectRect, makeIntegerRect, setRect, createRect, cloneRect, copyRect } from '../rect';
import { BLACK } from '../constants';
import { setupSurface } from '../toolSurface';
import { releaseToolRenderingContext } from '../user';
import { redrawLayer } from '../../services/editorUtils';
import { finishTransform } from '../toolUtils';
import { createViewport } from '../create';

export class RefTool implements ITool {
  id = ToolId.Ref;
  name = 'Reference tool';
  icon = faWrench;
  view = createViewport();
  drawingBounds = createRect(0, 0, 0, 0);
  private tempRect = createRect(0, 0, 0, 0);
  private layer: Layer | undefined = undefined;
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  private paint(x: number, y: number) {
    const { user, drawing } = this.model;

    if (user.surface.context) {
      setRect(this.tempRect, x - 6, y - 6, 12, 12);
      user.surface.context.fillCircle(BLACK, x, y, 5, 1, 0);
      user.surface.context.flush();
      redrawLayer(drawing, this.layer, this.tempRect);

      const rect = user.surface.rect;
      addRect(rect, this.tempRect);
      intersectRect(rect, drawing);
      makeIntegerRect(rect);
    }
  }
  setup(data?: IToolData) {
    this.layer = this.model.user.activeLayer;

    if (data) {
      if (!data.bounds) throw new Error('Missing drawing bounds');
      copyRect(this.drawingBounds, data.bounds);
    }
  }
  start(x: number, y: number, pressure: number, _: TabletEvent | undefined) {
    if (!this.layer) throw new Error('[RefTool.start] Missing layer');

    finishTransform(this.model, 'RefTool');
    this.editor.renderer.getToolRenderingContext(this.model.user, this.drawingBounds);
    setupSurface(this.model.user.surface, this.id, CompositeOp.Draw, this.layer, this.drawingBounds);
    this.paint(x, y);
    this.model.startTool<IToolData>(this.layer.id, { id: this.id, bounds: cloneRect(this.drawingBounds) }, x, y, pressure);
  }
  move(x: number, y: number, pressure: number) {
    this.paint(x, y);
    this.model.nextTool(x, y, pressure);
  }
  end(x: number, y: number, pressure: number) {
    if (!this.layer) throw new Error('[RefTool.end] Missing layer');

    this.paint(x, y);
    releaseToolRenderingContext(this.model.user);
    this.model.user.history.pushDirtyRect('ref', this.layer.id, this.model.user.surface.rect);
    const beforeRect = cloneRect(this.layer.rect);
    this.editor.renderer.commitTool(this.model.user, false);
    this.model.endTool(this.id, x, y, pressure, beforeRect, this.layer.rect);
  }
}
