import type { Rect, ViewFilter, Viewport } from './interfaces';

// moved here to avoid cyclical dependencies

export function createRect(x: number, y: number, w: number, h: number): Rect {
  if (w < 0 || h < 0) throw new Error(`Invalid rect (${x} ${y} ${w} ${h})`);
  return { x, y, w, h };
}

export function createViewport(x = 0, y = 0, scale = 1, rotation = 0, flipped = false, filter: ViewFilter = undefined): Viewport {
  return { x, y, scale, rotation, flipped, filter, width: 100, height: 100, content: createRect(0, 0, 100, 100) };
}
