import { Analytics, CompositeOp, CursorType, Drawing, hasAltKey, hasShiftKey, ITool, IToolEditor, IToolModel, Layer, Mat2d, Rect, SelectionMode, TabletEvent, ToolError, ToolId, TransformOperations, User, Viewport, } from '../interfaces';
import { clamp, deg2rad } from '../mathUtils';
import { invalidEnum } from '../baseUtils';
import { faVectorSquare } from '../icons';
import { pointInsidePolygon } from '../polyUtils';
import { updateTransform, createTransform, getTransformRect, commitedLayerRect, getTransformedRectBounds, resetTransform, copySurfaceTransform, createSurface, getTransformRectTransformation } from '../toolSurface';
import { isPerspectiveGridLayer, isTextLayer, redrawLayerThumb } from '../layer';
import { isMaskEmpty } from '../mask';
import { copyMat2d, createMat2d, invertMat2d, transformVec2ByMat2d } from '../mat2d';
import { cloneVec2, copyVec2, createVec2, createVec2FromValues, setVec2 } from '../vec2';
import { IMoveToolData, setupCanvasesAndLayerForMoveOrTransform } from './moveTool';
import { logAction } from '../actionLog';
import { redraw, redrawLayer } from '../../services/editorUtils';
import { fixedScale, isOk, safeFloatAny } from '../toolUtils';
import { MASK_COORD_LIMIT } from '../constants';
import { canDrawTextLayer, invokeRasterizeFlow, setTextLayerTransform } from '../text/text-utils';
import { Editor } from '../../services/editor';
import { TransformedLayerEvent } from '../analytics';
import { tabletEventSourceToString } from '../utils';
import { setPerspectiveGridLayerTransform } from './perspectiveGridTool';
import { cloneRect } from '../rect';
import { createViewport } from '../create';

export const enum TransformTo {
  Fit,
  Cover,
  Full,
}

// @ignore-translation
const TRANSFORM_TOS = ['fit', 'cover', 'full'];

interface Axes {
  x: boolean;
  y: boolean;
}

const enum RefType {
  TopLeft,
  TopCenter,
  TopRight,
  CenterRight,
  BottomRight,
  BottomCenter,
  BottomLeft,
  CenterLeft,
  Center,
}

interface RefPoint {
  cursor: CursorType;
  ref: number;
  axes: Axes;
  type: RefType;
  dir: number; // cursor direction
}

const enum Mode {
  Move,
  Rotate,
  Scale,
  MoveOrigin,
}

// @ignore-translation
const MODES: TransformOperations[] = ['move', 'rotate', 'scale', 'move-origin'];

const cursors = [
  CursorType.ResizeV, // up
  CursorType.ResizeTR, // up right
  CursorType.ResizeH, // right
  CursorType.ResizeTL, // down right
  CursorType.ResizeV, // down
  CursorType.ResizeTR, // down left
  CursorType.ResizeH, // left
  CursorType.ResizeTL, // up left
];

const cursorsFlipped = [
  CursorType.ResizeV, // up
  CursorType.ResizeTL, // up right
  CursorType.ResizeH, // right
  CursorType.ResizeTR, // down right
  CursorType.ResizeV, // down
  CursorType.ResizeTL, // down left
  CursorType.ResizeH, // left
  CursorType.ResizeTR, // up left
];

const points: RefPoint[] = [
  { dir: 0, type: RefType.Center /* none */, cursor: CursorType.MoveControl, ref: -1, axes: { x: true, y: true } }, // transform origin
  { dir: 7, type: RefType.TopLeft, cursor: CursorType.ResizeTL, ref: 2, axes: { x: true, y: true } },
  { dir: 0, type: RefType.TopCenter, cursor: CursorType.ResizeV, ref: 2, axes: { x: false, y: true } },
  { dir: 1, type: RefType.TopRight, cursor: CursorType.ResizeTR, ref: 3, axes: { x: true, y: true } },
  { dir: 6, type: RefType.CenterLeft, cursor: CursorType.ResizeH, ref: 3, axes: { x: true, y: false } },
  { dir: 0, type: RefType.Center /* none */, cursor: CursorType.Move, ref: -1, axes: { x: false, y: false } }, // inside of cage
  { dir: 2, type: RefType.CenterRight, cursor: CursorType.ResizeH, ref: 0, axes: { x: true, y: false } },
  { dir: 5, type: RefType.BottomLeft, cursor: CursorType.ResizeTR, ref: 1, axes: { x: true, y: true } },
  { dir: 4, type: RefType.BottomCenter, cursor: CursorType.ResizeV, ref: 1, axes: { x: false, y: true } },
  { dir: 3, type: RefType.BottomRight, cursor: CursorType.ResizeTL, ref: 0, axes: { x: true, y: true } },
];

const tempVec = createVec2();
const tempVec2 = createVec2();
const tempVec3 = createVec2();
const tempTransform = createMat2d();
const tempRegion = [createVec2(), createVec2(), createVec2(), createVec2()];

function getOpositeRef(type: RefType) {
  switch (type) {
    case RefType.TopLeft: return RefType.BottomRight;
    case RefType.TopCenter: return RefType.BottomCenter;
    case RefType.TopRight: return RefType.BottomLeft;
    case RefType.BottomLeft: return RefType.TopRight;
    case RefType.BottomCenter: return RefType.TopCenter;
    case RefType.BottomRight: return RefType.TopLeft;
    case RefType.CenterLeft: return RefType.CenterRight;
    case RefType.CenterRight: return RefType.CenterLeft;
    case RefType.Center: return RefType.Center;
    default: invalidEnum(type);
  }
}

function getRefX(type: RefType, rect: Rect) {
  switch (type) {
    case RefType.TopLeft:
    case RefType.CenterLeft:
    case RefType.BottomLeft:
      return rect.x;
    case RefType.TopRight:
    case RefType.CenterRight:
    case RefType.BottomRight:
      return rect.x + rect.w;
    case RefType.TopCenter:
    case RefType.BottomCenter:
    case RefType.Center:
      return rect.x + rect.w / 2;
    default: invalidEnum(type);
  }
}

function getRefY(type: RefType, rect: Rect) {
  switch (type) {
    case RefType.TopLeft:
    case RefType.TopCenter:
    case RefType.TopRight:
      return rect.y;
    case RefType.BottomLeft:
    case RefType.BottomCenter:
    case RefType.BottomRight:
      return rect.y + rect.h;
    case RefType.CenterLeft:
    case RefType.CenterRight:
    case RefType.Center:
      return rect.y + rect.h / 2;
    default: invalidEnum(type);
  }
}

function getAngleToTransformOrigin(originX: number, originY: number, x: number, y: number) {
  const dx = x - originX;
  const dy = y - originY;
  return Math.atan2(dx, dy);
}

function createTempRegion(
  ax: number, ay: number, bx: number, by: number, cx: number, cy: number,
  dx: number, dy: number, transform: Mat2d | undefined
) {
  tempRegion[0][0] = ax; tempRegion[0][1] = ay;
  tempRegion[1][0] = bx; tempRegion[1][1] = by;
  tempRegion[2][0] = cx; tempRegion[2][1] = cy;
  tempRegion[3][0] = dx; tempRegion[3][1] = dy;

  if (transform) {
    for (const point of tempRegion) {
      transformVec2ByMat2d(point, point, transform);
    }
  }
}

export function pointInsideRegion(
  x: number, y: number, ax: number, ay: number, bx: number, by: number, cx: number, cy: number,
  dx: number, dy: number, transform: Mat2d | undefined
) {
  createTempRegion(ax, ay, bx, by, cx, cy, dx, dy, transform);
  return pointInsidePolygon(x, y, tempRegion);
}

function drawRegion(
  context: CanvasRenderingContext2D,
  ax: number, ay: number, bx: number, by: number, cx: number, cy: number,
  dx: number, dy: number, transform: Mat2d | undefined, color: string
) {
  createTempRegion(ax, ay, bx, by, cx, cy, dx, dy, transform);

  context.save();
  context.globalAlpha = 0.75;
  context.fillStyle = color;
  context.beginPath();
  context.moveTo(tempRegion[0][0], tempRegion[0][1]);

  for (let i = 1; i < 4; i++) {
    context.lineTo(tempRegion[i][0], tempRegion[i][1]);
  }

  context.closePath();
  context.fill();
  context.restore();
}

export function pickRegion(
  user: User, drawing: Drawing, view: Viewport, x: number, y: number, context?: CanvasRenderingContext2D
) {
  const { surface, activeLayer } = user;
  const rect = getTransformRect(user, drawing);
  const transform = getTransformRectTransformation(user);
  const boxSize = 15 / view.scale;
  const boxOffset = 6 / view.scale;
  const sx = Math.abs(surface.scaleX);
  const sy = Math.abs(surface.scaleY);
  const boxSizeX = sx ? (boxSize / sx) : 0;
  const boxSizeY = sy ? (boxSize / sy) : 0;
  const boxOffsetX = sx ? (boxOffset / sx) : 0;
  const boxOffsetY = sy ? (boxOffset / sy) : 0;
  const origin = surface.transformOrigin;
  const hasOrigin = origin && surface.layer === activeLayer;
  const originX = hasOrigin ? origin![0] : (rect.x + rect.w / 2);
  const originY = hasOrigin ? origin![1] : (rect.y + rect.h / 2);

  const l = rect.x;
  const r = rect.x + rect.w;
  const t = rect.y;
  const b = rect.y + rect.h;

  const l2 = l - boxSizeX;
  const r2 = r + boxSizeX;
  const t2 = t - boxSizeY;
  const b2 = b + boxSizeY;

  const l1 = l + boxOffsetX;
  const r1 = r - boxOffsetX;
  const t1 = t + boxOffsetY;
  const b1 = b - boxOffsetY;

  const ol = originX - boxSizeX / 2;
  const or = originX + boxSizeX / 2;
  const ot = originY - boxSizeY / 2;
  const ob = originY + boxSizeY / 2;

  if (DEVELOPMENT && context) {
    drawRegion(context, r1, b1, r2, b1, r2, b2, r1, b2, transform, 'Red');
    drawRegion(context, l1, b1, r1, b1, r1, b2, l1, b2, transform, 'YellowGreen');
    drawRegion(context, l2, b1, l1, b1, l1, b2, l2, b2, transform, 'Red');
    drawRegion(context, r1, t1, r2, t1, r2, b1, r1, b1, transform, 'Chartreuse');
    drawRegion(context, l1, t1, r1, t1, r1, b1, l1, b1, transform, 'Yellow');
    drawRegion(context, l2, t1, l1, t1, l1, b1, l2, b1, transform, 'MediumAquamarine');
    drawRegion(context, r1, t2, r2, t2, r2, t1, r1, t1, transform, 'Red');
    drawRegion(context, l1, t2, r1, t2, r1, t1, l1, t1, transform, 'PaleGreen');
    drawRegion(context, l2, t2, l1, t2, l1, t1, l2, t1, transform, 'Red');
    drawRegion(context, ol, ot, or, ot, or, ob, ol, ob, transform, 'DarkRed');
  }

  if (pointInsideRegion(x, y, ol, ot, or, ot, or, ob, ol, ob, transform)) return 0; // transform origin
  if (pointInsideRegion(x, y, l2, t2, l1, t2, l1, t1, l2, t1, transform)) return 1; // top left
  if (pointInsideRegion(x, y, l1, t2, r1, t2, r1, t1, l1, t1, transform)) return 2; // top
  if (pointInsideRegion(x, y, r1, t2, r2, t2, r2, t1, r1, t1, transform)) return 3; // top right
  if (pointInsideRegion(x, y, l2, t1, l1, t1, l1, b1, l2, b1, transform)) return 4; // left
  if (pointInsideRegion(x, y, l1, t1, r1, t1, r1, b1, l1, b1, transform)) return 5; // mid
  if (pointInsideRegion(x, y, r1, t1, r2, t1, r2, b1, r1, b1, transform)) return 6; // right
  if (pointInsideRegion(x, y, l2, b1, l1, b1, l1, b2, l2, b2, transform)) return 7; // bottom left
  if (pointInsideRegion(x, y, l1, b1, r1, b1, r1, b2, l1, b2, transform)) return 8; // bottom
  if (pointInsideRegion(x, y, r1, b1, r2, b1, r2, b2, r1, b2, transform)) return 9; // bottom right

  return -1;
}

export class TransformTool implements ITool {
  id = ToolId.Transform;
  name = 'Transform';
  description = 'Move and resize your layer or a selected part of it';
  learnMore = 'https://help.magma.com/en/articles/6845211-transform-tool';
  video = { url: '/assets/videos/transform.mp4', width: 374, height: 210 };
  icon = faVectorSquare;
  cursor = CursorType.Move;
  usesModifiers = true;
  redrawAlways = true;
  cancellingKeepsSurface = true;
  view = createViewport();
  skipMoves = true;
  private ref = points[0];
  private startX = 0;
  private startY = 0;
  private startXScreen = 0;
  private startYScreen = 0;
  private startAngle = 0;
  layer: Layer | undefined = undefined;
  private moved = false;
  replace = false;
  private pushedMove = false;
  private mode = Mode.Move;
  private lastTranslateX = 0;
  private lastTranslateY = 0;
  private lastScaleX = 1;
  private lastScaleY = 1;
  private lastRotate = 0;
  private lastTransform = createMat2d();
  private lastTransformInverted = createMat2d();
  private lastTransformOrigin = createVec2();
  private fixedRatio = false;
  private surfaceBefore = createSurface();

  inputMethod: TransformedLayerEvent['source'] = 'mouse';

  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  do({ tx, ty, sx, sy, r, bounds }: IMoveToolData, _data: Uint8Array, debugInfo?: string) {
    const { user, drawing } = this.model;
    const pushed = setupCanvasesAndLayerForMoveOrTransform(this, this.model, bounds ?? drawing, debugInfo);

    if (!this.layer) throw new Error(`[TransformTool.do] Missing layer`);
    // TODO: push layer state for text layer ???
    if (pushed) user.history.pushLayerId('transform', this.layer.id);

    const surface = user.surface;
    if (isTextLayer(this.layer) && this.layer.fontsLoaded) resetTransform(surface);
    surface.translateX = safeFloatAny(tx);
    surface.translateY = safeFloatAny(ty);
    surface.scaleX = fixedScale(safeFloatAny(sx));
    surface.scaleY = fixedScale(safeFloatAny(sy));
    surface.rotate = safeFloatAny(r);
    updateTransform(surface);

    if (isTextLayer(this.layer)) setTextLayerTransform(this.layer, surface);
    if (isPerspectiveGridLayer(this.layer)) {
      setPerspectiveGridLayerTransform(this.layer, surface);
    }

    redrawLayerThumb(this.layer);
    redrawLayer(drawing, this.layer);
  }
  hover(x: number, y: number) {
    const { user, drawing } = this.model;
    const regionIndex = pickRegion(user, drawing, this.editor.view, x + drawing.x, y + drawing.y);
    if (regionIndex === -1) {
      this.cursor = CursorType.Rotate;
    } else {
      const pt = points[regionIndex];

      if (pt.type === RefType.Center) {
        this.cursor = pt.cursor;
      } else {
        let rotate = user.surface.rotate + this.editor.view.rotation;
        while (rotate < 0) rotate += Math.PI * 2;
        const dir = pt.dir + Math.round(rotate / (Math.PI / 4));

        // TODO: this flipping is still not working correctly, maybe transform a vector and measure it's angle
        let flip = this.editor.view.flipped;
        if (user.surface.scaleX < 0) flip = !flip;
        if (user.surface.scaleY < 0) flip = !flip;
        const list = flip ? cursorsFlipped : cursors;
        this.cursor = list[dir % cursors.length];
      }
    }
  }
  rotate(degrees: number) {
    logAction(`transform rotate (${degrees})`);

    const { user, drawing } = this.model;
    this.mode = Mode.Rotate; // for stats
    this.pushedMove = setupCanvasesAndLayerForMoveOrTransform(this, this.model, drawing);
    if (!this.layer) throw new Error('[TransformTool.rotate] Missing layer');
    this.saveLastAndInitOrigin();

    // TODO: remove ?
    const surface = user.surface;
    surface.toolId = this.id;
    surface.mode = CompositeOp.Move;

    this.rotateBy(deg2rad(degrees));
    if (isTextLayer(this.layer)) {
      setTextLayerTransform(this.layer, surface);
    }
    if (isPerspectiveGridLayer(this.layer)) {
      setPerspectiveGridLayerTransform(this.layer, surface);
    }

    redraw(this.editor);
    redrawLayer(drawing, this.layer);
    this.commit(`rotate${degrees < 0 ? degrees : `+${degrees}`}`);
  }
  scale(sx: number, sy: number) {
    logAction(`transform scale (${sx}, ${sy})`);

    const { user, drawing } = this.model;
    this.mode = Mode.Scale; // for stats
    this.pushedMove = setupCanvasesAndLayerForMoveOrTransform(this, this.model, drawing);
    if (!this.layer) throw new Error('[TransformTool.scale] Missing layer');
    this.saveLastAndInitOrigin();

    // TODO: remove ?
    const surface = user.surface;
    surface.toolId = this.id;
    surface.mode = CompositeOp.Move;

    const scaleX = fixedScale(this.lastScaleX * sx);
    const scaleY = fixedScale(this.lastScaleY * sy);

    if (!surface.transformOrigin) throw new Error('[TransformTool.scale] Missing transform origin');
    const origin = transformVec2ByMat2d(tempVec, surface.transformOrigin, this.lastTransform);
    createTransform(tempTransform, this.lastTranslateX, this.lastTranslateY, this.lastRotate, scaleX, scaleY);
    const newOrigin = transformVec2ByMat2d(tempVec2, surface.transformOrigin, tempTransform);

    surface.scaleX = scaleX;
    surface.scaleY = scaleY;
    surface.translateX = this.lastTranslateX + (origin[0] - newOrigin[0]);
    surface.translateY = this.lastTranslateY + (origin[1] - newOrigin[1]);
    updateTransform(surface);

    redraw(this.editor);
    redrawLayer(drawing, this.layer);

    if (isTextLayer(this.layer)) {
      setTextLayerTransform(this.layer, surface);
    }
    if (isPerspectiveGridLayer(this.layer)) {
      setPerspectiveGridLayerTransform(this.layer, surface);
    }

    let op = 'scale-xy';
    if (sx === 1 && sy === -1) op = 'flip-v';
    if (sx === -1 && sy === 1) op = 'flip-h';
    this.commit(op);
  }
  transformTo(to: TransformTo) {
    logAction(`transform to (${TRANSFORM_TOS[to]})`);

    const { user, drawing } = this.model;
    this.pushedMove = setupCanvasesAndLayerForMoveOrTransform(this, this.model, drawing);
    if (!this.layer) throw new Error('[TransformTool.transformTo] Missing layer');
    this.saveLastAndInitOrigin();

    // TODO: remove ?
    const { surface } = user;
    surface.toolId = this.id;
    surface.mode = CompositeOp.Move;

    const rect = isMaskEmpty(user.selection) ? surface.rect : user.selection.bounds;

    let scale: number;

    let rot = Math.round(surface.rotate / (Math.PI * 0.5));
    while (rot < 0) rot += 4;
    rot = rot % 4;

    let w = rect.w || 1;
    let h = rect.h || 1;
    const sx = surface.scaleX < 0 ? -1 : 1;
    const sy = surface.scaleY < 0 ? -1 : 1;

    if (rot === 1 || rot === 3) [w, h] = [h, w];

    switch (to) {
      case TransformTo.Fit:
        if ((drawing.w / drawing.h) < (w / h)) {
          scale = drawing.w / w;
        } else {
          scale = drawing.h / h;
        }
        break;
      case TransformTo.Cover:
        if ((drawing.w / drawing.h) < (w / h)) {
          scale = drawing.h / h;
        } else {
          scale = drawing.w / w;
        }
        break;
      case TransformTo.Full:
        scale = 1;
        break;
      default: invalidEnum(to);
    }

    scale = fixedScale(scale);

    if (rot === 1 || rot === 3) {
      surface.translateX = (drawing.w - rect.h * scale) / 2;
      surface.translateY = (drawing.h - rect.w * scale) / 2;
    } else {
      surface.translateX = (drawing.w - rect.w * scale) / 2;
      surface.translateY = (drawing.h - rect.h * scale) / 2;
    }

    surface.scaleX = scale * sx;
    surface.scaleY = scale * sy;
    surface.rotate = rot * Math.PI * 0.5;

    // compansate from rotation and scale
    const mat = createMat2d();
    createTransform(mat, -drawing.x, -drawing.y, surface.rotate, surface.scaleX, surface.scaleY);
    const bounds = getTransformedRectBounds(rect, mat);
    surface.translateX -= bounds.x;
    surface.translateY -= bounds.y;
    surface.translateX = Math.round(surface.translateX);
    surface.translateY = Math.round(surface.translateY);

    updateTransform(surface);
    if (isTextLayer(this.layer)) {
      setTextLayerTransform(this.layer, surface);
    }
    if (isPerspectiveGridLayer(this.layer)) {
      setPerspectiveGridLayerTransform(this.layer, surface);
    }

    redraw(this.editor);
    redrawLayer(drawing, this.layer);
    this.commit(TRANSFORM_TOS[to]);
  }
  private rotateBy(angle: number) {
    const surface = this.model.user.surface;
    if (!surface.transformOrigin) throw new Error('[TransformTool.rotateBy] Missing transform origin');
    const origin = transformVec2ByMat2d(tempVec, surface.transformOrigin, this.lastTransform);
    const rotate = this.lastRotate + angle;
    createTransform(tempTransform, this.lastTranslateX, this.lastTranslateY, rotate, this.lastScaleX, this.lastScaleY);
    const newOrigin = transformVec2ByMat2d(tempVec2, surface.transformOrigin, tempTransform);

    surface.rotate = rotate;
    surface.translateX = this.lastTranslateX + (origin[0] - newOrigin[0]);
    surface.translateY = this.lastTranslateY + (origin[1] - newOrigin[1]);
    updateTransform(surface);
  }
  private saveLastAndInitOrigin() {
    const { user, drawing } = this.model;
    const { surface } = user;

    if (!surface.transformOrigin) {
      const rect = getTransformRect(user, drawing);
      surface.transformOrigin = createVec2FromValues(rect.x + rect.w / 2, rect.y + rect.h / 2);
    }

    this.lastTranslateX = surface.translateX;
    this.lastTranslateY = surface.translateY;
    this.lastScaleX = surface.scaleX;
    this.lastScaleY = surface.scaleY;
    this.lastRotate = surface.rotate;
    copyMat2d(this.lastTransform, surface.transform);
    invertMat2d(this.lastTransformInverted, surface.transform);
    copyVec2(this.lastTransformOrigin, surface.transformOrigin);
  }
  canStart(): ToolError {
    const { activeLayer, selection } = this.model.user;
    if (isTextLayer(activeLayer)) {
      if (!isMaskEmpty(selection)) {
        invokeRasterizeFlow(this.editor as Editor, activeLayer).catch(e => DEVELOPMENT && console.error(e));
        return ToolError.ImpossibleOnTextLayerInvokedRasterizing;
      } else if (canDrawTextLayer(activeLayer)) {
        return ToolError.NoError;
      } else {
        return ToolError.UnableToInteractWithTextLayer;
      }
    }
    return ToolError.NoError;
  }
  start(x: number, y: number, _pressure: number, _e?: TabletEvent) {
    copySurfaceTransform(this.surfaceBefore, this.model.user.surface);

    this.moved = false;
    this.fixedRatio = false;
    this.startXScreen = x;
    this.startYScreen = y;

    const regionIndex = pickRegion(this.model.user, this.model.drawing, this.editor.view, x, y);

    if (regionIndex === -1) {
      this.mode = Mode.Rotate;
    } else if (regionIndex === 0) {
      this.mode = Mode.MoveOrigin;
    } else if (regionIndex === 5) {
      this.mode = Mode.Move;
    } else {
      this.mode = Mode.Scale;
    }

    this.startX = Math.round(x);
    this.startY = Math.round(y);
    this.ref = regionIndex !== -1 ? points[regionIndex] : points[0];
  }
  move(x: number, y: number, _pressure: number, e?: TabletEvent) {
    const { user, drawing } = this.model;
    const surface = user.surface;

    if (!this.moved) {
      if (this.startXScreen === x && this.startYScreen === y) return;

      this.moved = true;
      this.pushedMove = setupCanvasesAndLayerForMoveOrTransform(this, this.model, drawing);
      this.saveLastAndInitOrigin();

      // TODO: remove ?
      surface.toolId = this.id;
      surface.mode = CompositeOp.Move;

      if (!surface.transformOrigin) throw new Error('[TransformTool.move.if] Missing transform origin');
      const origin = cloneVec2(surface.transformOrigin);
      transformVec2ByMat2d(origin, origin, this.lastTransform);
      this.startAngle = getAngleToTransformOrigin(origin[0], origin[1], this.startX, this.startY);
    }

    if (!surface.transformOrigin) throw new Error('[TransformTool.move] Missing transform origin');

    const dx = Math.round(x - this.startX);
    const dy = Math.round(y - this.startY);
    const altKey = !!e && hasAltKey(e);
    const shiftKey = !!e && hasShiftKey(e);
    const mode = (altKey && this.mode !== Mode.Scale) ? Mode.MoveOrigin : this.mode;
    this.fixedRatio = false;

    switch (mode) {
      case Mode.Move: {
        const tx = (shiftKey && Math.abs(dx) < Math.abs(dy)) ? 0 : dx;
        const ty = (shiftKey && Math.abs(dy) < Math.abs(dx)) ? 0 : dy;
        // TODO: this can be improved
        surface.translateX = clamp(this.lastTranslateX + tx, -MASK_COORD_LIMIT, MASK_COORD_LIMIT);
        surface.translateY = clamp(this.lastTranslateY + ty, -MASK_COORD_LIMIT, MASK_COORD_LIMIT);
        updateTransform(surface);
        break;
      }
      case Mode.Rotate: {
        const origin = transformVec2ByMat2d(tempVec, surface.transformOrigin, this.lastTransform);
        let angle = this.startAngle - getAngleToTransformOrigin(origin[0], origin[1], x, y);

        if (shiftKey) {
          const snap = Math.PI / 12;
          angle = Math.round(angle / snap) * snap;
        }

        this.rotateBy(angle);
        break;
      }
      case Mode.Scale: {
        // TODO: ctrl - skew ?
        const selection = user.selection;
        const rect = isMaskEmpty(selection) ? surface.rect : selection.bounds;
        const type = this.ref.type;
        const refPt = tempVec;

        if (altKey) {
          copyVec2(refPt, surface.transformOrigin);
        } else {
          const oposite = getOpositeRef(type);
          setVec2(refPt, getRefX(oposite, rect), getRefY(oposite, rect));
        }

        const ctrlPt = setVec2(tempVec2, getRefX(type, rect), getRefY(type, rect));
        const cursorPt = copyVec2(tempVec3, ctrlPt);
        transformVec2ByMat2d(cursorPt, cursorPt, this.lastTransform);
        cursorPt[0] += dx;
        cursorPt[1] += dy;
        transformVec2ByMat2d(cursorPt, cursorPt, this.lastTransformInverted);

        if (type === RefType.TopCenter || type === RefType.BottomCenter) {
          ctrlPt[0] = refPt[0];
        } else if (type === RefType.CenterLeft || type === RefType.CenterRight) {
          ctrlPt[1] = refPt[1];
        }

        const w = ctrlPt[0] - refPt[0];
        const h = ctrlPt[1] - refPt[1];
        let sx = w ? (cursorPt[0] - refPt[0]) / w : 1;
        let sy = h ? (cursorPt[1] - refPt[1]) / h : 1;

        if (shiftKey) {
          if (w === 0) {
            sx = sy;
          } else if (h !== 0) {
            sx = (sx + sy) / 2;
          }

          sy = sx;
          this.fixedRatio = true;
        }

        const scaleX = fixedScale(this.lastScaleX * sx);
        const scaleY = fixedScale(this.lastScaleY * sy);

        const newRefPt = copyVec2(tempVec2, refPt);
        transformVec2ByMat2d(refPt, refPt, this.lastTransform);
        createTransform(tempTransform, this.lastTranslateX, this.lastTranslateY, this.lastRotate, scaleX, scaleY);
        transformVec2ByMat2d(newRefPt, newRefPt, tempTransform);

        surface.scaleX = scaleX;
        surface.scaleY = scaleY;
        surface.translateX = this.lastTranslateX + (refPt[0] - newRefPt[0]);
        surface.translateY = this.lastTranslateY + (refPt[1] - newRefPt[1]);
        updateTransform(surface);
        break;
      }
      case Mode.MoveOrigin: {
        // TODO: snap to 45deg ?
        // TODO: snap to ref points ?
        const tx = (shiftKey && Math.abs(dx) < Math.abs(dy)) ? 0 : dx;
        const ty = (shiftKey && Math.abs(dy) < Math.abs(dx)) ? 0 : dy;
        const origin = copyVec2(tempVec, this.lastTransformOrigin);
        transformVec2ByMat2d(origin, origin, this.lastTransform);
        origin[0] += tx;
        origin[1] += ty;
        transformVec2ByMat2d(origin, origin, this.lastTransformInverted);
        copyVec2(surface.transformOrigin, origin);
        break;
      }
      default: invalidEnum(mode);
    }

    if (isTextLayer(this.layer)) setTextLayerTransform(this.layer, surface);
    if (isPerspectiveGridLayer(this.layer)) {
      setPerspectiveGridLayerTransform(this.layer, surface);
    }

    redraw(this.editor);
    redrawLayer(drawing, this.layer);
  }
  end(x: number, y: number, pressure: number, e?: TabletEvent) {
    this.move(x, y, pressure, e);

    if (this.moved) {
      this.inputMethod = tabletEventSourceToString(e);
      this.commit(MODES[this.mode]);
    } else {
      logAction(`[local] transform omitted (layerId: ${this.layer?.id})`);
      this.model.user.history.unpre();
    }
  }
  cancel() {
    if (this.moved) {
      this.moved = false;
      copySurfaceTransform(this.model.user.surface, this.surfaceBefore);
    }
  }
  private commit(op: string) {
    const { user } = this.model;

    if (!this.layer) throw new Error('[TransformTool.commit] Missing layer');

    if (this.pushedMove) {
      user.history.pushLayerId('transform', this.layer.id);
    } else {
      user.history.clearRedos();
    }

    if (op !== 'move-origin') {
      this.model.track?.event<TransformedLayerEvent>(Analytics.TransformLayer, {
        currentTool: this.name.toLowerCase(),
        operation: this.getAnalyticsOperationsString(op),
        source: this.inputMethod,
        layerType: isTextLayer(this.layer) ? 'text' : 'raster',
      });
    }

    const { surface, selection } = user;
    const selectionIsEmpty = isMaskEmpty(selection);
    const { translateX, translateY, scaleX, scaleY, rotate } = surface;

    if (!isOk(translateX) || !isOk(translateY) || !isOk(scaleX) || !isOk(scaleY) || !isOk(rotate)) {
      resetTransform(surface);
      throw new Error(`Invalid transform (${translateX}, ${translateY}, ${scaleX}, ${scaleY}, ${rotate})`);
    }

    this.model.doTool<IMoveToolData>(this.layer.id, {
      id: this.id,
      tx: surface.translateX,
      ty: surface.translateY,
      sx: surface.scaleX,
      sy: surface.scaleY,
      r: surface.rotate,
      replace: this.replace,
      selection: selectionIsEmpty ? SelectionMode.Empty : SelectionMode.Update,
      ar: commitedLayerRect(surface, this.layer),
      op,
      ratio: op === 'scale' ? (this.fixedRatio ? 'shift' : 'none') : undefined,
      bounds: cloneRect(surface.drawingRect)
    });

    redrawLayerThumb(this.layer);
  }

  private getAnalyticsOperationsString(op: string): 'move' | 'rotate' | 'scale' | 'move-origin' | 'cover' | 'fit' | 'reset' {
    if (op.match(/rotate/)) return 'rotate'; // rotate+90, rotate-90
    if (op.match(/flip/)) return 'scale'; // flipX, flipY
    if (op.match(/full/)) return 'reset';
    return op as 'move' | 'rotate' | 'scale' | 'move-origin' | 'cover' | 'fit' | 'reset';
  }
}
