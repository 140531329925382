import { Editor } from '../services/editor';
import { Model } from '../services/model';
import { createViewport } from './create';
import { Drawing, DrawingReference, Layer, LayerData, Viewport } from './interfaces';
import { createMat2d, invertMat2d, transformCoordsByMat2d, transformVec2ByMat2d } from './mat2d';
import { areaOfPolygon, clipPolygon, reversePolygon, setPolygonFrom4Points } from './poly';
import { addRect, createRect, rectContainsXY, resetRect, setRect } from './rect';
import { getTransformedRectBounds } from './toolSurface';
import { createVec2, setVec2 } from './vec2';
import { copyViewport, createViewportMatrix2d, transformViewportFromRef, transformViewportToRef, refTransform } from './viewport';

const tempMat2d = createMat2d();
const tempView2 = createViewport();
const tempPoly1 = [0, 0, 0, 0, 0, 0, 0, 0];
const tempPoly2 = [0, 0, 0, 0, 0, 0, 0, 0];

function getDrawingAreaOnViewport(ref: DrawingReference, view: Viewport) {
  // TODO: check cached board-space bounds first
  //       shortcut for non-rotated view and drawing

  // bias towards center by insetting view area
  const pad = 0.05;
  const size = 1 - pad * 2;
  setPolygonFrom4Points(tempPoly2,
    view.width * pad, -view.height * pad,
    view.width * pad, -view.height * size,
    view.width * size, -view.height * size,
    view.width * size, -view.height * pad,
  );

  const r = ref.drawingRect;
  setPolygonFrom4Points(tempPoly1,
    r.x, r.y,
    r.x, r.y + r.h,
    r.x + r.w, r.y + r.h,
    r.x + r.w, r.y,
  );

  copyViewport(tempView2, view);
  transformViewportToRef(tempView2, ref);
  createViewportMatrix2d(tempMat2d, tempView2);
  transformCoordsByMat2d(tempPoly1, tempMat2d);

  // need to flip the poly upside-down to have conter-clocwise order when Y axis goes from bottom up
  for (let i = 1; i < tempPoly1.length; i += 2) {
    tempPoly1[i] = -tempPoly1[i];
  }

  // we get negative or 0 area if it's not counter-clockwise, reverse in that case
  if (areaOfPolygon(tempPoly1) <= 0) {
    reversePolygon(tempPoly1);
  }

  const clippedPoly = clipPolygon(tempPoly1, tempPoly2);
  return areaOfPolygon(clippedPoly) / (view.width * view.height);
}

function findMostVisibleDrawingInViewport(board: Drawing, boardView: Viewport, threshold: number) {
  let bestId: string | undefined = undefined;
  let bestArea = threshold;

  for (const { ref } of board.layers) { // TODO: we need to precalculate bounding boxes for each ref
    if (!ref || !ref.drawingRect) continue;

    const area = getDrawingAreaOnViewport(ref, boardView);

    if (area > bestArea) { // TODO: this is not very good for rotated images, maybe bias it based on rotation
      bestId = ref.id;
      bestArea = area;
    }
  }

  return bestId;
}

// TODO MULTIBOARD: fix resetting rotation to keep center of the screen

const tempView3 = createViewport();

export function switchActiveBoardDrawingIfNeeded(editor: Editor, model: Model, board: Drawing) {
  const drawingConnectionLimit = 1;

  // switch active drawing
  if (board && !editor.drawingInProgress) { // TODO MULTIBOARD: dragging view also sets drawingInProgress to true
    let switchTo: string | undefined = undefined;

    if (model.drawing !== board) {
      // switch to board if active drawing is zoomed out

      copyViewport(tempView3, editor.view);
      const currentRef = board.layers.find(l => l.ref?.id === model.drawing.id)?.ref;
      if (currentRef) transformViewportFromRef(tempView3, currentRef);
      const area = currentRef?.drawingRect ? getDrawingAreaOnViewport(currentRef, tempView3) : 0;

      if (area < 0.05) { // TODO: adjust area ?
        model.drawing = board;
      } else {
        const best = findMostVisibleDrawingInViewport(board, tempView3, area * 1.1); // more than 10% larger area than active one

        if (best && best !== model.drawing.id) {
          switchTo = best;
        }
      }
    }

    if (!switchTo && model.drawing === board) {
      const best = findMostVisibleDrawingInViewport(board, editor.view, 0.1); // at least 10% of viewport area

      if (best) {
        switchTo = best;
      }
    }

    if (switchTo) {
      const drawing = model.getDrawingById(switchTo);

      if (!drawing) {
        let activeConnections = 0;
        for (const drawing of model.drawings) {
          if (drawing !== board && !drawing.isClosed) {
            activeConnections++;
          }
        }

        if (activeConnections >= drawingConnectionLimit) {
          // TODO: prioritize disconnecting drawings without history ?
          //       also this is causing blinking of some drawings
          //       also closing before opening is causing flashing of tool/layer lists (maybe no-avoidable)
          //       maybe keep drawing.lastActive time and use that too ?
          //       also open drawings on click and then count that higher (lastActive would already handle this properly)
          const toClose = model.drawings.find(d => d !== board && d.id !== switchTo && !d.isClosed);
          if (toClose) model.closeDrawing(toClose).catch(e => DEVELOPMENT && console.error(e));
        }

        model.open(switchTo).catch(e => DEVELOPMENT && console.error(e));
      } else if (drawing.loaded === 1) {
        model.drawing = drawing; // TODO MULTIBOARD: when we're switching to loading drawing it causes issues for entire board
      }
    }
  }
}

const tempMat = createMat2d();
const tempVec = createVec2();
const tempRect = createRect(0, 0, 0, 0);
const tempRect2 = createRect(0, 0, 0, 0);

export function getBoardBounds(drawing: { layers: Layer[] | LayerData[] }) {
  resetRect(tempRect);

  for (const { ref } of drawing.layers) {
    if (!ref || !ref.drawingRect) continue;

    refTransform(tempMat, ref);
    setRect(tempRect2, 0, 0, ref.drawingRect.w, ref.drawingRect.h); // refTransform is already accounting for drawingRect.x/y
    addRect(tempRect, getTransformedRectBounds(tempRect2, tempMat));
  }

  return tempRect;
}

export function getBoardDrawingAt(drawing: Drawing, x: number, y: number) {
  const mat = tempMat, vec = tempVec;

  for (let i = 0; i < drawing.layers.length; i++) {
    const layer = drawing.layers[i];
    const ref = layer.ref;
    if (!ref) continue;

    refTransform(mat, ref);
    invertMat2d(mat, mat);
    setVec2(vec, x, y);
    transformVec2ByMat2d(vec, vec, mat);

    setRect(tempRect2, 0, 0, ref.drawingRect.w, ref.drawingRect.h); // refTransform is already accounting for drawingRect.x/y
    if (rectContainsXY(tempRect2, vec[0], vec[1])) {
      return layer;
    }
  }

  return undefined;
}
