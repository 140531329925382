import { Drawing, DrawingAction, DrawingType, HelpSection, IToolEditor, Layer, User, UserAction } from '../common/interfaces';
import { setActiveLayer } from '../common/user';
import { hasDrawingRole, hasPermission } from '../common/userRole';
import { getLayer, getNewLayerName, hasOwner, setLayerOwner, updateDrawingPersonalVisibility } from '../common/drawing';
import { isLayerVisible, isPerspectiveGridLayer, isTextLayer } from '../common/layer';
import { cloneRect, rectContainsXY, rectIncludesRect } from '../common/rect';
import { LAYER_NAME_LENGTH_LIMIT, getMaxLayers } from '../common/constants';
import { includes } from '../common/baseUtils';
import { getAlpha } from '../common/color';
import { isMaskEmpty } from '../common/mask';
import { logAction } from '../common/actionLog';
import { finishTransform } from '../common/toolUtils';
import { undosLog } from '../common/history';
import { getLayerRect, isLayerEmpty } from '../common/layerUtils';
import { canDisownLayer, canDrawOnLayer, canEdit, canEditLayer, Editor, getActiveFilterTool, isFilterActive, isMyLayer } from './editor';
import { redraw, redrawDrawing } from './editorUtils';
import { helpLockedLayer } from './help';
import { canOwnLayer } from '../common/drawingUtils';
import { canDrawTextLayer, invokeRasterizeFlow, isNonDirtyTextLayer } from '../common/text/text-utils';
import { pointInsidePolygon } from '../common/polyUtils';
import { Subject } from 'rxjs';
import { getAllows } from '../common/userUtils';
import { truncate } from 'lodash';
import { sendDisownLayer, sendLayerOrder, sendOwnLayer, sendSelectLayer } from './drawingConnection';
import { delay } from '../common/promiseUtils';
import { UserError } from '../common/userError';

export const onLayerReordered$ = new Subject<Layer>();

export function selectLayer(editor: IToolEditor, user: User, drawing: Drawing, layer: Layer | undefined, skipChecks = false) {
  const isCurrentDrawing = editor.type === 'client' && (editor as Editor).drawing === drawing;
  const clientEditor = editor as Editor;

  if (!skipChecks && isCurrentDrawing && (!canEdit(clientEditor) || (clientEditor.drawingInProgress && user.activeLayer))) return false;
  if (user.activeLayer === layer) return false;
  if (layer && layer.owner !== user) return false;
  if (layer && !includes(drawing.layers, layer)) return false;

  logAction(`[local] selectLayer (layerId: ${layer?.id})`);

  setActiveLayer(user, layer);
  drawing.selectLayerOnOwn = 0;
  sendSelectLayer(user, drawing, user.activeLayerId);

  if (isCurrentDrawing) {
    if (clientEditor.activeTool?.redrawAlways) {
      redraw(editor);
    }

    if (TESTS || !SERVER) {
      for (const tool of clientEditor.tools) {
        tool.onLayerChange?.(user.activeLayer);
      }

      if (isFilterActive(clientEditor)) {
        logAction(`[local] selectLayer [filter active]`);
        getActiveFilterTool(clientEditor)?.onLayerChange();
        clientEditor.movingView = false;
        clientEditor.drawingInProgress = false; // in case of reconnecting
      }
    }
  }

  return true;
}

export function selectOtherLayer(editor: IToolEditor, user: User, drawing: Drawing, layerId: number) {
  if (!user.activeLayer || user.activeLayerId === layerId) {
    const layers = drawing.layers;
    const index = user.activeLayer ? layers.indexOf(user.activeLayer) : 0;

    const trySelect = (i: number) => {
      if (i >= 0 && i < layers.length && layers[i].owner === user && layers[i].id !== layerId) {
        selectLayer(editor, user, drawing, layers[i]);
        return true;
      } else {
        return false;
      }
    };

    for (let i = 1; i < layers.length; i++) {
      if (trySelect(index + i) || trySelect(index - i)) {
        return;
      }
    }

    selectLayer(editor, user, drawing, undefined);
  }
}

export async function ownLayer(editor: Editor, layer: Layer | undefined, ignoreError = false) {
  try {
    if (canEdit(editor) && !editor.drawingInProgress && layer && canOwnLayer(editor.drawing, editor.model.user, layer)) {
      const { user, drawing } = editor.model;
      logAction(`[local] ownLayer (layerId: ${layer.id})`);
      await sendOwnLayer(user, drawing, layer.id);
      return true;
    }
  } catch (e) {
    if (!ignoreError) {
      editor.helpService.show({ text: e.message, section: HelpSection.Layer });
    } else if (DEVELOPMENT) {
      console.warn('ignored', e.message);
    }
  }

  return false;
}

export async function ownAndSelectLayer(editor: Editor, drawing: Drawing, layer: Layer, user: User) {
  if (layer.owner !== user) {
    const success = await ownLayer(editor, layer);
    if (!success) return false;

    // owners are updated asynchronously, wait for it to complete
    for (let i = 0; i < 10 && layer.owner !== user; i++) {
      await delay(50);
    }
  }

  return selectLayer(editor, user, drawing, layer);
}

export async function disownLayerOn(editor: Editor, user: User, drawing: Drawing, layer: Layer | undefined) {
  if (layer && layer.owner === user) {
    logAction(`[local] disownLayerOn (layerId: ${layer.id}) ${undosLog(user.history)}`);

    if (DEVELOPMENT && user.activeTool) throw new Error('User has active tool on disownLayer');
    editor.textTool.confirmDeferredChange();
    editor.textTool.denyLayerAutoDelete(layer);

    selectOtherLayer(editor, user, drawing, layer.id); // needs to select different layer before finishTransform

    if (user.surface.layer === layer) {
      finishTransform(editor.model, 'disownLayer');
    }

    setLayerOwner(layer, undefined); // need to reset owner after finishTransform
    user.history.clearLayer(layer.id);
    await sendDisownLayer(user, drawing, layer.id);
  }
}

export async function disownLayer(editor: Editor, layer: Layer | undefined) {
  if (canDisownLayer(editor) && !editor.drawingInProgress) {
    const { user, drawing } = editor.model;
    await disownLayerOn(editor, user, drawing, layer);
  }
}

export async function disownAllLayers(editor: Editor, user: User, drawing: Drawing) {
  const ownedLayers = drawing.layers.filter(l => l.owner === user);

  if (drawing !== editor.drawing || (canDisownLayer(editor) && !editor.drawingInProgress)) {
    await Promise.all(ownedLayers.map(layer => disownLayerOn(editor, user, drawing, layer)));
  }
}

export function toggleLayerOpacityLock(editor: Editor, layer: Layer | undefined) {
  if (canEditLayer(editor, layer) && !isFilterActive(editor)) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, opacityLocked: !layer.opacityLocked });
  }
}

export function rasterizeLayer(editor: Editor, layer: Layer | undefined) {
  if (isTextLayer(layer) && canEditLayer(editor, layer)) {
    editor.textTool.rasterizeLocal(layer);
    editor.apply(() => { });
  }
}

export function toggleLayerVisibility(editor: Editor, layer: Layer | undefined) {
  if (canEditLayer(editor, layer)) {
    if (editor.textTool.layer === layer) editor.textTool.focused = !isLayerVisible(layer);
    editor.layerUpdateTool.updateLayer({ id: layer.id, visible: !layer.visible });
  }
}

export function toggleLayerPersonalVisibility(editor: Editor, layer: Layer | undefined) {
  if(layer !== undefined && includes(editor.drawing.layers, layer)) {
    if (layer.visibleLocally === undefined) {
      layer.visibleLocally = !layer.visible;
      layer.personalVisibilityPrev = layer.visibleLocally;
    } else if (layer.visibleLocally === layer.personalVisibilityPrev) {
      layer.visibleLocally = !layer.visibleLocally;
    } else {
      layer.visibleLocally = undefined;
    }
    redrawDrawing(editor.model.drawing);
    updateDrawingPersonalVisibility(editor.model.drawing, layer.id, layer.visibleLocally);
    editor.model.drawingAction<{ layerId: number, visible: boolean | undefined }>(DrawingAction.ChangeLayerPersonalVisibility, {
      layerId: layer.id,
      visible: layer.visibleLocally
    }).catch(e => DEVELOPMENT && console.error(e));
  }
}

export function setLayerPersonalVisibility(editor: Editor, layer: Layer | undefined, visible: boolean | undefined) {
  if(layer !== undefined && includes(editor.drawing.layers, layer) && layer.visibleLocally !== visible) {
    layer.visibleLocally = visible;
    layer.personalVisibilityPrev = visible !== undefined ? !layer.visible : undefined;
    redrawDrawing(editor.model.drawing);
    updateDrawingPersonalVisibility(editor.model.drawing, layer.id, layer.visibleLocally);
    editor.model.drawingAction<{ layerId: number, visible: boolean | undefined }>(DrawingAction.ChangeLayerPersonalVisibility, {
      layerId: layer.id,
      visible: layer.visibleLocally
    }).catch(e => DEVELOPMENT && console.error(e));
  }
}

export function toggleLayerLocked(editor: Editor, layer: Layer | undefined) {
  if (canEditLayer(editor, layer)) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, locked: !layer.locked });
  }
}

export function canToggleLayerClippingGroup(editor: Editor, layer: Layer | undefined): layer is Layer {
  return canEditLayer(editor, layer) && !isPerspectiveGridLayer(layer) && !layer.ref;
}

export function toggleLayerClippingGroup(editor: Editor, layer: Layer | undefined) {
  if (canToggleLayerClippingGroup(editor, layer)) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, clippingGroup: !layer.clippingGroup });
  }
}

export function canChangeLayerMode(editor: Editor, layer: Layer | undefined): layer is Layer {
  return canEditLayer(editor, layer) && !isPerspectiveGridLayer(layer) && !layer.ref;
}

export function setLayerMode(editor: Editor, layer: Layer | undefined, mode: string) {
  if (canChangeLayerMode(editor, layer) && layer.mode !== mode) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, mode });
  }
}

export function setLayerName(editor: Editor, layer: Layer | undefined, name: string) {
  name = truncate(name, { length: LAYER_NAME_LENGTH_LIMIT, omission: '' });

  if (canEditLayer(editor, layer) && layer.name !== name) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, name });
  }
}

export function canChangeLayerOpacity(editor: Editor, layer: Layer | undefined): layer is Layer {
  return canEditLayer(editor, layer) && !layer.ref;
}

export function changeLayerOpacity(editor: Editor, layer: Layer | undefined, oldValue: number, newValue: number) {
  if (layer) layer.opacity = oldValue;

  if (canChangeLayerOpacity(editor, layer) && oldValue !== newValue) {
    editor.layerUpdateTool.updateLayer({ id: layer.id, opacity: newValue });
  }
}

export function clearLayer(editor: Editor, layer: Layer | undefined) {
  if (canDrawOnLayer(editor, layer) && !isLayerEmpty(layer)) {
    if (!layer.locked) {
      editor.layerTool.clear(layer.id);
    } else {
      helpLockedLayer(editor.helpService);
    }
  }
}

function layerBelow(drawing: Drawing, layer: Layer | undefined): Layer | undefined {
  return layer ? drawing.layers[drawing.layers.indexOf(layer) + 1] : undefined;
}

export function canTransferLayer(editor: Editor, layer: Layer | undefined) {
  if (canEdit(editor) && layer) {
    const other = layerBelow(editor.drawing, layer);
    return !!(other && other.owner === layer.owner && !isTextLayer(other) && !isPerspectiveGridLayer(other) && !isPerspectiveGridLayer(layer) && !layer.ref && !other.ref);
  } else {
    return false;
  }
}

export function transferLayer(editor: Editor, layer: Layer | undefined) {
  const otherLayer = layerBelow(editor.drawing, layer);

  if (
    canEditLayer(editor, layer) && !isLayerEmpty(layer) && isLayerVisible(layer) &&
    isMyLayer(editor, otherLayer) && isLayerVisible(otherLayer) && !isTextLayer(otherLayer)
  ) {
    if (!layer.locked && !otherLayer.locked) {
      if (isTextLayer(layer)) {
        invokeRasterizeFlow(editor, layer)
          .then((rasterized) => { if (rasterized) transferLayer(editor, layer); })
          .catch((e) => { DEVELOPMENT && console.error(e); });
      } else {
        const clip = layer.clippingGroup && !otherLayer.clippingGroup;
        editor.layerTool.transfer(layer.id, otherLayer.id, layer.opacity, clip, editor.drawing);
      }
    } else {
      helpLockedLayer(editor.helpService);
    }
  }
}

export const canMergeLayer = canTransferLayer;

export function mergeLayer(editor: Editor, layer: Layer | undefined) {
  const { user, drawing } = editor.model;
  const otherLayer = layerBelow(drawing, layer);

  if (
    canEditLayer(editor, layer) && isLayerVisible(layer) &&
    isMyLayer(editor, otherLayer) && isLayerVisible(otherLayer) && !isTextLayer(otherLayer)
  ) {
    if (!layer.locked && !otherLayer.locked) {
      if (isTextLayer(layer)) {
        invokeRasterizeFlow(editor, layer)
          .then((rasterized) => { if (rasterized) mergeLayer(editor, layer); })
          .catch((e) => { DEVELOPMENT && console.error(e); });
      } else {
        const clip = layer.clippingGroup && !otherLayer.clippingGroup;
        editor.layerTool.merge(layer.id, otherLayer.id, layer.opacity, clip, drawing);
        selectLayer(editor, user, drawing, otherLayer);
      }
    } else {
      helpLockedLayer(editor.helpService);
    }
  }
}

export function canClearLayer(editor: Editor, layer?: Layer) {
  const l = layer ?? editor.activeLayer;
  const editable = (canEdit(editor) && (l ? l.owner === editor.model.user : !!editor.activeLayer));
  const drawable = editable && !!l && !l.locked;
  return drawable && !isTextLayer(l) && !isPerspectiveGridLayer(l) && !l.ref;
}

export function canCreateNewLayerExceptLimit(editor: Editor) {
  return canEdit(editor) && !editor.drawingInProgress;
}

function checkLayerLimit(editor: Editor) {
  const maxLayers = getMaxLayers(getAllows(editor.model.user));
  return editor.drawing.layers.length < maxLayers;
}

// this function skips some checks in order to ignore results from canCreateNewLayerExceptLimit
export async function createNewLayersInsecure(editor: Editor, count: number, shouldFinishTransform: boolean) {
  if (!hasPermission(editor.drawing, editor.model.user, 'addRemoveLayer')) {
    throw new UserError(`You don't have permission to add new layers`);
  }
  if (!checkLayerLimit(editor)) {
    throw new UserError('Layer limit reached');
  }

  try {
    editor.drawingInProgress = true;
    editor.drawingNonCancellable = true;

    const ids = await editor.model.getNewLayerIds(count);
    logAction(`[local] createNewLayers ok (ids: ${ids.join(', ')}, drawingId: ${editor.drawing.id})`);
    if (ids.length !== count) return [];
    if (!editor.drawing.id) return []; // check if drawing is still loaded

    if (shouldFinishTransform) finishTransform(editor.model, 'createNewLayersInsecure');
    return ids;
  } finally {
    logAction(`[local] createNewLayers [finally]`);
    editor.movingView = false;
    editor.drawingInProgress = false;
    editor.drawingNonCancellable = false;
  }
}

export async function createNewLayers(editor: Editor, count: number, can = () => true, shouldFinishTransform = true) {
  logAction('[local] createNewLayers');

  try {
    if (canCreateNewLayerExceptLimit(editor) && can()) {
      return await createNewLayersInsecure(editor, count, shouldFinishTransform);
    } else {
      logAction('[local] createNewLayers blocked');
      return [];
    }
  } catch (e) {
    logAction(`[local] createNewLayers error (${e.message}, ${e.stack})`);
    editor.helpService.show({ text: e.message, section: HelpSection.Layer });
    DEVELOPMENT && !TESTS && console.error(e);
  }

  return [];
}

export async function withNewLayers(editor: Editor, count: number, can: () => boolean, action: (newLayerIds: number[]) => void) {
  logAction('[local] withNewLayer');
  const { drawing } = editor;
  const ids = await createNewLayers(editor, count, can);

  if (ids.length > 0) {
    action(ids);
    return ids.map(id => getLayer(drawing, id));
  } else {
    return [];
  }
}

export function canAddNewLayer(editor: Editor) {
  return canEdit(editor) && editor.drawing.type !== DrawingType.Board;
}

export function addLayer(editor: Editor, autogenerated = false) {
  const layer = editor.activeLayer;
  const index = layer ? editor.drawing.layers.indexOf(layer) : 0;
  const name = getNewLayerName(editor.drawing);
  return withNewLayers(editor, 1, () => true, ([id]) => editor.layerTool.add({ id, name }, index, autogenerated));
}

export async function addAndSelectLayer(editor: Editor, autogenerated = false) {
  const { user, drawing } = editor.model;
  const layers = await addLayer(editor, autogenerated);
  if (layers[0]) selectLayer(editor, user, drawing, layers[0]);
}

export function removeLayer(editor: Editor, layer: Layer | undefined) {
  try {
    if (!canEditLayer(editor, layer)) return;

    const { user, drawing } = editor.model;

    if (!hasPermission(drawing, user, 'addRemoveLayer'))
      throw new UserError(`You don't have permission to remove layers`);

    editor.textTool.denyLayerAutoDelete(layer);
    selectOtherLayer(editor, user, drawing, layer.id);
    editor.layerTool.remove(layer.id);
  } catch (e) {
    editor.helpService.show({ text: e.message, section: HelpSection.Layer });
  }
}

export function trimLayer(editor: Editor, layer: Layer | undefined) {
  try {
    if (!canEditLayer(editor, layer)) return;
    if (rectIncludesRect(editor.drawing, getLayerRect(layer))) return;
    editor.layerTool.trim(layer.id, cloneRect(editor.drawing));
  } catch (e) {
    DEVELOPMENT && console.error(e);
    editor.helpService.show({ text: e.message, section: HelpSection.Layer });
  }
}

export function duplicateLayer(editor: Editor, layer: Layer | undefined) {
  return withNewLayers(editor, 1, () => canEditLayer(editor, layer), ([id]) => editor.layerTool.duplicate(layer!.id, id, editor.drawing));
}

export async function duplicateAndSelectLayer(editor: Editor, layer: Layer | undefined) {
  const { user, drawing } = editor.model;
  const [newLayer] = await duplicateLayer(editor, layer);
  if (newLayer) selectLayer(editor, user, drawing, newLayer);
}

export function canCopyLayer(editor: Editor, layer: Layer | undefined) {
  return canEditLayer(editor, layer) && !isNonDirtyTextLayer(layer ?? editor.activeLayer) && !isPerspectiveGridLayer(layer) && !layer.ref; // && !isMaskEmpty(editor.model.user.selection);
}

export function copyLayer(editor: Editor, layer: Layer | undefined) {
  if (isTextLayer(layer)) {
    if (isMaskEmpty(editor.model.user.selection)) {
      return duplicateLayer(editor, layer);
    } else {
      return invokeRasterizeFlow(editor, layer, true)
        .then((confirmed) => {
          if (confirmed && editor.drawing.layers.includes(layer)) {
            return withNewLayers(editor, 1, () => canCopyLayer(editor, layer), ([id]) => editor.layerTool.copy(layer!.id, id, editor.drawing));
          } else {
            return Promise.resolve([]);
          }
        }).catch((e) => {
          DEVELOPMENT && console.error(e);
          return Promise.resolve([]);
        });
    }
  }
  return withNewLayers(editor, 1, () => canCopyLayer(editor, layer), ([id]) => editor.layerTool.copy(layer!.id, id, editor.drawing));
}

export async function copyAndSelectLayer(editor: Editor, layer: Layer | undefined) {
  const { user, drawing } = editor.model;
  const [newLayer] = await copyLayer(editor, layer);
  if (newLayer) selectLayer(editor, user, drawing, newLayer);
}

export function canCutLayer(editor: Editor, layer: Layer | undefined) {
  return canDrawOnLayer(editor, layer) && !isMaskEmpty(editor.model.user.selection) && !isTextLayer(layer) && !isPerspectiveGridLayer(layer);
}

export function cutLayer(editor: Editor, layer: Layer | undefined) {
  return withNewLayers(editor, 1, () => canCutLayer(editor, layer), ([id]) => editor.layerTool.cut(layer!.id, id, editor.drawing));
}

export async function cutAndSelectLayer(editor: Editor, layer: Layer | undefined) {
  const { user, drawing } = editor.model;
  const [newLayer] = await cutLayer(editor, layer);
  if (newLayer) selectLayer(editor, user, drawing, newLayer);
}

export function canReorderLayers(editor: Editor): boolean {
  const { user, drawing } = editor.model;
  return canEdit(editor) && !editor.drawingInProgress && hasPermission(drawing, user, 'reorderLayers');
}

export function reorderLayers(editor: Editor) {
  logAction('[local] reorder layers');
  const { user, drawing } = editor.model;
  sendLayerOrder(user, drawing);
  redrawDrawing(drawing);
}

// export function trimLayer(editor: Editor, layer: Layer) {
//   if (isLayerEmpty(layer)) return;

//   const bounds = getCanvasBounds(layer.canvas!, layer.rect);

//   if (!rectsEqual(bounds, layer.rect)) {
//     layer.rect = bounds;
//     redrawLayerThumb(layer);
//     editor.model.trimLayer(layer);
//   }
// }

export function pickLayerFromEditor(editor: IToolEditor, x: number, y: number, onlyOwned: boolean, onlyText = false) {
  if (editor.type === 'client') {
    return pickLayer(editor as Editor, x, y, onlyOwned, onlyText);
  } else {
    return undefined;
  }
}

// TODO: this doesn't account for clipping groups
export function pickLayer(editor: Editor, x: number, y: number, onlyOwned: boolean, onlyText = false) {
  if (rectContainsXY(editor.drawing, x, y)) {
    for (let i = 0; i < editor.drawing.layers.length; i++) {
      const layer = editor.drawing.layers[i];
      if (onlyText && !isTextLayer(layer)) continue;

      if ((!onlyOwned || layer.owner === editor.model.user) && !isLayerEmpty(layer) && isLayerVisible(layer)) {
        if (isTextLayer(layer) && canDrawTextLayer(layer) && pointInsidePolygon(x, y, layer.textarea.bounds)) return layer;

        // TODO: check layer and surface rect first here
        const color = editor.renderer.pickColor(editor.drawing, layer, x, y, true);

        if (color && getAlpha(color) > 0) {
          return layer;
        }
      }
    }
  }

  return undefined;
}

function canMoveLayer(editor: Editor, layer: Layer | undefined): boolean {
  return canReorderLayers(editor) && layer !== undefined;
}

export function canMoveLayerUp(editor: Editor, layer: Layer | undefined): layer is Layer {
  return canMoveLayer(editor, layer) && editor.drawing.layers.indexOf(layer!) > 0;
}

export function canMoveLayerDown(editor: Editor, layer: Layer | undefined): layer is Layer {
  const { drawing } = editor.model;
  return canMoveLayer(editor, layer) && drawing.layers.indexOf(layer!) < (drawing.layers.length - 1);
}

export function moveLayerUp(editor: Editor, layer: Layer) {
  const { drawing } = editor.model;
  const index = drawing.layers.indexOf(layer);
  moveLayer(drawing, layer, index - 1, index);
  reorderLayers(editor);
}

export function moveLayerDown(editor: Editor, layer: Layer) {
  const { drawing } = editor.model;
  const index = drawing.layers.indexOf(layer);
  moveLayer(drawing, layer, index + 1, index);
  reorderLayers(editor);
}

export function moveLayerToTop(editor: Editor, layer: Layer) {
  const { drawing } = editor.model;
  const index = drawing.layers.indexOf(layer);
  moveLayer(drawing, layer, 0, index);
  reorderLayers(editor);
}

export function moveLayerToBottom(editor: Editor, layer: Layer) {
  const { drawing } = editor.model;
  const index = drawing.layers.indexOf(layer);
  moveLayer(drawing, layer, drawing.layers.length - 1, index);
  reorderLayers(editor);
}

function moveLayer(drawing: Drawing, layer: Layer, position: number, index: number) {
  drawing.layers.splice(index, 1);
  drawing.layers.splice(position, 0, layer);
  onLayerReordered$.next(layer);
}

export function canKickFromLayer(editor: Editor, layer: Layer | undefined): layer is Layer {
  return hasDrawingRole(editor.model.user, 'admin') && !!layer && hasOwner(layer);
}

export function kickFromLayer(editor: Editor, layer: Layer | undefined) {
  if (canKickFromLayer(editor, layer)) {
    editor.model.tryUserAction(UserAction.KickFromLayer, 0, layer.id)
      .catch(e => DEVELOPMENT && console.error(e));
  }
}

export function canRemoveLayerOwner(editor: Editor, layer: Layer | undefined): layer is Layer {
  return hasDrawingRole(editor.model.user, 'admin') && !!layer && !!layer.layerOwner;
}

export function removeLayerOwner(editor: Editor, layer: Layer | undefined) {
  if (canRemoveLayerOwner(editor, layer)) {
    editor.model.tryUserAction(UserAction.RemoveLayerOwner, 0, layer.id)
      .catch(e => DEVELOPMENT && console.error(e));
  }
}

export function prevLayer(editor: Editor) {
  const { user, drawing } = editor.model;
  const activeLayer = user.activeLayer;
  if (!activeLayer) return;
  const layers = editor.drawing.layers.filter(l => l.owner === user);
  let newIndex = (layers.indexOf(activeLayer) + 1) % layers.length;
  selectLayer(editor, user, drawing, layers[newIndex]);
}

export function nextLayer(editor: Editor) {
  const { user, drawing } = editor.model;
  const activeLayer = user.activeLayer;
  if (!activeLayer) return;
  const layers = editor.drawing.layers.filter(l => l.owner === user);
  let newIndex = layers.indexOf(activeLayer) - 1;
  if (newIndex === -1) newIndex = layers.length - 1;
  selectLayer(editor, user, drawing, layers[newIndex]);
}
