import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { HelpMessage, IHelpService, SuperSimpleModePanel } from '../common/interfaces';
import { ITrackService } from './track.service.interface';
import { Model } from './model';

@Injectable({ providedIn: 'any' })
export class HelpService implements IHelpService {
  messages = new Subject<HelpMessage>();

  constructor(
    private zone: NgZone,
    private trackService: ITrackService,
    private model: Model
  ) { }

  show(message: HelpMessage) {
    this.trackService.handleHelpMessage(message);
    this.zone.run(() => this.messages.next(message));

    if (this.model.isSuperSimpleMode) {
      this.model.editor.activePanel = message.section === 'layer' ? SuperSimpleModePanel.LAYERS : SuperSimpleModePanel.SLIDERS;
    }
  }
}
