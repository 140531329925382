export class YoutubeApi {
  /**
   * @param ytUrl - url in format: https://www.youtube.com/watch?v=dQw4w9WgXcQ
   */
  static getVideoId(ytUrl: string) {
    try {
      const url = new URL(ytUrl);
      if (url.origin.endsWith('youtube.com')) {
        return new URLSearchParams(url.search).get('v') ?? undefined;
      } else if (url.origin.endsWith('youtu.be')) {
        return url.pathname.split('/')[1];
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  }

  static getEmbeddedVideoUrl(videoId: string, autoplay = false) {
    const url = new URL(`https://www.youtube-nocookie.com/embed/${videoId}`);
    if (autoplay) url.searchParams.append('autoplay', '1');
    return url.toString();
  }
}
