import { Allows, BrushBlendMode, BrushFeature, BrushToolSettings, CommonDrawingData, CursorType, DrawingType, SurveyId, Feature } from './interfaces';
import { setFlag } from './baseUtils';
import { faTimesOctagon } from './icons';

// https://colorbrewer2.org/#type=diverging&scheme=RdYlBu&n=11
export const brewerColors = [
  'hsl(3, 69%, 50%)',
  'hsl(8, 63%, 57%)',
  'hsl(14, 89%, 61%)',
  'hsl(18, 84%, 73%)',
  'hsl(28, 94%, 36%)',
  'hsl(30, 98%, 69%)',
  'hsl(32, 84%, 48%)',
  'hsl(33, 87%, 29%)',
  'hsl(35, 62%, 46%)',
  'hsl(42, 60%, 68%)',
  'hsl(44, 98%, 78%)',
  'hsl(60, 100%, 87%)',
  'hsl(87, 60%, 70%)',
  'hsl(90, 49%, 50%)',
  'hsl(97, 63%, 35%)',
  'hsl(108, 49%, 88%)',
  'hsl(114, 45%, 74%)',
  'hsl(125, 34%, 52%)',
  'hsl(138, 63%, 29%)',
  'hsl(171, 44%, 65%)',
  'hsl(171, 45%, 85%)',
  'hsl(175, 48%, 40%)',
  'hsl(175, 98%, 20%)',
  'hsl(195, 58%, 79%)',
  'hsl(200, 54%, 72%)',
  'hsl(201, 51%, 88%)',
  'hsl(203, 52%, 51%)',
  'hsl(203, 50%, 64%)',
  'hsl(210, 68%, 40%)',
  'hsl(237, 51%, 39%)',
  'hsl(251, 30%, 75%)',
  'hsl(254, 26%, 56%)',
  'hsl(268, 55%, 34%)',
  'hsl(281, 30%, 73%)',
  'hsl(282, 26%, 55%)',
  'hsl(291, 51%, 34%)',
  'hsl(297, 30%, 87%)',
  'hsl(323, 68%, 83%)',
  'hsl(325, 76%, 44%)',
  'hsl(326, 99%, 28%)',
  'hsl(328, 61%, 67%)',
  'hsl(346, 100%, 32%)',
];

// color value, text color, color name
const COLORS: [string, string, string][] = [
  ['#ff5bb6', '#ffffff', 'pink'],
  ['#de3bff', '#ffffff', 'magenta'],
  ['#cc78ff', '#ffffff', 'orchid'],
  ['#9879ff', '#ffffff', 'indigo'],
  ['#5c57ec', '#ffffff', 'royal blue'],
  ['#4676ff', '#ffffff', 'blue'],
  ['#46baff', '#ffffff', 'cyan'],
  ['#2cf7d5', '#222222', 'turquoise'],
  ['#40ffb1', '#222222', 'aquamarine'],
  ['#37d973', '#ffffff', 'jade'],
  ['#3db74c', '#ffffff', 'green'],
  ['#97ff40', '#222222', 'lime'],
  ['#f4f41a', '#222222', 'lemon'],
  ['#ffda40', '#222222', 'yellow'],
  ['#ffaf00', '#ffffff', 'tangerine'],
  ['#ff7200', '#ffffff', 'orange'],
  ['#ff4043', '#ffffff', 'scarlet'],
  ['#d71010', '#ffffff', 'red'],
];

export const USER_COLORS = COLORS.map(x => x[0]);
export const USER_COLORS_TEXT: { [key: string]: string | undefined; } = {};
export const USER_COLORS_NAMES: { [key: string]: string | undefined; } = {};

for (const [color, text, name] of COLORS) {
  USER_COLORS_TEXT[color] = text;
  USER_COLORS_NAMES[color] = name;
}

if (DEVELOPMENT) {
  USER_COLORS.forEach(c => !USER_COLORS_TEXT[c] && console.warn('Missing USER_COLORS_TEXT for', c));
}

export const USER_JOBS = [
  'Student',
  'Educator',
  'Freelancer',
  '2D artist / illustrator',
  '3D artist',
  'Art Director / Lead Artist',
  'Director / Manager',
  'C-Level Executive',
  'Other',
];

// @ignore-translation
export const PRESSURE_APIS = {
  ext: 'Chrome extension',
  pen: 'Native support',
  hid: 'WebHID',
  force: 'Touch force',
  none: '',
};

// @ignore-translation
export const RENDERER_APIS = {
  'webgl': 'WebGL',
  'webgl2': 'WebGL 2',
  '2d-off': '2D (off)',
  '2d-fail': '2D (fail)',
  'webgpu': 'WebGPU',
};

export const DEFAULT_USER_COLOR = USER_COLORS[0];

export const BACKGROUND_COLORS: { name: string; value: string; }[] = [
  { name: 'white', value: '#ffffff' },
  { name: 'light gray', value: '#c1c1c1' },
  { name: 'gray', value: '#7f7f7f' },
  { name: 'dark gray', value: '#3e3e3e' },
  { name: 'black', value: '#000000' },
  { name: 'light green', value: '#dae5d6' },
  { name: 'transparent', value: '' },
];

// colors
export const TRANSPARENT = 0x00000000;
export const BLACK = 0x000000ff;
export const WHITE = 0xffffffff;
export const WHITE_FLOAT = new Float32Array([1, 1, 1, 1]);
export const WHITE_STR = '#ffffff';

export const RED = 0xff0000ff;
export const BLUE = 0x0000ffff;
export const ORANGE = 0xffa500ff;

export const DEFAULT_BACKGROUND_COLOR = BACKGROUND_COLORS[0].value;
export const DEFAULT_LAYER_COLOR = '#888';
export const DEFAULT_CANVAS_WIDTH = 1920;
export const DEFAULT_CANVAS_HEIGHT = 1080;
export const DEFAULT_DRAWING_NAME = 'New Drawing';
export const DEFAULT_DPI = 72;

export const DEFAULT_STABILIZE = 0.34;

export const LIGHT_BACKGROUND = '#ccc';
export const DARK_BACKGROUND = '#222';

export const BRUSH_SIZES = [
  0.7, 0.8, 1, 1.5, 2, 2.3, 2.6, 3, 3.5, 4, 5, 6, 7, 8, 9, 10,
  12, 14, 16, 20, 25, 30, 35, 40, 50, 60, 70, 80, 100, 120,
  160, 200, 250, 300, 350, 400, 450, 500, 600, 800, 1000
];

export const PENCIL_SIZES = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  12, 14, 16, 20, 25, 30, 35, 40, 50, 60, 70, 80, 100, 120,
];

export const KB = 1024;
export const MB = 1024 * KB;
export const GB = 1024 * MB;

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;

export const GENERATED_AVATARS = IS_HOSTED;
export const AVATAR_DEFAULT = GENERATED_AVATARS ? 'none.png' : 'default.png';
export const AVATAR_BACKGROUND = '#454545';
export const AVATAR_MAX_FILE_SIZE = 1 * MB;

export const MASK_COORD_LIMIT = 100000; // -0x080000..0x07ffff is the limit when encoding masks

// cursors
export const USER_CURSOR_RADIUS = 15;
export const USER_NAME_OFFSET = USER_CURSOR_RADIUS - 6;
export const USER_NAME_WIDTH = 128;
export const USER_NAME_HEIGHT = 28;
export const CURSOR_AVATAR_LARGE_HEIGHT = 42;
export const CURSOR_FADE_DISTANCE = 60;
export const CURSOR_FADE_PAD = 60;
export const CURSOR_VIDEO_HEIGHT = 80;

// other limits
export const UNDO_LIMIT = 15;
export const USER_NAME_LENGTH_LIMIT = 32;
export const LAYER_NAME_LENGTH_LIMIT = 32;
export const RECENT_DRAWING_LIMIT = 10;
export const CHAT_MESSAGE_LENGTH_LIMIT = 250;
export const CHAT_MESSAGE_HISTORY_LIMIT = 100;
export const MAX_DRAWING_BACKGROUND_LENGTH = 32;
export const MAX_DRAWING_SAMPLES = 100000;
export const MAX_LASSO_SAMPLES = 5000;
export const MAX_SERVER_SIDE_SAMPLES = 150000;
export const MAX_FREE_SEQUENCE_DRAWINGS = 4;
export const MAX_IMPORT_SEQUENCE_DRAWINGS = 100;
export const MAX_FREE_IMPORT_SEQUENCE_DRAWINGS = MAX_FREE_SEQUENCE_DRAWINGS;
export const MAX_FREE_VOICE_CALL_USERS = 2;

export const MAX_ENTITY_NAME_LENGTH = 50;
export const MAX_PROJECT_NAME_LENGTH = 50;
export const MAX_ENTITY_PASSWORD_LENGTH = 16;
export const MAX_REVISION_LABEL_LENGTH = 64;
export const REVISION_VALID_TIME_FOR_FREE_USERS = 7 * DAY;
export const AUTOMATIC_REVISION_NAME = 'Auto-saved Version';

// @ignore-translation
export const IMPORT_FILE_TYPES = 'image/png,image/jpeg,image/gif,.psd,.pdf';

export const EMAIL_THUMB_RENDER_TYPE = 'thumbnail.email.png';

// @ignore-translation
export const EXPORT_TYPES = [
  'json',
  'zip', 'png', 'jpg', 'psd', 'pdf', 'thumbnail.png', EMAIL_THUMB_RENDER_TYPE, // drawings
  'text', 'svg', 'code.png', 'code.pdf', 'code.svg' // flowcharts
];

export const THUMBNAIL_TYPE_REGEX = /^thumbnail\.\d+\.png$/;

// @ignore-translation
export const EXPORT_TYPES_PRO = ['zip'];

export const FLUSH_TIMEOUT = IS_HOSTED ? 10 : 40; // ms, time after which tool moves get sent to server as a batch

export const MIN_VIEW_SCALE = 0.01;
export const MAX_VIEW_SCALE = 32.0;
export const DRAG_ZOOM_RATIO = 0.0005;
export const CLICK_ZOOM_RATIO = 0.02;
export const HARD_BRUSH_THRESHOLD = 0.95;

// size limits
export const MAX_LAYER_SIZE = 16384; // absolute limits supported by the application (for sanity checks)

// 87% of texture is usable data
export const DEFAULT_TEXTURE_TILE_SIZE = 960;
export const DEFAULT_TEXTURE_TILE_MARING_SIZE = 32;

export const MAX_LAYER_WIDTH = 16384; // absolute limits supported by the application (for sanity checks)
export const MAX_LAYER_HEIGHT = 16384;

export const MIN_IMAGE_WIDTH = 1;
export const MIN_IMAGE_HEIGHT = 1;

export const MAX_IMAGE_OFFSET = MAX_LAYER_WIDTH * 6;

export const MAX_IMAGE_WIDTH = Math.min(MAX_LAYER_WIDTH, 16384);
export const MAX_IMAGE_HEIGHT = Math.min(MAX_LAYER_HEIGHT, 16384);

export const MAX_IMAGE_WIDTH_PRO = Math.min(MAX_IMAGE_WIDTH, 8192);
export const MAX_IMAGE_HEIGHT_PRO = Math.min(MAX_IMAGE_HEIGHT, 8192);
export const MAX_IMAGE_WIDTH_FREE = IS_HOSTED ? MAX_IMAGE_WIDTH_PRO : Math.min(MAX_IMAGE_WIDTH, 4096);
export const MAX_IMAGE_HEIGHT_FREE = IS_HOSTED ? MAX_IMAGE_HEIGHT_PRO : Math.min(MAX_IMAGE_HEIGHT, 4096);

// layer count limits
export const MAX_IMAGE_LAYERS_PRO = 200;
export const MAX_IMAGE_LAYERS_FREE = IS_HOSTED ? MAX_IMAGE_LAYERS_PRO : 60;
export const MAX_IMAGE_LAYERS_ADMIN = 1000;

export function getMaxLayers(allows: Allows) {
  if (allows?.isSuperAdmin) return MAX_IMAGE_LAYERS_ADMIN;
  return allows?.pro ? MAX_IMAGE_LAYERS_PRO : MAX_IMAGE_LAYERS_FREE;
}

// allow 5 new layers per minute
export const NEW_LAYERS_LIMIT_TIMEOUT = 1 * MINUTE;
export const NEW_LAYERS_LIMIT_COUNT = 5;

// session limits
export const MAX_SESSIONS_FREE = 3;
export const MAX_SESSIONS_BLAZE = 5;
export const MAX_SESSIONS_FUSION = 1000000;

export const MAX_USERS_PER_DRAWING_FREE = IS_HOSTED ? 50 : 30; // when changing adjust limits for name plates (max 64 now)
export const MAX_USERS_PER_DRAWING_PRO = IS_HOSTED ? 50 : 50;

export function getMaxUsersPerDrawing(pro: boolean) {
  return pro ? MAX_USERS_PER_DRAWING_PRO : MAX_USERS_PER_DRAWING_FREE;
}

// upload limits
export const MAX_IMPORT_SIZE_PRO = 500 * MB;
export const MAX_IMPORT_SIZE_FREE = IS_HOSTED ? MAX_IMPORT_SIZE_PRO : (25 * MB);
export const MAX_PASTE_SIZE_PRO = 500 * MB;
export const MAX_PASTE_SIZE_FREE = IS_HOSTED ? MAX_PASTE_SIZE_PRO : (25 * MB);
export const MAX_SHARE_SIZE = 100 * MB;
export const MAX_PASTE_IMAGE_SIZE = MAX_IMAGE_WIDTH;
export const MAX_CHUNKED_DATA_SIZE = Math.max(MAX_IMPORT_SIZE_FREE, MAX_IMPORT_SIZE_PRO, MAX_SHARE_SIZE, MAX_PASTE_SIZE_PRO);

export function getMaxImportSize(allows: Allows) {
  return allows.pro ? MAX_IMPORT_SIZE_PRO : MAX_IMPORT_SIZE_FREE;
}

export function isValidImageSizeForPaste(width: number, height: number) {
  return width <= MAX_PASTE_IMAGE_SIZE && height <= MAX_PASTE_IMAGE_SIZE;
}

export const MIN_DPI = 1;
export const MAX_DPI = 10000;

// @ignore-translation
const proCustomShapes = new Set([
  ...[
    'L_Body_003', 'L_Body_004', 'L_Body_005', 'L_Body_006', 'L_Body_008',
    'L_Body_009', 'L_Body_010', 'L_Body_012',
    'L_Heads_004', 'L_Heads_005', 'L_Heads_006', 'L_Heads_008', 'L_Heads_009',
    'L_Legs_002', 'L_Legs_003', 'L_Legs_005', 'L_Legs_007', 'L_Legs_008',
    'L_Legs_009', 'L_Legs_010', 'L_Legs_011', 'L_Legs_012', 'L_Legs_013',
    'L_Legs_015', 'L_Legs_016',
    'M_Body_001', 'M_Body_003', 'M_Body_006', 'M_Body_007',
    'M_Body_009', 'M_Body_010', 'M_Body_011', 'M_Body_012', 'M_Body_013',
    'M_Body_014', 'M_Body_015', 'M_Body_016', 'M_Body_017', 'M_Body_018',
    'M_Heads_002', 'M_Heads_004', 'M_Heads_005', 'M_Heads_006',
    'M_Heads_008', 'M_Heads_009', 'M_Heads_010', 'M_Heads_011',
    'M_Heads_015', 'M_Heads_016', 'M_Heads_017',
    'M_Legs_002', 'M_Legs_003', 'M_Legs_006', 'M_Legs_008', 'M_Legs_009',
    'M_Legs_010', 'M_Legs_011', 'M_Legs_012', 'M_Legs_014', 'M_Legs_015',
    'S_Body_003', 'S_Body_004', 'S_Body_006', 'S_Body_008', 'S_Body_009',
    'S_Body_010', 'S_Body_011', 'S_Body_012',
    'S_Heads_004', 'S_Heads_005', 'S_Heads_006', 'S_Heads_007', 'S_Heads_008',
    'S_Heads_009', 'S_Heads_010', 'S_Heads_011', 'S_Heads_012', 'S_Heads_013',
    'S_Heads_014', 'S_Heads_016',
    'S_Legs_002', 'S_Legs_005', 'S_Legs_007', 'S_Legs_008', 'S_Legs_009',
    'S_Legs_010',
  ].map(x => `silvertoons-${x.toLocaleLowerCase()}`),
]);

export const isProCustomShape = (id: string) => proCustomShapes.has(id);
export const isProLassoPattern = (_id: string) => false;

export const REVISION_AUTO_INTERVAL = HOUR;
export const REVISION_TIMELAPSE_INTERVAL = 1 * MINUTE;

export const ANONYMOUS_USER_EXPIRE_AGE = 120 * DAY;
export const MAX_SESSION_AGE = IS_HOSTED ? (120 * DAY) : (120 * DAY);
export const SECURITY_DELAY = 200; // ms
export const ACTIVE_DRAWING_TIMEOUT = 5 * MINUTE;
export const SAVE_DRAWING_TIMEOUT = DEVELOPMENT ? SECOND : (IS_HOSTED ? 5 * SECOND : 10 * SECOND);
export const ACTIVE_PERIOD = 30 * DAY;
export const MAX_BIN_FOLDER_AGE = 30 * DAY;

export const ROTATION_SNAP = Math.PI / 12;
export const SAVED_VIEWPORT_LIMIT = 30;
export const LAYER_THUMB_SIZE = 40;

export const SEQUENCE_THUMB_SIZE = 256;
export const SEQUENCE_THUMB_WIDTH = 200 - 6;
export const SEQUENCE_THUMB_HEIGHT = 137 - 27;

// from _variables.scss
export const EDITOR_TOP_MENU_HEIGHT = 43;
export const EDITOR_LEFT_MENU_WIDTH = 39;
export const EDITOR_COLOR_SELECTOR_HEIGHT = 232;
export const EDITOR_TOOL_OPTIONS_SIMPLE_HEIGHT = 72;
export const EDITOR_TOOL_OPTIONS_FULL_HEIGHT = 195;

export const PDF_MAX_SIZE = 4096;

export const TOOL_SLOTS = 6;

export const DEFAULT_DRAWING_DATA: CommonDrawingData = {
  _id: '',
  id: '',
  name: '',
  type: DrawingType.Drawing,
  x: 0,
  y: 0,
  w: 100,
  h: 100,
  background: '#ffffff',
  dpi: DEFAULT_DPI,
  layers: [],
};

export const SLEEP_TIMEOUT = IS_HOSTED ? 0 : (5 * HOUR);
export const THUMB_CACHE_TIME = 3 * SECOND;
export const USE_SERVER_GL = true;
export const SHOW_CURSOR_UNMOVING_TIMEOUT = 30 * SECOND;

// @ignore-translation
export const LOG_FILES = [
  'long-tools.log', 'daily-stats.log', 'monthly-stats.log', 'tablets.log', 'dc.log',
  'dcs.log', 'admin.log', 'disk-io.log', 'apis.log', 'image-loaders.log', 'psds.log',
  'emails.log', 'requested-with.log',
];

export const TASK_CANCELED_ERROR = 'Task was canceled';
export const MAINTENANCE_ERROR = 'Server is undergoing maintenance, please check back later.';
export const PASSWORD_ERROR = 'Drawing is password protected';
export const BLOCKED_ERROR = 'Cannot connect, you are blocked from this drawing';
export const CLIENT_LIMIT_ERROR = 'Cannot connect, too many people are using the application. Try again later.';
export const SESSION_LIMIT_ERROR = 'Cannot connect, you reached limit of active tabs';
export const SIGN_IN_TO_VIEW_ERROR = `Anonymous users can't view documents, please sign in first.`;
export const ALREADY_SUBSCRIBED_ERROR = `Subscription is already active.`;
export const DRAWING_DELETED = 'Drawing is deleted';
export const DRAWING_REVISION_RESTORING = 'Drawing revision is being restored';
export const DRAWING_NOT_FOUND = 'Drawing not found';
export const DRAWING_NO_ACCESS = `You don't have access to view this drawing`;
export const ACCESS_DENIED = `Access denied. You don't have sufficient permissions to perform this action`;
export const SEQUENCE_FRAME_LIMIT = 'Sequences with more than four frames are paid feature';
export const OPTION_LOCKED_TOOLTIP = 'This option is currently locked due to Drawing permissions. To request access, contact Drawing Owner.';
export const OPTION_LOCKED_ARTDESK_TOOLTIP = 'Currently, only the Drawing Owner can access and manage Version History of any canvas located in My Artdesk section.';
export const ACCESS_DENIED_ACCEPT_RULES = `Access denied. You need to accept Art Jam rules to open this drawing`;
export const ACCESS_DENIED_MISSING_BIRTHDATE = 'Access denied. To join Art Jam you need to set birth date';
export const ACCESS_DENIED_INVALID_BIRTHDATE = 'Your date of birth does not comply with our Mature Content Policy. To change it, contact Magma Support.';
export const ACCESS_DENIED_ANONYMOUS_USER = `Anonymous users can't view mature content, please sign in first.`;

export const REMOVED_USER_NAME = 'User removed';
export const ANONYMOUS_USER_NAME = 'Anonymous';

export const userLimitError = (count: number) => `User limit reached, maximum of ${count} people can draw on the same canvas.`;
export const isUserLimitError = (error: string) => error.startsWith(`User limit reached, maximum of `);

// @ignore-translation
export const DEFAULT_FONT = `'Helvetica Neue', Helvetica, Arial, sans-serif`;

export const MIN_BRUSH_SIZE = 0.1;
export const MAX_BRUSH_SIZE = 2000;
export const MIN_BRUSH_SPACING = 0.001;
export const MAX_BRUSH_SPACING = 10;
export const MIN_STROKE_WIDTH = 1;
export const MAX_STROKE_WIDTH = 100;
export const MAX_SPREAD = 10;
export const MIN_PATTERN_SCALE = 2;
export const MAX_PATTERN_SCALE = 100;
export const BRUSH_IMAGE_SIZE = 170;
export const BRUSH_TEXTURE_SIZE = 512;

// @ignore-translation
export const ACCOUNT_EXPORT_COLUMNS = [
  'id', 'name', 'email', 'unverified email', 'current email verified', 'current email', 'created', 'last visit',
  'last active', 'created drawings', 'participated in drawings', 'cancellation reason',
  ...(IS_HOSTED ? [] : ['pro', 'pro until', 'stripe id', 'consent', 'job', 'work tags', 'tags']),
  'features', 'lastOrigin', 'country',
];

export const EDUCATION_LINK = 'https://magma.com/contact/education';
export const CHANGELOG_LINK = IS_HOSTED ? 'https://magm.ai/changelog-ext' : 'https://magm.ai/changelog';

export const DEFAULT_BRUSH_TOOL_SETTINGS: BrushToolSettings = {
  _id: '', name: '', sizePressure: true, sizeJitter: 0, minSize: 0, flow: 1, flowPressure: false, opacity: 1,
  opacityPressure: false, spacing: 0.2, hardness: 1, separateSpread: false, normalSpread: 0, tangentSpread: 0,
  shape: '', angle: 0, angleJitter: 3.14, angleToDirection: false,
  colorPressure: false, foregroundBackgroundJitter: 0, hueJitter: 0, saturationJitter: 0, brightnessJitter: 0,
  flipX: false, flipY: false, roundness: 1,
  hasFeatures: BrushFeature.BrushTipShape, lockedFeatures: 0, blendMode: BrushBlendMode.Normal,
};

const b = DEFAULT_BRUSH_TOOL_SETTINGS;

function nonDefault(brush: BrushToolSettings, prop: keyof BrushToolSettings) {
  return brush[prop] !== DEFAULT_BRUSH_TOOL_SETTINGS[prop];
}

export function setBrushFeaturesFromProps(brush: BrushToolSettings) {
  brush.hasFeatures = 0;
  brush.hasFeatures = setFlag(brush.hasFeatures, BrushFeature.BrushTipShape, true); // always true

  const hasShapeDynamics = nonDefault(brush, 'sizeJitter') || nonDefault(brush, 'minSize') || brush.angleJitter != 0 || nonDefault(brush, 'angleToDirection') || nonDefault(brush, 'sizePressure');
  brush.hasFeatures = setFlag(brush.hasFeatures, BrushFeature.ShapeDynamics, hasShapeDynamics);

  const hasScattering = nonDefault(brush, 'normalSpread') || nonDefault(brush, 'tangentSpread') || nonDefault(brush, 'separateSpread');
  brush.hasFeatures = setFlag(brush.hasFeatures, BrushFeature.Scattering, hasScattering);

  const hasColorDynamics = nonDefault(brush, 'foregroundBackgroundJitter') || nonDefault(brush, 'hueJitter') || nonDefault(brush, 'saturationJitter') || nonDefault(brush, 'brightnessJitter') || nonDefault(brush, 'colorPressure');
  brush.hasFeatures = setFlag(brush.hasFeatures, BrushFeature.ColorDynamics, hasColorDynamics);

  // unsupported yet
  brush.hasFeatures = setFlag(brush.hasFeatures, BrushFeature.Texture, false);
  brush.hasFeatures = setFlag(brush.hasFeatures, BrushFeature.DualBrush, false);
  brush.hasFeatures = setFlag(brush.hasFeatures, BrushFeature.Transfer, false);
  brush.hasFeatures = setFlag(brush.hasFeatures, BrushFeature.BrushPose, false);

  return brush;
}

// TODO: define which brush features these have, and also other brushes not only default
export const DEFAULT_BRUSHES: BrushToolSettings[] = [
  { ...b, name: 'ink' },
  { ...b, name: 'soft ink', minSize: 0.2, hardness: 0.5 },
  { ...b, name: 'soft', sizePressure: false, flowPressure: true, opacity: 0.5, hardness: 0 },
  { ...b, name: 'softer', sizePressure: false, flow: 0.3, flowPressure: true, opacity: 0.5, hardness: 0 },
  { ...b, name: 'rough', shape: 'brush' },
  { ...b, name: 'rougher', sizeJitter: 0.5, shape: 'brush' },
  { ...b, name: 'thick rough', sizePressure: false, flowPressure: true, shape: 'brush' },
  { ...b, name: 'thin graphite', size: 6, sizeJitter: 0.75, flowPressure: true, spacing: 0.3, shape: 'brush' },
  { ...b, name: 'pencil', size: 10, sizeJitter: 0.5, flowPressure: true, shape: 'pencil' },
  { ...b, name: 'soft pencil', size: 10, sizeJitter: 0.5, minSize: 0.2, flowPressure: true, opacity: 0.5, shape: 'pencil' },
  { ...b, name: 'splotchy', sizePressure: false, sizeJitter: 0.3, flow: 0.5, flowPressure: true, shape: 'paw' },
  { ...b, name: 'splatter', size: 40, sizeJitter: 0.5, normalSpread: 0.5, shape: 'splothes' },
  { ...b, name: 'pawprints', sizePressure: false, sizeJitter: 0.25, spacing: 1.75, separateSpread: true, normalSpread: 3, shape: 'paw', angleJitter: 0, angle: 1.57, angleToDirection: true },
  { ...b, name: 'hearts', sizePressure: false, sizeJitter: 0.3, flowPressure: true, spacing: 0.5, normalSpread: 3.5, shape: 'heart', angleJitter: 0.46 },
].map(setBrushFeaturesFromProps);

export const FALLBACK_CURSOR_SISE = 32;
export const FALLBACK_CURSORS = [
  CursorType.Default,
  CursorType.Eyedropper,
  CursorType.Move,
  CursorType.ResizeTR,
  CursorType.ResizeV,
  CursorType.ResizeTL,
  CursorType.ResizeH,
  CursorType.Hand,
];
export const FALLBACK_CURSORS_OFFSETS = [
  { x: 0, y: 0 },
  { x: 0, y: 31 },
  { x: 11, y: 11 },
  { x: 8, y: 8 },
  { x: 4, y: 11 },
  { x: 8, y: 8 },
  { x: 11, y: 4 },
  { x: 5, y: 0 },
];

export const CACHE_ID_LENGTH = 5;

// New Features
export const TEST_NEW_LAYER_RATE_LIMIT = false;

// Development switches
export const TEST_PRESSURE_OPACITY = DEVELOPMENT && false;
export const TEST_PNG_DECODE = DEVELOPMENT && false;
export const TEST_ALPHA_SURFACES = DEVELOPMENT && false; // this won't work because webgl can't render to alpha texture
export const USE_SEPARATE_SOCKETS = DEVELOPMENT && false;
export const PENCIL_LOG = false;
export const USER_BADGE_REGEX = /\[user\]\(([A-Za-z0-9]{24})\)/g;

// Filters constants
export const GAUSSIAN_BLUR_MIN = 0;
export const GAUSSIAN_BLUR_MAX = 100;
export const MOTION_BLUR_DISTANCE_MIN = 0;
export const MOTION_BLUR_DISTANCE_MAX = 2000;
export const MOTION_BLUR_ANGLE_MIN = 0;
export const MOTION_BLUR_ANGLE_MAX = 360;
export const GAUSSIAN_BLUR_STEP = 0.1;
export const HUE_FILTER_MAX = 180;
export const HUE_FILTER_MIN = -180;
export const SATURATION_FILTER_MAX = 100;
export const SATURATION_FILTER_MIN = -100;
export const LIGHTNESS_FILTER_MAX = 100;
export const LIGHTNESS_FILTER_MIN = -100;
export const BRIGHTNESS_FILTER_MAX = 100;
export const BRIGHTNESS_FILTER_MIN = -100;
export const CONTRAST_FILTER_MAX = 100;
export const CONTRAST_FILTER_MIN = -100;
export const BILLING_REVALIDATE_TIME = 10 * DAY;

// Presentation Mode
export const PRESENTATION_VIEWER_INVITE_TIME = 30 * SECOND;
export const PRESENTATION_VIEWER_FORCED_INVITE_TIME = 20 * SECOND;
export const PRESENTATION_INACTIVE_HOST_TIME = 5 * MINUTE; // host AFK time
export const PRESENTATION_INACTIVE_HOST_MODAL_TIME = 3 * MINUTE;
export const PRESENTATION_JOIN_TIME = 20 * SECOND;
export const PRESENTATION_HOST_DISCONNECTED_TIME = 20 * SECOND;
export const PRESENTATION_TAKE_OVER_TIME = 3 * MINUTE;

// Teams
export const TEAM_DEFAULT_PROJECT_NAME = 'Art Lobby';

// @ignore-translation
export enum UserFlowId {
  ConferenceModeChecklist = '186fc35b-8442-4895-bcb4-d0d1f199429f', // used in conference mode
  ConferenceModeFlow = 'f52d397f-0b06-452c-9482-35836abb586e',

  AiChecklist = 'ab13eeba-4567-4681-a942-20f5e0fb6948',
  AiExperiencePrompt = '56e15aa7-161d-400c-a551-ec6f40f01424', // asks if user wants to complete the flow at start
  AiExperienceDenied = 'e08ceaed-5922-4a0f-82c4-9e1748cad4f8', // if user denies AiExperiencePrompt at beginning userflow launches this to say "ok, here are the docs if you want"
  AiCreate = '45155b5f-af54-4ab7-91ad-c8f16ff79908',
  AiEnhance = '8accfd9a-d761-41aa-bab8-b85f96c30ad1',
  AiInpaint = 'f7025db9-c32b-4a93-8681-48f6d06ea28a',
  AiOutpaint = 'd45652aa-fae6-424a-8351-fe3c12e221cc',
  AiAllModes = '472a10da-4b00-4112-bd5a-e4eedf81471d',

  OnboardingMode = '490b8d68-7144-4856-ac52-9268530c56c9',
  ConferenceMode2 = '226d2203-5295-4d81-bdc6-04aab3907381',
}

// @ignore-translation
export enum UserflowEvent {
  NavigateToDrawingByName = 'navigate-to-drawing-by-name',
  InvokeConferenceLogout = 'invoke-conference-logout',
  JumpToDemoDrawing = 'jump-to-demo-drawing',
  SetEditorSettings = 'set-editor-settings',
  UserflowContentEnded = 'userflow-content-ended', // Content is flow or checklist. This is emitted not on window but instead on userflow object
  CompletedOnboardingFlow = 'completed-onboarding-flow',
  ClearInpaintingMask = 'clear-inpainting-mask',
  SelectLayerWithIndex = 'select-layer-with-index',
  CreateLayerAtTheBottom = 'create-layer-at-the-bottom',
  SelectAiRenderMode = 'select-ai-render-mode',
  EnsureToolsInToolbar = 'ensure-tools-in-toolbar',
  EnsureTwoColumns = 'ensure-two-columns',
  EnsureSequencePanel = 'ensure-sequence-panel',
  EnsureSlidersSettings = 'ensure-sliders-settings',
  RedirectTo = 'redirect-to',
  SelectTool = 'select-tool',
}

export const ONBOARDING_FLOWS_IDS = Object.entries(UserFlowId);
export const AI_ONBOARDING_FLOWS_IDS = ONBOARDING_FLOWS_IDS.filter(([k]) => k.startsWith('Ai'));
export const isUserflowId = (str: any): str is UserFlowId => str && ONBOARDING_FLOWS_IDS.map(([, v]) => v).includes(str);

// Reference window
export const REFERENCE_WINDOW_MAX_ZOOM = 500;
export const REFERENCE_WINDOW_MIN_ZOOM = 100;

// @ignore-translation
export const REFERENCE_WINDOW_IMPORTED_DRAWING = 'reference-imported-drawing';
export const REFERENCE_WINDOW_FOLDER_NAME = 'My References';

export const MAX_ENCODER_FLUSH_SIZE = 1024;
export const DATA_SEND_LIMIT = 128 * KB;
export const MAX_PRESENTATION_TIMEOUT = 4 * HOUR;

export const MAX_SIZE = 1e8;
export const MAX_RECT = { x: -MAX_SIZE, y: -MAX_SIZE, w: 3 * MAX_SIZE, h: 3 * MAX_SIZE };

export const MAX_FREE_CUSTOM_BRUSHES = 3;
// @ignore-translation
export const OWNED_BRUSHES_GROUP_ID = 'own';
export const MAX_CUSTOM_BRUSH_NAME_LENGTH = 64;
// @ignore-translation
export const DRAWING_MODEDATOR_TAG = 'drawing-moderator';
// @ignore-translation
export const TROLL_TAG = 'troll';

export const CUSTOMER_INSIGHTS_POSTPONE_PERIOD = 24 * HOUR;

export const CUSTOMER_INSIGHTS_GROUP_NEW = `customer-insights:group:new`;
export const CUSTOMER_INSIGHTS_GROUP_RECCURING = `customer-insights:group:recurring`;
export const CUSTOMER_INSIGHTS_GROUPS = [CUSTOMER_INSIGHTS_GROUP_NEW, CUSTOMER_INSIGHTS_GROUP_RECCURING] as const;
export type CustomerInsightGroups = typeof CUSTOMER_INSIGHTS_GROUPS[number];

export const CUSTOMER_INSIGHTS_STATUS_PREFIX = `customer-insights:status`;
export const CUSTOMER_INSIGHTS_REQUEST = `${CUSTOMER_INSIGHTS_STATUS_PREFIX}:request`;

export const CUSTOMER_INSIGHTS_POSTPONE = `${CUSTOMER_INSIGHTS_STATUS_PREFIX}:postpone-`;
export const CUSTOMER_INSIGHTS_CONFIRMED = `${CUSTOMER_INSIGHTS_STATUS_PREFIX}:confirmed`;
export const CUSTOMER_INSIGHTS_REJECTED = `${CUSTOMER_INSIGHTS_STATUS_PREFIX}:rejected`;

export const CUSTOMER_INSIGHTS_SCHEDULED = `${CUSTOMER_INSIGHTS_STATUS_PREFIX}:scheduled`; // manually assigned by interviewer
export const CUSTOMER_INSIGHTS_COMPLETED = `${CUSTOMER_INSIGHTS_STATUS_PREFIX}:completed`; // manually assigned by interviewer

// @ignore-translation
export const RESERVED_TAGS = {
  layerVisibilityExplanatoryTooltip: '@tooltip-vis-expl',
  advancedBrushesExplanatoryTooltip: '@explanatory-tooltip:advanced-brushes',
  noNps: '@no-nps',
  forceNps: `force-nps:${SurveyId.Nps}`,
  forceAiNps: `force-nps:${SurveyId.Ai}`,
  knowsAboutBrushLimits: '@knows-about-brush-limit',
  updatedUsername: 'updated-username',
  waitingForCommercialRemixing: '@waiting-for:commercial-remix',

  forceOnboarding: 'force-onboarding',
  conferenceUser: 'conference-user',
  conferenceTeam: 'conference-team',

  // Ads
  adTest_SideBanner_ad: '@adtest:test1.1', // Editor side ad banner
  adTest_BottomBanner_ad: '@adtest:test1.2', // Editor bottom ad banner

  adTest_EmailReach_ad: '@adtest:test2.2', // Email Reach
  adTest_GlowButton_ad: '@adtest:test2.3', // Change Button Layout
  adTest_ChangeOptWording_ad: '@adtest:test2.4', // Explain the benefits of opting in
  adTest_Reactionary_ad: '@adtest:test2.5', // Reactionary Pop-up

  adTest_Blaze_ad: '@adtest:test3.1', // Base of 3 (Blaze)
  adTest_BrushesAndReferenceWindow_ad: '@adtest:test3.2', // Unlimited brushes and Reference window
  adTest_BrushesAndStorage_ad: '@adtest:test3.3', // Unlimited brushes and 100 MB
  adTest_BrushesAndSequence_ad: '@adtest:test3.4', // Unlimited brushes and More Sequence Panels (+6)
  // artjam-group:${name} used in custom art jam groups
};

// @ignore-translation
export const POST_RESERVED_TAGS = {
  redirection: 'redirection',
  newFeatureNotification: 'notification:new-feature',
  educationalContentNotification: 'notification:edu-content',
  eventNotification: 'notification:event',
};
export const POST_NOTIFICATION_TAGS = Object.values(POST_RESERVED_TAGS).filter((t) => t.startsWith('notification:'));

export const AD_TAGS = [
  RESERVED_TAGS.adTest_SideBanner_ad,
  RESERVED_TAGS.adTest_BottomBanner_ad,
  RESERVED_TAGS.adTest_EmailReach_ad,
  RESERVED_TAGS.adTest_GlowButton_ad,
  RESERVED_TAGS.adTest_ChangeOptWording_ad,
  RESERVED_TAGS.adTest_Reactionary_ad,
  RESERVED_TAGS.adTest_Blaze_ad,
  RESERVED_TAGS.adTest_BrushesAndReferenceWindow_ad,
  RESERVED_TAGS.adTest_BrushesAndStorage_ad,
  RESERVED_TAGS.adTest_BrushesAndSequence_ad,
];

export const EXTRA_SEQUENCE_ADS_REWARD = 6;
export const EXTRA_STORAGE_ADS_REWARD = 100 * MB;

export const STATS_MILESTONES: { count: number; message: string }[] = [
  { count: 150, message: 'You\'re off to a bright start. What wonders will you create next?' },
  { count: 300, message: '300 strokes and counting! Your creativity knows no bounds. Keep the colors coming.' },
  { count: 600, message: 'The journey\'s getting exciting. Your canvas awaits more adventures.' },
  { count: 1200, message: 'You\'re crafting a masterpiece, stroke by stroke. What masterpiece will you paint today?' },
  { count: 3200, message: 'Every stroke adds to your story. Ready for the next chapter?' },
  { count: 6400, message: 'Your dedication is shaping your masterpiece. The world is your canvas.' },
  { count: 12800, message: 'You\'re not just painting; you\'re telling tales. What story will you tell next?' },
  { count: 25600, message: 'You\'ve reached new heights. Let\'s soar even higher.' },
  { count: 51200, message: 'Your art transcends the ordinary. Onward to the extraordinary!' },
  { count: 102400, message: 'You\'re rewriting the rules of art. Let\'s break new ground together.' },
  { count: 204800, message: 'Your art is a symphony of colors. Let\'s compose a new masterpiece.' },
];

export const SERVER_THUMB_SIZE = 400;
export const NOTIFICATION_THUMB_SIZE = 200;
export const AI_TRAINING_THUMB_SIZE = 512;
export const VALID_THUMBNAIL_SIZES = [SERVER_THUMB_SIZE, SERVER_THUMB_SIZE * 2, SERVER_THUMB_SIZE * 4, AI_TRAINING_THUMB_SIZE, NOTIFICATION_THUMB_SIZE];

const MICROPHONE_TROUBLESHOOTING_GUIDE = 'https://magm.ai/help-microphone-not-found'; // TODO: ensure links correct
const WEBCAM_TROUBLESHOOTING_GUIDE = 'https://magm.ai/help-camera-not-found';
export const NO_MIC_DEVICE_TOAST = { icon: faTimesOctagon, message: 'No microphone device found - ', actionText: 'read more', action: () => window.open(MICROPHONE_TROUBLESHOOTING_GUIDE, '_blank'), closable: false };
export const NO_MIC_PERMISSIONS_TOAST = { icon: faTimesOctagon, message: 'Your browser permissions prevented access to microphone - ', actionText: 'read more', action: () => window.open(MICROPHONE_TROUBLESHOOTING_GUIDE, '_blank'), closable: false };
export const NO_CAMERA_DEVICE_TOAST = { icon: faTimesOctagon, message: 'No camera device found - ', actionText: 'read more', action: () => window.open(WEBCAM_TROUBLESHOOTING_GUIDE, '_blank'), closable: false };
export const NO_CAMERA_PERMISSIONS_TOAST = { icon: faTimesOctagon, message: 'Your browser permissions prevented access to camera - ', actionText: 'read more', action: () => window.open(WEBCAM_TROUBLESHOOTING_GUIDE, '_blank'), closable: false };
export const NO_START_VOICE_CALL_PERMISSION = `You don't have permission to start voice calls`;

// i18n
//@ignore-translation
export const LOCALE_KEY = 'preferredLocale';
//@ignore-translation
export type SupportedLocales = 'en' | 'ja-JP' | 'ko-KR';
//@ignore-translation
export const DEFAULT_LOCALE: SupportedLocales = 'en';
//@ignore-translation
export const LANGUAGES: { [key in SupportedLocales]: string } = {
  'ja-JP': 'Japanese - 日本語',
  'ko-KR': 'Korean - 한국어',
  'en': 'English'
};

export enum EmbeddedFramerPages {
  CommunityGoal = 'community-goal',
}

export const EMBEDDED_FRAMER_PAGES_FEATURES: Record<EmbeddedFramerPages, Feature | null> = {
  [EmbeddedFramerPages.CommunityGoal]: Feature.EmbeddedCommunityGoal,
};

export const EMBEDDED_FRAMER_PAGES_TITLES: Record<EmbeddedFramerPages, string> = {
  [EmbeddedFramerPages.CommunityGoal]: 'Community Goal',
};

export const EMBEDDED_FRAMER_ROUTES_REGEX = new RegExp(`/e/(${Object.values(EmbeddedFramerPages).join('|')})`);

export const MAX_RECENTLY_DRAWN_WITH_ITEMS = 8;
