let data: Map<string, string> | undefined = undefined;

try {
  if (typeof localStorage === 'undefined') {
    data = new Map<string, string>();
  }
} catch {
  data = new Map<string, string>();
}

export function storageClear() {
  if (data) {
    data.clear();
  } else {
    try {
      localStorage.clear();
    } catch { }
  }
}

export function storageGetItem(key: string) {
  if (!key.includes(':expiration') && storageItemIsExpired(key)) return undefined;
  if (data) {
    return data.get(key);
  } else {
    try {
      return localStorage.getItem(key);
    } catch {
      return undefined;
    }
  }
}

export function storageGetJson<T>(key: string): T | undefined {
  const data = storageGetItem(key);

  try {
    if (data) return JSON.parse(data);
  } catch { }

  return undefined;
}

export function storageRemoveItem(key: string) {
  if (data) {
    data.delete(key);
    data.delete(`${key}:expiration`);
  } else {
    try {
      localStorage.removeItem(key);
      localStorage.removeItem(`${key}:expiration`);
    } catch { }
  }
}

export function storageSetItem(key: string, value: string, expiration?: number) {
  try {
    localStorage.setItem(key, value);
    if (expiration) localStorage.setItem(`${key}:expiration`, String(Date.now() + expiration));
    data = undefined;
  } catch {
    if (!data) data = new Map<string, string>();
    data.set(key, value);
  }
}

export function storageItemIsExpired(key: string) {
  const expiration = storageGetItem(`${key}:expiration`);
  if (!expiration) return false;

  if (Date.now() > Number(expiration)) {
    storageRemoveItem(key);
    storageRemoveItem(`${key}:expiration`);
    return true;
  }
  return false;
}

export function storageSetJson(key: string, value: Object, expiration?: number) {
  storageSetItem(key, JSON.stringify(value), expiration);
}

export function storageGetBoolean(key: string) {
  return storageGetItem(key) === 'y';
}

export function storageGetEnum<EnumType extends string>(key: string, values: string[], defaultValue = values[0]): EnumType {
  const value = storageGetItem(key);
  return ((value && values.includes(value)) ? value : (values.includes(defaultValue) ? defaultValue : values[0])) as EnumType;
}

export function storageSetBoolean(key: string, value: boolean, expiration?: number) {
  if (value) {
    storageSetItem(key, 'y', expiration);
  } else {
    storageRemoveItem(key);
  }
}

export function storageSetString(key: string, value: string | undefined | null, expiration?: number) {
  if (value != null) {
    storageSetItem(key, value, expiration);
  } else {
    storageRemoveItem(key);
  }
}

export function storageGetNumber(key: string, defaultValue = 0) {
  return +(storageGetItem(key) ?? defaultValue);
}

export function storageSetNumber(key: string, value: number, expiration?: number) {
  storageSetItem(key, `${value}`, expiration);
}
