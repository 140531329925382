import { Injectable } from '@angular/core';
import { Model } from './model';
import { UserFlowId } from '../common/constants';
import type { Userflow } from 'userflow.js';

@Injectable({ providedIn: 'root' })
export class OnboardingService {
  userflow: Userflow | undefined = undefined;
  model: Model | undefined = undefined;

  ensureAuthenticated() { return Promise.resolve(); }

  startConferenceMode(_idleTimeToLogout: number) { }

  startConferenceCycle() { }

  startFlow(_id: UserFlowId) { }

  stopConferenceMode() { }

  get isConferenceMode() { return false; }

  get canStartConferenceMode (){ return false; }

  get hasOnboarding() { return false; }

  get shouldInitConferenceTimeouts() { return false; }

  async initConferenceTimeouts(_user: any) { } // type: portal/shared/interfaces.ts > UserData

  launchDeployedOnboardingFlows(_user: any | undefined, _onboardingFlowToRun: UserFlowId) { } // type: portal/shared/interfaces.ts > UserData

  pickDeployedOnboardingFlow(_user: any): UserFlowId | undefined { return undefined; }
}
