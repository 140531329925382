import { Injectable } from '@angular/core';
import { AccountData, Analytics, CreateAccountData, OauthProfile, ResetPasswordData, User } from '../common/interfaces';

@Injectable()
export abstract class LoginSignupService {
  abstract createAccount(data: CreateAccountData, oauth?: boolean): Promise<{ needsEmailConfirmation: boolean }>;
  abstract reloadSession(): void;
  abstract initAuth(): Promise<void>;
  abstract signIn(email: string, password: string): Promise<void>;
  abstract signOut(): Promise<void>;
  abstract resetPassword(email: string): Promise<void>;
  abstract changePassword(data: ResetPasswordData): Promise<void>;
  abstract finishInviteSignup(data: CreateAccountData): Promise<void>;
  abstract validateInviteToken(token: string, email: string): Promise<boolean | void>;
  abstract getPendingProfile(): Promise<OauthProfile | undefined>;
  abstract clearPendingProfile(): Promise<void>;
  abstract openAuthPopup(provider: string, sessionId?: string | null): Window | null;
  abstract updateAccount(data: AccountData): Promise<void>;
  abstract trackEvent(eventName: Analytics, props?: any): void; // TODO: move to separate service
  abstract sessionIdRequired: boolean;
  abstract user: User;
  abstract getUserId(): string;
  abstract endImpersonation(): void;
  abstract isImpersonating(): boolean;
  abstract getTeamIdForOrganization(name: string): Promise<string | undefined>;
  // new features
  abstract getHideNewFeatureNotifications(): boolean;
  abstract setHideNewFeatureNotifications(value: boolean): void;
  abstract shouldShowNewFeature(feature: string): boolean;
  abstract dismissNewFeature(feature: string): void;
}
