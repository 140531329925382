import { uniq } from 'lodash';
import { IHistory, IUndoFunction, LayerData, Rect, HistoryBuffer, Drawing, HistoryStats, Texture, HistoryBufferEntry, User, IToolEditor } from './interfaces';
import { getLayerSafe, getLayer } from './drawing';
import { historyBufferStats, pushHistoryBufferEntry, removeHistoryBufferEntry } from './historyBuffer';
import { UNDO_LIMIT } from './constants';
import { setLayerState, toLayerState, layerFromState, redrawLayerThumb, layerChanged, isTextLayer } from './layer';
import { addRect, cloneRect, copyRect, createRect, rectToString } from './rect';
import { redraw, redrawDrawing } from '../services/editorUtils';
import { setPoint } from './point';
import { getSurfaceState, setSurfaceState } from './toolSurface';
import { deserializeMask, serializeMask } from './mask';
import { logAction } from './actionLog';
import { addLayerToDrawing, removeLayerFromDrawing } from './layerToolHelpers';
import { findLastIndex } from './utils';
import { selectLayer } from '../services/layerActions';
import { ensureTextLayerDirty } from './text/text-utils';
import { getMaxLayerWidth } from './drawingUtils';
import type { Editor } from '../services/editor';
import { sendLayerOrder, sendResizeDrawing } from '../services/drawingConnection';

let stateId = 0;

const LOG_ON = false;
const LOG = false;

function log(...args: any[]) {
  LOG_ON && DEVELOPMENT && !TESTS && console.log(...args);
}

function isValidDirtyRect(r: Rect, rect: Drawing) {
  return r.x === (r.x | 0) && r.x === (r.x | 0) && r.x === (r.x | 0) && r.x === (r.x | 0) &&
    rect.x <= r.x && (rect.x + rect.w) >= (r.x + r.w) && rect.y <= r.y && (rect.y + rect.h) >= (r.y + r.h);
}

export class History implements IHistory {
  undos: IUndoFunction[][] = [];
  redos: IUndoFunction[][] = [];
  preundos: IUndoFunction[] = [];
  private undosBuffer: HistoryBuffer = { sheets: [] };
  private redosBuffer: HistoryBuffer = { sheets: [] };
  private transaction: IUndoFunction[] | undefined = undefined;
  private discardedUndos = false;
  constructor(private user: User, private editor: IToolEditor, private drawing: Drawing) {
  }
  stats(stats: HistoryStats) {
    historyBufferStats(this.undosBuffer, stats);
    historyBufferStats(this.redosBuffer, stats);
  }
  hadDiscardedUndos() {
    return this.redos.length === UNDO_LIMIT && this.discardedUndos;
  }
  private log(_message: string) {
    // this.editor.log(`[history] ${this.transaction ? '  ' : ''}${message}`);
  }
  private undoOrRedo(from: IUndoFunction[][], to: IUndoFunction[][]) {
    if (this.transaction) throw new Error('transaction is not ended');

    if (this.preundos.length) {
      logAction(`Preundos not commited [${undoLog(this.preundos)}]`);
      throw new Error('Preundos not commited');
    }

    const fromTransaction = from.pop();
    const toTransaction: IUndoFunction[] = [];

    if (!fromTransaction) return;

    const activeDrawing = this.editor.isActiveDrawing(this.drawing) && this.drawing.user === this.user;

    try {
      if (activeDrawing) (this.editor as Editor).undoingOrRedoing = true; // TODO: fix
      while (fromTransaction.length) {
        const item = fromTransaction.pop()!;
        toTransaction.push(item());

        if (activeDrawing && item.lastX !== undefined && item.lastY !== undefined) {
          setPoint((this.editor as Editor).lastPoint, item.lastX, item.lastY); // TODO: fix
        }
      }
    } finally {
      if (activeDrawing) (this.editor as Editor).undoingOrRedoing = false; // TODO: fix
    }

    to.push(toTransaction);
  }
  attachLastPoint(x: number, y: number) {
    if (this.undos.length) {
      const undo = this.undos[this.undos.length - 1][0];

      if (undo) {
        undo.lastX = x;
        undo.lastY = y;
      } else {
        console.error(`Empty undo list`);
      }
    }
  }
  isLastEntryMove(layerId: number) {
    const last = this.undos[this.undos.length - 1];
    return !!last?.some(u => u.layerId === layerId && !!u.isMove);
  }
  clear() {
    if (this.transaction) throw new Error('transaction is not ended');

    while (this.redos.length) freeUndo(this.redos.pop()!);
    while (this.undos.length) freeUndo(this.undos.pop()!);

    if (this.preundos.length) {
      freeUndo(this.preundos);
      this.preundos = [];
    }

    this.discardedUndos = false;
  }
  clearRedos() {
    while (this.redos.length) {
      freeUndo(this.redos.shift()!);
    }
  }
  unpre() {
    while (this.preundos.length) {
      this.preundos.pop()!()?.free?.();
    }
  }
  canUndo() {
    return this.undos.length > 0;
  }
  canRedo() {
    return this.redos.length > 0;
  }
  undo() {
    LOG && this.log('undo');
    this.undoOrRedo(this.undos, this.redos);
  }
  redo() {
    LOG && this.log('redo');
    this.undoOrRedo(this.redos, this.undos);
  }
  beginTransaction() {
    LOG && this.log('beginTransaction');

    if (this.transaction) throw new Error('transaction is not ended');

    this.transaction = [];
  }
  endTransaction() {
    LOG && this.log('endTransaction');

    if (!this.transaction) throw new Error('transaction is not started');

    this.internalPushUndos([...this.preundos, ...this.transaction]);
    this.preundos = [];
    this.transaction = undefined;
  }
  revertTransaction() {
    LOG && this.log('revertTransaction');

    if (!this.transaction) throw new Error('transaction is not started');

    freeUndo(this.transaction);
    this.transaction = undefined;
  }
  execTransaction(actions: (history: IHistory) => void) {
    this.beginTransaction();

    try {
      actions(this);
      this.endTransaction();
    } catch (e) {
      this.revertTransaction();
      throw e;
    }
  }
  pushUndo(undo: IUndoFunction) {
    if (this.transaction) {
      this.transaction.push(undo);
    } else if (this.preundos.length) {
      LOG && this.log('(preundo transaction)');
      this.beginTransaction();
      this.pushUndo(undo);
      this.endTransaction();
    } else {
      this.internalPushUndos([undo]);
    }
  }
  private internalPushUndos(undos: IUndoFunction[]) {
    this.undos.push(undos);
    this.clearRedos();

    while (this.undos.length > UNDO_LIMIT) {
      freeUndo(this.undos.shift()!);
      this.discardedUndos = true;
    }
  }
  prepushUndo(undo: IUndoFunction) {
    undo.pre = true;
    this.preundos.push(undo);
  }
  // DON'T USE: this causes desync on server
  cancelLastUndo() {
    LOG && this.log('cancelLastUndo');
    this.undo();
    this.clearRedos();
  }
  createSelection(type: string) {
    let snapshot = serializeMask(this.user.selection);

    log('  selection push');

    const swap: IUndoFunction = () => {
      log('  selection swap');
      LOG && this.log('createSelection:swap');
      const temp = serializeMask(this.user.selection);
      deserializeMask(this.user.selection, snapshot);
      snapshot = temp;
      redraw(this.editor);
      return swap;
    };

    swap.type = type;

    return swap;
  }
  pushSelection(type: string) {
    LOG && this.log('pushSelection');
    this.pushUndo(this.createSelection(type));
  }
  prepushSelection(type: string) {
    LOG && this.log('prepushSelection');
    this.prepushUndo(this.createSelection(type));
  }
  private createDirtyRect(type: string, layerId: number, dirtyRect: Rect, isMove?: boolean, ignoreLayerState?: boolean) {
    const editor = this.editor;

    // TODO: re-enable this check
    if (DEVELOPMENT && false && !isValidDirtyRect(dirtyRect, this.drawing))
      throw new Error(`Invalid dirty rect (${rectToString(dirtyRect)}) / (${rectToString(this.drawing)})`);

    const rect = cloneRect(dirtyRect);
    const layer = getLayerSafe(this.drawing, layerId);
    let snapshot = ignoreLayerState ? undefined : pushHistoryBufferEntry(this.undosBuffer, editor.renderer, layer, rect);
    let rectSnapshot = ignoreLayerState ? createRect(0, 0, 0, 0) : cloneRect(layer.rect);
    let isUndo = false;

    log('  rect push', 'layerId:', layerId, rect);

    // get layer snapshot only if we know that it will be cropped after commit
    let layerSnapshot: HistoryBufferEntry | undefined = undefined;

    const r = cloneRect(dirtyRect);
    addRect(r, layer.rect);
    if (r.w > getMaxLayerWidth(this.drawing.w) || r.h > getMaxLayerWidth(this.drawing.h)) {
      layerSnapshot = pushHistoryBufferEntry(this.undosBuffer, editor.renderer, layer, layer.rect);
    }

    const swap: IUndoFunction = () => {
      log('  rect swap', 'layerId:', layerId, rect);
      LOG && this.log('createDirtyRect:swap');
      const buffer = isUndo ? this.undosBuffer : this.redosBuffer;
      const layer = getLayerSafe(this.drawing, layerId); // need to re-fetch layer here
      const temp = (ignoreLayerState && isUndo) ? undefined : pushHistoryBufferEntry(buffer, editor.renderer, layer, rect);
      const tempRect = (ignoreLayerState && isUndo) ? createRect(0, 0, 0, 0) : cloneRect(layer.rect);

      if (layerSnapshot) editor.renderer.restoreSnapshotToLayer(layerSnapshot, layer, rectSnapshot);

      editor.renderer.restoreSnapshotToLayer(snapshot, layer, rectSnapshot);
      removeHistoryBufferEntry(snapshot, editor.renderer);
      redrawDrawing(this.drawing, rect);
      redraw(editor);
      redrawLayerThumb(layer);
      snapshot = temp;
      rectSnapshot = tempRect;
      isUndo = !isUndo;
      return swap;
    };

    swap.type = type;
    swap.layerId = layerId;
    swap.isMove = isMove;
    swap.free = () => {
      if (layerSnapshot) removeHistoryBufferEntry(layerSnapshot, editor.renderer);
      removeHistoryBufferEntry(snapshot, editor.renderer);
    };
    return swap;
  }
  pushDirtyRect(type: string, layerId: number, dirtyRect: Rect, isMove?: boolean, ignoreLayerState?: boolean) {
    LOG && this.log('pushDirtyRect');
    this.pushUndo(this.createDirtyRect(type, layerId, dirtyRect, isMove, ignoreLayerState));
  }
  prepushDirtyRect(type: string, layerId: number, dirtyRect: Rect, isMove?: boolean) {
    LOG && this.log('prepushDirtyRect');
    this.prepushUndo(this.createDirtyRect(type, layerId, dirtyRect, isMove));
  }
  private createTool(type: string, isMove?: boolean) {
    const editor = this.editor;
    const user = this.user;

    let isUndo = false;
    let surface = user.surface;
    let rect = cloneRect(surface.rect);
    let state = getSurfaceState(surface);
    let snapshot = pushHistoryBufferEntry(this.undosBuffer, editor.renderer, surface, rect);

    const id = stateId++;

    log('  state push', id, 'layerId:', state.layerId, state.rect);

    const swap: IUndoFunction = () => {
      log('  state swap', id, 'layerId:', state.layerId, state.rect);
      LOG && this.log('createTool:swap');
      const buffer = isUndo ? this.undosBuffer : this.redosBuffer;
      const tempState = getSurfaceState(surface);
      const tempRect = cloneRect(surface.rect);
      const temp = pushHistoryBufferEntry(buffer, editor.renderer, surface, tempRect);

      if (snapshot) {
        editor.renderer.restoreSnapshotToTool(snapshot, user);
      } else {
        editor.renderer.releaseUserCanvas(user);
      }

      setSurfaceState(surface, state, this.drawing);

      removeHistoryBufferEntry(snapshot, editor.renderer);

      redrawDrawing(this.drawing); // TODO: use rect ?
      redraw(editor);

      const layer1 = getLayer(this.drawing, state.layerId);
      layer1 && layerChanged(layer1);

      const layer2 = getLayer(this.drawing, tempState.layerId);
      layer2 && layerChanged(layer2);

      // TODO:
      //if (surface.empty())
      //  editor.renderer.releaseUserCanvas(user);

      snapshot = temp;
      rect = tempRect;
      state = tempState;
      isUndo = !isUndo;
      return swap;
    };

    swap.layerId = state.layerId;
    swap.isMove = isMove;
    swap.type = type;
    swap.free = () => removeHistoryBufferEntry(snapshot, editor.renderer);
    return swap;
  }
  private createLayerId(type: string, layerId: number) {
    const swap: IUndoFunction = () => swap;
    swap.layerId = layerId;
    swap.type = type;
    return swap;
  }
  pushTool(type: string, isMove?: boolean) {
    LOG && this.log('pushTool');
    this.pushUndo(this.createTool(type, isMove));
  }
  pushLayerId(type: string, layerId: number) {
    LOG && this.log('pushLayerId');
    this.pushUndo(this.createLayerId(type, layerId));
  }
  pushResize(isLocal = false) {
    LOG && this.log('pushResize');
    this.pushUndo(this.createResize(isLocal));
  }
  createResize(isLocal: boolean) {
    const r = cloneRect(this.drawing);

    const swap: IUndoFunction = () => {
      log('  size swap', 'size:', rectToString(r), 'isLocal:', isLocal);
      logAction('  swap canvas size [history]');

      const temp = cloneRect(this.drawing);

      if (isLocal) {
        this.editor.resizeCanvas(r, this.drawing);
        this.drawing.lastCropRect = cloneRect(r);
        sendResizeDrawing(this.user, this.drawing, r.x, r.y, r.w, r.h);
      }

      copyRect(r, temp);
      return swap;
    };

    swap.type = 'resize';
    return swap;
  }
  prepushTool(type: string, isMove?: boolean) {
    LOG && this.log('prepushTool');
    this.prepushUndo(this.createTool(type, isMove));
  }
  prepushLayerState(layerId: number) {
    LOG && this.log('prepushLayerState');
    this.prepushUndo(this.createLayerState(layerId));
  }
  pushLayerState(layerId: number, textAction?: string) {
    LOG && this.log('pushLayerState');
    this.pushUndo(this.createLayerState(layerId, textAction));
  }
  createLayerState(layerId: number, textAction?: string) {
    const layer = getLayerSafe(this.drawing, layerId);
    ensureTextLayerDirty(layer);
    let snapshot = toLayerState(layer);
    delete snapshot.rect;

    log('  layer push', 'layerId:', layerId);

    const swap: IUndoFunction = () => {
      log('  layer swap', 'layerId:', layerId);
      logAction('  swap layer state [history]');
      const layer = getLayerSafe(this.drawing, layerId); // need to re-fetch layer here
      ensureTextLayerDirty(layer);
      const temp = toLayerState(layer);
      delete temp.rect;
      setLayerState(layer, snapshot);

      if (this.editor.isActiveDrawing(this.drawing) && this.user === this.drawing.user) {
        const editor = this.editor as Editor;
        const selection = swap.textSelection;
        swap.textSelection = editor.textTool.getSelectionForUndo(layer);
        if (editor.textTool.isUsingTextTool && layer === this.drawing.user.activeLayer) {
          editor.textTool.onLayerChange(layer);
          editor.textTool.retrieveSelectionFromUndo(selection);
        }
      }

      snapshot = temp;
      redrawDrawing(this.drawing);
      redraw(this.editor);
      return swap;
    };

    swap.layerId = layerId;
    swap.type = 'update layer';
    if (isTextLayer(layer)) {
      swap.textAction = textAction;
      swap.isText = true;

      if (this.editor.isActiveDrawing(this.drawing) && this.user === this.drawing.user) {
        swap.textSelection = (this.editor as Editor).textTool.getSelectionForUndo(layer);
      }
    }

    return swap;
  }
  pushAddLayer(data: LayerData, index: number, auto?: boolean) {
    LOG && this.log('pushAddLayer');
    this.pushLayerAddRemove(data, index, true, !!auto);
  }
  pushRemoveLayer(data: LayerData, index: number) {
    LOG && this.log('pushRemoveLayer');
    this.pushLayerAddRemove(data, index, false, false);
  }
  private pushLayerAddRemove(data: LayerData, index: number, adding: boolean, auto: boolean) {
    let add: IUndoFunction, remove: IUndoFunction;

    log(`  layer ${adding ? 'add' : 'remove'} push`, 'layerId:', data.id);

    add = () => {
      log(`  layer ${adding ? 'add' : 'remove'} (add)`, 'layerId:', data.id);
      logAction(`  add layer (${data.id}) [history]`);
      const layer = layerFromState(data);
      if (layer.textData) {
        // we don't want to retrieve layer in non-dirty state so it gets deleted right away
        layer.textData.dirty = true;
      }
      addLayerToDrawing(this.editor, this.user, this.drawing, layer, index);
      sendLayerOrder(this.user, this.drawing);
      if (this.drawing.user === this.user) {
        selectLayer(this.editor, this.user, this.drawing, layer);
      }
      redrawDrawing(this.drawing);
      return remove;
    };

    remove = () => {
      log(`  layer ${adding ? 'add' : 'remove'} (remove)`, 'layerId:', data.id);
      logAction(`  remove layer (${data.id}) [history]`);
      // TODO: save new index ???
      const layer = getLayer(this.drawing, data.id);
      if (layer && this.editor.isActiveDrawing(this.drawing) && this.user === this.drawing.user) {
        (this.editor as Editor).textTool.denyLayerAutoDelete(layer);
      }
      removeLayerFromDrawing(this.editor, this.user, this.drawing, data.id);
      redrawDrawing(this.drawing);
      redraw(this.editor);
      return add;
    };

    add.layerId = data.id;
    add.type = `${adding ? 'addLayer' : 'removeLayer'}`;
    add.skipVerify = auto;
    remove.layerId = data.id;
    remove.type = `${adding ? 'addLayer' : 'removeLayer'}`;
    remove.skipVerify = auto;
    this.pushUndo(adding ? remove : add);
  }
  clearLayer(layerId: number) {
    if (this.transaction) throw new Error('transaction is not ended');

    const redosIndex = findLastIndex(this.redos, redo => redo.some(i => i.layerId === layerId));

    if (redosIndex !== -1) {
      this.redos.splice(0, redosIndex + 1).forEach(freeUndo);
      this.undos.forEach(freeUndo);
      this.undos = [];
    } else {
      const undosIndex = findLastIndex(this.undos, undo => undo.some(i => i.layerId === layerId));

      if (undosIndex !== -1) {
        const lastToRemove = this.undos[undosIndex];

        // check if only prepushes are connected to the layer
        if (lastToRemove.some(i => !i.pre && i.layerId === layerId)) {
          this.undos.splice(0, undosIndex + 1).forEach(freeUndo);
        } else {
          // only remove prepushed funcions from last one
          for (let i = lastToRemove.length - 1; i >= 0; i--) {
            if (lastToRemove[i].pre) {
              lastToRemove[i].free?.();
              lastToRemove.splice(i, 1);
            }
          }

          if (lastToRemove.length === 0) {
            this.undos.splice(0, undosIndex + 1).forEach(freeUndo);
          } else {
            this.undos.splice(0, undosIndex).forEach(freeUndo);
          }
        }
      }
    }

    if (this.preundos.some(x => x.layerId === layerId)) {
      freeUndo(this.preundos);
      this.preundos = [];
    }

    // TEMP: testing, change to DEVELOPMENT later
    if (this.redos.some(u => u.some(i => i.layerId === layerId))) {
      const msg = `Failed to clearLayer redos for layerId: ${layerId}, redos: [${this.redos?.map(undoLog)}]`;
      logAction(msg);
      if (DEVELOPMENT) throw new Error(msg);
    }
    if (this.undos.some(u => u.some(i => i.layerId === layerId))) {
      const msg = `Failed to clearLayer undos for layerId: ${layerId}, undos: ${undosLog(this)}`;
      logAction(msg);
      if (DEVELOPMENT) throw new Error(msg);
    }
  }
  textureHandleInUse(handle: WebGLTexture) {
    for (const buffer of [this.undosBuffer, this.redosBuffer]) {
      for (const sheet of buffer.sheets) {
        if ((sheet.surface as Texture).handle === handle) {
          return true;
        }
      }
    }

    return false;
  }
}

function freeUndo(undos: IUndoFunction[]) {
  for (const undo of undos) undo.free?.();
}

export function undoLog(u: IUndoFunction[]) {
  const t = uniq(u.filter(x => x.type).map(x => `${x.type}${x.layerId ? `(${x.layerId}${x.textAction ? ', ' + x.textAction : ''})` : ''}`));
  return t.length ? t.join('+').replace(/\+move$/, '') : '?';
}

export function undosLog(history: IHistory, showAll = true) {
  return `[${(history as History).undos?.filter(undo => showAll || !undo.find(u => u.skipVerify)).map(undoLog)}]`;
}

export function redosLog(history: IHistory) {
  return `[${(history as History).redos?.map(undoLog)}]`;
}
