
import { Observable } from 'rxjs/internal/Observable';
import { JamDrawing, JamsList, LiveJam, CreateJamDrawing, UpdateJamDrawing, JamFilter, JamData, JamUserDataModerators } from '../common/interfaces-jams';
import { BehaviorSubject } from 'rxjs';
import { Drawing } from '../common/interfaces';

export function mapDrawingToJamDrawing(drawing: Drawing): JamDrawing | undefined {
  if (drawing.jam) {
    return {
      _id: drawing._id,
      name: drawing.name,
      shortId: drawing.id,
      tags: drawing.tags,
      author: {
        name: drawing.creator.name,
        _id: drawing.creator.id,
        avatar: drawing.creator.avatar
      },
      jam: drawing.jam,
    };
  }
  return undefined;
}

export abstract class JamsService {
  abstract activeJam$: BehaviorSubject<JamData | undefined>;
  abstract activeGroup$: BehaviorSubject<string | undefined>;
  abstract showNsfw$: BehaviorSubject<boolean>;
  abstract filterGenre$: BehaviorSubject<string[] | undefined>;
  abstract liveArtJams$: Observable<JamDrawing[]>;
  abstract refreshList$: Observable<void>;

  abstract ensureUserMatureContentAccess(): Promise<void>;
  abstract updateArtJamsMatureFilter(checked: boolean): void;

  abstract observeLiveJam(shortId: string): Observable<LiveJam | undefined>;
  abstract observeJam(shortId: string): Observable<JamDrawing | undefined>;

  abstract getJams(filter: JamFilter, skip: number, size: number): Promise<JamsList>;
  abstract getJam(shortId: string): Promise<JamDrawing | undefined>;

  abstract getJamUsers(entityId: string): Promise<JamUserDataModerators[]>;
  abstract toggleTrollTag(userId: string, enabled: boolean): Promise<void>;

  abstract editJam(shortId: string, jam: UpdateJamDrawing, from: string): Promise<void>;
  abstract createJam(jam: CreateJamDrawing): Promise<void>;
  abstract unarchive(shortId: string): Promise<void>;

  abstract join(shortId: string, from: string, indexOnTheList: number): Promise<void>;
  abstract leave(shortId: string, from: string): Promise<void>;
}
