import { Component, EventEmitter, Input, Output } from '@angular/core';
import { storageSetBoolean } from '../../../../services/storage';

export interface VcFeedback {
  experience: boolean;
  issue: VcRadioFeedback;
  details: string;
}

export enum VcBooleanFeedback {
  None = 'None',
  Bad = 'Bad',
  Good = 'Good',
}

export enum VcRadioFeedback {
  None = 'None',
  VoiceActivation = 'Voice activation was not transmitting.',
  NoAudio = 'I couldn\'t hear audio.',
  BrowserPermissions = 'Issues with setting browser permissions',
  HeadsetOrCamera = 'Issues with headset/camera.',
  ConnectionIssues = 'Connection issues',
  LaggyStream = 'Video/stream was laggy.', // only if video flag
  BrokenStream = 'Video/stream was broken/distorted.', // only if video flag
  ErrorOrBug = 'I\'ve experienced bug/error.',
  Other = 'Other',
}

@Component({
  selector: 'vc-feedback-modal',
  templateUrl: './vc-feedback-modal.pug',
  styleUrls: ['./vc-feedback-modal.scss'],
})
export class VcFeedbackModal {
  private _data: Partial<VcFeedback> = { };
  get data(): Partial<VcFeedback> { return this._data; }
  @Input() set data(value: Partial<VcFeedback>) {
    this._data = value;
    if (value.experience !== undefined) {
      if (value.experience) this.booleanFeedback = VcBooleanFeedback.Good;
      else this.booleanFeedback = VcBooleanFeedback.Bad;
    }
    if (value.issue) this._radioFeedback = value.issue;
    if (value.details) this.customFeedback = value.details;
  }

  @Output() close = new EventEmitter<VcFeedback | undefined>();

  readonly possibleRadioFeedbacks = Object.values(VcRadioFeedback);
  readonly possibleBooleanFeedbacks = Object.values(VcBooleanFeedback);

  step = 0;
  private _booleanFeedback = VcBooleanFeedback.None; // was the call good or bad
  get booleanFeedback(): VcBooleanFeedback { return this._booleanFeedback; }
  set booleanFeedback(value: VcBooleanFeedback) {
    this._booleanFeedback = value;
    if (value === VcBooleanFeedback.Good) {
      this.onClose(true);
    } else {
      if (this._booleanFeedback !== VcBooleanFeedback.None && this._radioFeedback !== VcRadioFeedback.None) {
        this.step++;
      }
    }
  }

  private _radioFeedback = VcRadioFeedback.None; // what was the issue?
  get radioFeedback(): VcRadioFeedback { return this._radioFeedback; }
  set radioFeedback(value: string) { // casted because custom radio emits strings not enum type
    this._radioFeedback = value as VcRadioFeedback;
    if (this._booleanFeedback !== VcBooleanFeedback.None && this._radioFeedback !== VcRadioFeedback.None) {
      this.step++;
    }
  }
  customFeedback = ''; // please give us more details

  private _dontAskAgain = false;
  get dontAskAgain(): boolean {
    return this._dontAskAgain;
  }
  set dontAskAgain(value: boolean) {
    storageSetBoolean('hideVcFeedback', value);
    this._dontAskAgain = value;
  }

  onClose(submit: boolean) {
    if (submit) {
      this.close.emit({
        experience: this._booleanFeedback === VcBooleanFeedback.Good,
        issue: this.radioFeedback,
        details: this.customFeedback,
      });
    } else {
      this.close.emit(undefined);
    }
  }
}
